import React, { useState, useEffect, useMemo, useRef } from 'react'
import './App.css';
import predictedSequences from './consts/predicted'

import MaterialTable from '@material-table/core'
import styled from 'styled-components'
import SelectedProtein from './SelectedProtein'
import CenterResidue from './components/CenterResidue'
import humanProteins from './consts/human-proteins';
import tableIcons from './tableIcons'
import { FlexCol } from './styles/layout';
import { accentColor, backgroundColor, subtitleColor, textColor } from './styles/colors';

import { CitePaper } from './components/CitePaper';
import { SiteHeader } from './components/SiteHeader';
import { TableFilter } from './components/TableFilter';
import { ProteinView } from './components/ProteinView';
import { devices } from './styles/devices';
import { CustomPagination } from './components/table/pagination';


const TableView = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 720px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box; 
`

const Results = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 75px;
  
  flex-wrap: wrap;

  @media ${devices.mobileS} {
    justify-content: center;
  }

`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0px; 
`

const ProteinStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 630px;
  max-width: 400px;
  border: 1px solid #FFFFFF;
  flex-grow: 1;
  padding: 0px;
`

const SequenceRow = ({ sequence }) => (
  <p>
    {sequence.substr(0, 7)}
    <CenterResidue residue={sequence[7]} />
    {sequence.substr(8, 7)}
  </p>
)

const App = () => {
  const pageSize = 4;
  const tableRef = useRef(null)

  // Table Saved State
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(null)

  // Hover and Select State
  const [selectedRow, setSelectedRow] = useState(null)
  const [hoveredRow, setHoveredRow] = useState(null)

  // Table filter
  const [probabilityDifference, setProbabilityDifference] = useState(20)
  const [wildTypeProb, setWildTypeProb] = useState(20)
  const [mutantProb, setMutantProb] = useState(20)
  const [hasT, setHasT] = useState(true)
  const [hasY, setHasY] = useState(true)
  const [hasS, setHasS] = useState(true)

  const [hasKnownPhosphite, setHasKnownPhosphite] = useState(true)
  const [hasUnknownPhosphite, setHasUnknownPhosphite] = useState(true)
  const [query, setQuery] = useState('')

  // Protein Filter
  const [trypticView, setTrypticView] = useState(false)
  const [visibleToMS, setVisbleToMS] = useState("true")
  const [fragmentLengthRange, setFragmentLengthRange] = useState([2, 50])


  // If suffer from performance issues, use useMemo() for each of the filter
  const filtered = useMemo(() => {
    let displayedSequences = predictedSequences
      .filter(sequence => Math.abs(sequence.probDifference) >= probabilityDifference)
      .filter(sequence => sequence.wtProb >= wildTypeProb)
      .filter(sequence => sequence.k198rProb >= mutantProb)

    if (!hasKnownPhosphite) {
      displayedSequences = displayedSequences.filter(each => each.known != "Yes")
    }

    if (!hasUnknownPhosphite) {
      displayedSequences = displayedSequences.filter(each => each.known != "No")
    }

    if (!hasS) {
      displayedSequences = displayedSequences.filter(each => each.sequence[7] !== 'S')
    }

    if (!hasT) {
      displayedSequences = displayedSequences.filter(each => each.sequence[7] !== 'T')
    }

    if (!hasY) {
      displayedSequences = displayedSequences.filter(each => each.sequence[7] !== 'Y')
    }

    if (query) {
      const geneNames = Object.keys(humanProteins)
      const lowerCaseQuery = query.toLowerCase()
      const matchedGeneNames = geneNames.filter(geneName => {
        const lowerCaseHeader = humanProteins[geneName].header.toLowerCase()
        return lowerCaseHeader.includes(lowerCaseQuery)
      })

      displayedSequences = displayedSequences.filter(each => matchedGeneNames.includes(each.geneName))

    }

    return displayedSequences
  }, [mutantProb, wildTypeProb, probabilityDifference, hasT, hasY, hasS, hasKnownPhosphite, hasUnknownPhosphite, query]);


  useEffect(() => {
    if (selectedRow) {
      const filteredIds = filtered.map(each => each.id)
      if (!filteredIds.includes(selectedRow.id)) {
        setSelectedRow(null)
      }
    }

  }, [mutantProb, wildTypeProb, probabilityDifference, hasT, hasY, hasS, hasKnownPhosphite, hasUnknownPhosphite, query])

  const changePageToMatchRow = (row) => {
    if (row) {
      const rowIndex = filtered.find(each => each.id == row.id)
      const page = parseInt(rowIndex.tableData.id / pageSize)
      tableRef.current.dataManager.changeCurrentPage(page)
    }

  }

  let highlightedRow = null
  if (hoveredRow) {
    highlightedRow = hoveredRow
  } else if (selectedRow) {
    highlightedRow = selectedRow
  }


  return (
    <Container>

      <FlexCol justifyContent="space-between">
        <SiteHeader />

        <Results>
          <div>
            <TableView>
              <TableFilter
                query={query}
                setQuery={setQuery}
                probabilityDifference={probabilityDifference}
                setProbabilityDifference={setProbabilityDifference}
                wildTypeProb={wildTypeProb}
                setWildTypeProb={setWildTypeProb}
                mutantProb={mutantProb}
                setMutantProb={setMutantProb}
                setHasY={setHasY}
                setHasT={setHasT}
                setHasS={setHasS}
                hasY={hasY}
                hasT={hasT}
                hasS={hasS}
                hasKnownPhosphite={hasKnownPhosphite}
                setHasKnownPhosphite={setHasKnownPhosphite}
                setHasUnknownPhosphite={setHasUnknownPhosphite}
                hasUnknownPhosphite={hasUnknownPhosphite}
              />
              <MaterialTable
                tableRef={tableRef}
                icons={tableIcons}
                onChangePage={setCurrentPage}
                style={{ margin: "0px 25px", borderRadius: "0px", boxShadow: "none"}}
                options={{
                  pageSizeOptions: [],
                  toolbar: false,
                  search: false,
                  pageSize: pageSize,
                  exportButton: true,
                  headerStyle: {
                    textAlign: 'center',
                    fontFamily: 'Roboto Mono',
                    fontSize: "14.23px",
                    color: textColor,
                    fontWeight: '700',
                    backgroundColor: backgroundColor,
                  },
                  rowStyle: rowData => ({
                    backgroundColor: (highlightedRow && highlightedRow.id === rowData.id) ? accentColor : backgroundColor,
                    fontFamily: 'Roboto Mono',
                    fontWeight: '400',
                    color: textColor,
                    fontSize: '14.24px',
                  })
                }}
                components={{
                  Pagination: (props) => 
                    <CustomPagination
                      props={props}
                      setCurrentPage={(event, page) => {
                        props.onChangePage(event, page);
                        setCurrentPage(page)
                        
                      }}                      
                      currentPage={currentPage}
                      lastPage={Math.ceil(filtered.length / pageSize)}
                    />
                  
                }}
                onRowClick={((evt, row) => {
                  setHoveredRow(null)
                  setSelectedRow(row)
                })}
                columns={[
                  {
                    title: 'Gene Name',
                    field: 'geneName',
                    cellStyle: {
                      width: '150px',
                    }
                  },
                  {
                    title: 'Sequence',
                    field: 'sequence',
                    render: rowData => <SequenceRow sequence={rowData.sequence} />,
                    sorting: false,
                  },
                  {
                    title: "Known",
                    field: 'known',
                  },
                  {
                    title: 'Position',
                    field: 'pos',
                    sorting: false,
                  },
                  {
                    title: 'WT Prob',
                    field: 'wtProb',
                    render: rowData => `${rowData.wtProb.toFixed(1)}%`,
                  },
                  {
                    title: 'K198R Prob',
                    field: 'k198rProb',
                    render: rowData => `${rowData.k198rProb.toFixed(1)}%`,
                  },
                  {
                    title: 'Prob Difference',
                    field: 'probDifference',
                    render: rowData => `${rowData.probDifference.toFixed(1)}%`,
                    customSort: (a, b) => Math.abs(a.probDifference) - Math.abs(b.probDifference),
                  }
                ]}
                data={filtered}
              />

            </TableView>
            <CitePaper />
          </div>

          <ProteinStyle>
            <ProteinView
              setTrypticView={setTrypticView}
              trypticView={trypticView}
              selectedRow={selectedRow}
              highlightedRow={highlightedRow}
              filtered={filtered}
              visibleToMS={visibleToMS}
              setSelectedRow={setSelectedRow}
              setHoveredRow={setHoveredRow}
              changePageToMatchRow={changePageToMatchRow}
              fragmentLengthRange={fragmentLengthRange}
            />
            <SelectedProtein
              trypticView={trypticView}
              minFragmentLength={fragmentLengthRange[0]}
              maxFragmentLength={fragmentLengthRange[1]}
              geneName={selectedRow && selectedRow.geneName}
              highlightedPos={highlightedRow && highlightedRow.pos}
              sequences={filtered}
              visibleToMS={visibleToMS}
              unhoverRow={() => {
                // If hoveredRow is null, when unhover is called, it means that a sequence has already been clicked on 
                // Therefore, the table has 
                if (hoveredRow !== null) {
                  if (lastPage !== null) {
                    setHoveredRow(null)
                    tableRef.current.dataManager.changeCurrentPage(lastPage)
                    setLastPage(null)
                  }
                }
              }}
              setSelectedRow={(row) => {
                setSelectedRow(row)
                setHoveredRow(null)
                changePageToMatchRow(row)
              }}
              setHoveredRow={(row) => {
                setLastPage(currentPage)
                setHoveredRow(row)
                changePageToMatchRow(row)
              }} />
          </ProteinStyle>

        </Results>
      </FlexCol>

    </Container>

  );
}

export default App;
