import React from 'react'
import styled from 'styled-components'
import { backgroundColor, primaryColor } from '../../styles/colors'
import { FlexCol, FlexRow } from '../../styles/layout'
import { NormalText } from '../../styles/text'

const Icon = styled.svg`
  fill: none;
  stroke: ${backgroundColor};
  stroke-width: 4px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18.75px;
  height: 18.75px;
  background-color: ${primaryColor};
  border-radius: 0px;
  // transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}
  }
`


export const Checkbox = ({ label, checked, onChange, style, ...props }) => {
  return (
    <FlexRow style={style} alignItems="center" onClick={() => onChange(!checked)}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <NormalText style={{marginLeft: "9.62px"}}>{label}</NormalText>
    </FlexRow>
  )

}
