import React, { useMemo } from 'react'
import styled from 'styled-components'
import humanProtein from './consts/human-proteins'
import CenterResidue from './components/CenterResidue'
import { BoldText, NormalText } from './styles/text';
import { accentColor, backgroundColor, subtitleColor, textColor } from './styles/colors';
import { FlexCol, FlexRow } from './styles/layout';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px;
    flex: 1;
    max-height: 790px;
    background-color: ${backgroundColor};
    color: ${textColor};
`

const SequenceDisplay = styled.div`
    text-align: center;
    box-sizing: border-box;
    line-break: anywhere;
    max-height: 350px;
    padding: 0px;

    border: 1px solid #FFFFFF;
    padding: 15px 24px;

    line-height: 25px;
    overflow-y: scroll;
    font-size: 14.24px;

    > span {
        text-align: center;
        display: inline-block;
        width: 9px;
        padding: 0em 0.2em;
    }
`

const SelectedHeader = styled(FlexCol)`
    padding: 30px 25px;
`

const Selected = styled.span`
    color: white;
    background-color: ${accentColor};
    padding: 4px 10px;

    overflow: hidden;
    line-height: 25px;
    letter-spacing: 2px;

    font-size: 14.24px;
    color: #000000;
`

const HighLighted = styled.span`
    background-color: ${accentColor};
    color: white;
`

const SelectedProtein = ({
    geneName,
    highlightedPos,
    sequences,
    minFragmentLength,
    maxFragmentLength,
    trypticView,
    visibleToMS,
    setSelectedRow,
    setHoveredRow,
    unhoverRow,
}) => {

    const protein = humanProtein[geneName]

    const matchedSequences = useMemo(() => !geneName ? [] : sequences
        .filter(sequence => sequence.geneName == geneName), [geneName, sequences])

    const memoizedDisplayedResidues = useMemo(() => {
        if (geneName) {
            let displayedResidues = protein.sequence.split('')
            if (trypticView) {
                let lowerIndex = 0
                let upperIndex = 0
                for (upperIndex = 0; upperIndex < displayedResidues.length; upperIndex++) {
                    if ((displayedResidues[upperIndex] == 'R' || displayedResidues[upperIndex] == 'K')
                        && (upperIndex + 1 < displayedResidues.length && displayedResidues[upperIndex + 1] != 'P')) {
                        let trypticFragmentLength = upperIndex - lowerIndex + 1;
                        if (trypticFragmentLength < minFragmentLength || trypticFragmentLength > maxFragmentLength) {
                            if (visibleToMS == "true") {
                                for (let j = lowerIndex; j <= upperIndex; j++) {
                                    displayedResidues[j] = ''
                                }
                            }
                        } else if (visibleToMS == "false") {
                            for (let j = lowerIndex; j <= upperIndex; j++) {
                                displayedResidues[j] = ''
                            }
                        }
                        lowerIndex = upperIndex + 1;
                    }
                }

                // Take the last fragment regardless presence of R or K
                if (lowerIndex < upperIndex) {
                    let trypticFragmentLength = upperIndex - lowerIndex;
                    if (trypticFragmentLength < minFragmentLength && trypticFragmentLength > maxFragmentLength) {
                        for (let j = lowerIndex; j <= upperIndex; j++) {
                            displayedResidues[j] = ''
                        }
                    }
                }
            }

            return displayedResidues
        }
        return []
    }, [trypticView, visibleToMS, minFragmentLength, maxFragmentLength, geneName])

    if (!geneName) {
        return (
            <Container elevation={3}>
                <SelectedHeader>
                    <BoldText>SELECTED PROTEIN:</BoldText>
                    <NormalText color={subtitleColor}>Please click on a protein to view more details.</NormalText>
                </SelectedHeader>
            </Container>
        )
    } else {
        return (
            <Container elevation={3}>
                <SelectedHeader>
                    <BoldText>SELECTED PROTEIN:</BoldText>
                    <Selected>{protein.header}</Selected>
                </SelectedHeader>

                <SequenceDisplay>
                    {memoizedDisplayedResidues.map((letter, i) => {
                        if (letter == '') {
                            return <span key={i}></span>
                        } else {
                            const sequence = matchedSequences.find(each => each.pos == i)
                            if (sequence) {
                                return <CenterResidue
                                    key={i}
                                    probDiff={sequence.probDifference}
                                    onClick={() => setSelectedRow(sequence)}
                                    onMouseLeave={() => unhoverRow()}
                                    onMouseOver={() => setHoveredRow(sequence)}
                                    residue={letter} />
                            } else if (i >= highlightedPos - 7 && i <= highlightedPos + 7) {
                                return <HighLighted key={i}>{letter}</HighLighted>
                            } else {
                                return <span key={i}>{letter}</span>
                            }
                        }
                    })}
                </SequenceDisplay>

            </Container>
        )
    }
}

export default SelectedProtein