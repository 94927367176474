import styled from "styled-components";
import { primaryColor } from "../../styles/colors";

const sliderThumbStyles = (props) => (`
  width: 16px;
  height: 16px;
  outline: none;
  border: none;
  border-radius: 16px;

  background-color: ${primaryColor};
  cursor: pointer;
  color: white;
`);

const SliderStyle = styled.div`
  display: flex;
  align-items: center;
  color: #888;

  .value {
    flex: 1;
    font-size: 2rem;
  }
  .slider {
    flex: 6;
    -webkit-appearance: none;
    width: 200px;
    height: 0px;

    outline: none;
    border: 0.5px solid #FFFFFF;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      ${props => sliderThumbStyles(props)}
    }
    &::-moz-range-thumb {
      ${props => sliderThumbStyles(props)}
    }
  }
`;

export const Slider = ({value, min, max, step, onChange, ariaLabel}) => {
    return (
        <SliderStyle color="white">
            <input aria-label={ariaLabel} type="range" className="slider" min={min} max={max} step={step} value={value} onChange={(e) => onChange(e, e.target.value)}/>
        </SliderStyle>
    );

}