import { Checkbox, FormControlLabel, Paper, Radio, RadioGroup, Slider } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import styled from "styled-components"
import SelectedProtein from "../SelectedProtein"
import { backgroundColor } from "../styles/colors"
import { FlexCol } from "../styles/layout"
import { BoldText } from "../styles/text"



const ProteinOptionsStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${backgroundColor};
    height: 293.43px;
`

const MSRangeLabel = styled.label`
  font-weight: bold;
  margin-top: 10px;
`

export const ProteinView = (props) => {
    console.log(props.trypticView)
    return (
        <ProteinOptionsStyle>
            <BoldText>Protein Options</BoldText>

            <Checkbox label="Tryptic Digest View" checked={props.trypticView} onChange={(e) => props.setTrypticView(e.target.checked)} name="trypticView" />
            {props.trypticView && (
                <FlexCol alignItems='center'>
                    <RadioGroup   name="tryptic view option" value={props.visibleToMS} onChange={(e) => props.setVisbleToMS(e.target.value)}>
                        <FormControlLabel value="true" control={<Radio style={{color: "white"}}/>} label="Show Fragments Visible to MS" />
                        <FormControlLabel value="false" control={<Radio style={{color: "white"}}/>} label="Show Fragment Not Visible to MS" />
                    </RadioGroup>
                    <MSRangeLabel>
                        MS Visible Range {props.fragmentLengthRange[0]}-{props.fragmentLengthRange[1]}
                    </MSRangeLabel>
                    <Slider
                        value={props.fragmentLengthRange}
                        min={1}
                        step={1}
                        max={100}
                        onChange={(e, newValue) => {
                            props.setFragmentLengthRange(newValue)
                        }}
                    />
                </FlexCol>
            )}
        </ProteinOptionsStyle>
    )
}