import styled from "styled-components";
import { FlexCol, FlexRow } from "../styles/layout";
import { BoldText, NormalText } from "../styles/text";
import { Checkbox } from "./form/Checkbox";
import { TextField } from "./form/TextField";

import ProbabilityFilter from './ProbabilityFilter'


const TableFilterStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 0px;
    padding: 24px 32px;
    align-self: center;
    width: 100%;
    box-sizing: border-box;
`

const ResidueFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;

  margin-left: 35px;
  & > div {
    margin-right: 20px;
  }
`

export const TableFilter = (props) => {
    return (
        <TableFilterStyle>
            <FlexCol fullWidth style={{marginBottom: "31px"}}>
            <BoldText style={{marginBottom: "17px"}}>Table Filters</BoldText>
            <FlexRow alignItems="center" justifyContent="space-between">
                <TextField 
                    value={props.query}
                    onChange={e => props.setQuery(e.target.value)}
                    placeholder="Filter by Protein Name" 
                    variant="filled" 
                />
                <FlexCol style={{alignSelf: "space-between"}}>
                <ResidueFilterContainer style={{marginBottom: "18.75px"}}>
                    <NormalText style={{marginRight: "20px"}}>Center Residual:</NormalText>
                    <Checkbox color="primary" checked={props.hasS} label="S" onChange={(val) => props.setHasS(val)} name="hasS" />
                    <Checkbox color="primary" checked={props.hasT} label="T" onChange={(val) => props.setHasT(val)} name="hasT" />
                    <Checkbox color="primary" checked={props.hasY} label="Y" onChange={(val) => props.setHasY(val)} name="hasY" />
                </ResidueFilterContainer>

                <ResidueFilterContainer>
                    <NormalText style={{marginRight: "20px"}}>Known Phosphite: </NormalText>
                    <Checkbox color="primary" checked={props.hasKnownPhosphite} label="Yes" onChange={(val) => props.setHasKnownPhosphite(val)} name="hasKnownPhosphite" />
                    <Checkbox color="primary" checked={props.hasUnknownPhosphite} label="No" onChange={(val) => props.setHasUnknownPhosphite(val)} name="hasUnknownPhosphite" />
                </ResidueFilterContainer>  
                </FlexCol>
            </FlexRow>

            </FlexCol>
            <ProbabilityFilter 
                probabilityDifference={props.probabilityDifference}
                setProbabilityDifference={props.setProbabilityDifference}
                wildTypeProb={props.wildTypeProb}
                setWildTypeProb={props.setWildTypeProb}
                mutantProb={props.mutantProb}
                setMutantProb={props.setMutantProb}
            />
        </TableFilterStyle>
    )
}