import { FlexCol, FlexRow } from "../styles/layout"
import { BoldText, Title } from "../styles/text"
import UConnLogo from "../images/uconnlogo.svg";
import styled from "styled-components";

const HeaderContainer = styled(FlexRow)`
  margin: 0px;
  margin-bottom: 50px;
  width: 100%;

  justify-content: space-between;
  align-items: center;
`

export const SiteHeader = () => {
    return (
      <HeaderContainer>
        <Title style={{margin: "0px", textAlign: "start"}}>OCNDS CK2 K198R Ion Channel Predictor</Title>
        <FlexCol alignItems="flex-end">
          <img src={UConnLogo} style={{width: "128px", marginBottom: "10.94px"}}/>
          <BoldText>schwartzlab.uconn.edu</BoldText>
        </FlexCol>
      </HeaderContainer>
    )
}