import React from 'react'
import styled from 'styled-components'

const getResidueBackgroundColor = (residue) => {
    switch(residue) {
        case 'T':
            return "rgb(255, 202, 123"
        case 'S':
            return "rgb(255, 202, 123"
        case 'Y':
            return "rgb(255, 202, 123"
        default:
            return "transparent"
    }
}

const Center = styled.span`
    background-color: var(--backgroundColor);
    color: white;
    font-weight: bold;
    &:hover {
        cursor: pointer;
    }
`

const MAX_PROB = 65.6

const CenterResidue = ({ residue, probDiff = MAX_PROB, ...props }) => {
    const baseBackground = getResidueBackgroundColor(residue)
    let backgroundOpacity = 1
    if(probDiff !== null) {
        backgroundOpacity = Math.abs(probDiff) / MAX_PROB * 0.8 + 0.2
    }

    const backgroundColor = `${baseBackground},${backgroundOpacity})`
    return <Center
        style={{
            '--backgroundColor': backgroundColor
        }}
        {...props}>{residue}</Center>
}

export default CenterResidue