import { FlexRow } from "../../styles/layout";
import ChevronClick from "../../images/clickchevron.svg";
import styled from "styled-components";
import { accentColor, backgroundColor, textColor } from "../../styles/colors";
import { NormalText } from "../../styles/text";
import { useState } from "react";

const ButtonNoStyle = styled.button`
    background: none;
	color: inherit;
	border: none;
	padding: 10px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`

const PaginationIcon = ({flip=false}) => {
    const additionalStyles = flip ? {transform: "rotate(180deg)"} : {};
    return <img src={ChevronClick} style={{height: "15px", ...additionalStyles}}/>
}

const PaginationStyle = styled(FlexRow)`
    justify-content: space-between;
    background-color: ${backgroundColor};
    align-items: center;
`


const SwitchToPageStyle = styled(NormalText)`
    color: ${textColor};
    ${({ selected }) => selected === true && `
        color: ${accentColor};
    `}
`
const SwitchToPage = ({pageNum, switchToPage, selected=false}) => {
    return (
        <ButtonNoStyle onClick={(event) => switchToPage(event, pageNum)}>
            <SwitchToPageStyle selected={selected} key={pageNum}>{pageNum + 1}</SwitchToPageStyle>
        </ButtonNoStyle>
    );
}

const ClickableNumbers = ({
    current, 
    lastPage, 
    setPage, 
    pageNumMargin,
}) => {
    return (
        <div>{[...Array(lastPage)].map((element, pageNum) => <SwitchToPage pageNum={pageNum } switchToPage={setPage} selected={pageNum == current}/> )}</div>
    )
}

export const CustomPagination = ({
    setCurrentPage,
    currentPage,
    lastPage,
    pageNumMargin=5,
    ...props
}) => {
    const nextPage = (event) => setCurrentPage(event, currentPage + 1)
    const prevPage = (event) => setCurrentPage(event, currentPage - 1)
    

    return (
        <PaginationStyle >
            {currentPage !== 0 ? 
                <ButtonNoStyle onClick={prevPage}><PaginationIcon /></ButtonNoStyle>
                : <div></div>
            }

            <ClickableNumbers 
                current={currentPage} 
                lastPage={lastPage} 
                setPage={setCurrentPage} 
                pageNumMargin={pageNumMargin}
            />

            {currentPage !== lastPage - 1? 
                <ButtonNoStyle onClick={nextPage}><PaginationIcon flip/></ButtonNoStyle>
                : <div></div>
            }
        </PaginationStyle>
    )
}