import styled from "styled-components"
import { textColor } from "./colors"

export const Title = styled.h1`
  text-align: center;
  font-family: "Sora";
  font-size: 45px;
  font-weight: 400;
  color: ${({ color }) => color ? color : textColor };
`

export const BoldText = styled.b`
  font-family: Roboto Mono;
  weight: 700px;
  font-size: 14.23px;
  margin: 0px;
  color: ${({ color }) => color ? color : textColor };
`

export const NormalText = styled.p`
  font-family: Roboto Mono;
  weight: 400px;
  font-size: 14.23px;
  margin: 0px;
  color: ${({ color }) => color ? color : textColor };
`
