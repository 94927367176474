
import styled from 'styled-components'


export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  ${({ alignItems }) => alignItems && `
    align-items: ${alignItems};
  `}

  ${({ justifyContent }) => justifyContent && `
    justify-content: ${justifyContent};
  `}

  ${({ fullWidth }) => fullWidth && `
    width: 100%;
  `}
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  ${({ alignItems }) => alignItems && `
    align-items: ${alignItems};
  `}

  ${({ justifyContent }) => justifyContent && `
    justify-content: ${justifyContent};
  `}

  ${({ fullWidth }) => fullWidth && `
    width: 100%;
  `}

  ${({ availableWidth}) => availableWidth && `
    flex: 1;
  `}
`