import styled from "styled-components";
import { backgroundColor, textColor } from "../../styles/colors";

const InputStyle = styled.input`
  width: 350px;
  height: 55px;
  box-sizing: border-box;
  padding: 5px 15px;

  outline: none;
  border: 1px solid #FFFFFF;
  background-color: ${backgroundColor};

  font-size: 15px;
  color: ${textColor}
`

export const TextField = ({value, onChange, placeholder=""}) => {
  return (
    <InputStyle type="text" value={value} onChange={onChange} placeholder={placeholder}/>
  );
}