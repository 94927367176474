import { subtitleColor } from "../styles/colors"
import { FlexRow } from "../styles/layout"
import { BoldText, NormalText } from "../styles/text"

export const CitePaper = () => {
    return (
        <FlexRow justifyContent="space-between" style={{marginTop: "25px"}}>
            <div style={{maxWidth: "600px", marginRight: "25px"}}>
                <BoldText>Please Cite:</BoldText>
                <NormalText color={subtitleColor}>
                    Caefer DM, Phan NQ, Liddle JC, Balsbaugh JL, O'Shea JP, 
                    Tzingounis AV, & Schwartz D. The Okur-Chung Neurodevelopmental 
                    Syndrome (OCNDS) mutation CK2K198R leads to a rewiring of kinase 
                    specificity. bioRxiv 2021.04.05.438522
                </NormalText>
            </div>
            <div style={{minWidth: "200px"}}>
                <BoldText>Article link</BoldText>
                <NormalText color={subtitleColor}>https://doi.org/10.1101/2021.04.05.438522</NormalText>
            </div>
        </FlexRow>
    )
}