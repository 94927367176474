export default [
    {
     "sequence": "KRKQKEQSGGEEKDE",
     "pos": 509,
     "wtScore": 126.297,
     "wtProb": 34.4,
     "k198rScore": 125.877,
     "k198rProb": 100,
     "probDifference": 65.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 0
    },
    {
     "sequence": "KKKQKEQSGEEEKND",
     "pos": 513,
     "wtScore": 130.905,
     "wtProb": 34.6,
     "k198rScore": 122.848,
     "k198rProb": 100,
     "probDifference": 65.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1
    },
    {
     "sequence": "VSSPNLASDSDLSRR",
     "pos": 436,
     "wtScore": 207.921,
     "wtProb": 63.6,
     "k198rScore": 67.4749,
     "k198rProb": 23.4,
     "probDifference": -40.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2
    },
    {
     "sequence": "TEEFSSESDMEESKE",
     "pos": 1125,
     "wtScore": 238.858,
     "wtProb": 82.9,
     "k198rScore": 95.5434,
     "k198rProb": 43.8,
     "probDifference": -39.2,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 3
    },
    {
     "sequence": "AEGDANRSDTDEDKT",
     "pos": 1026,
     "wtScore": 206.776,
     "wtProb": 63.7,
     "k198rScore": 70.3749,
     "k198rProb": 25,
     "probDifference": -38.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 4
    },
    {
     "sequence": "FKPKGYFSDAWNTFD",
     "pos": 1265,
     "wtScore": 199.147,
     "wtProb": 61.4,
     "k198rScore": 66.4384,
     "k198rProb": 22.8,
     "probDifference": -38.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 5
    },
    {
     "sequence": "TEDFSSESDLEESKE",
     "pos": 1135,
     "wtScore": 238.961,
     "wtProb": 82.5,
     "k198rScore": 95.3093,
     "k198rProb": 44.4,
     "probDifference": -38.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 6
    },
    {
     "sequence": "GGEEAEASDPADEEV",
     "pos": 2093,
     "wtScore": 199.219,
     "wtProb": 61.1,
     "k198rScore": 69.2837,
     "k198rProb": 24.1,
     "probDifference": -37,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 7
    },
    {
     "sequence": "QDFGPGYSDEEPDPG",
     "pos": 2137,
     "wtScore": 240.344,
     "wtProb": 80.6,
     "k198rScore": 89.9501,
     "k198rProb": 44,
     "probDifference": -36.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 8
    },
    {
     "sequence": "PYIAEGESDTDSDLC",
     "pos": 838,
     "wtScore": 197.073,
     "wtProb": 58,
     "k198rScore": 65.1786,
     "k198rProb": 22,
     "probDifference": -36,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 9
    },
    {
     "sequence": "EVNQIANSDNKVTID",
     "pos": 798,
     "wtScore": 196.718,
     "wtProb": 58,
     "k198rScore": 61.4557,
     "k198rProb": 22.1,
     "probDifference": -35.9,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 10
    },
    {
     "sequence": "FKPKGYFSDPWNVFD",
     "pos": 1299,
     "wtScore": 196.741,
     "wtProb": 58,
     "k198rScore": 65.4147,
     "k198rProb": 22.2,
     "probDifference": -35.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 11
    },
    {
     "sequence": "GNKEMKLSDGSYFGE",
     "pos": 601,
     "wtScore": 197.999,
     "wtProb": 58.9,
     "k198rScore": 69.1789,
     "k198rProb": 24.1,
     "probDifference": -34.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 12
    },
    {
     "sequence": "TEDVSSESDPEGSKD",
     "pos": 1116,
     "wtScore": 238.329,
     "wtProb": 77.1,
     "k198rScore": 94.9022,
     "k198rProb": 43.5,
     "probDifference": -33.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 13
    },
    {
     "sequence": "ISDVNNLSDCQALGK",
     "pos": 1381,
     "wtScore": 194.341,
     "wtProb": 54,
     "k198rScore": 58.3043,
     "k198rProb": 20.8,
     "probDifference": -33.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 14
    },
    {
     "sequence": "SGSESGDSDGRGVYE",
     "pos": 721,
     "wtScore": 195.573,
     "wtProb": 55.6,
     "k198rScore": 70.4314,
     "k198rProb": 25,
     "probDifference": -30.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 15
    },
    {
     "sequence": "FIEFAKLSDFADALD",
     "pos": 1818,
     "wtScore": 192.497,
     "wtProb": 50.6,
     "k198rScore": 55.9415,
     "k198rProb": 20,
     "probDifference": -30.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 16
    },
    {
     "sequence": "GKLCDPESDYNPGEE",
     "pos": 1424,
     "wtScore": 193.639,
     "wtProb": 52.5,
     "k198rScore": 65.17,
     "k198rProb": 22,
     "probDifference": -30.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 17
    },
    {
     "sequence": "SPNLASDSDLSRRSS",
     "pos": 438,
     "wtScore": 192.395,
     "wtProb": 50.5,
     "k198rScore": 56.1978,
     "k198rProb": 20.2,
     "probDifference": -30.3,
     "geneName": "KCNA1",
     "known": "No",
     "id": 18
    },
    {
     "sequence": "ADLQGPYSDRISPRQ",
     "pos": 770,
     "wtScore": 191.255,
     "wtProb": 48.2,
     "k198rScore": 52.8543,
     "k198rProb": 18.1,
     "probDifference": -30.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 19
    },
    {
     "sequence": "NCFADKPSDGRDPLR",
     "pos": 838,
     "wtScore": 193.429,
     "wtProb": 51.8,
     "k198rScore": 64.567,
     "k198rProb": 22.2,
     "probDifference": -29.6,
     "geneName": "KCNB2",
     "known": "No",
     "id": 20
    },
    {
     "sequence": "ENKPKPNSDLEAGKN",
     "pos": 52,
     "wtScore": 234.825,
     "wtProb": 73.7,
     "k198rScore": 89.5377,
     "k198rProb": 44.2,
     "probDifference": -29.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 21
    },
    {
     "sequence": "KTCFFADSDIVAEED",
     "pos": 305,
     "wtScore": 193.608,
     "wtProb": 52.5,
     "k198rScore": 67.2424,
     "k198rProb": 23.4,
     "probDifference": -29,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 22
    },
    {
     "sequence": "KGKLYTCSDSSKQTE",
     "pos": 1078,
     "wtScore": 192.615,
     "wtProb": 50.6,
     "k198rScore": 61.0395,
     "k198rProb": 22.1,
     "probDifference": -28.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 23
    },
    {
     "sequence": "DSKPKPNSDLEAGKS",
     "pos": 56,
     "wtScore": 234.193,
     "wtProb": 72.9,
     "k198rScore": 87.0847,
     "k198rProb": 44.6,
     "probDifference": -28.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 24
    },
    {
     "sequence": "FIEFSKLSDFAAALD",
     "pos": 1813,
     "wtScore": 189.879,
     "wtProb": 46.5,
     "k198rScore": 53.0446,
     "k198rProb": 18.3,
     "probDifference": -28.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 25
    },
    {
     "sequence": "PLLCGNGSDAGQCPE",
     "pos": 340,
     "wtScore": 192.267,
     "wtProb": 50,
     "k198rScore": 63.4043,
     "k198rProb": 22.2,
     "probDifference": -27.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 26
    },
    {
     "sequence": "IQSVKKLSDVMILTV",
     "pos": 247,
     "wtScore": 188.359,
     "wtProb": 44.3,
     "k198rScore": 51.2782,
     "k198rProb": 16.8,
     "probDifference": -27.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 27
    },
    {
     "sequence": "IQSVKKLSDVMILTV",
     "pos": 251,
     "wtScore": 188.359,
     "wtProb": 44.3,
     "k198rScore": 51.2782,
     "k198rProb": 16.8,
     "probDifference": -27.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 28
    },
    {
     "sequence": "IQSVKKLSDVMILTV",
     "pos": 248,
     "wtScore": 188.359,
     "wtProb": 44.3,
     "k198rScore": 51.2782,
     "k198rProb": 16.8,
     "probDifference": -27.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 29
    },
    {
     "sequence": "IQSVKKLSDVMILTV",
     "pos": 247,
     "wtScore": 188.359,
     "wtProb": 44.3,
     "k198rScore": 51.2782,
     "k198rProb": 16.8,
     "probDifference": -27.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 30
    },
    {
     "sequence": "PSAAGRLSDSSSEAS",
     "pos": 479,
     "wtScore": 191.185,
     "wtProb": 47.5,
     "k198rScore": 56.5788,
     "k198rProb": 20.3,
     "probDifference": -27.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 31
    },
    {
     "sequence": "EDPEGELSGSESGDS",
     "pos": 714,
     "wtScore": 123.52,
     "wtProb": 34.4,
     "k198rScore": 112.962,
     "k198rProb": 61.5,
     "probDifference": 27.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 32
    },
    {
     "sequence": "SSFRNKNSDKQRSAD",
     "pos": 2051,
     "wtScore": 190.391,
     "wtProb": 46.5,
     "k198rScore": 56.2427,
     "k198rProb": 20.2,
     "probDifference": -26.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 33
    },
    {
     "sequence": "ENGPKPNSDLEAGKS",
     "pos": 53,
     "wtScore": 233.194,
     "wtProb": 71.7,
     "k198rScore": 87.4338,
     "k198rProb": 45.5,
     "probDifference": -26.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 34
    },
    {
     "sequence": "ETYIRSDSGDEQLPT",
     "pos": 1814,
     "wtScore": 111.692,
     "wtProb": 35,
     "k198rScore": 108.91,
     "k198rProb": 61.1,
     "probDifference": 26.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 35
    },
    {
     "sequence": "ENGPKPNSDLEAGKN",
     "pos": 52,
     "wtScore": 232.79,
     "wtProb": 70.5,
     "k198rScore": 88.224,
     "k198rProb": 44.6,
     "probDifference": -25.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 36
    },
    {
     "sequence": "DCVCKIASDCQLPRW",
     "pos": 924,
     "wtScore": 189.668,
     "wtProb": 46.5,
     "k198rScore": 58.0593,
     "k198rProb": 20.7,
     "probDifference": -25.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 37
    },
    {
     "sequence": "EGESDTDSDLCTPCG",
     "pos": 842,
     "wtScore": 188.984,
     "wtProb": 45.1,
     "k198rScore": 55.0871,
     "k198rProb": 19.5,
     "probDifference": -25.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 38
    },
    {
     "sequence": "EKYIIDESDYMSFIN",
     "pos": 1099,
     "wtScore": 190.44,
     "wtProb": 46.6,
     "k198rScore": 59.2797,
     "k198rProb": 21.4,
     "probDifference": -25.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 39
    },
    {
     "sequence": "NHVSYYQSDGRSAFP",
     "pos": 1759,
     "wtScore": 191.144,
     "wtProb": 47.5,
     "k198rScore": 65.8477,
     "k198rProb": 22.5,
     "probDifference": -25.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 40
    },
    {
     "sequence": "EKYVVDESDYMSFIN",
     "pos": 1089,
     "wtScore": 190.563,
     "wtProb": 46.8,
     "k198rScore": 61.1732,
     "k198rProb": 22.1,
     "probDifference": -24.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 41
    },
    {
     "sequence": "RERIWQNSDFNFDNV",
     "pos": 1079,
     "wtScore": 190.366,
     "wtProb": 46.4,
     "k198rScore": 60.8912,
     "k198rProb": 22,
     "probDifference": -24.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 42
    },
    {
     "sequence": "HQYIDADTDDEGQLL",
     "pos": 759,
     "wtScore": 100.945,
     "wtProb": 32.1,
     "k198rScore": -4.31033,
     "k198rProb": 7.8,
     "probDifference": -24.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 43
    },
    {
     "sequence": "KKEDNRYSDLKTIIC",
     "pos": 677,
     "wtScore": 189.108,
     "wtProb": 45.4,
     "k198rScore": 61.0727,
     "k198rProb": 22.1,
     "probDifference": -23.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 44
    },
    {
     "sequence": "VPIAVGESDFENLNT",
     "pos": 1121,
     "wtScore": 231.959,
     "wtProb": 66.7,
     "k198rScore": 89.6759,
     "k198rProb": 43.8,
     "probDifference": -22.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 45
    },
    {
     "sequence": "VPIAVGESDFENLNT",
     "pos": 1109,
     "wtScore": 231.959,
     "wtProb": 66.7,
     "k198rScore": 89.6759,
     "k198rProb": 43.8,
     "probDifference": -22.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 46
    },
    {
     "sequence": "VPIAVGESDFENLNT",
     "pos": 1102,
     "wtScore": 231.959,
     "wtProb": 66.7,
     "k198rScore": 89.6759,
     "k198rProb": 43.8,
     "probDifference": -22.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 47
    },
    {
     "sequence": "VPIAVGESDFENLNT",
     "pos": 1111,
     "wtScore": 231.959,
     "wtProb": 66.7,
     "k198rScore": 89.6759,
     "k198rProb": 43.8,
     "probDifference": -22.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 48
    },
    {
     "sequence": "AQAPVSMSDLWITML",
     "pos": 369,
     "wtScore": 183.288,
     "wtProb": 36.4,
     "k198rScore": 47.5426,
     "k198rProb": 13.6,
     "probDifference": -22.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 49
    },
    {
     "sequence": "MDGNRLTSDKKFCSP",
     "pos": 547,
     "wtScore": 182.698,
     "wtProb": 35.7,
     "k198rScore": 46.3333,
     "k198rProb": 12.9,
     "probDifference": -22.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 50
    },
    {
     "sequence": "LLLSSFSSDNLAATD",
     "pos": 987,
     "wtScore": 179.34,
     "wtProb": 34.2,
     "k198rScore": 44.4261,
     "k198rProb": 11.6,
     "probDifference": -22.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 51
    },
    {
     "sequence": "LLLSSFSSDNLAATD",
     "pos": 988,
     "wtScore": 179.34,
     "wtProb": 34.2,
     "k198rScore": 44.4261,
     "k198rProb": 11.6,
     "probDifference": -22.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 52
    },
    {
     "sequence": "KRKQKELSEGEEKGD",
     "pos": 503,
     "wtScore": 160.933,
     "wtProb": 33.3,
     "k198rScore": 105.578,
     "k198rProb": 55.6,
     "probDifference": 22.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 53
    },
    {
     "sequence": "GDANRSDTDEDKTSV",
     "pos": 1028,
     "wtScore": 94.2781,
     "wtProb": 29.7,
     "k198rScore": -5.86301,
     "k198rProb": 7.5,
     "probDifference": -22.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 54
    },
    {
     "sequence": "GNHSSGKSGGFDVKA",
     "pos": 230,
     "wtScore": 60.6452,
     "wtProb": 17.3,
     "k198rScore": 80.6229,
     "k198rProb": 39.2,
     "probDifference": 21.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 55
    },
    {
     "sequence": "SGEGKGSTDDEAEDG",
     "pos": 1174,
     "wtScore": 91.0151,
     "wtProb": 28.3,
     "k198rScore": -12.8839,
     "k198rProb": 6.7,
     "probDifference": -21.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 56
    },
    {
     "sequence": "NHVNHVNSDRRDSLQ",
     "pos": 1694,
     "wtScore": 185.593,
     "wtProb": 39.2,
     "k198rScore": 52.826,
     "k198rProb": 18,
     "probDifference": -21.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 57
    },
    {
     "sequence": "SASTISKSDYMEIQE",
     "pos": 455,
     "wtScore": 187.444,
     "wtProb": 43,
     "k198rScore": 60.7609,
     "k198rProb": 21.9,
     "probDifference": -21.1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 58
    },
    {
     "sequence": "GLHSPLQSDNATDSP",
     "pos": 692,
     "wtScore": 184.886,
     "wtProb": 37.8,
     "k198rScore": 51.2344,
     "k198rProb": 16.8,
     "probDifference": -21,
     "geneName": "KCNB2",
     "known": "No",
     "id": 59
    },
    {
     "sequence": "SSTGDGISDSVWTPS",
     "pos": 860,
     "wtScore": 183.216,
     "wtProb": 36.3,
     "k198rScore": 49.8306,
     "k198rProb": 15.4,
     "probDifference": -20.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 60
    },
    {
     "sequence": "RPSIPPASDTEKPLF",
     "pos": 1721,
     "wtScore": 229.342,
     "wtProb": 63.4,
     "k198rScore": 86.3991,
     "k198rProb": 42.9,
     "probDifference": -20.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 61
    },
    {
     "sequence": "WIVFNVVSDTFFLMD",
     "pos": 250,
     "wtScore": 174.819,
     "wtProb": 33.3,
     "k198rScore": 46.2322,
     "k198rProb": 12.8,
     "probDifference": -20.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 62
    },
    {
     "sequence": "IAEGESDTDSDLCTP",
     "pos": 840,
     "wtScore": 88.5935,
     "wtProb": 27.1,
     "k198rScore": -12.609,
     "k198rProb": 6.7,
     "probDifference": -20.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 63
    },
    {
     "sequence": "TGNMFDISDVNNLSD",
     "pos": 1375,
     "wtScore": 183.658,
     "wtProb": 36.7,
     "k198rScore": 50.8939,
     "k198rProb": 16.5,
     "probDifference": -20.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 64
    },
    {
     "sequence": "WIIFNVASDTVFLLD",
     "pos": 181,
     "wtScore": 177.381,
     "wtProb": 33.7,
     "k198rScore": 47.2933,
     "k198rProb": 13.4,
     "probDifference": -20.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 65
    },
    {
     "sequence": "LAEGETDTDTDPFTP",
     "pos": 839,
     "wtScore": 88.1427,
     "wtProb": 27,
     "k198rScore": -15.3852,
     "k198rProb": 6.7,
     "probDifference": -20.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 66
    },
    {
     "sequence": "SAADNILSGGAPQSP",
     "pos": 2165,
     "wtScore": 79.5577,
     "wtProb": 25.2,
     "k198rScore": 87.4894,
     "k198rProb": 45.2,
     "probDifference": 20,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 67
    },
    {
     "sequence": "EGETDTDTDPFTPSG",
     "pos": 841,
     "wtScore": 86.9214,
     "wtProb": 26.6,
     "k198rScore": -10.9397,
     "k198rProb": 6.7,
     "probDifference": -20,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 68
    },
    {
     "sequence": "ALLCGNSSDAGQCPE",
     "pos": 339,
     "wtScore": 186.084,
     "wtProb": 40.4,
     "k198rScore": 57.5526,
     "k198rProb": 20.5,
     "probDifference": -19.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 69
    },
    {
     "sequence": "ALLCGNSSDAGQCPE",
     "pos": 341,
     "wtScore": 186.084,
     "wtProb": 40.4,
     "k198rScore": 57.5526,
     "k198rProb": 20.5,
     "probDifference": -19.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 70
    },
    {
     "sequence": "GQVVALPSDFFLRID",
     "pos": 1225,
     "wtScore": 181.416,
     "wtProb": 35,
     "k198rScore": 49.8815,
     "k198rProb": 15.4,
     "probDifference": -19.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 71
    },
    {
     "sequence": "AMNMPLNSDGTVMFN",
     "pos": 1539,
     "wtScore": 186.102,
     "wtProb": 40.4,
     "k198rScore": 58.5511,
     "k198rProb": 21,
     "probDifference": -19.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 72
    },
    {
     "sequence": "KCLQWPPSDSAFETN",
     "pos": 282,
     "wtScore": 185.523,
     "wtProb": 39,
     "k198rScore": 55.8168,
     "k198rProb": 20,
     "probDifference": -19,
     "geneName": "SCN3A",
     "known": "No",
     "id": 73
    },
    {
     "sequence": "AWIIHPYSDFRFYWD",
     "pos": 207,
     "wtScore": 180.28,
     "wtProb": 34.5,
     "k198rScore": 49.9891,
     "k198rProb": 15.5,
     "probDifference": -18.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 74
    },
    {
     "sequence": "MVTMMVETDDQGKYM",
     "pos": 1546,
     "wtScore": 77.6119,
     "wtProb": 25.3,
     "k198rScore": -22.1886,
     "k198rProb": 6.4,
     "probDifference": -18.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 75
    },
    {
     "sequence": "SADNLAATDDDGEMN",
     "pos": 987,
     "wtScore": 85.1231,
     "wtProb": 25.7,
     "k198rScore": -9.44774,
     "k198rProb": 6.8,
     "probDifference": -18.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 76
    },
    {
     "sequence": "SSKEMKLTDGSYFGE",
     "pos": 532,
     "wtScore": 82.6036,
     "wtProb": 25.4,
     "k198rScore": -19.2108,
     "k198rProb": 6.7,
     "probDifference": -18.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 77
    },
    {
     "sequence": "FWIIHPYSDFRFYWD",
     "pos": 138,
     "wtScore": 179.214,
     "wtProb": 34.1,
     "k198rScore": 49.8584,
     "k198rProb": 15.4,
     "probDifference": -18.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 78
    },
    {
     "sequence": "VIIDKPATDDNGTTT",
     "pos": 677,
     "wtScore": 78.5342,
     "wtProb": 25.2,
     "k198rScore": -21.4831,
     "k198rProb": 6.5,
     "probDifference": -18.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 79
    },
    {
     "sequence": "SSDNLAATDDDNEMN",
     "pos": 994,
     "wtScore": 84.4051,
     "wtProb": 25.5,
     "k198rScore": -9.08488,
     "k198rProb": 6.9,
     "probDifference": -18.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 80
    },
    {
     "sequence": "SSDNLAATDDDNEMN",
     "pos": 993,
     "wtScore": 84.4051,
     "wtProb": 25.5,
     "k198rScore": -9.08488,
     "k198rProb": 6.9,
     "probDifference": -18.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 81
    },
    {
     "sequence": "SADNLAATDDDNEMN",
     "pos": 1002,
     "wtScore": 84.1493,
     "wtProb": 25.5,
     "k198rScore": -9.6595,
     "k198rProb": 6.8,
     "probDifference": -18.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 82
    },
    {
     "sequence": "GNSTPEKTDGSSSTT",
     "pos": 1957,
     "wtScore": 79.1238,
     "wtProb": 25.2,
     "k198rScore": -20.8538,
     "k198rProb": 6.5,
     "probDifference": -18.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 83
    },
    {
     "sequence": "DSFISCATDFPEATR",
     "pos": 595,
     "wtScore": 83.8972,
     "wtProb": 25.4,
     "k198rScore": -15.4215,
     "k198rProb": 6.7,
     "probDifference": -18.6,
     "geneName": "KCNB1",
     "known": "No",
     "id": 84
    },
    {
     "sequence": "LDEFGQSTDNPQLAH",
     "pos": 223,
     "wtScore": 81.9837,
     "wtProb": 25.4,
     "k198rScore": -15.8383,
     "k198rProb": 6.7,
     "probDifference": -18.6,
     "geneName": "KCNB1",
     "known": "No",
     "id": 85
    },
    {
     "sequence": "AMEHYPMTDHFNNVL",
     "pos": 792,
     "wtScore": 78.0855,
     "wtProb": 25.2,
     "k198rScore": -19.9701,
     "k198rProb": 6.6,
     "probDifference": -18.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 86
    },
    {
     "sequence": "LHRSSWYTDEPDISY",
     "pos": 2025,
     "wtScore": 77.2184,
     "wtProb": 25.1,
     "k198rScore": -20.8049,
     "k198rProb": 6.6,
     "probDifference": -18.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 87
    },
    {
     "sequence": "ESSSVLNTDPDAEKP",
     "pos": 876,
     "wtScore": 78.9341,
     "wtProb": 25.2,
     "k198rScore": -19.9308,
     "k198rProb": 6.6,
     "probDifference": -18.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 88
    },
    {
     "sequence": "PLQSDNATDSPKSSL",
     "pos": 696,
     "wtScore": 78.4922,
     "wtProb": 25.1,
     "k198rScore": -13.9282,
     "k198rProb": 6.7,
     "probDifference": -18.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 89
    },
    {
     "sequence": "VGRGPAITDKDRTKG",
     "pos": 599,
     "wtScore": 80.7724,
     "wtProb": 25.2,
     "k198rScore": -15.8525,
     "k198rProb": 6.7,
     "probDifference": -18.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 90
    },
    {
     "sequence": "KGKFYRCTDEAKSNP",
     "pos": 1044,
     "wtScore": 77.2101,
     "wtProb": 25.1,
     "k198rScore": -19.5127,
     "k198rProb": 6.7,
     "probDifference": -18.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 91
    },
    {
     "sequence": "CRQEAVHTDSLEGKI",
     "pos": 2020,
     "wtScore": 79.6235,
     "wtProb": 25.2,
     "k198rScore": -18.3578,
     "k198rProb": 6.8,
     "probDifference": -18.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 92
    },
    {
     "sequence": "MVTMMVETDDQSEYV",
     "pos": 1561,
     "wtScore": 78.4346,
     "wtProb": 25.1,
     "k198rScore": -17.6662,
     "k198rProb": 6.8,
     "probDifference": -18.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 93
    },
    {
     "sequence": "DSFTSCATDFTETER",
     "pos": 610,
     "wtScore": 79.5104,
     "wtProb": 25.1,
     "k198rScore": -17.6293,
     "k198rProb": 6.8,
     "probDifference": -18.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 94
    },
    {
     "sequence": "RYLAEGETDTDTDPF",
     "pos": 837,
     "wtScore": 77.0111,
     "wtProb": 25.1,
     "k198rScore": -17.9915,
     "k198rProb": 6.8,
     "probDifference": -18.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 95
    },
    {
     "sequence": "MVTMMVETDDQSQEM",
     "pos": 1551,
     "wtScore": 75.463,
     "wtProb": 24.6,
     "k198rScore": -22.1185,
     "k198rProb": 6.4,
     "probDifference": -18.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 96
    },
    {
     "sequence": "TLPELQETDEFGQLN",
     "pos": 220,
     "wtScore": 75.2275,
     "wtProb": 24.5,
     "k198rScore": -23.2293,
     "k198rProb": 6.2,
     "probDifference": -18.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 97
    },
    {
     "sequence": "WRGALGASGGRFVEA",
     "pos": 632,
     "wtScore": 58.9915,
     "wtProb": 16.1,
     "k198rScore": 77.8215,
     "k198rProb": 34.3,
     "probDifference": 18.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 98
    },
    {
     "sequence": "ENSTPEKTDMTPSTT",
     "pos": 1962,
     "wtScore": 73.3815,
     "wtProb": 23.8,
     "k198rScore": -25.8885,
     "k198rProb": 5.8,
     "probDifference": -18,
     "geneName": "SCN2A",
     "known": "No",
     "id": 99
    },
    {
     "sequence": "RPSAELGSGEPGEAK",
     "pos": 2161,
     "wtScore": 71.3627,
     "wtProb": 22.8,
     "k198rScore": 83.7441,
     "k198rProb": 40.6,
     "probDifference": 17.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 100
    },
    {
     "sequence": "MVTMMVETDTQSKQM",
     "pos": 1542,
     "wtScore": 72.4892,
     "wtProb": 23.3,
     "k198rScore": -26.7209,
     "k198rProb": 5.6,
     "probDifference": -17.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 101
    },
    {
     "sequence": "NLVLGVLSGEFSKER",
     "pos": 404,
     "wtScore": 82.7959,
     "wtProb": 25.3,
     "k198rScore": 86.3276,
     "k198rProb": 42.9,
     "probDifference": 17.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 102
    },
    {
     "sequence": "NLVLGVLSGEFSKER",
     "pos": 405,
     "wtScore": 82.7959,
     "wtProb": 25.3,
     "k198rScore": 86.3276,
     "k198rProb": 42.9,
     "probDifference": 17.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 103
    },
    {
     "sequence": "YQQSTSFTDPFFIVE",
     "pos": 218,
     "wtScore": 73.7655,
     "wtProb": 24,
     "k198rScore": -22.2979,
     "k198rProb": 6.4,
     "probDifference": -17.6,
     "geneName": "KCNA2",
     "known": "No",
     "id": 104
    },
    {
     "sequence": "HLQMKFPTDLPGTEE",
     "pos": 634,
     "wtScore": 71.7705,
     "wtProb": 23,
     "k198rScore": -27.9369,
     "k198rProb": 5.4,
     "probDifference": -17.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 105
    },
    {
     "sequence": "RRSITRDSDTPLSLM",
     "pos": 785,
     "wtScore": 180.674,
     "wtProb": 34.7,
     "k198rScore": 51.7596,
     "k198rProb": 17.2,
     "probDifference": -17.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 106
    },
    {
     "sequence": "GCKFSLKTDTGDTVP",
     "pos": 944,
     "wtScore": 71.2323,
     "wtProb": 22.9,
     "k198rScore": -27.3543,
     "k198rProb": 5.5,
     "probDifference": -17.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 107
    },
    {
     "sequence": "SMNMPLNSDGTVMFN",
     "pos": 1599,
     "wtScore": 185.217,
     "wtProb": 38.3,
     "k198rScore": 58.739,
     "k198rProb": 21.1,
     "probDifference": -17.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 108
    },
    {
     "sequence": "IYNSNIFTDPFFIVE",
     "pos": 217,
     "wtScore": 73.2148,
     "wtProb": 23.7,
     "k198rScore": -20.1321,
     "k198rProb": 6.6,
     "probDifference": -17,
     "geneName": "KCNA1",
     "known": "No",
     "id": 109
    },
    {
     "sequence": "PEGNLRDSDTSISIP",
     "pos": 783,
     "wtScore": 181.179,
     "wtProb": 34.8,
     "k198rScore": 52.4649,
     "k198rProb": 17.7,
     "probDifference": -17,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 110
    },
    {
     "sequence": "GELSGSESGDSDGRG",
     "pos": 718,
     "wtScore": 71.2432,
     "wtProb": 22.9,
     "k198rScore": 83.0116,
     "k198rProb": 39.9,
     "probDifference": 17,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 111
    },
    {
     "sequence": "ENSITEKTDLTMSTA",
     "pos": 1972,
     "wtScore": 71.2228,
     "wtProb": 22.9,
     "k198rScore": -25.1519,
     "k198rProb": 5.9,
     "probDifference": -17,
     "geneName": "SCN1A",
     "known": "No",
     "id": 112
    },
    {
     "sequence": "PLLCGNSSDAGQCPE",
     "pos": 327,
     "wtScore": 184.616,
     "wtProb": 37.4,
     "k198rScore": 57.4543,
     "k198rProb": 20.5,
     "probDifference": -17,
     "geneName": "SCN8A",
     "known": "No",
     "id": 113
    },
    {
     "sequence": "YYETYIRSDSGDEQL",
     "pos": 1812,
     "wtScore": 184.401,
     "wtProb": 37.3,
     "k198rScore": 56.7935,
     "k198rProb": 20.4,
     "probDifference": -16.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 114
    },
    {
     "sequence": "IEDVNNHTDCLKLIE",
     "pos": 1393,
     "wtScore": 72.9344,
     "wtProb": 23.6,
     "k198rScore": -19.1226,
     "k198rProb": 6.7,
     "probDifference": -16.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 115
    },
    {
     "sequence": "SAASRDFSGIGGLGE",
     "pos": 472,
     "wtScore": 56.173,
     "wtProb": 14.2,
     "k198rScore": 75.9614,
     "k198rProb": 30.8,
     "probDifference": 16.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 116
    },
    {
     "sequence": "CITMEGWTDVLYWVN",
     "pos": 365,
     "wtScore": 68.3026,
     "wtProb": 21.7,
     "k198rScore": -30.0978,
     "k198rProb": 5.2,
     "probDifference": -16.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 117
    },
    {
     "sequence": "RQAPESMTDIWLTML",
     "pos": 438,
     "wtScore": 69.6961,
     "wtProb": 22.2,
     "k198rScore": -25.8399,
     "k198rProb": 5.8,
     "probDifference": -16.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 118
    },
    {
     "sequence": "PTRPPRATDTPGPGP",
     "pos": 748,
     "wtScore": 69.5714,
     "wtProb": 22.2,
     "k198rScore": -24.389,
     "k198rProb": 6,
     "probDifference": -16.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 119
    },
    {
     "sequence": "GELLESSSEASKLSS",
     "pos": 485,
     "wtScore": 80.1399,
     "wtProb": 25.3,
     "k198rScore": 39.5083,
     "k198rProb": 9.3,
     "probDifference": -16,
     "geneName": "SCN3A",
     "known": "No",
     "id": 120
    },
    {
     "sequence": "GPRPRPLSELHLKEK",
     "pos": 862,
     "wtScore": 91.9,
     "wtProb": 28.7,
     "k198rScore": 46.1362,
     "k198rProb": 12.7,
     "probDifference": -16,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 121
    },
    {
     "sequence": "LNTLTISSEHYNQPD",
     "pos": 541,
     "wtScore": 81.1546,
     "wtProb": 25.3,
     "k198rScore": 40.0727,
     "k198rProb": 9.5,
     "probDifference": -15.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 122
    },
    {
     "sequence": "GRLSDSSSEASKLSS",
     "pos": 483,
     "wtScore": 77.5314,
     "wtProb": 25.2,
     "k198rScore": 39.9137,
     "k198rProb": 9.4,
     "probDifference": -15.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 123
    },
    {
     "sequence": "YATNLSRTDLHSTWQ",
     "pos": 353,
     "wtScore": 68.0739,
     "wtProb": 21.5,
     "k198rScore": -25.7284,
     "k198rProb": 5.8,
     "probDifference": -15.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 124
    },
    {
     "sequence": "FFACPSKTDFFKNIM",
     "pos": 246,
     "wtScore": 70.0514,
     "wtProb": 22.4,
     "k198rScore": -19.705,
     "k198rProb": 6.7,
     "probDifference": -15.7,
     "geneName": "KCNA1",
     "known": "No",
     "id": 125
    },
    {
     "sequence": "IGNLEHVSENGHHSS",
     "pos": 1780,
     "wtScore": 89.6593,
     "wtProb": 27.6,
     "k198rScore": 45.2227,
     "k198rProb": 12.1,
     "probDifference": -15.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 126
    },
    {
     "sequence": "PEIRRAISGDLTAEE",
     "pos": 1717,
     "wtScore": 72.6196,
     "wtProb": 23.4,
     "k198rScore": 81.8177,
     "k198rProb": 38.7,
     "probDifference": 15.3,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 127
    },
    {
     "sequence": "GGSPRSSSEISKLSS",
     "pos": 477,
     "wtScore": 74.8473,
     "wtProb": 24.4,
     "k198rScore": 40.4249,
     "k198rProb": 9.6,
     "probDifference": -14.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 128
    },
    {
     "sequence": "CITMEGWTDVLYWMN",
     "pos": 366,
     "wtScore": 64.2048,
     "wtProb": 19.6,
     "k198rScore": -33.0418,
     "k198rProb": 5,
     "probDifference": -14.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 129
    },
    {
     "sequence": "SIIDVILSETNHYFC",
     "pos": 1320,
     "wtScore": 89.6959,
     "wtProb": 27.7,
     "k198rScore": 46.6127,
     "k198rProb": 13,
     "probDifference": -14.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 130
    },
    {
     "sequence": "DPAPCAFSGNGRQCT",
     "pos": 319,
     "wtScore": 64.4621,
     "wtProb": 19.8,
     "k198rScore": 77.8639,
     "k198rProb": 34.3,
     "probDifference": 14.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 131
    },
    {
     "sequence": "TKRVLGESGEMDALR",
     "pos": 1863,
     "wtScore": 75.591,
     "wtProb": 24.7,
     "k198rScore": 82.5024,
     "k198rProb": 39.2,
     "probDifference": 14.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 132
    },
    {
     "sequence": "TKRVLGESGEMDALR",
     "pos": 1868,
     "wtScore": 75.591,
     "wtProb": 24.7,
     "k198rScore": 82.5024,
     "k198rProb": 39.2,
     "probDifference": 14.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 133
    },
    {
     "sequence": "TKRVLGESGEMDALR",
     "pos": 1878,
     "wtScore": 75.591,
     "wtProb": 24.7,
     "k198rScore": 82.5024,
     "k198rProb": 39.2,
     "probDifference": 14.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 134
    },
    {
     "sequence": "TKRVLGDSGELDILR",
     "pos": 1858,
     "wtScore": 76.9246,
     "wtProb": 25,
     "k198rScore": 81.3828,
     "k198rProb": 39.5,
     "probDifference": 14.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 135
    },
    {
     "sequence": "AGGIGVFSESSSVAS",
     "pos": 483,
     "wtScore": 87.6884,
     "wtProb": 26.8,
     "k198rScore": 45.7522,
     "k198rProb": 12.5,
     "probDifference": -14.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 136
    },
    {
     "sequence": "DIPPEMVSEPLEDLD",
     "pos": 73,
     "wtScore": 85.9646,
     "wtProb": 26.1,
     "k198rScore": 44.6801,
     "k198rProb": 11.8,
     "probDifference": -14.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 137
    },
    {
     "sequence": "DIPPEMVSEPLEDLD",
     "pos": 73,
     "wtScore": 85.9646,
     "wtProb": 26.1,
     "k198rScore": 44.6801,
     "k198rProb": 11.8,
     "probDifference": -14.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 138
    },
    {
     "sequence": "VANALGYSELGAIKS",
     "pos": 1294,
     "wtScore": 85.0083,
     "wtProb": 25.7,
     "k198rScore": 44.2429,
     "k198rProb": 11.5,
     "probDifference": -14.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 139
    },
    {
     "sequence": "TANALGYSELGAIKS",
     "pos": 1306,
     "wtScore": 85.1808,
     "wtProb": 25.8,
     "k198rScore": 44.7544,
     "k198rProb": 11.8,
     "probDifference": -14,
     "geneName": "SCN1A",
     "known": "No",
     "id": 140
    },
    {
     "sequence": "TANALGYSELGAIKS",
     "pos": 1296,
     "wtScore": 85.1808,
     "wtProb": 25.8,
     "k198rScore": 44.7544,
     "k198rProb": 11.8,
     "probDifference": -14,
     "geneName": "SCN2A",
     "known": "No",
     "id": 141
    },
    {
     "sequence": "IANALGYSELGAIKS",
     "pos": 1286,
     "wtScore": 85.4404,
     "wtProb": 25.9,
     "k198rScore": 45.0148,
     "k198rProb": 12,
     "probDifference": -13.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 142
    },
    {
     "sequence": "KNGNGTTSGIGSSVE",
     "pos": 1067,
     "wtScore": 58.5696,
     "wtProb": 15.8,
     "k198rScore": 74.7025,
     "k198rProb": 29.6,
     "probDifference": 13.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 143
    },
    {
     "sequence": "SLEPTEGSGAGGDPA",
     "pos": 2237,
     "wtScore": 65.84,
     "wtProb": 20.6,
     "k198rScore": 77.6825,
     "k198rProb": 34.3,
     "probDifference": 13.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 144
    },
    {
     "sequence": "LNTLTIASEHYNQPN",
     "pos": 542,
     "wtScore": 85.6022,
     "wtProb": 25.9,
     "k198rScore": 45.4138,
     "k198rProb": 12.2,
     "probDifference": -13.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 145
    },
    {
     "sequence": "GPRPRRISELNMKEK",
     "pos": 848,
     "wtScore": 80.4819,
     "wtProb": 25.1,
     "k198rScore": 44.0335,
     "k198rProb": 11.4,
     "probDifference": -13.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 146
    },
    {
     "sequence": "PEGELSGSESGDSDG",
     "pos": 716,
     "wtScore": 92.2709,
     "wtProb": 29,
     "k198rScore": 49.8069,
     "k198rProb": 15.3,
     "probDifference": -13.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 147
    },
    {
     "sequence": "RLEGVESSEKLNSSF",
     "pos": 2062,
     "wtScore": 90.4361,
     "wtProb": 28,
     "k198rScore": 48.5832,
     "k198rProb": 14.4,
     "probDifference": -13.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 148
    },
    {
     "sequence": "KDGNGTTSGIGSSVE",
     "pos": 1075,
     "wtScore": 59.2802,
     "wtProb": 16.4,
     "k198rScore": 74.9626,
     "k198rProb": 29.9,
     "probDifference": 13.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 149
    },
    {
     "sequence": "KVQHDLNSGVFNNQE",
     "pos": 679,
     "wtScore": 61.8318,
     "wtProb": 18,
     "k198rScore": 76.2864,
     "k198rProb": 31.5,
     "probDifference": 13.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 150
    },
    {
     "sequence": "GTVNEDSSEIILDPK",
     "pos": 203,
     "wtScore": 78.8831,
     "wtProb": 25.2,
     "k198rScore": 44.7337,
     "k198rProb": 11.8,
     "probDifference": -13.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 151
    },
    {
     "sequence": "ALTGKGPSGQEKCKL",
     "pos": 825,
     "wtScore": 100.969,
     "wtProb": 32.1,
     "k198rScore": 100.407,
     "k198rProb": 45.5,
     "probDifference": 13.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 152
    },
    {
     "sequence": "GEQEYFSSEECYEDD",
     "pos": 1847,
     "wtScore": 92.0787,
     "wtProb": 28.8,
     "k198rScore": 49.9978,
     "k198rProb": 15.5,
     "probDifference": -13.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 153
    },
    {
     "sequence": "SVNTENVSGEGENRG",
     "pos": 479,
     "wtScore": 71.6254,
     "wtProb": 22.9,
     "k198rScore": 78.9149,
     "k198rProb": 36.2,
     "probDifference": 13.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 154
    },
    {
     "sequence": "LFGRLECSEDNPCEG",
     "pos": 1776,
     "wtScore": 103.807,
     "wtProb": 33.7,
     "k198rScore": 56.9498,
     "k198rProb": 20.4,
     "probDifference": -13.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 155
    },
    {
     "sequence": "SAESRDFSGAGGIGV",
     "pos": 474,
     "wtScore": 60.2708,
     "wtProb": 17.1,
     "k198rScore": 75.246,
     "k198rProb": 30.3,
     "probDifference": 13.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 156
    },
    {
     "sequence": "AILYYYQSGGRLRRP",
     "pos": 93,
     "wtScore": 60.6567,
     "wtProb": 17.3,
     "k198rScore": 75.5309,
     "k198rProb": 30.3,
     "probDifference": 13,
     "geneName": "KCNA2",
     "known": "No",
     "id": 157
    },
    {
     "sequence": "AILYYYQSGGRLRRP",
     "pos": 97,
     "wtScore": 60.6567,
     "wtProb": 17.3,
     "k198rScore": 75.5309,
     "k198rProb": 30.3,
     "probDifference": 13,
     "geneName": "KCNA1",
     "known": "No",
     "id": 158
    },
    {
     "sequence": "NHDTEACSEPSLLST",
     "pos": 1931,
     "wtScore": 87.9744,
     "wtProb": 26.9,
     "k198rScore": 48.0833,
     "k198rProb": 14,
     "probDifference": -12.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 159
    },
    {
     "sequence": "GMVNHSWSELYSFAL",
     "pos": 410,
     "wtScore": 79.6963,
     "wtProb": 25.2,
     "k198rScore": 45.5992,
     "k198rProb": 12.3,
     "probDifference": -12.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 160
    },
    {
     "sequence": "SIIDVALSEADPTES",
     "pos": 1286,
     "wtScore": 114.885,
     "wtProb": 35.2,
     "k198rScore": 66.0459,
     "k198rProb": 22.4,
     "probDifference": -12.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 161
    },
    {
     "sequence": "QYYNVCRSGDSNPHN",
     "pos": 348,
     "wtScore": 61.1699,
     "wtProb": 17.6,
     "k198rScore": 75.6107,
     "k198rProb": 30.4,
     "probDifference": 12.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 162
    },
    {
     "sequence": "SELGVSPSESPAAER",
     "pos": 50,
     "wtScore": 89.5721,
     "wtProb": 27.6,
     "k198rScore": 49.325,
     "k198rProb": 14.9,
     "probDifference": -12.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 163
    },
    {
     "sequence": "GRAKDVGSENDFADD",
     "pos": 585,
     "wtScore": 109.258,
     "wtProb": 34.9,
     "k198rScore": 63.4775,
     "k198rProb": 22.2,
     "probDifference": -12.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 164
    },
    {
     "sequence": "GRAKDVGSENDFADD",
     "pos": 588,
     "wtScore": 109.258,
     "wtProb": 34.9,
     "k198rScore": 63.4775,
     "k198rProb": 22.2,
     "probDifference": -12.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 165
    },
    {
     "sequence": "DEFKRIWSEYDPEAK",
     "pos": 1489,
     "wtScore": 109.295,
     "wtProb": 34.9,
     "k198rScore": 65.4214,
     "k198rProb": 22.2,
     "probDifference": -12.7,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 166
    },
    {
     "sequence": "GAASRQNSEEASLPG",
     "pos": 506,
     "wtScore": 87.4198,
     "wtProb": 26.6,
     "k198rScore": 48.069,
     "k198rProb": 14,
     "probDifference": -12.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 167
    },
    {
     "sequence": "AAELDDDSEDSCCLR",
     "pos": 1245,
     "wtScore": 109.626,
     "wtProb": 34.7,
     "k198rScore": 65.0569,
     "k198rProb": 22.1,
     "probDifference": -12.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 168
    },
    {
     "sequence": "GRAKDIGSENDFADD",
     "pos": 588,
     "wtScore": 109.405,
     "wtProb": 34.8,
     "k198rScore": 63.7593,
     "k198rProb": 22.1,
     "probDifference": -12.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 169
    },
    {
     "sequence": "GREAERGSELGVSPS",
     "pos": 43,
     "wtScore": 81.747,
     "wtProb": 25.4,
     "k198rScore": 46.2015,
     "k198rProb": 12.8,
     "probDifference": -12.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 170
    },
    {
     "sequence": "SEADPTESENVPVPT",
     "pos": 1293,
     "wtScore": 92.219,
     "wtProb": 29.1,
     "k198rScore": 50.9536,
     "k198rProb": 16.5,
     "probDifference": -12.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 171
    },
    {
     "sequence": "GERESLLSGEGKGST",
     "pos": 1167,
     "wtScore": 77.2351,
     "wtProb": 25.1,
     "k198rScore": 79.9667,
     "k198rProb": 37.6,
     "probDifference": 12.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 172
    },
    {
     "sequence": "PLIQVEQSEALDQVN",
     "pos": 2005,
     "wtScore": 94.3793,
     "wtProb": 29.7,
     "k198rScore": 51.9292,
     "k198rProb": 17.3,
     "probDifference": -12.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 173
    },
    {
     "sequence": "VVIATQFSETKQRES",
     "pos": 422,
     "wtScore": 83.7526,
     "wtProb": 25.3,
     "k198rScore": 46.4953,
     "k198rProb": 13,
     "probDifference": -12.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 174
    },
    {
     "sequence": "DSTLASFSEPGSCYE",
     "pos": 450,
     "wtScore": 84.3217,
     "wtProb": 25.5,
     "k198rScore": 46.8341,
     "k198rProb": 13.2,
     "probDifference": -12.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 175
    },
    {
     "sequence": "LNTEDFSSESDLEES",
     "pos": 1133,
     "wtScore": 100.314,
     "wtProb": 32,
     "k198rScore": 55.5914,
     "k198rProb": 19.8,
     "probDifference": -12.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 176
    },
    {
     "sequence": "NVPLDMFSEEIKFYE",
     "pos": 113,
     "wtScore": 91.6743,
     "wtProb": 28.7,
     "k198rScore": 51.0392,
     "k198rProb": 16.6,
     "probDifference": -12.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 177
    },
    {
     "sequence": "FSCRGAASGPAPGPG",
     "pos": 131,
     "wtScore": 63.5772,
     "wtProb": 19.2,
     "k198rScore": 76.1945,
     "k198rProb": 31.4,
     "probDifference": 12.2,
     "geneName": "HCN2",
     "known": "No",
     "id": 178
    },
    {
     "sequence": "LNTEDVSSESDPEGS",
     "pos": 1114,
     "wtScore": 101.306,
     "wtProb": 32.2,
     "k198rScore": 56.1763,
     "k198rProb": 20.2,
     "probDifference": -12.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 179
    },
    {
     "sequence": "PTATPGNSEESNRIS",
     "pos": 1306,
     "wtScore": 91.6706,
     "wtProb": 28.7,
     "k198rScore": 51.2684,
     "k198rProb": 16.8,
     "probDifference": -12,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 180
    },
    {
     "sequence": "VSVVNNYSECKALIE",
     "pos": 1383,
     "wtScore": 83.001,
     "wtProb": 25.2,
     "k198rScore": 46.9455,
     "k198rProb": 13.3,
     "probDifference": -12,
     "geneName": "SCN2A",
     "known": "No",
     "id": 181
    },
    {
     "sequence": "YPGRNIDSERPRGYH",
     "pos": 1877,
     "wtScore": 90.6448,
     "wtProb": 28.1,
     "k198rScore": 50.6476,
     "k198rProb": 16.2,
     "probDifference": -11.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 182
    },
    {
     "sequence": "PCAPWGPSGAWSSRR",
     "pos": 1134,
     "wtScore": 59.3893,
     "wtProb": 16.4,
     "k198rScore": 73.5968,
     "k198rProb": 28.2,
     "probDifference": 11.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 183
    },
    {
     "sequence": "IVEKGIDSEVYKTAR",
     "pos": 313,
     "wtScore": 104.515,
     "wtProb": 33.8,
     "k198rScore": 63.954,
     "k198rProb": 22,
     "probDifference": -11.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 184
    },
    {
     "sequence": "QDIYHAVSEVKKDSS",
     "pos": 871,
     "wtScore": 92.7039,
     "wtProb": 29.1,
     "k198rScore": 51.9309,
     "k198rProb": 17.3,
     "probDifference": -11.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 185
    },
    {
     "sequence": "LNTEEFSSESDMEES",
     "pos": 1123,
     "wtScore": 98.936,
     "wtProb": 31.4,
     "k198rScore": 55.6312,
     "k198rProb": 19.8,
     "probDifference": -11.6,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 186
    },
    {
     "sequence": "DEFQKSESEDSIRRK",
     "pos": 524,
     "wtScore": 103.787,
     "wtProb": 33.7,
     "k198rScore": 62.6693,
     "k198rProb": 22.2,
     "probDifference": -11.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 187
    },
    {
     "sequence": "VPLEPPESEPPMPVG",
     "pos": 2307,
     "wtScore": 84.7912,
     "wtProb": 25.7,
     "k198rScore": 48.3979,
     "k198rProb": 14.2,
     "probDifference": -11.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 188
    },
    {
     "sequence": "TEEFSSESELEESKE",
     "pos": 1123,
     "wtScore": 138.376,
     "wtProb": 34.2,
     "k198rScore": 87.566,
     "k198rProb": 45.7,
     "probDifference": 11.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 189
    },
    {
     "sequence": "LPRGGPSSGKVQATP",
     "pos": 726,
     "wtScore": 60.4953,
     "wtProb": 17.2,
     "k198rScore": 73.91,
     "k198rProb": 28.7,
     "probDifference": 11.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 190
    },
    {
     "sequence": "DRVRKSESEDSIRRK",
     "pos": 527,
     "wtScore": 102.91,
     "wtProb": 33.4,
     "k198rScore": 63.7727,
     "k198rProb": 22.1,
     "probDifference": -11.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 191
    },
    {
     "sequence": "DTDPFTPSGSMPLSS",
     "pos": 847,
     "wtScore": 47.8056,
     "wtProb": 10,
     "k198rScore": 59.532,
     "k198rProb": 21.3,
     "probDifference": 11.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 192
    },
    {
     "sequence": "LVEAVLISEGLGRYA",
     "pos": 2067,
     "wtScore": 88.6337,
     "wtProb": 27,
     "k198rScore": 50.2208,
     "k198rProb": 15.8,
     "probDifference": -11.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 193
    },
    {
     "sequence": "LDKEHPGSGFKGDCG",
     "pos": 1729,
     "wtScore": 61.1592,
     "wtProb": 17.6,
     "k198rScore": 74.1257,
     "k198rProb": 28.9,
     "probDifference": 11.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 194
    },
    {
     "sequence": "IKEYEKSSEGALYIL",
     "pos": 121,
     "wtScore": 91.6557,
     "wtProb": 28.7,
     "k198rScore": 52.1356,
     "k198rProb": 17.5,
     "probDifference": -11.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 195
    },
    {
     "sequence": "AYGGPSSSGMIVCIY",
     "pos": 723,
     "wtScore": 44.532,
     "wtProb": 9.2,
     "k198rScore": 56.6755,
     "k198rProb": 20.4,
     "probDifference": 11.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 196
    },
    {
     "sequence": "NVPLDIFSEEIRFYE",
     "pos": 109,
     "wtScore": 95.7176,
     "wtProb": 30.3,
     "k198rScore": 54.0845,
     "k198rProb": 19.1,
     "probDifference": -11.2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 197
    },
    {
     "sequence": "EHSTFEDSESRRDSL",
     "pos": 603,
     "wtScore": 94.8435,
     "wtProb": 30,
     "k198rScore": 53.8697,
     "k198rProb": 18.8,
     "probDifference": -11.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 198
    },
    {
     "sequence": "GALLIAGSEAPKRGS",
     "pos": 44,
     "wtScore": 90.7225,
     "wtProb": 28.2,
     "k198rScore": 51.6815,
     "k198rProb": 17.1,
     "probDifference": -11.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 199
    },
    {
     "sequence": "CEDVECGSERCNILE",
     "pos": 129,
     "wtScore": 92.3349,
     "wtProb": 29,
     "k198rScore": 52.6094,
     "k198rProb": 17.9,
     "probDifference": -11.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 200
    },
    {
     "sequence": "GPAEEAGSEEAGPAG",
     "pos": 145,
     "wtScore": 102.702,
     "wtProb": 33.2,
     "k198rScore": 62.1943,
     "k198rProb": 22.2,
     "probDifference": -11,
     "geneName": "HCN2",
     "known": "No",
     "id": 201
    },
    {
     "sequence": "LVEAVLISEGLGQFA",
     "pos": 2125,
     "wtScore": 91.5999,
     "wtProb": 28.7,
     "k198rScore": 52.5003,
     "k198rProb": 17.8,
     "probDifference": -10.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 202
    },
    {
     "sequence": "HYCFNETSEIRFEIE",
     "pos": 1360,
     "wtScore": 84.2081,
     "wtProb": 25.5,
     "k198rScore": 48.9776,
     "k198rProb": 14.7,
     "probDifference": -10.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 203
    },
    {
     "sequence": "DSFPKSESEDSVKRS",
     "pos": 527,
     "wtScore": 101.899,
     "wtProb": 32.7,
     "k198rScore": 60.8291,
     "k198rProb": 21.9,
     "probDifference": -10.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 204
    },
    {
     "sequence": "KWTKRTLSETSSSKS",
     "pos": 495,
     "wtScore": 94.3541,
     "wtProb": 29.7,
     "k198rScore": 54.1132,
     "k198rProb": 19,
     "probDifference": -10.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 205
    },
    {
     "sequence": "NYPTILPSGVGSGKG",
     "pos": 618,
     "wtScore": 51.7179,
     "wtProb": 11.4,
     "k198rScore": 62.9618,
     "k198rProb": 22.2,
     "probDifference": 10.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 206
    },
    {
     "sequence": "MVETDDQSEYVTTIL",
     "pos": 1565,
     "wtScore": 91.5398,
     "wtProb": 28.7,
     "k198rScore": 52.8669,
     "k198rProb": 18.1,
     "probDifference": -10.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 207
    },
    {
     "sequence": "IVEKGMDSEVYKTAR",
     "pos": 244,
     "wtScore": 101.261,
     "wtProb": 32.2,
     "k198rScore": 60.4646,
     "k198rProb": 21.6,
     "probDifference": -10.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 208
    },
    {
     "sequence": "LDPGAPDSTRDGALL",
     "pos": 33,
     "wtScore": 66.9381,
     "wtProb": 20.9,
     "k198rScore": 42.1129,
     "k198rProb": 10.4,
     "probDifference": -10.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 209
    },
    {
     "sequence": "RCTDEAKSNPEECRG",
     "pos": 1049,
     "wtScore": 110.282,
     "wtProb": 35.1,
     "k198rScore": 87.3076,
     "k198rProb": 45.6,
     "probDifference": 10.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 210
    },
    {
     "sequence": "EKVFKSESEDGMRRK",
     "pos": 519,
     "wtScore": 101.786,
     "wtProb": 32.6,
     "k198rScore": 63.6708,
     "k198rProb": 22.1,
     "probDifference": -10.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 211
    },
    {
     "sequence": "LLAPILNSGPPDCDP",
     "pos": 1725,
     "wtScore": 57.3086,
     "wtProb": 14.9,
     "k198rScore": 70.622,
     "k198rProb": 25.4,
     "probDifference": 10.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 212
    },
    {
     "sequence": "KLNATSSSEGSTVDI",
     "pos": 1140,
     "wtScore": 91.9818,
     "wtProb": 28.8,
     "k198rScore": 53.2143,
     "k198rProb": 18.3,
     "probDifference": -10.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 213
    },
    {
     "sequence": "RSSYSGYSGYSQGSR",
     "pos": 617,
     "wtScore": 64.9914,
     "wtProb": 20,
     "k198rScore": 75.728,
     "k198rProb": 30.5,
     "probDifference": 10.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 214
    },
    {
     "sequence": "PESRASSSGAIVPLE",
     "pos": 2296,
     "wtScore": 54.9102,
     "wtProb": 13.4,
     "k198rScore": 68.6162,
     "k198rProb": 23.8,
     "probDifference": 10.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 215
    },
    {
     "sequence": "KLNATSSSEGSTVDV",
     "pos": 1138,
     "wtScore": 92.4194,
     "wtProb": 29,
     "k198rScore": 53.5804,
     "k198rProb": 18.6,
     "probDifference": -10.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 216
    },
    {
     "sequence": "LMKHLEESNKEARED",
     "pos": 1865,
     "wtScore": 111.694,
     "wtProb": 35,
     "k198rScore": 87.6283,
     "k198rProb": 45.4,
     "probDifference": 10.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 217
    },
    {
     "sequence": "QWPPTNASLEEHSIE",
     "pos": 285,
     "wtScore": 92.568,
     "wtProb": 29,
     "k198rScore": 81.5169,
     "k198rProb": 39.3,
     "probDifference": 10.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 218
    },
    {
     "sequence": "EESAEPLSEDDFEMF",
     "pos": 1790,
     "wtScore": 121.911,
     "wtProb": 34.6,
     "k198rScore": 69.5328,
     "k198rProb": 24.4,
     "probDifference": -10.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 219
    },
    {
     "sequence": "EESAEPLSEDDFEMF",
     "pos": 1800,
     "wtScore": 121.911,
     "wtProb": 34.6,
     "k198rScore": 69.5328,
     "k198rProb": 24.4,
     "probDifference": -10.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 220
    },
    {
     "sequence": "EESAEPLSEDDFEMF",
     "pos": 1785,
     "wtScore": 121.911,
     "wtProb": 34.6,
     "k198rScore": 69.5328,
     "k198rProb": 24.4,
     "probDifference": -10.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 221
    },
    {
     "sequence": "IFMILLSSGALAFED",
     "pos": 1211,
     "wtScore": 46.3368,
     "wtProb": 9.6,
     "k198rScore": 55.6692,
     "k198rProb": 19.8,
     "probDifference": 10.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 222
    },
    {
     "sequence": "QEKPERPSAYEEEIE",
     "pos": 567,
     "wtScore": 105.469,
     "wtProb": 33.8,
     "k198rScore": 89.7144,
     "k198rProb": 44.1,
     "probDifference": 10.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 223
    },
    {
     "sequence": "AYAFWQSSEDAGTGD",
     "pos": 486,
     "wtScore": 83.4977,
     "wtProb": 25.2,
     "k198rScore": 49.4421,
     "k198rProb": 15,
     "probDifference": -10.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 224
    },
    {
     "sequence": "EKFEKDKSEKEDKGK",
     "pos": 1990,
     "wtScore": 148.381,
     "wtProb": 33.5,
     "k198rScore": 94.7231,
     "k198rProb": 43.7,
     "probDifference": 10.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 225
    },
    {
     "sequence": "LNESSSSSEGSTVDI",
     "pos": 1151,
     "wtScore": 90.4328,
     "wtProb": 28,
     "k198rScore": 52.6999,
     "k198rProb": 17.9,
     "probDifference": -10.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 226
    },
    {
     "sequence": "DLELRRDSGSAGTQA",
     "pos": 1878,
     "wtScore": 58.0366,
     "wtProb": 15.4,
     "k198rScore": 70.9586,
     "k198rProb": 25.5,
     "probDifference": 10.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 227
    },
    {
     "sequence": "ATSAGTVSEDAIEEE",
     "pos": 458,
     "wtScore": 101.193,
     "wtProb": 32.2,
     "k198rScore": 61.6578,
     "k198rProb": 22.2,
     "probDifference": -10.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 228
    },
    {
     "sequence": "AVSSPARSGEPLHAL",
     "pos": 1990,
     "wtScore": 63.8744,
     "wtProb": 19.4,
     "k198rScore": 74.6132,
     "k198rProb": 29.4,
     "probDifference": 10,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 229
    },
    {
     "sequence": "PIIVNNFSEFYKEQK",
     "pos": 416,
     "wtScore": 89.7466,
     "wtProb": 27.7,
     "k198rScore": 52.3481,
     "k198rProb": 17.7,
     "probDifference": -10,
     "geneName": "KCNB1",
     "known": "No",
     "id": 230
    },
    {
     "sequence": "PIIVNNFSEFYKEQK",
     "pos": 420,
     "wtScore": 89.7466,
     "wtProb": 27.7,
     "k198rScore": 52.3481,
     "k198rProb": 17.7,
     "probDifference": -10,
     "geneName": "KCNB2",
     "known": "No",
     "id": 231
    },
    {
     "sequence": "KWARKALSETSSNKS",
     "pos": 499,
     "wtScore": 99.0454,
     "wtProb": 31.5,
     "k198rScore": 59.8866,
     "k198rProb": 21.4,
     "probDifference": -10,
     "geneName": "KCNB2",
     "known": "No",
     "id": 232
    },
    {
     "sequence": "SSSSSEGSTVDIGAP",
     "pos": 1154,
     "wtScore": 69.3633,
     "wtProb": 22,
     "k198rScore": 45.0557,
     "k198rProb": 12,
     "probDifference": -10,
     "geneName": "SCN1A",
     "known": "No",
     "id": 233
    },
    {
     "sequence": "VFMILLSSGALAFED",
     "pos": 1221,
     "wtScore": 45.9047,
     "wtProb": 9.5,
     "k198rScore": 54.8973,
     "k198rProb": 19.5,
     "probDifference": 10,
     "geneName": "SCN2A",
     "known": "No",
     "id": 234
    },
    {
     "sequence": "VFMILLSSGALAFED",
     "pos": 1219,
     "wtScore": 45.9047,
     "wtProb": 9.5,
     "k198rScore": 54.8973,
     "k198rProb": 19.5,
     "probDifference": 10,
     "geneName": "SCN3A",
     "known": "No",
     "id": 235
    },
    {
     "sequence": "VFMILLSSGALAFED",
     "pos": 1231,
     "wtScore": 45.9047,
     "wtProb": 9.5,
     "k198rScore": 54.8973,
     "k198rProb": 19.5,
     "probDifference": 10,
     "geneName": "SCN1A",
     "known": "No",
     "id": 236
    },
    {
     "sequence": "EHSTVEESEGRRDSL",
     "pos": 593,
     "wtScore": 101.358,
     "wtProb": 32.3,
     "k198rScore": 65.4605,
     "k198rProb": 22.3,
     "probDifference": -10,
     "geneName": "SCN8A",
     "known": "No",
     "id": 237
    },
    {
     "sequence": "TSCPKIPSSPDLKKS",
     "pos": 439,
     "wtScore": 68.0696,
     "wtProb": 21.5,
     "k198rScore": 44.3156,
     "k198rProb": 11.5,
     "probDifference": -10,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 238
    },
    {
     "sequence": "LLYKAIDSNGENIGP",
     "pos": 1112,
     "wtScore": 111.353,
     "wtProb": 35,
     "k198rScore": 90.771,
     "k198rProb": 45,
     "probDifference": 10,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 239
    },
    {
     "sequence": "GRLWVTFSGKLRRIV",
     "pos": 782,
     "wtScore": 53.1426,
     "wtProb": 12.2,
     "k198rScore": 63.5381,
     "k198rProb": 22.2,
     "probDifference": 9.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 240
    },
    {
     "sequence": "ILPSGVGSGKGSTSP",
     "pos": 622,
     "wtScore": 65.052,
     "wtProb": 20.1,
     "k198rScore": 75.0698,
     "k198rProb": 30,
     "probDifference": 9.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 241
    },
    {
     "sequence": "ASKLSSKSAKEWRNR",
     "pos": 494,
     "wtScore": 112.21,
     "wtProb": 35.2,
     "k198rScore": 88.5088,
     "k198rProb": 45,
     "probDifference": 9.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 242
    },
    {
     "sequence": "PLDLGVPSGDPFLDG",
     "pos": 2276,
     "wtScore": 67.041,
     "wtProb": 21,
     "k198rScore": 75.892,
     "k198rProb": 30.8,
     "probDifference": 9.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 243
    },
    {
     "sequence": "LFEYPESSGPARIIA",
     "pos": 158,
     "wtScore": 56.7607,
     "wtProb": 14.6,
     "k198rScore": 69.549,
     "k198rProb": 24.3,
     "probDifference": 9.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 244
    },
    {
     "sequence": "KNDRVRKSESEDSIR",
     "pos": 525,
     "wtScore": 136.263,
     "wtProb": 34.4,
     "k198rScore": 89.5827,
     "k198rProb": 44.2,
     "probDifference": 9.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 245
    },
    {
     "sequence": "QAATATASEHSREPS",
     "pos": 466,
     "wtScore": 89.1885,
     "wtProb": 27.4,
     "k198rScore": 52.4298,
     "k198rProb": 17.7,
     "probDifference": -9.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 246
    },
    {
     "sequence": "DKQRSADSLVEAVLI",
     "pos": 2059,
     "wtScore": 97.0279,
     "wtProb": 30.3,
     "k198rScore": 83.1904,
     "k198rProb": 40,
     "probDifference": 9.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 247
    },
    {
     "sequence": "DEDEFQKSESEDSIR",
     "pos": 522,
     "wtScore": 141.044,
     "wtProb": 33.9,
     "k198rScore": 91.3111,
     "k198rProb": 43.4,
     "probDifference": 9.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 248
    },
    {
     "sequence": "RNTSMPTSETESVNT",
     "pos": 468,
     "wtScore": 123.606,
     "wtProb": 34.4,
     "k198rScore": 70.3344,
     "k198rProb": 24.9,
     "probDifference": -9.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 249
    },
    {
     "sequence": "RNMSMPTSETESVNT",
     "pos": 468,
     "wtScore": 123.863,
     "wtProb": 34.4,
     "k198rScore": 70.3374,
     "k198rProb": 24.9,
     "probDifference": -9.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 250
    },
    {
     "sequence": "SSEISKLSSKSAKER",
     "pos": 483,
     "wtScore": 65.4783,
     "wtProb": 20.4,
     "k198rScore": 43.1555,
     "k198rProb": 10.9,
     "probDifference": -9.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 251
    },
    {
     "sequence": "ATSSSEGSTVDVVLP",
     "pos": 1141,
     "wtScore": 70.0711,
     "wtProb": 22.4,
     "k198rScore": 46.3486,
     "k198rProb": 12.9,
     "probDifference": -9.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 252
    },
    {
     "sequence": "DNLADAESLNTAQKE",
     "pos": 759,
     "wtScore": 47.6963,
     "wtProb": 10,
     "k198rScore": 54.8183,
     "k198rProb": 19.4,
     "probDifference": 9.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 253
    },
    {
     "sequence": "TGTVNEDSSEIILDP",
     "pos": 202,
     "wtScore": 65.1816,
     "wtProb": 20.2,
     "k198rScore": 42.8389,
     "k198rProb": 10.8,
     "probDifference": -9.4,
     "geneName": "HCN1",
     "known": "No",
     "id": 254
    },
    {
     "sequence": "SSEASKLSSKSAKER",
     "pos": 489,
     "wtScore": 66.063,
     "wtProb": 20.7,
     "k198rScore": 43.826,
     "k198rProb": 11.3,
     "probDifference": -9.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 255
    },
    {
     "sequence": "GQAPGHLSGLSVPCP",
     "pos": 676,
     "wtScore": 63.2115,
     "wtProb": 18.9,
     "k198rScore": 73.59,
     "k198rProb": 28.2,
     "probDifference": 9.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 256
    },
    {
     "sequence": "ATSSSEGSTVDIGAP",
     "pos": 1143,
     "wtScore": 68.9404,
     "wtProb": 21.9,
     "k198rScore": 46.0347,
     "k198rProb": 12.7,
     "probDifference": -9.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 257
    },
    {
     "sequence": "PANGKMHSTVDCNGV",
     "pos": 642,
     "wtScore": 61.7652,
     "wtProb": 18,
     "k198rScore": 37.6719,
     "k198rProb": 8.7,
     "probDifference": -9.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 258
    },
    {
     "sequence": "PANGKMHSTVDCNGV",
     "pos": 645,
     "wtScore": 61.7652,
     "wtProb": 18,
     "k198rScore": 37.6719,
     "k198rProb": 8.7,
     "probDifference": -9.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 259
    },
    {
     "sequence": "ANGTECRSGWVGPNG",
     "pos": 334,
     "wtScore": 56.9535,
     "wtProb": 14.7,
     "k198rScore": 68.9852,
     "k198rProb": 23.9,
     "probDifference": 9.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 260
    },
    {
     "sequence": "KTIICNYSETGPPEP",
     "pos": 687,
     "wtScore": 86.4516,
     "wtProb": 26.3,
     "k198rScore": 51.6597,
     "k198rProb": 17.1,
     "probDifference": -9.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 261
    },
    {
     "sequence": "SSVASKLSSKSEKEL",
     "pos": 493,
     "wtScore": 63.4449,
     "wtProb": 19.1,
     "k198rScore": 41.2587,
     "k198rProb": 10,
     "probDifference": -9.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 262
    },
    {
     "sequence": "KLDDTSSSEGSTIDI",
     "pos": 1131,
     "wtScore": 93.6429,
     "wtProb": 29.5,
     "k198rScore": 57.3488,
     "k198rProb": 20.4,
     "probDifference": -9.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 263
    },
    {
     "sequence": "LFEYPESSGPARVIA",
     "pos": 162,
     "wtScore": 57.3685,
     "wtProb": 14.9,
     "k198rScore": 69.0826,
     "k198rProb": 24,
     "probDifference": 9.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 264
    },
    {
     "sequence": "ENEDMHGSGVTFHTY",
     "pos": 197,
     "wtScore": 60.5364,
     "wtProb": 17.3,
     "k198rScore": 71.7034,
     "k198rProb": 26.2,
     "probDifference": 8.9,
     "geneName": "KCNA2",
     "known": "No",
     "id": 265
    },
    {
     "sequence": "TEGGNHSSGKSGGFD",
     "pos": 227,
     "wtScore": 55.8662,
     "wtProb": 14,
     "k198rScore": 66.4915,
     "k198rProb": 22.8,
     "probDifference": 8.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 266
    },
    {
     "sequence": "KDVNGTTSGIGTGSS",
     "pos": 1083,
     "wtScore": 58.5003,
     "wtProb": 15.7,
     "k198rScore": 69.9579,
     "k198rProb": 24.5,
     "probDifference": 8.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 267
    },
    {
     "sequence": "LRNLKSKSGLAFRKD",
     "pos": 400,
     "wtScore": 60.8006,
     "wtProb": 17.4,
     "k198rScore": 71.6982,
     "k198rProb": 26.2,
     "probDifference": 8.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 268
    },
    {
     "sequence": "ISKLSSKSAKERRNR",
     "pos": 486,
     "wtScore": 113.825,
     "wtProb": 35.5,
     "k198rScore": 90.0704,
     "k198rProb": 44.3,
     "probDifference": 8.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 269
    },
    {
     "sequence": "DNLADAESLTSAQKE",
     "pos": 760,
     "wtScore": 44.9168,
     "wtProb": 9.3,
     "k198rScore": 52.8344,
     "k198rProb": 18,
     "probDifference": 8.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 270
    },
    {
     "sequence": "GGVYPGPSGEKKLKV",
     "pos": 14,
     "wtScore": 57.4099,
     "wtProb": 15,
     "k198rScore": 68.7067,
     "k198rProb": 23.7,
     "probDifference": 8.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 271
    },
    {
     "sequence": "LRPLRLVSGVPSLQV",
     "pos": 248,
     "wtScore": 54.8916,
     "wtProb": 13.4,
     "k198rScore": 64.1439,
     "k198rProb": 22.1,
     "probDifference": 8.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 272
    },
    {
     "sequence": "LRPLRLVSGVPSLQV",
     "pos": 251,
     "wtScore": 54.8916,
     "wtProb": 13.4,
     "k198rScore": 64.1439,
     "k198rProb": 22.1,
     "probDifference": 8.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 273
    },
    {
     "sequence": "ERVVINISGLRFETQ",
     "pos": 43,
     "wtScore": 57.5821,
     "wtProb": 15.1,
     "k198rScore": 68.56,
     "k198rProb": 23.8,
     "probDifference": 8.7,
     "geneName": "KCNA1",
     "known": "No",
     "id": 274
    },
    {
     "sequence": "ERVVINISGLRFETQ",
     "pos": 39,
     "wtScore": 57.5821,
     "wtProb": 15.1,
     "k198rScore": 68.56,
     "k198rProb": 23.8,
     "probDifference": 8.7,
     "geneName": "KCNA2",
     "known": "No",
     "id": 275
    },
    {
     "sequence": "SRRGSSSSGDPPLGD",
     "pos": 1109,
     "wtScore": 60.3654,
     "wtProb": 17.2,
     "k198rScore": 71.2486,
     "k198rProb": 25.8,
     "probDifference": 8.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 276
    },
    {
     "sequence": "SSSTMSKSEYMEIEE",
     "pos": 451,
     "wtScore": 84.0483,
     "wtProb": 25.4,
     "k198rScore": 51.2704,
     "k198rProb": 16.8,
     "probDifference": -8.7,
     "geneName": "KCNA1",
     "known": "No",
     "id": 277
    },
    {
     "sequence": "AYVTEFVSLGNVSAL",
     "pos": 207,
     "wtScore": 46.5219,
     "wtProb": 9.6,
     "k198rScore": 52.9975,
     "k198rProb": 18.2,
     "probDifference": 8.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 278
    },
    {
     "sequence": "ASKLSSKSAKERRNR",
     "pos": 492,
     "wtScore": 113.626,
     "wtProb": 35.5,
     "k198rScore": 89.8101,
     "k198rProb": 44.1,
     "probDifference": 8.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 279
    },
    {
     "sequence": "CSEPSLLSTEMLSYQ",
     "pos": 1937,
     "wtScore": 59.2858,
     "wtProb": 16.4,
     "k198rScore": 32.3108,
     "k198rProb": 7.8,
     "probDifference": -8.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 280
    },
    {
     "sequence": "KTSRSTLSLPPEPVD",
     "pos": 18,
     "wtScore": 51.2537,
     "wtProb": 11.2,
     "k198rScore": 55.4575,
     "k198rProb": 19.7,
     "probDifference": 8.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 281
    },
    {
     "sequence": "LGELLESSSEASKLS",
     "pos": 484,
     "wtScore": 60.2306,
     "wtProb": 17.1,
     "k198rScore": 37.2775,
     "k198rProb": 8.6,
     "probDifference": -8.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 282
    },
    {
     "sequence": "ITVNLDASGSQCGLH",
     "pos": 680,
     "wtScore": 56.625,
     "wtProb": 14.5,
     "k198rScore": 66.6854,
     "k198rProb": 22.9,
     "probDifference": 8.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 283
    },
    {
     "sequence": "KFSTGTRSEKNHFES",
     "pos": 791,
     "wtScore": 92.795,
     "wtProb": 29.1,
     "k198rScore": 57.7613,
     "k198rProb": 20.7,
     "probDifference": -8.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 284
    },
    {
     "sequence": "RHSTGLQSLGFTLRR",
     "pos": 322,
     "wtScore": 55.5956,
     "wtProb": 13.8,
     "k198rScore": 63.1827,
     "k198rProb": 22.1,
     "probDifference": 8.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 285
    },
    {
     "sequence": "RHSTGLQSLGFTLRR",
     "pos": 318,
     "wtScore": 55.5956,
     "wtProb": 13.8,
     "k198rScore": 63.1827,
     "k198rProb": 22.1,
     "probDifference": 8.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 286
    },
    {
     "sequence": "ALLLSSFSSDNLAAT",
     "pos": 986,
     "wtScore": 61.1188,
     "wtProb": 17.6,
     "k198rScore": 39.5152,
     "k198rProb": 9.3,
     "probDifference": -8.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 287
    },
    {
     "sequence": "ALLLSSFSSDNLAAT",
     "pos": 987,
     "wtScore": 61.1188,
     "wtProb": 17.6,
     "k198rScore": 39.5152,
     "k198rProb": 9.3,
     "probDifference": -8.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 288
    },
    {
     "sequence": "RQNSEEASLPGEDIV",
     "pos": 510,
     "wtScore": 53.2047,
     "wtProb": 12.3,
     "k198rScore": 57.2285,
     "k198rProb": 20.5,
     "probDifference": 8.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 289
    },
    {
     "sequence": "DALIVVGSIVDIAIT",
     "pos": 1341,
     "wtScore": 64.754,
     "wtProb": 19.9,
     "k198rScore": 44.591,
     "k198rProb": 11.7,
     "probDifference": -8.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 290
    },
    {
     "sequence": "EHYNQPKSLDEALKY",
     "pos": 1643,
     "wtScore": 92.8278,
     "wtProb": 29.1,
     "k198rScore": 79.8563,
     "k198rProb": 37.3,
     "probDifference": 8.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 291
    },
    {
     "sequence": "DPEKVFKSESEDGMR",
     "pos": 517,
     "wtScore": 136.745,
     "wtProb": 34.3,
     "k198rScore": 85.0391,
     "k198rProb": 42.3,
     "probDifference": 8.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 292
    },
    {
     "sequence": "EATPHRDSLEPTEGS",
     "pos": 2230,
     "wtScore": 50.0139,
     "wtProb": 10.7,
     "k198rScore": 53.8455,
     "k198rProb": 18.8,
     "probDifference": 8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 293
    },
    {
     "sequence": "DSLIVIGSIIDVALS",
     "pos": 1279,
     "wtScore": 62.5211,
     "wtProb": 18.5,
     "k198rScore": 42.2278,
     "k198rProb": 10.5,
     "probDifference": -8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 294
    },
    {
     "sequence": "LSYLPALSPVYFVTF",
     "pos": 1835,
     "wtScore": 63.238,
     "wtProb": 19,
     "k198rScore": 43.2134,
     "k198rProb": 11,
     "probDifference": -8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 295
    },
    {
     "sequence": "GKKCAPESEPSNSTE",
     "pos": 1482,
     "wtScore": 92.7313,
     "wtProb": 29.1,
     "k198rScore": 58.8158,
     "k198rProb": 21.1,
     "probDifference": -8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 296
    },
    {
     "sequence": "EKLNESSSSSEGSTV",
     "pos": 1149,
     "wtScore": 99.6549,
     "wtProb": 31.7,
     "k198rScore": 68.585,
     "k198rProb": 23.8,
     "probDifference": -7.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 297
    },
    {
     "sequence": "AGRLSDSSSEASKLS",
     "pos": 482,
     "wtScore": 59.4786,
     "wtProb": 16.5,
     "k198rScore": 36.9118,
     "k198rProb": 8.5,
     "probDifference": -7.9,
     "geneName": "SCN1A",
     "known": "Yes",
     "id": 298
    },
    {
     "sequence": "LRLEGVESSEKLNSS",
     "pos": 2061,
     "wtScore": 67.5339,
     "wtProb": 21.3,
     "k198rScore": 47.1774,
     "k198rProb": 13.3,
     "probDifference": -7.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 299
    },
    {
     "sequence": "KQKLFPFSSRERRSF",
     "pos": 796,
     "wtScore": 101.327,
     "wtProb": 32.2,
     "k198rScore": 69.7368,
     "k198rProb": 24.4,
     "probDifference": -7.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 300
    },
    {
     "sequence": "EKPAVGESKEEKIEL",
     "pos": 798,
     "wtScore": 109.169,
     "wtProb": 34.9,
     "k198rScore": 86.2534,
     "k198rProb": 42.7,
     "probDifference": 7.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 301
    },
    {
     "sequence": "SSEASKLSSKSAKEW",
     "pos": 491,
     "wtScore": 60.5876,
     "wtProb": 17.3,
     "k198rScore": 40.0227,
     "k198rProb": 9.5,
     "probDifference": -7.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 302
    },
    {
     "sequence": "DGIIVVISVWEIVGQ",
     "pos": 867,
     "wtScore": 95.6704,
     "wtProb": 30.3,
     "k198rScore": 66.1804,
     "k198rProb": 22.5,
     "probDifference": -7.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 303
    },
    {
     "sequence": "NTLPELQSLDEFGQS",
     "pos": 215,
     "wtScore": 93.6439,
     "wtProb": 29.5,
     "k198rScore": 79.6896,
     "k198rProb": 37.3,
     "probDifference": 7.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 304
    },
    {
     "sequence": "VAGVITKSSKEMKLT",
     "pos": 525,
     "wtScore": 98.9898,
     "wtProb": 31.4,
     "k198rScore": 68.4588,
     "k198rProb": 23.6,
     "probDifference": -7.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 305
    },
    {
     "sequence": "TLFRQMSSGAIPPNR",
     "pos": 846,
     "wtScore": 58.2536,
     "wtProb": 15.5,
     "k198rScore": 67.3838,
     "k198rProb": 23.3,
     "probDifference": 7.8,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 306
    },
    {
     "sequence": "EGGGSPRSSSEISKL",
     "pos": 475,
     "wtScore": 95.0637,
     "wtProb": 30.1,
     "k198rScore": 65.9071,
     "k198rProb": 22.4,
     "probDifference": -7.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 307
    },
    {
     "sequence": "LSRTDLHSTWQYYER",
     "pos": 357,
     "wtScore": 59.1639,
     "wtProb": 16.3,
     "k198rScore": 37.0263,
     "k198rProb": 8.6,
     "probDifference": -7.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 308
    },
    {
     "sequence": "EKKESTPSTASLPSY",
     "pos": 1939,
     "wtScore": 58.3089,
     "wtProb": 15.6,
     "k198rScore": 33.6375,
     "k198rProb": 8,
     "probDifference": -7.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 309
    },
    {
     "sequence": "LPAGILGSGFALKVQ",
     "pos": 313,
     "wtScore": 58.9854,
     "wtProb": 16.1,
     "k198rScore": 67.8348,
     "k198rProb": 23.8,
     "probDifference": 7.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 310
    },
    {
     "sequence": "ASKLSSKSEKELKNR",
     "pos": 496,
     "wtScore": 142.544,
     "wtProb": 33.7,
     "k198rScore": 84.2151,
     "k198rProb": 41.3,
     "probDifference": 7.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 311
    },
    {
     "sequence": "KEKLNESSSSSEGST",
     "pos": 1148,
     "wtScore": 63.8236,
     "wtProb": 19.4,
     "k198rScore": 44.6329,
     "k198rProb": 11.8,
     "probDifference": -7.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 312
    },
    {
     "sequence": "IDKLNGNSTPEKTDG",
     "pos": 1952,
     "wtScore": 97.2689,
     "wtProb": 30.6,
     "k198rScore": 66.7742,
     "k198rProb": 23,
     "probDifference": -7.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 313
    },
    {
     "sequence": "YYQSDGRSAFPQTFT",
     "pos": 1763,
     "wtScore": 52.5101,
     "wtProb": 11.9,
     "k198rScore": 54.7744,
     "k198rProb": 19.4,
     "probDifference": 7.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 314
    },
    {
     "sequence": "RKALSETSSNKSFEN",
     "pos": 502,
     "wtScore": 62.2483,
     "wtProb": 18.3,
     "k198rScore": 42.6859,
     "k198rProb": 10.7,
     "probDifference": -7.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 315
    },
    {
     "sequence": "SSKQTVLSWQAAIDA",
     "pos": 51,
     "wtScore": 60.2969,
     "wtProb": 17.1,
     "k198rScore": 40.4346,
     "k198rProb": 9.6,
     "probDifference": -7.5,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 316
    },
    {
     "sequence": "TSGIGTGSSVEKYII",
     "pos": 1089,
     "wtScore": 100.916,
     "wtProb": 32.1,
     "k198rScore": 70.0202,
     "k198rProb": 24.7,
     "probDifference": -7.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 317
    },
    {
     "sequence": "LQWPPSDSAFETNTT",
     "pos": 284,
     "wtScore": 107.078,
     "wtProb": 34.2,
     "k198rScore": 85.3023,
     "k198rProb": 41.6,
     "probDifference": 7.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 318
    },
    {
     "sequence": "KEKLNATSSSEGSTV",
     "pos": 1136,
     "wtScore": 104.782,
     "wtProb": 33.8,
     "k198rScore": 71.8732,
     "k198rProb": 26.4,
     "probDifference": -7.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 319
    },
    {
     "sequence": "KEKLNATSSSEGSTV",
     "pos": 1138,
     "wtScore": 104.782,
     "wtProb": 33.8,
     "k198rScore": 71.8732,
     "k198rProb": 26.4,
     "probDifference": -7.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 320
    },
    {
     "sequence": "INGMVNHSWSELYSF",
     "pos": 408,
     "wtScore": 93.5573,
     "wtProb": 29.5,
     "k198rScore": 64.2139,
     "k198rProb": 22.1,
     "probDifference": -7.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 321
    },
    {
     "sequence": "KAYAFWQSSEDAGTG",
     "pos": 485,
     "wtScore": 75.4821,
     "wtProb": 24.7,
     "k198rScore": 51.873,
     "k198rProb": 17.3,
     "probDifference": -7.4,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 322
    },
    {
     "sequence": "KKIARKESLENKKNN",
     "pos": 781,
     "wtScore": 54.9792,
     "wtProb": 13.4,
     "k198rScore": 57.9926,
     "k198rProb": 20.8,
     "probDifference": 7.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 323
    },
    {
     "sequence": "ERDSFPKSESEDSVK",
     "pos": 525,
     "wtScore": 135.216,
     "wtProb": 34.5,
     "k198rScore": 84.9577,
     "k198rProb": 41.8,
     "probDifference": 7.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 324
    },
    {
     "sequence": "LQWPPDNSSFEINIT",
     "pos": 285,
     "wtScore": 99.6679,
     "wtProb": 31.8,
     "k198rScore": 69.89,
     "k198rProb": 24.5,
     "probDifference": -7.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 325
    },
    {
     "sequence": "SLDEFGQSTDNPQLA",
     "pos": 222,
     "wtScore": 62.7127,
     "wtProb": 18.6,
     "k198rScore": 43.8046,
     "k198rProb": 11.3,
     "probDifference": -7.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 326
    },
    {
     "sequence": "AMDLPMVSGDRIHCL",
     "pos": 1857,
     "wtScore": 57.1395,
     "wtProb": 14.8,
     "k198rScore": 64.8306,
     "k198rProb": 22,
     "probDifference": 7.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 327
    },
    {
     "sequence": "AMDLPMVSGDRIHCL",
     "pos": 1842,
     "wtScore": 57.1395,
     "wtProb": 14.8,
     "k198rScore": 64.8306,
     "k198rProb": 22,
     "probDifference": 7.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 328
    },
    {
     "sequence": "AMDLPMVSGDRIHCL",
     "pos": 1837,
     "wtScore": 57.1395,
     "wtProb": 14.8,
     "k198rScore": 64.8306,
     "k198rProb": 22,
     "probDifference": 7.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 329
    },
    {
     "sequence": "AMDLPMVSGDRIHCL",
     "pos": 1847,
     "wtScore": 57.1395,
     "wtProb": 14.8,
     "k198rScore": 64.8306,
     "k198rProb": 22,
     "probDifference": 7.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 330
    },
    {
     "sequence": "RFYATNLSRTDLHST",
     "pos": 351,
     "wtScore": 61.7645,
     "wtProb": 18,
     "k198rScore": 43.0624,
     "k198rProb": 10.9,
     "probDifference": -7.1,
     "geneName": "KCNQ2",
     "known": "Yes",
     "id": 331
    },
    {
     "sequence": "RDGNGTTSGVGTGSS",
     "pos": 1071,
     "wtScore": 58.803,
     "wtProb": 16,
     "k198rScore": 66.9662,
     "k198rProb": 23.1,
     "probDifference": 7.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 332
    },
    {
     "sequence": "LRQNCIYSTEALTGK",
     "pos": 815,
     "wtScore": 58.5648,
     "wtProb": 15.8,
     "k198rScore": 37.4652,
     "k198rProb": 8.7,
     "probDifference": -7.1,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 333
    },
    {
     "sequence": "DETQTKRSTFDNFPQ",
     "pos": 685,
     "wtScore": 58.3559,
     "wtProb": 15.6,
     "k198rScore": 36.9431,
     "k198rProb": 8.5,
     "probDifference": -7.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 334
    },
    {
     "sequence": "IVDMKSTSSIDSFTS",
     "pos": 600,
     "wtScore": 58.9198,
     "wtProb": 16.1,
     "k198rScore": 38.7818,
     "k198rProb": 9,
     "probDifference": -7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 335
    },
    {
     "sequence": "LPAGILGSGLALKVQ",
     "pos": 352,
     "wtScore": 57.8749,
     "wtProb": 15.3,
     "k198rScore": 65.6161,
     "k198rProb": 22.4,
     "probDifference": 7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 336
    },
    {
     "sequence": "YVARPSTSEIEDQSM",
     "pos": 610,
     "wtScore": 128.111,
     "wtProb": 34.5,
     "k198rScore": 72.6495,
     "k198rProb": 27.5,
     "probDifference": -7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 337
    },
    {
     "sequence": "KDKLDDTSSSEGSTI",
     "pos": 1129,
     "wtScore": 103.531,
     "wtProb": 33.7,
     "k198rScore": 72.062,
     "k198rProb": 26.7,
     "probDifference": -7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 338
    },
    {
     "sequence": "EPLLCGNSSDAGQCP",
     "pos": 326,
     "wtScore": 61.4721,
     "wtProb": 17.8,
     "k198rScore": 42.854,
     "k198rProb": 10.8,
     "probDifference": -7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 339
    },
    {
     "sequence": "IDKLNENSTPEKTDM",
     "pos": 1957,
     "wtScore": 103.796,
     "wtProb": 33.7,
     "k198rScore": 72.1366,
     "k198rProb": 26.7,
     "probDifference": -7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 340
    },
    {
     "sequence": "RRSVKRNSTVDCNGV",
     "pos": 640,
     "wtScore": 59.827,
     "wtProb": 16.8,
     "k198rScore": 40.8798,
     "k198rProb": 9.8,
     "probDifference": -6.9,
     "geneName": "SCN8A",
     "known": "Yes",
     "id": 341
    },
    {
     "sequence": "EPYVARPSTSEIEDQ",
     "pos": 608,
     "wtScore": 89.1074,
     "wtProb": 27.4,
     "k198rScore": 57.4553,
     "k198rProb": 20.5,
     "probDifference": -6.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 342
    },
    {
     "sequence": "VSEVKKDSSQEGCKM",
     "pos": 877,
     "wtScore": 99.3823,
     "wtProb": 31.7,
     "k198rScore": 70.1313,
     "k198rProb": 24.8,
     "probDifference": -6.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 343
    },
    {
     "sequence": "KRTLSETSSSKSFET",
     "pos": 498,
     "wtScore": 58.5417,
     "wtProb": 15.8,
     "k198rScore": 38.179,
     "k198rProb": 8.9,
     "probDifference": -6.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 344
    },
    {
     "sequence": "MEMLEDSSGRQRAVS",
     "pos": 699,
     "wtScore": 58.7046,
     "wtProb": 15.9,
     "k198rScore": 66.5827,
     "k198rProb": 22.8,
     "probDifference": 6.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 345
    },
    {
     "sequence": "KPPKADGSHREDDED",
     "pos": 42,
     "wtScore": 108.106,
     "wtProb": 34.8,
     "k198rScore": 85.9323,
     "k198rProb": 41.7,
     "probDifference": 6.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 346
    },
    {
     "sequence": "DALLCGNSSDAGQCP",
     "pos": 340,
     "wtScore": 62.4546,
     "wtProb": 18.4,
     "k198rScore": 44.3356,
     "k198rProb": 11.5,
     "probDifference": -6.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 347
    },
    {
     "sequence": "DALLCGNSSDAGQCP",
     "pos": 338,
     "wtScore": 62.4546,
     "wtProb": 18.4,
     "k198rScore": 44.3356,
     "k198rProb": 11.5,
     "probDifference": -6.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 348
    },
    {
     "sequence": "EKLNATSSSEGSTVD",
     "pos": 1137,
     "wtScore": 59.5471,
     "wtProb": 16.5,
     "k198rScore": 40.5079,
     "k198rProb": 9.6,
     "probDifference": -6.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 349
    },
    {
     "sequence": "EKLNATSSSEGSTVD",
     "pos": 1139,
     "wtScore": 59.5471,
     "wtProb": 16.5,
     "k198rScore": 40.5079,
     "k198rProb": 9.6,
     "probDifference": -6.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 350
    },
    {
     "sequence": "KFPFCQVSTEEGKGK",
     "pos": 1182,
     "wtScore": 101.284,
     "wtProb": 32.2,
     "k198rScore": 70.7227,
     "k198rProb": 25.5,
     "probDifference": -6.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 351
    },
    {
     "sequence": "NLNTEDVSSESDPEG",
     "pos": 1113,
     "wtScore": 68.6468,
     "wtProb": 21.8,
     "k198rScore": 49.4849,
     "k198rProb": 15.1,
     "probDifference": -6.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 352
    },
    {
     "sequence": "EESADPLSEDDFETF",
     "pos": 1780,
     "wtScore": 124.634,
     "wtProb": 34.3,
     "k198rScore": 72.7283,
     "k198rProb": 27.6,
     "probDifference": -6.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 353
    },
    {
     "sequence": "FECLRRQSSQEEVPS",
     "pos": 1931,
     "wtScore": 94.8982,
     "wtProb": 30.1,
     "k198rScore": 67.414,
     "k198rProb": 23.3,
     "probDifference": -6.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 354
    },
    {
     "sequence": "EELERSFSGFSISQS",
     "pos": 802,
     "wtScore": 61.8762,
     "wtProb": 18.1,
     "k198rScore": 70.1364,
     "k198rProb": 24.8,
     "probDifference": 6.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 355
    },
    {
     "sequence": "QGPSKGLSPRFPKQK",
     "pos": 784,
     "wtScore": 61.451,
     "wtProb": 17.8,
     "k198rScore": 43.4135,
     "k198rProb": 11.1,
     "probDifference": -6.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 356
    },
    {
     "sequence": "NLRKTCYSIVEHNWF",
     "pos": 1200,
     "wtScore": 92.6638,
     "wtProb": 29,
     "k198rScore": 65.9619,
     "k198rProb": 22.4,
     "probDifference": -6.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 357
    },
    {
     "sequence": "GRFRDPGSENEFADD",
     "pos": 578,
     "wtScore": 136.743,
     "wtProb": 34.3,
     "k198rScore": 83.8658,
     "k198rProb": 40.9,
     "probDifference": 6.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 358
    },
    {
     "sequence": "DTSSSEGSTIDIKPE",
     "pos": 1134,
     "wtScore": 69.7546,
     "wtProb": 22.3,
     "k198rScore": 50.1347,
     "k198rProb": 15.6,
     "probDifference": -6.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 359
    },
    {
     "sequence": "ILVFIMLSSAALAAE",
     "pos": 898,
     "wtScore": 57.5794,
     "wtProb": 15.1,
     "k198rScore": 36.8572,
     "k198rProb": 8.5,
     "probDifference": -6.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 360
    },
    {
     "sequence": "AGYPSTVSTVEGHGP",
     "pos": 1836,
     "wtScore": 91.7654,
     "wtProb": 28.7,
     "k198rScore": 61.56,
     "k198rProb": 22.2,
     "probDifference": -6.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 361
    },
    {
     "sequence": "LFLALLLSSFSSDNL",
     "pos": 983,
     "wtScore": 56.9497,
     "wtProb": 14.7,
     "k198rScore": 35.1856,
     "k198rProb": 8.2,
     "probDifference": -6.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 362
    },
    {
     "sequence": "LFLALLLSSFSSDNL",
     "pos": 984,
     "wtScore": 56.9497,
     "wtProb": 14.7,
     "k198rScore": 35.1856,
     "k198rProb": 8.2,
     "probDifference": -6.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 363
    },
    {
     "sequence": "TIKEYEKSSEGALYI",
     "pos": 120,
     "wtScore": 64.3579,
     "wtProb": 19.7,
     "k198rScore": 46.8425,
     "k198rProb": 13.2,
     "probDifference": -6.5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 364
    },
    {
     "sequence": "RPVTQGGSLQSPPRS",
     "pos": 2053,
     "wtScore": 45.9305,
     "wtProb": 9.5,
     "k198rScore": 50.3321,
     "k198rProb": 16,
     "probDifference": 6.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 365
    },
    {
     "sequence": "DFLIVIGSIIDVILS",
     "pos": 1313,
     "wtScore": 60.4544,
     "wtProb": 17.2,
     "k198rScore": 42.7756,
     "k198rProb": 10.8,
     "probDifference": -6.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 366
    },
    {
     "sequence": "RERRSSYSGYSGYSQ",
     "pos": 614,
     "wtScore": 64.1734,
     "wtProb": 19.6,
     "k198rScore": 71.4248,
     "k198rProb": 26,
     "probDifference": 6.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 367
    },
    {
     "sequence": "DKLDDTSSSEGSTID",
     "pos": 1130,
     "wtScore": 60.7322,
     "wtProb": 17.3,
     "k198rScore": 43.222,
     "k198rProb": 11,
     "probDifference": -6.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 368
    },
    {
     "sequence": "VIDMRSMSSIDSFIS",
     "pos": 585,
     "wtScore": 59.975,
     "wtProb": 16.9,
     "k198rScore": 42.3886,
     "k198rProb": 10.6,
     "probDifference": -6.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 369
    },
    {
     "sequence": "RGFLRSASLGRRASF",
     "pos": 1974,
     "wtScore": 45.1672,
     "wtProb": 9.3,
     "k198rScore": 50.1176,
     "k198rProb": 15.6,
     "probDifference": 6.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 370
    },
    {
     "sequence": "RGTRLPLSGEGPTSQ",
     "pos": 34,
     "wtScore": 72.9332,
     "wtProb": 23.6,
     "k198rScore": 74.9078,
     "k198rProb": 29.8,
     "probDifference": 6.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 371
    },
    {
     "sequence": "QEAVHTDSLEGKIDS",
     "pos": 2022,
     "wtScore": 54.4554,
     "wtProb": 13.1,
     "k198rScore": 54.6193,
     "k198rProb": 19.3,
     "probDifference": 6.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 372
    },
    {
     "sequence": "VAGMMEYSLDGHNVS",
     "pos": 186,
     "wtScore": 51.1566,
     "wtProb": 11.2,
     "k198rScore": 51.9699,
     "k198rProb": 17.4,
     "probDifference": 6.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 373
    },
    {
     "sequence": "CKLENHISPDVRVLP",
     "pos": 837,
     "wtScore": 66.5037,
     "wtProb": 20.8,
     "k198rScore": 48.9105,
     "k198rProb": 14.6,
     "probDifference": -6.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 374
    },
    {
     "sequence": "NNTGIEISKELNYLR",
     "pos": 1057,
     "wtScore": 63.972,
     "wtProb": 19.4,
     "k198rScore": 47.092,
     "k198rProb": 13.3,
     "probDifference": -6.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 375
    },
    {
     "sequence": "ALAVAGLSPLLQRSH",
     "pos": 2018,
     "wtScore": 60.9701,
     "wtProb": 17.5,
     "k198rScore": 44.0391,
     "k198rProb": 11.4,
     "probDifference": -6.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 376
    },
    {
     "sequence": "EELERSPSGFSISQD",
     "pos": 804,
     "wtScore": 61.1401,
     "wtProb": 17.6,
     "k198rScore": 68.4164,
     "k198rProb": 23.7,
     "probDifference": 6.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 377
    },
    {
     "sequence": "KQRLKNISSNYNKEA",
     "pos": 1923,
     "wtScore": 63.4123,
     "wtProb": 19.1,
     "k198rScore": 46.6521,
     "k198rProb": 13.1,
     "probDifference": -6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 378
    },
    {
     "sequence": "REEGSVGSSSPQDTG",
     "pos": 852,
     "wtScore": 61.2075,
     "wtProb": 17.7,
     "k198rScore": 44.4635,
     "k198rProb": 11.6,
     "probDifference": -6,
     "geneName": "KCNB2",
     "known": "No",
     "id": 379
    },
    {
     "sequence": "FKPFTPESLANIERR",
     "pos": 20,
     "wtScore": 49.207,
     "wtProb": 10.4,
     "k198rScore": 50.8569,
     "k198rProb": 16.4,
     "probDifference": 6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 380
    },
    {
     "sequence": "IGYQQSTSFTDPFFI",
     "pos": 216,
     "wtScore": 57.5663,
     "wtProb": 15.1,
     "k198rScore": 39.0298,
     "k198rProb": 9.1,
     "probDifference": -6,
     "geneName": "KCNA2",
     "known": "No",
     "id": 381
    },
    {
     "sequence": "LFLALLLSSFSADNL",
     "pos": 977,
     "wtScore": 56.2647,
     "wtProb": 14.2,
     "k198rScore": 35.6593,
     "k198rProb": 8.3,
     "probDifference": -6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 382
    },
    {
     "sequence": "LFLALLLSSFSADNL",
     "pos": 992,
     "wtScore": 56.2647,
     "wtProb": 14.2,
     "k198rScore": 35.6593,
     "k198rProb": 8.3,
     "probDifference": -6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 383
    },
    {
     "sequence": "ECLRRQSSQEEVPSS",
     "pos": 1932,
     "wtScore": 99.1919,
     "wtProb": 31.6,
     "k198rScore": 70.897,
     "k198rProb": 25.6,
     "probDifference": -5.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 384
    },
    {
     "sequence": "PESEPSNSTEGETPC",
     "pos": 1487,
     "wtScore": 59.1428,
     "wtProb": 16.3,
     "k198rScore": 42.0244,
     "k198rProb": 10.3,
     "probDifference": -5.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 385
    },
    {
     "sequence": "STGQKNFSAPPAAPP",
     "pos": 701,
     "wtScore": 51.776,
     "wtProb": 11.5,
     "k198rScore": 51.9366,
     "k198rProb": 17.3,
     "probDifference": 5.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 386
    },
    {
     "sequence": "VRARGRPSEEELQDS",
     "pos": 2206,
     "wtScore": 140.256,
     "wtProb": 33.9,
     "k198rScore": 83.0481,
     "k198rProb": 39.8,
     "probDifference": 5.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 387
    },
    {
     "sequence": "ERRHSNVSQASRASR",
     "pos": 625,
     "wtScore": 65.365,
     "wtProb": 20.3,
     "k198rScore": 48.67,
     "k198rProb": 14.5,
     "probDifference": -5.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 388
    },
    {
     "sequence": "TSGVGTGSSVEKYVI",
     "pos": 1077,
     "wtScore": 103.264,
     "wtProb": 33.4,
     "k198rScore": 72.7444,
     "k198rProb": 27.6,
     "probDifference": -5.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 389
    },
    {
     "sequence": "IVGALIQSVKKLSDV",
     "pos": 246,
     "wtScore": 57.9275,
     "wtProb": 15.3,
     "k198rScore": 40.1173,
     "k198rProb": 9.5,
     "probDifference": -5.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 390
    },
    {
     "sequence": "IVGALIQSVKKLSDV",
     "pos": 242,
     "wtScore": 57.9275,
     "wtProb": 15.3,
     "k198rScore": 40.1173,
     "k198rProb": 9.5,
     "probDifference": -5.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 391
    },
    {
     "sequence": "IVGALIQSVKKLSDV",
     "pos": 242,
     "wtScore": 57.9275,
     "wtProb": 15.3,
     "k198rScore": 40.1173,
     "k198rProb": 9.5,
     "probDifference": -5.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 392
    },
    {
     "sequence": "IVGALIQSVKKLSDV",
     "pos": 243,
     "wtScore": 57.9275,
     "wtProb": 15.3,
     "k198rScore": 40.1173,
     "k198rProb": 9.5,
     "probDifference": -5.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 393
    },
    {
     "sequence": "TRYWNSLSNLVASLL",
     "pos": 637,
     "wtScore": 59.4514,
     "wtProb": 16.4,
     "k198rScore": 42.5459,
     "k198rProb": 10.6,
     "probDifference": -5.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 394
    },
    {
     "sequence": "LMSLFVLSSKDGWVN",
     "pos": 1500,
     "wtScore": 72.2761,
     "wtProb": 23.3,
     "k198rScore": 52.1909,
     "k198rProb": 17.5,
     "probDifference": -5.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 395
    },
    {
     "sequence": "PIRRACISIVEWKPF",
     "pos": 118,
     "wtScore": 93.7531,
     "wtProb": 29.5,
     "k198rScore": 67.8699,
     "k198rProb": 23.8,
     "probDifference": -5.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 396
    },
    {
     "sequence": "PIRRACISIVEWKPF",
     "pos": 120,
     "wtScore": 93.7531,
     "wtProb": 29.5,
     "k198rScore": 67.8699,
     "k198rProb": 23.8,
     "probDifference": -5.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 397
    },
    {
     "sequence": "FLNTLTISSEHYNQP",
     "pos": 540,
     "wtScore": 55.3329,
     "wtProb": 13.6,
     "k198rScore": 33.168,
     "k198rProb": 7.9,
     "probDifference": -5.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 398
    },
    {
     "sequence": "MRLVKLLSRGEGIRT",
     "pos": 1388,
     "wtScore": 102.868,
     "wtProb": 33.3,
     "k198rScore": 82.4911,
     "k198rProb": 39.1,
     "probDifference": 5.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 399
    },
    {
     "sequence": "MRLVKLLSRGEGIRT",
     "pos": 1330,
     "wtScore": 102.868,
     "wtProb": 33.3,
     "k198rScore": 82.4911,
     "k198rProb": 39.1,
     "probDifference": 5.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 400
    },
    {
     "sequence": "VFGPNGGSSWMREKR",
     "pos": 823,
     "wtScore": 60.374,
     "wtProb": 17.2,
     "k198rScore": 44.2092,
     "k198rProb": 11.5,
     "probDifference": -5.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 401
    },
    {
     "sequence": "TLCIIWFSFELVVRF",
     "pos": 232,
     "wtScore": 60.5931,
     "wtProb": 17.3,
     "k198rScore": 44.5116,
     "k198rProb": 11.7,
     "probDifference": -5.6,
     "geneName": "KCNA1",
     "known": "No",
     "id": 402
    },
    {
     "sequence": "SCPKIPSSPDLKKSR",
     "pos": 440,
     "wtScore": 60.2925,
     "wtProb": 17.1,
     "k198rScore": 44.1569,
     "k198rProb": 11.5,
     "probDifference": -5.6,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 403
    },
    {
     "sequence": "LSNVEGLSVLRSFRL",
     "pos": 847,
     "wtScore": 61.2871,
     "wtProb": 17.7,
     "k198rScore": 45.2771,
     "k198rProb": 12.1,
     "probDifference": -5.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 404
    },
    {
     "sequence": "LVSFGIQSSAISVVK",
     "pos": 973,
     "wtScore": 58.3952,
     "wtProb": 15.7,
     "k198rScore": 41.4644,
     "k198rProb": 10.1,
     "probDifference": -5.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 405
    },
    {
     "sequence": "CVNVITMSMEHYNQP",
     "pos": 1634,
     "wtScore": 57.2454,
     "wtProb": 14.9,
     "k198rScore": 39.5246,
     "k198rProb": 9.3,
     "probDifference": -5.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 406
    },
    {
     "sequence": "IVFMILLSSGALAFE",
     "pos": 1220,
     "wtScore": 63.9081,
     "wtProb": 19.4,
     "k198rScore": 47.9504,
     "k198rProb": 13.9,
     "probDifference": -5.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 407
    },
    {
     "sequence": "IVFMILLSSGALAFE",
     "pos": 1230,
     "wtScore": 63.9081,
     "wtProb": 19.4,
     "k198rScore": 47.9504,
     "k198rProb": 13.9,
     "probDifference": -5.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 408
    },
    {
     "sequence": "IVFMILLSSGALAFE",
     "pos": 1218,
     "wtScore": 63.9081,
     "wtProb": 19.4,
     "k198rScore": 47.9504,
     "k198rProb": 13.9,
     "probDifference": -5.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 409
    },
    {
     "sequence": "LNRPPDCSLDKEHPG",
     "pos": 1721,
     "wtScore": 54.6945,
     "wtProb": 13.2,
     "k198rScore": 53.849,
     "k198rProb": 18.8,
     "probDifference": 5.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 410
    },
    {
     "sequence": "MAVAGLDSSKAQKYS",
     "pos": 1965,
     "wtScore": 61.587,
     "wtProb": 17.8,
     "k198rScore": 45.5528,
     "k198rProb": 12.3,
     "probDifference": -5.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 411
    },
    {
     "sequence": "DSRVSCHSQADLQGP",
     "pos": 761,
     "wtScore": 71.0964,
     "wtProb": 22.8,
     "k198rScore": 51.8838,
     "k198rProb": 17.3,
     "probDifference": -5.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 412
    },
    {
     "sequence": "TPLRRAESLDPRPLR",
     "pos": 1197,
     "wtScore": 59.3047,
     "wtProb": 16.4,
     "k198rScore": 60.7146,
     "k198rProb": 21.8,
     "probDifference": 5.5,
     "geneName": "CACNA1H",
     "known": "Yes",
     "id": 413
    },
    {
     "sequence": "DEMESAASTLLNGNV",
     "pos": 2103,
     "wtScore": 55.8503,
     "wtProb": 14,
     "k198rScore": 36.864,
     "k198rProb": 8.5,
     "probDifference": -5.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 414
    },
    {
     "sequence": "EYLLRFLSSPNKWKF",
     "pos": 253,
     "wtScore": 56.896,
     "wtProb": 14.7,
     "k198rScore": 39.363,
     "k198rProb": 9.2,
     "probDifference": -5.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 415
    },
    {
     "sequence": "SNDSTLASFSEPGSC",
     "pos": 448,
     "wtScore": 92.9164,
     "wtProb": 29.2,
     "k198rScore": 68.6417,
     "k198rProb": 23.8,
     "probDifference": -5.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 416
    },
    {
     "sequence": "AAGRLSDSSSEASKL",
     "pos": 481,
     "wtScore": 102.292,
     "wtProb": 33,
     "k198rScore": 72.6873,
     "k198rProb": 27.5,
     "probDifference": -5.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 417
    },
    {
     "sequence": "ILFFILLSSISLAAE",
     "pos": 912,
     "wtScore": 56.3438,
     "wtProb": 14.3,
     "k198rScore": 38.149,
     "k198rProb": 8.9,
     "probDifference": -5.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 418
    },
    {
     "sequence": "PKNEVHKSTQALHNT",
     "pos": 769,
     "wtScore": 56.6086,
     "wtProb": 14.5,
     "k198rScore": 38.9333,
     "k198rProb": 9.1,
     "probDifference": -5.4,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 419
    },
    {
     "sequence": "DEGQLLYSVDSSPPK",
     "pos": 768,
     "wtScore": 65.0391,
     "wtProb": 20.1,
     "k198rScore": 49.0229,
     "k198rProb": 14.7,
     "probDifference": -5.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 420
    },
    {
     "sequence": "NVGFGYLSLLQVATF",
     "pos": 1423,
     "wtScore": 53.9219,
     "wtProb": 12.7,
     "k198rScore": 52.9063,
     "k198rProb": 18.1,
     "probDifference": 5.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 421
    },
    {
     "sequence": "EQRARHLSNDSTLAS",
     "pos": 441,
     "wtScore": 73.7861,
     "wtProb": 24,
     "k198rScore": 53.5986,
     "k198rProb": 18.6,
     "probDifference": -5.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 422
    },
    {
     "sequence": "ARALFCLSLNNPIRR",
     "pos": 109,
     "wtScore": 55.4008,
     "wtProb": 13.7,
     "k198rScore": 54.2836,
     "k198rProb": 19,
     "probDifference": 5.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 423
    },
    {
     "sequence": "SGSMPLSSTGDGISD",
     "pos": 854,
     "wtScore": 58.4513,
     "wtProb": 15.7,
     "k198rScore": 41.9681,
     "k198rProb": 10.3,
     "probDifference": -5.4,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 424
    },
    {
     "sequence": "LLDKAVLSPESSIYT",
     "pos": 719,
     "wtScore": 70.3994,
     "wtProb": 22.6,
     "k198rScore": 51.7662,
     "k198rProb": 17.2,
     "probDifference": -5.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 425
    },
    {
     "sequence": "RPDIDPGSTERVFLS",
     "pos": 1321,
     "wtScore": 56.7267,
     "wtProb": 14.5,
     "k198rScore": 39.3734,
     "k198rProb": 9.2,
     "probDifference": -5.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 426
    },
    {
     "sequence": "VDCNGVVSLIGGPGS",
     "pos": 649,
     "wtScore": 45.1226,
     "wtProb": 9.3,
     "k198rScore": 48.9585,
     "k198rProb": 14.6,
     "probDifference": 5.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 427
    },
    {
     "sequence": "DEMQTRRSTFDNFPQ",
     "pos": 686,
     "wtScore": 55.2545,
     "wtProb": 13.6,
     "k198rScore": 35.8702,
     "k198rProb": 8.3,
     "probDifference": -5.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 428
    },
    {
     "sequence": "TLCIIWFSFEFLVRF",
     "pos": 233,
     "wtScore": 60.5807,
     "wtProb": 17.3,
     "k198rScore": 45.0865,
     "k198rProb": 12,
     "probDifference": -5.3,
     "geneName": "KCNA2",
     "known": "No",
     "id": 429
    },
    {
     "sequence": "SSQEEVPSSPIFPHR",
     "pos": 1938,
     "wtScore": 58.6267,
     "wtProb": 15.8,
     "k198rScore": 42.3579,
     "k198rProb": 10.5,
     "probDifference": -5.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 430
    },
    {
     "sequence": "EVRKRRLSSYQISME",
     "pos": 686,
     "wtScore": 57.7633,
     "wtProb": 15.2,
     "k198rScore": 41.2585,
     "k198rProb": 10,
     "probDifference": -5.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 431
    },
    {
     "sequence": "ITSFFNNSLDGNGTT",
     "pos": 298,
     "wtScore": 46.3794,
     "wtProb": 9.6,
     "k198rScore": 49.1858,
     "k198rProb": 14.8,
     "probDifference": 5.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 432
    },
    {
     "sequence": "SRSASTISKSDYMEI",
     "pos": 453,
     "wtScore": 62.1171,
     "wtProb": 18.2,
     "k198rScore": 46.4872,
     "k198rProb": 13,
     "probDifference": -5.2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 433
    },
    {
     "sequence": "LGQTLKASMRELGLL",
     "pos": 323,
     "wtScore": 89.2106,
     "wtProb": 27.4,
     "k198rScore": 62.0205,
     "k198rProb": 22.2,
     "probDifference": -5.2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 434
    },
    {
     "sequence": "LGQTLKASMRELGLL",
     "pos": 321,
     "wtScore": 89.2106,
     "wtProb": 27.4,
     "k198rScore": 62.0205,
     "k198rProb": 22.2,
     "probDifference": -5.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 435
    },
    {
     "sequence": "REEKQVDSSPNCFAD",
     "pos": 828,
     "wtScore": 60.9834,
     "wtProb": 17.5,
     "k198rScore": 45.5569,
     "k198rProb": 12.3,
     "probDifference": -5.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 436
    },
    {
     "sequence": "IIFMILLSSGALAFE",
     "pos": 1210,
     "wtScore": 62.1925,
     "wtProb": 18.2,
     "k198rScore": 46.5965,
     "k198rProb": 13,
     "probDifference": -5.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 437
    },
    {
     "sequence": "KEQLEAASSQKLGLL",
     "pos": 419,
     "wtScore": 61.1879,
     "wtProb": 17.6,
     "k198rScore": 45.7329,
     "k198rProb": 12.5,
     "probDifference": -5.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 438
    },
    {
     "sequence": "GEPLHALSPRGTARS",
     "pos": 1998,
     "wtScore": 64.6353,
     "wtProb": 19.8,
     "k198rScore": 49.0759,
     "k198rProb": 14.7,
     "probDifference": -5.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 439
    },
    {
     "sequence": "QEVSQKDSHEQLNNT",
     "pos": 519,
     "wtScore": 64.8904,
     "wtProb": 20,
     "k198rScore": 49.315,
     "k198rProb": 14.9,
     "probDifference": -5.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 440
    },
    {
     "sequence": "KLNESSSSSEGSTVD",
     "pos": 1150,
     "wtScore": 61.8163,
     "wtProb": 18,
     "k198rScore": 46.4044,
     "k198rProb": 12.9,
     "probDifference": -5.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 441
    },
    {
     "sequence": "QEVAWKLSSNRERHV",
     "pos": 1859,
     "wtScore": 56.2563,
     "wtProb": 14.2,
     "k198rScore": 39.1544,
     "k198rProb": 9.1,
     "probDifference": -5.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 442
    },
    {
     "sequence": "ASNPSKVSYEPITTT",
     "pos": 1881,
     "wtScore": 57.7737,
     "wtProb": 15.3,
     "k198rScore": 41.6906,
     "k198rProb": 10.2,
     "probDifference": -5.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 443
    },
    {
     "sequence": "ASNPSKVSYEPITTT",
     "pos": 1886,
     "wtScore": 57.7737,
     "wtProb": 15.3,
     "k198rScore": 41.6906,
     "k198rProb": 10.2,
     "probDifference": -5.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 444
    },
    {
     "sequence": "ASNPSKVSYEPITTT",
     "pos": 1891,
     "wtScore": 57.7737,
     "wtProb": 15.3,
     "k198rScore": 41.6906,
     "k198rProb": 10.2,
     "probDifference": -5.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 445
    },
    {
     "sequence": "PPPVGSLSQRKRQQY",
     "pos": 80,
     "wtScore": 62.7584,
     "wtProb": 18.6,
     "k198rScore": 47.4622,
     "k198rProb": 13.5,
     "probDifference": -5,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 446
    },
    {
     "sequence": "PEVEPEESLEPEACF",
     "pos": 1163,
     "wtScore": 58.1709,
     "wtProb": 15.5,
     "k198rScore": 57.5926,
     "k198rProb": 20.5,
     "probDifference": 5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 447
    },
    {
     "sequence": "SLEGKIDSPRDTLDP",
     "pos": 2029,
     "wtScore": 70.1881,
     "wtProb": 22.5,
     "k198rScore": 52.1795,
     "k198rProb": 17.5,
     "probDifference": -5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 448
    },
    {
     "sequence": "EKTDLTMSTAACPPS",
     "pos": 1977,
     "wtScore": 53.9885,
     "wtProb": 12.8,
     "k198rScore": 32.007,
     "k198rProb": 7.8,
     "probDifference": -5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 449
    },
    {
     "sequence": "VFSCLVLSVFSTIKE",
     "pos": 109,
     "wtScore": 62.7558,
     "wtProb": 18.6,
     "k198rScore": 47.6437,
     "k198rProb": 13.6,
     "probDifference": -5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 450
    },
    {
     "sequence": "EYLLRFLSSPKKWKF",
     "pos": 249,
     "wtScore": 56.0753,
     "wtProb": 14.1,
     "k198rScore": 39.2045,
     "k198rProb": 9.2,
     "probDifference": -5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 451
    },
    {
     "sequence": "TLRRRTPSCEATPHR",
     "pos": 2221,
     "wtScore": 70.685,
     "wtProb": 22.7,
     "k198rScore": 52.4699,
     "k198rProb": 17.8,
     "probDifference": -4.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 452
    },
    {
     "sequence": "PSQRNALSLQAGLRT",
     "pos": 1697,
     "wtScore": 51.6607,
     "wtProb": 11.4,
     "k198rScore": 50.7738,
     "k198rProb": 16.3,
     "probDifference": 4.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 453
    },
    {
     "sequence": "EGHGPPLSPAIRVQE",
     "pos": 1846,
     "wtScore": 59.444,
     "wtProb": 16.4,
     "k198rScore": 44.3456,
     "k198rProb": 11.6,
     "probDifference": -4.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 454
    },
    {
     "sequence": "FLTLFRVSTGDNWNG",
     "pos": 1804,
     "wtScore": 58.9805,
     "wtProb": 16.1,
     "k198rScore": 43.7187,
     "k198rProb": 11.3,
     "probDifference": -4.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 455
    },
    {
     "sequence": "FKEYETVSGDWLLLL",
     "pos": 151,
     "wtScore": 66.7041,
     "wtProb": 20.8,
     "k198rScore": 71.0822,
     "k198rProb": 25.7,
     "probDifference": 4.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 456
    },
    {
     "sequence": "GLGELLESSSEASKL",
     "pos": 483,
     "wtScore": 97.3539,
     "wtProb": 30.6,
     "k198rScore": 71.1253,
     "k198rProb": 25.8,
     "probDifference": -4.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 457
    },
    {
     "sequence": "TRHWTSLSNLVASLL",
     "pos": 636,
     "wtScore": 56.1104,
     "wtProb": 14.1,
     "k198rScore": 39.6181,
     "k198rProb": 9.3,
     "probDifference": -4.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 458
    },
    {
     "sequence": "EECYEDDSSPTWSRQ",
     "pos": 1855,
     "wtScore": 64.6222,
     "wtProb": 19.8,
     "k198rScore": 49.467,
     "k198rProb": 15,
     "probDifference": -4.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 459
    },
    {
     "sequence": "GTTSGIGSSVEKYII",
     "pos": 1071,
     "wtScore": 98.998,
     "wtProb": 31.4,
     "k198rScore": 72.0556,
     "k198rProb": 26.6,
     "probDifference": -4.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 460
    },
    {
     "sequence": "NVGLGYLSLLQVATF",
     "pos": 1413,
     "wtScore": 55.5611,
     "wtProb": 13.8,
     "k198rScore": 53.5821,
     "k198rProb": 18.6,
     "probDifference": 4.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 461
    },
    {
     "sequence": "TTPGGGGSSAARRVR",
     "pos": 2087,
     "wtScore": 64.5303,
     "wtProb": 19.8,
     "k198rScore": 49.4275,
     "k198rProb": 15,
     "probDifference": -4.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 462
    },
    {
     "sequence": "HEGNKIDSCMSNNTG",
     "pos": 1046,
     "wtScore": 61.5808,
     "wtProb": 17.8,
     "k198rScore": 46.6486,
     "k198rProb": 13.1,
     "probDifference": -4.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 463
    },
    {
     "sequence": "LISFGIQSSAINVVK",
     "pos": 1007,
     "wtScore": 57.1575,
     "wtProb": 14.8,
     "k198rScore": 41.3841,
     "k198rProb": 10,
     "probDifference": -4.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 464
    },
    {
     "sequence": "DSRRGSSSSGDPPLG",
     "pos": 1108,
     "wtScore": 75.8461,
     "wtProb": 24.7,
     "k198rScore": 55.8115,
     "k198rProb": 20,
     "probDifference": -4.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 465
    },
    {
     "sequence": "SKEKLNESSSSSEGS",
     "pos": 1147,
     "wtScore": 58.5681,
     "wtProb": 15.8,
     "k198rScore": 43.3271,
     "k198rProb": 11,
     "probDifference": -4.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 466
    },
    {
     "sequence": "AKAAQTMSTSAPPPV",
     "pos": 69,
     "wtScore": 53.4626,
     "wtProb": 12.5,
     "k198rScore": 31.7961,
     "k198rProb": 7.8,
     "probDifference": -4.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 467
    },
    {
     "sequence": "ALIQSLDSSRRQYQE",
     "pos": 470,
     "wtScore": 55.1458,
     "wtProb": 13.5,
     "k198rScore": 38.0734,
     "k198rProb": 8.8,
     "probDifference": -4.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 468
    },
    {
     "sequence": "ALIQSLDSSRRQYQE",
     "pos": 401,
     "wtScore": 55.1458,
     "wtProb": 13.5,
     "k198rScore": 38.0734,
     "k198rProb": 8.8,
     "probDifference": -4.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 469
    },
    {
     "sequence": "QGDTESPSHEKLVDS",
     "pos": 1791,
     "wtScore": 58.4093,
     "wtProb": 15.7,
     "k198rScore": 43.2826,
     "k198rProb": 11,
     "probDifference": -4.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 470
    },
    {
     "sequence": "STERVFLSVSNYIFT",
     "pos": 1328,
     "wtScore": 59.159,
     "wtProb": 16.3,
     "k198rScore": 44.3803,
     "k198rProb": 11.6,
     "probDifference": -4.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 471
    },
    {
     "sequence": "TPGGGGSSAARRVRP",
     "pos": 2088,
     "wtScore": 53.3906,
     "wtProb": 12.4,
     "k198rScore": 51.585,
     "k198rProb": 17,
     "probDifference": 4.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 472
    },
    {
     "sequence": "YGAQAPVSMSDLWIT",
     "pos": 367,
     "wtScore": 59.9586,
     "wtProb": 16.9,
     "k198rScore": 45.4763,
     "k198rProb": 12.2,
     "probDifference": -4.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 473
    },
    {
     "sequence": "SAGNATISTVSSTQR",
     "pos": 73,
     "wtScore": 54.4885,
     "wtProb": 13.1,
     "k198rScore": 36.8019,
     "k198rProb": 8.5,
     "probDifference": -4.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 474
    },
    {
     "sequence": "FETKEQGSPEKARSS",
     "pos": 510,
     "wtScore": 64.1858,
     "wtProb": 19.6,
     "k198rScore": 49.4304,
     "k198rProb": 15,
     "probDifference": -4.6,
     "geneName": "KCNB1",
     "known": "No",
     "id": 475
    },
    {
     "sequence": "HEQLNNTSSSSPQHL",
     "pos": 527,
     "wtScore": 53.1812,
     "wtProb": 12.3,
     "k198rScore": 30.6629,
     "k198rProb": 7.7,
     "probDifference": -4.6,
     "geneName": "KCNB2",
     "known": "No",
     "id": 476
    },
    {
     "sequence": "KSKKQGNSSNSRPAR",
     "pos": 96,
     "wtScore": 66.6669,
     "wtProb": 20.8,
     "k198rScore": 50.5807,
     "k198rProb": 16.2,
     "probDifference": -4.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 477
    },
    {
     "sequence": "ERRNSNVSQASMSSR",
     "pos": 625,
     "wtScore": 61.5669,
     "wtProb": 17.8,
     "k198rScore": 47.03,
     "k198rProb": 13.3,
     "probDifference": -4.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 478
    },
    {
     "sequence": "EGETPCGSSFAVFYF",
     "pos": 1496,
     "wtScore": 54.4082,
     "wtProb": 13.1,
     "k198rScore": 36.8882,
     "k198rProb": 8.5,
     "probDifference": -4.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 479
    },
    {
     "sequence": "ADQSLEDSPSKVPKS",
     "pos": 475,
     "wtScore": 64.0589,
     "wtProb": 19.5,
     "k198rScore": 49.3741,
     "k198rProb": 15,
     "probDifference": -4.5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 480
    },
    {
     "sequence": "LEMESIPSPVAPLPT",
     "pos": 566,
     "wtScore": 56.7181,
     "wtProb": 14.5,
     "k198rScore": 41.4198,
     "k198rProb": 10,
     "probDifference": -4.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 481
    },
    {
     "sequence": "ERRNSNLSQTSRSSR",
     "pos": 622,
     "wtScore": 68.7902,
     "wtProb": 21.9,
     "k198rScore": 52.0356,
     "k198rProb": 17.4,
     "probDifference": -4.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 482
    },
    {
     "sequence": "QAQLLHVSSPNLASD",
     "pos": 430,
     "wtScore": 53.351,
     "wtProb": 12.4,
     "k198rScore": 33.0253,
     "k198rProb": 7.9,
     "probDifference": -4.5,
     "geneName": "KCNA1",
     "known": "No",
     "id": 483
    },
    {
     "sequence": "QSDNATDSPKSSLKG",
     "pos": 698,
     "wtScore": 59.3802,
     "wtProb": 16.4,
     "k198rScore": 44.875,
     "k198rProb": 11.9,
     "probDifference": -4.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 484
    },
    {
     "sequence": "DFADDEHSTFEDNDS",
     "pos": 598,
     "wtScore": 107.03,
     "wtProb": 34.1,
     "k198rScore": 74.7325,
     "k198rProb": 29.6,
     "probDifference": -4.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 485
    },
    {
     "sequence": "PRPAGYPSTVSTVEG",
     "pos": 1833,
     "wtScore": 55.2247,
     "wtProb": 13.6,
     "k198rScore": 39.0497,
     "k198rProb": 9.1,
     "probDifference": -4.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 486
    },
    {
     "sequence": "HPIIQPRSWENSKFD",
     "pos": 1109,
     "wtScore": 56.2559,
     "wtProb": 14.2,
     "k198rScore": 40.7553,
     "k198rProb": 9.7,
     "probDifference": -4.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 487
    },
    {
     "sequence": "MEEMCALSFSQELDY",
     "pos": 113,
     "wtScore": 61.4527,
     "wtProb": 17.8,
     "k198rScore": 47.099,
     "k198rProb": 13.3,
     "probDifference": -4.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 488
    },
    {
     "sequence": "DLLEKPNSSVAAKIL",
     "pos": 183,
     "wtScore": 54.7943,
     "wtProb": 13.3,
     "k198rScore": 38.1879,
     "k198rProb": 8.9,
     "probDifference": -4.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 489
    },
    {
     "sequence": "DLLEKPNSSVAAKIL",
     "pos": 187,
     "wtScore": 54.7943,
     "wtProb": 13.3,
     "k198rScore": 38.1879,
     "k198rProb": 8.9,
     "probDifference": -4.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 490
    },
    {
     "sequence": "SHEKLVDSTFTPSSY",
     "pos": 1798,
     "wtScore": 53.5242,
     "wtProb": 12.5,
     "k198rScore": 34.5239,
     "k198rProb": 8.1,
     "probDifference": -4.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 491
    },
    {
     "sequence": "GFLTLILSSFLVYLV",
     "pos": 274,
     "wtScore": 52.7665,
     "wtProb": 12,
     "k198rScore": 28.9272,
     "k198rProb": 7.6,
     "probDifference": -4.4,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 492
    },
    {
     "sequence": "PQPSAILSPCSYTTA",
     "pos": 694,
     "wtScore": 60.7359,
     "wtProb": 17.3,
     "k198rScore": 46.4083,
     "k198rProb": 12.9,
     "probDifference": -4.4,
     "geneName": "HCN1",
     "known": "No",
     "id": 493
    },
    {
     "sequence": "HGPLSLNSPDPYEKI",
     "pos": 652,
     "wtScore": 62.4811,
     "wtProb": 18.5,
     "k198rScore": 48.2168,
     "k198rProb": 14.1,
     "probDifference": -4.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 494
    },
    {
     "sequence": "IEEGLGKSWWILRKT",
     "pos": 1182,
     "wtScore": 54.6072,
     "wtProb": 13.2,
     "k198rScore": 37.8857,
     "k198rProb": 8.8,
     "probDifference": -4.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 495
    },
    {
     "sequence": "TPGNSEESNRISITF",
     "pos": 1309,
     "wtScore": 61.7674,
     "wtProb": 18,
     "k198rScore": 47.6025,
     "k198rProb": 13.6,
     "probDifference": -4.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 496
    },
    {
     "sequence": "KQKVKKVSSIYKKDK",
     "pos": 1928,
     "wtScore": 57.8625,
     "wtProb": 15.3,
     "k198rScore": 43.2495,
     "k198rProb": 11,
     "probDifference": -4.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 497
    },
    {
     "sequence": "GTTSGIGSSVEKYVV",
     "pos": 1079,
     "wtScore": 100.362,
     "wtProb": 32,
     "k198rScore": 73.0164,
     "k198rProb": 27.7,
     "probDifference": -4.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 498
    },
    {
     "sequence": "SKRKFKESLRPYDVM",
     "pos": 557,
     "wtScore": 43.4433,
     "wtProb": 9,
     "k198rScore": 47.2015,
     "k198rProb": 13.4,
     "probDifference": 4.3,
     "geneName": "KCNQ2",
     "known": "Yes",
     "id": 499
    },
    {
     "sequence": "LANVEGLSVLRSFRL",
     "pos": 846,
     "wtScore": 62.7754,
     "wtProb": 18.6,
     "k198rScore": 48.4329,
     "k198rProb": 14.3,
     "probDifference": -4.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 500
    },
    {
     "sequence": "LANVEGLSVLRSFRL",
     "pos": 855,
     "wtScore": 62.7754,
     "wtProb": 18.6,
     "k198rScore": 48.4329,
     "k198rProb": 14.3,
     "probDifference": -4.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 501
    },
    {
     "sequence": "LSGEGKGSTDDEAED",
     "pos": 1173,
     "wtScore": 83.8432,
     "wtProb": 25.3,
     "k198rScore": 58.5293,
     "k198rProb": 21,
     "probDifference": -4.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 502
    },
    {
     "sequence": "EENPFICSSRRDNGM",
     "pos": 291,
     "wtScore": 53.462,
     "wtProb": 12.5,
     "k198rScore": 35.1349,
     "k198rProb": 8.2,
     "probDifference": -4.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 503
    },
    {
     "sequence": "LKKSRSASTISKSDY",
     "pos": 450,
     "wtScore": 54.4607,
     "wtProb": 13.1,
     "k198rScore": 37.8828,
     "k198rProb": 8.8,
     "probDifference": -4.3,
     "geneName": "KCNA2",
     "known": "No",
     "id": 504
    },
    {
     "sequence": "THREKKESTPSTASL",
     "pos": 1936,
     "wtScore": 54.9221,
     "wtProb": 13.4,
     "k198rScore": 39.0775,
     "k198rProb": 9.1,
     "probDifference": -4.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 505
    },
    {
     "sequence": "TPALYILSPFNLIRR",
     "pos": 115,
     "wtScore": 65.5238,
     "wtProb": 20.4,
     "k198rScore": 50.5189,
     "k198rProb": 16.2,
     "probDifference": -4.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 506
    },
    {
     "sequence": "LEGRGSLSPPLIMCT",
     "pos": 1072,
     "wtScore": 58.1488,
     "wtProb": 15.5,
     "k198rScore": 43.7002,
     "k198rProb": 11.3,
     "probDifference": -4.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 507
    },
    {
     "sequence": "EEAESHFSSIPDAFW",
     "pos": 356,
     "wtScore": 59.2343,
     "wtProb": 16.3,
     "k198rScore": 45.2285,
     "k198rProb": 12.1,
     "probDifference": -4.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 508
    },
    {
     "sequence": "NFKENRGSAPQTPPS",
     "pos": 724,
     "wtScore": 56.4786,
     "wtProb": 14.4,
     "k198rScore": 53.6559,
     "k198rProb": 18.6,
     "probDifference": 4.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 509
    },
    {
     "sequence": "QKYSPSHSTRSWATP",
     "pos": 1976,
     "wtScore": 52.5349,
     "wtProb": 11.9,
     "k198rScore": 30.5495,
     "k198rProb": 7.7,
     "probDifference": -4.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 510
    },
    {
     "sequence": "QLLYSVDSSPPKSLP",
     "pos": 771,
     "wtScore": 56.2518,
     "wtProb": 14.2,
     "k198rScore": 41.3468,
     "k198rProb": 10,
     "probDifference": -4.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 511
    },
    {
     "sequence": "RDSDTPLSLMSVNHE",
     "pos": 790,
     "wtScore": 48.937,
     "wtProb": 10.3,
     "k198rScore": 48.7252,
     "k198rProb": 14.5,
     "probDifference": 4.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 512
    },
    {
     "sequence": "RQFHGSASSLVEAVL",
     "pos": 2116,
     "wtScore": 58.5924,
     "wtProb": 15.8,
     "k198rScore": 44.3899,
     "k198rProb": 11.6,
     "probDifference": -4.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 513
    },
    {
     "sequence": "YPTKGTSSPAEAEKK",
     "pos": 664,
     "wtScore": 97.4593,
     "wtProb": 30.7,
     "k198rScore": 78.0381,
     "k198rProb": 34.9,
     "probDifference": 4.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 514
    },
    {
     "sequence": "SWSFGDRSRARQAFR",
     "pos": 489,
     "wtScore": 50.8953,
     "wtProb": 11.1,
     "k198rScore": 49.692,
     "k198rProb": 15.2,
     "probDifference": 4.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 515
    },
    {
     "sequence": "NGDVGPLSHRQDYEL",
     "pos": 2122,
     "wtScore": 64.5403,
     "wtProb": 19.8,
     "k198rScore": 50.1384,
     "k198rProb": 15.6,
     "probDifference": -4.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 516
    },
    {
     "sequence": "GQCGERESLLSGEGK",
     "pos": 1164,
     "wtScore": 36.5852,
     "wtProb": 8.5,
     "k198rScore": 45.9625,
     "k198rProb": 12.6,
     "probDifference": 4.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 517
    },
    {
     "sequence": "ASAFFIFSSNNRFRL",
     "pos": 883,
     "wtScore": 54.6705,
     "wtProb": 13.2,
     "k198rScore": 38.9291,
     "k198rProb": 9.1,
     "probDifference": -4.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 518
    },
    {
     "sequence": "GPGSLLVSMDQLASY",
     "pos": 683,
     "wtScore": 54.6557,
     "wtProb": 13.2,
     "k198rScore": 38.8968,
     "k198rProb": 9.1,
     "probDifference": -4.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 519
    },
    {
     "sequence": "VVALGLLSGEHAYLQ",
     "pos": 1353,
     "wtScore": 71.7447,
     "wtProb": 23,
     "k198rScore": 72.4051,
     "k198rProb": 27.1,
     "probDifference": 4.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 520
    },
    {
     "sequence": "PRRNSRASLFSFRGR",
     "pos": 575,
     "wtScore": 40.9959,
     "wtProb": 8.8,
     "k198rScore": 46.2969,
     "k198rProb": 12.9,
     "probDifference": 4.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 521
    },
    {
     "sequence": "GERWGQASCRAEHLT",
     "pos": 2254,
     "wtScore": 61.3931,
     "wtProb": 17.7,
     "k198rScore": 47.5838,
     "k198rProb": 13.6,
     "probDifference": -4.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 522
    },
    {
     "sequence": "LTNALEISNIVFTSM",
     "pos": 827,
     "wtScore": 57.42,
     "wtProb": 15,
     "k198rScore": 43.0622,
     "k198rProb": 10.9,
     "probDifference": -4.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 523
    },
    {
     "sequence": "RRKGFRFSLEGSRLT",
     "pos": 539,
     "wtScore": 43.7786,
     "wtProb": 9.1,
     "k198rScore": 46.8548,
     "k198rProb": 13.2,
     "probDifference": 4.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 524
    },
    {
     "sequence": "SSYSSTGSNANINNA",
     "pos": 1810,
     "wtScore": 57.1598,
     "wtProb": 14.8,
     "k198rScore": 42.6994,
     "k198rProb": 10.7,
     "probDifference": -4.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 525
    },
    {
     "sequence": "RSSSYHVSMDLLEDP",
     "pos": 690,
     "wtScore": 57.406,
     "wtProb": 15,
     "k198rScore": 43.1054,
     "k198rProb": 10.9,
     "probDifference": -4.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 526
    },
    {
     "sequence": "EKTDGSSSTTSPPSY",
     "pos": 1962,
     "wtScore": 52.8208,
     "wtProb": 12,
     "k198rScore": 33.976,
     "k198rProb": 8,
     "probDifference": -4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 527
    },
    {
     "sequence": "VCSPPVQSPLAARTF",
     "pos": 709,
     "wtScore": 54.4602,
     "wtProb": 13.1,
     "k198rScore": 38.7228,
     "k198rProb": 9,
     "probDifference": -4,
     "geneName": "HCN1",
     "known": "No",
     "id": 528
    },
    {
     "sequence": "LDDDSEDSCCLRLHK",
     "pos": 1248,
     "wtScore": 65.763,
     "wtProb": 20.5,
     "k198rScore": 50.9079,
     "k198rProb": 16.5,
     "probDifference": -4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 529
    },
    {
     "sequence": "NFMEGDPSPLLPVLG",
     "pos": 681,
     "wtScore": 55.3934,
     "wtProb": 13.7,
     "k198rScore": 40.523,
     "k198rProb": 9.7,
     "probDifference": -4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 530
    },
    {
     "sequence": "LVELEIMSPLGISVF",
     "pos": 610,
     "wtScore": 56.7349,
     "wtProb": 14.5,
     "k198rScore": 42.3589,
     "k198rProb": 10.5,
     "probDifference": -4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 531
    },
    {
     "sequence": "FWYVVNSSPFEYMMF",
     "pos": 1204,
     "wtScore": 85.9414,
     "wtProb": 26.1,
     "k198rScore": 64.1589,
     "k198rProb": 22.1,
     "probDifference": -4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 532
    },
    {
     "sequence": "IVDSKYFSRGIMMAI",
     "pos": 795,
     "wtScore": 49.0071,
     "wtProb": 10.3,
     "k198rScore": 48.5177,
     "k198rProb": 14.3,
     "probDifference": 4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 533
    },
    {
     "sequence": "ILTVFCLSVFALIGL",
     "pos": 259,
     "wtScore": 56.9814,
     "wtProb": 14.7,
     "k198rScore": 42.7576,
     "k198rProb": 10.8,
     "probDifference": -4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 534
    },
    {
     "sequence": "ILTVFCLSVFALIGL",
     "pos": 258,
     "wtScore": 56.9814,
     "wtProb": 14.7,
     "k198rScore": 42.7576,
     "k198rProb": 10.8,
     "probDifference": -4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 535
    },
    {
     "sequence": "ILTVFCLSVFALIGL",
     "pos": 262,
     "wtScore": 56.9814,
     "wtProb": 14.7,
     "k198rScore": 42.7576,
     "k198rProb": 10.8,
     "probDifference": -4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 536
    },
    {
     "sequence": "ILTVFCLSVFALIGL",
     "pos": 258,
     "wtScore": 56.9814,
     "wtProb": 14.7,
     "k198rScore": 42.7576,
     "k198rProb": 10.8,
     "probDifference": -4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 537
    },
    {
     "sequence": "VNSDRRDSLQQTNTT",
     "pos": 1699,
     "wtScore": 43.7439,
     "wtProb": 9.1,
     "k198rScore": 46.6679,
     "k198rProb": 13.1,
     "probDifference": 4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 538
    },
    {
     "sequence": "MMALFTVSTFEGWPE",
     "pos": 1131,
     "wtScore": 86.0353,
     "wtProb": 26.1,
     "k198rScore": 63.5551,
     "k198rProb": 22.2,
     "probDifference": -4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 539
    },
    {
     "sequence": "SADDNHLSPSRWKWA",
     "pos": 487,
     "wtScore": 58.9086,
     "wtProb": 16.1,
     "k198rScore": 45.3103,
     "k198rProb": 12.1,
     "probDifference": -4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 540
    },
    {
     "sequence": "TATASEHSREPSAAG",
     "pos": 469,
     "wtScore": 58.5706,
     "wtProb": 15.8,
     "k198rScore": 44.7271,
     "k198rProb": 11.8,
     "probDifference": -4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 541
    },
    {
     "sequence": "SVFPAKASATGAGPA",
     "pos": 25,
     "wtScore": 48.344,
     "wtProb": 10.2,
     "k198rScore": 48.3027,
     "k198rProb": 14.1,
     "probDifference": 4,
     "geneName": "HCN1",
     "known": "No",
     "id": 542
    },
    {
     "sequence": "YCRLYSLSVDNFNEV",
     "pos": 633,
     "wtScore": 72.5475,
     "wtProb": 23.4,
     "k198rScore": 54.8188,
     "k198rProb": 19.4,
     "probDifference": -3.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 543
    },
    {
     "sequence": "YCRLYSLSVDNFNEV",
     "pos": 564,
     "wtScore": 72.5475,
     "wtProb": 23.4,
     "k198rScore": 54.8188,
     "k198rProb": 19.4,
     "probDifference": -3.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 544
    },
    {
     "sequence": "LESSSEASKLSSKSA",
     "pos": 488,
     "wtScore": 57.0249,
     "wtProb": 14.7,
     "k198rScore": 42.8644,
     "k198rProb": 10.8,
     "probDifference": -3.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 545
    },
    {
     "sequence": "DIVVAMASAGGAKIL",
     "pos": 1384,
     "wtScore": 59.7724,
     "wtProb": 16.7,
     "k198rScore": 57.6962,
     "k198rProb": 20.6,
     "probDifference": 3.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 546
    },
    {
     "sequence": "MMALFTVSTFEGWPA",
     "pos": 1097,
     "wtScore": 85.538,
     "wtProb": 25.9,
     "k198rScore": 61.2947,
     "k198rProb": 22,
     "probDifference": -3.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 547
    },
    {
     "sequence": "CLVLSVFSTIKEYEK",
     "pos": 112,
     "wtScore": 53.449,
     "wtProb": 12.4,
     "k198rScore": 37.0326,
     "k198rProb": 8.6,
     "probDifference": -3.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 548
    },
    {
     "sequence": "IKMNYLKSWFVVDFI",
     "pos": 219,
     "wtScore": 54.8453,
     "wtProb": 13.3,
     "k198rScore": 39.9189,
     "k198rProb": 9.5,
     "probDifference": -3.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 549
    },
    {
     "sequence": "DEGSARPSAAEGGST",
     "pos": 2206,
     "wtScore": 98.7705,
     "wtProb": 31.4,
     "k198rScore": 78.1698,
     "k198rProb": 35.2,
     "probDifference": 3.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 550
    },
    {
     "sequence": "EQFSSVLSVGNLVFT",
     "pos": 791,
     "wtScore": 70.0216,
     "wtProb": 22.4,
     "k198rScore": 53.526,
     "k198rProb": 18.5,
     "probDifference": -3.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 551
    },
    {
     "sequence": "RQNYGYYSRYPGRNI",
     "pos": 1868,
     "wtScore": 56.0588,
     "wtProb": 14.1,
     "k198rScore": 52.6251,
     "k198rProb": 17.9,
     "probDifference": 3.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 552
    },
    {
     "sequence": "VDCNGVVSLVGGPSA",
     "pos": 654,
     "wtScore": 47.5188,
     "wtProb": 9.9,
     "k198rScore": 47.8204,
     "k198rProb": 13.7,
     "probDifference": 3.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 553
    },
    {
     "sequence": "CIVKIVRSSSSTGQK",
     "pos": 691,
     "wtScore": 53.3495,
     "wtProb": 12.4,
     "k198rScore": 37.0397,
     "k198rProb": 8.6,
     "probDifference": -3.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 554
    },
    {
     "sequence": "FNFFTRESLAAIERR",
     "pos": 20,
     "wtScore": 40.3285,
     "wtProb": 8.7,
     "k198rScore": 45.8533,
     "k198rProb": 12.5,
     "probDifference": 3.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 555
    },
    {
     "sequence": "SEPSLLSTEMLSYQD",
     "pos": 1938,
     "wtScore": 1.66326,
     "wtProb": 8.7,
     "k198rScore": -36.9564,
     "k198rProb": 4.9,
     "probDifference": -3.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 556
    },
    {
     "sequence": "FRIKGAASRQNSEEA",
     "pos": 502,
     "wtScore": 56.0613,
     "wtProb": 14.1,
     "k198rScore": 52.6663,
     "k198rProb": 17.9,
     "probDifference": 3.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 557
    },
    {
     "sequence": "SRRSSWSSLGRAPSL",
     "pos": 1146,
     "wtScore": 40.9442,
     "wtProb": 8.8,
     "k198rScore": 45.8749,
     "k198rProb": 12.5,
     "probDifference": 3.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 558
    },
    {
     "sequence": "EGGGKPNSSSNSRDD",
     "pos": 8,
     "wtScore": 52.346,
     "wtProb": 11.8,
     "k198rScore": 33.9893,
     "k198rProb": 8,
     "probDifference": -3.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 559
    },
    {
     "sequence": "ARDPKFVSATKHEIA",
     "pos": 2081,
     "wtScore": 54.7525,
     "wtProb": 13.3,
     "k198rScore": 51.5838,
     "k198rProb": 17,
     "probDifference": 3.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 560
    },
    {
     "sequence": "PFLDAAPSLPDSRRG",
     "pos": 1098,
     "wtScore": 55.5933,
     "wtProb": 13.8,
     "k198rScore": 52.2637,
     "k198rProb": 17.6,
     "probDifference": 3.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 561
    },
    {
     "sequence": "SESDPEGSKDKLDDT",
     "pos": 1121,
     "wtScore": 71.1133,
     "wtProb": 22.8,
     "k198rScore": 54.3105,
     "k198rProb": 19.1,
     "probDifference": -3.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 562
    },
    {
     "sequence": "SIMFIVLSTIALSLN",
     "pos": 201,
     "wtScore": 51.67,
     "wtProb": 11.4,
     "k198rScore": 30.3209,
     "k198rProb": 7.7,
     "probDifference": -3.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 563
    },
    {
     "sequence": "IDRINENSITEKTDL",
     "pos": 1967,
     "wtScore": 100.319,
     "wtProb": 32,
     "k198rScore": 73.6506,
     "k198rProb": 28.3,
     "probDifference": -3.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 564
    },
    {
     "sequence": "DFTETERSPLPPPSA",
     "pos": 618,
     "wtScore": 58.384,
     "wtProb": 15.6,
     "k198rScore": 44.8742,
     "k198rProb": 11.9,
     "probDifference": -3.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 565
    },
    {
     "sequence": "DNDSRRDSLFVPHRH",
     "pos": 609,
     "wtScore": 41.3584,
     "wtProb": 8.8,
     "k198rScore": 45.8093,
     "k198rProb": 12.5,
     "probDifference": 3.7,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 566
    },
    {
     "sequence": "VLSTIALSLNTLPEL",
     "pos": 206,
     "wtScore": 51.3268,
     "wtProb": 11.2,
     "k198rScore": 49.3455,
     "k198rProb": 14.9,
     "probDifference": 3.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 567
    },
    {
     "sequence": "VLSTIALSLNTLPEL",
     "pos": 210,
     "wtScore": 51.3268,
     "wtProb": 11.2,
     "k198rScore": 49.3455,
     "k198rProb": 14.9,
     "probDifference": 3.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 568
    },
    {
     "sequence": "PSTASLPSYDSVTKP",
     "pos": 1945,
     "wtScore": 55.6687,
     "wtProb": 13.9,
     "k198rScore": 41.6492,
     "k198rProb": 10.2,
     "probDifference": -3.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 569
    },
    {
     "sequence": "DRINSIMSVVTNTLV",
     "pos": 701,
     "wtScore": 56.6115,
     "wtProb": 14.5,
     "k198rScore": 42.9016,
     "k198rProb": 10.8,
     "probDifference": -3.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 570
    },
    {
     "sequence": "PTPGAALSWQAAIDA",
     "pos": 50,
     "wtScore": 58.545,
     "wtProb": 15.8,
     "k198rScore": 45.2422,
     "k198rProb": 12.1,
     "probDifference": -3.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 571
    },
    {
     "sequence": "GSPEKARSSSSPQHL",
     "pos": 516,
     "wtScore": 54.0169,
     "wtProb": 12.8,
     "k198rScore": 38.9413,
     "k198rProb": 9.1,
     "probDifference": -3.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 572
    },
    {
     "sequence": "AARAAAPSPDRRDSA",
     "pos": 859,
     "wtScore": 66.9968,
     "wtProb": 21,
     "k198rScore": 51.8842,
     "k198rProb": 17.3,
     "probDifference": -3.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 573
    },
    {
     "sequence": "GGGRPGESPGATPAP",
     "pos": 12,
     "wtScore": 59.21,
     "wtProb": 16.3,
     "k198rScore": 55.7933,
     "k198rProb": 20,
     "probDifference": 3.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 574
    },
    {
     "sequence": "PDKDHPGSSVKGDCG",
     "pos": 1739,
     "wtScore": 54.6763,
     "wtProb": 13.2,
     "k198rScore": 40.2858,
     "k198rProb": 9.6,
     "probDifference": -3.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 575
    },
    {
     "sequence": "LVASLLNSVRSIASL",
     "pos": 646,
     "wtScore": 52.2024,
     "wtProb": 11.7,
     "k198rScore": 34.233,
     "k198rProb": 8,
     "probDifference": -3.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 576
    },
    {
     "sequence": "ENKYQEVSQKDSHEQ",
     "pos": 515,
     "wtScore": 80.9278,
     "wtProb": 25.2,
     "k198rScore": 60.2673,
     "k198rProb": 21.5,
     "probDifference": -3.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 577
    },
    {
     "sequence": "LEKPGSPSATPAPGG",
     "pos": 2339,
     "wtScore": 57.0526,
     "wtProb": 14.7,
     "k198rScore": 53.1118,
     "k198rProb": 18.4,
     "probDifference": 3.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 578
    },
    {
     "sequence": "AALPRESSSVLNTDP",
     "pos": 871,
     "wtScore": 52.2195,
     "wtProb": 11.7,
     "k198rScore": 34.4088,
     "k198rProb": 8.1,
     "probDifference": -3.6,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 579
    },
    {
     "sequence": "IMNQSLLSIPGSPFL",
     "pos": 548,
     "wtScore": 54.3727,
     "wtProb": 13,
     "k198rScore": 39.7663,
     "k198rProb": 9.4,
     "probDifference": -3.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 580
    },
    {
     "sequence": "NLNTEEFSSESELEE",
     "pos": 1120,
     "wtScore": 71.6397,
     "wtProb": 22.9,
     "k198rScore": 54.6265,
     "k198rProb": 19.3,
     "probDifference": -3.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 581
    },
    {
     "sequence": "ENRGCCGSLCQAISK",
     "pos": 490,
     "wtScore": 45.4279,
     "wtProb": 9.4,
     "k198rScore": 46.5776,
     "k198rProb": 13,
     "probDifference": 3.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 582
    },
    {
     "sequence": "SSRRSSWSSLGRAPS",
     "pos": 1145,
     "wtScore": 53.2699,
     "wtProb": 12.3,
     "k198rScore": 37.5591,
     "k198rProb": 8.7,
     "probDifference": -3.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 583
    },
    {
     "sequence": "RDLRRLYSVDAQGFL",
     "pos": 2136,
     "wtScore": 66.7417,
     "wtProb": 20.9,
     "k198rScore": 51.8577,
     "k198rProb": 17.2,
     "probDifference": -3.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 584
    },
    {
     "sequence": "NSRASLFSFRGRAKD",
     "pos": 578,
     "wtScore": 54.6891,
     "wtProb": 13.2,
     "k198rScore": 40.4541,
     "k198rProb": 9.6,
     "probDifference": -3.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 585
    },
    {
     "sequence": "PSLPHEVSTLISRPH",
     "pos": 797,
     "wtScore": 51.4585,
     "wtProb": 11.3,
     "k198rScore": 30.608,
     "k198rProb": 7.7,
     "probDifference": -3.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 586
    },
    {
     "sequence": "AVILENFSVATEESA",
     "pos": 1774,
     "wtScore": 60.625,
     "wtProb": 17.3,
     "k198rScore": 47.7925,
     "k198rProb": 13.7,
     "probDifference": -3.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 587
    },
    {
     "sequence": "AVILENFSVATEESA",
     "pos": 1779,
     "wtScore": 60.625,
     "wtProb": 17.3,
     "k198rScore": 47.7925,
     "k198rProb": 13.7,
     "probDifference": -3.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 588
    },
    {
     "sequence": "AVILENFSVATEESA",
     "pos": 1789,
     "wtScore": 60.625,
     "wtProb": 17.3,
     "k198rScore": 47.7925,
     "k198rProb": 13.7,
     "probDifference": -3.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 589
    },
    {
     "sequence": "GRSSQKVSLKDRVFS",
     "pos": 437,
     "wtScore": 52.715,
     "wtProb": 12,
     "k198rScore": 50.0429,
     "k198rProb": 15.6,
     "probDifference": 3.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 590
    },
    {
     "sequence": "GPAPAAASPGPPPPA",
     "pos": 770,
     "wtScore": 61.5646,
     "wtProb": 17.8,
     "k198rScore": 59.8575,
     "k198rProb": 21.4,
     "probDifference": 3.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 591
    },
    {
     "sequence": "NASLEEHSIEKNITV",
     "pos": 290,
     "wtScore": 65.9273,
     "wtProb": 20.6,
     "k198rScore": 51.558,
     "k198rProb": 17,
     "probDifference": -3.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 592
    },
    {
     "sequence": "KSLPGSTSPKFSTGT",
     "pos": 782,
     "wtScore": 55.833,
     "wtProb": 14,
     "k198rScore": 42.132,
     "k198rProb": 10.4,
     "probDifference": -3.6,
     "geneName": "KCNB1",
     "known": "No",
     "id": 593
    },
    {
     "sequence": "NSKTSIFSFRGRAKD",
     "pos": 578,
     "wtScore": 56.6528,
     "wtProb": 14.5,
     "k198rScore": 43.1934,
     "k198rProb": 11,
     "probDifference": -3.6,
     "geneName": "SCN3A",
     "known": "Yes",
     "id": 594
    },
    {
     "sequence": "TPEKTDGSSSTTSPP",
     "pos": 1960,
     "wtScore": 55.5367,
     "wtProb": 13.8,
     "k198rScore": 41.7742,
     "k198rProb": 10.2,
     "probDifference": -3.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 595
    },
    {
     "sequence": "VDCNGVVSLVGGPST",
     "pos": 654,
     "wtScore": 48.4162,
     "wtProb": 10.2,
     "k198rScore": 47.8012,
     "k198rProb": 13.7,
     "probDifference": 3.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 596
    },
    {
     "sequence": "GEEYTCGSNFAIVYF",
     "pos": 1436,
     "wtScore": 54.603,
     "wtProb": 13.2,
     "k198rScore": 40.4184,
     "k198rProb": 9.6,
     "probDifference": -3.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 597
    },
    {
     "sequence": "DTSISIPSVDHEELE",
     "pos": 791,
     "wtScore": 62.9728,
     "wtProb": 18.7,
     "k198rScore": 49.592,
     "k198rProb": 15.2,
     "probDifference": -3.5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 598
    },
    {
     "sequence": "VSENGHHSSHKHDRE",
     "pos": 1786,
     "wtScore": 56.4483,
     "wtProb": 14.4,
     "k198rScore": 43.0276,
     "k198rProb": 10.8,
     "probDifference": -3.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 599
    },
    {
     "sequence": "KDVIEQYSAGHLDML",
     "pos": 550,
     "wtScore": 58.1733,
     "wtProb": 15.5,
     "k198rScore": 54.2295,
     "k198rProb": 19,
     "probDifference": 3.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 600
    },
    {
     "sequence": "ARHLSNDSTLASFSE",
     "pos": 444,
     "wtScore": 51.8816,
     "wtProb": 11.5,
     "k198rScore": 33.7973,
     "k198rProb": 8,
     "probDifference": -3.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 601
    },
    {
     "sequence": "EMCALSFSQELDYWG",
     "pos": 115,
     "wtScore": 54.4475,
     "wtProb": 13.1,
     "k198rScore": 40.1988,
     "k198rProb": 9.5,
     "probDifference": -3.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 602
    },
    {
     "sequence": "IVIVGLFSVILEQLT",
     "pos": 210,
     "wtScore": 59.5388,
     "wtProb": 16.5,
     "k198rScore": 46.5031,
     "k198rProb": 13,
     "probDifference": -3.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 603
    },
    {
     "sequence": "NLNTEDFSSESDLEE",
     "pos": 1132,
     "wtScore": 66.7942,
     "wtProb": 20.9,
     "k198rScore": 52.0362,
     "k198rProb": 17.4,
     "probDifference": -3.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 604
    },
    {
     "sequence": "LADVEGLSVLRSFRL",
     "pos": 840,
     "wtScore": 62.0804,
     "wtProb": 18.2,
     "k198rScore": 49.022,
     "k198rProb": 14.7,
     "probDifference": -3.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 605
    },
    {
     "sequence": "NNKGERDSFPKSESE",
     "pos": 521,
     "wtScore": 52.8751,
     "wtProb": 12.1,
     "k198rScore": 50.0502,
     "k198rProb": 15.6,
     "probDifference": 3.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 606
    },
    {
     "sequence": "TKGRRTASVRADTYC",
     "pos": 551,
     "wtScore": 56.704,
     "wtProb": 14.5,
     "k198rScore": 43.3462,
     "k198rProb": 11,
     "probDifference": -3.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 607
    },
    {
     "sequence": "EEKQVDSSPNCFADK",
     "pos": 829,
     "wtScore": 59.1856,
     "wtProb": 16.3,
     "k198rScore": 46.2282,
     "k198rProb": 12.8,
     "probDifference": -3.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 608
    },
    {
     "sequence": "SEKNHFESSPLPTSP",
     "pos": 798,
     "wtScore": 54.3381,
     "wtProb": 13,
     "k198rScore": 40.199,
     "k198rProb": 9.5,
     "probDifference": -3.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 609
    },
    {
     "sequence": "NSRTSLFSFRGRAKD",
     "pos": 575,
     "wtScore": 55.1183,
     "wtProb": 13.5,
     "k198rScore": 41.4469,
     "k198rProb": 10,
     "probDifference": -3.5,
     "geneName": "SCN1A",
     "known": "Yes",
     "id": 610
    },
    {
     "sequence": "SILFIVLSTIALSLN",
     "pos": 205,
     "wtScore": 50.8344,
     "wtProb": 11.1,
     "k198rScore": 29.0521,
     "k198rProb": 7.6,
     "probDifference": -3.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 611
    },
    {
     "sequence": "EVIVDMKSTSSIDSF",
     "pos": 598,
     "wtScore": 50.9323,
     "wtProb": 11.1,
     "k198rScore": 29.9319,
     "k198rProb": 7.6,
     "probDifference": -3.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 612
    },
    {
     "sequence": "PVGITFFTEQTTTPW",
     "pos": 167,
     "wtScore": 3.41487,
     "wtProb": 8.7,
     "k198rScore": -29.4185,
     "k198rProb": 5.2,
     "probDifference": -3.4,
     "geneName": "HCN1",
     "known": "No",
     "id": 613
    },
    {
     "sequence": "SRIKSLQSRVDQIVG",
     "pos": 586,
     "wtScore": 61.0362,
     "wtProb": 17.5,
     "k198rScore": 48.2582,
     "k198rProb": 14.1,
     "probDifference": -3.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 614
    },
    {
     "sequence": "RRSSSTMSKSEYMEI",
     "pos": 449,
     "wtScore": 95.4554,
     "wtProb": 30.3,
     "k198rScore": 72.2143,
     "k198rProb": 26.9,
     "probDifference": -3.4,
     "geneName": "KCNA1",
     "known": "No",
     "id": 615
    },
    {
     "sequence": "EEVSHITSSACPWQP",
     "pos": 2105,
     "wtScore": 51.375,
     "wtProb": 11.3,
     "k198rScore": 32.4559,
     "k198rProb": 7.8,
     "probDifference": -3.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 616
    },
    {
     "sequence": "VDCNGVVSLVGGPSV",
     "pos": 651,
     "wtScore": 48.2163,
     "wtProb": 10.1,
     "k198rScore": 47.4581,
     "k198rProb": 13.5,
     "probDifference": 3.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 617
    },
    {
     "sequence": "SSTTSPPSYDSVTKP",
     "pos": 1968,
     "wtScore": 57.1348,
     "wtProb": 14.8,
     "k198rScore": 43.8889,
     "k198rProb": 11.4,
     "probDifference": -3.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 618
    },
    {
     "sequence": "NATISTVSSTQRKRQ",
     "pos": 76,
     "wtScore": 51.9542,
     "wtProb": 11.5,
     "k198rScore": 34.9339,
     "k198rProb": 8.1,
     "probDifference": -3.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 619
    },
    {
     "sequence": "EPAPPYHSPEDSREH",
     "pos": 671,
     "wtScore": 83.9242,
     "wtProb": 25.4,
     "k198rScore": 64.7363,
     "k198rProb": 22,
     "probDifference": -3.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 620
    },
    {
     "sequence": "EKTDMTPSTTSPPSY",
     "pos": 1967,
     "wtScore": 50.7415,
     "wtProb": 11,
     "k198rScore": 29.7867,
     "k198rProb": 7.6,
     "probDifference": -3.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 621
    },
    {
     "sequence": "NLNTEEFSSESDMEE",
     "pos": 1122,
     "wtScore": 71.3985,
     "wtProb": 22.8,
     "k198rScore": 54.7604,
     "k198rProb": 19.4,
     "probDifference": -3.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 622
    },
    {
     "sequence": "IGVFSESSSVASKLS",
     "pos": 486,
     "wtScore": 51.0922,
     "wtProb": 11.2,
     "k198rScore": 32.0108,
     "k198rProb": 7.8,
     "probDifference": -3.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 623
    },
    {
     "sequence": "SSIDSFTSCATDFTE",
     "pos": 607,
     "wtScore": 53.2218,
     "wtProb": 12.3,
     "k198rScore": 38.4512,
     "k198rProb": 8.9,
     "probDifference": -3.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 624
    },
    {
     "sequence": "PYFITLGTELAEKPE",
     "pos": 271,
     "wtScore": 10.2705,
     "wtProb": 8.7,
     "k198rScore": -28.5716,
     "k198rProb": 5.4,
     "probDifference": -3.4,
     "geneName": "KCNA2",
     "known": "No",
     "id": 625
    },
    {
     "sequence": "SNPLKSRSLKVNFKE",
     "pos": 713,
     "wtScore": 38.5688,
     "wtProb": 8.6,
     "k198rScore": 44.9487,
     "k198rProb": 11.9,
     "probDifference": 3.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 626
    },
    {
     "sequence": "RGPKVSFSCRGAASG",
     "pos": 125,
     "wtScore": 53.66,
     "wtProb": 12.6,
     "k198rScore": 39.3748,
     "k198rProb": 9.2,
     "probDifference": -3.3,
     "geneName": "HCN2",
     "known": "No",
     "id": 627
    },
    {
     "sequence": "STFEDNDSRRDSLFV",
     "pos": 605,
     "wtScore": 61.464,
     "wtProb": 17.8,
     "k198rScore": 48.6059,
     "k198rProb": 14.4,
     "probDifference": -3.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 628
    },
    {
     "sequence": "QKSESEDSIRRKGFR",
     "pos": 527,
     "wtScore": 68.289,
     "wtProb": 21.7,
     "k198rScore": 53.2348,
     "k198rProb": 18.3,
     "probDifference": -3.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 629
    },
    {
     "sequence": "ILDLLVVSVSLISFG",
     "pos": 997,
     "wtScore": 51.1284,
     "wtProb": 11.2,
     "k198rScore": 32.4007,
     "k198rProb": 7.8,
     "probDifference": -3.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 630
    },
    {
     "sequence": "AQYLQVTSCPKIPSS",
     "pos": 433,
     "wtScore": 50.825,
     "wtProb": 11.1,
     "k198rScore": 31.266,
     "k198rProb": 7.7,
     "probDifference": -3.3,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 631
    },
    {
     "sequence": "NSKSSIFSFRGPGRF",
     "pos": 566,
     "wtScore": 55.9257,
     "wtProb": 14,
     "k198rScore": 42.6553,
     "k198rProb": 10.7,
     "probDifference": -3.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 632
    },
    {
     "sequence": "DNESRRDSLFVPRRH",
     "pos": 606,
     "wtScore": 41.6097,
     "wtProb": 8.8,
     "k198rScore": 45.2532,
     "k198rProb": 12.1,
     "probDifference": 3.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 633
    },
    {
     "sequence": "RRRSTFPSPEAQRRP",
     "pos": 1590,
     "wtScore": 66.178,
     "wtProb": 20.7,
     "k198rScore": 52.1649,
     "k198rProb": 17.5,
     "probDifference": -3.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 634
    },
    {
     "sequence": "VGPLALGSPRLVRRP",
     "pos": 753,
     "wtScore": 52.1001,
     "wtProb": 11.6,
     "k198rScore": 36.2633,
     "k198rProb": 8.4,
     "probDifference": -3.3,
     "geneName": "HCN2",
     "known": "No",
     "id": 635
    },
    {
     "sequence": "YYVMDAHSFYNFIYF",
     "pos": 391,
     "wtScore": 47.6941,
     "wtProb": 10,
     "k198rScore": 46.9069,
     "k198rProb": 13.2,
     "probDifference": 3.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 636
    },
    {
     "sequence": "KVEKQVLSMEKKLDF",
     "pos": 631,
     "wtScore": 71.5609,
     "wtProb": 22.9,
     "k198rScore": 55.3853,
     "k198rProb": 19.7,
     "probDifference": -3.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 637
    },
    {
     "sequence": "FSNPPDWSKNVEYTF",
     "pos": 157,
     "wtScore": 55.4343,
     "wtProb": 13.7,
     "k198rScore": 42.1777,
     "k198rProb": 10.4,
     "probDifference": -3.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 638
    },
    {
     "sequence": "ESANTKDSADDNHLS",
     "pos": 480,
     "wtScore": 81.6137,
     "wtProb": 25.4,
     "k198rScore": 63.857,
     "k198rProb": 22.1,
     "probDifference": -3.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 639
    },
    {
     "sequence": "KRTVKQASFTYNKNK",
     "pos": 1938,
     "wtScore": 53.968,
     "wtProb": 12.7,
     "k198rScore": 50.3645,
     "k198rProb": 16,
     "probDifference": 3.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 640
    },
    {
     "sequence": "PSTTSPPSYDSVTKP",
     "pos": 1973,
     "wtScore": 56.5525,
     "wtProb": 14.4,
     "k198rScore": 43.6026,
     "k198rProb": 11.2,
     "probDifference": -3.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 641
    },
    {
     "sequence": "IQEGVNNSNEDFREE",
     "pos": 467,
     "wtScore": 81.4158,
     "wtProb": 25.4,
     "k198rScore": 64.4893,
     "k198rProb": 22.1,
     "probDifference": -3.2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 642
    },
    {
     "sequence": "RLVSGVPSLQVVLNS",
     "pos": 255,
     "wtScore": 41.6834,
     "wtProb": 8.8,
     "k198rScore": 45.2461,
     "k198rProb": 12.1,
     "probDifference": 3.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 643
    },
    {
     "sequence": "RLVSGVPSLQVVLNS",
     "pos": 252,
     "wtScore": 41.6834,
     "wtProb": 8.8,
     "k198rScore": 45.2461,
     "k198rProb": 12.1,
     "probDifference": 3.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 644
    },
    {
     "sequence": "PRSSSEISKLSSKSA",
     "pos": 480,
     "wtScore": 54.2073,
     "wtProb": 12.9,
     "k198rScore": 40.5887,
     "k198rProb": 9.7,
     "probDifference": -3.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 645
    },
    {
     "sequence": "KLRELQTTELKMCSL",
     "pos": 1050,
     "wtScore": 4.78955,
     "wtProb": 8.7,
     "k198rScore": -27.7291,
     "k198rProb": 5.5,
     "probDifference": -3.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 646
    },
    {
     "sequence": "LASDSDLSRRSSSTM",
     "pos": 441,
     "wtScore": 57.9065,
     "wtProb": 15.3,
     "k198rScore": 45.2553,
     "k198rProb": 12.1,
     "probDifference": -3.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 647
    },
    {
     "sequence": "VYTATSLSHSNLHSP",
     "pos": 673,
     "wtScore": 51.4881,
     "wtProb": 11.3,
     "k198rScore": 34.7758,
     "k198rProb": 8.1,
     "probDifference": -3.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 648
    },
    {
     "sequence": "GIVIEDNTEIILDPE",
     "pos": 272,
     "wtScore": 9.41001,
     "wtProb": 8.7,
     "k198rScore": -27.3594,
     "k198rProb": 5.5,
     "probDifference": -3.2,
     "geneName": "HCN2",
     "known": "No",
     "id": 649
    },
    {
     "sequence": "ILLEETPSQGDRPLL",
     "pos": 761,
     "wtScore": 75.8384,
     "wtProb": 24.7,
     "k198rScore": 60.1629,
     "k198rProb": 21.6,
     "probDifference": -3.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 650
    },
    {
     "sequence": "LVASLLNSMKSIASL",
     "pos": 645,
     "wtScore": 52.5758,
     "wtProb": 11.9,
     "k198rScore": 37.6349,
     "k198rProb": 8.7,
     "probDifference": -3.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 651
    },
    {
     "sequence": "ENLDALNSCYAAVAP",
     "pos": 818,
     "wtScore": 56.5703,
     "wtProb": 14.5,
     "k198rScore": 43.7913,
     "k198rProb": 11.3,
     "probDifference": -3.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 652
    },
    {
     "sequence": "FICKKTTSNKLENGG",
     "pos": 1921,
     "wtScore": 51.729,
     "wtProb": 11.4,
     "k198rScore": 35.7935,
     "k198rProb": 8.3,
     "probDifference": -3.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 653
    },
    {
     "sequence": "HHHHYHFSHGSPRRP",
     "pos": 531,
     "wtScore": 55.6582,
     "wtProb": 13.9,
     "k198rScore": 51.5391,
     "k198rProb": 17,
     "probDifference": 3.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 654
    },
    {
     "sequence": "PDRKNFDSLLWAIVT",
     "pos": 958,
     "wtScore": 44.6696,
     "wtProb": 9.2,
     "k198rScore": 45.653,
     "k198rProb": 12.4,
     "probDifference": 3.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 655
    },
    {
     "sequence": "LSRHNSKSSIFSFRG",
     "pos": 562,
     "wtScore": 52.6353,
     "wtProb": 11.9,
     "k198rScore": 37.8867,
     "k198rProb": 8.8,
     "probDifference": -3.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 656
    },
    {
     "sequence": "PHPTVGESLASIPQP",
     "pos": 810,
     "wtScore": 38.8618,
     "wtProb": 8.6,
     "k198rScore": 44.599,
     "k198rProb": 11.7,
     "probDifference": 3.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 657
    },
    {
     "sequence": "DIWLTMLSMIVGATC",
     "pos": 446,
     "wtScore": 52.5139,
     "wtProb": 11.9,
     "k198rScore": 37.6056,
     "k198rProb": 8.7,
     "probDifference": -3.1,
     "geneName": "HCN2",
     "known": "No",
     "id": 658
    },
    {
     "sequence": "IAPIPEGSAFFILSK",
     "pos": 863,
     "wtScore": 60.5596,
     "wtProb": 17.3,
     "k198rScore": 48.3266,
     "k198rProb": 14.1,
     "probDifference": -3.1,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 659
    },
    {
     "sequence": "ERESQFPSIPDAFWW",
     "pos": 359,
     "wtScore": 58.4969,
     "wtProb": 15.7,
     "k198rScore": 45.9292,
     "k198rProb": 12.6,
     "probDifference": -3.1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 660
    },
    {
     "sequence": "VLYNGMASTSSWAAL",
     "pos": 985,
     "wtScore": 50.3923,
     "wtProb": 10.9,
     "k198rScore": 31.383,
     "k198rProb": 7.7,
     "probDifference": -3.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 661
    },
    {
     "sequence": "NLRDSDTSISIPSVD",
     "pos": 786,
     "wtScore": 50.8984,
     "wtProb": 11.1,
     "k198rScore": 33.4816,
     "k198rProb": 7.9,
     "probDifference": -3.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 662
    },
    {
     "sequence": "CATDFTETERSPLPP",
     "pos": 615,
     "wtScore": 3.67748,
     "wtProb": 8.7,
     "k198rScore": -27.1644,
     "k198rProb": 5.6,
     "probDifference": -3.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 663
    },
    {
     "sequence": "KVWYVVNSTYFEYLM",
     "pos": 1237,
     "wtScore": 51.5159,
     "wtProb": 11.3,
     "k198rScore": 35.3424,
     "k198rProb": 8.2,
     "probDifference": -3.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 664
    },
    {
     "sequence": "MAGQEETSQDETYEV",
     "pos": 1914,
     "wtScore": 113.904,
     "wtProb": 35.4,
     "k198rScore": 81.731,
     "k198rProb": 38.5,
     "probDifference": 3.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 665
    },
    {
     "sequence": "TGGHGPLSLNSPDPY",
     "pos": 649,
     "wtScore": 50.6333,
     "wtProb": 11,
     "k198rScore": 48.2983,
     "k198rProb": 14.1,
     "probDifference": 3.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 666
    },
    {
     "sequence": "SSQGDTESPSHEKLV",
     "pos": 1789,
     "wtScore": 53.893,
     "wtProb": 12.7,
     "k198rScore": 40.2781,
     "k198rProb": 9.6,
     "probDifference": -3.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 667
    },
    {
     "sequence": "YVFLLVFSCLVLSVF",
     "pos": 104,
     "wtScore": 51.7607,
     "wtProb": 11.5,
     "k198rScore": 36.128,
     "k198rProb": 8.3,
     "probDifference": -3.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 668
    },
    {
     "sequence": "AIILENFSVATEESA",
     "pos": 1769,
     "wtScore": 58.9095,
     "wtProb": 16.1,
     "k198rScore": 46.4386,
     "k198rProb": 13,
     "probDifference": -3.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 669
    },
    {
     "sequence": "AEHLTVPSFAFEPLD",
     "pos": 2264,
     "wtScore": 53.5695,
     "wtProb": 12.5,
     "k198rScore": 39.7915,
     "k198rProb": 9.4,
     "probDifference": -3.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 670
    },
    {
     "sequence": "FFIESPKSSMKTNNP",
     "pos": 658,
     "wtScore": 54.3123,
     "wtProb": 13,
     "k198rScore": 41.0154,
     "k198rProb": 9.9,
     "probDifference": -3.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 671
    },
    {
     "sequence": "ESSPLPTSPKFLRQN",
     "pos": 804,
     "wtScore": 50.7244,
     "wtProb": 11,
     "k198rScore": 33.183,
     "k198rProb": 7.9,
     "probDifference": -3.1,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 672
    },
    {
     "sequence": "EVAWKLSSNRERHVP",
     "pos": 1860,
     "wtScore": 96.7463,
     "wtProb": 30.4,
     "k198rScore": 72.5758,
     "k198rProb": 27.3,
     "probDifference": -3.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 673
    },
    {
     "sequence": "LQIPLAVSSPARSGE",
     "pos": 1985,
     "wtScore": 57.0348,
     "wtProb": 14.7,
     "k198rScore": 44.4327,
     "k198rProb": 11.6,
     "probDifference": -3.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 674
    },
    {
     "sequence": "LGFTLRRSYNELGLL",
     "pos": 330,
     "wtScore": 82.9674,
     "wtProb": 25.2,
     "k198rScore": 61.6983,
     "k198rProb": 22.2,
     "probDifference": -3.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 675
    },
    {
     "sequence": "LGFTLRRSYNELGLL",
     "pos": 326,
     "wtScore": 82.9674,
     "wtProb": 25.2,
     "k198rScore": 61.6983,
     "k198rProb": 22.2,
     "probDifference": -3.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 676
    },
    {
     "sequence": "MVETDDQSQEMTNIL",
     "pos": 1555,
     "wtScore": 61.7033,
     "wtProb": 17.9,
     "k198rScore": 49.236,
     "k198rProb": 14.9,
     "probDifference": -3.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 677
    },
    {
     "sequence": "KVQATPPSSATTYVE",
     "pos": 735,
     "wtScore": 53.8169,
     "wtProb": 12.6,
     "k198rScore": 40.3253,
     "k198rProb": 9.6,
     "probDifference": -3.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 678
    },
    {
     "sequence": "IEEDMNNSIAHYRQV",
     "pos": 463,
     "wtScore": 51.397,
     "wtProb": 11.3,
     "k198rScore": 35.4159,
     "k198rProb": 8.2,
     "probDifference": -3.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 679
    },
    {
     "sequence": "KDTLRECSREDKHCL",
     "pos": 1821,
     "wtScore": 74.3624,
     "wtProb": 24.2,
     "k198rScore": 58.8315,
     "k198rProb": 21.1,
     "probDifference": -3.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 680
    },
    {
     "sequence": "GDRPLLGTEVSAPCQ",
     "pos": 770,
     "wtScore": 9.5183,
     "wtProb": 8.7,
     "k198rScore": -26.5888,
     "k198rProb": 5.7,
     "probDifference": -3.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 681
    },
    {
     "sequence": "TSMPTSETESVNTEN",
     "pos": 470,
     "wtScore": 2.33064,
     "wtProb": 8.7,
     "k198rScore": -26.7813,
     "k198rProb": 5.6,
     "probDifference": -3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 682
    },
    {
     "sequence": "AASTRPASSSTPRLG",
     "pos": 841,
     "wtScore": 50.9961,
     "wtProb": 11.1,
     "k198rScore": 34.5722,
     "k198rProb": 8.1,
     "probDifference": -3,
     "geneName": "HCN2",
     "known": "No",
     "id": 683
    },
    {
     "sequence": "ALLLSSFSADNLAAT",
     "pos": 980,
     "wtScore": 64.7152,
     "wtProb": 19.9,
     "k198rScore": 51.3302,
     "k198rProb": 16.8,
     "probDifference": -3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 684
    },
    {
     "sequence": "ALLLSSFSADNLAAT",
     "pos": 995,
     "wtScore": 64.7152,
     "wtProb": 19.9,
     "k198rScore": 51.3302,
     "k198rProb": 16.8,
     "probDifference": -3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 685
    },
    {
     "sequence": "DLWITMLSMIVGATC",
     "pos": 377,
     "wtScore": 52.1169,
     "wtProb": 11.6,
     "k198rScore": 37.2156,
     "k198rProb": 8.6,
     "probDifference": -3,
     "geneName": "HCN1",
     "known": "No",
     "id": 686
    },
    {
     "sequence": "VSLVIFGSFFVLNLV",
     "pos": 393,
     "wtScore": 50.7014,
     "wtProb": 11,
     "k198rScore": 33.8601,
     "k198rProb": 8,
     "probDifference": -3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 687
    },
    {
     "sequence": "PYFITLGTEIAEQEG",
     "pos": 270,
     "wtScore": 11.3012,
     "wtProb": 8.7,
     "k198rScore": -26.3345,
     "k198rProb": 5.7,
     "probDifference": -3,
     "geneName": "KCNA1",
     "known": "No",
     "id": 688
    },
    {
     "sequence": "ERSPSGFSISQDRDD",
     "pos": 807,
     "wtScore": 54.5169,
     "wtProb": 13.1,
     "k198rScore": 41.5357,
     "k198rProb": 10.1,
     "probDifference": -3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 689
    },
    {
     "sequence": "VIVMAYVTEFVSLGN",
     "pos": 203,
     "wtScore": 11.3351,
     "wtProb": 8.7,
     "k198rScore": -26.3231,
     "k198rProb": 5.7,
     "probDifference": -3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 690
    },
    {
     "sequence": "GPTSQPNSSKQTVLS",
     "pos": 44,
     "wtScore": 51.1711,
     "wtProb": 11.2,
     "k198rScore": 35.2743,
     "k198rProb": 8.2,
     "probDifference": -3,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 691
    },
    {
     "sequence": "FLTESNKSVLQFQNV",
     "pos": 288,
     "wtScore": 60.1489,
     "wtProb": 17,
     "k198rScore": 48.1354,
     "k198rProb": 14,
     "probDifference": -3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 692
    },
    {
     "sequence": "FLTESNKSVLQFQNV",
     "pos": 284,
     "wtScore": 60.1489,
     "wtProb": 17,
     "k198rScore": 48.1354,
     "k198rProb": 14,
     "probDifference": -3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 693
    },
    {
     "sequence": "SDSSSEASKLSSKSA",
     "pos": 486,
     "wtScore": 55.1116,
     "wtProb": 13.5,
     "k198rScore": 42.3069,
     "k198rProb": 10.5,
     "probDifference": -3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 694
    },
    {
     "sequence": "RLIAATFSLIGVSFF",
     "pos": 336,
     "wtScore": 40.6318,
     "wtProb": 8.7,
     "k198rScore": 44.5656,
     "k198rProb": 11.7,
     "probDifference": 3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 695
    },
    {
     "sequence": "KIALNDTTEINRNNN",
     "pos": 1438,
     "wtScore": 12.7402,
     "wtProb": 8.7,
     "k198rScore": -26.3004,
     "k198rProb": 5.7,
     "probDifference": -3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 696
    },
    {
     "sequence": "MDFLEDPSQRQRAMS",
     "pos": 707,
     "wtScore": 52.8822,
     "wtProb": 12.1,
     "k198rScore": 38.9987,
     "k198rProb": 9.1,
     "probDifference": -3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 697
    },
    {
     "sequence": "RAINRVPSMRILVTL",
     "pos": 214,
     "wtScore": 51.7348,
     "wtProb": 11.4,
     "k198rScore": 36.6312,
     "k198rProb": 8.5,
     "probDifference": -3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 698
    },
    {
     "sequence": "TSRQRAMSIASILTN",
     "pos": 705,
     "wtScore": 51.089,
     "wtProb": 11.2,
     "k198rScore": 35.2874,
     "k198rProb": 8.2,
     "probDifference": -3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 699
    },
    {
     "sequence": "NLNNANMSKAAHGKR",
     "pos": 1763,
     "wtScore": 58.5239,
     "wtProb": 15.8,
     "k198rScore": 46.2046,
     "k198rProb": 12.8,
     "probDifference": -3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 700
    },
    {
     "sequence": "LLSTEMLSYQDDENR",
     "pos": 1942,
     "wtScore": 79.6132,
     "wtProb": 25.2,
     "k198rScore": 62.0933,
     "k198rProb": 22.2,
     "probDifference": -3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 701
    },
    {
     "sequence": "KKIWKKTSMKLLDQV",
     "pos": 1584,
     "wtScore": 51.0204,
     "wtProb": 11.1,
     "k198rScore": 35.2116,
     "k198rProb": 8.2,
     "probDifference": -3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 702
    },
    {
     "sequence": "AKILAIVSILFIVLS",
     "pos": 198,
     "wtScore": 50.3178,
     "wtProb": 10.8,
     "k198rScore": 32.9523,
     "k198rProb": 7.9,
     "probDifference": -3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 703
    },
    {
     "sequence": "VDMKSTSSIDSFTSC",
     "pos": 601,
     "wtScore": 52.2434,
     "wtProb": 11.7,
     "k198rScore": 37.8187,
     "k198rProb": 8.8,
     "probDifference": -3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 704
    },
    {
     "sequence": "KCRAAVKSNVFYWLV",
     "pos": 522,
     "wtScore": 59.3568,
     "wtProb": 16.4,
     "k198rScore": 47.3304,
     "k198rProb": 13.4,
     "probDifference": -3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 705
    },
    {
     "sequence": "KPNSSSNSRDDGNSV",
     "pos": 12,
     "wtScore": 72.5324,
     "wtProb": 23.4,
     "k198rScore": 57.1807,
     "k198rProb": 20.4,
     "probDifference": -2.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 706
    },
    {
     "sequence": "DTPLSLMSVNHEELE",
     "pos": 793,
     "wtScore": 54.5095,
     "wtProb": 13.1,
     "k198rScore": 41.6494,
     "k198rProb": 10.2,
     "probDifference": -2.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 707
    },
    {
     "sequence": "DLNNKKDSCMSNHTA",
     "pos": 1059,
     "wtScore": 59.5313,
     "wtProb": 16.5,
     "k198rScore": 47.4822,
     "k198rProb": 13.6,
     "probDifference": -2.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 708
    },
    {
     "sequence": "LSGEGPTSQPNSSKQ",
     "pos": 40,
     "wtScore": 53.7937,
     "wtProb": 12.6,
     "k198rScore": 40.6932,
     "k198rProb": 9.7,
     "probDifference": -2.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 709
    },
    {
     "sequence": "PNKVNPGSSVKGDCG",
     "pos": 1749,
     "wtScore": 52.249,
     "wtProb": 11.7,
     "k198rScore": 37.9621,
     "k198rProb": 8.8,
     "probDifference": -2.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 710
    },
    {
     "sequence": "VIMMAYITEFVNLGN",
     "pos": 207,
     "wtScore": 8.71034,
     "wtProb": 8.7,
     "k198rScore": -25.5426,
     "k198rProb": 5.8,
     "probDifference": -2.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 711
    },
    {
     "sequence": "MSMPTSETESVNTEN",
     "pos": 470,
     "wtScore": 3.47742,
     "wtProb": 8.7,
     "k198rScore": -25.8465,
     "k198rProb": 5.8,
     "probDifference": -2.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 712
    },
    {
     "sequence": "EMLVKMYSLGLQAYF",
     "pos": 575,
     "wtScore": 41.4596,
     "wtProb": 8.8,
     "k198rScore": 44.5677,
     "k198rProb": 11.7,
     "probDifference": 2.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 713
    },
    {
     "sequence": "LAPPGPDSFKPFTPE",
     "pos": 12,
     "wtScore": 62.4244,
     "wtProb": 18.4,
     "k198rScore": 59.4968,
     "k198rProb": 21.3,
     "probDifference": 2.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 714
    },
    {
     "sequence": "ALDQVNGSLPSLHRS",
     "pos": 2014,
     "wtScore": 42.5264,
     "wtProb": 8.9,
     "k198rScore": 44.6833,
     "k198rProb": 11.8,
     "probDifference": 2.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 715
    },
    {
     "sequence": "YGRQAPESMTDIWLT",
     "pos": 436,
     "wtScore": 61.2371,
     "wtProb": 17.7,
     "k198rScore": 49.18,
     "k198rProb": 14.8,
     "probDifference": -2.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 716
    },
    {
     "sequence": "FRFFTRESLAAIEQR",
     "pos": 20,
     "wtScore": 40.1439,
     "wtProb": 8.7,
     "k198rScore": 44.3604,
     "k198rProb": 11.6,
     "probDifference": 2.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 717
    },
    {
     "sequence": "KVLLALFTCEMLVKM",
     "pos": 566,
     "wtScore": -9.12827,
     "wtProb": 7.8,
     "k198rScore": -34.4647,
     "k198rProb": 4.9,
     "probDifference": -2.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 718
    },
    {
     "sequence": "FSSRERRSFTEIDTG",
     "pos": 802,
     "wtScore": 86.8168,
     "wtProb": 26.5,
     "k198rScore": 68.4716,
     "k198rProb": 23.6,
     "probDifference": -2.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 719
    },
    {
     "sequence": "ADEQWRPSAELGSGE",
     "pos": 2156,
     "wtScore": 53.4986,
     "wtProb": 12.5,
     "k198rScore": 49.8233,
     "k198rProb": 15.4,
     "probDifference": 2.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 720
    },
    {
     "sequence": "SGFSISQSKENLDAL",
     "pos": 809,
     "wtScore": 54.2618,
     "wtProb": 12.9,
     "k198rScore": 41.5094,
     "k198rProb": 10.1,
     "probDifference": -2.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 721
    },
    {
     "sequence": "PCGPPPRSATGEGPF",
     "pos": 854,
     "wtScore": 49.2061,
     "wtProb": 10.4,
     "k198rScore": 46.9727,
     "k198rProb": 13.3,
     "probDifference": 2.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 722
    },
    {
     "sequence": "SDFENLNTEEFSSES",
     "pos": 1116,
     "wtScore": 9.79725,
     "wtProb": 8.7,
     "k198rScore": -25.308,
     "k198rProb": 5.9,
     "probDifference": -2.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 723
    },
    {
     "sequence": "SDFENLNTEEFSSES",
     "pos": 1118,
     "wtScore": 9.79725,
     "wtProb": 8.7,
     "k198rScore": -25.308,
     "k198rProb": 5.9,
     "probDifference": -2.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 724
    },
    {
     "sequence": "IFTPGPPSTPKHKKS",
     "pos": 577,
     "wtScore": 50.3228,
     "wtProb": 10.8,
     "k198rScore": 34.0517,
     "k198rProb": 8,
     "probDifference": -2.8,
     "geneName": "KCNQ3",
     "known": "Yes",
     "id": 725
    },
    {
     "sequence": "RTALRIKTEGNLEQA",
     "pos": 1621,
     "wtScore": 9.19805,
     "wtProb": 8.7,
     "k198rScore": -25.0983,
     "k198rProb": 5.9,
     "probDifference": -2.8,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 726
    },
    {
     "sequence": "GAGTPLGSVASVHSP",
     "pos": 1963,
     "wtScore": 54.5343,
     "wtProb": 13.1,
     "k198rScore": 41.9221,
     "k198rProb": 10.3,
     "probDifference": -2.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 727
    },
    {
     "sequence": "WWGLITLTTIGYGDK",
     "pos": 275,
     "wtScore": -6.46234,
     "wtProb": 8.2,
     "k198rScore": -28.4614,
     "k198rProb": 5.4,
     "probDifference": -2.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 728
    },
    {
     "sequence": "RTALKIKTEGNLEQA",
     "pos": 1561,
     "wtScore": 11.2894,
     "wtProb": 8.7,
     "k198rScore": -25.1555,
     "k198rProb": 5.9,
     "probDifference": -2.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 729
    },
    {
     "sequence": "SIVDIAITEVNPAEH",
     "pos": 1348,
     "wtScore": 5.56812,
     "wtProb": 8.7,
     "k198rScore": -25.1798,
     "k198rProb": 5.9,
     "probDifference": -2.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 730
    },
    {
     "sequence": "TLLDSRVSCHSQADL",
     "pos": 758,
     "wtScore": 53.062,
     "wtProb": 12.2,
     "k198rScore": 39.693,
     "k198rProb": 9.4,
     "probDifference": -2.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 731
    },
    {
     "sequence": "RNPNYGYTSFDTFSW",
     "pos": 363,
     "wtScore": -1.74337,
     "wtProb": 8.6,
     "k198rScore": -25.8145,
     "k198rProb": 5.8,
     "probDifference": -2.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 732
    },
    {
     "sequence": "RNPNYGYTSFDTFSW",
     "pos": 362,
     "wtScore": -1.74337,
     "wtProb": 8.6,
     "k198rScore": -25.8145,
     "k198rProb": 5.8,
     "probDifference": -2.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 733
    },
    {
     "sequence": "RNPNYGYTSFDTFSW",
     "pos": 364,
     "wtScore": -1.74337,
     "wtProb": 8.6,
     "k198rScore": -25.8145,
     "k198rProb": 5.8,
     "probDifference": -2.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 734
    },
    {
     "sequence": "RNPNYGYTSFDTFSW",
     "pos": 350,
     "wtScore": -1.74337,
     "wtProb": 8.6,
     "k198rScore": -25.8145,
     "k198rProb": 5.8,
     "probDifference": -2.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 735
    },
    {
     "sequence": "SETESVNTENVAGGD",
     "pos": 475,
     "wtScore": 11.2302,
     "wtProb": 8.7,
     "k198rScore": -25.1203,
     "k198rProb": 5.9,
     "probDifference": -2.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 736
    },
    {
     "sequence": "GRLLPEATTEVEIKK",
     "pos": 667,
     "wtScore": -1.77749,
     "wtProb": 8.6,
     "k198rScore": -25.8047,
     "k198rProb": 5.8,
     "probDifference": -2.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 737
    },
    {
     "sequence": "VLTTFKEYETVSGDW",
     "pos": 147,
     "wtScore": -40.544,
     "wtProb": 5.2,
     "k198rScore": 2.44056,
     "k198rProb": 8,
     "probDifference": 2.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 738
    },
    {
     "sequence": "LPSKTGGSTAPEVGW",
     "pos": 618,
     "wtScore": 52.5872,
     "wtProb": 11.9,
     "k198rScore": 38.9409,
     "k198rProb": 9.1,
     "probDifference": -2.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 739
    },
    {
     "sequence": "RDCNTHESLLEVCDD",
     "pos": 71,
     "wtScore": 87.4942,
     "wtProb": 26.7,
     "k198rScore": 74.6724,
     "k198rProb": 29.5,
     "probDifference": 2.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 740
    },
    {
     "sequence": "DKRDIRQSPKRGFLR",
     "pos": 1964,
     "wtScore": 61.85,
     "wtProb": 18.1,
     "k198rScore": 49.7149,
     "k198rProb": 15.3,
     "probDifference": -2.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 741
    },
    {
     "sequence": "VGGPSTLTSAGQLLP",
     "pos": 663,
     "wtScore": -8.30342,
     "wtProb": 7.9,
     "k198rScore": -30.4824,
     "k198rProb": 5.1,
     "probDifference": -2.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 742
    },
    {
     "sequence": "LQVQVTEYYPTKGTS",
     "pos": 656,
     "wtScore": -40.6442,
     "wtProb": 5.2,
     "k198rScore": 2.12677,
     "k198rProb": 8,
     "probDifference": 2.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 743
    },
    {
     "sequence": "PSGSMPLSSTGDGIS",
     "pos": 853,
     "wtScore": 51.5802,
     "wtProb": 11.4,
     "k198rScore": 37.0612,
     "k198rProb": 8.6,
     "probDifference": -2.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 744
    },
    {
     "sequence": "TGDGISDSVWTPSNK",
     "pos": 862,
     "wtScore": 58.9305,
     "wtProb": 16.1,
     "k198rScore": 47.0975,
     "k198rProb": 13.3,
     "probDifference": -2.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 745
    },
    {
     "sequence": "RSSSFHVSMDFLEDP",
     "pos": 699,
     "wtScore": 58.2038,
     "wtProb": 15.5,
     "k198rScore": 46.1505,
     "k198rProb": 12.8,
     "probDifference": -2.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 746
    },
    {
     "sequence": "DATQFIEYCKLADFA",
     "pos": 1804,
     "wtScore": -40.2758,
     "wtProb": 5.2,
     "k198rScore": 0.504079,
     "k198rProb": 8,
     "probDifference": 2.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 747
    },
    {
     "sequence": "LQPKYEESLYMYLYF",
     "pos": 1455,
     "wtScore": 40.6687,
     "wtProb": 8.7,
     "k198rScore": 44.2693,
     "k198rProb": 11.5,
     "probDifference": 2.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 748
    },
    {
     "sequence": "ILKLARHSTGLQSLG",
     "pos": 317,
     "wtScore": 51.4767,
     "wtProb": 11.3,
     "k198rScore": 36.9874,
     "k198rProb": 8.5,
     "probDifference": -2.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 749
    },
    {
     "sequence": "ILKLARHSTGLQSLG",
     "pos": 313,
     "wtScore": 51.4767,
     "wtProb": 11.3,
     "k198rScore": 36.9874,
     "k198rProb": 8.5,
     "probDifference": -2.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 750
    },
    {
     "sequence": "SVASKLSSKSEKELK",
     "pos": 494,
     "wtScore": 89.469,
     "wtProb": 27.6,
     "k198rScore": 70.0704,
     "k198rProb": 24.9,
     "probDifference": -2.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 751
    },
    {
     "sequence": "NFPQALLTVFQILTG",
     "pos": 696,
     "wtScore": -8.77756,
     "wtProb": 7.8,
     "k198rScore": -31.2605,
     "k198rProb": 5.1,
     "probDifference": -2.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 752
    },
    {
     "sequence": "EKARSSSSPQHLNVQ",
     "pos": 519,
     "wtScore": 51.619,
     "wtProb": 11.4,
     "k198rScore": 37.2858,
     "k198rProb": 8.6,
     "probDifference": -2.8,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 753
    },
    {
     "sequence": "TARPLPVTTADFSLT",
     "pos": 739,
     "wtScore": -6.30994,
     "wtProb": 8.2,
     "k198rScore": -27.9403,
     "k198rProb": 5.4,
     "probDifference": -2.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 754
    },
    {
     "sequence": "ERLQVQVTEYYPTKG",
     "pos": 654,
     "wtScore": 11.2009,
     "wtProb": 8.7,
     "k198rScore": -24.7463,
     "k198rProb": 6,
     "probDifference": -2.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 755
    },
    {
     "sequence": "DNGMQKCSHIPGRRE",
     "pos": 302,
     "wtScore": 49.62,
     "wtProb": 10.6,
     "k198rScore": 47.1001,
     "k198rProb": 13.3,
     "probDifference": 2.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 756
    },
    {
     "sequence": "EQFSSVLTVGNLVFT",
     "pos": 792,
     "wtScore": -5.20881,
     "wtProb": 8.3,
     "k198rScore": -27.091,
     "k198rProb": 5.6,
     "probDifference": -2.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 757
    },
    {
     "sequence": "PSAAEGGSTTLRRRT",
     "pos": 2212,
     "wtScore": 49.8483,
     "wtProb": 10.7,
     "k198rScore": 33.1223,
     "k198rProb": 7.9,
     "probDifference": -2.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 758
    },
    {
     "sequence": "AAEDPIRSHSFRNTI",
     "pos": 910,
     "wtScore": 54.8882,
     "wtProb": 13.4,
     "k198rScore": 42.4834,
     "k198rProb": 10.6,
     "probDifference": -2.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 759
    },
    {
     "sequence": "SRIKYLQTRIDMIFT",
     "pos": 565,
     "wtScore": -8.12708,
     "wtProb": 8,
     "k198rScore": -29.5616,
     "k198rProb": 5.2,
     "probDifference": -2.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 760
    },
    {
     "sequence": "EGEEGGGSPRSSSEI",
     "pos": 472,
     "wtScore": 65.1513,
     "wtProb": 20.2,
     "k198rScore": 52.185,
     "k198rProb": 17.5,
     "probDifference": -2.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 761
    },
    {
     "sequence": "VNSSPFEYMMFVLIM",
     "pos": 1208,
     "wtScore": -39.6097,
     "wtProb": 5.3,
     "k198rScore": -0.23781,
     "k198rProb": 8,
     "probDifference": 2.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 762
    },
    {
     "sequence": "IAWFTMEYLLRFLSS",
     "pos": 247,
     "wtScore": -41.6551,
     "wtProb": 5.1,
     "k198rScore": -4.42264,
     "k198rProb": 7.8,
     "probDifference": 2.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 763
    },
    {
     "sequence": "IAWFTMEYLLRFLSS",
     "pos": 243,
     "wtScore": -41.6551,
     "wtProb": 5.1,
     "k198rScore": -4.42264,
     "k198rProb": 7.8,
     "probDifference": 2.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 764
    },
    {
     "sequence": "QVWLLFEYPESSGPA",
     "pos": 154,
     "wtScore": -39.6753,
     "wtProb": 5.3,
     "k198rScore": -0.338107,
     "k198rProb": 8,
     "probDifference": 2.7,
     "geneName": "KCNA2",
     "known": "No",
     "id": 765
    },
    {
     "sequence": "QVWLLFEYPESSGPA",
     "pos": 158,
     "wtScore": -39.6753,
     "wtProb": 5.3,
     "k198rScore": -0.338107,
     "k198rProb": 8,
     "probDifference": 2.7,
     "geneName": "KCNA1",
     "known": "No",
     "id": 766
    },
    {
     "sequence": "YYVTIFLTESNKSVL",
     "pos": 283,
     "wtScore": 12.3016,
     "wtProb": 8.7,
     "k198rScore": -24.6403,
     "k198rProb": 6,
     "probDifference": -2.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 767
    },
    {
     "sequence": "YYVTIFLTESNKSVL",
     "pos": 279,
     "wtScore": 12.3016,
     "wtProb": 8.7,
     "k198rScore": -24.6403,
     "k198rProb": 6,
     "probDifference": -2.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 768
    },
    {
     "sequence": "VPAEDDPSPCALETG",
     "pos": 313,
     "wtScore": 59.1289,
     "wtProb": 16.3,
     "k198rScore": 47.453,
     "k198rProb": 13.5,
     "probDifference": -2.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 769
    },
    {
     "sequence": "ELLRNLKSKSGLAFR",
     "pos": 398,
     "wtScore": 54.1549,
     "wtProb": 12.9,
     "k198rScore": 41.6152,
     "k198rProb": 10.2,
     "probDifference": -2.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 770
    },
    {
     "sequence": "AKILAIISIMFIVLS",
     "pos": 194,
     "wtScore": 49.687,
     "wtProb": 10.6,
     "k198rScore": 32.8512,
     "k198rProb": 7.9,
     "probDifference": -2.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 771
    },
    {
     "sequence": "FPEDDSNSTNHNLEK",
     "pos": 155,
     "wtScore": 50.4031,
     "wtProb": 10.9,
     "k198rScore": 35.3028,
     "k198rProb": 8.2,
     "probDifference": -2.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 772
    },
    {
     "sequence": "PPAESCASLQIPLAV",
     "pos": 1977,
     "wtScore": 39.9072,
     "wtProb": 8.7,
     "k198rScore": 43.869,
     "k198rProb": 11.4,
     "probDifference": 2.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 773
    },
    {
     "sequence": "CLILAVLTTFKEYET",
     "pos": 142,
     "wtScore": -6.30278,
     "wtProb": 8.2,
     "k198rScore": -27.4279,
     "k198rProb": 5.5,
     "probDifference": -2.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 774
    },
    {
     "sequence": "HREKKESTPSTASLP",
     "pos": 1937,
     "wtScore": -9.04942,
     "wtProb": 7.8,
     "k198rScore": -30.7497,
     "k198rProb": 5.1,
     "probDifference": -2.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 775
    },
    {
     "sequence": "FGEICLLTKGRRTAS",
     "pos": 544,
     "wtScore": -6.6584,
     "wtProb": 8.2,
     "k198rScore": -27.7335,
     "k198rProb": 5.5,
     "probDifference": -2.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 776
    },
    {
     "sequence": "RSMSSIDSFISCATD",
     "pos": 589,
     "wtScore": 57.2479,
     "wtProb": 14.9,
     "k198rScore": 45.3893,
     "k198rProb": 12.2,
     "probDifference": -2.7,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 777
    },
    {
     "sequence": "SIPPASDTEKPLFPP",
     "pos": 1723,
     "wtScore": 12.6154,
     "wtProb": 8.7,
     "k198rScore": -24.4413,
     "k198rProb": 6,
     "probDifference": -2.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 778
    },
    {
     "sequence": "NVSQASMSSRMVPGL",
     "pos": 630,
     "wtScore": 49.5328,
     "wtProb": 10.5,
     "k198rScore": 32.5839,
     "k198rProb": 7.9,
     "probDifference": -2.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 779
    },
    {
     "sequence": "VTIFLTESNKSVLQF",
     "pos": 281,
     "wtScore": 54.67,
     "wtProb": 13.2,
     "k198rScore": 42.344,
     "k198rProb": 10.5,
     "probDifference": -2.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 780
    },
    {
     "sequence": "VTIFLTESNKSVLQF",
     "pos": 285,
     "wtScore": 54.67,
     "wtProb": 13.2,
     "k198rScore": 42.344,
     "k198rProb": 10.5,
     "probDifference": -2.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 781
    },
    {
     "sequence": "DTDEDKTSVHFEEDF",
     "pos": 1034,
     "wtScore": 53.6351,
     "wtProb": 12.6,
     "k198rScore": 40.9974,
     "k198rProb": 9.9,
     "probDifference": -2.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 782
    },
    {
     "sequence": "KDKPEKESKGKEVRE",
     "pos": 1989,
     "wtScore": 67.2072,
     "wtProb": 21,
     "k198rScore": 67.7173,
     "k198rProb": 23.7,
     "probDifference": 2.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 783
    },
    {
     "sequence": "GKMFDEDSILGELNG",
     "pos": 516,
     "wtScore": 57.7889,
     "wtProb": 15.3,
     "k198rScore": 45.9537,
     "k198rProb": 12.6,
     "probDifference": -2.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 784
    },
    {
     "sequence": "PEIRRAISCDLQDDE",
     "pos": 1658,
     "wtScore": 70.2475,
     "wtProb": 22.5,
     "k198rScore": 55.6375,
     "k198rProb": 19.8,
     "probDifference": -2.7,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 785
    },
    {
     "sequence": "AMEHYPMTEQFSSVL",
     "pos": 783,
     "wtScore": 8.72357,
     "wtProb": 8.7,
     "k198rScore": -24.174,
     "k198rProb": 6.1,
     "probDifference": -2.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 786
    },
    {
     "sequence": "AMEHYPMTEQFSSVL",
     "pos": 784,
     "wtScore": 8.72357,
     "wtProb": 8.7,
     "k198rScore": -24.174,
     "k198rProb": 6.1,
     "probDifference": -2.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 787
    },
    {
     "sequence": "NKMAKTQSQPILNTK",
     "pos": 540,
     "wtScore": 52.7838,
     "wtProb": 12,
     "k198rScore": 39.6764,
     "k198rProb": 9.4,
     "probDifference": -2.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 788
    },
    {
     "sequence": "GPDTRNISTKAQCRA",
     "pos": 1467,
     "wtScore": 51.945,
     "wtProb": 11.5,
     "k198rScore": 38.2343,
     "k198rProb": 8.9,
     "probDifference": -2.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 789
    },
    {
     "sequence": "PMNGKMHSAVDCNGV",
     "pos": 645,
     "wtScore": 65.788,
     "wtProb": 20.5,
     "k198rScore": 52.5323,
     "k198rProb": 17.9,
     "probDifference": -2.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 790
    },
    {
     "sequence": "LFKGKLYTCSDSSKQ",
     "pos": 1076,
     "wtScore": -8.80469,
     "wtProb": 7.8,
     "k198rScore": -30.1052,
     "k198rProb": 5.2,
     "probDifference": -2.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 791
    },
    {
     "sequence": "ESSEKLNSSFPSIHC",
     "pos": 2067,
     "wtScore": 51.9077,
     "wtProb": 11.5,
     "k198rScore": 38.1661,
     "k198rProb": 8.9,
     "probDifference": -2.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 792
    },
    {
     "sequence": "NFPQSLLTVFQILTG",
     "pos": 697,
     "wtScore": -10.1733,
     "wtProb": 7.6,
     "k198rScore": -33.6228,
     "k198rProb": 4.9,
     "probDifference": -2.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 793
    },
    {
     "sequence": "SYSGYSGYSQGSRSS",
     "pos": 619,
     "wtScore": -39.6213,
     "wtProb": 5.3,
     "k198rScore": -3.33338,
     "k198rProb": 8,
     "probDifference": 2.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 794
    },
    {
     "sequence": "TATSLSHSNLHSPSP",
     "pos": 675,
     "wtScore": 49.9094,
     "wtProb": 10.7,
     "k198rScore": 34.2069,
     "k198rProb": 8,
     "probDifference": -2.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 795
    },
    {
     "sequence": "AVEKAGESANTKDSA",
     "pos": 474,
     "wtScore": 61.9446,
     "wtProb": 18.1,
     "k198rScore": 58.0945,
     "k198rProb": 20.7,
     "probDifference": 2.6,
     "geneName": "KCNB2",
     "known": "No",
     "id": 796
    },
    {
     "sequence": "LHINKAGSSQGDTES",
     "pos": 1782,
     "wtScore": 60.4526,
     "wtProb": 17.2,
     "k198rScore": 48.7958,
     "k198rProb": 14.6,
     "probDifference": -2.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 797
    },
    {
     "sequence": "KVQDNHLSPNKWKWT",
     "pos": 483,
     "wtScore": 67.0856,
     "wtProb": 21,
     "k198rScore": 53.1275,
     "k198rProb": 18.4,
     "probDifference": -2.6,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 798
    },
    {
     "sequence": "DIPPEMVSVPLEDLD",
     "pos": 74,
     "wtScore": 53.0289,
     "wtProb": 12.2,
     "k198rScore": 40.1142,
     "k198rProb": 9.5,
     "probDifference": -2.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 799
    },
    {
     "sequence": "KALLALFTAEMLLKM",
     "pos": 567,
     "wtScore": -9.4642,
     "wtProb": 7.7,
     "k198rScore": -31.3346,
     "k198rProb": 5.1,
     "probDifference": -2.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 800
    },
    {
     "sequence": "SETESVNTENVSGEG",
     "pos": 475,
     "wtScore": 11.7794,
     "wtProb": 8.7,
     "k198rScore": -24.1569,
     "k198rProb": 6.1,
     "probDifference": -2.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 801
    },
    {
     "sequence": "RFSHSPLTSLPSKTG",
     "pos": 609,
     "wtScore": -8.59952,
     "wtProb": 7.9,
     "k198rScore": -29.5349,
     "k198rProb": 5.2,
     "probDifference": -2.6,
     "geneName": "KCNB1",
     "known": "No",
     "id": 802
    },
    {
     "sequence": "CAPESEPSNSTEGET",
     "pos": 1485,
     "wtScore": 67.0126,
     "wtProb": 21,
     "k198rScore": 53.2342,
     "k198rProb": 18.3,
     "probDifference": -2.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 803
    },
    {
     "sequence": "FDPLRNEYFFDRNRP",
     "pos": 75,
     "wtScore": -39.2241,
     "wtProb": 5.4,
     "k198rScore": 4.60533,
     "k198rProb": 8,
     "probDifference": 2.6,
     "geneName": "KCNA2",
     "known": "No",
     "id": 804
    },
    {
     "sequence": "FDPLRNEYFFDRNRP",
     "pos": 79,
     "wtScore": -39.2241,
     "wtProb": 5.4,
     "k198rScore": 4.60533,
     "k198rProb": 8,
     "probDifference": 2.6,
     "geneName": "KCNA1",
     "known": "No",
     "id": 805
    },
    {
     "sequence": "AQCRAAHYRWVRRKY",
     "pos": 1477,
     "wtScore": -40.093,
     "wtProb": 5.2,
     "k198rScore": -4.03679,
     "k198rProb": 7.9,
     "probDifference": 2.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 806
    },
    {
     "sequence": "AESVHSIYHADCHIE",
     "pos": 575,
     "wtScore": -38.8122,
     "wtProb": 5.4,
     "k198rScore": -1.3048,
     "k198rProb": 8,
     "probDifference": 2.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 807
    },
    {
     "sequence": "NATDSPKSSLKGSNP",
     "pos": 701,
     "wtScore": 55.014,
     "wtProb": 13.5,
     "k198rScore": 43.0219,
     "k198rProb": 10.8,
     "probDifference": -2.6,
     "geneName": "KCNB2",
     "known": "No",
     "id": 808
    },
    {
     "sequence": "FPPDCWVSLNEMVND",
     "pos": 331,
     "wtScore": 84.7893,
     "wtProb": 25.7,
     "k198rScore": 73.5091,
     "k198rProb": 28.4,
     "probDifference": 2.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 809
    },
    {
     "sequence": "MGIPPTETEAYFGAK",
     "pos": 654,
     "wtScore": 6.64231,
     "wtProb": 8.7,
     "k198rScore": -23.8372,
     "k198rProb": 6.1,
     "probDifference": -2.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 810
    },
    {
     "sequence": "SDFENLNTEDVSSES",
     "pos": 1109,
     "wtScore": 12.571,
     "wtProb": 8.7,
     "k198rScore": -24.0653,
     "k198rProb": 6.1,
     "probDifference": -2.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 811
    },
    {
     "sequence": "RRRLLPPTPASHRRS",
     "pos": 1913,
     "wtScore": -8.71108,
     "wtProb": 7.9,
     "k198rScore": -29.4595,
     "k198rProb": 5.2,
     "probDifference": -2.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 812
    },
    {
     "sequence": "RRQYQEKYKQVEQYM",
     "pos": 479,
     "wtScore": -41.4181,
     "wtProb": 5.1,
     "k198rScore": -4.91436,
     "k198rProb": 7.7,
     "probDifference": 2.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 813
    },
    {
     "sequence": "RRQYQEKYKQVEQYM",
     "pos": 410,
     "wtScore": -41.4181,
     "wtProb": 5.1,
     "k198rScore": -4.91436,
     "k198rProb": 7.7,
     "probDifference": 2.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 814
    },
    {
     "sequence": "VNSTYFEYLMFVLIL",
     "pos": 1242,
     "wtScore": -38.6389,
     "wtProb": 5.4,
     "k198rScore": -1.52119,
     "k198rProb": 8,
     "probDifference": 2.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 815
    },
    {
     "sequence": "LAVVAMAYEEQNQAT",
     "pos": 426,
     "wtScore": -40.8695,
     "wtProb": 5.2,
     "k198rScore": -4.68863,
     "k198rProb": 7.8,
     "probDifference": 2.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 816
    },
    {
     "sequence": "LAVVAMAYEEQNQAT",
     "pos": 413,
     "wtScore": -40.8695,
     "wtProb": 5.2,
     "k198rScore": -4.68863,
     "k198rProb": 7.8,
     "probDifference": 2.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 817
    },
    {
     "sequence": "LAVVAMAYEEQNQAT",
     "pos": 425,
     "wtScore": -40.8695,
     "wtProb": 5.2,
     "k198rScore": -4.68863,
     "k198rProb": 7.8,
     "probDifference": 2.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 818
    },
    {
     "sequence": "LAVVAMAYEEQNQAT",
     "pos": 427,
     "wtScore": -40.8695,
     "wtProb": 5.2,
     "k198rScore": -4.68863,
     "k198rProb": 7.8,
     "probDifference": 2.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 819
    },
    {
     "sequence": "ENPFICSSRRDNGMQ",
     "pos": 292,
     "wtScore": 50.6918,
     "wtProb": 11,
     "k198rScore": 36.4136,
     "k198rProb": 8.4,
     "probDifference": -2.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 820
    },
    {
     "sequence": "RINENSITEKTDLTM",
     "pos": 1969,
     "wtScore": 9.09737,
     "wtProb": 8.7,
     "k198rScore": -23.7903,
     "k198rProb": 6.1,
     "probDifference": -2.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 821
    },
    {
     "sequence": "SVILEQLTKETEGGN",
     "pos": 217,
     "wtScore": -5.60585,
     "wtProb": 8.3,
     "k198rScore": -26.507,
     "k198rProb": 5.7,
     "probDifference": -2.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 822
    },
    {
     "sequence": "ARPLPVTTADFSLTT",
     "pos": 740,
     "wtScore": -7.4327,
     "wtProb": 8.1,
     "k198rScore": -27.6974,
     "k198rProb": 5.5,
     "probDifference": -2.6,
     "geneName": "KCNB2",
     "known": "No",
     "id": 823
    },
    {
     "sequence": "PDIDPGSTERVFLSV",
     "pos": 1322,
     "wtScore": 9.21726,
     "wtProb": 8.7,
     "k198rScore": -23.7149,
     "k198rProb": 6.1,
     "probDifference": -2.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 824
    },
    {
     "sequence": "TFGQRCVSSRPAAPG",
     "pos": 2079,
     "wtScore": 49.2295,
     "wtProb": 10.4,
     "k198rScore": 32.3976,
     "k198rProb": 7.8,
     "probDifference": -2.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 825
    },
    {
     "sequence": "ILASFLVYLAEKGEN",
     "pos": 250,
     "wtScore": -38.8691,
     "wtProb": 5.4,
     "k198rScore": -3.2338,
     "k198rProb": 8,
     "probDifference": 2.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 826
    },
    {
     "sequence": "PEAQRRPYYADYSPT",
     "pos": 1598,
     "wtScore": -38.4281,
     "wtProb": 5.5,
     "k198rScore": -2.00185,
     "k198rProb": 8,
     "probDifference": 2.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 827
    },
    {
     "sequence": "PPTETEAYFGAKEPE",
     "pos": 657,
     "wtScore": -38.5429,
     "wtProb": 5.4,
     "k198rScore": -2.14116,
     "k198rProb": 8,
     "probDifference": 2.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 828
    },
    {
     "sequence": "SDFENLNTEDFSSES",
     "pos": 1128,
     "wtScore": 10.6866,
     "wtProb": 8.7,
     "k198rScore": -23.6821,
     "k198rProb": 6.1,
     "probDifference": -2.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 829
    },
    {
     "sequence": "PQPQTPGSSTPKNEV",
     "pos": 759,
     "wtScore": 51.6751,
     "wtProb": 11.4,
     "k198rScore": 38.0414,
     "k198rProb": 8.8,
     "probDifference": -2.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 830
    },
    {
     "sequence": "SPTASQLSLMQQQPQ",
     "pos": 727,
     "wtScore": 45.409,
     "wtProb": 9.4,
     "k198rScore": 44.9829,
     "k198rProb": 12,
     "probDifference": 2.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 831
    },
    {
     "sequence": "PASLTVPSSFRNKNS",
     "pos": 2044,
     "wtScore": 49.6885,
     "wtProb": 10.6,
     "k198rScore": 34.1196,
     "k198rProb": 8,
     "probDifference": -2.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 832
    },
    {
     "sequence": "RPPLPQESPGARDAP",
     "pos": 1904,
     "wtScore": 61.94,
     "wtProb": 18.1,
     "k198rScore": 57.9429,
     "k198rProb": 20.7,
     "probDifference": 2.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 833
    },
    {
     "sequence": "VTLIIIGSFFVLNLV",
     "pos": 392,
     "wtScore": 49.6405,
     "wtProb": 10.6,
     "k198rScore": 34.0002,
     "k198rProb": 8,
     "probDifference": -2.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 834
    },
    {
     "sequence": "IDMRSMSSIDSFISC",
     "pos": 586,
     "wtScore": 50.1252,
     "wtProb": 10.8,
     "k198rScore": 35.3255,
     "k198rProb": 8.2,
     "probDifference": -2.6,
     "geneName": "KCNB1",
     "known": "No",
     "id": 835
    },
    {
     "sequence": "RRSSYSGYSGYSQGS",
     "pos": 616,
     "wtScore": -38.4162,
     "wtProb": 5.5,
     "k198rScore": -0.25634,
     "k198rProb": 8,
     "probDifference": 2.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 836
    },
    {
     "sequence": "AVVLQRAYRGHLARR",
     "pos": 1905,
     "wtScore": -38.3722,
     "wtProb": 5.5,
     "k198rScore": -1.6237,
     "k198rProb": 8,
     "probDifference": 2.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 837
    },
    {
     "sequence": "DWSKNVEYTFTGIYT",
     "pos": 162,
     "wtScore": -38.1918,
     "wtProb": 5.5,
     "k198rScore": -2.03115,
     "k198rProb": 8,
     "probDifference": 2.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 838
    },
    {
     "sequence": "LKDAFARSMELIDVA",
     "pos": 460,
     "wtScore": 58.9454,
     "wtProb": 16.1,
     "k198rScore": 47.4582,
     "k198rProb": 13.5,
     "probDifference": -2.6,
     "geneName": "KCNB2",
     "known": "No",
     "id": 839
    },
    {
     "sequence": "DNGTTTETEMRKRRS",
     "pos": 686,
     "wtScore": 11.9653,
     "wtProb": 8.7,
     "k198rScore": -23.6779,
     "k198rProb": 6.1,
     "probDifference": -2.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 840
    },
    {
     "sequence": "AIIIQRAYRRYLLKQ",
     "pos": 1915,
     "wtScore": -41.3241,
     "wtProb": 5.1,
     "k198rScore": -5.10837,
     "k198rProb": 7.7,
     "probDifference": 2.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 841
    },
    {
     "sequence": "MLFTGLFTVEMILKL",
     "pos": 1282,
     "wtScore": -5.67733,
     "wtProb": 8.3,
     "k198rScore": -26.264,
     "k198rProb": 5.7,
     "probDifference": -2.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 842
    },
    {
     "sequence": "RTLSETSSSKSFETK",
     "pos": 499,
     "wtScore": 52.2888,
     "wtProb": 11.7,
     "k198rScore": 39.3239,
     "k198rProb": 9.2,
     "probDifference": -2.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 843
    },
    {
     "sequence": "PLSHRQDYELQDFGP",
     "pos": 2127,
     "wtScore": -39.6769,
     "wtProb": 5.3,
     "k198rScore": -4.18355,
     "k198rProb": 7.8,
     "probDifference": 2.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 844
    },
    {
     "sequence": "ETSQDETYEVKMNHD",
     "pos": 1919,
     "wtScore": -40.2412,
     "wtProb": 5.2,
     "k198rScore": -4.61543,
     "k198rProb": 7.8,
     "probDifference": 2.5,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 845
    },
    {
     "sequence": "FDNVGAGYLALLQVA",
     "pos": 1402,
     "wtScore": -38.7323,
     "wtProb": 5.4,
     "k198rScore": -3.64164,
     "k198rProb": 8,
     "probDifference": 2.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 846
    },
    {
     "sequence": "FDNVGAGYLALLQVA",
     "pos": 1406,
     "wtScore": -38.7323,
     "wtProb": 5.4,
     "k198rScore": -3.64164,
     "k198rProb": 8,
     "probDifference": 2.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 847
    },
    {
     "sequence": "RQNCIYSTEALTGKG",
     "pos": 816,
     "wtScore": 12.4035,
     "wtProb": 8.7,
     "k198rScore": -23.6297,
     "k198rProb": 6.2,
     "probDifference": -2.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 848
    },
    {
     "sequence": "SHMLCIGYGAQAPVS",
     "pos": 360,
     "wtScore": -38.0049,
     "wtProb": 5.5,
     "k198rScore": -1.84306,
     "k198rProb": 8,
     "probDifference": 2.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 849
    },
    {
     "sequence": "IQAAWRYYATNPNRI",
     "pos": 385,
     "wtScore": -41.0464,
     "wtProb": 5.1,
     "k198rScore": -5.10327,
     "k198rProb": 7.7,
     "probDifference": 2.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 850
    },
    {
     "sequence": "SAKTPPRSPEKHTAI",
     "pos": 736,
     "wtScore": 58.3656,
     "wtProb": 15.6,
     "k198rScore": 46.7176,
     "k198rProb": 13.1,
     "probDifference": -2.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 851
    },
    {
     "sequence": "LVETKIMSPLGISVL",
     "pos": 611,
     "wtScore": 54.473,
     "wtProb": 13.1,
     "k198rScore": 42.3578,
     "k198rProb": 10.5,
     "probDifference": -2.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 852
    },
    {
     "sequence": "PPKSLPGSTSPKFST",
     "pos": 780,
     "wtScore": 48.9967,
     "wtProb": 10.3,
     "k198rScore": 32.3071,
     "k198rProb": 7.8,
     "probDifference": -2.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 853
    },
    {
     "sequence": "MEERFVASNPSKVSY",
     "pos": 1875,
     "wtScore": 56.3955,
     "wtProb": 14.3,
     "k198rScore": 44.6802,
     "k198rProb": 11.8,
     "probDifference": -2.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 854
    },
    {
     "sequence": "VAPLPTRTEGVIDMR",
     "pos": 575,
     "wtScore": 15.1772,
     "wtProb": 8.7,
     "k198rScore": -23.5999,
     "k198rProb": 6.2,
     "probDifference": -2.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 855
    },
    {
     "sequence": "RTQSPPVYTATSLSH",
     "pos": 667,
     "wtScore": -38.6648,
     "wtProb": 5.4,
     "k198rScore": -3.12852,
     "k198rProb": 7.9,
     "probDifference": 2.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 856
    },
    {
     "sequence": "VNLDASGSQCGLHSP",
     "pos": 682,
     "wtScore": 53.2263,
     "wtProb": 12.3,
     "k198rScore": 40.8409,
     "k198rProb": 9.8,
     "probDifference": -2.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 857
    },
    {
     "sequence": "LDALNSCYAAVAPCA",
     "pos": 820,
     "wtScore": -37.8261,
     "wtProb": 5.5,
     "k198rScore": -1.9629,
     "k198rProb": 8,
     "probDifference": 2.5,
     "geneName": "KCNQ2",
     "known": "Yes",
     "id": 858
    },
    {
     "sequence": "IVVFGVEYFVRIWAA",
     "pos": 140,
     "wtScore": -38.132,
     "wtProb": 5.5,
     "k198rScore": 0.926751,
     "k198rProb": 8,
     "probDifference": 2.5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 859
    },
    {
     "sequence": "LENFSVATEESADPL",
     "pos": 1772,
     "wtScore": 10.5894,
     "wtProb": 8.7,
     "k198rScore": -23.1811,
     "k198rProb": 6.2,
     "probDifference": -2.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 860
    },
    {
     "sequence": "EDPIRSHSFRNTILG",
     "pos": 912,
     "wtScore": 50.9813,
     "wtProb": 11.1,
     "k198rScore": 37.2113,
     "k198rProb": 8.6,
     "probDifference": -2.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 861
    },
    {
     "sequence": "SLPSLHRSSWYTDEP",
     "pos": 2021,
     "wtScore": 49.7634,
     "wtProb": 10.6,
     "k198rScore": 34.8361,
     "k198rProb": 8.1,
     "probDifference": -2.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 862
    },
    {
     "sequence": "RFVEANPSPDASQHS",
     "pos": 642,
     "wtScore": 66.5816,
     "wtProb": 20.8,
     "k198rScore": 53.1807,
     "k198rProb": 18.3,
     "probDifference": -2.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 863
    },
    {
     "sequence": "SLMELGLSNVEGLSV",
     "pos": 841,
     "wtScore": 107.018,
     "wtProb": 34.1,
     "k198rScore": 76.3016,
     "k198rProb": 31.6,
     "probDifference": -2.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 864
    },
    {
     "sequence": "LSRHATFSNFGMAFL",
     "pos": 1791,
     "wtScore": 54.4862,
     "wtProb": 13.1,
     "k198rScore": 42.4449,
     "k198rProb": 10.6,
     "probDifference": -2.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 865
    },
    {
     "sequence": "EQGLVGKYPAKNTTI",
     "pos": 1630,
     "wtScore": -39.8032,
     "wtProb": 5.3,
     "k198rScore": -4.67936,
     "k198rProb": 7.8,
     "probDifference": 2.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 866
    },
    {
     "sequence": "LEQLTKETEGGNHSS",
     "pos": 220,
     "wtScore": 13.6281,
     "wtProb": 8.7,
     "k198rScore": -23.0072,
     "k198rProb": 6.2,
     "probDifference": -2.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 867
    },
    {
     "sequence": "DSDGRGVYEFTQDVR",
     "pos": 727,
     "wtScore": -37.8923,
     "wtProb": 5.5,
     "k198rScore": -2.22239,
     "k198rProb": 8,
     "probDifference": 2.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 868
    },
    {
     "sequence": "IDEIYLESCCQARYH",
     "pos": 130,
     "wtScore": 55.404,
     "wtProb": 13.7,
     "k198rScore": 43.6177,
     "k198rProb": 11.2,
     "probDifference": -2.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 869
    },
    {
     "sequence": "IDEIYLESCCQARYH",
     "pos": 134,
     "wtScore": 55.404,
     "wtProb": 13.7,
     "k198rScore": 43.6177,
     "k198rProb": 11.2,
     "probDifference": -2.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 870
    },
    {
     "sequence": "ASVRADTYCRLYSLS",
     "pos": 626,
     "wtScore": -37.9499,
     "wtProb": 5.5,
     "k198rScore": 0.182993,
     "k198rProb": 8,
     "probDifference": 2.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 871
    },
    {
     "sequence": "ASVRADTYCRLYSLS",
     "pos": 557,
     "wtScore": -37.9499,
     "wtProb": 5.5,
     "k198rScore": 0.182993,
     "k198rProb": 8,
     "probDifference": 2.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 872
    },
    {
     "sequence": "RSPLPPPSASHLQMK",
     "pos": 624,
     "wtScore": 50.4553,
     "wtProb": 10.9,
     "k198rScore": 36.5061,
     "k198rProb": 8.4,
     "probDifference": -2.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 873
    },
    {
     "sequence": "KRNTSMPTSETESVN",
     "pos": 467,
     "wtScore": -9.14545,
     "wtProb": 7.8,
     "k198rScore": -28.8242,
     "k198rProb": 5.3,
     "probDifference": -2.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 874
    },
    {
     "sequence": "LISRPHPTVGESLAS",
     "pos": 806,
     "wtScore": -3.03254,
     "wtProb": 8.5,
     "k198rScore": -24.2433,
     "k198rProb": 6,
     "probDifference": -2.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 875
    },
    {
     "sequence": "DSESRRDSLFVPHRH",
     "pos": 609,
     "wtScore": 41.0785,
     "wtProb": 8.8,
     "k198rScore": 43.6604,
     "k198rProb": 11.2,
     "probDifference": 2.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 876
    },
    {
     "sequence": "EVFQPGDYIIREGAV",
     "pos": 500,
     "wtScore": -38.6885,
     "wtProb": 5.4,
     "k198rScore": -3.99411,
     "k198rProb": 7.9,
     "probDifference": 2.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 877
    },
    {
     "sequence": "TPALYILTPFNPIRK",
     "pos": 112,
     "wtScore": -9.99709,
     "wtProb": 7.6,
     "k198rScore": -30.1226,
     "k198rProb": 5.2,
     "probDifference": -2.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 878
    },
    {
     "sequence": "EVFQPGDYIIREGTI",
     "pos": 569,
     "wtScore": -38.99,
     "wtProb": 5.4,
     "k198rScore": -4.17951,
     "k198rProb": 7.8,
     "probDifference": 2.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 879
    },
    {
     "sequence": "ERAKRNGSIVSMNLK",
     "pos": 447,
     "wtScore": 53.108,
     "wtProb": 12.2,
     "k198rScore": 40.7509,
     "k198rProb": 9.7,
     "probDifference": -2.5,
     "geneName": "KCNB2",
     "known": "Yes",
     "id": 880
    },
    {
     "sequence": "SQRQRAMSIASILTN",
     "pos": 714,
     "wtScore": 51.0183,
     "wtProb": 11.1,
     "k198rScore": 37.4557,
     "k198rProb": 8.7,
     "probDifference": -2.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 881
    },
    {
     "sequence": "IFSIFGMSNFAYVKH",
     "pos": 1670,
     "wtScore": 54.1754,
     "wtProb": 12.9,
     "k198rScore": 42.1621,
     "k198rProb": 10.4,
     "probDifference": -2.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 882
    },
    {
     "sequence": "LGEQEYFSSEECYED",
     "pos": 1846,
     "wtScore": 111.096,
     "wtProb": 34.9,
     "k198rScore": 79.6282,
     "k198rProb": 37.4,
     "probDifference": 2.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 883
    },
    {
     "sequence": "IPKNQHQYKVWYVVN",
     "pos": 1229,
     "wtScore": -38.8275,
     "wtProb": 5.4,
     "k198rScore": -4.09871,
     "k198rProb": 7.9,
     "probDifference": 2.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 884
    },
    {
     "sequence": "AYVKRKIYEFIQQSF",
     "pos": 1030,
     "wtScore": -39.7648,
     "wtProb": 5.3,
     "k198rScore": -4.86667,
     "k198rProb": 7.7,
     "probDifference": 2.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 885
    },
    {
     "sequence": "DWTKNVEYTFTGIYT",
     "pos": 158,
     "wtScore": -37.3445,
     "wtProb": 5.6,
     "k198rScore": -0.86019,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 886
    },
    {
     "sequence": "DWTKNVEYTFTGIYT",
     "pos": 159,
     "wtScore": -37.3445,
     "wtProb": 5.6,
     "k198rScore": -0.86019,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 887
    },
    {
     "sequence": "DWTKNVEYTFTGIYT",
     "pos": 158,
     "wtScore": -37.3445,
     "wtProb": 5.6,
     "k198rScore": -0.86019,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 888
    },
    {
     "sequence": "TRGRRTASVRADTYC",
     "pos": 620,
     "wtScore": 53.4595,
     "wtProb": 12.5,
     "k198rScore": 41.2882,
     "k198rProb": 10,
     "probDifference": -2.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 889
    },
    {
     "sequence": "YVVDESDYMSFINNP",
     "pos": 1091,
     "wtScore": -37.655,
     "wtProb": 5.6,
     "k198rScore": -2.28616,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 890
    },
    {
     "sequence": "RKCPPCWYKFANTFL",
     "pos": 723,
     "wtScore": -40.41,
     "wtProb": 5.2,
     "k198rScore": -5.18762,
     "k198rProb": 7.7,
     "probDifference": 2.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 891
    },
    {
     "sequence": "YSLDDNEYFFDRHPG",
     "pos": 82,
     "wtScore": -37.6023,
     "wtProb": 5.6,
     "k198rScore": 3.22205,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 892
    },
    {
     "sequence": "VFSTIKEYEKSSEGA",
     "pos": 117,
     "wtScore": -37.6579,
     "wtProb": 5.6,
     "k198rScore": 0.142307,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 893
    },
    {
     "sequence": "IFRFSATSALYILTP",
     "pos": 105,
     "wtScore": 51.1866,
     "wtProb": 11.2,
     "k198rScore": 37.7413,
     "k198rProb": 8.7,
     "probDifference": -2.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 894
    },
    {
     "sequence": "IFRFSATSALYILTP",
     "pos": 105,
     "wtScore": 51.1866,
     "wtProb": 11.2,
     "k198rScore": 37.7413,
     "k198rProb": 8.7,
     "probDifference": -2.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 895
    },
    {
     "sequence": "EMLLKMYSLGLQAYF",
     "pos": 576,
     "wtScore": 41.6073,
     "wtProb": 8.8,
     "k198rScore": 43.7395,
     "k198rProb": 11.3,
     "probDifference": 2.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 896
    },
    {
     "sequence": "FGEICLLTRGRRTAS",
     "pos": 613,
     "wtScore": -8.51396,
     "wtProb": 7.9,
     "k198rScore": -27.8795,
     "k198rProb": 5.5,
     "probDifference": -2.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 897
    },
    {
     "sequence": "ILSSFLVYLVEKDVP",
     "pos": 279,
     "wtScore": -41.8419,
     "wtProb": 5.1,
     "k198rScore": -5.83961,
     "k198rProb": 7.5,
     "probDifference": 2.4,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 898
    },
    {
     "sequence": "RRPYYADYSPTRRSI",
     "pos": 1602,
     "wtScore": -37.969,
     "wtProb": 5.5,
     "k198rScore": -3.02769,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 899
    },
    {
     "sequence": "IKKKYLRTWFVVDFV",
     "pos": 288,
     "wtScore": -8.13456,
     "wtProb": 8,
     "k198rScore": -27.3224,
     "k198rProb": 5.5,
     "probDifference": -2.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 900
    },
    {
     "sequence": "LVPPGPESFRLFTRE",
     "pos": 12,
     "wtScore": 56.4563,
     "wtProb": 14.4,
     "k198rScore": 51.296,
     "k198rProb": 16.8,
     "probDifference": 2.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 901
    },
    {
     "sequence": "DHFNNVLTVGNLVFT",
     "pos": 800,
     "wtScore": -2.80821,
     "wtProb": 8.5,
     "k198rScore": -23.85,
     "k198rProb": 6.1,
     "probDifference": -2.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 902
    },
    {
     "sequence": "EAQRRPYYADYSPTR",
     "pos": 1599,
     "wtScore": -37.0009,
     "wtProb": 5.6,
     "k198rScore": -1.96624,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 903
    },
    {
     "sequence": "DLNNKKDSCISNHTT",
     "pos": 1050,
     "wtScore": 60.8653,
     "wtProb": 17.4,
     "k198rScore": 49.4242,
     "k198rProb": 15,
     "probDifference": -2.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 904
    },
    {
     "sequence": "PEGTTTETEIRKRRS",
     "pos": 677,
     "wtScore": 11.5061,
     "wtProb": 8.7,
     "k198rScore": -22.7053,
     "k198rProb": 6.3,
     "probDifference": -2.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 905
    },
    {
     "sequence": "GAPPSPPSPGRGPPD",
     "pos": 560,
     "wtScore": 53.5917,
     "wtProb": 12.5,
     "k198rScore": 49.3605,
     "k198rProb": 14.9,
     "probDifference": 2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 906
    },
    {
     "sequence": "MNNSIAHYRQVNIRT",
     "pos": 467,
     "wtScore": -40.2691,
     "wtProb": 5.2,
     "k198rScore": -5.23636,
     "k198rProb": 7.6,
     "probDifference": 2.4,
     "geneName": "KCNA1",
     "known": "No",
     "id": 907
    },
    {
     "sequence": "KFWYVVNSSPFEYMM",
     "pos": 1203,
     "wtScore": 50.6451,
     "wtProb": 11,
     "k198rScore": 37.0946,
     "k198rProb": 8.6,
     "probDifference": -2.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 908
    },
    {
     "sequence": "LRDGNGTTSGVGTGS",
     "pos": 1070,
     "wtScore": -10.1759,
     "wtProb": 7.6,
     "k198rScore": -29.9407,
     "k198rProb": 5.2,
     "probDifference": -2.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 909
    },
    {
     "sequence": "RLATGLGTMNYPTIL",
     "pos": 609,
     "wtScore": -7.51251,
     "wtProb": 8.1,
     "k198rScore": -26.5873,
     "k198rProb": 5.7,
     "probDifference": -2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 910
    },
    {
     "sequence": "GRGVYEFTQDVRHGD",
     "pos": 730,
     "wtScore": -7.26094,
     "wtProb": 8.1,
     "k198rScore": -26.4671,
     "k198rProb": 5.7,
     "probDifference": -2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 911
    },
    {
     "sequence": "EQLAVAQTEVIVDMK",
     "pos": 590,
     "wtScore": 5.30027,
     "wtProb": 8.7,
     "k198rScore": -22.6735,
     "k198rProb": 6.3,
     "probDifference": -2.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 912
    },
    {
     "sequence": "LSPESSIYTTASAKT",
     "pos": 725,
     "wtScore": -43.5239,
     "wtProb": 5,
     "k198rScore": -6.4694,
     "k198rProb": 7.4,
     "probDifference": 2.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 913
    },
    {
     "sequence": "LENFSVATEESAEPL",
     "pos": 1792,
     "wtScore": 9.92122,
     "wtProb": 8.7,
     "k198rScore": -22.5381,
     "k198rProb": 6.3,
     "probDifference": -2.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 914
    },
    {
     "sequence": "LENFSVATEESAEPL",
     "pos": 1777,
     "wtScore": 9.92122,
     "wtProb": 8.7,
     "k198rScore": -22.5381,
     "k198rProb": 6.3,
     "probDifference": -2.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 915
    },
    {
     "sequence": "LENFSVATEESAEPL",
     "pos": 1782,
     "wtScore": 9.92122,
     "wtProb": 8.7,
     "k198rScore": -22.5381,
     "k198rProb": 6.3,
     "probDifference": -2.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 916
    },
    {
     "sequence": "TFVNVTMSTFNWKDY",
     "pos": 310,
     "wtScore": 47.5153,
     "wtProb": 9.9,
     "k198rScore": 26.8375,
     "k198rProb": 7.5,
     "probDifference": -2.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 917
    },
    {
     "sequence": "SKKQGNSSNSRPARA",
     "pos": 97,
     "wtScore": 60.2405,
     "wtProb": 17.1,
     "k198rScore": 49.0182,
     "k198rProb": 14.7,
     "probDifference": -2.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 918
    },
    {
     "sequence": "LHKVLEPYKPQWCRS",
     "pos": 1260,
     "wtScore": -43.1604,
     "wtProb": 5,
     "k198rScore": -6.43347,
     "k198rProb": 7.4,
     "probDifference": 2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 919
    },
    {
     "sequence": "IKKKGPGSLLVSMDQ",
     "pos": 679,
     "wtScore": 49.4719,
     "wtProb": 10.5,
     "k198rScore": 46.3132,
     "k198rProb": 12.9,
     "probDifference": 2.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 920
    },
    {
     "sequence": "LEDLDPYYINKKTFI",
     "pos": 83,
     "wtScore": -38.9545,
     "wtProb": 5.4,
     "k198rScore": -4.65022,
     "k198rProb": 7.8,
     "probDifference": 2.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 921
    },
    {
     "sequence": "LEDLDPYYINKKTFI",
     "pos": 84,
     "wtScore": -38.9545,
     "wtProb": 5.4,
     "k198rScore": -4.65022,
     "k198rProb": 7.8,
     "probDifference": 2.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 922
    },
    {
     "sequence": "LEDLDPYYINKKTFI",
     "pos": 83,
     "wtScore": -38.9545,
     "wtProb": 5.4,
     "k198rScore": -4.65022,
     "k198rProb": 7.8,
     "probDifference": 2.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 923
    },
    {
     "sequence": "LEDFDPYYLTQKTFV",
     "pos": 87,
     "wtScore": -38.5284,
     "wtProb": 5.4,
     "k198rScore": -4.3068,
     "k198rProb": 7.8,
     "probDifference": 2.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 924
    },
    {
     "sequence": "QEVEMETYGAGTPLG",
     "pos": 1955,
     "wtScore": -36.5058,
     "wtProb": 5.7,
     "k198rScore": -1.14463,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 925
    },
    {
     "sequence": "DEEQRVPYPALAATV",
     "pos": 71,
     "wtScore": -40.0006,
     "wtProb": 5.3,
     "k198rScore": -5.29075,
     "k198rProb": 7.6,
     "probDifference": 2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 926
    },
    {
     "sequence": "GVGSGKGSTSPGPKG",
     "pos": 626,
     "wtScore": 49.8172,
     "wtProb": 10.6,
     "k198rScore": 35.8079,
     "k198rProb": 8.3,
     "probDifference": -2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 927
    },
    {
     "sequence": "VKKVSSIYKKDKGKE",
     "pos": 1931,
     "wtScore": -37.1502,
     "wtProb": 5.6,
     "k198rScore": -2.92268,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 928
    },
    {
     "sequence": "VVAGMMEYSLDGHNV",
     "pos": 185,
     "wtScore": -36.6617,
     "wtProb": 5.7,
     "k198rScore": -1.59884,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 929
    },
    {
     "sequence": "LKDRVFSSPRGVAAK",
     "pos": 445,
     "wtScore": 53.3536,
     "wtProb": 12.4,
     "k198rScore": 41.3282,
     "k198rProb": 10,
     "probDifference": -2.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 930
    },
    {
     "sequence": "SPPGAPASPRAPRTS",
     "pos": 785,
     "wtScore": 52.1178,
     "wtProb": 11.6,
     "k198rScore": 39.496,
     "k198rProb": 9.3,
     "probDifference": -2.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 931
    },
    {
     "sequence": "LIQAAWRYYATNPNR",
     "pos": 384,
     "wtScore": -40.293,
     "wtProb": 5.2,
     "k198rScore": -5.5521,
     "k198rProb": 7.6,
     "probDifference": 2.4,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 932
    },
    {
     "sequence": "LSLPNDSYMFRPVVP",
     "pos": 1931,
     "wtScore": -36.6622,
     "wtProb": 5.7,
     "k198rScore": 0.666784,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 933
    },
    {
     "sequence": "VKIVRSSSSTGQKNF",
     "pos": 693,
     "wtScore": 50.8724,
     "wtProb": 11.1,
     "k198rScore": 37.6053,
     "k198rProb": 8.7,
     "probDifference": -2.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 934
    },
    {
     "sequence": "FNEVLEEYPMMRRAF",
     "pos": 644,
     "wtScore": -36.4092,
     "wtProb": 5.7,
     "k198rScore": -0.39035,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 935
    },
    {
     "sequence": "FNEVLEEYPMMRRAF",
     "pos": 575,
     "wtScore": -36.4092,
     "wtProb": 5.7,
     "k198rScore": -0.39035,
     "k198rProb": 8,
     "probDifference": 2.4,
     "geneName": "HCN1",
     "known": "No",
     "id": 936
    },
    {
     "sequence": "YRWVRRKYNFDNLGQ",
     "pos": 1484,
     "wtScore": -43.4464,
     "wtProb": 5,
     "k198rScore": -6.70642,
     "k198rProb": 7.3,
     "probDifference": 2.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 937
    },
    {
     "sequence": "DIFVLIASVPVVAVG",
     "pos": 208,
     "wtScore": 52.7937,
     "wtProb": 12,
     "k198rScore": 40.6145,
     "k198rProb": 9.7,
     "probDifference": -2.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 938
    },
    {
     "sequence": "LICLNMVTMMVETDT",
     "pos": 1537,
     "wtScore": -11.3432,
     "wtProb": 7.4,
     "k198rScore": -32.2367,
     "k198rProb": 5,
     "probDifference": -2.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 939
    },
    {
     "sequence": "WRKKVDPSAVQGQGP",
     "pos": 492,
     "wtScore": 60.1754,
     "wtProb": 17,
     "k198rScore": 54.723,
     "k198rProb": 19.4,
     "probDifference": 2.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 940
    },
    {
     "sequence": "LDSSRRQYQEKYKQV",
     "pos": 475,
     "wtScore": -42.8699,
     "wtProb": 5,
     "k198rScore": -6.54887,
     "k198rProb": 7.3,
     "probDifference": 2.3,
     "geneName": "HCN2",
     "known": "No",
     "id": 941
    },
    {
     "sequence": "LDSSRRQYQEKYKQV",
     "pos": 406,
     "wtScore": -42.8699,
     "wtProb": 5,
     "k198rScore": -6.54887,
     "k198rProb": 7.3,
     "probDifference": 2.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 942
    },
    {
     "sequence": "DHANEANYARGTRLP",
     "pos": 25,
     "wtScore": -39.291,
     "wtProb": 5.3,
     "k198rScore": -5.02436,
     "k198rProb": 7.7,
     "probDifference": 2.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 943
    },
    {
     "sequence": "TLTCELKSCPYCTRA",
     "pos": 698,
     "wtScore": 56.1937,
     "wtProb": 14.2,
     "k198rScore": 44.7634,
     "k198rProb": 11.8,
     "probDifference": -2.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 944
    },
    {
     "sequence": "ARIIAIVSVMVILIS",
     "pos": 168,
     "wtScore": 49.1737,
     "wtProb": 10.4,
     "k198rScore": 34.3113,
     "k198rProb": 8,
     "probDifference": -2.3,
     "geneName": "KCNA2",
     "known": "No",
     "id": 945
    },
    {
     "sequence": "SPDASQHSSFFIESP",
     "pos": 649,
     "wtScore": 49.6118,
     "wtProb": 10.5,
     "k198rScore": 35.3959,
     "k198rProb": 8.2,
     "probDifference": -2.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 946
    },
    {
     "sequence": "PEGTTTETEVRKRRL",
     "pos": 678,
     "wtScore": 10.5137,
     "wtProb": 8.7,
     "k198rScore": -22.018,
     "k198rProb": 6.4,
     "probDifference": -2.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 947
    },
    {
     "sequence": "DVSLVSLTANALGYS",
     "pos": 1299,
     "wtScore": -4.24764,
     "wtProb": 8.4,
     "k198rScore": -23.9957,
     "k198rProb": 6.1,
     "probDifference": -2.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 948
    },
    {
     "sequence": "DVSLVSLTANALGYS",
     "pos": 1289,
     "wtScore": -4.24764,
     "wtProb": 8.4,
     "k198rScore": -23.9957,
     "k198rProb": 6.1,
     "probDifference": -2.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 949
    },
    {
     "sequence": "RMQKGIDYVKNKMRE",
     "pos": 1016,
     "wtScore": -36.1889,
     "wtProb": 5.7,
     "k198rScore": -0.94799,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 950
    },
    {
     "sequence": "TREVRPLSASQPSLP",
     "pos": 786,
     "wtScore": 65.1822,
     "wtProb": 20.2,
     "k198rScore": 52.6979,
     "k198rProb": 17.9,
     "probDifference": -2.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 951
    },
    {
     "sequence": "NQRQCVEYALKARPL",
     "pos": 1177,
     "wtScore": -36.4849,
     "wtProb": 5.7,
     "k198rScore": 2.7208,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 952
    },
    {
     "sequence": "NQRQCVEYALKARPL",
     "pos": 1211,
     "wtScore": -36.4849,
     "wtProb": 5.7,
     "k198rScore": 2.7208,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 953
    },
    {
     "sequence": "TGIYTFESLIKIIAR",
     "pos": 168,
     "wtScore": 39.6114,
     "wtProb": 8.6,
     "k198rScore": 43.2472,
     "k198rProb": 11,
     "probDifference": 2.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 954
    },
    {
     "sequence": "YVIDENDYMSFINNP",
     "pos": 1089,
     "wtScore": -38.38,
     "wtProb": 5.5,
     "k198rScore": -4.4842,
     "k198rProb": 7.8,
     "probDifference": 2.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 955
    },
    {
     "sequence": "AEGPRRQYGFMQRQF",
     "pos": 90,
     "wtScore": -38.5561,
     "wtProb": 5.4,
     "k198rScore": -4.59444,
     "k198rProb": 7.8,
     "probDifference": 2.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 956
    },
    {
     "sequence": "FVVCGGITETILVEL",
     "pos": 599,
     "wtScore": 6.79432,
     "wtProb": 8.7,
     "k198rScore": -21.858,
     "k198rProb": 6.4,
     "probDifference": -2.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 957
    },
    {
     "sequence": "SNLERVEYLFLIIFT",
     "pos": 163,
     "wtScore": -36.7968,
     "wtProb": 5.6,
     "k198rScore": 1.70072,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 958
    },
    {
     "sequence": "ILSETNHYFCDAWNT",
     "pos": 1325,
     "wtScore": -39.6059,
     "wtProb": 5.3,
     "k198rScore": -5.30723,
     "k198rProb": 7.6,
     "probDifference": 2.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 959
    },
    {
     "sequence": "RTVTVPMYSSQTQTY",
     "pos": 371,
     "wtScore": -37.9196,
     "wtProb": 5.5,
     "k198rScore": -4.1967,
     "k198rProb": 7.8,
     "probDifference": 2.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 960
    },
    {
     "sequence": "PKSESEDSVKRSSFL",
     "pos": 530,
     "wtScore": 70.708,
     "wtProb": 22.7,
     "k198rScore": 57.1244,
     "k198rProb": 20.4,
     "probDifference": -2.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 961
    },
    {
     "sequence": "LTFLRPYYQTEEGEE",
     "pos": 278,
     "wtScore": -45.1158,
     "wtProb": 4.9,
     "k198rScore": -7.31339,
     "k198rProb": 7.2,
     "probDifference": 2.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 962
    },
    {
     "sequence": "IEGNRLTYEKRYSSP",
     "pos": 544,
     "wtScore": -38.4755,
     "wtProb": 5.5,
     "k198rScore": -4.63115,
     "k198rProb": 7.8,
     "probDifference": 2.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 963
    },
    {
     "sequence": "ERDLRRLYSVDAQGF",
     "pos": 2135,
     "wtScore": -39.4223,
     "wtProb": 5.3,
     "k198rScore": -5.27283,
     "k198rProb": 7.6,
     "probDifference": 2.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 964
    },
    {
     "sequence": "GAVGKKMYFIQHGVA",
     "pos": 512,
     "wtScore": -42.2153,
     "wtProb": 5,
     "k198rScore": -6.53465,
     "k198rProb": 7.3,
     "probDifference": 2.3,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 965
    },
    {
     "sequence": "TARSPSLSRLLCRQE",
     "pos": 2009,
     "wtScore": 50.4115,
     "wtProb": 10.9,
     "k198rScore": 37.1229,
     "k198rProb": 8.6,
     "probDifference": -2.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 966
    },
    {
     "sequence": "LDILFAFTKRVLGDS",
     "pos": 1851,
     "wtScore": -10.4379,
     "wtProb": 7.6,
     "k198rScore": -29.3791,
     "k198rProb": 5.2,
     "probDifference": -2.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 967
    },
    {
     "sequence": "AVIIQRAYRRHLLKR",
     "pos": 1925,
     "wtScore": -36.748,
     "wtProb": 5.6,
     "k198rScore": -3.52319,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 968
    },
    {
     "sequence": "RKSESEDSIRRKGFR",
     "pos": 530,
     "wtScore": 68.6013,
     "wtProb": 21.8,
     "k198rScore": 54.8811,
     "k198rProb": 19.5,
     "probDifference": -2.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 969
    },
    {
     "sequence": "CGCCPGRSSQKVSLK",
     "pos": 432,
     "wtScore": 50.0431,
     "wtProb": 10.7,
     "k198rScore": 36.4869,
     "k198rProb": 8.4,
     "probDifference": -2.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 970
    },
    {
     "sequence": "SESELEESKEKLNAT",
     "pos": 1128,
     "wtScore": 67.8759,
     "wtProb": 21.4,
     "k198rScore": 54.3786,
     "k198rProb": 19.1,
     "probDifference": -2.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 971
    },
    {
     "sequence": "FRQKIHDYYEHRYQG",
     "pos": 502,
     "wtScore": -36.8381,
     "wtProb": 5.6,
     "k198rScore": -3.69567,
     "k198rProb": 7.9,
     "probDifference": 2.3,
     "geneName": "HCN2",
     "known": "Yes",
     "id": 972
    },
    {
     "sequence": "MRQKIHDYYEHRYQG",
     "pos": 433,
     "wtScore": -36.4506,
     "wtProb": 5.7,
     "k198rScore": -2.91908,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 973
    },
    {
     "sequence": "IAFKPKGYFSDPWNV",
     "pos": 1297,
     "wtScore": -36.3526,
     "wtProb": 5.7,
     "k198rScore": -2.88289,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 974
    },
    {
     "sequence": "STFNWKDYIGDDSHF",
     "pos": 317,
     "wtScore": -35.8917,
     "wtProb": 5.7,
     "k198rScore": -2.19502,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 975
    },
    {
     "sequence": "YIIDESDYMSFINNP",
     "pos": 1101,
     "wtScore": -36.5549,
     "wtProb": 5.7,
     "k198rScore": -2.63142,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 976
    },
    {
     "sequence": "RRHLLKRTVKQASFT",
     "pos": 1933,
     "wtScore": -11.774,
     "wtProb": 7.3,
     "k198rScore": -32.5705,
     "k198rProb": 5,
     "probDifference": -2.3,
     "geneName": "SCN1A",
     "known": "Yes",
     "id": 977
    },
    {
     "sequence": "MEERFMASNPSKVSY",
     "pos": 1895,
     "wtScore": 53.2893,
     "wtProb": 12.3,
     "k198rScore": 41.4726,
     "k198rProb": 10.1,
     "probDifference": -2.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 978
    },
    {
     "sequence": "MEERFMASNPSKVSY",
     "pos": 1885,
     "wtScore": 53.2893,
     "wtProb": 12.3,
     "k198rScore": 41.4726,
     "k198rProb": 10.1,
     "probDifference": -2.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 979
    },
    {
     "sequence": "SSSPQHLSAQKLEML",
     "pos": 535,
     "wtScore": 60.3892,
     "wtProb": 17.2,
     "k198rScore": 49.308,
     "k198rProb": 14.9,
     "probDifference": -2.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 980
    },
    {
     "sequence": "WYVVNSTYFEYLMFV",
     "pos": 1239,
     "wtScore": -42.251,
     "wtProb": 5,
     "k198rScore": -6.72386,
     "k198rProb": 7.3,
     "probDifference": 2.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 981
    },
    {
     "sequence": "YSLGLQAYFVSLFNR",
     "pos": 581,
     "wtScore": -38.3922,
     "wtProb": 5.5,
     "k198rScore": -4.82381,
     "k198rProb": 7.7,
     "probDifference": 2.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 982
    },
    {
     "sequence": "YSLGLQAYFVSLFNR",
     "pos": 582,
     "wtScore": -38.3922,
     "wtProb": 5.5,
     "k198rScore": -4.82381,
     "k198rProb": 7.7,
     "probDifference": 2.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 983
    },
    {
     "sequence": "KILGNADYVFTSIFT",
     "pos": 957,
     "wtScore": -35.6821,
     "wtProb": 5.7,
     "k198rScore": -2.15414,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 984
    },
    {
     "sequence": "SLKDRVFSSPRGVAA",
     "pos": 444,
     "wtScore": 52.2399,
     "wtProb": 11.7,
     "k198rScore": 39.9044,
     "k198rProb": 9.4,
     "probDifference": -2.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 985
    },
    {
     "sequence": "AAALPRESSSVLNTD",
     "pos": 870,
     "wtScore": 47.8301,
     "wtProb": 10,
     "k198rScore": 31.4134,
     "k198rProb": 7.7,
     "probDifference": -2.3,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 986
    },
    {
     "sequence": "YNPGEEYTCGSNFAI",
     "pos": 1433,
     "wtScore": -8.99422,
     "wtProb": 7.8,
     "k198rScore": -27.28,
     "k198rProb": 5.5,
     "probDifference": -2.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 987
    },
    {
     "sequence": "ARERRSSYSGYSGYS",
     "pos": 613,
     "wtScore": -41.5252,
     "wtProb": 5.1,
     "k198rScore": -6.41391,
     "k198rProb": 7.4,
     "probDifference": 2.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 988
    },
    {
     "sequence": "TVGYGDMYPVTIGGK",
     "pos": 378,
     "wtScore": -35.568,
     "wtProb": 5.8,
     "k198rScore": 3.10007,
     "k198rProb": 8,
     "probDifference": 2.3,
     "geneName": "KCNA1",
     "known": "No",
     "id": 989
    },
    {
     "sequence": "SLEGSRLTYEKRFSS",
     "pos": 546,
     "wtScore": -9.63761,
     "wtProb": 7.7,
     "k198rScore": -28.1115,
     "k198rProb": 5.4,
     "probDifference": -2.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 990
    },
    {
     "sequence": "IILKMTAYGAFLHKG",
     "pos": 974,
     "wtScore": -37.5261,
     "wtProb": 5.6,
     "k198rScore": -4.34017,
     "k198rProb": 7.8,
     "probDifference": 2.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 991
    },
    {
     "sequence": "IIAMDPYYYFQEGWN",
     "pos": 817,
     "wtScore": -39.7742,
     "wtProb": 5.3,
     "k198rScore": -5.70563,
     "k198rProb": 7.5,
     "probDifference": 2.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 992
    },
    {
     "sequence": "IIAMDPYYYFQEGWN",
     "pos": 816,
     "wtScore": -39.7742,
     "wtProb": 5.3,
     "k198rScore": -5.70563,
     "k198rProb": 7.5,
     "probDifference": 2.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 993
    },
    {
     "sequence": "IIAMDPYYYFQEGWN",
     "pos": 825,
     "wtScore": -39.7742,
     "wtProb": 5.3,
     "k198rScore": -5.70563,
     "k198rProb": 7.5,
     "probDifference": 2.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 994
    },
    {
     "sequence": "PKRGSILSKPRAGGA",
     "pos": 54,
     "wtScore": 56.7245,
     "wtProb": 14.5,
     "k198rScore": 45.5181,
     "k198rProb": 12.3,
     "probDifference": -2.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 995
    },
    {
     "sequence": "FLVVVNMYIAVILEN",
     "pos": 1765,
     "wtScore": -42.9903,
     "wtProb": 5,
     "k198rScore": -7.17777,
     "k198rProb": 7.2,
     "probDifference": 2.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 996
    },
    {
     "sequence": "FLVVVNMYIAVILEN",
     "pos": 1770,
     "wtScore": -42.9903,
     "wtProb": 5,
     "k198rScore": -7.17777,
     "k198rProb": 7.2,
     "probDifference": 2.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 997
    },
    {
     "sequence": "FLVVVNMYIAVILEN",
     "pos": 1780,
     "wtScore": -42.9903,
     "wtProb": 5,
     "k198rScore": -7.17777,
     "k198rProb": 7.2,
     "probDifference": 2.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 998
    },
    {
     "sequence": "PDTIHPGSSVKGDCG",
     "pos": 1734,
     "wtScore": 48.6441,
     "wtProb": 10.3,
     "k198rScore": 34.0665,
     "k198rProb": 8,
     "probDifference": -2.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 999
    },
    {
     "sequence": "RPLPEKEYQRQVWLL",
     "pos": 148,
     "wtScore": -35.3216,
     "wtProb": 5.8,
     "k198rScore": 4.08633,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1000
    },
    {
     "sequence": "AGQCPEGYQCMKAGR",
     "pos": 336,
     "wtScore": -35.6547,
     "wtProb": 5.7,
     "k198rScore": -2.38502,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1001
    },
    {
     "sequence": "NSRDDGNSVFPAKAS",
     "pos": 18,
     "wtScore": 58.298,
     "wtProb": 15.6,
     "k198rScore": 47.177,
     "k198rProb": 13.3,
     "probDifference": -2.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 1002
    },
    {
     "sequence": "LVPPGPDSFNFFTRE",
     "pos": 12,
     "wtScore": 62.5582,
     "wtProb": 18.5,
     "k198rScore": 58.1669,
     "k198rProb": 20.7,
     "probDifference": 2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1003
    },
    {
     "sequence": "DYNPGEEYTCGSNFA",
     "pos": 1432,
     "wtScore": -35.5364,
     "wtProb": 5.8,
     "k198rScore": 6.1129,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1004
    },
    {
     "sequence": "KRNGSIVSMNLKDAF",
     "pos": 450,
     "wtScore": 57.1059,
     "wtProb": 14.8,
     "k198rScore": 45.8317,
     "k198rProb": 12.5,
     "probDifference": -2.2,
     "geneName": "KCNB2",
     "known": "Yes",
     "id": 1005
    },
    {
     "sequence": "INFDNIGYAWIAIFQ",
     "pos": 365,
     "wtScore": -35.1595,
     "wtProb": 5.8,
     "k198rScore": -1.53639,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1006
    },
    {
     "sequence": "LFKGKFYYCEGPDTR",
     "pos": 1457,
     "wtScore": -35.8803,
     "wtProb": 5.7,
     "k198rScore": -2.90179,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1007
    },
    {
     "sequence": "TILGYFDYAFTAIFT",
     "pos": 923,
     "wtScore": -35.8418,
     "wtProb": 5.7,
     "k198rScore": -2.89534,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1008
    },
    {
     "sequence": "PVTTADFSLTTPQHI",
     "pos": 744,
     "wtScore": 38.0765,
     "wtProb": 8.5,
     "k198rScore": 42.8104,
     "k198rProb": 10.8,
     "probDifference": 2.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1009
    },
    {
     "sequence": "CKGNYITYKDGEVDH",
     "pos": 1095,
     "wtScore": -38.35,
     "wtProb": 5.5,
     "k198rScore": -4.99767,
     "k198rProb": 7.7,
     "probDifference": 2.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1010
    },
    {
     "sequence": "YFSSEECYEDDSSPT",
     "pos": 1851,
     "wtScore": -34.7828,
     "wtProb": 5.8,
     "k198rScore": -0.925025,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1011
    },
    {
     "sequence": "GSQCGLHSPLQSDNA",
     "pos": 688,
     "wtScore": 54.4049,
     "wtProb": 13.1,
     "k198rScore": 42.9782,
     "k198rProb": 10.8,
     "probDifference": -2.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1012
    },
    {
     "sequence": "LIAMDPYYYFQEGWN",
     "pos": 810,
     "wtScore": -41.9202,
     "wtProb": 5.1,
     "k198rScore": -6.82174,
     "k198rProb": 7.3,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1013
    },
    {
     "sequence": "LICLNMVTMMVETDD",
     "pos": 1546,
     "wtScore": -11.2668,
     "wtProb": 7.4,
     "k198rScore": -30.3865,
     "k198rProb": 5.1,
     "probDifference": -2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1014
    },
    {
     "sequence": "LICLNMVTMMVETDD",
     "pos": 1556,
     "wtScore": -11.2668,
     "wtProb": 7.4,
     "k198rScore": -30.3865,
     "k198rProb": 5.1,
     "probDifference": -2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1015
    },
    {
     "sequence": "LICLNMVTMMVETDD",
     "pos": 1541,
     "wtScore": -11.2668,
     "wtProb": 7.4,
     "k198rScore": -30.3865,
     "k198rProb": 5.1,
     "probDifference": -2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1016
    },
    {
     "sequence": "ARDGTLEYAPVDITV",
     "pos": 668,
     "wtScore": -35.4826,
     "wtProb": 5.8,
     "k198rScore": 4.73798,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1017
    },
    {
     "sequence": "EKNHFESSPLPTSPK",
     "pos": 799,
     "wtScore": 59.1249,
     "wtProb": 16.3,
     "k198rScore": 48.1476,
     "k198rProb": 14,
     "probDifference": -2.2,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 1018
    },
    {
     "sequence": "ITLATIGYGDKTPKT",
     "pos": 318,
     "wtScore": -35.4093,
     "wtProb": 5.8,
     "k198rScore": 0.185576,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1019
    },
    {
     "sequence": "SRQNYGYYSRYPGRN",
     "pos": 1867,
     "wtScore": -41.429,
     "wtProb": 5.1,
     "k198rScore": -6.55758,
     "k198rProb": 7.3,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1020
    },
    {
     "sequence": "DFFLRIDSHREDAAE",
     "pos": 1233,
     "wtScore": 102.742,
     "wtProb": 33.2,
     "k198rScore": 78.5702,
     "k198rProb": 35.5,
     "probDifference": 2.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1021
    },
    {
     "sequence": "CGSWAETTPGGGGSS",
     "pos": 2081,
     "wtScore": -6.86354,
     "wtProb": 8.1,
     "k198rScore": -25.0401,
     "k198rProb": 5.9,
     "probDifference": -2.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1022
    },
    {
     "sequence": "ITLTTIGYGDKYPQT",
     "pos": 279,
     "wtScore": -35.8248,
     "wtProb": 5.7,
     "k198rScore": -3.46513,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1023
    },
    {
     "sequence": "GKIALNDTTEINRNN",
     "pos": 1437,
     "wtScore": -6.76767,
     "wtProb": 8.2,
     "k198rScore": -24.9041,
     "k198rProb": 5.9,
     "probDifference": -2.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1024
    },
    {
     "sequence": "FRLFTRESLAAIEKR",
     "pos": 20,
     "wtScore": 39.8734,
     "wtProb": 8.7,
     "k198rScore": 43.0923,
     "k198rProb": 10.9,
     "probDifference": 2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1025
    },
    {
     "sequence": "DFVVVILSIVGMFLA",
     "pos": 1604,
     "wtScore": 50.7106,
     "wtProb": 11,
     "k198rScore": 37.8788,
     "k198rProb": 8.8,
     "probDifference": -2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1026
    },
    {
     "sequence": "DFVVVILSIVGMFLA",
     "pos": 1599,
     "wtScore": 50.7106,
     "wtProb": 11,
     "k198rScore": 37.8788,
     "k198rProb": 8.8,
     "probDifference": -2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1027
    },
    {
     "sequence": "DFVVVILSIVGMFLA",
     "pos": 1595,
     "wtScore": 50.7106,
     "wtProb": 11,
     "k198rScore": 37.8788,
     "k198rProb": 8.8,
     "probDifference": -2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1028
    },
    {
     "sequence": "DFVVVILSIVGMFLA",
     "pos": 1614,
     "wtScore": 50.7106,
     "wtProb": 11,
     "k198rScore": 37.8788,
     "k198rProb": 8.8,
     "probDifference": -2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1029
    },
    {
     "sequence": "PVQCPPSTSWQPQSH",
     "pos": 715,
     "wtScore": -12.2031,
     "wtProb": 7.2,
     "k198rScore": -32.7793,
     "k198rProb": 5,
     "probDifference": -2.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1030
    },
    {
     "sequence": "HDYYEHRYQGKMFDE",
     "pos": 507,
     "wtScore": -38.8603,
     "wtProb": 5.4,
     "k198rScore": -5.3314,
     "k198rProb": 7.6,
     "probDifference": 2.2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1031
    },
    {
     "sequence": "LLAPILNSAPPDCDP",
     "pos": 1720,
     "wtScore": 56.156,
     "wtProb": 14.2,
     "k198rScore": 50.814,
     "k198rProb": 16.4,
     "probDifference": 2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1032
    },
    {
     "sequence": "EAECKGNYITYKDGE",
     "pos": 1092,
     "wtScore": -40.3398,
     "wtProb": 5.2,
     "k198rScore": -6.09514,
     "k198rProb": 7.4,
     "probDifference": 2.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1033
    },
    {
     "sequence": "DDEGQLLYSVDSSPP",
     "pos": 767,
     "wtScore": -37.7868,
     "wtProb": 5.5,
     "k198rScore": -4.65123,
     "k198rProb": 7.8,
     "probDifference": 2.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1034
    },
    {
     "sequence": "ILFSSAVYFAEAEEA",
     "pos": 344,
     "wtScore": -34.1288,
     "wtProb": 5.8,
     "k198rScore": -0.500154,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1035
    },
    {
     "sequence": "RKLQNFLYNVLERPR",
     "pos": 81,
     "wtScore": -35.0196,
     "wtProb": 5.8,
     "k198rScore": 0.574898,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1036
    },
    {
     "sequence": "TDEPDISYRTFTPAS",
     "pos": 2032,
     "wtScore": -40.0368,
     "wtProb": 5.3,
     "k198rScore": -5.97959,
     "k198rProb": 7.5,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1037
    },
    {
     "sequence": "HERSLSAYGGGNRAS",
     "pos": 754,
     "wtScore": -34.1864,
     "wtProb": 5.8,
     "k198rScore": -0.827488,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNQ2",
     "known": "Yes",
     "id": 1038
    },
    {
     "sequence": "GSAICAHSKELITAW",
     "pos": 257,
     "wtScore": 52.2778,
     "wtProb": 11.7,
     "k198rScore": 40.178,
     "k198rProb": 9.5,
     "probDifference": -2.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1039
    },
    {
     "sequence": "EQLNNTSSSSPQHLS",
     "pos": 528,
     "wtScore": 48.176,
     "wtProb": 10.1,
     "k198rScore": 33.0762,
     "k198rProb": 7.9,
     "probDifference": -2.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1040
    },
    {
     "sequence": "MKEEFETYADALWWG",
     "pos": 302,
     "wtScore": -34.7772,
     "wtProb": 5.8,
     "k198rScore": -2.2083,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1041
    },
    {
     "sequence": "EIGKDLDYLKDVNGT",
     "pos": 1074,
     "wtScore": -33.0921,
     "wtProb": 5.8,
     "k198rScore": -1.82054,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1042
    },
    {
     "sequence": "SESDLEESKEKLNES",
     "pos": 1140,
     "wtScore": 68.1726,
     "wtProb": 21.6,
     "k198rScore": 54.8108,
     "k198rProb": 19.4,
     "probDifference": -2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1043
    },
    {
     "sequence": "TMEELEESRQKCPPC",
     "pos": 720,
     "wtScore": 50.3973,
     "wtProb": 10.9,
     "k198rScore": 46.7013,
     "k198rProb": 13.1,
     "probDifference": 2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1044
    },
    {
     "sequence": "TMEELEESRQKCPPC",
     "pos": 721,
     "wtScore": 50.3973,
     "wtProb": 10.9,
     "k198rScore": 46.7013,
     "k198rProb": 13.1,
     "probDifference": 2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1045
    },
    {
     "sequence": "IAFKPKGYFSDAWNT",
     "pos": 1263,
     "wtScore": -35.6434,
     "wtProb": 5.7,
     "k198rScore": -2.65309,
     "k198rProb": 7.9,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1046
    },
    {
     "sequence": "HKLRELQTTELKMCS",
     "pos": 1049,
     "wtScore": -11.3328,
     "wtProb": 7.4,
     "k198rScore": -30.2156,
     "k198rProb": 5.2,
     "probDifference": -2.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1047
    },
    {
     "sequence": "QSPRNEPYVARPSTS",
     "pos": 603,
     "wtScore": -40.0612,
     "wtProb": 5.3,
     "k198rScore": -6.05931,
     "k198rProb": 7.4,
     "probDifference": 2.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1048
    },
    {
     "sequence": "ISFLLIVSFFVLNMF",
     "pos": 1542,
     "wtScore": 47.4797,
     "wtProb": 9.9,
     "k198rScore": 31.1493,
     "k198rProb": 7.7,
     "probDifference": -2.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1049
    },
    {
     "sequence": "DIVAIIPYFITLGTE",
     "pos": 265,
     "wtScore": -41.8052,
     "wtProb": 5.1,
     "k198rScore": -7.01961,
     "k198rProb": 7.3,
     "probDifference": 2.2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1050
    },
    {
     "sequence": "DIVAIIPYFITLGTE",
     "pos": 264,
     "wtScore": -41.8052,
     "wtProb": 5.1,
     "k198rScore": -7.01961,
     "k198rProb": 7.3,
     "probDifference": 2.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1051
    },
    {
     "sequence": "FPFCQVSTEEGKGKI",
     "pos": 1183,
     "wtScore": 12.4106,
     "wtProb": 8.7,
     "k198rScore": -21.3142,
     "k198rProb": 6.5,
     "probDifference": -2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1052
    },
    {
     "sequence": "LADIIEKYFVSPTLF",
     "pos": 1608,
     "wtScore": -42.5289,
     "wtProb": 5,
     "k198rScore": -7.34911,
     "k198rProb": 7.2,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1053
    },
    {
     "sequence": "YNQPNWLTEVQDTAN",
     "pos": 552,
     "wtScore": 12.6341,
     "wtProb": 8.7,
     "k198rScore": -21.2945,
     "k198rProb": 6.5,
     "probDifference": -2.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1054
    },
    {
     "sequence": "GSLCQAISKSKLSRR",
     "pos": 496,
     "wtScore": 52.5291,
     "wtProb": 11.9,
     "k198rScore": 40.6033,
     "k198rProb": 9.7,
     "probDifference": -2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1055
    },
    {
     "sequence": "KQVEQYMSFHKLPAD",
     "pos": 487,
     "wtScore": 57.6576,
     "wtProb": 15.1,
     "k198rScore": 51.9311,
     "k198rProb": 17.3,
     "probDifference": 2.2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1056
    },
    {
     "sequence": "KQVEQYMSFHKLPAD",
     "pos": 418,
     "wtScore": 57.6576,
     "wtProb": 15.1,
     "k198rScore": 51.9311,
     "k198rProb": 17.3,
     "probDifference": 2.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 1057
    },
    {
     "sequence": "GMQLFGKSYKECVCK",
     "pos": 905,
     "wtScore": 88.2423,
     "wtProb": 27,
     "k198rScore": 70.1279,
     "k198rProb": 24.8,
     "probDifference": -2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1058
    },
    {
     "sequence": "GMQLFGKSYKECVCK",
     "pos": 906,
     "wtScore": 88.2423,
     "wtProb": 27,
     "k198rScore": 70.1279,
     "k198rProb": 24.8,
     "probDifference": -2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1059
    },
    {
     "sequence": "GMQLFGKSYKECVCK",
     "pos": 899,
     "wtScore": 88.2423,
     "wtProb": 27,
     "k198rScore": 70.1279,
     "k198rProb": 24.8,
     "probDifference": -2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1060
    },
    {
     "sequence": "KIIAMDPYYYFQEGW",
     "pos": 816,
     "wtScore": -34.8934,
     "wtProb": 5.8,
     "k198rScore": 2.21877,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1061
    },
    {
     "sequence": "KIIAMDPYYYFQEGW",
     "pos": 815,
     "wtScore": -34.8934,
     "wtProb": 5.8,
     "k198rScore": 2.21877,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1062
    },
    {
     "sequence": "KIIAMDPYYYFQEGW",
     "pos": 824,
     "wtScore": -34.8934,
     "wtProb": 5.8,
     "k198rScore": 2.21877,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1063
    },
    {
     "sequence": "KGFDWEEYINNKTNF",
     "pos": 304,
     "wtScore": -33.8812,
     "wtProb": 5.8,
     "k198rScore": 3.61138,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1064
    },
    {
     "sequence": "STISKSDYMEIQEGV",
     "pos": 457,
     "wtScore": -34.0849,
     "wtProb": 5.8,
     "k198rScore": -2.17686,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 1065
    },
    {
     "sequence": "SFSQELDYWGIDEIY",
     "pos": 120,
     "wtScore": -29.3411,
     "wtProb": 5.8,
     "k198rScore": -0.0515038,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1066
    },
    {
     "sequence": "CPLEKPGSPSATPAP",
     "pos": 2337,
     "wtScore": 57.0393,
     "wtProb": 14.7,
     "k198rScore": 45.8868,
     "k198rProb": 12.5,
     "probDifference": -2.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1067
    },
    {
     "sequence": "ESCCQARYHQKKEQM",
     "pos": 136,
     "wtScore": -43.9574,
     "wtProb": 4.9,
     "k198rScore": -7.74955,
     "k198rProb": 7.1,
     "probDifference": 2.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1068
    },
    {
     "sequence": "ESCCQARYHQKKEQM",
     "pos": 140,
     "wtScore": -43.9574,
     "wtProb": 4.9,
     "k198rScore": -7.74955,
     "k198rProb": 7.1,
     "probDifference": 2.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1069
    },
    {
     "sequence": "ATPPSSATTYVERPT",
     "pos": 738,
     "wtScore": -12.0652,
     "wtProb": 7.2,
     "k198rScore": -31.815,
     "k198rProb": 5,
     "probDifference": -2.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1070
    },
    {
     "sequence": "STMSKSEYMEIEEDM",
     "pos": 453,
     "wtScore": -34.5205,
     "wtProb": 5.8,
     "k198rScore": 4.79528,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1071
    },
    {
     "sequence": "GFLEDDDSPVCYDSR",
     "pos": 1895,
     "wtScore": 64.2233,
     "wtProb": 19.6,
     "k198rScore": 52.1035,
     "k198rProb": 17.4,
     "probDifference": -2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1072
    },
    {
     "sequence": "VKDVIEQYSAGHLDM",
     "pos": 549,
     "wtScore": -41.0211,
     "wtProb": 5.1,
     "k198rScore": -6.59332,
     "k198rProb": 7.3,
     "probDifference": 2.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1073
    },
    {
     "sequence": "LAELIEKYFVSPTLF",
     "pos": 1617,
     "wtScore": -41.3083,
     "wtProb": 5.1,
     "k198rScore": -6.78661,
     "k198rProb": 7.3,
     "probDifference": 2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1074
    },
    {
     "sequence": "LAELIEKYFVSPTLF",
     "pos": 1627,
     "wtScore": -41.3083,
     "wtProb": 5.1,
     "k198rScore": -6.78661,
     "k198rProb": 7.3,
     "probDifference": 2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1075
    },
    {
     "sequence": "GGGKPNSSSNSRDDG",
     "pos": 9,
     "wtScore": 47.5237,
     "wtProb": 9.9,
     "k198rScore": 31.4184,
     "k198rProb": 7.7,
     "probDifference": -2.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 1076
    },
    {
     "sequence": "AQKLEMLYNEITKTQ",
     "pos": 543,
     "wtScore": -32.8008,
     "wtProb": 5.8,
     "k198rScore": -1.54452,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1077
    },
    {
     "sequence": "KLIAMDPYYYFQEGW",
     "pos": 809,
     "wtScore": -34.828,
     "wtProb": 5.8,
     "k198rScore": 2.03632,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1078
    },
    {
     "sequence": "VSLVGGPSTLTSAGQ",
     "pos": 660,
     "wtScore": 46.8698,
     "wtProb": 9.7,
     "k198rScore": 27.5248,
     "k198rProb": 7.5,
     "probDifference": -2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1079
    },
    {
     "sequence": "PVEQPEEYLDPDACF",
     "pos": 1153,
     "wtScore": -29.7068,
     "wtProb": 5.8,
     "k198rScore": 3.96275,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1080
    },
    {
     "sequence": "KPERPSAYEEEIEME",
     "pos": 569,
     "wtScore": -31.6721,
     "wtProb": 5.8,
     "k198rScore": -1.6668,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1081
    },
    {
     "sequence": "RQAKLMGSAGNATIS",
     "pos": 66,
     "wtScore": 64.7404,
     "wtProb": 19.9,
     "k198rScore": 61.1284,
     "k198rProb": 22.1,
     "probDifference": 2.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1082
    },
    {
     "sequence": "TLSMGVEYHEQPEEL",
     "pos": 813,
     "wtScore": -33.1847,
     "wtProb": 5.8,
     "k198rScore": 2.46381,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1083
    },
    {
     "sequence": "LCDPESDYNPGEEYT",
     "pos": 1426,
     "wtScore": -34.2135,
     "wtProb": 5.8,
     "k198rScore": 2.35678,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1084
    },
    {
     "sequence": "HNLEKVEYAFLIIFT",
     "pos": 165,
     "wtScore": -33.1258,
     "wtProb": 5.8,
     "k198rScore": 2.72627,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1085
    },
    {
     "sequence": "LEGSRLTYEKRFSSP",
     "pos": 547,
     "wtScore": -41.7954,
     "wtProb": 5.1,
     "k198rScore": -7.14949,
     "k198rProb": 7.2,
     "probDifference": 2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1086
    },
    {
     "sequence": "MFREDEGYIKEEERP",
     "pos": 131,
     "wtScore": -31.5359,
     "wtProb": 5.8,
     "k198rScore": -1.38648,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 1087
    },
    {
     "sequence": "ATFLIQDYFRKFKKR",
     "pos": 1614,
     "wtScore": -32.9158,
     "wtProb": 5.8,
     "k198rScore": -0.160495,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1088
    },
    {
     "sequence": "QRMGIPPTETEAYFG",
     "pos": 652,
     "wtScore": 13.9843,
     "wtProb": 8.7,
     "k198rScore": -21.1996,
     "k198rProb": 6.5,
     "probDifference": -2.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1089
    },
    {
     "sequence": "EPEPAPPYHSPEDSR",
     "pos": 669,
     "wtScore": -32.6561,
     "wtProb": 5.9,
     "k198rScore": -1.72591,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNQ2",
     "known": "Yes",
     "id": 1090
    },
    {
     "sequence": "MEDRFMASNPSKVSY",
     "pos": 1880,
     "wtScore": 53.2403,
     "wtProb": 12.3,
     "k198rScore": 41.5969,
     "k198rProb": 10.1,
     "probDifference": -2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1091
    },
    {
     "sequence": "PLEDFDPYYLTQKTF",
     "pos": 86,
     "wtScore": -34.907,
     "wtProb": 5.8,
     "k198rScore": 1.8429,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1092
    },
    {
     "sequence": "ESEGRRDSLFIPIRA",
     "pos": 599,
     "wtScore": 39.8066,
     "wtProb": 8.7,
     "k198rScore": 42.9907,
     "k198rProb": 10.8,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1093
    },
    {
     "sequence": "IQDSRYHYFLEGFLD",
     "pos": 324,
     "wtScore": -43.354,
     "wtProb": 5,
     "k198rScore": -7.69225,
     "k198rProb": 7.1,
     "probDifference": 2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1094
    },
    {
     "sequence": "SCPCEFVTEDLTPGL",
     "pos": 528,
     "wtScore": 8.66755,
     "wtProb": 8.7,
     "k198rScore": -20.7614,
     "k198rProb": 6.6,
     "probDifference": -2.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1095
    },
    {
     "sequence": "SQRKRQQYAKSKKQG",
     "pos": 87,
     "wtScore": -38.5326,
     "wtProb": 5.4,
     "k198rScore": -5.34641,
     "k198rProb": 7.6,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1096
    },
    {
     "sequence": "ETDDQSEYVTTILSR",
     "pos": 1567,
     "wtScore": -33.5092,
     "wtProb": 5.8,
     "k198rScore": 6.66943,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1097
    },
    {
     "sequence": "AGQCPEGYICVKAGR",
     "pos": 349,
     "wtScore": -34.4236,
     "wtProb": 5.8,
     "k198rScore": 1.39781,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1098
    },
    {
     "sequence": "AGQCPEGYICVKAGR",
     "pos": 350,
     "wtScore": -34.4236,
     "wtProb": 5.8,
     "k198rScore": 1.39781,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1099
    },
    {
     "sequence": "SFGQELDYWGIDEIY",
     "pos": 124,
     "wtScore": -28.5562,
     "wtProb": 5.8,
     "k198rScore": 1.33406,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1100
    },
    {
     "sequence": "TTADFSLTTPQHIST",
     "pos": 746,
     "wtScore": -11.6175,
     "wtProb": 7.3,
     "k198rScore": -30.394,
     "k198rProb": 5.1,
     "probDifference": -2.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1101
    },
    {
     "sequence": "TIRTILEYADKVFTY",
     "pos": 1233,
     "wtScore": -31.8334,
     "wtProb": 5.8,
     "k198rScore": 3.29317,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1102
    },
    {
     "sequence": "LEEDLKGYLDWITQA",
     "pos": 437,
     "wtScore": -34.7976,
     "wtProb": 5.8,
     "k198rScore": -2.82262,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1103
    },
    {
     "sequence": "LEEDLKGYLDWITQA",
     "pos": 436,
     "wtScore": -34.7976,
     "wtProb": 5.8,
     "k198rScore": -2.82262,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1104
    },
    {
     "sequence": "YGYQTYFTNAWCWLD",
     "pos": 1280,
     "wtScore": -7.26655,
     "wtProb": 8.1,
     "k198rScore": -24.9361,
     "k198rProb": 5.9,
     "probDifference": -2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1105
    },
    {
     "sequence": "TIGYGDKYPQTWNGR",
     "pos": 283,
     "wtScore": -31.8183,
     "wtProb": 5.8,
     "k198rScore": 3.42383,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1106
    },
    {
     "sequence": "TIKTMLEYADKVFTY",
     "pos": 1243,
     "wtScore": -30.241,
     "wtProb": 5.8,
     "k198rScore": 4.75632,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1107
    },
    {
     "sequence": "TIKTMLEYADKVFTY",
     "pos": 1253,
     "wtScore": -30.241,
     "wtProb": 5.8,
     "k198rScore": 4.75632,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1108
    },
    {
     "sequence": "TIKTMLEYADKVFTY",
     "pos": 1241,
     "wtScore": -30.241,
     "wtProb": 5.8,
     "k198rScore": 4.75632,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1109
    },
    {
     "sequence": "TVGYGDIYPKTLLGK",
     "pos": 383,
     "wtScore": -34.3188,
     "wtProb": 5.8,
     "k198rScore": 1.53082,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1110
    },
    {
     "sequence": "TVGYGDIYPKTLLGK",
     "pos": 387,
     "wtScore": -34.3188,
     "wtProb": 5.8,
     "k198rScore": 1.53082,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1111
    },
    {
     "sequence": "GLFGQKCYLGDTWNR",
     "pos": 165,
     "wtScore": -37.4087,
     "wtProb": 5.6,
     "k198rScore": -4.81069,
     "k198rProb": 7.7,
     "probDifference": 2.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1112
    },
    {
     "sequence": "AGQCPEGYMCVKAGR",
     "pos": 348,
     "wtScore": -34.2836,
     "wtProb": 5.8,
     "k198rScore": 1.31266,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1113
    },
    {
     "sequence": "PNENEDKSPYPNPET",
     "pos": 834,
     "wtScore": 60.899,
     "wtProb": 17.5,
     "k198rScore": 55.2335,
     "k198rProb": 19.6,
     "probDifference": 2.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1114
    },
    {
     "sequence": "ALAFEDIYIEQRKTI",
     "pos": 1228,
     "wtScore": -31.3667,
     "wtProb": 5.8,
     "k198rScore": 6.95557,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1115
    },
    {
     "sequence": "ALAFEDIYIEQRKTI",
     "pos": 1220,
     "wtScore": -31.3667,
     "wtProb": 5.8,
     "k198rScore": 6.95557,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1116
    },
    {
     "sequence": "ALAFEDIYIEQRKTI",
     "pos": 1230,
     "wtScore": -31.3667,
     "wtProb": 5.8,
     "k198rScore": 6.95557,
     "k198rProb": 8,
     "probDifference": 2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1117
    },
    {
     "sequence": "SIEGNRLTYEKRYSS",
     "pos": 543,
     "wtScore": -7.18415,
     "wtProb": 8.1,
     "k198rScore": -24.9435,
     "k198rProb": 5.9,
     "probDifference": -2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1118
    },
    {
     "sequence": "GRNPNYGYTSFDTFS",
     "pos": 361,
     "wtScore": -37.4162,
     "wtProb": 5.6,
     "k198rScore": -4.81688,
     "k198rProb": 7.7,
     "probDifference": 2.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1119
    },
    {
     "sequence": "GRNPNYGYTSFDTFS",
     "pos": 362,
     "wtScore": -37.4162,
     "wtProb": 5.6,
     "k198rScore": -4.81688,
     "k198rProb": 7.7,
     "probDifference": 2.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1120
    },
    {
     "sequence": "GRNPNYGYTSFDTFS",
     "pos": 363,
     "wtScore": -37.4162,
     "wtProb": 5.6,
     "k198rScore": -4.81688,
     "k198rProb": 7.7,
     "probDifference": 2.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1121
    },
    {
     "sequence": "GRNPNYGYTSFDTFS",
     "pos": 349,
     "wtScore": -37.4162,
     "wtProb": 5.6,
     "k198rScore": -4.81688,
     "k198rProb": 7.7,
     "probDifference": 2.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1122
    },
    {
     "sequence": "QVQVTEYYPTKGTSS",
     "pos": 657,
     "wtScore": -44.4884,
     "wtProb": 4.9,
     "k198rScore": -7.96077,
     "k198rProb": 7.1,
     "probDifference": 2.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1123
    },
    {
     "sequence": "SLHRSSWYTDEPDIS",
     "pos": 2024,
     "wtScore": -35.16,
     "wtProb": 5.8,
     "k198rScore": -3.73372,
     "k198rProb": 7.9,
     "probDifference": 2.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1124
    },
    {
     "sequence": "EDPEIHGYFRDPHCL",
     "pos": 1832,
     "wtScore": -34.4472,
     "wtProb": 5.8,
     "k198rScore": -2.73695,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1125
    },
    {
     "sequence": "FSLRMFGSQKAVEKE",
     "pos": 115,
     "wtScore": 56.9391,
     "wtProb": 14.7,
     "k198rScore": 45.8915,
     "k198rProb": 12.6,
     "probDifference": -2.1,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 1126
    },
    {
     "sequence": "MICLFQITTSAGWDG",
     "pos": 1701,
     "wtScore": -12.3977,
     "wtProb": 7.2,
     "k198rScore": -32.0344,
     "k198rProb": 5,
     "probDifference": -2.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1127
    },
    {
     "sequence": "MICLFQITTSAGWDG",
     "pos": 1705,
     "wtScore": -12.3977,
     "wtProb": 7.2,
     "k198rScore": -32.0344,
     "k198rProb": 5,
     "probDifference": -2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1128
    },
    {
     "sequence": "MICLFQITTSAGWDG",
     "pos": 1710,
     "wtScore": -12.3977,
     "wtProb": 7.2,
     "k198rScore": -32.0344,
     "k198rProb": 5,
     "probDifference": -2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1129
    },
    {
     "sequence": "MICLFQITTSAGWDG",
     "pos": 1720,
     "wtScore": -12.3977,
     "wtProb": 7.2,
     "k198rScore": -32.0344,
     "k198rProb": 5,
     "probDifference": -2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1130
    },
    {
     "sequence": "PMAEDRGYGNDFPIE",
     "pos": 501,
     "wtScore": -33.4705,
     "wtProb": 5.8,
     "k198rScore": -2.94859,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "KCNQ3",
     "known": "Yes",
     "id": 1131
    },
    {
     "sequence": "GNADYVFTSIFTLEI",
     "pos": 960,
     "wtScore": -12.2188,
     "wtProb": 7.2,
     "k198rScore": -31.582,
     "k198rProb": 5.1,
     "probDifference": -2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1132
    },
    {
     "sequence": "VQQLEDMYNKMAKTQ",
     "pos": 532,
     "wtScore": -31.3627,
     "wtProb": 5.8,
     "k198rScore": 6.65388,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1133
    },
    {
     "sequence": "SAILEQATKADGANA",
     "pos": 215,
     "wtScore": -2.60833,
     "wtProb": 8.6,
     "k198rScore": -21.8713,
     "k198rProb": 6.4,
     "probDifference": -2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1134
    },
    {
     "sequence": "EDEDKDPYPPCDVPV",
     "pos": 817,
     "wtScore": -31.007,
     "wtProb": 5.8,
     "k198rScore": 4.70263,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1135
    },
    {
     "sequence": "GTIGKKMYFIQHGVV",
     "pos": 581,
     "wtScore": -43.0076,
     "wtProb": 5,
     "k198rScore": -7.71267,
     "k198rProb": 7.1,
     "probDifference": 2.1,
     "geneName": "HCN2",
     "known": "No",
     "id": 1136
    },
    {
     "sequence": "LQDFGPGYSDEEPDP",
     "pos": 2136,
     "wtScore": -35.5811,
     "wtProb": 5.8,
     "k198rScore": -3.9372,
     "k198rProb": 7.9,
     "probDifference": 2.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1137
    },
    {
     "sequence": "PLEDLDPYYINKKTF",
     "pos": 82,
     "wtScore": -31.9762,
     "wtProb": 5.9,
     "k198rScore": 4.78881,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1138
    },
    {
     "sequence": "PLEDLDPYYINKKTF",
     "pos": 83,
     "wtScore": -31.9762,
     "wtProb": 5.9,
     "k198rScore": 4.78881,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1139
    },
    {
     "sequence": "PLEDLDPYYINKKTF",
     "pos": 82,
     "wtScore": -31.9762,
     "wtProb": 5.9,
     "k198rScore": 4.78881,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1140
    },
    {
     "sequence": "ATFLIQEYFRKFKKR",
     "pos": 1674,
     "wtScore": -32.2569,
     "wtProb": 5.8,
     "k198rScore": 6.53314,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1141
    },
    {
     "sequence": "SPHQSLLSIRGSLFS",
     "pos": 557,
     "wtScore": 47.5886,
     "wtProb": 9.9,
     "k198rScore": 32.1316,
     "k198rProb": 7.8,
     "probDifference": -2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1142
    },
    {
     "sequence": "SPHQSLLSIRGSLFS",
     "pos": 560,
     "wtScore": 47.5886,
     "wtProb": 9.9,
     "k198rScore": 32.1316,
     "k198rProb": 7.8,
     "probDifference": -2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1143
    },
    {
     "sequence": "SPHQSLLSIRGSLFS",
     "pos": 560,
     "wtScore": 47.5886,
     "wtProb": 9.9,
     "k198rScore": 32.1316,
     "k198rProb": 7.8,
     "probDifference": -2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1144
    },
    {
     "sequence": "FIGVILFSSAVYFAE",
     "pos": 340,
     "wtScore": 48.9289,
     "wtProb": 10.3,
     "k198rScore": 35.2832,
     "k198rProb": 8.2,
     "probDifference": -2.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1145
    },
    {
     "sequence": "FIGVILFSSAVYFAE",
     "pos": 342,
     "wtScore": 48.9289,
     "wtProb": 10.3,
     "k198rScore": 35.2832,
     "k198rProb": 8.2,
     "probDifference": -2.1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1146
    },
    {
     "sequence": "AMAGQEETSQDETYE",
     "pos": 1913,
     "wtScore": -3.63075,
     "wtProb": 8.5,
     "k198rScore": -22.4294,
     "k198rProb": 6.3,
     "probDifference": -2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1147
    },
    {
     "sequence": "NFAFAMLTVFQCITM",
     "pos": 355,
     "wtScore": -10.1996,
     "wtProb": 7.6,
     "k198rScore": -27.8412,
     "k198rProb": 5.5,
     "probDifference": -2.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1148
    },
    {
     "sequence": "NFAFAMLTVFQCITM",
     "pos": 354,
     "wtScore": -10.1996,
     "wtProb": 7.6,
     "k198rScore": -27.8412,
     "k198rProb": 5.5,
     "probDifference": -2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1149
    },
    {
     "sequence": "AAGCCCRYRGWRGRL",
     "pos": 153,
     "wtScore": -45.9886,
     "wtProb": 4.9,
     "k198rScore": -8.15918,
     "k198rProb": 7,
     "probDifference": 2.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1150
    },
    {
     "sequence": "IQSAWRFYATNLSRT",
     "pos": 346,
     "wtScore": -40.9596,
     "wtProb": 5.1,
     "k198rScore": -6.90277,
     "k198rProb": 7.3,
     "probDifference": 2.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1151
    },
    {
     "sequence": "SPVAPLPTRTEGVID",
     "pos": 573,
     "wtScore": -12.5631,
     "wtProb": 7.1,
     "k198rScore": -32.4462,
     "k198rProb": 5,
     "probDifference": -2.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1152
    },
    {
     "sequence": "TTSGIGSSVEKYVVD",
     "pos": 1080,
     "wtScore": 55.0904,
     "wtProb": 13.5,
     "k198rScore": 43.8978,
     "k198rProb": 11.4,
     "probDifference": -2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1153
    },
    {
     "sequence": "KRRGLYLTVPQCPLE",
     "pos": 2326,
     "wtScore": -1.24634,
     "wtProb": 8.6,
     "k198rScore": -21.4621,
     "k198rProb": 6.5,
     "probDifference": -2.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1154
    },
    {
     "sequence": "LAEMIEKYFVSPTLF",
     "pos": 1612,
     "wtScore": -40.6693,
     "wtProb": 5.2,
     "k198rScore": -6.71203,
     "k198rProb": 7.3,
     "probDifference": 2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1155
    },
    {
     "sequence": "SSKAQKYSPSHSTRS",
     "pos": 1972,
     "wtScore": 47.9682,
     "wtProb": 10,
     "k198rScore": 33.1434,
     "k198rProb": 7.9,
     "probDifference": -2.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1156
    },
    {
     "sequence": "TWQYYERTVTVPMYS",
     "pos": 365,
     "wtScore": -12.8354,
     "wtProb": 7.1,
     "k198rScore": -32.9993,
     "k198rProb": 5,
     "probDifference": -2.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1157
    },
    {
     "sequence": "ILFSSAVYFAEADER",
     "pos": 346,
     "wtScore": -32.977,
     "wtProb": 5.8,
     "k198rScore": 1.97043,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1158
    },
    {
     "sequence": "EFVTEDLTPGLKVSI",
     "pos": 532,
     "wtScore": -5.88349,
     "wtProb": 8.2,
     "k198rScore": -23.8536,
     "k198rProb": 6.1,
     "probDifference": -2.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1159
    },
    {
     "sequence": "YNLNENEYFFDRHPG",
     "pos": 86,
     "wtScore": -33.6373,
     "wtProb": 5.8,
     "k198rScore": 7.70852,
     "k198rProb": 7.9,
     "probDifference": 2.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1160
    },
    {
     "sequence": "SIRGSLFSPRRNSKT",
     "pos": 567,
     "wtScore": 51.3512,
     "wtProb": 11.2,
     "k198rScore": 39.128,
     "k198rProb": 9.1,
     "probDifference": -2.1,
     "geneName": "SCN3A",
     "known": "Yes",
     "id": 1161
    },
    {
     "sequence": "ARVIAIVSVMVILIS",
     "pos": 172,
     "wtScore": 48.4863,
     "wtProb": 10.2,
     "k198rScore": 34.5496,
     "k198rProb": 8.1,
     "probDifference": -2.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1162
    },
    {
     "sequence": "ALAFEDIYIDQRKTI",
     "pos": 1240,
     "wtScore": -29.4002,
     "wtProb": 5.8,
     "k198rScore": 7.7859,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1163
    },
    {
     "sequence": "HCLGEQEYFSSEECY",
     "pos": 1844,
     "wtScore": -29.9069,
     "wtProb": 5.8,
     "k198rScore": 8.00401,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1164
    },
    {
     "sequence": "LIMCTILTNCVFMTM",
     "pos": 139,
     "wtScore": -10.8211,
     "wtProb": 7.5,
     "k198rScore": -28.4417,
     "k198rProb": 5.4,
     "probDifference": -2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1165
    },
    {
     "sequence": "LIMCTILTNCVFMTM",
     "pos": 140,
     "wtScore": -10.8211,
     "wtProb": 7.5,
     "k198rScore": -28.4417,
     "k198rProb": 5.4,
     "probDifference": -2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1166
    },
    {
     "sequence": "YNQPDWLTQIQDIAN",
     "pos": 551,
     "wtScore": -6.19857,
     "wtProb": 8.2,
     "k198rScore": -23.9584,
     "k198rProb": 6.1,
     "probDifference": -2.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1167
    },
    {
     "sequence": "FEAGVHQYIDADTDD",
     "pos": 754,
     "wtScore": -33.4758,
     "wtProb": 5.8,
     "k198rScore": -3.76635,
     "k198rProb": 7.9,
     "probDifference": 2.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1168
    },
    {
     "sequence": "LLEVCDDYSLDDNEY",
     "pos": 75,
     "wtScore": -30.5264,
     "wtProb": 5.8,
     "k198rScore": 7.62539,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1169
    },
    {
     "sequence": "TSALYILTPFNPLRK",
     "pos": 111,
     "wtScore": -11.5684,
     "wtProb": 7.3,
     "k198rScore": -29.8325,
     "k198rProb": 5.2,
     "probDifference": -2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1170
    },
    {
     "sequence": "HLFGCKFSLKTDTGD",
     "pos": 941,
     "wtScore": 36.968,
     "wtProb": 8.5,
     "k198rScore": 42.4466,
     "k198rProb": 10.6,
     "probDifference": 2.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1171
    },
    {
     "sequence": "SPESSIYTTASAKTP",
     "pos": 726,
     "wtScore": -8.29292,
     "wtProb": 7.9,
     "k198rScore": -25.4943,
     "k198rProb": 5.8,
     "probDifference": -2.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1172
    },
    {
     "sequence": "VMDVIEQYSAGHLDM",
     "pos": 570,
     "wtScore": -41.5559,
     "wtProb": 5.1,
     "k198rScore": -7.37316,
     "k198rProb": 7.2,
     "probDifference": 2.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1173
    },
    {
     "sequence": "HDYYEHRYQGKIFDE",
     "pos": 438,
     "wtScore": -38.0147,
     "wtProb": 5.5,
     "k198rScore": -5.32875,
     "k198rProb": 7.6,
     "probDifference": 2.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 1174
    },
    {
     "sequence": "DGIIVSLSLMELGLS",
     "pos": 834,
     "wtScore": 85.4,
     "wtProb": 25.8,
     "k198rScore": 68.6633,
     "k198rProb": 23.7,
     "probDifference": -2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1175
    },
    {
     "sequence": "EQLEAASSQKLGLLD",
     "pos": 420,
     "wtScore": 51.2167,
     "wtProb": 11.2,
     "k198rScore": 38.9337,
     "k198rProb": 9.1,
     "probDifference": -2.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1176
    },
    {
     "sequence": "AAEKRLGTPPGGGGA",
     "pos": 40,
     "wtScore": -11.782,
     "wtProb": 7.3,
     "k198rScore": -30.1285,
     "k198rProb": 5.2,
     "probDifference": -2.1,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 1177
    },
    {
     "sequence": "LKNISSNYNKEAIKG",
     "pos": 1926,
     "wtScore": -41.177,
     "wtProb": 5.1,
     "k198rScore": -7.16011,
     "k198rProb": 7.2,
     "probDifference": 2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1178
    },
    {
     "sequence": "SREAWALYLFSPQNR",
     "pos": 1274,
     "wtScore": -32.8764,
     "wtProb": 5.8,
     "k198rScore": -3.40999,
     "k198rProb": 8,
     "probDifference": 2.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1179
    },
    {
     "sequence": "TKRTLSETSSSKSFE",
     "pos": 497,
     "wtScore": -7.24268,
     "wtProb": 8.1,
     "k198rScore": -24.6359,
     "k198rProb": 6,
     "probDifference": -2.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1180
    },
    {
     "sequence": "LTIASEHYNQPNWLT",
     "pos": 545,
     "wtScore": -40.2865,
     "wtProb": 5.2,
     "k198rScore": -6.61522,
     "k198rProb": 7.3,
     "probDifference": 2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1181
    },
    {
     "sequence": "TSRMRTQSPPVYTAT",
     "pos": 663,
     "wtScore": 51.3239,
     "wtProb": 11.2,
     "k198rScore": 39.1575,
     "k198rProb": 9.1,
     "probDifference": -2.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 1182
    },
    {
     "sequence": "PRNMSMPTSETESVN",
     "pos": 467,
     "wtScore": -12.3841,
     "wtProb": 7.2,
     "k198rScore": -31.3543,
     "k198rProb": 5.1,
     "probDifference": -2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1183
    },
    {
     "sequence": "LEPEACFTEDCVRKF",
     "pos": 1171,
     "wtScore": 17.2401,
     "wtProb": 8.6,
     "k198rScore": -20.8031,
     "k198rProb": 6.6,
     "probDifference": -2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1184
    },
    {
     "sequence": "KGWMDIMYAAVDSRN",
     "pos": 1428,
     "wtScore": -37.3068,
     "wtProb": 5.6,
     "k198rScore": -5.03509,
     "k198rProb": 7.7,
     "probDifference": 2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1185
    },
    {
     "sequence": "KGWMDIMYAAVDSRN",
     "pos": 1438,
     "wtScore": -37.3068,
     "wtProb": 5.6,
     "k198rScore": -5.03509,
     "k198rProb": 7.7,
     "probDifference": 2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1186
    },
    {
     "sequence": "IIMCTILTNCVFMTF",
     "pos": 143,
     "wtScore": -9.328,
     "wtProb": 7.7,
     "k198rScore": -26.6097,
     "k198rProb": 5.6,
     "probDifference": -2.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1187
    },
    {
     "sequence": "IKKIWKRTSMKLLDQ",
     "pos": 1643,
     "wtScore": -13.4479,
     "wtProb": 7,
     "k198rScore": -34.5095,
     "k198rProb": 4.9,
     "probDifference": -2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1188
    },
    {
     "sequence": "VNENTRMYIPEENHQ",
     "pos": 8,
     "wtScore": -40.3409,
     "wtProb": 5.2,
     "k198rScore": -6.72752,
     "k198rProb": 7.3,
     "probDifference": 2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1189
    },
    {
     "sequence": "TSDKKFCSPHQSLLS",
     "pos": 553,
     "wtScore": 50.0128,
     "wtProb": 10.7,
     "k198rScore": 37.2817,
     "k198rProb": 8.6,
     "probDifference": -2.1,
     "geneName": "SCN3A",
     "known": "Yes",
     "id": 1190
    },
    {
     "sequence": "EDDFEMFYEVWEKFD",
     "pos": 1793,
     "wtScore": -35.5555,
     "wtProb": 5.8,
     "k198rScore": -4.16489,
     "k198rProb": 7.8,
     "probDifference": 2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1191
    },
    {
     "sequence": "EDDFEMFYEVWEKFD",
     "pos": 1808,
     "wtScore": -35.5555,
     "wtProb": 5.8,
     "k198rScore": -4.16489,
     "k198rProb": 7.8,
     "probDifference": 2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1192
    },
    {
     "sequence": "EDDFEMFYEVWEKFD",
     "pos": 1798,
     "wtScore": -35.5555,
     "wtProb": 5.8,
     "k198rScore": -4.16489,
     "k198rProb": 7.8,
     "probDifference": 2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1193
    },
    {
     "sequence": "VITFAYVTEFVDLGN",
     "pos": 204,
     "wtScore": 15.0689,
     "wtProb": 8.7,
     "k198rScore": -20.3719,
     "k198rProb": 6.6,
     "probDifference": -2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1194
    },
    {
     "sequence": "VITFAYVTEFVDLGN",
     "pos": 203,
     "wtScore": 15.0689,
     "wtProb": 8.7,
     "k198rScore": -20.3719,
     "k198rProb": 6.6,
     "probDifference": -2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1195
    },
    {
     "sequence": "LDILFAFTKRVLGES",
     "pos": 1856,
     "wtScore": -12.0857,
     "wtProb": 7.2,
     "k198rScore": -30.5681,
     "k198rProb": 5.1,
     "probDifference": -2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1196
    },
    {
     "sequence": "LDILFAFTKRVLGES",
     "pos": 1871,
     "wtScore": -12.0857,
     "wtProb": 7.2,
     "k198rScore": -30.5681,
     "k198rProb": 5.1,
     "probDifference": -2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1197
    },
    {
     "sequence": "LDILFAFTKRVLGES",
     "pos": 1861,
     "wtScore": -12.0857,
     "wtProb": 7.2,
     "k198rScore": -30.5681,
     "k198rProb": 5.1,
     "probDifference": -2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1198
    },
    {
     "sequence": "ASNPSKVSYQPITTT",
     "pos": 1901,
     "wtScore": 50.0193,
     "wtProb": 10.7,
     "k198rScore": 37.3054,
     "k198rProb": 8.6,
     "probDifference": -2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1199
    },
    {
     "sequence": "SNPGDTGYCPTRETS",
     "pos": 902,
     "wtScore": -36.0559,
     "wtProb": 5.7,
     "k198rScore": -4.48421,
     "k198rProb": 7.8,
     "probDifference": 2.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1200
    },
    {
     "sequence": "SPRGTARSPSLSRLL",
     "pos": 2005,
     "wtScore": 49.8266,
     "wtProb": 10.6,
     "k198rScore": 37.0416,
     "k198rProb": 8.6,
     "probDifference": -2.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1201
    },
    {
     "sequence": "VIVSNFNYFYHRETE",
     "pos": 412,
     "wtScore": -39.9061,
     "wtProb": 5.3,
     "k198rScore": -6.49998,
     "k198rProb": 7.4,
     "probDifference": 2.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1202
    },
    {
     "sequence": "VIVSNFNYFYHRETE",
     "pos": 414,
     "wtScore": -39.9061,
     "wtProb": 5.3,
     "k198rScore": -6.49998,
     "k198rProb": 7.4,
     "probDifference": 2.1,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 1203
    },
    {
     "sequence": "TVTVPMYSSQTQTYG",
     "pos": 372,
     "wtScore": 47.0243,
     "wtProb": 9.8,
     "k198rScore": 30.6018,
     "k198rProb": 7.7,
     "probDifference": -2.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1204
    },
    {
     "sequence": "LIMCTILTNCVFMTL",
     "pos": 139,
     "wtScore": -9.89526,
     "wtProb": 7.6,
     "k198rScore": -27.1077,
     "k198rProb": 5.6,
     "probDifference": -2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1205
    },
    {
     "sequence": "TSALYILTPLNPVRK",
     "pos": 111,
     "wtScore": -12.3118,
     "wtProb": 7.2,
     "k198rScore": -30.9494,
     "k198rProb": 5.1,
     "probDifference": -2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1206
    },
    {
     "sequence": "LTANALGYSELGAIK",
     "pos": 1305,
     "wtScore": -38.9985,
     "wtProb": 5.4,
     "k198rScore": -6.01427,
     "k198rProb": 7.5,
     "probDifference": 2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1207
    },
    {
     "sequence": "LTANALGYSELGAIK",
     "pos": 1295,
     "wtScore": -38.9985,
     "wtProb": 5.4,
     "k198rScore": -6.01427,
     "k198rProb": 7.5,
     "probDifference": 2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1208
    },
    {
     "sequence": "DGSSSTTSPPSYDSV",
     "pos": 1965,
     "wtScore": 47.8813,
     "wtProb": 10,
     "k198rScore": 33.4491,
     "k198rProb": 7.9,
     "probDifference": -2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1209
    },
    {
     "sequence": "HNCRQDIYHAVSEVK",
     "pos": 867,
     "wtScore": -31.733,
     "wtProb": 5.9,
     "k198rScore": 8.09132,
     "k198rProb": 7.9,
     "probDifference": 2.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1210
    },
    {
     "sequence": "LLSGEHAYLQSSWNL",
     "pos": 1358,
     "wtScore": -43.2838,
     "wtProb": 5,
     "k198rScore": -8.09603,
     "k198rProb": 7,
     "probDifference": 2.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1211
    },
    {
     "sequence": "KGMDSEVYKTARALR",
     "pos": 247,
     "wtScore": -37.3539,
     "wtProb": 5.6,
     "k198rScore": -5.1491,
     "k198rProb": 7.7,
     "probDifference": 2.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 1212
    },
    {
     "sequence": "DMTPSTTSPPSYDSV",
     "pos": 1970,
     "wtScore": 48.1173,
     "wtProb": 10.1,
     "k198rScore": 34.1064,
     "k198rProb": 8,
     "probDifference": -2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1213
    },
    {
     "sequence": "GGQDIFMTEEQKKYY",
     "pos": 1500,
     "wtScore": 12.6867,
     "wtProb": 8.7,
     "k198rScore": -20.1621,
     "k198rProb": 6.6,
     "probDifference": -2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1214
    },
    {
     "sequence": "GGQDIFMTEEQKKYY",
     "pos": 1485,
     "wtScore": 12.6867,
     "wtProb": 8.7,
     "k198rScore": -20.1621,
     "k198rProb": 6.6,
     "probDifference": -2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1215
    },
    {
     "sequence": "GGQDIFMTEEQKKYY",
     "pos": 1490,
     "wtScore": 12.6867,
     "wtProb": 8.7,
     "k198rScore": -20.1621,
     "k198rProb": 6.6,
     "probDifference": -2.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1216
    },
    {
     "sequence": "GGQDIFMTEEQKKYY",
     "pos": 1481,
     "wtScore": 12.6867,
     "wtProb": 8.7,
     "k198rScore": -20.1621,
     "k198rProb": 6.6,
     "probDifference": -2.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1217
    },
    {
     "sequence": "KLYTCSDSSKQTEAE",
     "pos": 1080,
     "wtScore": 63.3681,
     "wtProb": 19.1,
     "k198rScore": 51.5203,
     "k198rProb": 17,
     "probDifference": -2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1218
    },
    {
     "sequence": "KLVSLRHYYFTIGWN",
     "pos": 1582,
     "wtScore": -43.1311,
     "wtProb": 5,
     "k198rScore": -8.09218,
     "k198rProb": 7,
     "probDifference": 2.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1219
    },
    {
     "sequence": "KGIDSEVYKTARALR",
     "pos": 316,
     "wtScore": -36.7206,
     "wtProb": 5.7,
     "k198rScore": -4.91033,
     "k198rProb": 7.7,
     "probDifference": 2.1,
     "geneName": "HCN2",
     "known": "No",
     "id": 1220
    },
    {
     "sequence": "IMAYGGPSFPGMLVC",
     "pos": 722,
     "wtScore": 45.7497,
     "wtProb": 9.5,
     "k198rScore": 44.3282,
     "k198rProb": 11.5,
     "probDifference": 2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1221
    },
    {
     "sequence": "IVMLFFIYAVIGMQV",
     "pos": 1421,
     "wtScore": -43.8751,
     "wtProb": 4.9,
     "k198rScore": -8.26038,
     "k198rProb": 7,
     "probDifference": 2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1222
    },
    {
     "sequence": "TFTLIGVSFFALPAG",
     "pos": 302,
     "wtScore": 50.0264,
     "wtProb": 10.7,
     "k198rScore": 37.4094,
     "k198rProb": 8.7,
     "probDifference": -2.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1223
    },
    {
     "sequence": "LEPEACFTEGCVQRF",
     "pos": 1181,
     "wtScore": 15.9497,
     "wtProb": 8.7,
     "k198rScore": -20.5857,
     "k198rProb": 6.6,
     "probDifference": -2.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1224
    },
    {
     "sequence": "VTLFRQMSSGAIPPN",
     "pos": 845,
     "wtScore": 51.2072,
     "wtProb": 11.2,
     "k198rScore": 39.1308,
     "k198rProb": 9.1,
     "probDifference": -2.1,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 1225
    },
    {
     "sequence": "DLAIVLLSLMGITLE",
     "pos": 1691,
     "wtScore": 43.0797,
     "wtProb": 9,
     "k198rScore": 43.3709,
     "k198rProb": 11.1,
     "probDifference": 2.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1226
    },
    {
     "sequence": "LEIILKMTAYGAFLH",
     "pos": 972,
     "wtScore": -13.0529,
     "wtProb": 7.1,
     "k198rScore": -32.2622,
     "k198rProb": 5,
     "probDifference": -2.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1227
    },
    {
     "sequence": "LSHSNLHSPSPSTQT",
     "pos": 679,
     "wtScore": 50.9894,
     "wtProb": 11.1,
     "k198rScore": 38.8274,
     "k198rProb": 9.1,
     "probDifference": -2.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 1228
    },
    {
     "sequence": "EGEEFDNTCCPDKRK",
     "pos": 169,
     "wtScore": -2.30511,
     "wtProb": 8.6,
     "k198rScore": -21.2174,
     "k198rProb": 6.5,
     "probDifference": -2.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1229
    },
    {
     "sequence": "ENDHFDTYADALWWG",
     "pos": 263,
     "wtScore": -32.64,
     "wtProb": 5.9,
     "k198rScore": 8.61219,
     "k198rProb": 7.9,
     "probDifference": 2.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1230
    },
    {
     "sequence": "GTWKLLGSVVYAHSK",
     "pos": 222,
     "wtScore": 51.8766,
     "wtProb": 11.5,
     "k198rScore": 39.9548,
     "k198rProb": 9.4,
     "probDifference": -2.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1231
    },
    {
     "sequence": "KGWMDIMYAAVDSRK",
     "pos": 1419,
     "wtScore": -35.5532,
     "wtProb": 5.8,
     "k198rScore": -4.39728,
     "k198rProb": 7.8,
     "probDifference": 2.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1232
    },
    {
     "sequence": "PGHLSGLSVPCPLPS",
     "pos": 679,
     "wtScore": 53.1113,
     "wtProb": 12.2,
     "k198rScore": 41.6281,
     "k198rProb": 10.2,
     "probDifference": -2.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1233
    },
    {
     "sequence": "PEPGACDTRLVRAGA",
     "pos": 547,
     "wtScore": -11.595,
     "wtProb": 7.3,
     "k198rScore": -29.3404,
     "k198rProb": 5.3,
     "probDifference": -2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1234
    },
    {
     "sequence": "ASRPLSASQPSLPHG",
     "pos": 822,
     "wtScore": 49.546,
     "wtProb": 10.5,
     "k198rScore": 36.7404,
     "k198rProb": 8.5,
     "probDifference": -2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1235
    },
    {
     "sequence": "SDLEAGKSLPFIYGD",
     "pos": 60,
     "wtScore": 38.8175,
     "wtProb": 8.6,
     "k198rScore": 42.5167,
     "k198rProb": 10.6,
     "probDifference": 2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1236
    },
    {
     "sequence": "SDLEAGKSLPFIYGD",
     "pos": 63,
     "wtScore": 38.8175,
     "wtProb": 8.6,
     "k198rScore": 42.5167,
     "k198rProb": 10.6,
     "probDifference": 2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1237
    },
    {
     "sequence": "GSTSPKFSTGTRSEK",
     "pos": 786,
     "wtScore": 51.1314,
     "wtProb": 11.2,
     "k198rScore": 39.0804,
     "k198rProb": 9.1,
     "probDifference": -2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1238
    },
    {
     "sequence": "IAMLFFIYAVIGMQM",
     "pos": 1363,
     "wtScore": -43.8937,
     "wtProb": 4.9,
     "k198rScore": -8.33038,
     "k198rProb": 7,
     "probDifference": 2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1239
    },
    {
     "sequence": "KIELKSITADGESPP",
     "pos": 809,
     "wtScore": -1.49998,
     "wtProb": 8.6,
     "k198rScore": -20.9304,
     "k198rProb": 6.5,
     "probDifference": -2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1240
    },
    {
     "sequence": "IGSSVEKYIIDEDHM",
     "pos": 1076,
     "wtScore": -41.1918,
     "wtProb": 5.1,
     "k198rScore": -7.5149,
     "k198rProb": 7.2,
     "probDifference": 2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1241
    },
    {
     "sequence": "PRRNSKTSIFSFRGR",
     "pos": 575,
     "wtScore": 47.8624,
     "wtProb": 10,
     "k198rScore": 33.6442,
     "k198rProb": 8,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "Yes",
     "id": 1242
    },
    {
     "sequence": "TVHRIDNTTVIYNSN",
     "pos": 207,
     "wtScore": -11.5148,
     "wtProb": 7.3,
     "k198rScore": -29.1229,
     "k198rProb": 5.3,
     "probDifference": -2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1243
    },
    {
     "sequence": "RLSSYQISMEMLEDS",
     "pos": 691,
     "wtScore": 53.7986,
     "wtProb": 12.6,
     "k198rScore": 42.4308,
     "k198rProb": 10.6,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1244
    },
    {
     "sequence": "EDPVQHTSFRNHILF",
     "pos": 926,
     "wtScore": 46.7592,
     "wtProb": 9.7,
     "k198rScore": 30.0219,
     "k198rProb": 7.7,
     "probDifference": -2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1245
    },
    {
     "sequence": "PKSLPGSTSPKFSTG",
     "pos": 781,
     "wtScore": -13.1592,
     "wtProb": 7,
     "k198rScore": -32.3265,
     "k198rProb": 5,
     "probDifference": -2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1246
    },
    {
     "sequence": "QDDEPEETKREEEDD",
     "pos": 1669,
     "wtScore": 6.64692,
     "wtProb": 8.7,
     "k198rScore": -10.7602,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1247
    },
    {
     "sequence": "SPYPNPETTGEEDEE",
     "pos": 841,
     "wtScore": 7.65954,
     "wtProb": 8.7,
     "k198rScore": -14.0628,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1248
    },
    {
     "sequence": "LEMLLKWTAYGFVKF",
     "pos": 1251,
     "wtScore": -11.8384,
     "wtProb": 7.3,
     "k198rScore": -29.5152,
     "k198rProb": 5.2,
     "probDifference": -2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1249
    },
    {
     "sequence": "SWGKQYSYALFKAMS",
     "pos": 346,
     "wtScore": -44.89,
     "wtProb": 4.9,
     "k198rScore": -8.66725,
     "k198rProb": 6.9,
     "probDifference": 2,
     "geneName": "HCN1",
     "known": "No",
     "id": 1250
    },
    {
     "sequence": "HEQPEELTNALEISN",
     "pos": 821,
     "wtScore": -2.61041,
     "wtProb": 8.6,
     "k198rScore": -21.0953,
     "k198rProb": 6.5,
     "probDifference": -2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1251
    },
    {
     "sequence": "SHMLCIGYGRQAPES",
     "pos": 429,
     "wtScore": -39.084,
     "wtProb": 5.4,
     "k198rScore": -6.23085,
     "k198rProb": 7.4,
     "probDifference": 2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1252
    },
    {
     "sequence": "KELITAWYIGFLTLI",
     "pos": 265,
     "wtScore": -39.2917,
     "wtProb": 5.3,
     "k198rScore": -6.34939,
     "k198rProb": 7.4,
     "probDifference": 2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1253
    },
    {
     "sequence": "DSCMSNNTGIEISKE",
     "pos": 1052,
     "wtScore": 2.82976,
     "wtProb": 8.7,
     "k198rScore": -12.4824,
     "k198rProb": 6.6,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1254
    },
    {
     "sequence": "EPMEIVRSKACSRRV",
     "pos": 25,
     "wtScore": 55.2126,
     "wtProb": 13.6,
     "k198rScore": 44.3292,
     "k198rProb": 11.5,
     "probDifference": -2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1255
    },
    {
     "sequence": "STTSPPSYDSVTKPE",
     "pos": 1974,
     "wtScore": -22.5566,
     "wtProb": 6,
     "k198rScore": -1.67568,
     "k198rProb": 8,
     "probDifference": 2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1256
    },
    {
     "sequence": "IRARERRSSYSGYSG",
     "pos": 611,
     "wtScore": 48.3242,
     "wtProb": 10.2,
     "k198rScore": 34.8478,
     "k198rProb": 8.1,
     "probDifference": -2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1257
    },
    {
     "sequence": "MPLNSDGTVMFNATL",
     "pos": 1602,
     "wtScore": -8.7956,
     "wtProb": 7.8,
     "k198rScore": -25.6055,
     "k198rProb": 5.8,
     "probDifference": -2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1258
    },
    {
     "sequence": "MPLNSDGTVMFNATL",
     "pos": 1542,
     "wtScore": -8.7956,
     "wtProb": 7.8,
     "k198rScore": -25.6055,
     "k198rProb": 5.8,
     "probDifference": -2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1259
    },
    {
     "sequence": "VGGPSALTSPTGQLP",
     "pos": 663,
     "wtScore": -10.388,
     "wtProb": 7.6,
     "k198rScore": -27.267,
     "k198rProb": 5.5,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1260
    },
    {
     "sequence": "EKKEDNRYSDLKTII",
     "pos": 676,
     "wtScore": -34.5169,
     "wtProb": 5.8,
     "k198rScore": -4.2768,
     "k198rProb": 7.8,
     "probDifference": 2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1261
    },
    {
     "sequence": "GQLLPEGTTTETEIR",
     "pos": 673,
     "wtScore": 5.96626,
     "wtProb": 8.7,
     "k198rScore": -19.3512,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1262
    },
    {
     "sequence": "LLEVCDDYNLNENEY",
     "pos": 79,
     "wtScore": -32.7091,
     "wtProb": 5.9,
     "k198rScore": 8.77777,
     "k198rProb": 7.9,
     "probDifference": 2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1263
    },
    {
     "sequence": "TPMPTPKSSPFLDAA",
     "pos": 1089,
     "wtScore": 48.2995,
     "wtProb": 10.1,
     "k198rScore": 34.8688,
     "k198rProb": 8.1,
     "probDifference": -2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1264
    },
    {
     "sequence": "RAPRTSPYGGLPAAP",
     "pos": 794,
     "wtScore": -41.6455,
     "wtProb": 5.1,
     "k198rScore": -7.8513,
     "k198rProb": 7.1,
     "probDifference": 2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1265
    },
    {
     "sequence": "RQKIHDYYEHRYQGK",
     "pos": 434,
     "wtScore": -32.6107,
     "wtProb": 5.9,
     "k198rScore": 8.78449,
     "k198rProb": 7.9,
     "probDifference": 2,
     "geneName": "HCN1",
     "known": "No",
     "id": 1266
    },
    {
     "sequence": "RQKIHDYYEHRYQGK",
     "pos": 503,
     "wtScore": -32.6107,
     "wtProb": 5.9,
     "k198rScore": 8.78449,
     "k198rProb": 7.9,
     "probDifference": 2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1267
    },
    {
     "sequence": "IASILTNTVEELEES",
     "pos": 722,
     "wtScore": -1.23801,
     "wtProb": 8.6,
     "k198rScore": -20.3848,
     "k198rProb": 6.6,
     "probDifference": -2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1268
    },
    {
     "sequence": "FYHCINTTTGDRFDI",
     "pos": 1379,
     "wtScore": -5.58731,
     "wtProb": 8.3,
     "k198rScore": -22.9509,
     "k198rProb": 6.2,
     "probDifference": -2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1269
    },
    {
     "sequence": "NKLENGGTHREKKES",
     "pos": 1929,
     "wtScore": 6.29047,
     "wtProb": 8.7,
     "k198rScore": -14.8142,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1270
    },
    {
     "sequence": "IPGSPFLSRHNSKSS",
     "pos": 556,
     "wtScore": 48.2491,
     "wtProb": 10.1,
     "k198rScore": 34.7868,
     "k198rProb": 8.1,
     "probDifference": -2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1271
    },
    {
     "sequence": "SGRQRAVSIASILTN",
     "pos": 706,
     "wtScore": 49.4379,
     "wtProb": 10.5,
     "k198rScore": 36.7184,
     "k198rProb": 8.5,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1272
    },
    {
     "sequence": "LPALSPVYFVTFVLV",
     "pos": 1838,
     "wtScore": -41.4117,
     "wtProb": 5.1,
     "k198rScore": -7.74159,
     "k198rProb": 7.1,
     "probDifference": 2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1273
    },
    {
     "sequence": "DPKFIEVTTQELADA",
     "pos": 2141,
     "wtScore": 3.95416,
     "wtProb": 8.7,
     "k198rScore": -19.1847,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1274
    },
    {
     "sequence": "PQMTTLNSTSSTTTP",
     "pos": 648,
     "wtScore": 46.188,
     "wtProb": 9.6,
     "k198rScore": 27.5224,
     "k198rProb": 7.5,
     "probDifference": -2,
     "geneName": "HCN1",
     "known": "No",
     "id": 1275
    },
    {
     "sequence": "DSCISNHTTIEIGKD",
     "pos": 1056,
     "wtScore": 2.34619,
     "wtProb": 8.7,
     "k198rScore": -19.7699,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1276
    },
    {
     "sequence": "AEADERESQFPSIPD",
     "pos": 355,
     "wtScore": 51.7097,
     "wtProb": 11.4,
     "k198rScore": 47.29,
     "k198rProb": 13.4,
     "probDifference": 2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1277
    },
    {
     "sequence": "IMNFRTGTVNEDSSE",
     "pos": 197,
     "wtScore": -2.28573,
     "wtProb": 8.6,
     "k198rScore": -20.7523,
     "k198rProb": 6.6,
     "probDifference": -2,
     "geneName": "HCN1",
     "known": "No",
     "id": 1278
    },
    {
     "sequence": "ECKGNYITYKDGEVD",
     "pos": 1094,
     "wtScore": 1.41025,
     "wtProb": 8.7,
     "k198rScore": -12.5506,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1279
    },
    {
     "sequence": "FQKNGNGTTSGIGSS",
     "pos": 1065,
     "wtScore": 0.350238,
     "wtProb": 8.7,
     "k198rScore": -13.6757,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1280
    },
    {
     "sequence": "QEQGEQEYKNCELDK",
     "pos": 1196,
     "wtScore": -31.0244,
     "wtProb": 5.8,
     "k198rScore": 9.31935,
     "k198rProb": 7.8,
     "probDifference": 2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1281
    },
    {
     "sequence": "ASPRAPRTSPYGGLP",
     "pos": 791,
     "wtScore": -12.5909,
     "wtProb": 7.1,
     "k198rScore": -30.746,
     "k198rProb": 5.1,
     "probDifference": -2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1282
    },
    {
     "sequence": "DDDSPVCYDSRRSPR",
     "pos": 1899,
     "wtScore": -21.7384,
     "wtProb": 6,
     "k198rScore": -1.21245,
     "k198rProb": 8,
     "probDifference": 2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1283
    },
    {
     "sequence": "FINNPSLTVTVPIAV",
     "pos": 1099,
     "wtScore": -9.87772,
     "wtProb": 7.7,
     "k198rScore": -26.6107,
     "k198rProb": 5.6,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1284
    },
    {
     "sequence": "FINNPSLTVTVPIAV",
     "pos": 1111,
     "wtScore": -9.87772,
     "wtProb": 7.7,
     "k198rScore": -26.6107,
     "k198rProb": 5.6,
     "probDifference": -2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1285
    },
    {
     "sequence": "FINNPSLTVTVPIAV",
     "pos": 1101,
     "wtScore": -9.87772,
     "wtProb": 7.7,
     "k198rScore": -26.6107,
     "k198rProb": 5.6,
     "probDifference": -2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1286
    },
    {
     "sequence": "DPKKRMRYFDPLRNE",
     "pos": 67,
     "wtScore": -36.4314,
     "wtProb": 5.7,
     "k198rScore": -5.05089,
     "k198rProb": 7.7,
     "probDifference": 2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1287
    },
    {
     "sequence": "FPTDLPGTEEHQRAR",
     "pos": 639,
     "wtScore": 12.1302,
     "wtProb": 8.7,
     "k198rScore": -19.3743,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1288
    },
    {
     "sequence": "VNNFSEFYKEQKRQE",
     "pos": 419,
     "wtScore": -35.8368,
     "wtProb": 5.7,
     "k198rScore": -4.86592,
     "k198rProb": 7.7,
     "probDifference": 2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1289
    },
    {
     "sequence": "VNNFSEFYKEQKRQE",
     "pos": 423,
     "wtScore": -35.8368,
     "wtProb": 5.7,
     "k198rScore": -4.86592,
     "k198rProb": 7.7,
     "probDifference": 2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1290
    },
    {
     "sequence": "AQAAAMATSAGTVSE",
     "pos": 452,
     "wtScore": -11.4881,
     "wtProb": 7.3,
     "k198rScore": -28.7559,
     "k198rProb": 5.3,
     "probDifference": -2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1291
    },
    {
     "sequence": "ERVFLSVSNYIFTAI",
     "pos": 1330,
     "wtScore": 53.7727,
     "wtProb": 12.6,
     "k198rScore": 42.5007,
     "k198rProb": 10.6,
     "probDifference": -2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1292
    },
    {
     "sequence": "PFATPPATPGSRGWP",
     "pos": 2040,
     "wtScore": -10.416,
     "wtProb": 7.6,
     "k198rScore": -27.1484,
     "k198rProb": 5.6,
     "probDifference": -2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1293
    },
    {
     "sequence": "IQRNFRCYLLKQRLK",
     "pos": 1913,
     "wtScore": -42.1009,
     "wtProb": 5,
     "k198rScore": -8.10581,
     "k198rProb": 7,
     "probDifference": 2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1294
    },
    {
     "sequence": "SAPPPVGSLSQRKRQ",
     "pos": 78,
     "wtScore": 46.9176,
     "wtProb": 9.7,
     "k198rScore": 44.599,
     "k198rProb": 11.7,
     "probDifference": 2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1295
    },
    {
     "sequence": "IFLIWDCSPYWLKVK",
     "pos": 750,
     "wtScore": 49.5465,
     "wtProb": 10.5,
     "k198rScore": 36.9271,
     "k198rProb": 8.5,
     "probDifference": -2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1296
    },
    {
     "sequence": "SLNSPDPYEKIPHVV",
     "pos": 656,
     "wtScore": -31.863,
     "wtProb": 5.8,
     "k198rScore": 9.19256,
     "k198rProb": 7.8,
     "probDifference": 2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1297
    },
    {
     "sequence": "LDPDACFTEGCVQRF",
     "pos": 1161,
     "wtScore": 13.1665,
     "wtProb": 8.7,
     "k198rScore": -19.1305,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1298
    },
    {
     "sequence": "EYYPTKGTSSPAEAE",
     "pos": 662,
     "wtScore": -12.5564,
     "wtProb": 7.1,
     "k198rScore": -30.4489,
     "k198rProb": 5.1,
     "probDifference": -2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1299
    },
    {
     "sequence": "ETDDQGKYMTLVLSR",
     "pos": 1552,
     "wtScore": -39.7798,
     "wtProb": 5.3,
     "k198rScore": -6.97042,
     "k198rProb": 7.3,
     "probDifference": 2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1300
    },
    {
     "sequence": "LIANALGYSELGAIK",
     "pos": 1285,
     "wtScore": -38.2914,
     "wtProb": 5.5,
     "k198rScore": -5.9719,
     "k198rProb": 7.5,
     "probDifference": 2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1301
    },
    {
     "sequence": "PYPNPETTGEEDEEE",
     "pos": 842,
     "wtScore": 4.72332,
     "wtProb": 8.7,
     "k198rScore": -10.5889,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1302
    },
    {
     "sequence": "PAALVGASPESPGAP",
     "pos": 28,
     "wtScore": 61.1306,
     "wtProb": 17.6,
     "k198rScore": 50.1013,
     "k198rProb": 15.6,
     "probDifference": -2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1303
    },
    {
     "sequence": "LTVFQILTGEDWNAV",
     "pos": 702,
     "wtScore": -0.143729,
     "wtProb": 8.6,
     "k198rScore": -11.6109,
     "k198rProb": 6.6,
     "probDifference": -2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1304
    },
    {
     "sequence": "PATDDNGTTTETEMR",
     "pos": 682,
     "wtScore": 0.500133,
     "wtProb": 8.7,
     "k198rScore": -13.8185,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1305
    },
    {
     "sequence": "PDRRDSASPGAAGGL",
     "pos": 867,
     "wtScore": 62.4284,
     "wtProb": 18.4,
     "k198rScore": 56.4584,
     "k198rProb": 20.4,
     "probDifference": 2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1306
    },
    {
     "sequence": "ITMTTVGYGDIYPKT",
     "pos": 379,
     "wtScore": -35.229,
     "wtProb": 5.8,
     "k198rScore": -4.59403,
     "k198rProb": 7.8,
     "probDifference": 2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1307
    },
    {
     "sequence": "ITMTTVGYGDIYPKT",
     "pos": 383,
     "wtScore": -35.229,
     "wtProb": 5.8,
     "k198rScore": -4.59403,
     "k198rProb": 7.8,
     "probDifference": 2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1308
    },
    {
     "sequence": "KARPLRRYIPKNQHQ",
     "pos": 1221,
     "wtScore": -40.766,
     "wtProb": 5.2,
     "k198rScore": -7.57715,
     "k198rProb": 7.2,
     "probDifference": 2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1309
    },
    {
     "sequence": "TSCATDFTETERSPL",
     "pos": 613,
     "wtScore": 15.0397,
     "wtProb": 8.7,
     "k198rScore": -19.4255,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1310
    },
    {
     "sequence": "EISNIVFTSMFALEM",
     "pos": 832,
     "wtScore": -13.8926,
     "wtProb": 6.9,
     "k198rScore": -34.1003,
     "k198rProb": 4.9,
     "probDifference": -2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1311
    },
    {
     "sequence": "FTFPSQQSPRNEPYV",
     "pos": 597,
     "wtScore": 52.1809,
     "wtProb": 11.7,
     "k198rScore": 40.6474,
     "k198rProb": 9.7,
     "probDifference": -2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1312
    },
    {
     "sequence": "GAPGPAASTRPASSS",
     "pos": 836,
     "wtScore": 46.7142,
     "wtProb": 9.7,
     "k198rScore": 30.639,
     "k198rProb": 7.7,
     "probDifference": -2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1313
    },
    {
     "sequence": "ISQDRDDYVFGPNGG",
     "pos": 815,
     "wtScore": -34.7713,
     "wtProb": 5.8,
     "k198rScore": 10.2116,
     "k198rProb": 7.8,
     "probDifference": 2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1314
    },
    {
     "sequence": "STILLEETPSQGDRP",
     "pos": 759,
     "wtScore": -8.92859,
     "wtProb": 7.8,
     "k198rScore": -25.5028,
     "k198rProb": 5.8,
     "probDifference": -2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1315
    },
    {
     "sequence": "MIVGATCYAMFIGHA",
     "pos": 454,
     "wtScore": -42.0615,
     "wtProb": 5,
     "k198rScore": -8.16393,
     "k198rProb": 7,
     "probDifference": 2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1316
    },
    {
     "sequence": "PPPPPQVTSAIATLQ",
     "pos": 723,
     "wtScore": -13.7496,
     "wtProb": 6.9,
     "k198rScore": -33.1273,
     "k198rProb": 5,
     "probDifference": -2,
     "geneName": "HCN2",
     "known": "No",
     "id": 1317
    },
    {
     "sequence": "STTSPPSYDSVTKPD",
     "pos": 1969,
     "wtScore": -23.1891,
     "wtProb": 6,
     "k198rScore": -3.68186,
     "k198rProb": 7.9,
     "probDifference": 2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1318
    },
    {
     "sequence": "LTISSEHYNQPDWLT",
     "pos": 544,
     "wtScore": -39.8609,
     "wtProb": 5.3,
     "k198rScore": -7.11259,
     "k198rProb": 7.3,
     "probDifference": 2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1319
    },
    {
     "sequence": "IASILTNTMEELEES",
     "pos": 713,
     "wtScore": -2.26175,
     "wtProb": 8.6,
     "k198rScore": -20.5807,
     "k198rProb": 6.6,
     "probDifference": -2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1320
    },
    {
     "sequence": "IASILTNTMEELEES",
     "pos": 714,
     "wtScore": -2.26175,
     "wtProb": 8.6,
     "k198rScore": -20.5807,
     "k198rProb": 6.6,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1321
    },
    {
     "sequence": "SKEKLNATSSSEGST",
     "pos": 1135,
     "wtScore": 0.54325,
     "wtProb": 8.7,
     "k198rScore": -19.4132,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1322
    },
    {
     "sequence": "SKEKLNATSSSEGST",
     "pos": 1137,
     "wtScore": 0.54325,
     "wtProb": 8.7,
     "k198rScore": -19.4132,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1323
    },
    {
     "sequence": "FINNPNLTVRVPIAV",
     "pos": 1092,
     "wtScore": -8.31328,
     "wtProb": 7.9,
     "k198rScore": -24.754,
     "k198rProb": 6,
     "probDifference": -2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1324
    },
    {
     "sequence": "PRGWAFIYHAYVFLL",
     "pos": 94,
     "wtScore": -40.0968,
     "wtProb": 5.2,
     "k198rScore": -7.23855,
     "k198rProb": 7.2,
     "probDifference": 2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1325
    },
    {
     "sequence": "RLPRPAGYPSTVSTV",
     "pos": 1831,
     "wtScore": -39.1125,
     "wtProb": 5.4,
     "k198rScore": -6.53611,
     "k198rProb": 7.3,
     "probDifference": 2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1326
    },
    {
     "sequence": "PPVQCPPSTSWQPQS",
     "pos": 714,
     "wtScore": 46.4695,
     "wtProb": 9.6,
     "k198rScore": 29.7975,
     "k198rProb": 7.6,
     "probDifference": -2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1327
    },
    {
     "sequence": "KTPVRPVTQGGSLQS",
     "pos": 2049,
     "wtScore": -11.115,
     "wtProb": 7.4,
     "k198rScore": -27.9792,
     "k198rProb": 5.4,
     "probDifference": -2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1328
    },
    {
     "sequence": "ENEDKSPYPNPETTG",
     "pos": 836,
     "wtScore": -35.0316,
     "wtProb": 5.8,
     "k198rScore": -4.66533,
     "k198rProb": 7.8,
     "probDifference": 2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1329
    },
    {
     "sequence": "FYHCINYTTGEMFDV",
     "pos": 1369,
     "wtScore": 1.82739,
     "wtProb": 8.7,
     "k198rScore": -15.1219,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1330
    },
    {
     "sequence": "KAVLSPESSIYTTAS",
     "pos": 722,
     "wtScore": 57.9111,
     "wtProb": 15.3,
     "k198rScore": 47.1797,
     "k198rProb": 13.3,
     "probDifference": -2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1331
    },
    {
     "sequence": "YGYTSFDTFSWAFLS",
     "pos": 367,
     "wtScore": -11.5688,
     "wtProb": 7.3,
     "k198rScore": -28.6741,
     "k198rProb": 5.3,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1332
    },
    {
     "sequence": "YGYTSFDTFSWAFLS",
     "pos": 366,
     "wtScore": -11.5688,
     "wtProb": 7.3,
     "k198rScore": -28.6741,
     "k198rProb": 5.3,
     "probDifference": -2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1333
    },
    {
     "sequence": "YGYTSFDTFSWAFLS",
     "pos": 368,
     "wtScore": -11.5688,
     "wtProb": 7.3,
     "k198rScore": -28.6741,
     "k198rProb": 5.3,
     "probDifference": -2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1334
    },
    {
     "sequence": "MDLLEDPTSRQRAMS",
     "pos": 698,
     "wtScore": -11.1166,
     "wtProb": 7.4,
     "k198rScore": -27.9521,
     "k198rProb": 5.4,
     "probDifference": -2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1335
    },
    {
     "sequence": "AAGCCCRYKGWRGRL",
     "pos": 183,
     "wtScore": -46.0688,
     "wtProb": 4.9,
     "k198rScore": -9.12981,
     "k198rProb": 6.9,
     "probDifference": 2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1336
    },
    {
     "sequence": "ANCIANHTGADIHRN",
     "pos": 1048,
     "wtScore": -1.2003,
     "wtProb": 8.6,
     "k198rScore": -11.6102,
     "k198rProb": 6.6,
     "probDifference": -2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1337
    },
    {
     "sequence": "YLRDGNGTTSGVGTG",
     "pos": 1069,
     "wtScore": 0.250504,
     "wtProb": 8.7,
     "k198rScore": -14.3297,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1338
    },
    {
     "sequence": "HNHHNHNSIGKQVPT",
     "pos": 1744,
     "wtScore": 52.2696,
     "wtProb": 11.7,
     "k198rScore": 47.7162,
     "k198rProb": 13.7,
     "probDifference": 2,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 1339
    },
    {
     "sequence": "KELVTAWYIGFLCLI",
     "pos": 236,
     "wtScore": -42.1483,
     "wtProb": 5,
     "k198rScore": -8.27688,
     "k198rProb": 7,
     "probDifference": 2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1340
    },
    {
     "sequence": "FDNVGLGYLSLLQVA",
     "pos": 1411,
     "wtScore": -39.6642,
     "wtProb": 5.3,
     "k198rScore": -7.03161,
     "k198rProb": 7.3,
     "probDifference": 2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1341
    },
    {
     "sequence": "EEGSVGSSSPQDTGH",
     "pos": 853,
     "wtScore": 50.1179,
     "wtProb": 10.7,
     "k198rScore": 37.9155,
     "k198rProb": 8.8,
     "probDifference": -2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1342
    },
    {
     "sequence": "SIRGSLFSPRRNSRT",
     "pos": 564,
     "wtScore": 51.8039,
     "wtProb": 11.5,
     "k198rScore": 40.1521,
     "k198rProb": 9.5,
     "probDifference": -2,
     "geneName": "SCN1A",
     "known": "Yes",
     "id": 1343
    },
    {
     "sequence": "VILISIVSFCLETLP",
     "pos": 178,
     "wtScore": 48.3558,
     "wtProb": 10.1,
     "k198rScore": 35.316,
     "k198rProb": 8.2,
     "probDifference": -2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1344
    },
    {
     "sequence": "LTIDEMESAASTLLN",
     "pos": 2100,
     "wtScore": 57.1949,
     "wtProb": 14.8,
     "k198rScore": 51.2739,
     "k198rProb": 16.8,
     "probDifference": 2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1345
    },
    {
     "sequence": "AQLLHVSSPNLASDS",
     "pos": 431,
     "wtScore": 47.4098,
     "wtProb": 9.9,
     "k198rScore": 33.3769,
     "k198rProb": 7.9,
     "probDifference": -2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1346
    },
    {
     "sequence": "DGESPPATKINMDDL",
     "pos": 818,
     "wtScore": -7.47088,
     "wtProb": 8.1,
     "k198rScore": -23.9133,
     "k198rProb": 6.1,
     "probDifference": -2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1347
    },
    {
     "sequence": "APQTPPSTARPLPVT",
     "pos": 732,
     "wtScore": -12.1191,
     "wtProb": 7.2,
     "k198rScore": -29.3131,
     "k198rProb": 5.3,
     "probDifference": -2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1348
    },
    {
     "sequence": "YGFQTYFTNAWCWLD",
     "pos": 1268,
     "wtScore": -8.45432,
     "wtProb": 7.9,
     "k198rScore": -24.8067,
     "k198rProb": 5.9,
     "probDifference": -2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1349
    },
    {
     "sequence": "QEQGEKEYKNCELDK",
     "pos": 1162,
     "wtScore": -31.3283,
     "wtProb": 5.8,
     "k198rScore": 9.78824,
     "k198rProb": 7.8,
     "probDifference": 2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1350
    },
    {
     "sequence": "PPSYDSVTKPEKEKF",
     "pos": 1978,
     "wtScore": 0.128308,
     "wtProb": 8.7,
     "k198rScore": -14.2731,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1351
    },
    {
     "sequence": "ITEKTDLTMSTAACP",
     "pos": 1975,
     "wtScore": -8.13709,
     "wtProb": 8,
     "k198rScore": -24.4772,
     "k198rProb": 6,
     "probDifference": -2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1352
    },
    {
     "sequence": "GTWKLLGSAICAHSK",
     "pos": 251,
     "wtScore": 51.8712,
     "wtProb": 11.5,
     "k198rScore": 47.3658,
     "k198rProb": 13.5,
     "probDifference": 2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1353
    },
    {
     "sequence": "IEDVNNKTECEKLME",
     "pos": 1373,
     "wtScore": 16.2119,
     "wtProb": 8.6,
     "k198rScore": -14.3853,
     "k198rProb": 6.7,
     "probDifference": -2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1354
    },
    {
     "sequence": "VEILLKMTTFGAFLH",
     "pos": 938,
     "wtScore": -14.2137,
     "wtProb": 6.9,
     "k198rScore": -34.7933,
     "k198rProb": 4.9,
     "probDifference": -2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1355
    },
    {
     "sequence": "EVKMNHDTEACSEPS",
     "pos": 1927,
     "wtScore": 15.2298,
     "wtProb": 8.7,
     "k198rScore": -18.6655,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1356
    },
    {
     "sequence": "SSIDSFISCATDFPE",
     "pos": 592,
     "wtScore": 55.729,
     "wtProb": 13.9,
     "k198rScore": 44.9745,
     "k198rProb": 12,
     "probDifference": -1.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1357
    },
    {
     "sequence": "KKIWKRTSMKLLDQV",
     "pos": 1644,
     "wtScore": 47.6622,
     "wtProb": 10,
     "k198rScore": 34.1357,
     "k198rProb": 8,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 1358
    },
    {
     "sequence": "MIVGATCYAMFVGHA",
     "pos": 385,
     "wtScore": -42.5217,
     "wtProb": 5,
     "k198rScore": -8.49309,
     "k198rProb": 7,
     "probDifference": 1.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 1359
    },
    {
     "sequence": "YGYTSFDTFSWAFLA",
     "pos": 354,
     "wtScore": -11.6031,
     "wtProb": 7.3,
     "k198rScore": -28.5053,
     "k198rProb": 5.4,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1360
    },
    {
     "sequence": "VSLVGGPSVPTSPVG",
     "pos": 657,
     "wtScore": 52.6065,
     "wtProb": 11.9,
     "k198rScore": 41.1608,
     "k198rProb": 10,
     "probDifference": -1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1361
    },
    {
     "sequence": "RQCTANGTECRSGWV",
     "pos": 330,
     "wtScore": 14.1482,
     "wtProb": 8.7,
     "k198rScore": -18.5658,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1362
    },
    {
     "sequence": "FVSYIIISFLVVVNM",
     "pos": 1762,
     "wtScore": 50.9212,
     "wtProb": 11.1,
     "k198rScore": 39.1629,
     "k198rProb": 9.1,
     "probDifference": -1.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1363
    },
    {
     "sequence": "FVSYIIISFLVVVNM",
     "pos": 1757,
     "wtScore": 50.9212,
     "wtProb": 11.1,
     "k198rScore": 39.1629,
     "k198rProb": 9.1,
     "probDifference": -1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1364
    },
    {
     "sequence": "FVSYIIISFLVVVNM",
     "pos": 1772,
     "wtScore": 50.9212,
     "wtProb": 11.1,
     "k198rScore": 39.1629,
     "k198rProb": 9.1,
     "probDifference": -1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1365
    },
    {
     "sequence": "EPRGSQASFMQRQFG",
     "pos": 160,
     "wtScore": 55.5268,
     "wtProb": 13.8,
     "k198rScore": 44.7239,
     "k198rProb": 11.8,
     "probDifference": -1.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 1366
    },
    {
     "sequence": "PYQYKFWYVVNSSPF",
     "pos": 1199,
     "wtScore": -41.6808,
     "wtProb": 5.1,
     "k198rScore": -8.17269,
     "k198rProb": 7,
     "probDifference": 1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1367
    },
    {
     "sequence": "HRDSLEPTEGSGAGG",
     "pos": 2234,
     "wtScore": 17.075,
     "wtProb": 8.6,
     "k198rScore": -19.0696,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1368
    },
    {
     "sequence": "YLKDGNGTTSGIGSS",
     "pos": 1073,
     "wtScore": -0.875704,
     "wtProb": 8.6,
     "k198rScore": -13.9538,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1369
    },
    {
     "sequence": "TEACSEPSLLSTEML",
     "pos": 1934,
     "wtScore": 46.7616,
     "wtProb": 9.7,
     "k198rScore": 44.4234,
     "k198rProb": 11.6,
     "probDifference": 1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1370
    },
    {
     "sequence": "ASVHHLVYHHHHHHH",
     "pos": 518,
     "wtScore": -38.7715,
     "wtProb": 5.4,
     "k198rScore": -6.49821,
     "k198rProb": 7.4,
     "probDifference": 1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1371
    },
    {
     "sequence": "WLTEVQDTANKALLA",
     "pos": 557,
     "wtScore": -9.83104,
     "wtProb": 7.7,
     "k198rScore": -26.2632,
     "k198rProb": 5.7,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1372
    },
    {
     "sequence": "HGSRSTSSLPPEPME",
     "pos": 14,
     "wtScore": 32.8452,
     "wtProb": 8.4,
     "k198rScore": 41.9315,
     "k198rProb": 10.3,
     "probDifference": 1.9,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 1373
    },
    {
     "sequence": "LKPEACFTEGCIKKF",
     "pos": 1169,
     "wtScore": 18.3102,
     "wtProb": 8.6,
     "k198rScore": -19.7187,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1374
    },
    {
     "sequence": "LISLRHYYFTIGWNI",
     "pos": 1598,
     "wtScore": -46.1161,
     "wtProb": 4.9,
     "k198rScore": -9.56278,
     "k198rProb": 6.8,
     "probDifference": 1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1375
    },
    {
     "sequence": "RPPRATDTPGPGPGS",
     "pos": 750,
     "wtScore": -0.739398,
     "wtProb": 8.6,
     "k198rScore": -19.4072,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1376
    },
    {
     "sequence": "STASLPSYDSVTKPE",
     "pos": 1946,
     "wtScore": -22.1633,
     "wtProb": 6,
     "k198rScore": -2.62457,
     "k198rProb": 8,
     "probDifference": 1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1377
    },
    {
     "sequence": "KGYLDWITQAEDIDP",
     "pos": 442,
     "wtScore": 6.74221,
     "wtProb": 8.7,
     "k198rScore": -16.8083,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 1378
    },
    {
     "sequence": "KGYLDWITQAEDIDP",
     "pos": 441,
     "wtScore": 6.74221,
     "wtProb": 8.7,
     "k198rScore": -16.8083,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1379
    },
    {
     "sequence": "RFMASNPSKVSYQPI",
     "pos": 1898,
     "wtScore": 50.5661,
     "wtProb": 11,
     "k198rScore": 38.6758,
     "k198rProb": 9,
     "probDifference": -1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1380
    },
    {
     "sequence": "ESYLENGTKGFDWEE",
     "pos": 296,
     "wtScore": -2.04467,
     "wtProb": 8.6,
     "k198rScore": -20.0188,
     "k198rProb": 6.6,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1381
    },
    {
     "sequence": "GRLRSRDSSCGRPGT",
     "pos": 79,
     "wtScore": 49.9438,
     "wtProb": 10.7,
     "k198rScore": 37.7739,
     "k198rProb": 8.7,
     "probDifference": -1.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 1382
    },
    {
     "sequence": "LTTPQHISTILLEET",
     "pos": 752,
     "wtScore": 46.2005,
     "wtProb": 9.5,
     "k198rScore": 29.1747,
     "k198rProb": 7.6,
     "probDifference": -1.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1383
    },
    {
     "sequence": "IADACDLTIDEMESA",
     "pos": 2094,
     "wtScore": -0.515429,
     "wtProb": 8.6,
     "k198rScore": -13.1755,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1384
    },
    {
     "sequence": "EDWNSVMYDGIMAYG",
     "pos": 712,
     "wtScore": -23.9708,
     "wtProb": 5.9,
     "k198rScore": -4.03514,
     "k198rProb": 7.9,
     "probDifference": 1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1385
    },
    {
     "sequence": "MYDGIMAYGGPSFPG",
     "pos": 718,
     "wtScore": -40.0737,
     "wtProb": 5.2,
     "k198rScore": -7.50971,
     "k198rProb": 7.2,
     "probDifference": 1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1386
    },
    {
     "sequence": "LTVFQILTGEDWNSV",
     "pos": 703,
     "wtScore": 2.56767,
     "wtProb": 8.7,
     "k198rScore": -10.187,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1387
    },
    {
     "sequence": "PINFNESYLENGTKG",
     "pos": 291,
     "wtScore": -38.6703,
     "wtProb": 5.4,
     "k198rScore": -6.50107,
     "k198rProb": 7.4,
     "probDifference": 1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1388
    },
    {
     "sequence": "EPDISYRTFTPASLT",
     "pos": 2034,
     "wtScore": -11.1973,
     "wtProb": 7.4,
     "k198rScore": -27.7729,
     "k198rProb": 5.5,
     "probDifference": -1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1389
    },
    {
     "sequence": "HPRQGHGTSPVGDHG",
     "pos": 729,
     "wtScore": 1.07065,
     "wtProb": 8.6,
     "k198rScore": -19.2933,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1390
    },
    {
     "sequence": "MLLKWVAYGYQTYFT",
     "pos": 1273,
     "wtScore": -38.147,
     "wtProb": 5.5,
     "k198rScore": -6.16094,
     "k198rProb": 7.4,
     "probDifference": 1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1391
    },
    {
     "sequence": "SAAEGGSTTLRRRTP",
     "pos": 2213,
     "wtScore": -5.84599,
     "wtProb": 8.2,
     "k198rScore": -22.5654,
     "k198rProb": 6.3,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1392
    },
    {
     "sequence": "YHYCFNETSEIRFEI",
     "pos": 1359,
     "wtScore": -12.396,
     "wtProb": 7.2,
     "k198rScore": -29.4679,
     "k198rProb": 5.2,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1393
    },
    {
     "sequence": "GHLEGRGSLSPPLIM",
     "pos": 1070,
     "wtScore": 34.7843,
     "wtProb": 8.4,
     "k198rScore": 42.0275,
     "k198rProb": 10.3,
     "probDifference": 1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1394
    },
    {
     "sequence": "QKCPPCWYRFANVFL",
     "pos": 730,
     "wtScore": -39.3228,
     "wtProb": 5.3,
     "k198rScore": -7.03849,
     "k198rProb": 7.3,
     "probDifference": 1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1395
    },
    {
     "sequence": "FDNVGFGYLSLLQVA",
     "pos": 1421,
     "wtScore": -40.0443,
     "wtProb": 5.3,
     "k198rScore": -7.51483,
     "k198rProb": 7.2,
     "probDifference": 1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1396
    },
    {
     "sequence": "DKGPIYNYRVEISIF",
     "pos": 1157,
     "wtScore": -42.0162,
     "wtProb": 5,
     "k198rScore": -8.44854,
     "k198rProb": 7,
     "probDifference": 1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1397
    },
    {
     "sequence": "FKESLRPYDVMDVIE",
     "pos": 561,
     "wtScore": -20.9452,
     "wtProb": 6.1,
     "k198rScore": -1.69171,
     "k198rProb": 8,
     "probDifference": 1.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1398
    },
    {
     "sequence": "WWATITMTTVGYGDI",
     "pos": 379,
     "wtScore": -13.6356,
     "wtProb": 7,
     "k198rScore": -31.7848,
     "k198rProb": 5,
     "probDifference": -1.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1399
    },
    {
     "sequence": "WWATITMTTVGYGDI",
     "pos": 375,
     "wtScore": -13.6356,
     "wtProb": 7,
     "k198rScore": -31.7848,
     "k198rProb": 5,
     "probDifference": -1.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1400
    },
    {
     "sequence": "LVANALGYSELGAIK",
     "pos": 1293,
     "wtScore": -39.1372,
     "wtProb": 5.4,
     "k198rScore": -6.92921,
     "k198rProb": 7.3,
     "probDifference": 1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1401
    },
    {
     "sequence": "LVPPGPDSFRFFTRE",
     "pos": 12,
     "wtScore": 60.3182,
     "wtProb": 17.1,
     "k198rScore": 54.2071,
     "k198rProb": 19,
     "probDifference": 1.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1402
    },
    {
     "sequence": "DGFIVTLSLVELGLA",
     "pos": 842,
     "wtScore": 86.3101,
     "wtProb": 26.2,
     "k198rScore": 69.7538,
     "k198rProb": 24.3,
     "probDifference": -1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1403
    },
    {
     "sequence": "PYVARPSTSEIEDQS",
     "pos": 609,
     "wtScore": -14.0671,
     "wtProb": 6.9,
     "k198rScore": -33.4024,
     "k198rProb": 5,
     "probDifference": -1.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1404
    },
    {
     "sequence": "LKTDTGDTVPDRKNF",
     "pos": 949,
     "wtScore": -3.03169,
     "wtProb": 8.5,
     "k198rScore": -20.5832,
     "k198rProb": 6.6,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1405
    },
    {
     "sequence": "GRDWPWIYFVTLIII",
     "pos": 383,
     "wtScore": -42.3199,
     "wtProb": 5,
     "k198rScore": -8.6385,
     "k198rProb": 7,
     "probDifference": 1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1406
    },
    {
     "sequence": "LPGHPQDTYDPEADH",
     "pos": 21,
     "wtScore": 0.99594,
     "wtProb": 8.6,
     "k198rScore": -19.1243,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1407
    },
    {
     "sequence": "TIDKVSPYGFFAHDP",
     "pos": 709,
     "wtScore": -38.4195,
     "wtProb": 5.5,
     "k198rScore": -6.3231,
     "k198rProb": 7.4,
     "probDifference": 1.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1408
    },
    {
     "sequence": "GSSFAVFYFISFYML",
     "pos": 1502,
     "wtScore": -47.2882,
     "wtProb": 4.9,
     "k198rScore": -17.8701,
     "k198rProb": 6.8,
     "probDifference": 1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1409
    },
    {
     "sequence": "FVSYIIISFLIVVNM",
     "pos": 1752,
     "wtScore": 50.9695,
     "wtProb": 11.1,
     "k198rScore": 39.2963,
     "k198rProb": 9.2,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1410
    },
    {
     "sequence": "VGPNGGITNFDNFAF",
     "pos": 344,
     "wtScore": -1.58826,
     "wtProb": 8.6,
     "k198rScore": -13.6261,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1411
    },
    {
     "sequence": "ELRREAETMREREGE",
     "pos": 157,
     "wtScore": 0.229133,
     "wtProb": 8.7,
     "k198rScore": -15.6289,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1412
    },
    {
     "sequence": "GFELPWVYFVSLVIF",
     "pos": 384,
     "wtScore": -38.393,
     "wtProb": 5.5,
     "k198rScore": -6.33286,
     "k198rProb": 7.4,
     "probDifference": 1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1413
    },
    {
     "sequence": "KLMEGNNTEIRWKNV",
     "pos": 1384,
     "wtScore": 19.5118,
     "wtProb": 8.6,
     "k198rScore": -11.0805,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1414
    },
    {
     "sequence": "MLLKWVAYGFQTYFT",
     "pos": 1261,
     "wtScore": -37.0571,
     "wtProb": 5.6,
     "k198rScore": -5.72562,
     "k198rProb": 7.5,
     "probDifference": 1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1415
    },
    {
     "sequence": "KHGSRSTSSLPPEPM",
     "pos": 13,
     "wtScore": 47.5068,
     "wtProb": 9.9,
     "k198rScore": 34.06,
     "k198rProb": 8,
     "probDifference": -1.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1416
    },
    {
     "sequence": "FMLLFFIYAALGVEL",
     "pos": 1762,
     "wtScore": -40.1236,
     "wtProb": 5.2,
     "k198rScore": -7.61048,
     "k198rProb": 7.2,
     "probDifference": 1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1417
    },
    {
     "sequence": "ILLIIVGSFFMINLC",
     "pos": 406,
     "wtScore": 47.389,
     "wtProb": 9.9,
     "k198rScore": 33.7224,
     "k198rProb": 8,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1418
    },
    {
     "sequence": "CINWNQYYNVCRSGD",
     "pos": 343,
     "wtScore": -43.9753,
     "wtProb": 4.9,
     "k198rScore": -9.43577,
     "k198rProb": 6.8,
     "probDifference": 1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1419
    },
    {
     "sequence": "NKVTIDDYREEDEDK",
     "pos": 807,
     "wtScore": -27.6653,
     "wtProb": 5.8,
     "k198rScore": 10.9763,
     "k198rProb": 7.7,
     "probDifference": 1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1420
    },
    {
     "sequence": "EMNNLQISVIRIKKG",
     "pos": 999,
     "wtScore": 49.3237,
     "wtProb": 10.4,
     "k198rScore": 36.9672,
     "k198rProb": 8.5,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1421
    },
    {
     "sequence": "PEKRRGLYLTVPQCP",
     "pos": 2324,
     "wtScore": -35.9407,
     "wtProb": 5.7,
     "k198rScore": -5.29057,
     "k198rProb": 7.6,
     "probDifference": 1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1422
    },
    {
     "sequence": "DFDPYYLTQKTFVVL",
     "pos": 89,
     "wtScore": -0.073594,
     "wtProb": 8.6,
     "k198rScore": -18.9477,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1423
    },
    {
     "sequence": "EGEEQAQYLQVTSCP",
     "pos": 428,
     "wtScore": -40.6459,
     "wtProb": 5.2,
     "k198rScore": -7.89668,
     "k198rProb": 7.1,
     "probDifference": 1.9,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 1424
    },
    {
     "sequence": "KCIQWPPTNASLEEH",
     "pos": 282,
     "wtScore": -9.36612,
     "wtProb": 7.7,
     "k198rScore": -25.4575,
     "k198rProb": 5.8,
     "probDifference": -1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1425
    },
    {
     "sequence": "YGDKYPQTWNGRLLA",
     "pos": 286,
     "wtScore": -11.8906,
     "wtProb": 7.3,
     "k198rScore": -28.5821,
     "k198rProb": 5.4,
     "probDifference": -1.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1426
    },
    {
     "sequence": "GYSQGSRSSRIFPSL",
     "pos": 625,
     "wtScore": 48.2905,
     "wtProb": 10.1,
     "k198rScore": 35.5536,
     "k198rProb": 8.2,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1427
    },
    {
     "sequence": "LFAGKYHYCFNETSE",
     "pos": 1354,
     "wtScore": -39.6565,
     "wtProb": 5.3,
     "k198rScore": -7.39467,
     "k198rProb": 7.2,
     "probDifference": 1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1428
    },
    {
     "sequence": "YWGIDEIYLESCCQA",
     "pos": 131,
     "wtScore": -40.6091,
     "wtProb": 5.2,
     "k198rScore": -7.88856,
     "k198rProb": 7.1,
     "probDifference": 1.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1429
    },
    {
     "sequence": "YWGIDEIYLESCCQA",
     "pos": 127,
     "wtScore": -40.6091,
     "wtProb": 5.2,
     "k198rScore": -7.88856,
     "k198rProb": 7.1,
     "probDifference": 1.9,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 1430
    },
    {
     "sequence": "DQRKTIKTMLEYADK",
     "pos": 1249,
     "wtScore": -4.17552,
     "wtProb": 8.4,
     "k198rScore": -21.009,
     "k198rProb": 6.5,
     "probDifference": -1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1431
    },
    {
     "sequence": "ERAKRNGSIVSMNMK",
     "pos": 443,
     "wtScore": 50.827,
     "wtProb": 11.1,
     "k198rScore": 39.1836,
     "k198rProb": 9.2,
     "probDifference": -1.9,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 1432
    },
    {
     "sequence": "REDKHCLSYLPALSP",
     "pos": 1829,
     "wtScore": 57.6699,
     "wtProb": 15.2,
     "k198rScore": 46.9919,
     "k198rProb": 13.3,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1433
    },
    {
     "sequence": "VRAGAPPSPPSPGRG",
     "pos": 557,
     "wtScore": 53.7963,
     "wtProb": 12.6,
     "k198rScore": 42.7318,
     "k198rProb": 10.7,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1434
    },
    {
     "sequence": "FLRPYYQTEEGEENP",
     "pos": 280,
     "wtScore": 19.5971,
     "wtProb": 8.6,
     "k198rScore": -13.9106,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1435
    },
    {
     "sequence": "HATALIQSLDSSRRQ",
     "pos": 467,
     "wtScore": 51.7979,
     "wtProb": 11.5,
     "k198rScore": 47.1927,
     "k198rProb": 13.4,
     "probDifference": 1.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 1436
    },
    {
     "sequence": "HATALIQSLDSSRRQ",
     "pos": 398,
     "wtScore": 51.7979,
     "wtProb": 11.5,
     "k198rScore": 47.1927,
     "k198rProb": 13.4,
     "probDifference": 1.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 1437
    },
    {
     "sequence": "NGTLINETVFEFDWK",
     "pos": 307,
     "wtScore": 0.168288,
     "wtProb": 8.7,
     "k198rScore": -18.3289,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1438
    },
    {
     "sequence": "MFALRHYYFTIGWNI",
     "pos": 1579,
     "wtScore": -42.9416,
     "wtProb": 5,
     "k198rScore": -9.04223,
     "k198rProb": 6.9,
     "probDifference": 1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1439
    },
    {
     "sequence": "DPKFVSATKHEIADA",
     "pos": 2083,
     "wtScore": 1.19936,
     "wtProb": 8.6,
     "k198rScore": -18.4444,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1440
    },
    {
     "sequence": "RYIPKNPYQYKFWYV",
     "pos": 1193,
     "wtScore": -43.6326,
     "wtProb": 5,
     "k198rScore": -9.36798,
     "k198rProb": 6.8,
     "probDifference": 1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1441
    },
    {
     "sequence": "TSSIDSFTSCATDFT",
     "pos": 606,
     "wtScore": -8.24157,
     "wtProb": 7.9,
     "k198rScore": -24.2995,
     "k198rProb": 6,
     "probDifference": -1.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1442
    },
    {
     "sequence": "TPPSSATTYVERPTV",
     "pos": 739,
     "wtScore": -1.26673,
     "wtProb": 8.6,
     "k198rScore": -14.8879,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1443
    },
    {
     "sequence": "IQRAYRRYLLKQKVK",
     "pos": 1918,
     "wtScore": -40.4297,
     "wtProb": 5.2,
     "k198rScore": -7.84382,
     "k198rProb": 7.1,
     "probDifference": 1.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1444
    },
    {
     "sequence": "FETNTTSYFNGTMDS",
     "pos": 293,
     "wtScore": -42.9668,
     "wtProb": 5,
     "k198rScore": -9.05661,
     "k198rProb": 6.9,
     "probDifference": 1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1445
    },
    {
     "sequence": "NIRTANCTTANQNCV",
     "pos": 478,
     "wtScore": -8.11092,
     "wtProb": 8,
     "k198rScore": -24.0734,
     "k198rProb": 6.1,
     "probDifference": -1.9,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1446
    },
    {
     "sequence": "KPDEQPKYEDNIYMY",
     "pos": 1433,
     "wtScore": -32.9109,
     "wtProb": 5.8,
     "k198rScore": -4.79973,
     "k198rProb": 7.7,
     "probDifference": 1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1447
    },
    {
     "sequence": "NLFAGKFYHCINTTT",
     "pos": 1373,
     "wtScore": -39.2936,
     "wtProb": 5.3,
     "k198rScore": -7.16283,
     "k198rProb": 7.2,
     "probDifference": 1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1448
    },
    {
     "sequence": "KLLGSVVYAHSKELV",
     "pos": 225,
     "wtScore": -34.8862,
     "wtProb": 5.8,
     "k198rScore": -5.04541,
     "k198rProb": 7.7,
     "probDifference": 1.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1449
    },
    {
     "sequence": "SKDKLDDTSSSEGST",
     "pos": 1128,
     "wtScore": 4.76141,
     "wtProb": 8.7,
     "k198rScore": -17.1981,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1450
    },
    {
     "sequence": "LGYIRNPYNIFDGII",
     "pos": 856,
     "wtScore": -46.8852,
     "wtProb": 4.9,
     "k198rScore": -9.89922,
     "k198rProb": 6.8,
     "probDifference": 1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1451
    },
    {
     "sequence": "RILQFRLYKKKFKET",
     "pos": 529,
     "wtScore": -39.0212,
     "wtProb": 5.4,
     "k198rScore": -7.06723,
     "k198rProb": 7.3,
     "probDifference": 1.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1452
    },
    {
     "sequence": "NPKKRMRYFDPLRNE",
     "pos": 71,
     "wtScore": -35.9255,
     "wtProb": 5.7,
     "k198rScore": -5.46574,
     "k198rProb": 7.6,
     "probDifference": 1.9,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1453
    },
    {
     "sequence": "AGSSQGDTESPSHEK",
     "pos": 1787,
     "wtScore": 11.4654,
     "wtProb": 8.7,
     "k198rScore": -17.6178,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1454
    },
    {
     "sequence": "VQCPPSTSWQPQSHP",
     "pos": 716,
     "wtScore": 49.1211,
     "wtProb": 10.4,
     "k198rScore": 36.7152,
     "k198rProb": 8.5,
     "probDifference": -1.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1455
    },
    {
     "sequence": "GQLPPEGTTTETEVR",
     "pos": 674,
     "wtScore": 4.42239,
     "wtProb": 8.7,
     "k198rScore": -17.652,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1456
    },
    {
     "sequence": "VQATPPSSATTYVER",
     "pos": 736,
     "wtScore": 51.6274,
     "wtProb": 11.4,
     "k198rScore": 46.9868,
     "k198rProb": 13.3,
     "probDifference": 1.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1457
    },
    {
     "sequence": "IGSSVEKYVVDESDY",
     "pos": 1084,
     "wtScore": -37.5895,
     "wtProb": 5.6,
     "k198rScore": -6.05754,
     "k198rProb": 7.4,
     "probDifference": 1.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1458
    },
    {
     "sequence": "LKWVAYGYQTYFTNA",
     "pos": 1275,
     "wtScore": -43.307,
     "wtProb": 5,
     "k198rScore": -9.16363,
     "k198rProb": 6.9,
     "probDifference": 1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1459
    },
    {
     "sequence": "YAFLIIFTVETFLKI",
     "pos": 172,
     "wtScore": -12.3607,
     "wtProb": 7.2,
     "k198rScore": -29.017,
     "k198rProb": 5.3,
     "probDifference": -1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1460
    },
    {
     "sequence": "FLIVVNMYIAIILEN",
     "pos": 1760,
     "wtScore": -41.9921,
     "wtProb": 5.1,
     "k198rScore": -8.73243,
     "k198rProb": 6.9,
     "probDifference": 1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1461
    },
    {
     "sequence": "HGVVSVLTKGNKEMK",
     "pos": 592,
     "wtScore": -9.51779,
     "wtProb": 7.7,
     "k198rScore": -25.5319,
     "k198rProb": 5.8,
     "probDifference": -1.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 1462
    },
    {
     "sequence": "LVEELEESQRKCPPC",
     "pos": 714,
     "wtScore": 58.7062,
     "wtProb": 15.9,
     "k198rScore": 48.1366,
     "k198rProb": 14,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1463
    },
    {
     "sequence": "QGLVGKPSQRNALSL",
     "pos": 1691,
     "wtScore": 47.3306,
     "wtProb": 9.9,
     "k198rScore": 33.7862,
     "k198rProb": 8,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 1464
    },
    {
     "sequence": "YYPTKGTSSPAEAEK",
     "pos": 663,
     "wtScore": 48.2968,
     "wtProb": 10.1,
     "k198rScore": 35.6379,
     "k198rProb": 8.3,
     "probDifference": -1.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1465
    },
    {
     "sequence": "EGEEFDNTCCAEKRK",
     "pos": 165,
     "wtScore": -0.0808187,
     "wtProb": 8.6,
     "k198rScore": -18.5272,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1466
    },
    {
     "sequence": "EYTFTGIYTFESLIK",
     "pos": 164,
     "wtScore": -42.9656,
     "wtProb": 5,
     "k198rScore": -9.08379,
     "k198rProb": 6.9,
     "probDifference": 1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1467
    },
    {
     "sequence": "EYTFTGIYTFESLIK",
     "pos": 165,
     "wtScore": -42.9656,
     "wtProb": 5,
     "k198rScore": -9.08379,
     "k198rProb": 6.9,
     "probDifference": 1.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1468
    },
    {
     "sequence": "EYTFTGIYTFESLIK",
     "pos": 164,
     "wtScore": -42.9656,
     "wtProb": 5,
     "k198rScore": -9.08379,
     "k198rProb": 6.9,
     "probDifference": 1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1469
    },
    {
     "sequence": "YRSIDSHTEDKGPIY",
     "pos": 1148,
     "wtScore": 13.9261,
     "wtProb": 8.7,
     "k198rScore": -17.1669,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1470
    },
    {
     "sequence": "DISYRTFTPASLTVP",
     "pos": 2036,
     "wtScore": -13.2997,
     "wtProb": 7,
     "k198rScore": -30.2301,
     "k198rProb": 5.2,
     "probDifference": -1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1471
    },
    {
     "sequence": "LLPVLGMYHDPLRNR",
     "pos": 690,
     "wtScore": -35.3195,
     "wtProb": 5.8,
     "k198rScore": -5.18461,
     "k198rProb": 7.7,
     "probDifference": 1.9,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 1472
    },
    {
     "sequence": "NKIDSCMSNNTGIEI",
     "pos": 1049,
     "wtScore": 63.5399,
     "wtProb": 19.2,
     "k198rScore": 51.8781,
     "k198rProb": 17.3,
     "probDifference": -1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1473
    },
    {
     "sequence": "FRNTILGYFDYAFTA",
     "pos": 920,
     "wtScore": -35.2276,
     "wtProb": 5.8,
     "k198rScore": -5.14552,
     "k198rProb": 7.7,
     "probDifference": 1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1474
    },
    {
     "sequence": "CSDSSKQTEAECKGN",
     "pos": 1084,
     "wtScore": 16.9371,
     "wtProb": 8.7,
     "k198rScore": -18.2091,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1475
    },
    {
     "sequence": "DDKDFTGTVHRIDNT",
     "pos": 200,
     "wtScore": -8.09246,
     "wtProb": 8,
     "k198rScore": -23.9454,
     "k198rProb": 6.1,
     "probDifference": -1.9,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1476
    },
    {
     "sequence": "KKESTPSTASLPSYD",
     "pos": 1940,
     "wtScore": -11.2781,
     "wtProb": 7.4,
     "k198rScore": -27.514,
     "k198rProb": 5.5,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1477
    },
    {
     "sequence": "EFLRQEDTPGCRPPE",
     "pos": 770,
     "wtScore": -1.29909,
     "wtProb": 8.6,
     "k198rScore": -15.5444,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1478
    },
    {
     "sequence": "EDWNAVMYDGIMAYG",
     "pos": 711,
     "wtScore": -24.1229,
     "wtProb": 5.9,
     "k198rScore": -4.44891,
     "k198rProb": 7.8,
     "probDifference": 1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1479
    },
    {
     "sequence": "EYTFTGIYTFESLVK",
     "pos": 168,
     "wtScore": -43.119,
     "wtProb": 5,
     "k198rScore": -9.3608,
     "k198rProb": 6.8,
     "probDifference": 1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1480
    },
    {
     "sequence": "VKQASFTYNKNKIKG",
     "pos": 1941,
     "wtScore": -38.7953,
     "wtProb": 5.4,
     "k198rScore": -6.95244,
     "k198rProb": 7.3,
     "probDifference": 1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1481
    },
    {
     "sequence": "EGSVGSSSPQDTGHN",
     "pos": 854,
     "wtScore": 65.3192,
     "wtProb": 20.3,
     "k198rScore": 53.409,
     "k198rProb": 18.4,
     "probDifference": -1.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1482
    },
    {
     "sequence": "EVTVGKFYATFLIQE",
     "pos": 1666,
     "wtScore": -45.2188,
     "wtProb": 4.9,
     "k198rScore": -9.90296,
     "k198rProb": 6.8,
     "probDifference": 1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1483
    },
    {
     "sequence": "DKLNGNSTPEKTDGS",
     "pos": 1953,
     "wtScore": -0.644123,
     "wtProb": 8.6,
     "k198rScore": -15.7674,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1484
    },
    {
     "sequence": "NYFYHRETEGEEQAQ",
     "pos": 420,
     "wtScore": 19.7317,
     "wtProb": 8.6,
     "k198rScore": -14.6448,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1485
    },
    {
     "sequence": "NYFYHRETEGEEQAQ",
     "pos": 418,
     "wtScore": 19.7317,
     "wtProb": 8.6,
     "k198rScore": -14.6448,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1486
    },
    {
     "sequence": "KMFALRHYYFTIGWN",
     "pos": 1578,
     "wtScore": -42.0493,
     "wtProb": 5,
     "k198rScore": -8.81914,
     "k198rProb": 6.9,
     "probDifference": 1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1487
    },
    {
     "sequence": "KAAQTMSTSAPPPVG",
     "pos": 70,
     "wtScore": -13.3745,
     "wtProb": 7,
     "k198rScore": -30.3034,
     "k198rProb": 5.1,
     "probDifference": -1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1488
    },
    {
     "sequence": "FADDEHSTVEESEGR",
     "pos": 589,
     "wtScore": -1.55625,
     "wtProb": 8.6,
     "k198rScore": -15.4978,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1489
    },
    {
     "sequence": "VTVFQILTQEDWNVV",
     "pos": 971,
     "wtScore": 1.90533,
     "wtProb": 8.7,
     "k198rScore": -17.4555,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1490
    },
    {
     "sequence": "TPESRASSSGAIVPL",
     "pos": 2295,
     "wtScore": 54.831,
     "wtProb": 13.3,
     "k198rScore": 44.0561,
     "k198rProb": 11.4,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1491
    },
    {
     "sequence": "DRHPGAFTSILNFYR",
     "pos": 92,
     "wtScore": 0.152475,
     "wtProb": 8.7,
     "k198rScore": -16.4674,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1492
    },
    {
     "sequence": "DRHPGAFTSILNFYR",
     "pos": 96,
     "wtScore": 0.152475,
     "wtProb": 8.7,
     "k198rScore": -16.4674,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1493
    },
    {
     "sequence": "DLHSTWQYYERTVTV",
     "pos": 361,
     "wtScore": -42.9616,
     "wtProb": 5,
     "k198rScore": -9.45517,
     "k198rProb": 6.9,
     "probDifference": 1.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1494
    },
    {
     "sequence": "CLFQITTSAGWDGLL",
     "pos": 1712,
     "wtScore": 57.7618,
     "wtProb": 15.2,
     "k198rScore": 47.2084,
     "k198rProb": 13.4,
     "probDifference": -1.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1495
    },
    {
     "sequence": "CLFQITTSAGWDGLL",
     "pos": 1707,
     "wtScore": 57.7618,
     "wtProb": 15.2,
     "k198rScore": 47.2084,
     "k198rProb": 13.4,
     "probDifference": -1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1496
    },
    {
     "sequence": "CLFQITTSAGWDGLL",
     "pos": 1722,
     "wtScore": 57.7618,
     "wtProb": 15.2,
     "k198rScore": 47.2084,
     "k198rProb": 13.4,
     "probDifference": -1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1497
    },
    {
     "sequence": "CLFQITTSAGWDGLL",
     "pos": 1703,
     "wtScore": 57.7618,
     "wtProb": 15.2,
     "k198rScore": 47.2084,
     "k198rProb": 13.4,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1498
    },
    {
     "sequence": "SQGNVFATSALRSLR",
     "pos": 193,
     "wtScore": -12.8526,
     "wtProb": 7.1,
     "k198rScore": -29.5388,
     "k198rProb": 5.2,
     "probDifference": -1.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1499
    },
    {
     "sequence": "FKETLRPYDVKDVIE",
     "pos": 540,
     "wtScore": -20.3701,
     "wtProb": 6.1,
     "k198rScore": 0.0779208,
     "k198rProb": 8,
     "probDifference": 1.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1500
    },
    {
     "sequence": "VRFFACPSKTDFFKN",
     "pos": 244,
     "wtScore": 58.6567,
     "wtProb": 15.8,
     "k198rScore": 48.0802,
     "k198rProb": 14,
     "probDifference": -1.9,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1501
    },
    {
     "sequence": "EEKIELKSITADGES",
     "pos": 807,
     "wtScore": 53.4711,
     "wtProb": 12.5,
     "k198rScore": 42.4645,
     "k198rProb": 10.6,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1502
    },
    {
     "sequence": "LCLAMQHYEQSKMFN",
     "pos": 1226,
     "wtScore": -44.7474,
     "wtProb": 4.9,
     "k198rScore": -9.93283,
     "k198rProb": 6.8,
     "probDifference": 1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1503
    },
    {
     "sequence": "TRIDMIFTPGPPSTP",
     "pos": 572,
     "wtScore": -9.35236,
     "wtProb": 7.7,
     "k198rScore": -25.2375,
     "k198rProb": 5.9,
     "probDifference": -1.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1504
    },
    {
     "sequence": "EFADDEHSTVEESEG",
     "pos": 588,
     "wtScore": 110.085,
     "wtProb": 35,
     "k198rScore": 77.2564,
     "k198rProb": 33.2,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1505
    },
    {
     "sequence": "PSKTGGSTAPEVGWR",
     "pos": 619,
     "wtScore": 7.25246,
     "wtProb": 8.7,
     "k198rScore": -9.10937,
     "k198rProb": 6.9,
     "probDifference": -1.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1506
    },
    {
     "sequence": "PTWSRQNYGYYSRYP",
     "pos": 1864,
     "wtScore": -46.3589,
     "wtProb": 4.9,
     "k198rScore": -10.149,
     "k198rProb": 6.7,
     "probDifference": 1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1507
    },
    {
     "sequence": "SITRDSDTPLSLMSV",
     "pos": 787,
     "wtScore": -5.76512,
     "wtProb": 8.2,
     "k198rScore": -22.0281,
     "k198rProb": 6.4,
     "probDifference": -1.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1508
    },
    {
     "sequence": "RKRRSSSYHVSMDLL",
     "pos": 687,
     "wtScore": -40.7854,
     "wtProb": 5.2,
     "k198rScore": -8.16623,
     "k198rProb": 7,
     "probDifference": 1.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1509
    },
    {
     "sequence": "LPSYDSVTKPEKEKQ",
     "pos": 1950,
     "wtScore": -0.584703,
     "wtProb": 8.6,
     "k198rScore": -15.7032,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1510
    },
    {
     "sequence": "GENDHFDTYADALWW",
     "pos": 262,
     "wtScore": -2.28099,
     "wtProb": 8.6,
     "k198rScore": -19.1119,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1511
    },
    {
     "sequence": "QHQYKVWYVVNSTYF",
     "pos": 1233,
     "wtScore": -42.9341,
     "wtProb": 5,
     "k198rScore": -9.35275,
     "k198rProb": 6.8,
     "probDifference": 1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1512
    },
    {
     "sequence": "MSIASILTNTVEELE",
     "pos": 720,
     "wtScore": -6.7073,
     "wtProb": 8.2,
     "k198rScore": -22.6923,
     "k198rProb": 6.3,
     "probDifference": -1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1513
    },
    {
     "sequence": "EYADKVFTYIFILEM",
     "pos": 1247,
     "wtScore": -13.0431,
     "wtProb": 7.1,
     "k198rScore": -29.7028,
     "k198rProb": 5.2,
     "probDifference": -1.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1514
    },
    {
     "sequence": "EYADKVFTYIFILEM",
     "pos": 1259,
     "wtScore": -13.0431,
     "wtProb": 7.1,
     "k198rScore": -29.7028,
     "k198rProb": 5.2,
     "probDifference": -1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1515
    },
    {
     "sequence": "EYADKVFTYIFILEM",
     "pos": 1249,
     "wtScore": -13.0431,
     "wtProb": 7.1,
     "k198rScore": -29.7028,
     "k198rProb": 5.2,
     "probDifference": -1.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1516
    },
    {
     "sequence": "EYADKVFTYIFILEM",
     "pos": 1239,
     "wtScore": -13.0431,
     "wtProb": 7.1,
     "k198rScore": -29.7028,
     "k198rProb": 5.2,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1517
    },
    {
     "sequence": "MMRRAFETVAIDRLD",
     "pos": 653,
     "wtScore": -9.88996,
     "wtProb": 7.6,
     "k198rScore": -25.7116,
     "k198rProb": 5.8,
     "probDifference": -1.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 1518
    },
    {
     "sequence": "MMRRAFETVAIDRLD",
     "pos": 584,
     "wtScore": -9.88996,
     "wtProb": 7.6,
     "k198rScore": -25.7116,
     "k198rProb": 5.8,
     "probDifference": -1.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 1519
    },
    {
     "sequence": "KLISLRHYYFTIGWN",
     "pos": 1597,
     "wtScore": -42.8769,
     "wtProb": 5,
     "k198rScore": -9.39475,
     "k198rProb": 6.8,
     "probDifference": 1.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1520
    },
    {
     "sequence": "RNRCFLDSAFVRNNN",
     "pos": 263,
     "wtScore": 61.1224,
     "wtProb": 17.6,
     "k198rScore": 54.8288,
     "k198rProb": 19.4,
     "probDifference": 1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1521
    },
    {
     "sequence": "TEDKGPIYNYRVEIS",
     "pos": 1155,
     "wtScore": -37.7542,
     "wtProb": 5.5,
     "k198rScore": -6.24197,
     "k198rProb": 7.4,
     "probDifference": 1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1522
    },
    {
     "sequence": "SPKFSTGTRSEKNHF",
     "pos": 789,
     "wtScore": 0.530912,
     "wtProb": 8.7,
     "k198rScore": -16.8307,
     "k198rProb": 6.8,
     "probDifference": -1.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1523
    },
    {
     "sequence": "YGFQVYFTNAWCWLD",
     "pos": 1270,
     "wtScore": -7.51136,
     "wtProb": 8.1,
     "k198rScore": -23.3158,
     "k198rProb": 6.2,
     "probDifference": -1.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1524
    },
    {
     "sequence": "RPAGYPSTVSTVEGH",
     "pos": 1834,
     "wtScore": -14.9221,
     "wtProb": 6.7,
     "k198rScore": -36.0904,
     "k198rProb": 4.9,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1525
    },
    {
     "sequence": "VDSTFTPSSYSSTGS",
     "pos": 1803,
     "wtScore": 47.1383,
     "wtProb": 9.8,
     "k198rScore": 33.6258,
     "k198rProb": 8,
     "probDifference": -1.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1526
    },
    {
     "sequence": "CRGLFILYKDGDVDS",
     "pos": 1061,
     "wtScore": -37.7812,
     "wtProb": 5.5,
     "k198rScore": -6.25204,
     "k198rProb": 7.4,
     "probDifference": 1.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1527
    },
    {
     "sequence": "SPPAGTLTCELKSCP",
     "pos": 693,
     "wtScore": -2.37536,
     "wtProb": 8.6,
     "k198rScore": -18.7901,
     "k198rProb": 6.7,
     "probDifference": -1.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1528
    },
    {
     "sequence": "KEQERVKTAGFWIIH",
     "pos": 128,
     "wtScore": -5.75108,
     "wtProb": 8.3,
     "k198rScore": -21.9567,
     "k198rProb": 6.4,
     "probDifference": -1.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 1529
    },
    {
     "sequence": "EQRKTIRTILEYADK",
     "pos": 1229,
     "wtScore": -6.03194,
     "wtProb": 8.2,
     "k198rScore": -22.0483,
     "k198rProb": 6.4,
     "probDifference": -1.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1530
    },
    {
     "sequence": "HHHHHHHYHFSHGSP",
     "pos": 528,
     "wtScore": -41.901,
     "wtProb": 5.1,
     "k198rScore": -8.84667,
     "k198rProb": 6.9,
     "probDifference": 1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1531
    },
    {
     "sequence": "GKPKKQGSTTATRPP",
     "pos": 93,
     "wtScore": 49.4146,
     "wtProb": 10.5,
     "k198rScore": 37.3417,
     "k198rProb": 8.6,
     "probDifference": -1.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1532
    },
    {
     "sequence": "AMATSAGTVSEDAIE",
     "pos": 456,
     "wtScore": 2.57073,
     "wtProb": 8.7,
     "k198rScore": -17.6086,
     "k198rProb": 6.8,
     "probDifference": -1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1533
    },
    {
     "sequence": "DDQSEYVTTILSRIN",
     "pos": 1569,
     "wtScore": -7.16205,
     "wtProb": 8.1,
     "k198rScore": -22.9936,
     "k198rProb": 6.2,
     "probDifference": -1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1534
    },
    {
     "sequence": "SSVKRTRYYETYIRS",
     "pos": 1805,
     "wtScore": -45.5107,
     "wtProb": 4.9,
     "k198rScore": -10.2077,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1535
    },
    {
     "sequence": "KARPLRRYIPKNPYQ",
     "pos": 1187,
     "wtScore": -42.7183,
     "wtProb": 5,
     "k198rScore": -9.25304,
     "k198rProb": 6.8,
     "probDifference": 1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1536
    },
    {
     "sequence": "STFEDNESRRDSLFV",
     "pos": 602,
     "wtScore": 59.4381,
     "wtProb": 16.5,
     "k198rScore": 48.8732,
     "k198rProb": 14.6,
     "probDifference": -1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1537
    },
    {
     "sequence": "PCAKVRPYIAEGESD",
     "pos": 832,
     "wtScore": -37.1372,
     "wtProb": 5.6,
     "k198rScore": -6.04912,
     "k198rProb": 7.4,
     "probDifference": 1.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1538
    },
    {
     "sequence": "NCVALAIYIPFPEDD",
     "pos": 143,
     "wtScore": -42.0086,
     "wtProb": 5.1,
     "k198rScore": -9.00972,
     "k198rProb": 6.9,
     "probDifference": 1.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1539
    },
    {
     "sequence": "NCVALAIYIPFPEDD",
     "pos": 145,
     "wtScore": -42.0086,
     "wtProb": 5.1,
     "k198rScore": -9.00972,
     "k198rProb": 6.9,
     "probDifference": 1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1540
    },
    {
     "sequence": "YLFLIIFTVEAFLKV",
     "pos": 170,
     "wtScore": -7.53023,
     "wtProb": 8.1,
     "k198rScore": -23.2438,
     "k198rProb": 6.2,
     "probDifference": -1.8,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 1541
    },
    {
     "sequence": "PRSPRPASVRTRKHT",
     "pos": 2065,
     "wtScore": 52.9965,
     "wtProb": 12.1,
     "k198rScore": 41.9506,
     "k198rProb": 10.3,
     "probDifference": -1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1542
    },
    {
     "sequence": "ALSEADPTESENVPV",
     "pos": 1291,
     "wtScore": 21.965,
     "wtProb": 8.5,
     "k198rScore": -10.7009,
     "k198rProb": 6.7,
     "probDifference": -1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1543
    },
    {
     "sequence": "AVLSPESSIYTTASA",
     "pos": 723,
     "wtScore": 47.0403,
     "wtProb": 9.8,
     "k198rScore": 33.5167,
     "k198rProb": 7.9,
     "probDifference": -1.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1544
    },
    {
     "sequence": "FNFDNVLSAMMALFT",
     "pos": 1088,
     "wtScore": 58.0656,
     "wtProb": 15.4,
     "k198rScore": 47.6181,
     "k198rProb": 13.6,
     "probDifference": -1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1545
    },
    {
     "sequence": "GDPFLDGSHSVTPES",
     "pos": 2284,
     "wtScore": 52.8324,
     "wtProb": 12,
     "k198rScore": 41.791,
     "k198rProb": 10.2,
     "probDifference": -1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1546
    },
    {
     "sequence": "EQRKTIKTMLEYADK",
     "pos": 1237,
     "wtScore": -5.5199,
     "wtProb": 8.3,
     "k198rScore": -21.641,
     "k198rProb": 6.4,
     "probDifference": -1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1547
    },
    {
     "sequence": "EQRKTIKTMLEYADK",
     "pos": 1239,
     "wtScore": -5.5199,
     "wtProb": 8.3,
     "k198rScore": -21.641,
     "k198rProb": 6.4,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1548
    },
    {
     "sequence": "TFSLIGVSFFALPAG",
     "pos": 341,
     "wtScore": 50.9215,
     "wtProb": 11.1,
     "k198rScore": 39.4598,
     "k198rProb": 9.3,
     "probDifference": -1.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1549
    },
    {
     "sequence": "LNRKTSRSTLSLPPE",
     "pos": 15,
     "wtScore": 46.1227,
     "wtProb": 9.5,
     "k198rScore": 31.1689,
     "k198rProb": 7.7,
     "probDifference": -1.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1550
    },
    {
     "sequence": "KYKQVEQYMSFHKLP",
     "pos": 485,
     "wtScore": -34.1497,
     "wtProb": 5.8,
     "k198rScore": -5.17991,
     "k198rProb": 7.7,
     "probDifference": 1.8,
     "geneName": "HCN2",
     "known": "No",
     "id": 1551
    },
    {
     "sequence": "KYKQVEQYMSFHKLP",
     "pos": 416,
     "wtScore": -34.1497,
     "wtProb": 5.8,
     "k198rScore": -5.17991,
     "k198rProb": 7.7,
     "probDifference": 1.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 1552
    },
    {
     "sequence": "ERSFSGFSISQSKEN",
     "pos": 805,
     "wtScore": 51.1598,
     "wtProb": 11.2,
     "k198rScore": 39.661,
     "k198rProb": 9.3,
     "probDifference": -1.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1553
    },
    {
     "sequence": "MVFTGVFTVEMVLKV",
     "pos": 1248,
     "wtScore": -0.45002,
     "wtProb": 8.6,
     "k198rScore": -18.1907,
     "k198rProb": 6.8,
     "probDifference": -1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1554
    },
    {
     "sequence": "RRIQTLIYDALERPR",
     "pos": 111,
     "wtScore": -19.5806,
     "wtProb": 6.2,
     "k198rScore": 3.46333,
     "k198rProb": 8,
     "probDifference": 1.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1555
    },
    {
     "sequence": "RLLPEATTEVEIKKK",
     "pos": 668,
     "wtScore": 17.6189,
     "wtProb": 8.6,
     "k198rScore": -17.8313,
     "k198rProb": 6.8,
     "probDifference": -1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1556
    },
    {
     "sequence": "ITFFKDETTAPWIVF",
     "pos": 239,
     "wtScore": -11.7029,
     "wtProb": 7.3,
     "k198rScore": -27.7725,
     "k198rProb": 5.5,
     "probDifference": -1.8,
     "geneName": "HCN2",
     "known": "No",
     "id": 1557
    },
    {
     "sequence": "LVVETLISSLRPIGN",
     "pos": 1424,
     "wtScore": 46.3724,
     "wtProb": 9.6,
     "k198rScore": 31.6018,
     "k198rProb": 7.8,
     "probDifference": -1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1558
    },
    {
     "sequence": "GIFFFVSYIIISFLI",
     "pos": 1748,
     "wtScore": -45.0638,
     "wtProb": 4.9,
     "k198rScore": -15.2563,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1559
    },
    {
     "sequence": "QAAAMATSAGTVSED",
     "pos": 453,
     "wtScore": 62.8447,
     "wtProb": 18.6,
     "k198rScore": 57.5372,
     "k198rProb": 20.5,
     "probDifference": 1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1560
    },
    {
     "sequence": "LVSLRHYYFTIGWNI",
     "pos": 1583,
     "wtScore": -46.9619,
     "wtProb": 4.9,
     "k198rScore": -10.5201,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1561
    },
    {
     "sequence": "GNLRDSDTSISIPSV",
     "pos": 785,
     "wtScore": -0.261129,
     "wtProb": 8.6,
     "k198rScore": -17.2978,
     "k198rProb": 6.8,
     "probDifference": -1.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1562
    },
    {
     "sequence": "FVSLGNVSALRTFRV",
     "pos": 212,
     "wtScore": 58.985,
     "wtProb": 16.1,
     "k198rScore": 52.7366,
     "k198rProb": 18,
     "probDifference": 1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1563
    },
    {
     "sequence": "LISLRYYYFTIGWNI",
     "pos": 1588,
     "wtScore": -46.1854,
     "wtProb": 4.9,
     "k198rScore": -10.6354,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1564
    },
    {
     "sequence": "CTLGWEAYTQPQAEG",
     "pos": 321,
     "wtScore": -38.986,
     "wtProb": 5.4,
     "k198rScore": -7.39855,
     "k198rProb": 7.2,
     "probDifference": 1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1565
    },
    {
     "sequence": "DIMVLIASIAVLAAG",
     "pos": 178,
     "wtScore": 45.8508,
     "wtProb": 9.5,
     "k198rScore": 30.2827,
     "k198rProb": 7.7,
     "probDifference": -1.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1566
    },
    {
     "sequence": "AAATAAASLRLATGL",
     "pos": 600,
     "wtScore": 40.1266,
     "wtProb": 8.7,
     "k198rScore": 42.2779,
     "k198rProb": 10.5,
     "probDifference": 1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1567
    },
    {
     "sequence": "QAQYLQVTSCPKIPS",
     "pos": 432,
     "wtScore": -11.4059,
     "wtProb": 7.3,
     "k198rScore": -27.3744,
     "k198rProb": 5.5,
     "probDifference": -1.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1568
    },
    {
     "sequence": "DMLSRIKYLQTRIDM",
     "pos": 562,
     "wtScore": -48.2594,
     "wtProb": 4.9,
     "k198rScore": -14.36,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1569
    },
    {
     "sequence": "MYDGIMAYGGPSSSG",
     "pos": 717,
     "wtScore": -38.6758,
     "wtProb": 5.4,
     "k198rScore": -7.15677,
     "k198rProb": 7.2,
     "probDifference": 1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1570
    },
    {
     "sequence": "SNPSKVSYEPITTTL",
     "pos": 1892,
     "wtScore": -38.7623,
     "wtProb": 5.4,
     "k198rScore": -7.20357,
     "k198rProb": 7.2,
     "probDifference": 1.8,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 1571
    },
    {
     "sequence": "SNPSKVSYEPITTTL",
     "pos": 1882,
     "wtScore": -38.7623,
     "wtProb": 5.4,
     "k198rScore": -7.20357,
     "k198rProb": 7.2,
     "probDifference": 1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1572
    },
    {
     "sequence": "SNPSKVSYEPITTTL",
     "pos": 1887,
     "wtScore": -38.7623,
     "wtProb": 5.4,
     "k198rScore": -7.20357,
     "k198rProb": 7.2,
     "probDifference": 1.8,
     "geneName": "SCN3A",
     "known": "Yes",
     "id": 1573
    },
    {
     "sequence": "IPKNPYQYKFWYVVN",
     "pos": 1195,
     "wtScore": -37.4784,
     "wtProb": 5.6,
     "k198rScore": -6.26888,
     "k198rProb": 7.4,
     "probDifference": 1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1574
    },
    {
     "sequence": "AFLKVIAYGLLFHPN",
     "pos": 180,
     "wtScore": -41.6736,
     "wtProb": 5.1,
     "k198rScore": -8.92275,
     "k198rProb": 6.9,
     "probDifference": 1.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1575
    },
    {
     "sequence": "VFYFISFYMLCAFLI",
     "pos": 1507,
     "wtScore": -42.892,
     "wtProb": 5,
     "k198rScore": -17.3249,
     "k198rProb": 6.8,
     "probDifference": 1.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1576
    },
    {
     "sequence": "RMHKGVAYVKRKIYE",
     "pos": 1024,
     "wtScore": -38.7832,
     "wtProb": 5.4,
     "k198rScore": -7.2472,
     "k198rProb": 7.2,
     "probDifference": 1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1577
    },
    {
     "sequence": "NLTFLRPYYQTEEGE",
     "pos": 277,
     "wtScore": -40.9322,
     "wtProb": 5.2,
     "k198rScore": -8.53361,
     "k198rProb": 7,
     "probDifference": 1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1578
    },
    {
     "sequence": "SESSSVASKLSSKSE",
     "pos": 490,
     "wtScore": 51.3726,
     "wtProb": 11.3,
     "k198rScore": 39.9621,
     "k198rProb": 9.4,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1579
    },
    {
     "sequence": "PGHPQDTYDPEADHE",
     "pos": 22,
     "wtScore": -20.6129,
     "wtProb": 6.1,
     "k198rScore": 8.1156,
     "k198rProb": 7.9,
     "probDifference": 1.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1580
    },
    {
     "sequence": "FTSILNFYRTGKLHM",
     "pos": 102,
     "wtScore": -45.1548,
     "wtProb": 4.9,
     "k198rScore": -10.6336,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1581
    },
    {
     "sequence": "PSFDAILYYYQSGGR",
     "pos": 89,
     "wtScore": -40.5907,
     "wtProb": 5.2,
     "k198rScore": -8.29974,
     "k198rProb": 7,
     "probDifference": 1.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1582
    },
    {
     "sequence": "PSFDAILYYYQSGGR",
     "pos": 93,
     "wtScore": -40.5907,
     "wtProb": 5.2,
     "k198rScore": -8.29974,
     "k198rProb": 7,
     "probDifference": 1.8,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1583
    },
    {
     "sequence": "TGIYTFESLVKIIAR",
     "pos": 172,
     "wtScore": 40.6069,
     "wtProb": 8.7,
     "k198rScore": 42.3416,
     "k198rProb": 10.5,
     "probDifference": 1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1584
    },
    {
     "sequence": "KNEVHKSTQALHNTN",
     "pos": 770,
     "wtScore": -12.8419,
     "wtProb": 7.1,
     "k198rScore": -29.0143,
     "k198rProb": 5.3,
     "probDifference": -1.8,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 1585
    },
    {
     "sequence": "AQQAATATASEHSRE",
     "pos": 464,
     "wtScore": -0.959143,
     "wtProb": 8.6,
     "k198rScore": -16.6051,
     "k198rProb": 6.8,
     "probDifference": -1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1586
    },
    {
     "sequence": "SFEINITSFFNNSLD",
     "pos": 293,
     "wtScore": 47.7014,
     "wtProb": 10,
     "k198rScore": 35.2149,
     "k198rProb": 8.2,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1587
    },
    {
     "sequence": "VKPLDELYEKKANCI",
     "pos": 1037,
     "wtScore": -35.1512,
     "wtProb": 5.8,
     "k198rScore": -5.56542,
     "k198rProb": 7.6,
     "probDifference": 1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1588
    },
    {
     "sequence": "VDSSPPKSLPGSTSP",
     "pos": 776,
     "wtScore": 37.4791,
     "wtProb": 8.5,
     "k198rScore": 41.9416,
     "k198rProb": 10.3,
     "probDifference": 1.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1589
    },
    {
     "sequence": "LACGPLGYIRNPYNI",
     "pos": 851,
     "wtScore": -40.0523,
     "wtProb": 5.3,
     "k198rScore": -8.06918,
     "k198rProb": 7.1,
     "probDifference": 1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1590
    },
    {
     "sequence": "TEIRKRRSSSYHVSM",
     "pos": 684,
     "wtScore": 45.6818,
     "wtProb": 9.5,
     "k198rScore": 29.9633,
     "k198rProb": 7.7,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1591
    },
    {
     "sequence": "SHEQLNNTSSSSPQH",
     "pos": 526,
     "wtScore": -7.52561,
     "wtProb": 8.1,
     "k198rScore": -22.8904,
     "k198rProb": 6.3,
     "probDifference": -1.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1592
    },
    {
     "sequence": "SMDQLASYGRKDRIN",
     "pos": 690,
     "wtScore": -37.9787,
     "wtProb": 5.5,
     "k198rScore": -6.69035,
     "k198rProb": 7.3,
     "probDifference": 1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1593
    },
    {
     "sequence": "KRTRYYETYIRSDSG",
     "pos": 1808,
     "wtScore": -1.2603,
     "wtProb": 8.6,
     "k198rScore": -17.8214,
     "k198rProb": 6.8,
     "probDifference": -1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1594
    },
    {
     "sequence": "AISKSKLSRRWRRWN",
     "pos": 501,
     "wtScore": 47.7983,
     "wtProb": 10,
     "k198rScore": 35.3798,
     "k198rProb": 8.2,
     "probDifference": -1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1595
    },
    {
     "sequence": "DLSRRSSSTMSKSEY",
     "pos": 446,
     "wtScore": 46.8192,
     "wtProb": 9.7,
     "k198rScore": 33.289,
     "k198rProb": 7.9,
     "probDifference": -1.8,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1596
    },
    {
     "sequence": "YGDMYPVTIGGKIVG",
     "pos": 381,
     "wtScore": -7.10298,
     "wtProb": 8.1,
     "k198rScore": -22.7084,
     "k198rProb": 6.3,
     "probDifference": -1.8,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1597
    },
    {
     "sequence": "RLTYEKRYSSPHQSL",
     "pos": 548,
     "wtScore": -37.6383,
     "wtProb": 5.6,
     "k198rScore": -6.4966,
     "k198rProb": 7.4,
     "probDifference": 1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1598
    },
    {
     "sequence": "TGLGTMNYPTILPSG",
     "pos": 612,
     "wtScore": -43.485,
     "wtProb": 5,
     "k198rScore": -15.7105,
     "k198rProb": 6.8,
     "probDifference": 1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1599
    },
    {
     "sequence": "LLRLLRLSRLIRYIH",
     "pos": 340,
     "wtScore": 46.7805,
     "wtProb": 9.7,
     "k198rScore": 33.2053,
     "k198rProb": 7.9,
     "probDifference": -1.8,
     "geneName": "HCN2",
     "known": "No",
     "id": 1600
    },
    {
     "sequence": "LLRLLRLSRLIRYIH",
     "pos": 271,
     "wtScore": 46.7805,
     "wtProb": 9.7,
     "k198rScore": 33.2053,
     "k198rProb": 7.9,
     "probDifference": -1.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 1601
    },
    {
     "sequence": "EHTQCSPSMNAEENS",
     "pos": 1361,
     "wtScore": 56.5427,
     "wtProb": 14.4,
     "k198rScore": 46.0036,
     "k198rProb": 12.6,
     "probDifference": -1.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1602
    },
    {
     "sequence": "YSNSTIGYQQSTSFT",
     "pos": 211,
     "wtScore": -44.8633,
     "wtProb": 4.9,
     "k198rScore": -10.5129,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1603
    },
    {
     "sequence": "GIFFFVSYIIISFLV",
     "pos": 1753,
     "wtScore": -45.1392,
     "wtProb": 4.9,
     "k198rScore": -14.5943,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1604
    },
    {
     "sequence": "GIFFFVSYIIISFLV",
     "pos": 1768,
     "wtScore": -45.1392,
     "wtProb": 4.9,
     "k198rScore": -14.5943,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1605
    },
    {
     "sequence": "GIFFFVSYIIISFLV",
     "pos": 1758,
     "wtScore": -45.1392,
     "wtProb": 4.9,
     "k198rScore": -14.5943,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1606
    },
    {
     "sequence": "CYEELLKYVGHIFRK",
     "pos": 462,
     "wtScore": -36.0936,
     "wtProb": 5.7,
     "k198rScore": -5.84405,
     "k198rProb": 7.5,
     "probDifference": 1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1607
    },
    {
     "sequence": "AIKILIHSVFSMIIM",
     "pos": 131,
     "wtScore": 49.1445,
     "wtProb": 10.4,
     "k198rScore": 37.1532,
     "k198rProb": 8.6,
     "probDifference": -1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1608
    },
    {
     "sequence": "LPVPVIVSNFNYFYH",
     "pos": 408,
     "wtScore": 54.82,
     "wtProb": 13.3,
     "k198rScore": 44.2327,
     "k198rProb": 11.5,
     "probDifference": -1.8,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1609
    },
    {
     "sequence": "LPVPVIVSNFNYFYH",
     "pos": 410,
     "wtScore": 54.82,
     "wtProb": 13.3,
     "k198rScore": 44.2327,
     "k198rProb": 11.5,
     "probDifference": -1.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1610
    },
    {
     "sequence": "EDDFETFYEIWEKFD",
     "pos": 1788,
     "wtScore": -34.785,
     "wtProb": 5.8,
     "k198rScore": -5.56557,
     "k198rProb": 7.6,
     "probDifference": 1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1611
    },
    {
     "sequence": "KGKECDGTPIKEDTL",
     "pos": 1942,
     "wtScore": -2.36471,
     "wtProb": 8.6,
     "k198rScore": -16.4834,
     "k198rProb": 6.8,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1612
    },
    {
     "sequence": "LIWDCSPYWLKVKHV",
     "pos": 752,
     "wtScore": -40.1255,
     "wtProb": 5.2,
     "k198rScore": -8.17103,
     "k198rProb": 7,
     "probDifference": 1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1613
    },
    {
     "sequence": "QVTEYYPTKGTSSPA",
     "pos": 659,
     "wtScore": -8.15581,
     "wtProb": 8,
     "k198rScore": -23.444,
     "k198rProb": 6.2,
     "probDifference": -1.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1614
    },
    {
     "sequence": "AIFRFSATSALYILT",
     "pos": 104,
     "wtScore": -15.3264,
     "wtProb": 6.7,
     "k198rScore": -36.3229,
     "k198rProb": 4.9,
     "probDifference": -1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1615
    },
    {
     "sequence": "AIFRFSATSALYILT",
     "pos": 104,
     "wtScore": -15.3264,
     "wtProb": 6.7,
     "k198rScore": -36.3229,
     "k198rProb": 4.9,
     "probDifference": -1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1616
    },
    {
     "sequence": "SCRAEHLTVPSFAFE",
     "pos": 2261,
     "wtScore": -10.3627,
     "wtProb": 7.6,
     "k198rScore": -25.8155,
     "k198rProb": 5.8,
     "probDifference": -1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1617
    },
    {
     "sequence": "VIFIIFGSFFTLNLF",
     "pos": 1460,
     "wtScore": 47.8781,
     "wtProb": 10,
     "k198rScore": 35.4934,
     "k198rProb": 8.2,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1618
    },
    {
     "sequence": "VIFIIFGSFFTLNLF",
     "pos": 1455,
     "wtScore": 47.8781,
     "wtProb": 10,
     "k198rScore": 35.4934,
     "k198rProb": 8.2,
     "probDifference": -1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1619
    },
    {
     "sequence": "VIFIIFGSFFTLNLF",
     "pos": 1451,
     "wtScore": 47.8781,
     "wtProb": 10,
     "k198rScore": 35.4934,
     "k198rProb": 8.2,
     "probDifference": -1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1620
    },
    {
     "sequence": "VIFIIFGSFFTLNLF",
     "pos": 1470,
     "wtScore": 47.8781,
     "wtProb": 10,
     "k198rScore": 35.4934,
     "k198rProb": 8.2,
     "probDifference": -1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1621
    },
    {
     "sequence": "MSLFVLSSKDGWVNI",
     "pos": 1501,
     "wtScore": 48.289,
     "wtProb": 10.1,
     "k198rScore": 36.2562,
     "k198rProb": 8.4,
     "probDifference": -1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1622
    },
    {
     "sequence": "GKLRDCNTHESLLEV",
     "pos": 68,
     "wtScore": -8.95134,
     "wtProb": 7.8,
     "k198rScore": -24.3779,
     "k198rProb": 6,
     "probDifference": -1.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1623
    },
    {
     "sequence": "LDFLVNIYMQRMGIP",
     "pos": 643,
     "wtScore": -44.3434,
     "wtProb": 4.9,
     "k198rScore": -10.6798,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1624
    },
    {
     "sequence": "QKLFPFSSRERRSFT",
     "pos": 797,
     "wtScore": 48.1971,
     "wtProb": 10.1,
     "k198rScore": 36.1576,
     "k198rProb": 8.3,
     "probDifference": -1.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1625
    },
    {
     "sequence": "NYGYYSRYPGRNIDS",
     "pos": 1870,
     "wtScore": -41.5634,
     "wtProb": 5.1,
     "k198rScore": -9.08005,
     "k198rProb": 6.9,
     "probDifference": 1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1626
    },
    {
     "sequence": "WGLITLATIGYGDKT",
     "pos": 315,
     "wtScore": -14.3919,
     "wtProb": 6.8,
     "k198rScore": -31.6348,
     "k198rProb": 5,
     "probDifference": -1.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1627
    },
    {
     "sequence": "LAARTFHYASPTASQ",
     "pos": 718,
     "wtScore": -41.7632,
     "wtProb": 5.1,
     "k198rScore": -9.23011,
     "k198rProb": 6.8,
     "probDifference": 1.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 1628
    },
    {
     "sequence": "IIQEIVKYDREMVQQ",
     "pos": 696,
     "wtScore": -21.6539,
     "wtProb": 6,
     "k198rScore": -4.30274,
     "k198rProb": 7.8,
     "probDifference": 1.8,
     "geneName": "HCN2",
     "known": "No",
     "id": 1629
    },
    {
     "sequence": "NLFAGKFYHCVNMTT",
     "pos": 1361,
     "wtScore": -41.254,
     "wtProb": 5.1,
     "k198rScore": -8.91886,
     "k198rProb": 6.9,
     "probDifference": 1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1630
    },
    {
     "sequence": "YHCINYTTGEMFDVS",
     "pos": 1370,
     "wtScore": -3.67264,
     "wtProb": 8.5,
     "k198rScore": -14.6027,
     "k198rProb": 6.7,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1631
    },
    {
     "sequence": "LFGNHVSYYQSDGRS",
     "pos": 1756,
     "wtScore": -46.6083,
     "wtProb": 4.9,
     "k198rScore": -12.8536,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1632
    },
    {
     "sequence": "FGMSNFAYVKKEAGI",
     "pos": 1678,
     "wtScore": -40.7502,
     "wtProb": 5.2,
     "k198rScore": -8.68979,
     "k198rProb": 6.9,
     "probDifference": 1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1633
    },
    {
     "sequence": "ERKKLARTASPEKKQ",
     "pos": 780,
     "wtScore": -2.29103,
     "wtProb": 8.6,
     "k198rScore": -16.5229,
     "k198rProb": 6.8,
     "probDifference": -1.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1634
    },
    {
     "sequence": "LVFIVLFTGECVLKL",
     "pos": 1574,
     "wtScore": -10.6567,
     "wtProb": 7.5,
     "k198rScore": -26.0962,
     "k198rProb": 5.7,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1635
    },
    {
     "sequence": "LVFIVLFTGECVLKL",
     "pos": 1584,
     "wtScore": -10.6567,
     "wtProb": 7.5,
     "k198rScore": -26.0962,
     "k198rProb": 5.7,
     "probDifference": -1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1636
    },
    {
     "sequence": "NDSWGKQYSYALFKA",
     "pos": 344,
     "wtScore": -41.6827,
     "wtProb": 5.1,
     "k198rScore": -9.43955,
     "k198rProb": 6.8,
     "probDifference": 1.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 1637
    },
    {
     "sequence": "NGTTFNRTVSIFNWD",
     "pos": 309,
     "wtScore": -13.2537,
     "wtProb": 7,
     "k198rScore": -29.1356,
     "k198rProb": 5.3,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1638
    },
    {
     "sequence": "AITDKDRTKGPAEAE",
     "pos": 604,
     "wtScore": -8.97176,
     "wtProb": 7.8,
     "k198rScore": -24.3308,
     "k198rProb": 6,
     "probDifference": -1.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1639
    },
    {
     "sequence": "LCGEWIETMWDCMEV",
     "pos": 954,
     "wtScore": -6.16699,
     "wtProb": 8.2,
     "k198rScore": -21.5987,
     "k198rProb": 6.4,
     "probDifference": -1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1640
    },
    {
     "sequence": "LCGEWIETMWDCMEV",
     "pos": 946,
     "wtScore": -6.16699,
     "wtProb": 8.2,
     "k198rScore": -21.5987,
     "k198rProb": 6.4,
     "probDifference": -1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1641
    },
    {
     "sequence": "LCGEWIETMWDCMEV",
     "pos": 945,
     "wtScore": -6.16699,
     "wtProb": 8.2,
     "k198rScore": -21.5987,
     "k198rProb": 6.4,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1642
    },
    {
     "sequence": "LCGEWIETMWDCMEV",
     "pos": 939,
     "wtScore": -6.16699,
     "wtProb": 8.2,
     "k198rScore": -21.5987,
     "k198rProb": 6.4,
     "probDifference": -1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1643
    },
    {
     "sequence": "DPGAPDSTRDGALLI",
     "pos": 34,
     "wtScore": -14.4102,
     "wtProb": 6.8,
     "k198rScore": -31.4929,
     "k198rProb": 5.1,
     "probDifference": -1.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1644
    },
    {
     "sequence": "SVPCPLPSPPAGTLT",
     "pos": 686,
     "wtScore": 49.4582,
     "wtProb": 10.5,
     "k198rScore": 37.6982,
     "k198rProb": 8.7,
     "probDifference": -1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1645
    },
    {
     "sequence": "DEDATKFTSIPASFW",
     "pos": 365,
     "wtScore": -14.5704,
     "wtProb": 6.8,
     "k198rScore": -31.9368,
     "k198rProb": 5,
     "probDifference": -1.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1646
    },
    {
     "sequence": "IAMDPYYYFQEGWNI",
     "pos": 826,
     "wtScore": -42.8567,
     "wtProb": 5,
     "k198rScore": -10.0875,
     "k198rProb": 6.8,
     "probDifference": 1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1647
    },
    {
     "sequence": "IAMDPYYYFQEGWNI",
     "pos": 817,
     "wtScore": -42.8567,
     "wtProb": 5,
     "k198rScore": -10.0875,
     "k198rProb": 6.8,
     "probDifference": 1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1648
    },
    {
     "sequence": "IAMDPYYYFQEGWNI",
     "pos": 818,
     "wtScore": -42.8567,
     "wtProb": 5,
     "k198rScore": -10.0875,
     "k198rProb": 6.8,
     "probDifference": 1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1649
    },
    {
     "sequence": "IAMDPYYYFQEGWNI",
     "pos": 811,
     "wtScore": -42.8567,
     "wtProb": 5,
     "k198rScore": -10.0875,
     "k198rProb": 6.8,
     "probDifference": 1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1650
    },
    {
     "sequence": "TSSWAALYFVALMTF",
     "pos": 993,
     "wtScore": -43.8066,
     "wtProb": 4.9,
     "k198rScore": -10.6304,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1651
    },
    {
     "sequence": "ETEGGNHSSGKSGGF",
     "pos": 226,
     "wtScore": 62.628,
     "wtProb": 18.5,
     "k198rScore": 51.2016,
     "k198rProb": 16.7,
     "probDifference": -1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1652
    },
    {
     "sequence": "ALMTFGNYVLFNLLV",
     "pos": 1003,
     "wtScore": -45.9055,
     "wtProb": 4.9,
     "k198rScore": -12.6886,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1653
    },
    {
     "sequence": "EDWNVVLYNGMASTS",
     "pos": 980,
     "wtScore": -38.8763,
     "wtProb": 5.4,
     "k198rScore": -7.57598,
     "k198rProb": 7.2,
     "probDifference": 1.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1654
    },
    {
     "sequence": "LHSTWQYYERTVTVP",
     "pos": 362,
     "wtScore": -45.5478,
     "wtProb": 4.9,
     "k198rScore": -13.3429,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1655
    },
    {
     "sequence": "FEFDWKSYIQDSRYH",
     "pos": 316,
     "wtScore": -40.7876,
     "wtProb": 5.2,
     "k198rScore": -8.75708,
     "k198rProb": 6.9,
     "probDifference": 1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1656
    },
    {
     "sequence": "VLVIFVGSFYLVNLI",
     "pos": 398,
     "wtScore": 48.7455,
     "wtProb": 10.3,
     "k198rScore": 36.875,
     "k198rProb": 8.5,
     "probDifference": -1.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1657
    },
    {
     "sequence": "TFHTYSNSTIGYQQS",
     "pos": 207,
     "wtScore": 45.7206,
     "wtProb": 9.5,
     "k198rScore": 30.7985,
     "k198rProb": 7.7,
     "probDifference": -1.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1658
    },
    {
     "sequence": "LKLISLRYYYFTIGW",
     "pos": 1586,
     "wtScore": -46.731,
     "wtProb": 4.9,
     "k198rScore": -12.4096,
     "k198rProb": 6.6,
     "probDifference": 1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1659
    },
    {
     "sequence": "SPVGDHGSLVRIPPP",
     "pos": 737,
     "wtScore": 35.3019,
     "wtProb": 8.4,
     "k198rScore": 41.6771,
     "k198rProb": 10.2,
     "probDifference": 1.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1660
    },
    {
     "sequence": "SNPRGSNTKGKLFTP",
     "pos": 439,
     "wtScore": -3.2074,
     "wtProb": 8.5,
     "k198rScore": -18.6409,
     "k198rProb": 6.7,
     "probDifference": -1.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1661
    },
    {
     "sequence": "GFTLRRSYNELGLLI",
     "pos": 331,
     "wtScore": -46.332,
     "wtProb": 4.9,
     "k198rScore": -12.4003,
     "k198rProb": 6.6,
     "probDifference": 1.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1662
    },
    {
     "sequence": "GFTLRRSYNELGLLI",
     "pos": 327,
     "wtScore": -46.332,
     "wtProb": 4.9,
     "k198rScore": -12.4003,
     "k198rProb": 6.6,
     "probDifference": 1.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1663
    },
    {
     "sequence": "RCRAAVKSVTFYWLV",
     "pos": 521,
     "wtScore": 53.4852,
     "wtProb": 12.5,
     "k198rScore": 42.678,
     "k198rProb": 10.7,
     "probDifference": -1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1664
    },
    {
     "sequence": "NKKDSCMSNHTAEIG",
     "pos": 1062,
     "wtScore": 59.6166,
     "wtProb": 16.6,
     "k198rScore": 49.1827,
     "k198rProb": 14.8,
     "probDifference": -1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1665
    },
    {
     "sequence": "YSSQTQTYGASRLIP",
     "pos": 378,
     "wtScore": -45.1915,
     "wtProb": 4.9,
     "k198rScore": -11.1454,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1666
    },
    {
     "sequence": "SMDGNRLTSDKKFCS",
     "pos": 546,
     "wtScore": -11.0766,
     "wtProb": 7.4,
     "k198rScore": -26.5087,
     "k198rProb": 5.7,
     "probDifference": -1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1667
    },
    {
     "sequence": "LFLVMFIYAIFGMSN",
     "pos": 1668,
     "wtScore": -48.1803,
     "wtProb": 4.9,
     "k198rScore": -12.0409,
     "k198rProb": 6.6,
     "probDifference": 1.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1668
    },
    {
     "sequence": "LFLVMFIYAIFGMSN",
     "pos": 1683,
     "wtScore": -48.1803,
     "wtProb": 4.9,
     "k198rScore": -12.0409,
     "k198rProb": 6.6,
     "probDifference": 1.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1669
    },
    {
     "sequence": "LFLVMFIYAIFGMSN",
     "pos": 1673,
     "wtScore": -48.1803,
     "wtProb": 4.9,
     "k198rScore": -12.0409,
     "k198rProb": 6.6,
     "probDifference": 1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1670
    },
    {
     "sequence": "TVGESLASIPQPVTA",
     "pos": 813,
     "wtScore": 51.239,
     "wtProb": 11.2,
     "k198rScore": 39.9573,
     "k198rProb": 9.4,
     "probDifference": -1.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 1671
    },
    {
     "sequence": "LVFLNTLTIASEHYN",
     "pos": 539,
     "wtScore": -9.92082,
     "wtProb": 7.6,
     "k198rScore": -25.1544,
     "k198rProb": 5.9,
     "probDifference": -1.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1672
    },
    {
     "sequence": "ALHNTNLTREVRPLS",
     "pos": 779,
     "wtScore": -8.46994,
     "wtProb": 7.9,
     "k198rScore": -23.7318,
     "k198rProb": 6.1,
     "probDifference": -1.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 1673
    },
    {
     "sequence": "KLISLRYYYFTIGWN",
     "pos": 1587,
     "wtScore": -43.9528,
     "wtProb": 4.9,
     "k198rScore": -10.8068,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1674
    },
    {
     "sequence": "IVYFISFYMLCAFLI",
     "pos": 1447,
     "wtScore": -42.8777,
     "wtProb": 5,
     "k198rScore": -15.8656,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1675
    },
    {
     "sequence": "EILLKMTTFGAFLHK",
     "pos": 939,
     "wtScore": -14.1266,
     "wtProb": 6.9,
     "k198rScore": -30.764,
     "k198rProb": 5.1,
     "probDifference": -1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1676
    },
    {
     "sequence": "SIGKQVPTSTNANLN",
     "pos": 1751,
     "wtScore": -14.3433,
     "wtProb": 6.8,
     "k198rScore": -31.1327,
     "k198rProb": 5.1,
     "probDifference": -1.8,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 1677
    },
    {
     "sequence": "FTSILNFYRTGRLHM",
     "pos": 98,
     "wtScore": -47.049,
     "wtProb": 4.9,
     "k198rScore": -11.9407,
     "k198rProb": 6.6,
     "probDifference": 1.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1678
    },
    {
     "sequence": "LKDGNGTTSGIGSSV",
     "pos": 1074,
     "wtScore": -8.8535,
     "wtProb": 7.8,
     "k198rScore": -24.0942,
     "k198rProb": 6.1,
     "probDifference": -1.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1679
    },
    {
     "sequence": "GSNFAIVYFISFYML",
     "pos": 1442,
     "wtScore": -44.6703,
     "wtProb": 4.9,
     "k198rScore": -12.9926,
     "k198rProb": 6.7,
     "probDifference": 1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1680
    },
    {
     "sequence": "MDNFDYLTRDWSILG",
     "pos": 1530,
     "wtScore": -3.19831,
     "wtProb": 8.5,
     "k198rScore": -18.5609,
     "k198rProb": 6.7,
     "probDifference": -1.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1681
    },
    {
     "sequence": "MDNFDYLTRDWSILG",
     "pos": 1470,
     "wtScore": -3.19831,
     "wtProb": 8.5,
     "k198rScore": -18.5609,
     "k198rProb": 6.7,
     "probDifference": -1.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1682
    },
    {
     "sequence": "DTDTDPFTPSGSMPL",
     "pos": 845,
     "wtScore": -9.81141,
     "wtProb": 7.7,
     "k198rScore": -25.065,
     "k198rProb": 5.9,
     "probDifference": -1.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1683
    },
    {
     "sequence": "GKQVPTSTNANLNNA",
     "pos": 1753,
     "wtScore": -13.3423,
     "wtProb": 7,
     "k198rScore": -29.1247,
     "k198rProb": 5.3,
     "probDifference": -1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1684
    },
    {
     "sequence": "WAFIYHAYVFLLVFS",
     "pos": 97,
     "wtScore": -39.4467,
     "wtProb": 5.3,
     "k198rScore": -7.9583,
     "k198rProb": 7.1,
     "probDifference": 1.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1685
    },
    {
     "sequence": "TQSPPVYTATSLSHS",
     "pos": 668,
     "wtScore": -13.6541,
     "wtProb": 7,
     "k198rScore": -29.6899,
     "k198rProb": 5.2,
     "probDifference": -1.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 1686
    },
    {
     "sequence": "SGVGTGSSVEKYVID",
     "pos": 1078,
     "wtScore": 50.9881,
     "wtProb": 11.1,
     "k198rScore": 39.6894,
     "k198rProb": 9.4,
     "probDifference": -1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1687
    },
    {
     "sequence": "YYCEGPDTRNISTKA",
     "pos": 1463,
     "wtScore": -2.56574,
     "wtProb": 8.6,
     "k198rScore": -17.1511,
     "k198rProb": 6.8,
     "probDifference": -1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1688
    },
    {
     "sequence": "LLAILPYYVTIFLTE",
     "pos": 277,
     "wtScore": -41.8627,
     "wtProb": 5.1,
     "k198rScore": -9.76243,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1689
    },
    {
     "sequence": "LLAILPYYVTIFLTE",
     "pos": 273,
     "wtScore": -41.8627,
     "wtProb": 5.1,
     "k198rScore": -9.76243,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1690
    },
    {
     "sequence": "EGWVDIMYYVMDAHS",
     "pos": 384,
     "wtScore": -45.1379,
     "wtProb": 4.9,
     "k198rScore": -11.3622,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1691
    },
    {
     "sequence": "RRIVDSKYFSRGIMM",
     "pos": 793,
     "wtScore": -43.4521,
     "wtProb": 5,
     "k198rScore": -10.6574,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1692
    },
    {
     "sequence": "DVSVVNNYSECKALI",
     "pos": 1382,
     "wtScore": -44.8709,
     "wtProb": 4.9,
     "k198rScore": -13.4279,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1693
    },
    {
     "sequence": "GRKDRINSIMSVVTN",
     "pos": 698,
     "wtScore": 49.7662,
     "wtProb": 10.6,
     "k198rScore": 38.2384,
     "k198rProb": 8.9,
     "probDifference": -1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1694
    },
    {
     "sequence": "SVSNYIFTAIFVAEM",
     "pos": 1335,
     "wtScore": -10.2233,
     "wtProb": 7.6,
     "k198rScore": -25.3715,
     "k198rProb": 5.8,
     "probDifference": -1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1695
    },
    {
     "sequence": "PQQQVQQSQPPQTQP",
     "pos": 740,
     "wtScore": 50.993,
     "wtProb": 11.1,
     "k198rScore": 46.2827,
     "k198rProb": 12.9,
     "probDifference": 1.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 1696
    },
    {
     "sequence": "LVFTGIFTAEMVLKI",
     "pos": 803,
     "wtScore": -4.51185,
     "wtProb": 8.4,
     "k198rScore": -19.9671,
     "k198rProb": 6.6,
     "probDifference": -1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1697
    },
    {
     "sequence": "KGAFCRNYFNLLDML",
     "pos": 953,
     "wtScore": -44.2133,
     "wtProb": 4.9,
     "k198rScore": -13.204,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1698
    },
    {
     "sequence": "SPPLIMCTAATPMPT",
     "pos": 1079,
     "wtScore": -14.1844,
     "wtProb": 6.9,
     "k198rScore": -30.6265,
     "k198rProb": 5.1,
     "probDifference": -1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1699
    },
    {
     "sequence": "SESDMEESKEKLNAT",
     "pos": 1130,
     "wtScore": 66.2163,
     "wtProb": 20.8,
     "k198rScore": 54.2399,
     "k198rProb": 19,
     "probDifference": -1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1700
    },
    {
     "sequence": "KDYIGDDSHFYVLDG",
     "pos": 322,
     "wtScore": 61.6832,
     "wtProb": 17.9,
     "k198rScore": 50.6319,
     "k198rProb": 16.2,
     "probDifference": -1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1701
    },
    {
     "sequence": "DKLNENSTPEKTDMT",
     "pos": 1958,
     "wtScore": -10.794,
     "wtProb": 7.5,
     "k198rScore": -26.0356,
     "k198rProb": 5.7,
     "probDifference": -1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1702
    },
    {
     "sequence": "NLFAGKFYHCINYTT",
     "pos": 1363,
     "wtScore": -39.6326,
     "wtProb": 5.3,
     "k198rScore": -8.13191,
     "k198rProb": 7,
     "probDifference": 1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1703
    },
    {
     "sequence": "LVFVIFFTCECVLKM",
     "pos": 1565,
     "wtScore": -14.5091,
     "wtProb": 6.8,
     "k198rScore": -31.3107,
     "k198rProb": 5.1,
     "probDifference": -1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1704
    },
    {
     "sequence": "EYVTTILSRINLVFI",
     "pos": 1573,
     "wtScore": 48.065,
     "wtProb": 10.1,
     "k198rScore": 36.1594,
     "k198rProb": 8.3,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1705
    },
    {
     "sequence": "EVTVGKFYATFLIQD",
     "pos": 1606,
     "wtScore": -45.8514,
     "wtProb": 4.9,
     "k198rScore": -11.9092,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1706
    },
    {
     "sequence": "PYSDFRFYWDFTMLL",
     "pos": 212,
     "wtScore": -44.0048,
     "wtProb": 4.9,
     "k198rScore": -10.9853,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 1707
    },
    {
     "sequence": "GMRALLDTVVQALPQ",
     "pos": 1740,
     "wtScore": -15.5658,
     "wtProb": 6.6,
     "k198rScore": -35.3579,
     "k198rProb": 4.9,
     "probDifference": -1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1708
    },
    {
     "sequence": "RSIHSLCTSHYLDLF",
     "pos": 1614,
     "wtScore": -15.7527,
     "wtProb": 6.6,
     "k198rScore": -38.1244,
     "k198rProb": 4.9,
     "probDifference": -1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1709
    },
    {
     "sequence": "SRERRSFTEIDTGDD",
     "pos": 804,
     "wtScore": 25.216,
     "wtProb": 8.4,
     "k198rScore": -14.5574,
     "k198rProb": 6.7,
     "probDifference": -1.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1710
    },
    {
     "sequence": "PGTPGAASTAKGSPN",
     "pos": 91,
     "wtScore": 52.934,
     "wtProb": 12.1,
     "k198rScore": 42.088,
     "k198rProb": 10.4,
     "probDifference": -1.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 1711
    },
    {
     "sequence": "AELPEDPSMMGRLGK",
     "pos": 617,
     "wtScore": 53.393,
     "wtProb": 12.4,
     "k198rScore": 42.5971,
     "k198rProb": 10.7,
     "probDifference": -1.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1712
    },
    {
     "sequence": "KYCNYVFTIVFVFEA",
     "pos": 1656,
     "wtScore": -14.0067,
     "wtProb": 6.9,
     "k198rScore": -30.1925,
     "k198rProb": 5.2,
     "probDifference": -1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1713
    },
    {
     "sequence": "TEEQKKYYNAMKKLG",
     "pos": 1497,
     "wtScore": -36.9434,
     "wtProb": 5.6,
     "k198rScore": -6.44828,
     "k198rProb": 7.4,
     "probDifference": 1.7,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 1714
    },
    {
     "sequence": "TEEQKKYYNAMKKLG",
     "pos": 1507,
     "wtScore": -36.9434,
     "wtProb": 5.6,
     "k198rScore": -6.44828,
     "k198rProb": 7.4,
     "probDifference": 1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1715
    },
    {
     "sequence": "TEEQKKYYNAMKKLG",
     "pos": 1492,
     "wtScore": -36.9434,
     "wtProb": 5.6,
     "k198rScore": -6.44828,
     "k198rProb": 7.4,
     "probDifference": 1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1716
    },
    {
     "sequence": "TEEQKKYYNAMKKLG",
     "pos": 1488,
     "wtScore": -36.9434,
     "wtProb": 5.6,
     "k198rScore": -6.44828,
     "k198rProb": 7.4,
     "probDifference": 1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1717
    },
    {
     "sequence": "IEDKSHFYFLEGQND",
     "pos": 326,
     "wtScore": -37.5341,
     "wtProb": 5.6,
     "k198rScore": -6.81797,
     "k198rProb": 7.3,
     "probDifference": 1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1718
    },
    {
     "sequence": "QAIAPINYPQMTTLN",
     "pos": 640,
     "wtScore": -44.4559,
     "wtProb": 4.9,
     "k198rScore": -12.3968,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 1719
    },
    {
     "sequence": "PKVIKMNYLKSWFVV",
     "pos": 216,
     "wtScore": -44.215,
     "wtProb": 4.9,
     "k198rScore": -13.3791,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 1720
    },
    {
     "sequence": "ACINWNQYYNVCRSG",
     "pos": 342,
     "wtScore": -41.2831,
     "wtProb": 5.1,
     "k198rScore": -9.23233,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1721
    },
    {
     "sequence": "QKCPPCWYKFANMCL",
     "pos": 729,
     "wtScore": -41.3871,
     "wtProb": 5.1,
     "k198rScore": -9.53801,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 1722
    },
    {
     "sequence": "PPSYDSVTKPDKEKF",
     "pos": 1973,
     "wtScore": -3.19749,
     "wtProb": 8.5,
     "k198rScore": -16.3079,
     "k198rProb": 6.8,
     "probDifference": -1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1723
    },
    {
     "sequence": "LIWECHPYWIKLKEI",
     "pos": 737,
     "wtScore": -37.6236,
     "wtProb": 5.6,
     "k198rScore": -6.94232,
     "k198rProb": 7.3,
     "probDifference": 1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1724
    },
    {
     "sequence": "CELKSCPYCTRALED",
     "pos": 701,
     "wtScore": -38.4518,
     "wtProb": 5.5,
     "k198rScore": -7.51432,
     "k198rProb": 7.2,
     "probDifference": 1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1725
    },
    {
     "sequence": "GKMHKTCYNQEGIAD",
     "pos": 298,
     "wtScore": -43.0954,
     "wtProb": 5,
     "k198rScore": -14.6143,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1726
    },
    {
     "sequence": "ISIFFIIYIIIVAFF",
     "pos": 1134,
     "wtScore": -43.9457,
     "wtProb": 4.9,
     "k198rScore": -12.7272,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1727
    },
    {
     "sequence": "PYSDFRFYWDLIMLI",
     "pos": 143,
     "wtScore": -43.709,
     "wtProb": 4.9,
     "k198rScore": -13.2359,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 1728
    },
    {
     "sequence": "IVVVGLFSAILEQAT",
     "pos": 208,
     "wtScore": 60.752,
     "wtProb": 17.3,
     "k198rScore": 54.0838,
     "k198rProb": 19.1,
     "probDifference": 1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1729
    },
    {
     "sequence": "QKNGNGTTSGIGSSV",
     "pos": 1066,
     "wtScore": -9.62581,
     "wtProb": 7.7,
     "k198rScore": -24.6894,
     "k198rProb": 6,
     "probDifference": -1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1730
    },
    {
     "sequence": "LFMAMEHYPMTEQFS",
     "pos": 781,
     "wtScore": -41.6069,
     "wtProb": 5.1,
     "k198rScore": -9.78872,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1731
    },
    {
     "sequence": "LFMAMEHYPMTEQFS",
     "pos": 780,
     "wtScore": -41.6069,
     "wtProb": 5.1,
     "k198rScore": -9.78872,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1732
    },
    {
     "sequence": "VWYVVNSTYFEYLMF",
     "pos": 1238,
     "wtScore": -10.4021,
     "wtProb": 7.6,
     "k198rScore": -25.3797,
     "k198rProb": 5.8,
     "probDifference": -1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1733
    },
    {
     "sequence": "SFDAILYYYQSGGRL",
     "pos": 90,
     "wtScore": -43.726,
     "wtProb": 4.9,
     "k198rScore": -11.2185,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1734
    },
    {
     "sequence": "SFDAILYYYQSGGRL",
     "pos": 94,
     "wtScore": -43.726,
     "wtProb": 4.9,
     "k198rScore": -11.2185,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1735
    },
    {
     "sequence": "IGKQVPTSTNANLNN",
     "pos": 1752,
     "wtScore": 44.6076,
     "wtProb": 9.2,
     "k198rScore": 25.4771,
     "k198rProb": 7.5,
     "probDifference": -1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1736
    },
    {
     "sequence": "FADDEHSTFEDNDSR",
     "pos": 599,
     "wtScore": -4.35194,
     "wtProb": 8.4,
     "k198rScore": -14.0971,
     "k198rProb": 6.7,
     "probDifference": -1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1737
    },
    {
     "sequence": "ANGKMHSTVDCNGVV",
     "pos": 643,
     "wtScore": -9.08356,
     "wtProb": 7.8,
     "k198rScore": -24.0035,
     "k198rProb": 6.1,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1738
    },
    {
     "sequence": "ANGKMHSTVDCNGVV",
     "pos": 646,
     "wtScore": -9.08356,
     "wtProb": 7.8,
     "k198rScore": -24.0035,
     "k198rProb": 6.1,
     "probDifference": -1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1739
    },
    {
     "sequence": "PDSFKPFTPESLANI",
     "pos": 17,
     "wtScore": -13.9378,
     "wtProb": 6.9,
     "k198rScore": -29.8218,
     "k198rProb": 5.2,
     "probDifference": -1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1740
    },
    {
     "sequence": "FSLRMFGSQKAVERE",
     "pos": 184,
     "wtScore": 57.3918,
     "wtProb": 14.9,
     "k198rScore": 46.9156,
     "k198rProb": 13.2,
     "probDifference": -1.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 1741
    },
    {
     "sequence": "DVKLQPVYEENLYMY",
     "pos": 1437,
     "wtScore": -35.3194,
     "wtProb": 5.8,
     "k198rScore": -5.90621,
     "k198rProb": 7.5,
     "probDifference": 1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1742
    },
    {
     "sequence": "RTRYYETYIRSDSGD",
     "pos": 1809,
     "wtScore": -39.7486,
     "wtProb": 5.3,
     "k198rScore": -8.29709,
     "k198rProb": 7,
     "probDifference": 1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1743
    },
    {
     "sequence": "LILAVLTTFKEYETV",
     "pos": 143,
     "wtScore": -10.8858,
     "wtProb": 7.5,
     "k198rScore": -25.8864,
     "k198rProb": 5.8,
     "probDifference": -1.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1744
    },
    {
     "sequence": "LRAAGKTYMIFFVLV",
     "pos": 400,
     "wtScore": -43.9882,
     "wtProb": 4.9,
     "k198rScore": -11.1339,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1745
    },
    {
     "sequence": "LRAAGKTYMIFFVLV",
     "pos": 398,
     "wtScore": -43.9882,
     "wtProb": 4.9,
     "k198rScore": -11.1339,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1746
    },
    {
     "sequence": "LRAAGKTYMIFFVLV",
     "pos": 386,
     "wtScore": -43.9882,
     "wtProb": 4.9,
     "k198rScore": -11.1339,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1747
    },
    {
     "sequence": "LRAAGKTYMIFFVLV",
     "pos": 399,
     "wtScore": -43.9882,
     "wtProb": 4.9,
     "k198rScore": -11.1339,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1748
    },
    {
     "sequence": "VACKRLVSMNMPLNS",
     "pos": 1592,
     "wtScore": 50.9629,
     "wtProb": 11.1,
     "k198rScore": 39.762,
     "k198rProb": 9.4,
     "probDifference": -1.7,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 1749
    },
    {
     "sequence": "VLFVIIIYAIIGLEL",
     "pos": 281,
     "wtScore": -42.0109,
     "wtProb": 5.1,
     "k198rScore": -10.0838,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1750
    },
    {
     "sequence": "VLFVIIIYAIIGLEL",
     "pos": 284,
     "wtScore": -42.0109,
     "wtProb": 5.1,
     "k198rScore": -10.0838,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1751
    },
    {
     "sequence": "LVFLNTLTISSEHYN",
     "pos": 538,
     "wtScore": -10.4878,
     "wtProb": 7.5,
     "k198rScore": -25.4381,
     "k198rProb": 5.8,
     "probDifference": -1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1752
    },
    {
     "sequence": "HSFYNFIYFILLIIV",
     "pos": 397,
     "wtScore": -41.0296,
     "wtProb": 5.1,
     "k198rScore": -9.43991,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1753
    },
    {
     "sequence": "DLLAILPYYVTIFLT",
     "pos": 276,
     "wtScore": -41.7871,
     "wtProb": 5.1,
     "k198rScore": -9.94516,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1754
    },
    {
     "sequence": "DLLAILPYYVTIFLT",
     "pos": 272,
     "wtScore": -41.7871,
     "wtProb": 5.1,
     "k198rScore": -9.94516,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1755
    },
    {
     "sequence": "VGGPSVPTSPVGQLL",
     "pos": 660,
     "wtScore": -14.5398,
     "wtProb": 6.8,
     "k198rScore": -30.9348,
     "k198rProb": 5.1,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1756
    },
    {
     "sequence": "SGIGTGSSVEKYIID",
     "pos": 1090,
     "wtScore": 51.0676,
     "wtProb": 11.2,
     "k198rScore": 39.9176,
     "k198rProb": 9.4,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1757
    },
    {
     "sequence": "NKSFENKYQEVSQKD",
     "pos": 511,
     "wtScore": -40.1595,
     "wtProb": 5.2,
     "k198rScore": -8.70157,
     "k198rProb": 6.9,
     "probDifference": 1.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1758
    },
    {
     "sequence": "DSAFETNTTSYFNGT",
     "pos": 290,
     "wtScore": -13.3373,
     "wtProb": 7,
     "k198rScore": -28.7595,
     "k198rProb": 5.3,
     "probDifference": -1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1759
    },
    {
     "sequence": "DLVLNFRTGIVIEDN",
     "pos": 264,
     "wtScore": -7.13675,
     "wtProb": 8.1,
     "k198rScore": -22.1248,
     "k198rProb": 6.4,
     "probDifference": -1.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 1760
    },
    {
     "sequence": "TFTPSSYSSTGSNAN",
     "pos": 1806,
     "wtScore": 46.5596,
     "wtProb": 9.6,
     "k198rScore": 33.4326,
     "k198rProb": 7.9,
     "probDifference": -1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1761
    },
    {
     "sequence": "WVAYGYQTYFTNAWC",
     "pos": 1277,
     "wtScore": -4.93559,
     "wtProb": 8.4,
     "k198rScore": -13.4369,
     "k198rProb": 6.7,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1762
    },
    {
     "sequence": "MSNPPDWTKNVEYTF",
     "pos": 154,
     "wtScore": -7.98242,
     "wtProb": 8,
     "k198rScore": -22.7625,
     "k198rProb": 6.3,
     "probDifference": -1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1763
    },
    {
     "sequence": "MSNPPDWTKNVEYTF",
     "pos": 153,
     "wtScore": -7.98242,
     "wtProb": 8,
     "k198rScore": -22.7625,
     "k198rProb": 6.3,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1764
    },
    {
     "sequence": "VAYGFQVYFTNAWCW",
     "pos": 1268,
     "wtScore": -40.1947,
     "wtProb": 5.2,
     "k198rScore": -8.76728,
     "k198rProb": 6.9,
     "probDifference": 1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1765
    },
    {
     "sequence": "LVATWRFYESVVSFP",
     "pos": 401,
     "wtScore": -43.4855,
     "wtProb": 5,
     "k198rScore": -11.2523,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1766
    },
    {
     "sequence": "GYPSTVSTVEGHGPP",
     "pos": 1837,
     "wtScore": -15.4784,
     "wtProb": 6.6,
     "k198rScore": -33.6081,
     "k198rProb": 4.9,
     "probDifference": -1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1767
    },
    {
     "sequence": "MLLKWTAYGFVKFFT",
     "pos": 1253,
     "wtScore": -37.3584,
     "wtProb": 5.6,
     "k198rScore": -6.91702,
     "k198rProb": 7.3,
     "probDifference": 1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1768
    },
    {
     "sequence": "KGWMDIMYAAVDSRD",
     "pos": 1423,
     "wtScore": -36.3787,
     "wtProb": 5.7,
     "k198rScore": -6.31067,
     "k198rProb": 7.4,
     "probDifference": 1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1769
    },
    {
     "sequence": "PKKQGSTTATRPPRA",
     "pos": 95,
     "wtScore": -4.88805,
     "wtProb": 8.4,
     "k198rScore": -12.9113,
     "k198rProb": 6.7,
     "probDifference": -1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1770
    },
    {
     "sequence": "GKLRDCNTHDSLLEV",
     "pos": 64,
     "wtScore": -8.06203,
     "wtProb": 8,
     "k198rScore": -22.752,
     "k198rProb": 6.3,
     "probDifference": -1.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1771
    },
    {
     "sequence": "NFVTAMLSKLRFEVF",
     "pos": 488,
     "wtScore": 51.0609,
     "wtProb": 11.1,
     "k198rScore": 39.9841,
     "k198rProb": 9.5,
     "probDifference": -1.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 1772
    },
    {
     "sequence": "VAYGFQTYFTNAWCW",
     "pos": 1266,
     "wtScore": -42.4879,
     "wtProb": 5,
     "k198rScore": -10.475,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1773
    },
    {
     "sequence": "ISIFFIIYIIIIAFF",
     "pos": 1168,
     "wtScore": -43.4855,
     "wtProb": 5,
     "k198rScore": -12.398,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1774
    },
    {
     "sequence": "SLLSIPGSPFLSRHN",
     "pos": 552,
     "wtScore": 47.652,
     "wtProb": 10,
     "k198rScore": 35.7965,
     "k198rProb": 8.3,
     "probDifference": -1.7,
     "geneName": "SCN8A",
     "known": "Yes",
     "id": 1775
    },
    {
     "sequence": "AVCIAWFTMEYLLRF",
     "pos": 244,
     "wtScore": -14.1228,
     "wtProb": 6.9,
     "k198rScore": -30.0207,
     "k198rProb": 5.2,
     "probDifference": -1.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1776
    },
    {
     "sequence": "AVCIAWFTMEYLLRF",
     "pos": 240,
     "wtScore": -14.1228,
     "wtProb": 6.9,
     "k198rScore": -30.0207,
     "k198rProb": 5.2,
     "probDifference": -1.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1777
    },
    {
     "sequence": "PEKARSSSSPQHLNV",
     "pos": 518,
     "wtScore": 46.3653,
     "wtProb": 9.6,
     "k198rScore": 33.0323,
     "k198rProb": 7.9,
     "probDifference": -1.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1778
    },
    {
     "sequence": "DSCMSNHTAEIGKDL",
     "pos": 1065,
     "wtScore": -5.18797,
     "wtProb": 8.3,
     "k198rScore": -20.0245,
     "k198rProb": 6.6,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1779
    },
    {
     "sequence": "EVNPAEHTQCSPSMN",
     "pos": 1356,
     "wtScore": -14.3591,
     "wtProb": 6.8,
     "k198rScore": -30.3526,
     "k198rProb": 5.1,
     "probDifference": -1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1780
    },
    {
     "sequence": "RNSNLSQTSRSSRML",
     "pos": 624,
     "wtScore": -13.361,
     "wtProb": 7,
     "k198rScore": -28.6977,
     "k198rProb": 5.3,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1781
    },
    {
     "sequence": "GMVFDFVTKQVFDIS",
     "pos": 1528,
     "wtScore": -14.1532,
     "wtProb": 6.9,
     "k198rScore": -30.01,
     "k198rProb": 5.2,
     "probDifference": -1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1782
    },
    {
     "sequence": "YEDNLYMYLYFVIFI",
     "pos": 1449,
     "wtScore": -42.5951,
     "wtProb": 5,
     "k198rScore": -10.8059,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1783
    },
    {
     "sequence": "GFWIIHPYSDFRFYW",
     "pos": 137,
     "wtScore": -43.0661,
     "wtProb": 5,
     "k198rScore": -13.2856,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 1784
    },
    {
     "sequence": "QADLQGPYSDRISPR",
     "pos": 769,
     "wtScore": -34.989,
     "wtProb": 5.8,
     "k198rScore": -5.93549,
     "k198rProb": 7.5,
     "probDifference": 1.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1785
    },
    {
     "sequence": "FADDEHSTFEDNESR",
     "pos": 596,
     "wtScore": -5.02016,
     "wtProb": 8.3,
     "k198rScore": -13.454,
     "k198rProb": 6.7,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1786
    },
    {
     "sequence": "LSTEMLSYQDDENRQ",
     "pos": 1943,
     "wtScore": -38.9658,
     "wtProb": 5.4,
     "k198rScore": -8.0191,
     "k198rProb": 7.1,
     "probDifference": 1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1787
    },
    {
     "sequence": "VHSPPAESCASLQIP",
     "pos": 1974,
     "wtScore": 61.6306,
     "wtProb": 17.9,
     "k198rScore": 50.5318,
     "k198rProb": 16.2,
     "probDifference": -1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1788
    },
    {
     "sequence": "VAYGYQTYFTNAWCW",
     "pos": 1278,
     "wtScore": -41.1541,
     "wtProb": 5.1,
     "k198rScore": -9.69553,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1789
    },
    {
     "sequence": "SPRRNSRTSLFSFRG",
     "pos": 571,
     "wtScore": -14.448,
     "wtProb": 6.8,
     "k198rScore": -30.4426,
     "k198rProb": 5.1,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1790
    },
    {
     "sequence": "LKDVNGTTSGIGTGS",
     "pos": 1082,
     "wtScore": -12.4718,
     "wtProb": 7.2,
     "k198rScore": -27.7448,
     "k198rProb": 5.5,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1791
    },
    {
     "sequence": "ICLAMQHYGQSCLFK",
     "pos": 1260,
     "wtScore": -40.283,
     "wtProb": 5.2,
     "k198rScore": -8.89353,
     "k198rProb": 6.9,
     "probDifference": 1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1792
    },
    {
     "sequence": "MSIASILTNTMEELE",
     "pos": 711,
     "wtScore": -8.96452,
     "wtProb": 7.8,
     "k198rScore": -23.8016,
     "k198rProb": 6.1,
     "probDifference": -1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1793
    },
    {
     "sequence": "WFVVDFVSSIPVDYI",
     "pos": 296,
     "wtScore": 48.8696,
     "wtProb": 10.3,
     "k198rScore": 37.2552,
     "k198rProb": 8.6,
     "probDifference": -1.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 1794
    },
    {
     "sequence": "RAGRHTASVHHLVYH",
     "pos": 512,
     "wtScore": 50.4225,
     "wtProb": 10.9,
     "k198rScore": 39.3766,
     "k198rProb": 9.2,
     "probDifference": -1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1795
    },
    {
     "sequence": "DYIIREGTIGKKMYF",
     "pos": 575,
     "wtScore": -8.42491,
     "wtProb": 7.9,
     "k198rScore": -23.2287,
     "k198rProb": 6.2,
     "probDifference": -1.7,
     "geneName": "HCN2",
     "known": "Yes",
     "id": 1796
    },
    {
     "sequence": "FEIIILLTIFANCVA",
     "pos": 132,
     "wtScore": -13.3755,
     "wtProb": 7,
     "k198rScore": -28.644,
     "k198rProb": 5.3,
     "probDifference": -1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1797
    },
    {
     "sequence": "MLSLPNDSYMFRPVV",
     "pos": 1930,
     "wtScore": 52.9346,
     "wtProb": 12.1,
     "k198rScore": 42.1866,
     "k198rProb": 10.4,
     "probDifference": -1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1798
    },
    {
     "sequence": "ISEGLGRYARDPKFV",
     "pos": 2073,
     "wtScore": -36.9246,
     "wtProb": 5.6,
     "k198rScore": -6.71783,
     "k198rProb": 7.3,
     "probDifference": 1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1799
    },
    {
     "sequence": "EGWPELLYRSIDSHT",
     "pos": 1141,
     "wtScore": -35.4764,
     "wtProb": 5.8,
     "k198rScore": -6.1224,
     "k198rProb": 7.4,
     "probDifference": 1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1800
    },
    {
     "sequence": "LVFTGIFTAEMFLKL",
     "pos": 796,
     "wtScore": -3.29228,
     "wtProb": 8.5,
     "k198rScore": -17.1369,
     "k198rProb": 6.8,
     "probDifference": -1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1801
    },
    {
     "sequence": "EKFDPDATQFMEFEK",
     "pos": 1819,
     "wtScore": -6.89948,
     "wtProb": 8.1,
     "k198rScore": -21.531,
     "k198rProb": 6.5,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1802
    },
    {
     "sequence": "GAWIIHPYSDFRFYW",
     "pos": 206,
     "wtScore": -42.8715,
     "wtProb": 5,
     "k198rScore": -12.665,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 1803
    },
    {
     "sequence": "LVFTGIFTAEMFLKI",
     "pos": 802,
     "wtScore": -4.81491,
     "wtProb": 8.4,
     "k198rScore": -19.2428,
     "k198rProb": 6.7,
     "probDifference": -1.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1804
    },
    {
     "sequence": "LVFTGIFTAEMFLKI",
     "pos": 811,
     "wtScore": -4.81491,
     "wtProb": 8.4,
     "k198rScore": -19.2428,
     "k198rProb": 6.7,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1805
    },
    {
     "sequence": "KTDLTMSTAACPPSY",
     "pos": 1978,
     "wtScore": -11.6151,
     "wtProb": 7.3,
     "k198rScore": -26.6837,
     "k198rProb": 5.6,
     "probDifference": -1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1806
    },
    {
     "sequence": "FKVTRHWTSLSNLVA",
     "pos": 633,
     "wtScore": -15.2177,
     "wtProb": 6.7,
     "k198rScore": -32.3053,
     "k198rProb": 5,
     "probDifference": -1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1807
    },
    {
     "sequence": "EGVIDMRSMSSIDSF",
     "pos": 583,
     "wtScore": 46.3406,
     "wtProb": 9.6,
     "k198rScore": 33.119,
     "k198rProb": 7.9,
     "probDifference": -1.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1808
    },
    {
     "sequence": "LVFIVLFTGEFVLKL",
     "pos": 1569,
     "wtScore": -11.0836,
     "wtProb": 7.4,
     "k198rScore": -25.9949,
     "k198rProb": 5.8,
     "probDifference": -1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1809
    },
    {
     "sequence": "LKTIICNYSETGPPE",
     "pos": 686,
     "wtScore": -43.1639,
     "wtProb": 5,
     "k198rScore": -11.9999,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1810
    },
    {
     "sequence": "KGSFCRNYFNILDLL",
     "pos": 987,
     "wtScore": -42.7773,
     "wtProb": 5,
     "k198rScore": -12.8646,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1811
    },
    {
     "sequence": "LVLSVFSTIKEYEKS",
     "pos": 113,
     "wtScore": -9.37112,
     "wtProb": 7.7,
     "k198rScore": -24.1716,
     "k198rProb": 6.1,
     "probDifference": -1.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1812
    },
    {
     "sequence": "EETSQDETYEVKMNH",
     "pos": 1918,
     "wtScore": -3.96158,
     "wtProb": 8.4,
     "k198rScore": -18.2921,
     "k198rProb": 6.8,
     "probDifference": -1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1813
    },
    {
     "sequence": "PPSSATTYVERPTVL",
     "pos": 740,
     "wtScore": -43.0914,
     "wtProb": 5,
     "k198rScore": -11.4487,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1814
    },
    {
     "sequence": "WFVVDFISSIPVDYI",
     "pos": 227,
     "wtScore": 48.3913,
     "wtProb": 10.2,
     "k198rScore": 36.7958,
     "k198rProb": 8.5,
     "probDifference": -1.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 1815
    },
    {
     "sequence": "YAFTAIFTVEILLKM",
     "pos": 930,
     "wtScore": -12.5579,
     "wtProb": 7.1,
     "k198rScore": -27.7868,
     "k198rProb": 5.5,
     "probDifference": -1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1816
    },
    {
     "sequence": "GENIGPIYNHRVEIS",
     "pos": 1121,
     "wtScore": -38.5287,
     "wtProb": 5.4,
     "k198rScore": -7.80329,
     "k198rProb": 7.1,
     "probDifference": 1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1817
    },
    {
     "sequence": "DTDSDLCTPCGPPPR",
     "pos": 846,
     "wtScore": -10.0084,
     "wtProb": 7.6,
     "k198rScore": -24.6948,
     "k198rProb": 6,
     "probDifference": -1.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1818
    },
    {
     "sequence": "EKNITVNYNGTLINE",
     "pos": 299,
     "wtScore": -42.8867,
     "wtProb": 5,
     "k198rScore": -12.7473,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1819
    },
    {
     "sequence": "MTEEQKKYYNAMKKL",
     "pos": 1496,
     "wtScore": -39.4354,
     "wtProb": 5.3,
     "k198rScore": -8.33303,
     "k198rProb": 7,
     "probDifference": 1.7,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 1820
    },
    {
     "sequence": "MTEEQKKYYNAMKKL",
     "pos": 1491,
     "wtScore": -39.4354,
     "wtProb": 5.3,
     "k198rScore": -8.33303,
     "k198rProb": 7,
     "probDifference": 1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1821
    },
    {
     "sequence": "MTEEQKKYYNAMKKL",
     "pos": 1487,
     "wtScore": -39.4354,
     "wtProb": 5.3,
     "k198rScore": -8.33303,
     "k198rProb": 7,
     "probDifference": 1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1822
    },
    {
     "sequence": "MTEEQKKYYNAMKKL",
     "pos": 1506,
     "wtScore": -39.4354,
     "wtProb": 5.3,
     "k198rScore": -8.33303,
     "k198rProb": 7,
     "probDifference": 1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1823
    },
    {
     "sequence": "PGMLVCIYFIILFIC",
     "pos": 731,
     "wtScore": -41.6278,
     "wtProb": 5.1,
     "k198rScore": -10.2307,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1824
    },
    {
     "sequence": "TKESAAQSKPKEELE",
     "pos": 553,
     "wtScore": 60.0996,
     "wtProb": 17,
     "k198rScore": 53.7096,
     "k198rProb": 18.6,
     "probDifference": 1.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1825
    },
    {
     "sequence": "HEKLVDSTFTPSSYS",
     "pos": 1799,
     "wtScore": -13.8792,
     "wtProb": 6.9,
     "k198rScore": -29.3125,
     "k198rProb": 5.3,
     "probDifference": -1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1826
    },
    {
     "sequence": "GKNLPFIYGDIPPEM",
     "pos": 64,
     "wtScore": -37.545,
     "wtProb": 5.6,
     "k198rScore": -7.28158,
     "k198rProb": 7.2,
     "probDifference": 1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1827
    },
    {
     "sequence": "GKNLPFIYGDIPPEM",
     "pos": 64,
     "wtScore": -37.545,
     "wtProb": 5.6,
     "k198rScore": -7.28158,
     "k198rProb": 7.2,
     "probDifference": 1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1828
    },
    {
     "sequence": "FSATSALYILTPFNP",
     "pos": 108,
     "wtScore": -42.3444,
     "wtProb": 5,
     "k198rScore": -14.2331,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1829
    },
    {
     "sequence": "KKLARTASPEKKQEL",
     "pos": 782,
     "wtScore": 69.4065,
     "wtProb": 22,
     "k198rScore": 56.443,
     "k198rProb": 20.4,
     "probDifference": -1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1830
    },
    {
     "sequence": "GSGKGSTSPGPKGKW",
     "pos": 628,
     "wtScore": 57.3648,
     "wtProb": 14.9,
     "k198rScore": 51.0785,
     "k198rProb": 16.6,
     "probDifference": 1.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1831
    },
    {
     "sequence": "SGMIVCIYFIILFIC",
     "pos": 730,
     "wtScore": -40.8903,
     "wtProb": 5.2,
     "k198rScore": -9.69176,
     "k198rProb": 6.8,
     "probDifference": 1.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1832
    },
    {
     "sequence": "EKFDPDATQFIEFSK",
     "pos": 1804,
     "wtScore": -4.96564,
     "wtProb": 8.3,
     "k198rScore": -19.3909,
     "k198rProb": 6.7,
     "probDifference": -1.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1833
    },
    {
     "sequence": "ANCTLANTNYVNITK",
     "pos": 486,
     "wtScore": -9.28373,
     "wtProb": 7.8,
     "k198rScore": -23.936,
     "k198rProb": 6.1,
     "probDifference": -1.7,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1834
    },
    {
     "sequence": "SKSKFSRYWRRWNRF",
     "pos": 504,
     "wtScore": -43.0418,
     "wtProb": 5,
     "k198rScore": -12.0487,
     "k198rProb": 6.6,
     "probDifference": 1.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1835
    },
    {
     "sequence": "FSVIMMAYITEFVNL",
     "pos": 205,
     "wtScore": -42.5557,
     "wtProb": 5,
     "k198rScore": -12.9466,
     "k198rProb": 6.7,
     "probDifference": 1.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1836
    },
    {
     "sequence": "LLTRGRRTASVRADT",
     "pos": 618,
     "wtScore": -5.93548,
     "wtProb": 8.2,
     "k198rScore": -20.492,
     "k198rProb": 6.6,
     "probDifference": -1.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 1837
    },
    {
     "sequence": "FLRQNCIYSTEALTG",
     "pos": 814,
     "wtScore": -40.6059,
     "wtProb": 5.2,
     "k198rScore": -9.28347,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1838
    },
    {
     "sequence": "QSQPILNTKESAAQS",
     "pos": 546,
     "wtScore": -15.3778,
     "wtProb": 6.7,
     "k198rScore": -32.3318,
     "k198rProb": 5,
     "probDifference": -1.6,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1839
    },
    {
     "sequence": "LSEDDFETFYEIWEK",
     "pos": 1786,
     "wtScore": -3.81094,
     "wtProb": 8.4,
     "k198rScore": -17.292,
     "k198rProb": 6.8,
     "probDifference": -1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1840
    },
    {
     "sequence": "HMTYDLASAVVRIFN",
     "pos": 292,
     "wtScore": 51.5337,
     "wtProb": 11.3,
     "k198rScore": 46.5095,
     "k198rProb": 13,
     "probDifference": 1.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 1841
    },
    {
     "sequence": "YEDNIYMYIYFVIFI",
     "pos": 1440,
     "wtScore": -42.5016,
     "wtProb": 5,
     "k198rScore": -11.0248,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1842
    },
    {
     "sequence": "SGVTFHTYSNSTIGY",
     "pos": 204,
     "wtScore": -42.7929,
     "wtProb": 5,
     "k198rScore": -12.028,
     "k198rProb": 6.6,
     "probDifference": 1.6,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1843
    },
    {
     "sequence": "ITMSMEHYNQPKSLD",
     "pos": 1638,
     "wtScore": -39.9568,
     "wtProb": 5.3,
     "k198rScore": -8.84723,
     "k198rProb": 6.9,
     "probDifference": 1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1844
    },
    {
     "sequence": "EPHGPEASPVAGGER",
     "pos": 2122,
     "wtScore": 65.3982,
     "wtProb": 20.4,
     "k198rScore": 53.7604,
     "k198rProb": 18.7,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1845
    },
    {
     "sequence": "EDKHCLSYLPALSPV",
     "pos": 1830,
     "wtScore": -39.4911,
     "wtProb": 5.3,
     "k198rScore": -8.51371,
     "k198rProb": 7,
     "probDifference": 1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1846
    },
    {
     "sequence": "PKYEESLYMYLYFVI",
     "pos": 1457,
     "wtScore": -38.5705,
     "wtProb": 5.4,
     "k198rScore": -7.90312,
     "k198rProb": 7.1,
     "probDifference": 1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1847
    },
    {
     "sequence": "NVCRSGDSNPHNGAI",
     "pos": 351,
     "wtScore": 57.9427,
     "wtProb": 15.3,
     "k198rScore": 47.7198,
     "k198rProb": 13.7,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1848
    },
    {
     "sequence": "FTVITFAYVTEFVDL",
     "pos": 202,
     "wtScore": -41.3589,
     "wtProb": 5.1,
     "k198rScore": -10.079,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1849
    },
    {
     "sequence": "FTVITFAYVTEFVDL",
     "pos": 201,
     "wtScore": -41.3589,
     "wtProb": 5.1,
     "k198rScore": -10.079,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1850
    },
    {
     "sequence": "NLSQTSRSSRMLAVF",
     "pos": 627,
     "wtScore": 44.4005,
     "wtProb": 9.2,
     "k198rScore": 27.3928,
     "k198rProb": 7.5,
     "probDifference": -1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1851
    },
    {
     "sequence": "PWNWLDFTVITFAYV",
     "pos": 195,
     "wtScore": -11.1882,
     "wtProb": 7.4,
     "k198rScore": -25.9928,
     "k198rProb": 5.8,
     "probDifference": -1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1852
    },
    {
     "sequence": "PWNWLDFTVITFAYV",
     "pos": 196,
     "wtScore": -11.1882,
     "wtProb": 7.4,
     "k198rScore": -25.9928,
     "k198rProb": 5.8,
     "probDifference": -1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1853
    },
    {
     "sequence": "TPPATPPYRDWTPCY",
     "pos": 1989,
     "wtScore": -38.656,
     "wtProb": 5.4,
     "k198rScore": -8.02598,
     "k198rProb": 7.1,
     "probDifference": 1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1854
    },
    {
     "sequence": "LLPEGTTTETEIRKR",
     "pos": 675,
     "wtScore": 28.7177,
     "wtProb": 8.4,
     "k198rScore": -10.3069,
     "k198rProb": 6.7,
     "probDifference": -1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1855
    },
    {
     "sequence": "ISSIPVDYIFLIVEK",
     "pos": 233,
     "wtScore": -39.1201,
     "wtProb": 5.4,
     "k198rScore": -8.27736,
     "k198rProb": 7,
     "probDifference": 1.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 1856
    },
    {
     "sequence": "VIMDNFDYLTRDWSI",
     "pos": 1528,
     "wtScore": -37.8055,
     "wtProb": 5.5,
     "k198rScore": -7.52392,
     "k198rProb": 7.2,
     "probDifference": 1.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1857
    },
    {
     "sequence": "VIMDNFDYLTRDWSI",
     "pos": 1468,
     "wtScore": -37.8055,
     "wtProb": 5.5,
     "k198rScore": -7.52392,
     "k198rProb": 7.2,
     "probDifference": 1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1858
    },
    {
     "sequence": "GEEGKRNTSMPTSET",
     "pos": 463,
     "wtScore": -16.4685,
     "wtProb": 6.5,
     "k198rScore": -36.5946,
     "k198rProb": 4.9,
     "probDifference": -1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1859
    },
    {
     "sequence": "PRGWALLYHALVFLI",
     "pos": 124,
     "wtScore": -40.2192,
     "wtProb": 5.2,
     "k198rScore": -9.09437,
     "k198rProb": 6.9,
     "probDifference": 1.6,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1860
    },
    {
     "sequence": "GEHAYLQSSWNLLDG",
     "pos": 1361,
     "wtScore": 43.9714,
     "wtProb": 9.1,
     "k198rScore": 24.7803,
     "k198rProb": 7.5,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1861
    },
    {
     "sequence": "FPRNCWVSINGMVNH",
     "pos": 400,
     "wtScore": 49.8389,
     "wtProb": 10.6,
     "k198rScore": 38.6798,
     "k198rProb": 9,
     "probDifference": -1.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 1862
    },
    {
     "sequence": "SIFNWDEYIEDKSHF",
     "pos": 318,
     "wtScore": -27.288,
     "wtProb": 5.8,
     "k198rScore": 18.8444,
     "k198rProb": 7.5,
     "probDifference": 1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1863
    },
    {
     "sequence": "NSLDGNGTTFNRTVS",
     "pos": 304,
     "wtScore": -4.54548,
     "wtProb": 8.4,
     "k198rScore": -15.7174,
     "k198rProb": 6.8,
     "probDifference": -1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1864
    },
    {
     "sequence": "RLSRLIRYIHQWEEI",
     "pos": 276,
     "wtScore": -42.2712,
     "wtProb": 5,
     "k198rScore": -11.1585,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 1865
    },
    {
     "sequence": "RLSRLIRYIHQWEEI",
     "pos": 345,
     "wtScore": -42.2712,
     "wtProb": 5,
     "k198rScore": -11.1585,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 1866
    },
    {
     "sequence": "GWVDIMYYVMDAHSF",
     "pos": 385,
     "wtScore": -42.1629,
     "wtProb": 5,
     "k198rScore": -12.8751,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1867
    },
    {
     "sequence": "FSATPALYILTPFNP",
     "pos": 109,
     "wtScore": -42.2164,
     "wtProb": 5,
     "k198rScore": -13.7248,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1868
    },
    {
     "sequence": "QLNNTSSSSPQHLSA",
     "pos": 529,
     "wtScore": 44.5648,
     "wtProb": 9.2,
     "k198rScore": 28.6126,
     "k198rProb": 7.6,
     "probDifference": -1.6,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1869
    },
    {
     "sequence": "IDNTTVIYNSNIFTD",
     "pos": 211,
     "wtScore": -42.1598,
     "wtProb": 5,
     "k198rScore": -12.8835,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1870
    },
    {
     "sequence": "EMKEEFETYADALWW",
     "pos": 301,
     "wtScore": -6.3777,
     "wtProb": 8.2,
     "k198rScore": -20.7327,
     "k198rProb": 6.6,
     "probDifference": -1.6,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1871
    },
    {
     "sequence": "VIFVGSFYLVNLILA",
     "pos": 400,
     "wtScore": -42.2037,
     "wtProb": 5,
     "k198rScore": -12.8028,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1872
    },
    {
     "sequence": "PYHSPEDSREHVDRH",
     "pos": 675,
     "wtScore": 56.4671,
     "wtProb": 14.4,
     "k198rScore": 46.1562,
     "k198rProb": 12.8,
     "probDifference": -1.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1873
    },
    {
     "sequence": "DGPKHGITNFDNFAF",
     "pos": 343,
     "wtScore": -6.29769,
     "wtProb": 8.2,
     "k198rScore": -20.4283,
     "k198rProb": 6.6,
     "probDifference": -1.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1874
    },
    {
     "sequence": "MDSEVYKTARALRIV",
     "pos": 249,
     "wtScore": -5.16869,
     "wtProb": 8.3,
     "k198rScore": -14.8411,
     "k198rProb": 6.7,
     "probDifference": -1.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 1875
    },
    {
     "sequence": "ADTYCRLYSLSVDNF",
     "pos": 630,
     "wtScore": -41.0345,
     "wtProb": 5.1,
     "k198rScore": -10.0786,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 1876
    },
    {
     "sequence": "ADTYCRLYSLSVDNF",
     "pos": 561,
     "wtScore": -41.0345,
     "wtProb": 5.1,
     "k198rScore": -10.0786,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 1877
    },
    {
     "sequence": "LFMAMEHYPMTDHFN",
     "pos": 789,
     "wtScore": -42.1956,
     "wtProb": 5,
     "k198rScore": -12.4579,
     "k198rProb": 6.6,
     "probDifference": 1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1878
    },
    {
     "sequence": "GTPIKEDTLIDKLNE",
     "pos": 1948,
     "wtScore": -6.31247,
     "wtProb": 8.2,
     "k198rScore": -20.5634,
     "k198rProb": 6.6,
     "probDifference": -1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1879
    },
    {
     "sequence": "YEESLYMYLYFVIFI",
     "pos": 1459,
     "wtScore": -41.3712,
     "wtProb": 5.1,
     "k198rScore": -10.381,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1880
    },
    {
     "sequence": "IKKIWKKTSMKLLDQ",
     "pos": 1583,
     "wtScore": -16.0881,
     "wtProb": 6.5,
     "k198rScore": -33.7927,
     "k198rProb": 4.9,
     "probDifference": -1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1881
    },
    {
     "sequence": "QKCPPCWYKFSNIFL",
     "pos": 738,
     "wtScore": -40.4458,
     "wtProb": 5.2,
     "k198rScore": -9.70663,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1882
    },
    {
     "sequence": "ASFSEPGSCYEELLK",
     "pos": 454,
     "wtScore": 103.376,
     "wtProb": 33.5,
     "k198rScore": 76.64,
     "k198rProb": 31.9,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1883
    },
    {
     "sequence": "FSEEIRFYELGEEAM",
     "pos": 115,
     "wtScore": -38.9762,
     "wtProb": 5.4,
     "k198rScore": -8.36349,
     "k198rProb": 7,
     "probDifference": 1.6,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1884
    },
    {
     "sequence": "PPPAHERSLSAYGGG",
     "pos": 750,
     "wtScore": 39.0782,
     "wtProb": 8.6,
     "k198rScore": 41.7715,
     "k198rProb": 10.2,
     "probDifference": 1.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1885
    },
    {
     "sequence": "PEENHQGSNYGSPRP",
     "pos": 17,
     "wtScore": 59.3249,
     "wtProb": 16.4,
     "k198rScore": 52.7509,
     "k198rProb": 18,
     "probDifference": 1.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1886
    },
    {
     "sequence": "DNIYMYIYFVIFIIF",
     "pos": 1442,
     "wtScore": -40.7704,
     "wtProb": 5.2,
     "k198rScore": -9.97512,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1887
    },
    {
     "sequence": "GKSLPFIYGDIPQGL",
     "pos": 68,
     "wtScore": -35.9827,
     "wtProb": 5.7,
     "k198rScore": -6.64756,
     "k198rProb": 7.3,
     "probDifference": 1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1888
    },
    {
     "sequence": "YNYRVEISIFFIIYI",
     "pos": 1162,
     "wtScore": 47.5756,
     "wtProb": 9.9,
     "k198rScore": 36.1001,
     "k198rProb": 8.3,
     "probDifference": -1.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1889
    },
    {
     "sequence": "HSWSELYSFALFKAM",
     "pos": 414,
     "wtScore": 48.7563,
     "wtProb": 10.3,
     "k198rScore": 37.4723,
     "k198rProb": 8.7,
     "probDifference": -1.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 1890
    },
    {
     "sequence": "STFEDSESRRDSLFV",
     "pos": 605,
     "wtScore": 58.3241,
     "wtProb": 15.6,
     "k198rScore": 48.1757,
     "k198rProb": 14,
     "probDifference": -1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1891
    },
    {
     "sequence": "NGMASTSSWAALYFV",
     "pos": 988,
     "wtScore": 44.3442,
     "wtProb": 9.2,
     "k198rScore": 28.0809,
     "k198rProb": 7.6,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1892
    },
    {
     "sequence": "DSPVCYDSRRSPRRR",
     "pos": 1901,
     "wtScore": 58.6293,
     "wtProb": 15.8,
     "k198rScore": 48.3787,
     "k198rProb": 14.2,
     "probDifference": -1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1893
    },
    {
     "sequence": "FSATSALYILTPLNP",
     "pos": 108,
     "wtScore": -41.9939,
     "wtProb": 5.1,
     "k198rScore": -13.4755,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1894
    },
    {
     "sequence": "FSATPALYILSPFNL",
     "pos": 112,
     "wtScore": -41.8633,
     "wtProb": 5.1,
     "k198rScore": -13.1683,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1895
    },
    {
     "sequence": "TFLKIIAYGLLLHPN",
     "pos": 182,
     "wtScore": -41.839,
     "wtProb": 5.1,
     "k198rScore": -11.0109,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1896
    },
    {
     "sequence": "NQKGEQATSLAILRV",
     "pos": 285,
     "wtScore": -13.3365,
     "wtProb": 7,
     "k198rScore": -28.0635,
     "k198rProb": 5.4,
     "probDifference": -1.6,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1897
    },
    {
     "sequence": "EGWTDVLYWMNDAMG",
     "pos": 370,
     "wtScore": -40.7549,
     "wtProb": 5.2,
     "k198rScore": -16.4136,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1898
    },
    {
     "sequence": "QLFKGKFYYCEGPDT",
     "pos": 1456,
     "wtScore": -40.6807,
     "wtProb": 5.2,
     "k198rScore": -9.88064,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1899
    },
    {
     "sequence": "KLRRIVDSKYFSRGI",
     "pos": 791,
     "wtScore": 59.3856,
     "wtProb": 16.4,
     "k198rScore": 49.1856,
     "k198rProb": 14.8,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1900
    },
    {
     "sequence": "KFYHCINYTTGEMFD",
     "pos": 1368,
     "wtScore": -41.1301,
     "wtProb": 5.1,
     "k198rScore": -10.3265,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1901
    },
    {
     "sequence": "WEEIFHMTYDLASAV",
     "pos": 287,
     "wtScore": -10.3692,
     "wtProb": 7.6,
     "k198rScore": -24.7031,
     "k198rProb": 6,
     "probDifference": -1.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 1902
    },
    {
     "sequence": "WEEIFHMTYDLASAV",
     "pos": 356,
     "wtScore": -10.3692,
     "wtProb": 7.6,
     "k198rScore": -24.7031,
     "k198rProb": 6,
     "probDifference": -1.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 1903
    },
    {
     "sequence": "GKFNFDETQTKRSTF",
     "pos": 680,
     "wtScore": -10.9573,
     "wtProb": 7.5,
     "k198rScore": -25.3055,
     "k198rProb": 5.9,
     "probDifference": -1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1904
    },
    {
     "sequence": "LVGASPESPGAPGRE",
     "pos": 31,
     "wtScore": 64.4891,
     "wtProb": 19.8,
     "k198rScore": 59.2636,
     "k198rProb": 21.4,
     "probDifference": 1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1905
    },
    {
     "sequence": "GIVFDFVTQQAFDIV",
     "pos": 1519,
     "wtScore": -9.76392,
     "wtProb": 7.7,
     "k198rScore": -24.1793,
     "k198rProb": 6.1,
     "probDifference": -1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1906
    },
    {
     "sequence": "DWLLLLETFAIFIFG",
     "pos": 160,
     "wtScore": -14.9717,
     "wtProb": 6.7,
     "k198rScore": -30.5826,
     "k198rProb": 5.1,
     "probDifference": -1.6,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1907
    },
    {
     "sequence": "PVYEENLYMYLYFVI",
     "pos": 1442,
     "wtScore": -40.8234,
     "wtProb": 5.2,
     "k198rScore": -10.0774,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1908
    },
    {
     "sequence": "PKYEDNIYMYIYFVI",
     "pos": 1438,
     "wtScore": -40.2442,
     "wtProb": 5.2,
     "k198rScore": -9.67239,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1909
    },
    {
     "sequence": "LPPEGTTTETEVRKR",
     "pos": 676,
     "wtScore": 30.3648,
     "wtProb": 8.4,
     "k198rScore": -9.94606,
     "k198rProb": 6.8,
     "probDifference": -1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1910
    },
    {
     "sequence": "SKVPKSWSFGDRSRA",
     "pos": 484,
     "wtScore": 79.3077,
     "wtProb": 25.1,
     "k198rScore": 72.0854,
     "k198rProb": 26.7,
     "probDifference": 1.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1911
    },
    {
     "sequence": "EIGKDLNYLKDGNGT",
     "pos": 1066,
     "wtScore": -36.7549,
     "wtProb": 5.6,
     "k198rScore": -7.16195,
     "k198rProb": 7.2,
     "probDifference": 1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1912
    },
    {
     "sequence": "QPRSWENSKFDFDNV",
     "pos": 1113,
     "wtScore": 71.8139,
     "wtProb": 23,
     "k198rScore": 59.24,
     "k198rProb": 21.4,
     "probDifference": -1.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1913
    },
    {
     "sequence": "RMDRRGGTWKLLGSA",
     "pos": 245,
     "wtScore": -15.2916,
     "wtProb": 6.7,
     "k198rScore": -31.2474,
     "k198rProb": 5.1,
     "probDifference": -1.6,
     "geneName": "KCNQ3",
     "known": "Yes",
     "id": 1914
    },
    {
     "sequence": "VFLSVSNYIFTAIFV",
     "pos": 1332,
     "wtScore": -41.7244,
     "wtProb": 5.1,
     "k198rScore": -12.8266,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1915
    },
    {
     "sequence": "GQADGGLSVLRTFRL",
     "pos": 880,
     "wtScore": 64.041,
     "wtProb": 19.5,
     "k198rScore": 52.6945,
     "k198rProb": 17.9,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1916
    },
    {
     "sequence": "YKDGDVDSPVVRERI",
     "pos": 1068,
     "wtScore": 64.876,
     "wtProb": 20,
     "k198rScore": 53.3799,
     "k198rProb": 18.4,
     "probDifference": -1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1917
    },
    {
     "sequence": "LADAESLTSAQKEEE",
     "pos": 762,
     "wtScore": -6.65081,
     "wtProb": 8.2,
     "k198rScore": -20.6801,
     "k198rProb": 6.6,
     "probDifference": -1.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1918
    },
    {
     "sequence": "MLLKWVAYGFQVYFT",
     "pos": 1263,
     "wtScore": -36.834,
     "wtProb": 5.6,
     "k198rScore": -7.19684,
     "k198rProb": 7.2,
     "probDifference": 1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1919
    },
    {
     "sequence": "STTLRRRTPSCEATP",
     "pos": 2219,
     "wtScore": -14.5747,
     "wtProb": 6.8,
     "k198rScore": -29.6914,
     "k198rProb": 5.2,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1920
    },
    {
     "sequence": "YRDWTPCYTPLIQVE",
     "pos": 1996,
     "wtScore": -40.5451,
     "wtProb": 5.2,
     "k198rScore": -9.89523,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1921
    },
    {
     "sequence": "CVMRFLVSKRKFKES",
     "pos": 550,
     "wtScore": 47.912,
     "wtProb": 10,
     "k198rScore": 36.5396,
     "k198rProb": 8.4,
     "probDifference": -1.6,
     "geneName": "KCNQ2",
     "known": "Yes",
     "id": 1922
    },
    {
     "sequence": "SSCGRPGTPGAASTA",
     "pos": 86,
     "wtScore": -10.5315,
     "wtProb": 7.5,
     "k198rScore": -24.7712,
     "k198rProb": 5.9,
     "probDifference": -1.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 1923
    },
    {
     "sequence": "SWMREKRYLAEGETD",
     "pos": 831,
     "wtScore": -38.7301,
     "wtProb": 5.4,
     "k198rScore": -8.30237,
     "k198rProb": 7,
     "probDifference": 1.6,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1924
    },
    {
     "sequence": "NNKTNFYTVPGMLEP",
     "pos": 313,
     "wtScore": -14.8575,
     "wtProb": 6.7,
     "k198rScore": -30.1715,
     "k198rProb": 5.2,
     "probDifference": -1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1925
    },
    {
     "sequence": "AAWRYYATNPNRIDL",
     "pos": 387,
     "wtScore": -6.01374,
     "wtProb": 8.2,
     "k198rScore": -19.8461,
     "k198rProb": 6.7,
     "probDifference": -1.6,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1926
    },
    {
     "sequence": "KLVDSTFTPSSYSST",
     "pos": 1801,
     "wtScore": -14.7755,
     "wtProb": 6.8,
     "k198rScore": -30.0133,
     "k198rProb": 5.2,
     "probDifference": -1.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1927
    },
    {
     "sequence": "SVKRTRYYETYIRSD",
     "pos": 1806,
     "wtScore": -39.8312,
     "wtProb": 5.3,
     "k198rScore": -9.19016,
     "k198rProb": 6.9,
     "probDifference": 1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1928
    },
    {
     "sequence": "YEENLYMYLYFVIFI",
     "pos": 1444,
     "wtScore": -41.1578,
     "wtProb": 5.1,
     "k198rScore": -10.4505,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1929
    },
    {
     "sequence": "KTDMTPSTTSPPSYD",
     "pos": 1968,
     "wtScore": -16.7527,
     "wtProb": 6.5,
     "k198rScore": -35.1817,
     "k198rProb": 4.9,
     "probDifference": -1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1930
    },
    {
     "sequence": "PLLQRSHSPASFPRP",
     "pos": 2026,
     "wtScore": 53.7888,
     "wtProb": 12.6,
     "k198rScore": 43.3715,
     "k198rProb": 11.1,
     "probDifference": -1.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1931
    },
    {
     "sequence": "NHSWSELYSFALFKA",
     "pos": 413,
     "wtScore": -39.9373,
     "wtProb": 5.3,
     "k198rScore": -9.517,
     "k198rProb": 6.8,
     "probDifference": 1.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 1932
    },
    {
     "sequence": "EEIFHMTYDLASAVV",
     "pos": 288,
     "wtScore": -28.1414,
     "wtProb": 5.8,
     "k198rScore": -6.22978,
     "k198rProb": 7.4,
     "probDifference": 1.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 1933
    },
    {
     "sequence": "KSTSSIDSFTSCATD",
     "pos": 604,
     "wtScore": 55.7264,
     "wtProb": 13.9,
     "k198rScore": 45.5618,
     "k198rProb": 12.3,
     "probDifference": -1.6,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1934
    },
    {
     "sequence": "PDTRNISTKAQCRAA",
     "pos": 1468,
     "wtScore": -13.3993,
     "wtProb": 7,
     "k198rScore": -27.9691,
     "k198rProb": 5.4,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1935
    },
    {
     "sequence": "PDAESVHSIYHADCH",
     "pos": 573,
     "wtScore": 46.7658,
     "wtProb": 9.7,
     "k198rScore": 34.8991,
     "k198rProb": 8.1,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1936
    },
    {
     "sequence": "YEEQNQATLEEAEQK",
     "pos": 432,
     "wtScore": -5.96665,
     "wtProb": 8.2,
     "k198rScore": -13.8152,
     "k198rProb": 6.7,
     "probDifference": -1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1937
    },
    {
     "sequence": "YEEQNQATLEEAEQK",
     "pos": 433,
     "wtScore": -5.96665,
     "wtProb": 8.2,
     "k198rScore": -13.8152,
     "k198rProb": 6.7,
     "probDifference": -1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1938
    },
    {
     "sequence": "YEEQNQATLEEAEQK",
     "pos": 434,
     "wtScore": -5.96665,
     "wtProb": 8.2,
     "k198rScore": -13.8152,
     "k198rProb": 6.7,
     "probDifference": -1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1939
    },
    {
     "sequence": "YEEQNQATLEEAEQK",
     "pos": 420,
     "wtScore": -5.96665,
     "wtProb": 8.2,
     "k198rScore": -13.8152,
     "k198rProb": 6.7,
     "probDifference": -1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1940
    },
    {
     "sequence": "YPMTEQFSSVLTVGN",
     "pos": 788,
     "wtScore": 46.2978,
     "wtProb": 9.6,
     "k198rScore": 33.8776,
     "k198rProb": 8,
     "probDifference": -1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1941
    },
    {
     "sequence": "GGLDPQDSARSRLSS",
     "pos": 879,
     "wtScore": 52.3115,
     "wtProb": 11.8,
     "k198rScore": 41.6794,
     "k198rProb": 10.2,
     "probDifference": -1.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 1942
    },
    {
     "sequence": "NCRKLVATMPLFANA",
     "pos": 471,
     "wtScore": -15.5051,
     "wtProb": 6.6,
     "k198rScore": -31.4042,
     "k198rProb": 5.1,
     "probDifference": -1.6,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 1943
    },
    {
     "sequence": "SAWRFYATNLSRTDL",
     "pos": 348,
     "wtScore": -6.71888,
     "wtProb": 8.2,
     "k198rScore": -20.3847,
     "k198rProb": 6.6,
     "probDifference": -1.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 1944
    },
    {
     "sequence": "GMQLFGKSYKDCVCK",
     "pos": 914,
     "wtScore": 57.7561,
     "wtProb": 15.2,
     "k198rScore": 47.7027,
     "k198rProb": 13.7,
     "probDifference": -1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1945
    },
    {
     "sequence": "AILSPCSYTTAVCSP",
     "pos": 698,
     "wtScore": -40.6706,
     "wtProb": 5.2,
     "k198rScore": -10.1527,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 1946
    },
    {
     "sequence": "WSRQNYGYYSRYPGR",
     "pos": 1866,
     "wtScore": -36.2116,
     "wtProb": 5.7,
     "k198rScore": -7.06045,
     "k198rProb": 7.3,
     "probDifference": 1.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1947
    },
    {
     "sequence": "LTGEDWNSVMYDGIM",
     "pos": 709,
     "wtScore": 56.7411,
     "wtProb": 14.5,
     "k198rScore": 46.4293,
     "k198rProb": 13,
     "probDifference": -1.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1948
    },
    {
     "sequence": "IYAIFGMSNFAYVKR",
     "pos": 1679,
     "wtScore": 57.0023,
     "wtProb": 14.7,
     "k198rScore": 46.7827,
     "k198rProb": 13.2,
     "probDifference": -1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1949
    },
    {
     "sequence": "IYAIFGMSNFAYVKR",
     "pos": 1689,
     "wtScore": 57.0023,
     "wtProb": 14.7,
     "k198rScore": 46.7827,
     "k198rProb": 13.2,
     "probDifference": -1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1950
    },
    {
     "sequence": "FADDEHSTFEDSESR",
     "pos": 599,
     "wtScore": -5.58708,
     "wtProb": 8.3,
     "k198rScore": -14.3248,
     "k198rProb": 6.7,
     "probDifference": -1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1951
    },
    {
     "sequence": "DRIGKKNSILLQKFQ",
     "pos": 598,
     "wtScore": 45.2535,
     "wtProb": 9.4,
     "k198rScore": 32.0981,
     "k198rProb": 7.8,
     "probDifference": -1.6,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 1952
    },
    {
     "sequence": "RTPSCEATPHRDSLE",
     "pos": 2225,
     "wtScore": -14.0829,
     "wtProb": 6.9,
     "k198rScore": -28.8538,
     "k198rProb": 5.3,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1953
    },
    {
     "sequence": "TTSYFNGTMDSNGTF",
     "pos": 297,
     "wtScore": -9.5287,
     "wtProb": 7.7,
     "k198rScore": -23.6639,
     "k198rProb": 6.1,
     "probDifference": -1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1954
    },
    {
     "sequence": "FGMSNFAYVKHEAGI",
     "pos": 1674,
     "wtScore": -39.5819,
     "wtProb": 5.3,
     "k198rScore": -9.12125,
     "k198rProb": 6.9,
     "probDifference": 1.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1955
    },
    {
     "sequence": "AILVNTLSMGVEYHE",
     "pos": 808,
     "wtScore": 62.4294,
     "wtProb": 18.4,
     "k198rScore": 51.3266,
     "k198rProb": 16.8,
     "probDifference": -1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1956
    },
    {
     "sequence": "SIRGSLFSPRRNSRA",
     "pos": 567,
     "wtScore": 50.9066,
     "wtProb": 11.1,
     "k198rScore": 40.1713,
     "k198rProb": 9.5,
     "probDifference": -1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1957
    },
    {
     "sequence": "VSIASILTNTMEELE",
     "pos": 712,
     "wtScore": -9.93841,
     "wtProb": 7.6,
     "k198rScore": -23.99,
     "k198rProb": 6.1,
     "probDifference": -1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1958
    },
    {
     "sequence": "SYIQDSRYHYFLEGF",
     "pos": 322,
     "wtScore": -38.4976,
     "wtProb": 5.5,
     "k198rScore": -8.27536,
     "k198rProb": 7,
     "probDifference": 1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1959
    },
    {
     "sequence": "TSEIEDQSMMGKFVK",
     "pos": 616,
     "wtScore": 55.1542,
     "wtProb": 13.5,
     "k198rScore": 44.9808,
     "k198rProb": 12,
     "probDifference": -1.6,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1960
    },
    {
     "sequence": "FVDLGNVSALRTFRV",
     "pos": 213,
     "wtScore": 59.4606,
     "wtProb": 16.4,
     "k198rScore": 52.7814,
     "k198rProb": 18,
     "probDifference": 1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1961
    },
    {
     "sequence": "FVDLGNVSALRTFRV",
     "pos": 212,
     "wtScore": 59.4606,
     "wtProb": 16.4,
     "k198rScore": 52.7814,
     "k198rProb": 18,
     "probDifference": 1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1962
    },
    {
     "sequence": "NNPSLTVTVPIAVGE",
     "pos": 1101,
     "wtScore": -15.4703,
     "wtProb": 6.6,
     "k198rScore": -31.0533,
     "k198rProb": 5.1,
     "probDifference": -1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1963
    },
    {
     "sequence": "NNPSLTVTVPIAVGE",
     "pos": 1103,
     "wtScore": -15.4703,
     "wtProb": 6.6,
     "k198rScore": -31.0533,
     "k198rProb": 5.1,
     "probDifference": -1.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1964
    },
    {
     "sequence": "NNPSLTVTVPIAVGE",
     "pos": 1113,
     "wtScore": -15.4703,
     "wtProb": 6.6,
     "k198rScore": -31.0533,
     "k198rProb": 5.1,
     "probDifference": -1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1965
    },
    {
     "sequence": "EISKELNYLRDGNGT",
     "pos": 1062,
     "wtScore": -37.4484,
     "wtProb": 5.6,
     "k198rScore": -7.71452,
     "k198rProb": 7.1,
     "probDifference": 1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1966
    },
    {
     "sequence": "TQRKRQQYGKPKKQG",
     "pos": 85,
     "wtScore": -36.1226,
     "wtProb": 5.7,
     "k198rScore": -7.08293,
     "k198rProb": 7.3,
     "probDifference": 1.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1967
    },
    {
     "sequence": "SAFETNTTSYFNGTM",
     "pos": 291,
     "wtScore": -14.8421,
     "wtProb": 6.7,
     "k198rScore": -29.836,
     "k198rProb": 5.2,
     "probDifference": -1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1968
    },
    {
     "sequence": "SLRHYYFTIGWNIFD",
     "pos": 1585,
     "wtScore": -6.27078,
     "wtProb": 8.2,
     "k198rScore": -19.8527,
     "k198rProb": 6.7,
     "probDifference": -1.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1969
    },
    {
     "sequence": "SLRHYYFTIGWNIFD",
     "pos": 1600,
     "wtScore": -6.27078,
     "wtProb": 8.2,
     "k198rScore": -19.8527,
     "k198rProb": 6.7,
     "probDifference": -1.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1970
    },
    {
     "sequence": "HSLCTSHYLDLFITF",
     "pos": 1617,
     "wtScore": -41.4714,
     "wtProb": 5.1,
     "k198rScore": -12.3743,
     "k198rProb": 6.7,
     "probDifference": 1.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1971
    },
    {
     "sequence": "DDENRQLTLPEEDKR",
     "pos": 1952,
     "wtScore": 3.20688,
     "wtProb": 8.7,
     "k198rScore": -7.64313,
     "k198rProb": 7.1,
     "probDifference": -1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1972
    },
    {
     "sequence": "DREPQRRSSVKRTRY",
     "pos": 1798,
     "wtScore": 50.5472,
     "wtProb": 10.9,
     "k198rScore": 39.7772,
     "k198rProb": 9.4,
     "probDifference": -1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1973
    },
    {
     "sequence": "ITFFTEQTTTPWIIF",
     "pos": 170,
     "wtScore": -15.8491,
     "wtProb": 6.6,
     "k198rScore": -31.94,
     "k198rProb": 5,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 1974
    },
    {
     "sequence": "FPSIHCGSWAETTPG",
     "pos": 2076,
     "wtScore": 98.1755,
     "wtProb": 31,
     "k198rScore": 74.6788,
     "k198rProb": 29.5,
     "probDifference": -1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1975
    },
    {
     "sequence": "ESEPSNSTEGETPCG",
     "pos": 1488,
     "wtScore": 27.7974,
     "wtProb": 8.4,
     "k198rScore": -9.36462,
     "k198rProb": 6.8,
     "probDifference": -1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 1976
    },
    {
     "sequence": "NFVTAMLTKLKFEVF",
     "pos": 557,
     "wtScore": -14.4771,
     "wtProb": 6.8,
     "k198rScore": -29.2136,
     "k198rProb": 5.3,
     "probDifference": -1.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 1977
    },
    {
     "sequence": "ADAESLNTAQKEEAE",
     "pos": 762,
     "wtScore": -11.2968,
     "wtProb": 7.4,
     "k198rScore": -25.4901,
     "k198rProb": 5.8,
     "probDifference": -1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1978
    },
    {
     "sequence": "LLTKGRRTASVRADT",
     "pos": 549,
     "wtScore": -6.37182,
     "wtProb": 8.2,
     "k198rScore": -19.9424,
     "k198rProb": 6.6,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 1979
    },
    {
     "sequence": "MAYGGPSSSGMIVCI",
     "pos": 722,
     "wtScore": 48.3778,
     "wtProb": 10.2,
     "k198rScore": 37.2211,
     "k198rProb": 8.6,
     "probDifference": -1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1980
    },
    {
     "sequence": "KFSLKTDTGDTVPDR",
     "pos": 946,
     "wtScore": 3.66995,
     "wtProb": 8.7,
     "k198rScore": -7.61185,
     "k198rProb": 7.2,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1981
    },
    {
     "sequence": "NQGNVLATSLRSLRF",
     "pos": 223,
     "wtScore": -17.1836,
     "wtProb": 6.4,
     "k198rScore": -36.8181,
     "k198rProb": 4.9,
     "probDifference": -1.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 1982
    },
    {
     "sequence": "KAQKYSPSHSTRSWA",
     "pos": 1974,
     "wtScore": 52.2408,
     "wtProb": 11.7,
     "k198rScore": 46.9542,
     "k198rProb": 13.3,
     "probDifference": 1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1983
    },
    {
     "sequence": "ECKALIESNQTARWK",
     "pos": 1391,
     "wtScore": 57.2292,
     "wtProb": 14.8,
     "k198rScore": 47.1007,
     "k198rProb": 13.3,
     "probDifference": -1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1984
    },
    {
     "sequence": "LSNPPDWTKNVEYTF",
     "pos": 153,
     "wtScore": -9.79384,
     "wtProb": 7.7,
     "k198rScore": -23.8492,
     "k198rProb": 6.1,
     "probDifference": -1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1985
    },
    {
     "sequence": "SLLWAIVTVFQILTQ",
     "pos": 965,
     "wtScore": -13.2366,
     "wtProb": 7,
     "k198rScore": -27.4887,
     "k198rProb": 5.5,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1986
    },
    {
     "sequence": "LKDDKDFTGTVHRID",
     "pos": 198,
     "wtScore": -5.26551,
     "wtProb": 8.3,
     "k198rScore": -16.2779,
     "k198rProb": 6.8,
     "probDifference": -1.5,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1987
    },
    {
     "sequence": "QKCYLGDTWNRLDFF",
     "pos": 169,
     "wtScore": -10.0325,
     "wtProb": 7.6,
     "k198rScore": -24.0152,
     "k198rProb": 6.1,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 1988
    },
    {
     "sequence": "FDAILYYYQSGGRLR",
     "pos": 95,
     "wtScore": -37.561,
     "wtProb": 5.6,
     "k198rScore": -7.83548,
     "k198rProb": 7.1,
     "probDifference": 1.5,
     "geneName": "KCNA1",
     "known": "No",
     "id": 1989
    },
    {
     "sequence": "FDAILYYYQSGGRLR",
     "pos": 91,
     "wtScore": -37.561,
     "wtProb": 5.6,
     "k198rScore": -7.83548,
     "k198rProb": 7.1,
     "probDifference": 1.5,
     "geneName": "KCNA2",
     "known": "No",
     "id": 1990
    },
    {
     "sequence": "MKLSDGSYFGEICLL",
     "pos": 605,
     "wtScore": -38.3629,
     "wtProb": 5.5,
     "k198rScore": -8.2563,
     "k198rProb": 7,
     "probDifference": 1.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 1991
    },
    {
     "sequence": "KNVEYTFTGIYTFES",
     "pos": 161,
     "wtScore": -7.83587,
     "wtProb": 8,
     "k198rScore": -21.4932,
     "k198rProb": 6.5,
     "probDifference": -1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 1992
    },
    {
     "sequence": "KNVEYTFTGIYTFES",
     "pos": 165,
     "wtScore": -7.83587,
     "wtProb": 8,
     "k198rScore": -21.4932,
     "k198rProb": 6.5,
     "probDifference": -1.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1993
    },
    {
     "sequence": "KNVEYTFTGIYTFES",
     "pos": 162,
     "wtScore": -7.83587,
     "wtProb": 8,
     "k198rScore": -21.4932,
     "k198rProb": 6.5,
     "probDifference": -1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 1994
    },
    {
     "sequence": "KNVEYTFTGIYTFES",
     "pos": 161,
     "wtScore": -7.83587,
     "wtProb": 8,
     "k198rScore": -21.4932,
     "k198rProb": 6.5,
     "probDifference": -1.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 1995
    },
    {
     "sequence": "RHPGAFTSILNFYRT",
     "pos": 97,
     "wtScore": 44.757,
     "wtProb": 9.3,
     "k198rScore": 31.1805,
     "k198rProb": 7.7,
     "probDifference": -1.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 1996
    },
    {
     "sequence": "RHPGAFTSILNFYRT",
     "pos": 93,
     "wtScore": 44.757,
     "wtProb": 9.3,
     "k198rScore": 31.1805,
     "k198rProb": 7.7,
     "probDifference": -1.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 1997
    },
    {
     "sequence": "TFTPASLTVPSSFRN",
     "pos": 2041,
     "wtScore": -13.9105,
     "wtProb": 6.9,
     "k198rScore": -28.387,
     "k198rProb": 5.4,
     "probDifference": -1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 1998
    },
    {
     "sequence": "ALRHYYFTIGWNIFD",
     "pos": 1581,
     "wtScore": -6.66022,
     "wtProb": 8.2,
     "k198rScore": -20.1384,
     "k198rProb": 6.6,
     "probDifference": -1.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 1999
    },
    {
     "sequence": "PAGDDEVTVGKFYAT",
     "pos": 1601,
     "wtScore": -7.86391,
     "wtProb": 8,
     "k198rScore": -21.5068,
     "k198rProb": 6.5,
     "probDifference": -1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2000
    },
    {
     "sequence": "PAGDDEVTVGKFYAT",
     "pos": 1661,
     "wtScore": -7.86391,
     "wtProb": 8,
     "k198rScore": -21.5068,
     "k198rProb": 6.5,
     "probDifference": -1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2001
    },
    {
     "sequence": "DHEELERSFSGFSIS",
     "pos": 800,
     "wtScore": 57.9936,
     "wtProb": 15.4,
     "k198rScore": 51.3972,
     "k198rProb": 16.9,
     "probDifference": 1.5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2002
    },
    {
     "sequence": "YTSFDTFSWAFLALF",
     "pos": 356,
     "wtScore": 47.6817,
     "wtProb": 10,
     "k198rScore": 36.5076,
     "k198rProb": 8.4,
     "probDifference": -1.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2003
    },
    {
     "sequence": "WGPSGAWSSRRSSWS",
     "pos": 1138,
     "wtScore": 48.2927,
     "wtProb": 10.1,
     "k198rScore": 37.2217,
     "k198rProb": 8.6,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2004
    },
    {
     "sequence": "GKSLPFIYGDIPPEM",
     "pos": 65,
     "wtScore": -38.5917,
     "wtProb": 5.4,
     "k198rScore": -8.47634,
     "k198rProb": 7,
     "probDifference": 1.5,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 2005
    },
    {
     "sequence": "IDSEVYKTARALRIV",
     "pos": 318,
     "wtScore": -6.0777,
     "wtProb": 8.2,
     "k198rScore": -14.817,
     "k198rProb": 6.7,
     "probDifference": -1.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 2006
    },
    {
     "sequence": "HSNLHSPSPSTQTPQ",
     "pos": 681,
     "wtScore": 51.0681,
     "wtProb": 11.2,
     "k198rScore": 40.4734,
     "k198rProb": 9.6,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 2007
    },
    {
     "sequence": "SNPSKVSYQPITTTL",
     "pos": 1902,
     "wtScore": -41.2166,
     "wtProb": 5.1,
     "k198rScore": -11.2792,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2008
    },
    {
     "sequence": "ISNIVFTSMFALEML",
     "pos": 833,
     "wtScore": 43.4507,
     "wtProb": 9,
     "k198rScore": 26.2521,
     "k198rProb": 7.5,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2009
    },
    {
     "sequence": "KECVCKISNDCELPR",
     "pos": 914,
     "wtScore": 71.1107,
     "wtProb": 22.8,
     "k198rScore": 59.481,
     "k198rProb": 21.3,
     "probDifference": -1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2010
    },
    {
     "sequence": "GLQAGGRSTVPQRVT",
     "pos": 832,
     "wtScore": 50.6233,
     "wtProb": 11,
     "k198rScore": 39.9232,
     "k198rProb": 9.5,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 2011
    },
    {
     "sequence": "LLLHPNAYVRNGWNL",
     "pos": 191,
     "wtScore": -40.8453,
     "wtProb": 5.2,
     "k198rScore": -10.7975,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2012
    },
    {
     "sequence": "PINYPQMTTLNSTSS",
     "pos": 644,
     "wtScore": -16.9992,
     "wtProb": 6.4,
     "k198rScore": -34.535,
     "k198rProb": 4.9,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 2013
    },
    {
     "sequence": "TEMRKRRSSSFHVSM",
     "pos": 693,
     "wtScore": 44.0396,
     "wtProb": 9.1,
     "k198rScore": 28.9754,
     "k198rProb": 7.6,
     "probDifference": -1.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2014
    },
    {
     "sequence": "KTDGSSSTTSPPSYD",
     "pos": 1963,
     "wtScore": -16.6889,
     "wtProb": 6.5,
     "k198rScore": -33.3288,
     "k198rProb": 5,
     "probDifference": -1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2015
    },
    {
     "sequence": "TPLGSVASVHSPPAE",
     "pos": 1966,
     "wtScore": 53.4928,
     "wtProb": 12.5,
     "k198rScore": 43.1953,
     "k198rProb": 11,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2016
    },
    {
     "sequence": "IGDDSHFYVLDGQKD",
     "pos": 325,
     "wtScore": -39.4881,
     "wtProb": 5.3,
     "k198rScore": -9.43218,
     "k198rProb": 6.8,
     "probDifference": 1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2017
    },
    {
     "sequence": "TLFALVRTALRIKTE",
     "pos": 1615,
     "wtScore": -14.1207,
     "wtProb": 6.9,
     "k198rScore": -28.6139,
     "k198rProb": 5.3,
     "probDifference": -1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2018
    },
    {
     "sequence": "LLFHPNAYLRNGWNL",
     "pos": 189,
     "wtScore": -41.3074,
     "wtProb": 5.1,
     "k198rScore": -11.4964,
     "k198rProb": 6.6,
     "probDifference": 1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2019
    },
    {
     "sequence": "TSSSEGSTVDVVLPR",
     "pos": 1142,
     "wtScore": -12.4866,
     "wtProb": 7.1,
     "k198rScore": -26.7157,
     "k198rProb": 5.6,
     "probDifference": -1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2020
    },
    {
     "sequence": "AGQTMCLTVFMMVMV",
     "pos": 960,
     "wtScore": -15.9182,
     "wtProb": 6.6,
     "k198rScore": -31.5981,
     "k198rProb": 5.1,
     "probDifference": -1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2021
    },
    {
     "sequence": "NSIMSVVTNTLVEEL",
     "pos": 704,
     "wtScore": -13.8056,
     "wtProb": 6.9,
     "k198rScore": -28.1471,
     "k198rProb": 5.4,
     "probDifference": -1.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2022
    },
    {
     "sequence": "ILFICGNYILLNVFL",
     "pos": 741,
     "wtScore": -41.1966,
     "wtProb": 5.1,
     "k198rScore": -11.3726,
     "k198rProb": 6.6,
     "probDifference": 1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2023
    },
    {
     "sequence": "ILFICGNYILLNVFL",
     "pos": 740,
     "wtScore": -41.1966,
     "wtProb": 5.1,
     "k198rScore": -11.3726,
     "k198rProb": 6.6,
     "probDifference": 1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2024
    },
    {
     "sequence": "MTKHGSRSTSSLPPE",
     "pos": 11,
     "wtScore": 46.6559,
     "wtProb": 9.7,
     "k198rScore": 35.0185,
     "k198rProb": 8.2,
     "probDifference": -1.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2025
    },
    {
     "sequence": "VSSIPVDYIFLIVEK",
     "pos": 302,
     "wtScore": -39.624,
     "wtProb": 5.3,
     "k198rScore": -9.71229,
     "k198rProb": 6.8,
     "probDifference": 1.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 2026
    },
    {
     "sequence": "TDMTPSTTSPPSYDS",
     "pos": 1969,
     "wtScore": -16.099,
     "wtProb": 6.5,
     "k198rScore": -31.8574,
     "k198rProb": 5,
     "probDifference": -1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2027
    },
    {
     "sequence": "PDLKKSRSASTISKS",
     "pos": 448,
     "wtScore": 51.0373,
     "wtProb": 11.1,
     "k198rScore": 46.0102,
     "k198rProb": 12.6,
     "probDifference": 1.5,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 2028
    },
    {
     "sequence": "ALPRESSSVLNTDPD",
     "pos": 872,
     "wtScore": 50.4686,
     "wtProb": 10.9,
     "k198rScore": 39.7839,
     "k198rProb": 9.4,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 2029
    },
    {
     "sequence": "SPPVYTATSLSHSNL",
     "pos": 670,
     "wtScore": -16.6207,
     "wtProb": 6.5,
     "k198rScore": -32.8636,
     "k198rProb": 5,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 2030
    },
    {
     "sequence": "MDVIEQYSAGHLDML",
     "pos": 571,
     "wtScore": 54.0399,
     "wtProb": 12.8,
     "k198rScore": 48.4837,
     "k198rProb": 14.3,
     "probDifference": 1.5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2031
    },
    {
     "sequence": "TGSSVEKYIIDESDY",
     "pos": 1094,
     "wtScore": -38.479,
     "wtProb": 5.5,
     "k198rScore": -8.5121,
     "k198rProb": 7,
     "probDifference": 1.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2032
    },
    {
     "sequence": "YGDKTPKTWEGRLIA",
     "pos": 325,
     "wtScore": -14.3807,
     "wtProb": 6.8,
     "k198rScore": -28.8285,
     "k198rProb": 5.3,
     "probDifference": -1.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2033
    },
    {
     "sequence": "FSVIVMAYVTEFVSL",
     "pos": 201,
     "wtScore": -40.5312,
     "wtProb": 5.2,
     "k198rScore": -10.5478,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2034
    },
    {
     "sequence": "GVLSGEFSKEREKAK",
     "pos": 408,
     "wtScore": 63.9442,
     "wtProb": 19.4,
     "k198rScore": 52.6164,
     "k198rProb": 17.9,
     "probDifference": -1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2035
    },
    {
     "sequence": "GVLSGEFSKEREKAK",
     "pos": 409,
     "wtScore": 63.9442,
     "wtProb": 19.4,
     "k198rScore": 52.6164,
     "k198rProb": 17.9,
     "probDifference": -1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2036
    },
    {
     "sequence": "ILIHSVFSMIIMCTI",
     "pos": 134,
     "wtScore": 47.126,
     "wtProb": 9.8,
     "k198rScore": 35.9392,
     "k198rProb": 8.3,
     "probDifference": -1.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2037
    },
    {
     "sequence": "RPAALPPTKCRDRDG",
     "pos": 1211,
     "wtScore": -14.6915,
     "wtProb": 6.8,
     "k198rScore": -29.2406,
     "k198rProb": 5.3,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2038
    },
    {
     "sequence": "EALKYCNYVFTIVFV",
     "pos": 1653,
     "wtScore": -40.2631,
     "wtProb": 5.2,
     "k198rScore": -10.3285,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2039
    },
    {
     "sequence": "CTLANTNYVNITKML",
     "pos": 488,
     "wtScore": -40.9707,
     "wtProb": 5.1,
     "k198rScore": -11.0857,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 2040
    },
    {
     "sequence": "ESLYMYLYFVIFIIF",
     "pos": 1461,
     "wtScore": -41.1746,
     "wtProb": 5.1,
     "k198rScore": -11.5146,
     "k198rProb": 6.6,
     "probDifference": 1.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2041
    },
    {
     "sequence": "KAGRNPNYGYTSFDT",
     "pos": 360,
     "wtScore": -40.6284,
     "wtProb": 5.2,
     "k198rScore": -10.7687,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2042
    },
    {
     "sequence": "KAGRNPNYGYTSFDT",
     "pos": 347,
     "wtScore": -40.6284,
     "wtProb": 5.2,
     "k198rScore": -10.7687,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2043
    },
    {
     "sequence": "KAGRNPNYGYTSFDT",
     "pos": 361,
     "wtScore": -40.6284,
     "wtProb": 5.2,
     "k198rScore": -10.7687,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2044
    },
    {
     "sequence": "KAGRNPNYGYTSFDT",
     "pos": 359,
     "wtScore": -40.6284,
     "wtProb": 5.2,
     "k198rScore": -10.7687,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2045
    },
    {
     "sequence": "TKQVFDISIMILICL",
     "pos": 1535,
     "wtScore": 45.3287,
     "wtProb": 9.4,
     "k198rScore": 32.8097,
     "k198rProb": 7.9,
     "probDifference": -1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2046
    },
    {
     "sequence": "FREENLKTANCTLAN",
     "pos": 478,
     "wtScore": -13.1345,
     "wtProb": 7.1,
     "k198rScore": -27.2305,
     "k198rProb": 5.5,
     "probDifference": -1.5,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2047
    },
    {
     "sequence": "NVPVPTATPGNSEES",
     "pos": 1302,
     "wtScore": -13.1512,
     "wtProb": 7,
     "k198rScore": -27.2506,
     "k198rProb": 5.5,
     "probDifference": -1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2048
    },
    {
     "sequence": "FSEEIKFYELGEEAM",
     "pos": 119,
     "wtScore": -38.9213,
     "wtProb": 5.4,
     "k198rScore": -8.93983,
     "k198rProb": 6.9,
     "probDifference": 1.5,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2049
    },
    {
     "sequence": "KSFQALPYVALLIVM",
     "pos": 1409,
     "wtScore": -38.9465,
     "wtProb": 5.4,
     "k198rScore": -9.00026,
     "k198rProb": 6.9,
     "probDifference": 1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2050
    },
    {
     "sequence": "PKFIEVTTQELADAC",
     "pos": 2142,
     "wtScore": -14.9179,
     "wtProb": 6.7,
     "k198rScore": -29.5177,
     "k198rProb": 5.2,
     "probDifference": -1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2051
    },
    {
     "sequence": "QPQTPGSSTPKNEVH",
     "pos": 760,
     "wtScore": 45.8555,
     "wtProb": 9.5,
     "k198rScore": 33.8427,
     "k198rProb": 8,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 2052
    },
    {
     "sequence": "EKFDPDATQFIEYCK",
     "pos": 1799,
     "wtScore": -5.74172,
     "wtProb": 8.3,
     "k198rScore": -18.4847,
     "k198rProb": 6.8,
     "probDifference": -1.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2053
    },
    {
     "sequence": "ALLGAIPSIMNVLLV",
     "pos": 1335,
     "wtScore": 43.8687,
     "wtProb": 9.1,
     "k198rScore": 29.1915,
     "k198rProb": 7.6,
     "probDifference": -1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2054
    },
    {
     "sequence": "ALLGAIPSIMNVLLV",
     "pos": 1345,
     "wtScore": 43.8687,
     "wtProb": 9.1,
     "k198rScore": 29.1915,
     "k198rProb": 7.6,
     "probDifference": -1.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2055
    },
    {
     "sequence": "NCRKLVASMPLFANA",
     "pos": 540,
     "wtScore": 52.5661,
     "wtProb": 11.9,
     "k198rScore": 42.1181,
     "k198rProb": 10.4,
     "probDifference": -1.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 2056
    },
    {
     "sequence": "KAGESANTKDSADDN",
     "pos": 477,
     "wtScore": -6.49761,
     "wtProb": 8.2,
     "k198rScore": -19.4851,
     "k198rProb": 6.7,
     "probDifference": -1.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2057
    },
    {
     "sequence": "TPGPGPGSPQRRAQQ",
     "pos": 757,
     "wtScore": 58.9233,
     "wtProb": 16.1,
     "k198rScore": 48.8248,
     "k198rProb": 14.6,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2058
    },
    {
     "sequence": "GHPQDGSYPRQADHD",
     "pos": 23,
     "wtScore": -40.819,
     "wtProb": 5.2,
     "k198rScore": -13.3313,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2059
    },
    {
     "sequence": "RRKQEEVSAVVLQRA",
     "pos": 1897,
     "wtScore": 71.2164,
     "wtProb": 22.9,
     "k198rScore": 59.1343,
     "k198rProb": 21.4,
     "probDifference": -1.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2060
    },
    {
     "sequence": "RDCNTHDSLLEVCDD",
     "pos": 67,
     "wtScore": 89.5201,
     "wtProb": 27.6,
     "k198rScore": 74.4051,
     "k198rProb": 29.1,
     "probDifference": 1.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2061
    },
    {
     "sequence": "QSSEDAGTGDPMAED",
     "pos": 491,
     "wtScore": -4.51483,
     "wtProb": 8.4,
     "k198rScore": -8.96195,
     "k198rProb": 6.9,
     "probDifference": -1.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2062
    },
    {
     "sequence": "LQAGGRSTVPQRVTL",
     "pos": 833,
     "wtScore": -12.4716,
     "wtProb": 7.2,
     "k198rScore": -26.5578,
     "k198rProb": 5.7,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 2063
    },
    {
     "sequence": "WATITMTTVGYGDIY",
     "pos": 376,
     "wtScore": -15.8288,
     "wtProb": 6.6,
     "k198rScore": -31.0293,
     "k198rProb": 5.1,
     "probDifference": -1.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2064
    },
    {
     "sequence": "WATITMTTVGYGDIY",
     "pos": 380,
     "wtScore": -15.8288,
     "wtProb": 6.6,
     "k198rScore": -31.0293,
     "k198rProb": 5.1,
     "probDifference": -1.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2065
    },
    {
     "sequence": "GKYPAKNTTIALQAG",
     "pos": 1635,
     "wtScore": -14.5238,
     "wtProb": 6.8,
     "k198rScore": -28.8439,
     "k198rProb": 5.3,
     "probDifference": -1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2066
    },
    {
     "sequence": "GYGDMVPTTIGGKIV",
     "pos": 382,
     "wtScore": -13.3089,
     "wtProb": 7,
     "k198rScore": -27.2273,
     "k198rProb": 5.5,
     "probDifference": -1.5,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2067
    },
    {
     "sequence": "VGSGKGSTSPGPKGK",
     "pos": 627,
     "wtScore": -17.5621,
     "wtProb": 6.4,
     "k198rScore": -35.8371,
     "k198rProb": 4.9,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2068
    },
    {
     "sequence": "NLPRGGPSSGKVQAT",
     "pos": 725,
     "wtScore": 64.4117,
     "wtProb": 19.8,
     "k198rScore": 53.0331,
     "k198rProb": 18.3,
     "probDifference": -1.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2069
    },
    {
     "sequence": "DEEVSHITSSACPWQ",
     "pos": 2104,
     "wtScore": -11.0607,
     "wtProb": 7.4,
     "k198rScore": -24.779,
     "k198rProb": 5.9,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2070
    },
    {
     "sequence": "PKYEDNLYMYLYFVI",
     "pos": 1447,
     "wtScore": -40.5061,
     "wtProb": 5.2,
     "k198rScore": -10.9041,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2071
    },
    {
     "sequence": "KYMTLVLSRINLVFI",
     "pos": 1558,
     "wtScore": 49.6779,
     "wtProb": 10.6,
     "k198rScore": 39.0351,
     "k198rProb": 9.1,
     "probDifference": -1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2072
    },
    {
     "sequence": "AMGIMIFSSLVFFAE",
     "pos": 345,
     "wtScore": 45.5868,
     "wtProb": 9.4,
     "k198rScore": 33.5637,
     "k198rProb": 8,
     "probDifference": -1.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2073
    },
    {
     "sequence": "AMGIMIFSSLVFFAE",
     "pos": 349,
     "wtScore": 45.5868,
     "wtProb": 9.4,
     "k198rScore": 33.5637,
     "k198rProb": 8,
     "probDifference": -1.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2074
    },
    {
     "sequence": "ANSDNKVTIDDYREE",
     "pos": 803,
     "wtScore": -5.34546,
     "wtProb": 8.3,
     "k198rScore": -17.3979,
     "k198rProb": 6.8,
     "probDifference": -1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2075
    },
    {
     "sequence": "EKFDPDATQFIEFAK",
     "pos": 1809,
     "wtScore": -7.67704,
     "wtProb": 8,
     "k198rScore": -20.8148,
     "k198rProb": 6.6,
     "probDifference": -1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2076
    },
    {
     "sequence": "TGIYTFESLIKILAR",
     "pos": 168,
     "wtScore": 37.954,
     "wtProb": 8.5,
     "k198rScore": 41.2877,
     "k198rProb": 10,
     "probDifference": 1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2077
    },
    {
     "sequence": "TGIYTFESLIKILAR",
     "pos": 169,
     "wtScore": 37.954,
     "wtProb": 8.5,
     "k198rScore": 41.2877,
     "k198rProb": 10,
     "probDifference": 1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2078
    },
    {
     "sequence": "RDWTPCYTPLIQVEQ",
     "pos": 1997,
     "wtScore": -16.68,
     "wtProb": 6.5,
     "k198rScore": -32.4236,
     "k198rProb": 5,
     "probDifference": -1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2079
    },
    {
     "sequence": "FGNHVSYYQSDGRSA",
     "pos": 1757,
     "wtScore": -40.8914,
     "wtProb": 5.2,
     "k198rScore": -11.6393,
     "k198rProb": 6.6,
     "probDifference": 1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2080
    },
    {
     "sequence": "GTTSGVGTGSSVEKY",
     "pos": 1075,
     "wtScore": -0.091601,
     "wtProb": 8.6,
     "k198rScore": -7.61954,
     "k198rProb": 7.2,
     "probDifference": -1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2081
    },
    {
     "sequence": "STIGYQQSTSFTDPF",
     "pos": 214,
     "wtScore": 43.798,
     "wtProb": 9.1,
     "k198rScore": 29.3517,
     "k198rProb": 7.6,
     "probDifference": -1.5,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2082
    },
    {
     "sequence": "VFRIFKLSRHSKGLQ",
     "pos": 307,
     "wtScore": 45.8212,
     "wtProb": 9.5,
     "k198rScore": 34.1028,
     "k198rProb": 8,
     "probDifference": -1.5,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2083
    },
    {
     "sequence": "VFRIFKLSRHSKGLQ",
     "pos": 305,
     "wtScore": 45.8212,
     "wtProb": 9.5,
     "k198rScore": 34.1028,
     "k198rProb": 8,
     "probDifference": -1.5,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2084
    },
    {
     "sequence": "DSERPRGYHHPQGFL",
     "pos": 1883,
     "wtScore": -37.9866,
     "wtProb": 5.5,
     "k198rScore": -8.36805,
     "k198rProb": 7,
     "probDifference": 1.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2085
    },
    {
     "sequence": "SEEELQDSRVYVSSL",
     "pos": 2213,
     "wtScore": 52.5845,
     "wtProb": 11.9,
     "k198rScore": 42.1625,
     "k198rProb": 10.4,
     "probDifference": -1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2086
    },
    {
     "sequence": "NINNANNTALGRLPR",
     "pos": 1820,
     "wtScore": -9.5665,
     "wtProb": 7.7,
     "k198rScore": -23.2073,
     "k198rProb": 6.2,
     "probDifference": -1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2087
    },
    {
     "sequence": "NRYSDLKTIICNYSE",
     "pos": 681,
     "wtScore": -15.485,
     "wtProb": 6.6,
     "k198rScore": -30.1306,
     "k198rProb": 5.2,
     "probDifference": -1.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2088
    },
    {
     "sequence": "DMLSRIKSLQSRVDQ",
     "pos": 583,
     "wtScore": 40.738,
     "wtProb": 8.7,
     "k198rScore": 41.774,
     "k198rProb": 10.2,
     "probDifference": 1.5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2089
    },
    {
     "sequence": "TGSSVEKYVIDENDY",
     "pos": 1082,
     "wtScore": -37.9462,
     "wtProb": 5.5,
     "k198rScore": -8.34303,
     "k198rProb": 7,
     "probDifference": 1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2090
    },
    {
     "sequence": "KPGSPSATPAPGGGA",
     "pos": 2341,
     "wtScore": -12.1417,
     "wtProb": 7.2,
     "k198rScore": -26.0661,
     "k198rProb": 5.7,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2091
    },
    {
     "sequence": "KFYHCINTTTGDRFD",
     "pos": 1378,
     "wtScore": -12.0115,
     "wtProb": 7.2,
     "k198rScore": -25.8659,
     "k198rProb": 5.8,
     "probDifference": -1.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2092
    },
    {
     "sequence": "VMDAHSFYNFIYFIL",
     "pos": 393,
     "wtScore": -40.059,
     "wtProb": 5.3,
     "k198rScore": -10.4227,
     "k198rProb": 6.7,
     "probDifference": 1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2093
    },
    {
     "sequence": "KQMENILYWINLVFV",
     "pos": 1554,
     "wtScore": -34.8147,
     "wtProb": 5.8,
     "k198rScore": -7.03343,
     "k198rProb": 7.3,
     "probDifference": 1.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2094
    },
    {
     "sequence": "VIVDMKSTSSIDSFT",
     "pos": 599,
     "wtScore": -17.832,
     "wtProb": 6.3,
     "k198rScore": -36.6122,
     "k198rProb": 4.9,
     "probDifference": -1.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2095
    },
    {
     "sequence": "HCGSWAETTPGGGGS",
     "pos": 2080,
     "wtScore": -12.4823,
     "wtProb": 7.1,
     "k198rScore": -26.4062,
     "k198rProb": 5.7,
     "probDifference": -1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2096
    },
    {
     "sequence": "SACPWQPTAEPHGPE",
     "pos": 2113,
     "wtScore": -11.6643,
     "wtProb": 7.3,
     "k198rScore": -25.4024,
     "k198rProb": 5.8,
     "probDifference": -1.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2097
    },
    {
     "sequence": "EEIFHMTYDLASAVM",
     "pos": 357,
     "wtScore": -28.8443,
     "wtProb": 5.8,
     "k198rScore": -6.896,
     "k198rProb": 7.3,
     "probDifference": 1.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 2098
    },
    {
     "sequence": "VLVIFLGSFYLVNLI",
     "pos": 411,
     "wtScore": 46.7438,
     "wtProb": 9.7,
     "k198rScore": 35.5166,
     "k198rProb": 8.2,
     "probDifference": -1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2099
    },
    {
     "sequence": "PQTPGSSTPKNEVHK",
     "pos": 761,
     "wtScore": -8.09912,
     "wtProb": 8,
     "k198rScore": -21.2486,
     "k198rProb": 6.5,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 2100
    },
    {
     "sequence": "LNNTSSSSPQHLSAQ",
     "pos": 530,
     "wtScore": 44.9583,
     "wtProb": 9.3,
     "k198rScore": 32.4921,
     "k198rProb": 7.8,
     "probDifference": -1.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2101
    },
    {
     "sequence": "FMQRQFTSMLQPGVN",
     "pos": 99,
     "wtScore": 45.2463,
     "wtProb": 9.4,
     "k198rScore": 33.0488,
     "k198rProb": 7.9,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 2102
    },
    {
     "sequence": "VKRNNAKYRRIQTLI",
     "pos": 103,
     "wtScore": -39.2041,
     "wtProb": 5.4,
     "k198rScore": -9.72198,
     "k198rProb": 6.8,
     "probDifference": 1.5,
     "geneName": "KCNQ3",
     "known": "Yes",
     "id": 2103
    },
    {
     "sequence": "PWNWLDFSVIVMAYV",
     "pos": 195,
     "wtScore": 48.9382,
     "wtProb": 10.3,
     "k198rScore": 38.2101,
     "k198rProb": 8.9,
     "probDifference": -1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2104
    },
    {
     "sequence": "TIALSLNTLPELQET",
     "pos": 213,
     "wtScore": -17.1628,
     "wtProb": 6.4,
     "k198rScore": -33.1673,
     "k198rProb": 5,
     "probDifference": -1.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2105
    },
    {
     "sequence": "QMTTLNSTSSTTTPT",
     "pos": 649,
     "wtScore": -17.4277,
     "wtProb": 6.4,
     "k198rScore": -33.8052,
     "k198rProb": 4.9,
     "probDifference": -1.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 2106
    },
    {
     "sequence": "ENHQGSNYGSPRPAH",
     "pos": 19,
     "wtScore": -36.4944,
     "wtProb": 5.7,
     "k198rScore": -7.74953,
     "k198rProb": 7.1,
     "probDifference": 1.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2107
    },
    {
     "sequence": "TDGSSSTTSPPSYDS",
     "pos": 1964,
     "wtScore": -15.5317,
     "wtProb": 6.6,
     "k198rScore": -30.0075,
     "k198rProb": 5.2,
     "probDifference": -1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2108
    },
    {
     "sequence": "LIFWLIFSIMGVNLF",
     "pos": 1349,
     "wtScore": 44.3335,
     "wtProb": 9.2,
     "k198rScore": 31.0017,
     "k198rProb": 7.7,
     "probDifference": -1.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2109
    },
    {
     "sequence": "LIFWLIFSIMGVNLF",
     "pos": 1341,
     "wtScore": 44.3335,
     "wtProb": 9.2,
     "k198rScore": 31.0017,
     "k198rProb": 7.7,
     "probDifference": -1.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2110
    },
    {
     "sequence": "LIFWLIFSIMGVNLF",
     "pos": 1351,
     "wtScore": 44.3335,
     "wtProb": 9.2,
     "k198rScore": 31.0017,
     "k198rProb": 7.7,
     "probDifference": -1.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2111
    },
    {
     "sequence": "LIFWLIFSIMGVNLF",
     "pos": 1361,
     "wtScore": 44.3335,
     "wtProb": 9.2,
     "k198rScore": 31.0017,
     "k198rProb": 7.7,
     "probDifference": -1.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2112
    },
    {
     "sequence": "LIKIIGNSVGALGNL",
     "pos": 876,
     "wtScore": 61.1643,
     "wtProb": 17.6,
     "k198rScore": 50.5216,
     "k198rProb": 16.2,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2113
    },
    {
     "sequence": "LIKIIGNSVGALGNL",
     "pos": 885,
     "wtScore": 61.1643,
     "wtProb": 17.6,
     "k198rScore": 50.5216,
     "k198rProb": 16.2,
     "probDifference": -1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2114
    },
    {
     "sequence": "LIKIIGNSVGALGNL",
     "pos": 870,
     "wtScore": 61.1643,
     "wtProb": 17.6,
     "k198rScore": 50.5216,
     "k198rProb": 16.2,
     "probDifference": -1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2115
    },
    {
     "sequence": "LIKIIGNSVGALGNL",
     "pos": 877,
     "wtScore": 61.1643,
     "wtProb": 17.6,
     "k198rScore": 50.5216,
     "k198rProb": 16.2,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2116
    },
    {
     "sequence": "FRLMTQDYWENLYQL",
     "pos": 370,
     "wtScore": -35.7508,
     "wtProb": 5.7,
     "k198rScore": -7.42078,
     "k198rProb": 7.2,
     "probDifference": 1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2117
    },
    {
     "sequence": "FRLMTQDYWENLYQL",
     "pos": 383,
     "wtScore": -35.7508,
     "wtProb": 5.7,
     "k198rScore": -7.42078,
     "k198rProb": 7.2,
     "probDifference": 1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2118
    },
    {
     "sequence": "ALVGAIPSIMNVLLV",
     "pos": 1333,
     "wtScore": 44.5208,
     "wtProb": 9.2,
     "k198rScore": 31.6488,
     "k198rProb": 7.8,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2119
    },
    {
     "sequence": "ALVGAIPSIMNVLLV",
     "pos": 1325,
     "wtScore": 44.5208,
     "wtProb": 9.2,
     "k198rScore": 31.6488,
     "k198rProb": 7.8,
     "probDifference": -1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2120
    },
    {
     "sequence": "GIQSSAISVVKILRV",
     "pos": 977,
     "wtScore": 51.4415,
     "wtProb": 11.3,
     "k198rScore": 40.9508,
     "k198rProb": 9.8,
     "probDifference": -1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2121
    },
    {
     "sequence": "HGDRWDPTRPPRATD",
     "pos": 742,
     "wtScore": -10.8237,
     "wtProb": 7.5,
     "k198rScore": -24.304,
     "k198rProb": 6,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2122
    },
    {
     "sequence": "TLFALVRTALKIKTE",
     "pos": 1555,
     "wtScore": -15.4371,
     "wtProb": 6.6,
     "k198rScore": -29.8071,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2123
    },
    {
     "sequence": "MQLFGKSYKECVCKI",
     "pos": 900,
     "wtScore": -40.4859,
     "wtProb": 5.2,
     "k198rScore": -11.3819,
     "k198rProb": 6.6,
     "probDifference": 1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2124
    },
    {
     "sequence": "MQLFGKSYKECVCKI",
     "pos": 906,
     "wtScore": -40.4859,
     "wtProb": 5.2,
     "k198rScore": -11.3819,
     "k198rProb": 6.6,
     "probDifference": 1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2125
    },
    {
     "sequence": "MQLFGKSYKECVCKI",
     "pos": 907,
     "wtScore": -40.4859,
     "wtProb": 5.2,
     "k198rScore": -11.3819,
     "k198rProb": 6.6,
     "probDifference": 1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2126
    },
    {
     "sequence": "HSSFFIESPKSSMKT",
     "pos": 655,
     "wtScore": 54.9936,
     "wtProb": 13.4,
     "k198rScore": 45.0465,
     "k198rProb": 12,
     "probDifference": -1.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2127
    },
    {
     "sequence": "ASLTVPSSFRNKNSD",
     "pos": 2045,
     "wtScore": 43.9267,
     "wtProb": 9.1,
     "k198rScore": 30.371,
     "k198rProb": 7.7,
     "probDifference": -1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2128
    },
    {
     "sequence": "TVEELEESRQKCPPC",
     "pos": 729,
     "wtScore": 52.5025,
     "wtProb": 11.9,
     "k198rScore": 47.0951,
     "k198rProb": 13.3,
     "probDifference": 1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2129
    },
    {
     "sequence": "HALSPRGTARSPSLS",
     "pos": 2002,
     "wtScore": -14.7426,
     "wtProb": 6.8,
     "k198rScore": -28.8109,
     "k198rProb": 5.3,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2130
    },
    {
     "sequence": "YGFVKFFTNAWCWLD",
     "pos": 1260,
     "wtScore": -16.0714,
     "wtProb": 6.6,
     "k198rScore": -30.7857,
     "k198rProb": 5.1,
     "probDifference": -1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2131
    },
    {
     "sequence": "DQSEYVTTILSRINL",
     "pos": 1570,
     "wtScore": -15.6587,
     "wtProb": 6.6,
     "k198rScore": -30.1343,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2132
    },
    {
     "sequence": "NLHSPSPSTQTPQPS",
     "pos": 683,
     "wtScore": 43.5096,
     "wtProb": 9,
     "k198rScore": 28.8916,
     "k198rProb": 7.6,
     "probDifference": -1.4,
     "geneName": "HCN1",
     "known": "No",
     "id": 2133
    },
    {
     "sequence": "RDSGSAGTQAHCLLL",
     "pos": 1883,
     "wtScore": -14.0083,
     "wtProb": 6.9,
     "k198rScore": -27.8629,
     "k198rProb": 5.5,
     "probDifference": -1.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2134
    },
    {
     "sequence": "PPGLNRKTSRSTLSL",
     "pos": 12,
     "wtScore": -16.4379,
     "wtProb": 6.5,
     "k198rScore": -31.2749,
     "k198rProb": 5.1,
     "probDifference": -1.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2135
    },
    {
     "sequence": "AWALYLFSPQNRFRV",
     "pos": 1277,
     "wtScore": 54.5498,
     "wtProb": 13.1,
     "k198rScore": 44.5135,
     "k198rProb": 11.7,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2136
    },
    {
     "sequence": "FGMSNFAYVKREVGI",
     "pos": 1693,
     "wtScore": -39.9751,
     "wtProb": 5.3,
     "k198rScore": -10.7223,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2137
    },
    {
     "sequence": "FGMSNFAYVKREVGI",
     "pos": 1683,
     "wtScore": -39.9751,
     "wtProb": 5.3,
     "k198rScore": -10.7223,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2138
    },
    {
     "sequence": "FFDRNRPSFDAILYY",
     "pos": 83,
     "wtScore": 50.8096,
     "wtProb": 11,
     "k198rScore": 40.4123,
     "k198rProb": 9.6,
     "probDifference": -1.4,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2139
    },
    {
     "sequence": "FFDRNRPSFDAILYY",
     "pos": 87,
     "wtScore": 50.8096,
     "wtProb": 11,
     "k198rScore": 40.4123,
     "k198rProb": 9.6,
     "probDifference": -1.4,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2140
    },
    {
     "sequence": "PPKRNAFYRKLQNFL",
     "pos": 73,
     "wtScore": -38.9841,
     "wtProb": 5.4,
     "k198rScore": -9.7009,
     "k198rProb": 6.8,
     "probDifference": 1.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2141
    },
    {
     "sequence": "SLRYYYFTIGWNIFD",
     "pos": 1590,
     "wtScore": -7.7384,
     "wtProb": 8,
     "k198rScore": -20.3834,
     "k198rProb": 6.6,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2142
    },
    {
     "sequence": "LYILEIVTIVVFGVE",
     "pos": 132,
     "wtScore": -14.1335,
     "wtProb": 6.9,
     "k198rScore": -28.0178,
     "k198rProb": 5.4,
     "probDifference": -1.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2143
    },
    {
     "sequence": "HREDDEDSKPKPNSD",
     "pos": 50,
     "wtScore": 64.5324,
     "wtProb": 19.8,
     "k198rScore": 53.307,
     "k198rProb": 18.4,
     "probDifference": -1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2144
    },
    {
     "sequence": "RIFKITRYWNSLSNL",
     "pos": 632,
     "wtScore": -40.2696,
     "wtProb": 5.2,
     "k198rScore": -11.0757,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2145
    },
    {
     "sequence": "QRLKNISSNYNKEAI",
     "pos": 1924,
     "wtScore": 56.6166,
     "wtProb": 14.5,
     "k198rScore": 46.6802,
     "k198rProb": 13.1,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2146
    },
    {
     "sequence": "SVMVILISIVSFCLE",
     "pos": 175,
     "wtScore": 45.6894,
     "wtProb": 9.5,
     "k198rScore": 34.1987,
     "k198rProb": 8,
     "probDifference": -1.4,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2147
    },
    {
     "sequence": "PAEPGEKTPVRPVTQ",
     "pos": 2043,
     "wtScore": -5.93936,
     "wtProb": 8.2,
     "k198rScore": -17.3973,
     "k198rProb": 6.8,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2148
    },
    {
     "sequence": "YPMTEQFSSVLSVGN",
     "pos": 787,
     "wtScore": 44.6643,
     "wtProb": 9.2,
     "k198rScore": 32.1601,
     "k198rProb": 7.8,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2149
    },
    {
     "sequence": "NGRLLAATFTLIGVS",
     "pos": 295,
     "wtScore": -14.5109,
     "wtProb": 6.8,
     "k198rScore": -28.3693,
     "k198rProb": 5.4,
     "probDifference": -1.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2150
    },
    {
     "sequence": "SLDEALKYCNYVFTI",
     "pos": 1650,
     "wtScore": -38.6911,
     "wtProb": 5.4,
     "k198rScore": -9.4349,
     "k198rProb": 6.8,
     "probDifference": 1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2151
    },
    {
     "sequence": "AVKSNVFYWLVIFLV",
     "pos": 526,
     "wtScore": -40.384,
     "wtProb": 5.2,
     "k198rScore": -11.796,
     "k198rProb": 6.6,
     "probDifference": 1.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2152
    },
    {
     "sequence": "SWATPPATPPYRDWT",
     "pos": 1986,
     "wtScore": -15.8556,
     "wtProb": 6.6,
     "k198rScore": -30.2771,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2153
    },
    {
     "sequence": "LHSPSPSTQTPQPSA",
     "pos": 684,
     "wtScore": -15.6775,
     "wtProb": 6.6,
     "k198rScore": -30.007,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "HCN1",
     "known": "No",
     "id": 2154
    },
    {
     "sequence": "SAMMALFTVSTFEGW",
     "pos": 1095,
     "wtScore": -17.2344,
     "wtProb": 6.4,
     "k198rScore": -32.6531,
     "k198rProb": 5,
     "probDifference": -1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2155
    },
    {
     "sequence": "AEMLLKMYSLGLQAY",
     "pos": 575,
     "wtScore": -37.6431,
     "wtProb": 5.6,
     "k198rScore": -8.3957,
     "k198rProb": 7,
     "probDifference": 1.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2156
    },
    {
     "sequence": "NILNMLFTGLFTVEM",
     "pos": 1278,
     "wtScore": -16.0384,
     "wtProb": 6.5,
     "k198rScore": -30.6301,
     "k198rProb": 5.1,
     "probDifference": -1.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2157
    },
    {
     "sequence": "DNLYMYLYFVIFIIF",
     "pos": 1451,
     "wtScore": -38.6947,
     "wtProb": 5.4,
     "k198rScore": -9.50257,
     "k198rProb": 6.8,
     "probDifference": 1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2158
    },
    {
     "sequence": "IVRSKACSRRVRLNV",
     "pos": 29,
     "wtScore": 48.8464,
     "wtProb": 10.3,
     "k198rScore": 38.1886,
     "k198rProb": 8.9,
     "probDifference": -1.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2159
    },
    {
     "sequence": "DRIGKKNSILLHKVQ",
     "pos": 667,
     "wtScore": 43.6818,
     "wtProb": 9.1,
     "k198rScore": 29.8813,
     "k198rProb": 7.6,
     "probDifference": -1.4,
     "geneName": "HCN2",
     "known": "Yes",
     "id": 2160
    },
    {
     "sequence": "VSYEPITTTLRRKQE",
     "pos": 1887,
     "wtScore": -16.0119,
     "wtProb": 6.6,
     "k198rScore": -30.5583,
     "k198rProb": 5.1,
     "probDifference": -1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2161
    },
    {
     "sequence": "VLVIFLGSFYLINLI",
     "pos": 412,
     "wtScore": 45.312,
     "wtProb": 9.4,
     "k198rScore": 33.5381,
     "k198rProb": 7.9,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2162
    },
    {
     "sequence": "VLVIFLGSFYLINLI",
     "pos": 410,
     "wtScore": 45.312,
     "wtProb": 9.4,
     "k198rScore": 33.5381,
     "k198rProb": 7.9,
     "probDifference": -1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2163
    },
    {
     "sequence": "SETSSSKSFETKEQG",
     "pos": 502,
     "wtScore": 59.7385,
     "wtProb": 16.7,
     "k198rScore": 49.7303,
     "k198rProb": 15.3,
     "probDifference": -1.4,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 2164
    },
    {
     "sequence": "LETILVETKIMSPLG",
     "pos": 607,
     "wtScore": -15.5965,
     "wtProb": 6.6,
     "k198rScore": -29.7882,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2165
    },
    {
     "sequence": "LSLFRLMTQDFWENL",
     "pos": 381,
     "wtScore": -16.2228,
     "wtProb": 6.5,
     "k198rScore": -30.7247,
     "k198rProb": 5.1,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2166
    },
    {
     "sequence": "LSLFRLMTQDFWENL",
     "pos": 379,
     "wtScore": -16.2228,
     "wtProb": 6.5,
     "k198rScore": -30.7247,
     "k198rProb": 5.1,
     "probDifference": -1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2167
    },
    {
     "sequence": "CEMLVKMYSLGLQAY",
     "pos": 574,
     "wtScore": -37.9288,
     "wtProb": 5.5,
     "k198rScore": -8.67653,
     "k198rProb": 6.9,
     "probDifference": 1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2168
    },
    {
     "sequence": "VSNFNYFYHRETEGE",
     "pos": 416,
     "wtScore": -39.5175,
     "wtProb": 5.3,
     "k198rScore": -10.3418,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 2169
    },
    {
     "sequence": "VSNFNYFYHRETEGE",
     "pos": 414,
     "wtScore": -39.5175,
     "wtProb": 5.3,
     "k198rScore": -10.3418,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2170
    },
    {
     "sequence": "FALFKAMSHMLCIGY",
     "pos": 422,
     "wtScore": 45.4281,
     "wtProb": 9.4,
     "k198rScore": 33.7829,
     "k198rProb": 8,
     "probDifference": -1.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 2171
    },
    {
     "sequence": "RFMASNPSKVSYEPI",
     "pos": 1883,
     "wtScore": 49.6402,
     "wtProb": 10.6,
     "k198rScore": 39.1789,
     "k198rProb": 9.1,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2172
    },
    {
     "sequence": "RFMASNPSKVSYEPI",
     "pos": 1888,
     "wtScore": 49.6402,
     "wtProb": 10.6,
     "k198rScore": 39.1789,
     "k198rProb": 9.1,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2173
    },
    {
     "sequence": "WNLRKTCYKIVEHNW",
     "pos": 1201,
     "wtScore": -39.6817,
     "wtProb": 5.3,
     "k198rScore": -10.4446,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2174
    },
    {
     "sequence": "DSSKAQKYSPSHSTR",
     "pos": 1971,
     "wtScore": -40.2684,
     "wtProb": 5.2,
     "k198rScore": -11.5231,
     "k198rProb": 6.6,
     "probDifference": 1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2175
    },
    {
     "sequence": "GSVVYAHSKELVTAW",
     "pos": 228,
     "wtScore": 53.0961,
     "wtProb": 12.2,
     "k198rScore": 42.8446,
     "k198rProb": 10.8,
     "probDifference": -1.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2176
    },
    {
     "sequence": "PPPPQVTSAIATLQQ",
     "pos": 724,
     "wtScore": 50.5022,
     "wtProb": 10.9,
     "k198rScore": 40.151,
     "k198rProb": 9.5,
     "probDifference": -1.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 2177
    },
    {
     "sequence": "KSFQALPYVALLIAM",
     "pos": 1351,
     "wtScore": -38.6644,
     "wtProb": 5.4,
     "k198rScore": -9.54387,
     "k198rProb": 6.8,
     "probDifference": 1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2178
    },
    {
     "sequence": "TLDRLPRTRLGKLRD",
     "pos": 58,
     "wtScore": -15.9206,
     "wtProb": 6.6,
     "k198rScore": -30.2402,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2179
    },
    {
     "sequence": "TLDRLPRTRLGKLRD",
     "pos": 54,
     "wtScore": -15.9206,
     "wtProb": 6.6,
     "k198rScore": -30.2402,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2180
    },
    {
     "sequence": "VIFLGSFYLVNLILA",
     "pos": 413,
     "wtScore": -40.1683,
     "wtProb": 5.2,
     "k198rScore": -12.4183,
     "k198rProb": 6.6,
     "probDifference": 1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2181
    },
    {
     "sequence": "ENLYMYLYFVIFIIF",
     "pos": 1446,
     "wtScore": -38.3357,
     "wtProb": 5.5,
     "k198rScore": -9.04894,
     "k198rProb": 6.9,
     "probDifference": 1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2182
    },
    {
     "sequence": "RMDRRGGTWKLLGSV",
     "pos": 216,
     "wtScore": -14.3791,
     "wtProb": 6.8,
     "k198rScore": -28.1296,
     "k198rProb": 5.4,
     "probDifference": -1.4,
     "geneName": "KCNQ2",
     "known": "Yes",
     "id": 2183
    },
    {
     "sequence": "GPPFLTLSREKGPAA",
     "pos": 654,
     "wtScore": 52.3854,
     "wtProb": 11.8,
     "k198rScore": 42.1365,
     "k198rProb": 10.4,
     "probDifference": -1.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2184
    },
    {
     "sequence": "SAPQTPPSTARPLPV",
     "pos": 731,
     "wtScore": 42.3739,
     "wtProb": 8.9,
     "k198rScore": 24.9582,
     "k198rProb": 7.5,
     "probDifference": -1.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2185
    },
    {
     "sequence": "LALFRLMTQDYWENL",
     "pos": 367,
     "wtScore": -16.6943,
     "wtProb": 6.5,
     "k198rScore": -31.3637,
     "k198rProb": 5.1,
     "probDifference": -1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2186
    },
    {
     "sequence": "TTFNRTVSIFNWDEY",
     "pos": 311,
     "wtScore": 47.6998,
     "wtProb": 10,
     "k198rScore": 37.0605,
     "k198rProb": 8.6,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2187
    },
    {
     "sequence": "QYYERTVTVPMYSSQ",
     "pos": 367,
     "wtScore": -16.1669,
     "wtProb": 6.5,
     "k198rScore": -30.5003,
     "k198rProb": 5.1,
     "probDifference": -1.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2188
    },
    {
     "sequence": "HMTYDLASAVMRICN",
     "pos": 361,
     "wtScore": 46.3562,
     "wtProb": 9.6,
     "k198rScore": 43.2614,
     "k198rProb": 11,
     "probDifference": 1.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 2189
    },
    {
     "sequence": "SPNKWKWTKRTLSET",
     "pos": 490,
     "wtScore": -17.8781,
     "wtProb": 6.3,
     "k198rScore": -34.1628,
     "k198rProb": 4.9,
     "probDifference": -1.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2190
    },
    {
     "sequence": "METYGAGTPLGSVAS",
     "pos": 1959,
     "wtScore": -6.52236,
     "wtProb": 8.2,
     "k198rScore": -18.232,
     "k198rProb": 6.8,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2191
    },
    {
     "sequence": "TIALSLNTLPELQSL",
     "pos": 209,
     "wtScore": -15.9421,
     "wtProb": 6.6,
     "k198rScore": -30.2303,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2192
    },
    {
     "sequence": "HNPWMLLYFISFLLI",
     "pos": 1533,
     "wtScore": -39.5955,
     "wtProb": 5.3,
     "k198rScore": -10.6389,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2193
    },
    {
     "sequence": "KRKQEEVSAAIIQRN",
     "pos": 1902,
     "wtScore": 72.9556,
     "wtProb": 23.6,
     "k198rScore": 62.8011,
     "k198rProb": 22.2,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2194
    },
    {
     "sequence": "LSLFRLMTQDYWENL",
     "pos": 380,
     "wtScore": -16.4384,
     "wtProb": 6.5,
     "k198rScore": -30.7891,
     "k198rProb": 5.1,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2195
    },
    {
     "sequence": "KVSYEPITTTLKRKQ",
     "pos": 1891,
     "wtScore": -15.8825,
     "wtProb": 6.6,
     "k198rScore": -30.122,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2196
    },
    {
     "sequence": "KVSYEPITTTLKRKQ",
     "pos": 1896,
     "wtScore": -15.8825,
     "wtProb": 6.6,
     "k198rScore": -30.122,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2197
    },
    {
     "sequence": "DGFIVSLSLMELGLA",
     "pos": 833,
     "wtScore": 85.2441,
     "wtProb": 25.8,
     "k198rScore": 69.5955,
     "k198rProb": 24.4,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2198
    },
    {
     "sequence": "PTRRSIHSLCTSHYL",
     "pos": 1611,
     "wtScore": 39.2367,
     "wtProb": 8.6,
     "k198rScore": 41.2811,
     "k198rProb": 10,
     "probDifference": 1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2199
    },
    {
     "sequence": "TTFKEYETVSGDWLL",
     "pos": 149,
     "wtScore": -8.19609,
     "wtProb": 7.9,
     "k198rScore": -20.8265,
     "k198rProb": 6.6,
     "probDifference": -1.4,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2200
    },
    {
     "sequence": "RKRRLSSYQISMEML",
     "pos": 688,
     "wtScore": -39.9637,
     "wtProb": 5.3,
     "k198rScore": -11.2356,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2201
    },
    {
     "sequence": "FSEPGSCYEELLKYV",
     "pos": 456,
     "wtScore": -36.497,
     "wtProb": 5.7,
     "k198rScore": -7.98845,
     "k198rProb": 7.1,
     "probDifference": 1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2202
    },
    {
     "sequence": "PDSRRGSSSSGDPPL",
     "pos": 1107,
     "wtScore": 47.8919,
     "wtProb": 10,
     "k198rScore": 37.2896,
     "k198rProb": 8.6,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2203
    },
    {
     "sequence": "ALPARRLSRASRPLS",
     "pos": 813,
     "wtScore": 46.4609,
     "wtProb": 9.6,
     "k198rScore": 35.4225,
     "k198rProb": 8.2,
     "probDifference": -1.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 2204
    },
    {
     "sequence": "PEKIKKKYLRTWFVV",
     "pos": 285,
     "wtScore": -40.1282,
     "wtProb": 5.2,
     "k198rScore": -11.9419,
     "k198rProb": 6.6,
     "probDifference": 1.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 2205
    },
    {
     "sequence": "GPYSDRISPRQRRSI",
     "pos": 774,
     "wtScore": 45.682,
     "wtProb": 9.5,
     "k198rScore": 34.4383,
     "k198rProb": 8.1,
     "probDifference": -1.4,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2206
    },
    {
     "sequence": "DDSNATNSNLERVEY",
     "pos": 156,
     "wtScore": 104.103,
     "wtProb": 33.9,
     "k198rScore": 78.1864,
     "k198rProb": 35.2,
     "probDifference": 1.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2207
    },
    {
     "sequence": "FTPGPPSTPKHKKSQ",
     "pos": 578,
     "wtScore": -16.8277,
     "wtProb": 6.5,
     "k198rScore": -31.3941,
     "k198rProb": 5.1,
     "probDifference": -1.4,
     "geneName": "KCNQ3",
     "known": "Yes",
     "id": 2208
    },
    {
     "sequence": "DGWVNIMYDGLDAVG",
     "pos": 1510,
     "wtScore": -29.1027,
     "wtProb": 5.8,
     "k198rScore": -7.25816,
     "k198rProb": 7.2,
     "probDifference": 1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2209
    },
    {
     "sequence": "AAMMALFTVSTFEGW",
     "pos": 1129,
     "wtScore": -17.6238,
     "wtProb": 6.4,
     "k198rScore": -32.9388,
     "k198rProb": 5,
     "probDifference": -1.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2210
    },
    {
     "sequence": "ICLFQITTSAGWDGL",
     "pos": 1706,
     "wtScore": -15.4321,
     "wtProb": 6.6,
     "k198rScore": -29.3612,
     "k198rProb": 5.3,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2211
    },
    {
     "sequence": "ICLFQITTSAGWDGL",
     "pos": 1702,
     "wtScore": -15.4321,
     "wtProb": 6.6,
     "k198rScore": -29.3612,
     "k198rProb": 5.3,
     "probDifference": -1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2212
    },
    {
     "sequence": "ICLFQITTSAGWDGL",
     "pos": 1711,
     "wtScore": -15.4321,
     "wtProb": 6.6,
     "k198rScore": -29.3612,
     "k198rProb": 5.3,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2213
    },
    {
     "sequence": "ICLFQITTSAGWDGL",
     "pos": 1721,
     "wtScore": -15.4321,
     "wtProb": 6.6,
     "k198rScore": -29.3612,
     "k198rProb": 5.3,
     "probDifference": -1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2214
    },
    {
     "sequence": "SPIFPHRTALPLHLM",
     "pos": 1946,
     "wtScore": -17.3557,
     "wtProb": 6.4,
     "k198rScore": -32.309,
     "k198rProb": 5,
     "probDifference": -1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2215
    },
    {
     "sequence": "NVELQPKYEESLYMY",
     "pos": 1452,
     "wtScore": -38.0245,
     "wtProb": 5.5,
     "k198rScore": -8.90345,
     "k198rProb": 6.9,
     "probDifference": 1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2216
    },
    {
     "sequence": "STFTPSSYSSTGSNA",
     "pos": 1805,
     "wtScore": -40.0928,
     "wtProb": 5.2,
     "k198rScore": -11.5369,
     "k198rProb": 6.6,
     "probDifference": 1.4,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 2217
    },
    {
     "sequence": "GMVFDFVTRQVFDIS",
     "pos": 1523,
     "wtScore": -16.0088,
     "wtProb": 6.6,
     "k198rScore": -30.156,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2218
    },
    {
     "sequence": "GMVFDFVTRQVFDIS",
     "pos": 1538,
     "wtScore": -16.0088,
     "wtProb": 6.6,
     "k198rScore": -30.156,
     "k198rProb": 5.2,
     "probDifference": -1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2219
    },
    {
     "sequence": "INNKTNFYTVPGMLE",
     "pos": 312,
     "wtScore": -38.9705,
     "wtProb": 5.4,
     "k198rScore": -10.0178,
     "k198rProb": 6.8,
     "probDifference": 1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2220
    },
    {
     "sequence": "TLEEIEMSAALPINP",
     "pos": 1703,
     "wtScore": 65.5348,
     "wtProb": 20.4,
     "k198rScore": 54.3303,
     "k198rProb": 19.1,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2221
    },
    {
     "sequence": "WGLITLTTIGYGDKY",
     "pos": 276,
     "wtScore": -17.9242,
     "wtProb": 6.3,
     "k198rScore": -33.7126,
     "k198rProb": 4.9,
     "probDifference": -1.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2222
    },
    {
     "sequence": "AISRFSATPALYILT",
     "pos": 105,
     "wtScore": -18.354,
     "wtProb": 6.3,
     "k198rScore": -35.2806,
     "k198rProb": 4.9,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2223
    },
    {
     "sequence": "MKLTDGSYFGEICLL",
     "pos": 536,
     "wtScore": -38.5381,
     "wtProb": 5.4,
     "k198rScore": -9.6,
     "k198rProb": 6.8,
     "probDifference": 1.4,
     "geneName": "HCN1",
     "known": "No",
     "id": 2224
    },
    {
     "sequence": "AAPSLPDSRRGSSSS",
     "pos": 1102,
     "wtScore": 45.7066,
     "wtProb": 9.5,
     "k198rScore": 34.563,
     "k198rProb": 8.1,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2225
    },
    {
     "sequence": "LLEKPNSSVAAKILA",
     "pos": 188,
     "wtScore": 51.6173,
     "wtProb": 11.4,
     "k198rScore": 41.2564,
     "k198rProb": 10,
     "probDifference": -1.4,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2226
    },
    {
     "sequence": "LLEKPNSSVAAKILA",
     "pos": 184,
     "wtScore": 51.6173,
     "wtProb": 11.4,
     "k198rScore": 41.2564,
     "k198rProb": 10,
     "probDifference": -1.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2227
    },
    {
     "sequence": "DFADDEHSTFEDSES",
     "pos": 598,
     "wtScore": 108.578,
     "wtProb": 34.8,
     "k198rScore": 78.9886,
     "k198rProb": 36.2,
     "probDifference": 1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2228
    },
    {
     "sequence": "TPEKTDMTPSTTSPP",
     "pos": 1965,
     "wtScore": -13.3202,
     "wtProb": 7,
     "k198rScore": -26.5543,
     "k198rProb": 5.7,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2229
    },
    {
     "sequence": "TIGYQQSTSFTDPFF",
     "pos": 215,
     "wtScore": -14.3648,
     "wtProb": 6.8,
     "k198rScore": -27.8205,
     "k198rProb": 5.5,
     "probDifference": -1.4,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2230
    },
    {
     "sequence": "GKAISRFSATPALYI",
     "pos": 103,
     "wtScore": 42.8098,
     "wtProb": 9,
     "k198rScore": 41.9911,
     "k198rProb": 10.3,
     "probDifference": 1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2231
    },
    {
     "sequence": "EGWTDVLYWVNDAVG",
     "pos": 369,
     "wtScore": -39.7446,
     "wtProb": 5.3,
     "k198rScore": -12.8238,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2232
    },
    {
     "sequence": "GSVASVHSPPAESCA",
     "pos": 1969,
     "wtScore": 50.1559,
     "wtProb": 10.8,
     "k198rScore": 39.7864,
     "k198rProb": 9.4,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2233
    },
    {
     "sequence": "ANPSPDASQHSSFFI",
     "pos": 646,
     "wtScore": 49.6294,
     "wtProb": 10.6,
     "k198rScore": 39.3068,
     "k198rProb": 9.2,
     "probDifference": -1.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2234
    },
    {
     "sequence": "DILNMVFTGVFTVEM",
     "pos": 1244,
     "wtScore": -9.38564,
     "wtProb": 7.7,
     "k198rScore": -22.269,
     "k198rProb": 6.4,
     "probDifference": -1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2235
    },
    {
     "sequence": "VSLVGGPSALTSPTG",
     "pos": 660,
     "wtScore": 54.1189,
     "wtProb": 12.8,
     "k198rScore": 44.0765,
     "k198rProb": 11.5,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2236
    },
    {
     "sequence": "SPRRNSKTSIFSFRG",
     "pos": 574,
     "wtScore": -15.0154,
     "wtProb": 6.7,
     "k198rScore": -28.6557,
     "k198rProb": 5.3,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2237
    },
    {
     "sequence": "YLKDVNGTTSGIGTG",
     "pos": 1081,
     "wtScore": -9.52796,
     "wtProb": 7.7,
     "k198rScore": -22.425,
     "k198rProb": 6.3,
     "probDifference": -1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2238
    },
    {
     "sequence": "FRFYWDFTMLLFMVG",
     "pos": 216,
     "wtScore": -14.3991,
     "wtProb": 6.8,
     "k198rScore": -27.8182,
     "k198rProb": 5.5,
     "probDifference": -1.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 2239
    },
    {
     "sequence": "DDQSQEMTNILYWIN",
     "pos": 1559,
     "wtScore": -11.0719,
     "wtProb": 7.4,
     "k198rScore": -24.1898,
     "k198rProb": 6.1,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2240
    },
    {
     "sequence": "QLFKGKFYRCTDEAK",
     "pos": 1041,
     "wtScore": -37.0465,
     "wtProb": 5.6,
     "k198rScore": -8.37987,
     "k198rProb": 7,
     "probDifference": 1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2241
    },
    {
     "sequence": "PPDCDPDTIHPGSSV",
     "pos": 1729,
     "wtScore": -7.4439,
     "wtProb": 8.1,
     "k198rScore": -19.2429,
     "k198rProb": 6.7,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2242
    },
    {
     "sequence": "YADKVFTYIFILEML",
     "pos": 1248,
     "wtScore": -38.1956,
     "wtProb": 5.5,
     "k198rScore": -9.16349,
     "k198rProb": 6.9,
     "probDifference": 1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2243
    },
    {
     "sequence": "YADKVFTYIFILEML",
     "pos": 1250,
     "wtScore": -38.1956,
     "wtProb": 5.5,
     "k198rScore": -9.16349,
     "k198rProb": 6.9,
     "probDifference": 1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2244
    },
    {
     "sequence": "YADKVFTYIFILEML",
     "pos": 1260,
     "wtScore": -38.1956,
     "wtProb": 5.5,
     "k198rScore": -9.16349,
     "k198rProb": 6.9,
     "probDifference": 1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2245
    },
    {
     "sequence": "YADKVFTYIFILEML",
     "pos": 1240,
     "wtScore": -38.1956,
     "wtProb": 5.5,
     "k198rScore": -9.16349,
     "k198rProb": 6.9,
     "probDifference": 1.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2246
    },
    {
     "sequence": "SRASRPLSASQPSLP",
     "pos": 820,
     "wtScore": 63.3294,
     "wtProb": 19,
     "k198rScore": 52.3903,
     "k198rProb": 17.7,
     "probDifference": -1.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 2247
    },
    {
     "sequence": "SENVPVPTATPGNSE",
     "pos": 1300,
     "wtScore": -11.6046,
     "wtProb": 7.3,
     "k198rScore": -24.8108,
     "k198rProb": 5.9,
     "probDifference": -1.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2248
    },
    {
     "sequence": "SVMVILISIVIFCLE",
     "pos": 179,
     "wtScore": 44.4747,
     "wtProb": 9.2,
     "k198rScore": 32.435,
     "k198rProb": 7.8,
     "probDifference": -1.4,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2249
    },
    {
     "sequence": "TRPASSSTPRLGPTP",
     "pos": 844,
     "wtScore": -17.4993,
     "wtProb": 6.4,
     "k198rScore": -32.0552,
     "k198rProb": 5,
     "probDifference": -1.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 2250
    },
    {
     "sequence": "TAACPPSYDRVTKPI",
     "pos": 1985,
     "wtScore": -22.9339,
     "wtProb": 6,
     "k198rScore": -6.55068,
     "k198rProb": 7.3,
     "probDifference": 1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2251
    },
    {
     "sequence": "VIFLGSFYLINLILA",
     "pos": 414,
     "wtScore": -39.5642,
     "wtProb": 5.3,
     "k198rScore": -13.2127,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2252
    },
    {
     "sequence": "VIFLGSFYLINLILA",
     "pos": 412,
     "wtScore": -39.5642,
     "wtProb": 5.3,
     "k198rScore": -13.2127,
     "k198rProb": 6.7,
     "probDifference": 1.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2253
    },
    {
     "sequence": "TAFRMKAYAFWQSSE",
     "pos": 480,
     "wtScore": -39.8891,
     "wtProb": 5.3,
     "k198rScore": -11.5795,
     "k198rProb": 6.6,
     "probDifference": 1.4,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2254
    },
    {
     "sequence": "LADACDMTIEEMESA",
     "pos": 2152,
     "wtScore": -6.68557,
     "wtProb": 8.2,
     "k198rScore": -16.8055,
     "k198rProb": 6.8,
     "probDifference": -1.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2255
    },
    {
     "sequence": "VSYEPITTTLKRKQE",
     "pos": 1892,
     "wtScore": -17.3283,
     "wtProb": 6.4,
     "k198rScore": -31.7515,
     "k198rProb": 5,
     "probDifference": -1.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2256
    },
    {
     "sequence": "VSYEPITTTLKRKQE",
     "pos": 1897,
     "wtScore": -17.3283,
     "wtProb": 6.4,
     "k198rScore": -31.7515,
     "k198rProb": 5,
     "probDifference": -1.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2257
    },
    {
     "sequence": "GVTFHTYSNSTIGYQ",
     "pos": 205,
     "wtScore": 45.5391,
     "wtProb": 9.4,
     "k198rScore": 34.5099,
     "k198rProb": 8.1,
     "probDifference": -1.4,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2258
    },
    {
     "sequence": "LKSCPYCTRALEDPE",
     "pos": 703,
     "wtScore": -8.93504,
     "wtProb": 7.8,
     "k198rScore": -21.5551,
     "k198rProb": 6.5,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2259
    },
    {
     "sequence": "EHAYLQSSWNLLDGL",
     "pos": 1362,
     "wtScore": 43.4448,
     "wtProb": 9,
     "k198rScore": 30.5943,
     "k198rProb": 7.7,
     "probDifference": -1.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2260
    },
    {
     "sequence": "PFLSRHNSKSSIFSF",
     "pos": 560,
     "wtScore": 42.8183,
     "wtProb": 9,
     "k198rScore": 29.0132,
     "k198rProb": 7.6,
     "probDifference": -1.4,
     "geneName": "SCN8A",
     "known": "Yes",
     "id": 2261
    },
    {
     "sequence": "EGRLIAATFSLIGVS",
     "pos": 334,
     "wtScore": -12.2825,
     "wtProb": 7.2,
     "k198rScore": -25.4479,
     "k198rProb": 5.8,
     "probDifference": -1.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2262
    },
    {
     "sequence": "GGGSPRSSSEISKLS",
     "pos": 476,
     "wtScore": 41.8843,
     "wtProb": 8.9,
     "k198rScore": 25.4414,
     "k198rProb": 7.5,
     "probDifference": -1.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2263
    },
    {
     "sequence": "REGEQAETEPEEDLK",
     "pos": 1156,
     "wtScore": 26.3842,
     "wtProb": 8.4,
     "k198rScore": -8.08722,
     "k198rProb": 7,
     "probDifference": -1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2264
    },
    {
     "sequence": "GIRTLLWTFIKSFQA",
     "pos": 1341,
     "wtScore": -19.156,
     "wtProb": 6.2,
     "k198rScore": -37.7047,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2265
    },
    {
     "sequence": "GIRTLLWTFIKSFQA",
     "pos": 1399,
     "wtScore": -19.156,
     "wtProb": 6.2,
     "k198rScore": -37.7047,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2266
    },
    {
     "sequence": "LNKGKAISRFSATPA",
     "pos": 100,
     "wtScore": 46.4905,
     "wtProb": 9.6,
     "k198rScore": 43.2081,
     "k198rProb": 11,
     "probDifference": 1.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2267
    },
    {
     "sequence": "KRKQEEVSAVIIQRA",
     "pos": 1917,
     "wtScore": 72.7489,
     "wtProb": 23.5,
     "k198rScore": 61.9436,
     "k198rProb": 22.1,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "Yes",
     "id": 2268
    },
    {
     "sequence": "DEYIEDKSHFYFLEG",
     "pos": 323,
     "wtScore": 56.3203,
     "wtProb": 14.3,
     "k198rScore": 46.3713,
     "k198rProb": 12.9,
     "probDifference": -1.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2269
    },
    {
     "sequence": "TSSTTTPTSRMRTQS",
     "pos": 656,
     "wtScore": -18.5939,
     "wtProb": 6.2,
     "k198rScore": -34.8756,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 2270
    },
    {
     "sequence": "LSGGAPQSPNGALLP",
     "pos": 2171,
     "wtScore": 49.6267,
     "wtProb": 10.6,
     "k198rScore": 39.3672,
     "k198rProb": 9.2,
     "probDifference": -1.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2271
    },
    {
     "sequence": "EGLSVLRSFRLLRVF",
     "pos": 851,
     "wtScore": 42.789,
     "wtProb": 9,
     "k198rScore": 29.3326,
     "k198rProb": 7.6,
     "probDifference": -1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2272
    },
    {
     "sequence": "EGLSVLRSFRLLRVF",
     "pos": 859,
     "wtScore": 42.789,
     "wtProb": 9,
     "k198rScore": 29.3326,
     "k198rProb": 7.6,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2273
    },
    {
     "sequence": "EGLSVLRSFRLLRVF",
     "pos": 844,
     "wtScore": 42.789,
     "wtProb": 9,
     "k198rScore": 29.3326,
     "k198rProb": 7.6,
     "probDifference": -1.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2274
    },
    {
     "sequence": "EGLSVLRSFRLLRVF",
     "pos": 850,
     "wtScore": 42.789,
     "wtProb": 9,
     "k198rScore": 29.3326,
     "k198rProb": 7.6,
     "probDifference": -1.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2275
    },
    {
     "sequence": "VHRIDNTTVIYNSNI",
     "pos": 208,
     "wtScore": -15.6856,
     "wtProb": 6.6,
     "k198rScore": -29.2574,
     "k198rProb": 5.3,
     "probDifference": -1.3,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2276
    },
    {
     "sequence": "GTTSGIGTGSSVEKY",
     "pos": 1087,
     "wtScore": -1.58287,
     "wtProb": 8.6,
     "k198rScore": -7.10599,
     "k198rProb": 7.3,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2277
    },
    {
     "sequence": "QRRAGRHTASVHHLV",
     "pos": 510,
     "wtScore": -6.99779,
     "wtProb": 8.1,
     "k198rScore": -16.1016,
     "k198rProb": 6.8,
     "probDifference": -1.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2278
    },
    {
     "sequence": "RFYESVVSFPFFRKE",
     "pos": 406,
     "wtScore": 57.175,
     "wtProb": 14.8,
     "k198rScore": 50.5091,
     "k198rProb": 16.2,
     "probDifference": 1.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2279
    },
    {
     "sequence": "DLIMNFRTGTVNEDS",
     "pos": 195,
     "wtScore": -7.78985,
     "wtProb": 8,
     "k198rScore": -19.6221,
     "k198rProb": 6.7,
     "probDifference": -1.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 2280
    },
    {
     "sequence": "AHSKELVTAWYIGFL",
     "pos": 233,
     "wtScore": -14.4973,
     "wtProb": 6.8,
     "k198rScore": -27.6558,
     "k198rProb": 5.5,
     "probDifference": -1.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2281
    },
    {
     "sequence": "IIEKYFVSPTLFRVI",
     "pos": 1611,
     "wtScore": 48.093,
     "wtProb": 10.1,
     "k198rScore": 37.7318,
     "k198rProb": 8.7,
     "probDifference": -1.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2282
    },
    {
     "sequence": "EMAQGPGSARRVDAD",
     "pos": 1889,
     "wtScore": 59.9964,
     "wtProb": 16.9,
     "k198rScore": 52.9832,
     "k198rProb": 18.2,
     "probDifference": 1.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2283
    },
    {
     "sequence": "TISTVSSTQRKRQQY",
     "pos": 78,
     "wtScore": -18.6071,
     "wtProb": 6.2,
     "k198rScore": -34.4463,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2284
    },
    {
     "sequence": "DPLREEGSVGSSSPQ",
     "pos": 849,
     "wtScore": 71.3072,
     "wtProb": 22.9,
     "k198rScore": 60.4133,
     "k198rProb": 21.5,
     "probDifference": -1.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2285
    },
    {
     "sequence": "LLRIFKVTRHWTSLS",
     "pos": 629,
     "wtScore": -18.8344,
     "wtProb": 6.2,
     "k198rScore": -35.0985,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2286
    },
    {
     "sequence": "KVSYQPITTTLKRKQ",
     "pos": 1906,
     "wtScore": -15.6058,
     "wtProb": 6.6,
     "k198rScore": -29.0514,
     "k198rProb": 5.3,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2287
    },
    {
     "sequence": "TLFRFSATPALYILS",
     "pos": 108,
     "wtScore": -18.5776,
     "wtProb": 6.3,
     "k198rScore": -34.2122,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2288
    },
    {
     "sequence": "TTLNSTSSTTTPTSR",
     "pos": 651,
     "wtScore": 41.6556,
     "wtProb": 8.8,
     "k198rScore": 25.7174,
     "k198rProb": 7.5,
     "probDifference": -1.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 2289
    },
    {
     "sequence": "EMLYNEITKTQPHSH",
     "pos": 547,
     "wtScore": -14.9339,
     "wtProb": 6.7,
     "k198rScore": -28.1924,
     "k198rProb": 5.4,
     "probDifference": -1.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2290
    },
    {
     "sequence": "ASTRPASSSTPRLGP",
     "pos": 842,
     "wtScore": 43.4344,
     "wtProb": 9,
     "k198rScore": 31.0376,
     "k198rProb": 7.7,
     "probDifference": -1.3,
     "geneName": "HCN2",
     "known": "No",
     "id": 2291
    },
    {
     "sequence": "YTSFDTFSWAFLSLF",
     "pos": 370,
     "wtScore": 47.5827,
     "wtProb": 9.9,
     "k198rScore": 37.2599,
     "k198rProb": 8.6,
     "probDifference": -1.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2292
    },
    {
     "sequence": "YTSFDTFSWAFLSLF",
     "pos": 368,
     "wtScore": 47.5827,
     "wtProb": 9.9,
     "k198rScore": 37.2599,
     "k198rProb": 8.6,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2293
    },
    {
     "sequence": "YTSFDTFSWAFLSLF",
     "pos": 369,
     "wtScore": 47.5827,
     "wtProb": 9.9,
     "k198rScore": 37.2599,
     "k198rProb": 8.6,
     "probDifference": -1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2294
    },
    {
     "sequence": "ECYEDDSSPTWSRQN",
     "pos": 1856,
     "wtScore": 51.4456,
     "wtProb": 11.3,
     "k198rScore": 41.1828,
     "k198rProb": 10,
     "probDifference": -1.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2295
    },
    {
     "sequence": "KVSYEPITTTLRRKQ",
     "pos": 1886,
     "wtScore": -17.9462,
     "wtProb": 6.3,
     "k198rScore": -32.386,
     "k198rProb": 5,
     "probDifference": -1.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2296
    },
    {
     "sequence": "VEHNWFETFIVFMIL",
     "pos": 1221,
     "wtScore": -12.1986,
     "wtProb": 7.2,
     "k198rScore": -25.1789,
     "k198rProb": 5.9,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2297
    },
    {
     "sequence": "VEHNWFETFIVFMIL",
     "pos": 1209,
     "wtScore": -12.1986,
     "wtProb": 7.2,
     "k198rScore": -25.1789,
     "k198rProb": 5.9,
     "probDifference": -1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2298
    },
    {
     "sequence": "VEHNWFETFIVFMIL",
     "pos": 1211,
     "wtScore": -12.1986,
     "wtProb": 7.2,
     "k198rScore": -25.1789,
     "k198rProb": 5.9,
     "probDifference": -1.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2299
    },
    {
     "sequence": "TKHGSRSTSSLPPEP",
     "pos": 12,
     "wtScore": -19.1734,
     "wtProb": 6.2,
     "k198rScore": -35.5021,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2300
    },
    {
     "sequence": "SEDSVKRSSFLFSMD",
     "pos": 534,
     "wtScore": 43.0532,
     "wtProb": 9,
     "k198rScore": 30.4181,
     "k198rProb": 7.7,
     "probDifference": -1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2301
    },
    {
     "sequence": "RLRSRDSSCGRPGTP",
     "pos": 80,
     "wtScore": 60.6885,
     "wtProb": 17.3,
     "k198rScore": 53.6959,
     "k198rProb": 18.6,
     "probDifference": 1.3,
     "geneName": "HCN2",
     "known": "No",
     "id": 2302
    },
    {
     "sequence": "LTYEKRYSSPHQSLL",
     "pos": 549,
     "wtScore": 44.9854,
     "wtProb": 9.3,
     "k198rScore": 33.824,
     "k198rProb": 8,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2303
    },
    {
     "sequence": "EGWPALLYKAIDSNG",
     "pos": 1107,
     "wtScore": -37.8705,
     "wtProb": 5.5,
     "k198rScore": -9.36166,
     "k198rProb": 6.8,
     "probDifference": 1.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2304
    },
    {
     "sequence": "ALRIVRFTKILSLLR",
     "pos": 259,
     "wtScore": -18.8658,
     "wtProb": 6.2,
     "k198rScore": -34.3879,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 2305
    },
    {
     "sequence": "ALRIVRFTKILSLLR",
     "pos": 328,
     "wtScore": -18.8658,
     "wtProb": 6.2,
     "k198rScore": -34.3879,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "HCN2",
     "known": "No",
     "id": 2306
    },
    {
     "sequence": "DGHNVSLSAIRTVRV",
     "pos": 195,
     "wtScore": 59.2658,
     "wtProb": 16.3,
     "k198rScore": 52.3753,
     "k198rProb": 17.7,
     "probDifference": 1.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2307
    },
    {
     "sequence": "GGDISQKTVLPLHLV",
     "pos": 2000,
     "wtScore": -16.9272,
     "wtProb": 6.4,
     "k198rScore": -30.5868,
     "k198rProb": 5.1,
     "probDifference": -1.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2308
    },
    {
     "sequence": "SLLNSMKSIASLLLL",
     "pos": 648,
     "wtScore": 42.034,
     "wtProb": 8.9,
     "k198rScore": 27.8272,
     "k198rProb": 7.6,
     "probDifference": -1.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2309
    },
    {
     "sequence": "NTKGKLFTPLNVDAI",
     "pos": 445,
     "wtScore": -19.9915,
     "wtProb": 6.2,
     "k198rScore": -38.9601,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2310
    },
    {
     "sequence": "VNLFAGKYHYCFNET",
     "pos": 1352,
     "wtScore": -39.3271,
     "wtProb": 5.3,
     "k198rScore": -12.3851,
     "k198rProb": 6.7,
     "probDifference": 1.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2311
    },
    {
     "sequence": "IGGKIVGSLCAIAGV",
     "pos": 391,
     "wtScore": 43.9159,
     "wtProb": 9.1,
     "k198rScore": 42.1615,
     "k198rProb": 10.4,
     "probDifference": 1.3,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2312
    },
    {
     "sequence": "IGGKIVGSLCAIAGV",
     "pos": 389,
     "wtScore": 43.9159,
     "wtProb": 9.1,
     "k198rScore": 42.1615,
     "k198rProb": 10.4,
     "probDifference": 1.3,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2313
    },
    {
     "sequence": "NADPNFVTAMLSKLR",
     "pos": 484,
     "wtScore": -14.0384,
     "wtProb": 6.9,
     "k198rScore": -27.0058,
     "k198rProb": 5.6,
     "probDifference": -1.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 2314
    },
    {
     "sequence": "SAFFIFSSNNRFRLQ",
     "pos": 884,
     "wtScore": 47.5585,
     "wtProb": 9.9,
     "k198rScore": 37.2816,
     "k198rProb": 8.6,
     "probDifference": -1.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2315
    },
    {
     "sequence": "ILVHSLFSMLIMCTI",
     "pos": 130,
     "wtScore": 44.1528,
     "wtProb": 9.1,
     "k198rScore": 32.4994,
     "k198rProb": 7.8,
     "probDifference": -1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2316
    },
    {
     "sequence": "ILVHSLFSMLIMCTI",
     "pos": 130,
     "wtScore": 44.1528,
     "wtProb": 9.1,
     "k198rScore": 32.4994,
     "k198rProb": 7.8,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2317
    },
    {
     "sequence": "KRRSLRLYARWQSRW",
     "pos": 478,
     "wtScore": -37.8098,
     "wtProb": 5.5,
     "k198rScore": -9.27072,
     "k198rProb": 6.8,
     "probDifference": 1.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2318
    },
    {
     "sequence": "FMEFEKLSQFAAALE",
     "pos": 1828,
     "wtScore": 55.3247,
     "wtProb": 13.6,
     "k198rScore": 45.5598,
     "k198rProb": 12.3,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2319
    },
    {
     "sequence": "MTTLNSTSSTTTPTS",
     "pos": 650,
     "wtScore": 41.4769,
     "wtProb": 8.8,
     "k198rScore": 25.9713,
     "k198rProb": 7.5,
     "probDifference": -1.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 2320
    },
    {
     "sequence": "PESFRLFTRESLAAI",
     "pos": 17,
     "wtScore": -19.8798,
     "wtProb": 6.2,
     "k198rScore": -36.0397,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2321
    },
    {
     "sequence": "LYNGMASTSSWAALY",
     "pos": 986,
     "wtScore": -19.9797,
     "wtProb": 6.2,
     "k198rScore": -36.7815,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2322
    },
    {
     "sequence": "GSAFFILSKTNPIRV",
     "pos": 869,
     "wtScore": 51.2062,
     "wtProb": 11.2,
     "k198rScore": 41.031,
     "k198rProb": 9.9,
     "probDifference": -1.3,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 2323
    },
    {
     "sequence": "VSYQPITTTLKRKQE",
     "pos": 1907,
     "wtScore": -18.4584,
     "wtProb": 6.3,
     "k198rScore": -33.0295,
     "k198rProb": 5,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2324
    },
    {
     "sequence": "TPTSRMRTQSPPVYT",
     "pos": 661,
     "wtScore": -19.4999,
     "wtProb": 6.2,
     "k198rScore": -35.276,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 2325
    },
    {
     "sequence": "ITKTQPHSHPNPDCQ",
     "pos": 553,
     "wtScore": 50.3167,
     "wtProb": 10.8,
     "k198rScore": 40.2378,
     "k198rProb": 9.5,
     "probDifference": -1.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2326
    },
    {
     "sequence": "QDYWENLYQLTLRAA",
     "pos": 375,
     "wtScore": -37.394,
     "wtProb": 5.6,
     "k198rScore": -9.06382,
     "k198rProb": 6.9,
     "probDifference": 1.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2327
    },
    {
     "sequence": "QDYWENLYQLTLRAA",
     "pos": 388,
     "wtScore": -37.394,
     "wtProb": 5.6,
     "k198rScore": -9.06382,
     "k198rProb": 6.9,
     "probDifference": 1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2328
    },
    {
     "sequence": "LCQAISKSKLSRRWR",
     "pos": 498,
     "wtScore": 52.8021,
     "wtProb": 12,
     "k198rScore": 42.743,
     "k198rProb": 10.7,
     "probDifference": -1.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2329
    },
    {
     "sequence": "GNVSALRTFRVLRAL",
     "pos": 217,
     "wtScore": -19.7225,
     "wtProb": 6.2,
     "k198rScore": -36.0722,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2330
    },
    {
     "sequence": "GNVSALRTFRVLRAL",
     "pos": 216,
     "wtScore": -19.7225,
     "wtProb": 6.2,
     "k198rScore": -36.0722,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2331
    },
    {
     "sequence": "GNVSALRTFRVLRAL",
     "pos": 220,
     "wtScore": -19.7225,
     "wtProb": 6.2,
     "k198rScore": -36.0722,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2332
    },
    {
     "sequence": "GNVSALRTFRVLRAL",
     "pos": 216,
     "wtScore": -19.7225,
     "wtProb": 6.2,
     "k198rScore": -36.0722,
     "k198rProb": 4.9,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2333
    },
    {
     "sequence": "SLLNSVRSIASLLLL",
     "pos": 649,
     "wtScore": 42.3238,
     "wtProb": 8.9,
     "k198rScore": 28.9207,
     "k198rProb": 7.6,
     "probDifference": -1.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2334
    },
    {
     "sequence": "RSVKRNSTVDCNGVV",
     "pos": 641,
     "wtScore": -11.2982,
     "wtProb": 7.4,
     "k198rScore": -24.0452,
     "k198rProb": 6.1,
     "probDifference": -1.3,
     "geneName": "SCN8A",
     "known": "Yes",
     "id": 2335
    },
    {
     "sequence": "YGDMVPTTIGGKIVG",
     "pos": 383,
     "wtScore": -12.5582,
     "wtProb": 7.1,
     "k198rScore": -25.3636,
     "k198rProb": 5.8,
     "probDifference": -1.3,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2336
    },
    {
     "sequence": "RDEGQRRTPQGIGLL",
     "pos": 80,
     "wtScore": -16.3123,
     "wtProb": 6.5,
     "k198rScore": -29.441,
     "k198rProb": 5.2,
     "probDifference": -1.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2337
    },
    {
     "sequence": "WNLRKTCYSIVEHNW",
     "pos": 1199,
     "wtScore": -38.7221,
     "wtProb": 5.4,
     "k198rScore": -10.7558,
     "k198rProb": 6.7,
     "probDifference": 1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2338
    },
    {
     "sequence": "KSRNGGVYPGPSGEK",
     "pos": 10,
     "wtScore": -36.9463,
     "wtProb": 5.6,
     "k198rScore": -8.84472,
     "k198rProb": 6.9,
     "probDifference": 1.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2339
    },
    {
     "sequence": "QLPPEGTTTETEVRK",
     "pos": 675,
     "wtScore": -16.6732,
     "wtProb": 6.5,
     "k198rScore": -29.7381,
     "k198rProb": 5.2,
     "probDifference": -1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2340
    },
    {
     "sequence": "RGFICKKTTSNKLEN",
     "pos": 1919,
     "wtScore": -18.5021,
     "wtProb": 6.3,
     "k198rScore": -32.586,
     "k198rProb": 5,
     "probDifference": -1.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2341
    },
    {
     "sequence": "LTYEKRFSSPHQSLL",
     "pos": 552,
     "wtScore": 45.4876,
     "wtProb": 9.4,
     "k198rScore": 34.9865,
     "k198rProb": 8.1,
     "probDifference": -1.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2342
    },
    {
     "sequence": "HINKAGSSQGDTESP",
     "pos": 1783,
     "wtScore": 71.2297,
     "wtProb": 22.9,
     "k198rScore": 60.309,
     "k198rProb": 21.6,
     "probDifference": -1.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2343
    },
    {
     "sequence": "YTFTGIYTFESLIKI",
     "pos": 166,
     "wtScore": -7.99653,
     "wtProb": 8,
     "k198rScore": -19.0403,
     "k198rProb": 6.7,
     "probDifference": -1.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2344
    },
    {
     "sequence": "YTFTGIYTFESLIKI",
     "pos": 165,
     "wtScore": -7.99653,
     "wtProb": 8,
     "k198rScore": -19.0403,
     "k198rProb": 6.7,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2345
    },
    {
     "sequence": "YTFTGIYTFESLIKI",
     "pos": 165,
     "wtScore": -7.99653,
     "wtProb": 8,
     "k198rScore": -19.0403,
     "k198rProb": 6.7,
     "probDifference": -1.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2346
    },
    {
     "sequence": "QIPLAVSSPARSGEP",
     "pos": 1986,
     "wtScore": 48.164,
     "wtProb": 10.1,
     "k198rScore": 38.092,
     "k198rProb": 8.8,
     "probDifference": -1.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2347
    },
    {
     "sequence": "FLALLLSSFSADNLA",
     "pos": 993,
     "wtScore": 45.5536,
     "wtProb": 9.4,
     "k198rScore": 35.0862,
     "k198rProb": 8.2,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2348
    },
    {
     "sequence": "FLALLLSSFSADNLA",
     "pos": 978,
     "wtScore": 45.5536,
     "wtProb": 9.4,
     "k198rScore": 35.0862,
     "k198rProb": 8.2,
     "probDifference": -1.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2349
    },
    {
     "sequence": "SRTDLHSTWQYYERT",
     "pos": 358,
     "wtScore": -16.4672,
     "wtProb": 6.5,
     "k198rScore": -29.4495,
     "k198rProb": 5.2,
     "probDifference": -1.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2350
    },
    {
     "sequence": "KSSEGALYILEIVTI",
     "pos": 126,
     "wtScore": -37.9834,
     "wtProb": 5.5,
     "k198rScore": -9.87791,
     "k198rProb": 6.8,
     "probDifference": 1.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2351
    },
    {
     "sequence": "IMAYGGPSSSGMIVC",
     "pos": 721,
     "wtScore": 48.2722,
     "wtProb": 10.1,
     "k198rScore": 38.1932,
     "k198rProb": 8.9,
     "probDifference": -1.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2352
    },
    {
     "sequence": "QWWNLRRTCFRIVEH",
     "pos": 1209,
     "wtScore": -17.9334,
     "wtProb": 6.3,
     "k198rScore": -31.4795,
     "k198rProb": 5.1,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2353
    },
    {
     "sequence": "RRSSVKRTRYYETYI",
     "pos": 1803,
     "wtScore": -18.3293,
     "wtProb": 6.3,
     "k198rScore": -32.1483,
     "k198rProb": 5,
     "probDifference": -1.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2354
    },
    {
     "sequence": "TGPPEPPYSFHQVTI",
     "pos": 696,
     "wtScore": -36.7038,
     "wtProb": 5.7,
     "k198rScore": -8.81092,
     "k198rProb": 6.9,
     "probDifference": 1.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2355
    },
    {
     "sequence": "KRNGSIVSMNMKDAF",
     "pos": 446,
     "wtScore": 56.2195,
     "wtProb": 14.2,
     "k198rScore": 46.4049,
     "k198rProb": 12.9,
     "probDifference": -1.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2356
    },
    {
     "sequence": "LPDSRRGSSSSGDPP",
     "pos": 1106,
     "wtScore": 47.1216,
     "wtProb": 9.8,
     "k198rScore": 36.9773,
     "k198rProb": 8.5,
     "probDifference": -1.3,
     "geneName": "CACNA1H",
     "known": "Yes",
     "id": 2357
    },
    {
     "sequence": "AVKSVTFYWLVIVLV",
     "pos": 525,
     "wtScore": -38.833,
     "wtProb": 5.4,
     "k198rScore": -11.042,
     "k198rProb": 6.7,
     "probDifference": 1.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2358
    },
    {
     "sequence": "FGPNGGSSWMREKRY",
     "pos": 824,
     "wtScore": 48.3702,
     "wtProb": 10.2,
     "k198rScore": 38.2974,
     "k198rProb": 8.9,
     "probDifference": -1.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2359
    },
    {
     "sequence": "WWAVVSMTTVGYGDM",
     "pos": 370,
     "wtScore": -17.5223,
     "wtProb": 6.4,
     "k198rScore": -30.6821,
     "k198rProb": 5.1,
     "probDifference": -1.3,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2360
    },
    {
     "sequence": "WWAVVSMTTVGYGDM",
     "pos": 372,
     "wtScore": -17.5223,
     "wtProb": 6.4,
     "k198rScore": -30.6821,
     "k198rProb": 5.1,
     "probDifference": -1.3,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2361
    },
    {
     "sequence": "QDFWENLYQLTLRAA",
     "pos": 387,
     "wtScore": -36.635,
     "wtProb": 5.7,
     "k198rScore": -8.81051,
     "k198rProb": 6.9,
     "probDifference": 1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2362
    },
    {
     "sequence": "QDFWENLYQLTLRAA",
     "pos": 389,
     "wtScore": -36.635,
     "wtProb": 5.7,
     "k198rScore": -8.81051,
     "k198rProb": 6.9,
     "probDifference": 1.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2363
    },
    {
     "sequence": "GSSSPQDTGHNCRQD",
     "pos": 858,
     "wtScore": -7.52077,
     "wtProb": 8.1,
     "k198rScore": -17.9071,
     "k198rProb": 6.8,
     "probDifference": -1.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2364
    },
    {
     "sequence": "SSGKVQATPPSSATT",
     "pos": 732,
     "wtScore": -14.0689,
     "wtProb": 6.9,
     "k198rScore": -26.7776,
     "k198rProb": 5.6,
     "probDifference": -1.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2365
    },
    {
     "sequence": "RVAHAAATAAASLRL",
     "pos": 596,
     "wtScore": -8.62796,
     "wtProb": 7.9,
     "k198rScore": -20.2422,
     "k198rProb": 6.6,
     "probDifference": -1.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2366
    },
    {
     "sequence": "PSKAGFFTNIMNIID",
     "pos": 251,
     "wtScore": -7.46368,
     "wtProb": 8.1,
     "k198rScore": -17.7455,
     "k198rProb": 6.8,
     "probDifference": -1.3,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2367
    },
    {
     "sequence": "SVRTRKHTFGQRCVS",
     "pos": 2072,
     "wtScore": -12.621,
     "wtProb": 7.1,
     "k198rScore": -25.2874,
     "k198rProb": 5.9,
     "probDifference": -1.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2368
    },
    {
     "sequence": "MQLFGKSYKDCVCKI",
     "pos": 915,
     "wtScore": -38.5194,
     "wtProb": 5.4,
     "k198rScore": -10.5515,
     "k198rProb": 6.7,
     "probDifference": 1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2369
    },
    {
     "sequence": "KLIERNETARWKNVK",
     "pos": 1404,
     "wtScore": -7.5271,
     "wtProb": 8.1,
     "k198rScore": -17.8347,
     "k198rProb": 6.8,
     "probDifference": -1.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2370
    },
    {
     "sequence": "NRGSAPQTPPSTARP",
     "pos": 728,
     "wtScore": -12.2679,
     "wtProb": 7.2,
     "k198rScore": -24.8599,
     "k198rProb": 5.9,
     "probDifference": -1.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2371
    },
    {
     "sequence": "ALIESNQTARWKNVK",
     "pos": 1394,
     "wtScore": -7.54299,
     "wtProb": 8.1,
     "k198rScore": -17.8493,
     "k198rProb": 6.8,
     "probDifference": -1.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2372
    },
    {
     "sequence": "DNPCEGLSRHATFSN",
     "pos": 1785,
     "wtScore": 52.2848,
     "wtProb": 11.7,
     "k198rScore": 42.2709,
     "k198rProb": 10.5,
     "probDifference": -1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2373
    },
    {
     "sequence": "GAKEHGNSVCFKVDG",
     "pos": 55,
     "wtScore": 56.6963,
     "wtProb": 14.5,
     "k198rScore": 47.0691,
     "k198rProb": 13.3,
     "probDifference": -1.2,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 2374
    },
    {
     "sequence": "IWWNLRKTCYSIVEH",
     "pos": 1197,
     "wtScore": -18.9437,
     "wtProb": 6.2,
     "k198rScore": -32.8389,
     "k198rProb": 5,
     "probDifference": -1.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2375
    },
    {
     "sequence": "EVSHITSSACPWQPT",
     "pos": 2106,
     "wtScore": 53.4929,
     "wtProb": 12.5,
     "k198rScore": 47.7652,
     "k198rProb": 13.7,
     "probDifference": 1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2376
    },
    {
     "sequence": "DPFTPSGSMPLSSTG",
     "pos": 849,
     "wtScore": 48.4316,
     "wtProb": 10.2,
     "k198rScore": 38.4361,
     "k198rProb": 8.9,
     "probDifference": -1.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2377
    },
    {
     "sequence": "RRDSLQQTNTTHRPL",
     "pos": 1703,
     "wtScore": -11.4182,
     "wtProb": 7.3,
     "k198rScore": -23.943,
     "k198rProb": 6.1,
     "probDifference": -1.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2378
    },
    {
     "sequence": "VSMTTVGYGDMVPTT",
     "pos": 376,
     "wtScore": -38.7487,
     "wtProb": 5.4,
     "k198rScore": -12.6944,
     "k198rProb": 6.7,
     "probDifference": 1.2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2379
    },
    {
     "sequence": "AFPQTFTTQRPLHIN",
     "pos": 1771,
     "wtScore": -20.6581,
     "wtProb": 6.1,
     "k198rScore": -36.4189,
     "k198rProb": 4.9,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2380
    },
    {
     "sequence": "FRNHILFYFDIVFTT",
     "pos": 934,
     "wtScore": -37.2594,
     "wtProb": 5.6,
     "k198rScore": -9.53844,
     "k198rProb": 6.8,
     "probDifference": 1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2381
    },
    {
     "sequence": "QQGQQAMSLAILRVI",
     "pos": 288,
     "wtScore": 45.4721,
     "wtProb": 9.4,
     "k198rScore": 42.5472,
     "k198rProb": 10.7,
     "probDifference": 1.2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2382
    },
    {
     "sequence": "QLFKGKLYTCSDSSK",
     "pos": 1075,
     "wtScore": -35.969,
     "wtProb": 5.7,
     "k198rScore": -8.54633,
     "k198rProb": 7,
     "probDifference": 1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2383
    },
    {
     "sequence": "PYYLTQKTFVVLNRG",
     "pos": 92,
     "wtScore": -18.8243,
     "wtProb": 6.2,
     "k198rScore": -32.4808,
     "k198rProb": 5,
     "probDifference": -1.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2384
    },
    {
     "sequence": "ILSPCSYTTAVCSPP",
     "pos": 699,
     "wtScore": -18.9489,
     "wtProb": 6.2,
     "k198rScore": -32.6153,
     "k198rProb": 5,
     "probDifference": -1.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 2385
    },
    {
     "sequence": "LINHHIFTNLILVFI",
     "pos": 888,
     "wtScore": -18.2313,
     "wtProb": 6.3,
     "k198rScore": -31.5,
     "k198rProb": 5.1,
     "probDifference": -1.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2386
    },
    {
     "sequence": "YLLRFLSSPKKWKFF",
     "pos": 250,
     "wtScore": 44.9312,
     "wtProb": 9.3,
     "k198rScore": 34.4502,
     "k198rProb": 8.1,
     "probDifference": -1.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2387
    },
    {
     "sequence": "PRPASVRTRKHTFGQ",
     "pos": 2068,
     "wtScore": -19.7926,
     "wtProb": 6.2,
     "k198rScore": -33.6655,
     "k198rProb": 4.9,
     "probDifference": -1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2388
    },
    {
     "sequence": "FVGFVIVTFQEQGEQ",
     "pos": 1187,
     "wtScore": -8.14459,
     "wtProb": 8,
     "k198rScore": -18.832,
     "k198rProb": 6.7,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2389
    },
    {
     "sequence": "FYFDIVFTTIFTIEI",
     "pos": 940,
     "wtScore": -16.7034,
     "wtProb": 6.5,
     "k198rScore": -29.4035,
     "k198rProb": 5.2,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2390
    },
    {
     "sequence": "EYAPVDITVNLDASG",
     "pos": 674,
     "wtScore": -14.7729,
     "wtProb": 6.8,
     "k198rScore": -27.3221,
     "k198rProb": 5.5,
     "probDifference": -1.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2391
    },
    {
     "sequence": "NVELQPKYEDNLYMY",
     "pos": 1442,
     "wtScore": -35.2512,
     "wtProb": 5.8,
     "k198rScore": -8.26969,
     "k198rProb": 7,
     "probDifference": 1.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2392
    },
    {
     "sequence": "WNGIMKDTLRECSRE",
     "pos": 1816,
     "wtScore": -10.162,
     "wtProb": 7.6,
     "k198rScore": -22.16,
     "k198rProb": 6.4,
     "probDifference": -1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2393
    },
    {
     "sequence": "AGHLDMLSRIKYLQT",
     "pos": 558,
     "wtScore": 45.9702,
     "wtProb": 9.5,
     "k198rScore": 35.8836,
     "k198rProb": 8.3,
     "probDifference": -1.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2394
    },
    {
     "sequence": "IHSNPGDTGYCPTRE",
     "pos": 900,
     "wtScore": -7.68668,
     "wtProb": 8,
     "k198rScore": -18.0199,
     "k198rProb": 6.8,
     "probDifference": -1.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2395
    },
    {
     "sequence": "LSMIVGATCYAMFVG",
     "pos": 383,
     "wtScore": -17.4516,
     "wtProb": 6.4,
     "k198rScore": -30.1746,
     "k198rProb": 5.2,
     "probDifference": -1.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 2396
    },
    {
     "sequence": "GIGVFSESSSVASKL",
     "pos": 485,
     "wtScore": 46.5896,
     "wtProb": 9.6,
     "k198rScore": 36.4743,
     "k198rProb": 8.4,
     "probDifference": -1.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2397
    },
    {
     "sequence": "PRSPEKHTAIAFNFE",
     "pos": 741,
     "wtScore": -10.0773,
     "wtProb": 7.6,
     "k198rScore": -21.9864,
     "k198rProb": 6.4,
     "probDifference": -1.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2398
    },
    {
     "sequence": "GRQCQNGTVCKPGWD",
     "pos": 329,
     "wtScore": -10.8739,
     "wtProb": 7.5,
     "k198rScore": -22.9034,
     "k198rProb": 6.3,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2399
    },
    {
     "sequence": "DMLVVGVSLVSFGIQ",
     "pos": 965,
     "wtScore": 37.0898,
     "wtProb": 8.5,
     "k198rScore": 40.6785,
     "k198rProb": 9.7,
     "probDifference": 1.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2400
    },
    {
     "sequence": "AFETNTTSYFNGTMD",
     "pos": 292,
     "wtScore": 42.3051,
     "wtProb": 8.9,
     "k198rScore": 30.4093,
     "k198rProb": 7.7,
     "probDifference": -1.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2401
    },
    {
     "sequence": "NSTSSTTTPTSRMRT",
     "pos": 654,
     "wtScore": -19.61,
     "wtProb": 6.2,
     "k198rScore": -33.1145,
     "k198rProb": 5,
     "probDifference": -1.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 2402
    },
    {
     "sequence": "TIGYGDKTPKTWEGR",
     "pos": 322,
     "wtScore": -7.77464,
     "wtProb": 8,
     "k198rScore": -17.948,
     "k198rProb": 6.8,
     "probDifference": -1.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2403
    },
    {
     "sequence": "ARKALSETSSNKSFE",
     "pos": 501,
     "wtScore": -11.846,
     "wtProb": 7.3,
     "k198rScore": -24.2641,
     "k198rProb": 6,
     "probDifference": -1.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2404
    },
    {
     "sequence": "TTSGIGSSVEKYIID",
     "pos": 1072,
     "wtScore": 53.5565,
     "wtProb": 12.5,
     "k198rScore": 43.7694,
     "k198rProb": 11.3,
     "probDifference": -1.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2405
    },
    {
     "sequence": "FSVATEESAEPLSED",
     "pos": 1785,
     "wtScore": 69.2975,
     "wtProb": 22,
     "k198rScore": 58.2051,
     "k198rProb": 20.7,
     "probDifference": -1.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2406
    },
    {
     "sequence": "FSVATEESAEPLSED",
     "pos": 1780,
     "wtScore": 69.2975,
     "wtProb": 22,
     "k198rScore": 58.2051,
     "k198rProb": 20.7,
     "probDifference": -1.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2407
    },
    {
     "sequence": "FSVATEESAEPLSED",
     "pos": 1795,
     "wtScore": 69.2975,
     "wtProb": 22,
     "k198rScore": 58.2051,
     "k198rProb": 20.7,
     "probDifference": -1.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2408
    },
    {
     "sequence": "LTPGLKVSIRAVCVM",
     "pos": 538,
     "wtScore": 40.9452,
     "wtProb": 8.8,
     "k198rScore": 27.4298,
     "k198rProb": 7.5,
     "probDifference": -1.2,
     "geneName": "KCNQ2",
     "known": "Yes",
     "id": 2409
    },
    {
     "sequence": "FESSPLPTSPKFLRQ",
     "pos": 803,
     "wtScore": -21.0011,
     "wtProb": 6.1,
     "k198rScore": -36.3426,
     "k198rProb": 4.9,
     "probDifference": -1.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2410
    },
    {
     "sequence": "SMTDIWLTMLSMIVG",
     "pos": 443,
     "wtScore": -15.6477,
     "wtProb": 6.6,
     "k198rScore": -28.2856,
     "k198rProb": 5.4,
     "probDifference": -1.2,
     "geneName": "HCN2",
     "known": "No",
     "id": 2411
    },
    {
     "sequence": "LDGSHSVTPESRASS",
     "pos": 2288,
     "wtScore": -9.91945,
     "wtProb": 7.6,
     "k198rScore": -21.7007,
     "k198rProb": 6.4,
     "probDifference": -1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2412
    },
    {
     "sequence": "VSLSAIRTVRVLRPL",
     "pos": 199,
     "wtScore": -15.8663,
     "wtProb": 6.6,
     "k198rScore": -28.5033,
     "k198rProb": 5.4,
     "probDifference": -1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2413
    },
    {
     "sequence": "FSVATEESADPLSED",
     "pos": 1775,
     "wtScore": 70.9143,
     "wtProb": 22.8,
     "k198rScore": 60.4206,
     "k198rProb": 21.5,
     "probDifference": -1.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2414
    },
    {
     "sequence": "KIVRSSSSTGQKNFS",
     "pos": 694,
     "wtScore": 52.6619,
     "wtProb": 11.9,
     "k198rScore": 42.7006,
     "k198rProb": 10.7,
     "probDifference": -1.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2415
    },
    {
     "sequence": "RSSRIFPSLRRSVKR",
     "pos": 631,
     "wtScore": 42.9965,
     "wtProb": 9,
     "k198rScore": 41.7543,
     "k198rProb": 10.2,
     "probDifference": 1.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2416
    },
    {
     "sequence": "FTPSSYSSTGSNANI",
     "pos": 1807,
     "wtScore": 52.2316,
     "wtProb": 11.7,
     "k198rScore": 42.277,
     "k198rProb": 10.5,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2417
    },
    {
     "sequence": "EIRKRRSSSYHVSMD",
     "pos": 685,
     "wtScore": 40.6163,
     "wtProb": 8.7,
     "k198rScore": 26.6252,
     "k198rProb": 7.5,
     "probDifference": -1.2,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 2418
    },
    {
     "sequence": "FLALLLSSFSSDNLA",
     "pos": 984,
     "wtScore": 45.1343,
     "wtProb": 9.3,
     "k198rScore": 34.865,
     "k198rProb": 8.1,
     "probDifference": -1.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2419
    },
    {
     "sequence": "FLALLLSSFSSDNLA",
     "pos": 985,
     "wtScore": 45.1343,
     "wtProb": 9.3,
     "k198rScore": 34.865,
     "k198rProb": 8.1,
     "probDifference": -1.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2420
    },
    {
     "sequence": "REPQRRSSVKRTRYY",
     "pos": 1799,
     "wtScore": 46.6182,
     "wtProb": 9.7,
     "k198rScore": 36.5566,
     "k198rProb": 8.4,
     "probDifference": -1.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2421
    },
    {
     "sequence": "SSSKSFETKEQGSPE",
     "pos": 505,
     "wtScore": -12.9808,
     "wtProb": 7.1,
     "k198rScore": -25.2499,
     "k198rProb": 5.9,
     "probDifference": -1.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2422
    },
    {
     "sequence": "DPLRNRGSAAAAVAG",
     "pos": 699,
     "wtScore": 52.732,
     "wtProb": 12,
     "k198rScore": 46.8506,
     "k198rProb": 13.2,
     "probDifference": 1.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2423
    },
    {
     "sequence": "SLQQTNTTHRPLHVQ",
     "pos": 1706,
     "wtScore": -18.1145,
     "wtProb": 6.3,
     "k198rScore": -30.9137,
     "k198rProb": 5.1,
     "probDifference": -1.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2424
    },
    {
     "sequence": "PRQGHGTSPVGDHGS",
     "pos": 730,
     "wtScore": 48.3397,
     "wtProb": 10.2,
     "k198rScore": 38.5283,
     "k198rProb": 9,
     "probDifference": -1.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2425
    },
    {
     "sequence": "QFHGSASSLVEAVLI",
     "pos": 2117,
     "wtScore": 79.4316,
     "wtProb": 25.1,
     "k198rScore": 68.9997,
     "k198rProb": 23.9,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2426
    },
    {
     "sequence": "LNSTSSTTTPTSRMR",
     "pos": 653,
     "wtScore": -20.3757,
     "wtProb": 6.1,
     "k198rScore": -33.8514,
     "k198rProb": 4.9,
     "probDifference": -1.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 2427
    },
    {
     "sequence": "AMFIGHATALIQSLD",
     "pos": 462,
     "wtScore": -8.64677,
     "wtProb": 7.9,
     "k198rScore": -19.8209,
     "k198rProb": 6.7,
     "probDifference": -1.2,
     "geneName": "HCN2",
     "known": "No",
     "id": 2428
    },
    {
     "sequence": "FYHCVNMTTGNMFDI",
     "pos": 1367,
     "wtScore": -14.2924,
     "wtProb": 6.8,
     "k198rScore": -26.6052,
     "k198rProb": 5.6,
     "probDifference": -1.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2429
    },
    {
     "sequence": "GFICKKTTSNKLENG",
     "pos": 1920,
     "wtScore": -20.8789,
     "wtProb": 6.1,
     "k198rScore": -35.0564,
     "k198rProb": 4.9,
     "probDifference": -1.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2430
    },
    {
     "sequence": "IMSPLGISVLRCVRL",
     "pos": 616,
     "wtScore": 49.0068,
     "wtProb": 10.3,
     "k198rScore": 39.1402,
     "k198rProb": 9.1,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2431
    },
    {
     "sequence": "PARGPKVSFSCRGAA",
     "pos": 123,
     "wtScore": 50.4032,
     "wtProb": 10.9,
     "k198rScore": 40.6121,
     "k198rProb": 9.7,
     "probDifference": -1.2,
     "geneName": "HCN2",
     "known": "No",
     "id": 2432
    },
    {
     "sequence": "ATISTVSSTQRKRQQ",
     "pos": 77,
     "wtScore": 40.1539,
     "wtProb": 8.7,
     "k198rScore": 24.0189,
     "k198rProb": 7.5,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2433
    },
    {
     "sequence": "AGQAMCLTVFMMVMV",
     "pos": 969,
     "wtScore": -18.8398,
     "wtProb": 6.2,
     "k198rScore": -31.9488,
     "k198rProb": 5,
     "probDifference": -1.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2434
    },
    {
     "sequence": "PILTLLDSRVSCHSQ",
     "pos": 755,
     "wtScore": 41.9314,
     "wtProb": 8.9,
     "k198rScore": 30.0731,
     "k198rProb": 7.7,
     "probDifference": -1.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2435
    },
    {
     "sequence": "TVKQASFTYNKNKIK",
     "pos": 1940,
     "wtScore": -12.4337,
     "wtProb": 7.2,
     "k198rScore": -24.7229,
     "k198rProb": 6,
     "probDifference": -1.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2436
    },
    {
     "sequence": "VCGGILETILVETKI",
     "pos": 602,
     "wtScore": -21.0143,
     "wtProb": 6.1,
     "k198rScore": -35.3644,
     "k198rProb": 4.9,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2437
    },
    {
     "sequence": "IVKIVRSSSSTGQKN",
     "pos": 692,
     "wtScore": 42.0911,
     "wtProb": 8.9,
     "k198rScore": 30.3384,
     "k198rProb": 7.7,
     "probDifference": -1.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2438
    },
    {
     "sequence": "AEDPVQHTSFRNHIL",
     "pos": 925,
     "wtScore": -13.9623,
     "wtProb": 6.9,
     "k198rScore": -26.3143,
     "k198rProb": 5.7,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2439
    },
    {
     "sequence": "DSLQQTNTTHRPLHV",
     "pos": 1705,
     "wtScore": -13.1151,
     "wtProb": 7.1,
     "k198rScore": -25.3219,
     "k198rProb": 5.9,
     "probDifference": -1.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2440
    },
    {
     "sequence": "NRIDLVATWRFYESV",
     "pos": 397,
     "wtScore": -10.5689,
     "wtProb": 7.5,
     "k198rScore": -22.4611,
     "k198rProb": 6.3,
     "probDifference": -1.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2441
    },
    {
     "sequence": "NKKDSCISNHTTIEI",
     "pos": 1053,
     "wtScore": 62.7785,
     "wtProb": 18.6,
     "k198rScore": 52.051,
     "k198rProb": 17.4,
     "probDifference": -1.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2442
    },
    {
     "sequence": "KFQKDLNTGVFNNQE",
     "pos": 610,
     "wtScore": -8.87442,
     "wtProb": 7.8,
     "k198rScore": -20.0544,
     "k198rProb": 6.6,
     "probDifference": -1.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 2443
    },
    {
     "sequence": "LSMIVGATCYAMFIG",
     "pos": 452,
     "wtScore": -18.4913,
     "wtProb": 6.3,
     "k198rScore": -31.3197,
     "k198rProb": 5.1,
     "probDifference": -1.2,
     "geneName": "HCN2",
     "known": "No",
     "id": 2444
    },
    {
     "sequence": "QAQTVRRSPSADQSL",
     "pos": 465,
     "wtScore": 51.0412,
     "wtProb": 11.1,
     "k198rScore": 41.1285,
     "k198rProb": 9.9,
     "probDifference": -1.2,
     "geneName": "KCNQ2",
     "known": "Yes",
     "id": 2445
    },
    {
     "sequence": "DSDLSRRSSSTMSKS",
     "pos": 444,
     "wtScore": 42.0866,
     "wtProb": 8.9,
     "k198rScore": 30.4687,
     "k198rProb": 7.7,
     "probDifference": -1.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2446
    },
    {
     "sequence": "DLLVVSVSLISFGIQ",
     "pos": 999,
     "wtScore": 37.082,
     "wtProb": 8.5,
     "k198rScore": 40.5811,
     "k198rProb": 9.7,
     "probDifference": 1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2447
    },
    {
     "sequence": "AEAEEAESHFSSIPD",
     "pos": 353,
     "wtScore": 61.3875,
     "wtProb": 17.7,
     "k198rScore": 53.9641,
     "k198rProb": 18.9,
     "probDifference": 1.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2448
    },
    {
     "sequence": "HYASPTASQLSLMQQ",
     "pos": 724,
     "wtScore": 44.097,
     "wtProb": 9.1,
     "k198rScore": 33.5623,
     "k198rProb": 8,
     "probDifference": -1.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 2449
    },
    {
     "sequence": "KDRGGDISQKTVLPL",
     "pos": 1997,
     "wtScore": 61.3069,
     "wtProb": 17.7,
     "k198rScore": 53.9407,
     "k198rProb": 18.9,
     "probDifference": 1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2450
    },
    {
     "sequence": "NADPNFVTAMLTKLK",
     "pos": 553,
     "wtScore": -15.8931,
     "wtProb": 6.6,
     "k198rScore": -28.3598,
     "k198rProb": 5.4,
     "probDifference": -1.2,
     "geneName": "HCN2",
     "known": "No",
     "id": 2451
    },
    {
     "sequence": "LIEKYFVSPTLFRVI",
     "pos": 1630,
     "wtScore": 47.7478,
     "wtProb": 10,
     "k198rScore": 37.9408,
     "k198rProb": 8.8,
     "probDifference": -1.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2452
    },
    {
     "sequence": "LIEKYFVSPTLFRVI",
     "pos": 1620,
     "wtScore": 47.7478,
     "wtProb": 10,
     "k198rScore": 37.9408,
     "k198rProb": 8.8,
     "probDifference": -1.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2453
    },
    {
     "sequence": "PMTEQFSSVLSVGNL",
     "pos": 788,
     "wtScore": 48.8659,
     "wtProb": 10.3,
     "k198rScore": 39.0741,
     "k198rProb": 9.1,
     "probDifference": -1.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2454
    },
    {
     "sequence": "LYFVALMTFGNYVLF",
     "pos": 999,
     "wtScore": -19.7896,
     "wtProb": 6.2,
     "k198rScore": -32.5766,
     "k198rProb": 5,
     "probDifference": -1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2455
    },
    {
     "sequence": "DSTFTPSSYSSTGSN",
     "pos": 1804,
     "wtScore": 40.8826,
     "wtProb": 8.8,
     "k198rScore": 28.3022,
     "k198rProb": 7.6,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2456
    },
    {
     "sequence": "PESSIYTTASAKTPP",
     "pos": 727,
     "wtScore": -7.89452,
     "wtProb": 8,
     "k198rScore": -17.9584,
     "k198rProb": 6.8,
     "probDifference": -1.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2457
    },
    {
     "sequence": "AVAGLDSSKAQKYSP",
     "pos": 1966,
     "wtScore": 47.5763,
     "wtProb": 9.9,
     "k198rScore": 37.7963,
     "k198rProb": 8.8,
     "probDifference": -1.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2458
    },
    {
     "sequence": "SLDGHNVSLSAIRTV",
     "pos": 193,
     "wtScore": 40.6789,
     "wtProb": 8.7,
     "k198rScore": 41.0821,
     "k198rProb": 9.9,
     "probDifference": 1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2459
    },
    {
     "sequence": "KDPPPEPSPSKGSPC",
     "pos": 413,
     "wtScore": 59.039,
     "wtProb": 16.2,
     "k198rScore": 49.4421,
     "k198rProb": 15,
     "probDifference": -1.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2460
    },
    {
     "sequence": "WMLLYFISFLLIVSF",
     "pos": 1536,
     "wtScore": 40.3162,
     "wtProb": 8.7,
     "k198rScore": 27.0121,
     "k198rProb": 7.5,
     "probDifference": -1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2461
    },
    {
     "sequence": "TRQVFDISIMILICL",
     "pos": 1530,
     "wtScore": 42.0842,
     "wtProb": 8.9,
     "k198rScore": 30.7517,
     "k198rProb": 7.7,
     "probDifference": -1.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2462
    },
    {
     "sequence": "TRQVFDISIMILICL",
     "pos": 1545,
     "wtScore": 42.0842,
     "wtProb": 8.9,
     "k198rScore": 30.7517,
     "k198rProb": 7.7,
     "probDifference": -1.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2463
    },
    {
     "sequence": "YALFKAMSHMLCIGY",
     "pos": 353,
     "wtScore": 44.2124,
     "wtProb": 9.2,
     "k198rScore": 33.8044,
     "k198rProb": 8,
     "probDifference": -1.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 2464
    },
    {
     "sequence": "YTFTGIYTFESLVKI",
     "pos": 169,
     "wtScore": -8.67826,
     "wtProb": 7.9,
     "k198rScore": -19.5831,
     "k198rProb": 6.7,
     "probDifference": -1.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2465
    },
    {
     "sequence": "QLLPEGTTTETEIRK",
     "pos": 674,
     "wtScore": -16.8633,
     "wtProb": 6.5,
     "k198rScore": -29.044,
     "k198rProb": 5.3,
     "probDifference": -1.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2466
    },
    {
     "sequence": "GENVDEASAAPGHPQ",
     "pos": 12,
     "wtScore": 64.9854,
     "wtProb": 20,
     "k198rScore": 58.902,
     "k198rProb": 21.2,
     "probDifference": 1.2,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2467
    },
    {
     "sequence": "LKLLKMATGMRALLD",
     "pos": 1732,
     "wtScore": -15.4499,
     "wtProb": 6.6,
     "k198rScore": -27.6478,
     "k198rProb": 5.5,
     "probDifference": -1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2468
    },
    {
     "sequence": "NADYVFTSIFTLEII",
     "pos": 961,
     "wtScore": 40.9981,
     "wtProb": 8.8,
     "k198rScore": 28.8274,
     "k198rProb": 7.6,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2469
    },
    {
     "sequence": "VSCQKVITHKMFDHV",
     "pos": 1291,
     "wtScore": -13.6613,
     "wtProb": 7,
     "k198rScore": -25.6307,
     "k198rProb": 5.8,
     "probDifference": -1.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2470
    },
    {
     "sequence": "IDVILSETNHYFCDA",
     "pos": 1322,
     "wtScore": -12.7339,
     "wtProb": 7.1,
     "k198rScore": -24.7668,
     "k198rProb": 5.9,
     "probDifference": -1.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2471
    },
    {
     "sequence": "LALLQVATFKGWMDI",
     "pos": 1410,
     "wtScore": -15.3988,
     "wtProb": 6.6,
     "k198rScore": -27.5775,
     "k198rProb": 5.5,
     "probDifference": -1.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2472
    },
    {
     "sequence": "LALLQVATFKGWMDI",
     "pos": 1414,
     "wtScore": -15.3988,
     "wtProb": 6.6,
     "k198rScore": -27.5775,
     "k198rProb": 5.5,
     "probDifference": -1.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2473
    },
    {
     "sequence": "EMRKRRSSSFHVSMD",
     "pos": 694,
     "wtScore": 40.2289,
     "wtProb": 8.7,
     "k198rScore": 27.1755,
     "k198rProb": 7.5,
     "probDifference": -1.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2474
    },
    {
     "sequence": "TFFTEQTTTPWIIFN",
     "pos": 171,
     "wtScore": -21.2687,
     "wtProb": 6.1,
     "k198rScore": -34.3337,
     "k198rProb": 4.9,
     "probDifference": -1.2,
     "geneName": "HCN1",
     "known": "No",
     "id": 2475
    },
    {
     "sequence": "QEMTNILYWINLVFI",
     "pos": 1563,
     "wtScore": -35.8489,
     "wtProb": 5.7,
     "k198rScore": -8.97596,
     "k198rProb": 6.9,
     "probDifference": 1.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2476
    },
    {
     "sequence": "LLPPTPASHRRSSFN",
     "pos": 1916,
     "wtScore": 44.8743,
     "wtProb": 9.3,
     "k198rScore": 34.8785,
     "k198rProb": 8.1,
     "probDifference": -1.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2477
    },
    {
     "sequence": "FVGFVIVTFQEQGEK",
     "pos": 1153,
     "wtScore": -8.16848,
     "wtProb": 8,
     "k198rScore": -17.8114,
     "k198rProb": 6.8,
     "probDifference": -1.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2478
    },
    {
     "sequence": "EANYARGTRLPLSGE",
     "pos": 29,
     "wtScore": -20.4734,
     "wtProb": 6.1,
     "k198rScore": -32.8104,
     "k198rProb": 5,
     "probDifference": -1.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2479
    },
    {
     "sequence": "QGNVFATSALRSLRF",
     "pos": 194,
     "wtScore": 48.6317,
     "wtProb": 10.3,
     "k198rScore": 39.0059,
     "k198rProb": 9.1,
     "probDifference": -1.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2480
    },
    {
     "sequence": "PLLGTEVSAPCQGPS",
     "pos": 773,
     "wtScore": 56.3327,
     "wtProb": 14.3,
     "k198rScore": 49.9359,
     "k198rProb": 15.4,
     "probDifference": 1.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2481
    },
    {
     "sequence": "ATQFIEFSKLSDFAA",
     "pos": 1810,
     "wtScore": 53.1751,
     "wtProb": 12.3,
     "k198rScore": 43.4613,
     "k198rProb": 11.1,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2482
    },
    {
     "sequence": "WAGGPPGTGGHGPLS",
     "pos": 642,
     "wtScore": -8.13925,
     "wtProb": 8,
     "k198rScore": -16.9392,
     "k198rProb": 6.8,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2483
    },
    {
     "sequence": "VSMTTVGYGDMYPVT",
     "pos": 374,
     "wtScore": -38.1524,
     "wtProb": 5.5,
     "k198rScore": -11.5168,
     "k198rProb": 6.6,
     "probDifference": 1.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2484
    },
    {
     "sequence": "EDATKFTSIPASFWW",
     "pos": 366,
     "wtScore": 41.9441,
     "wtProb": 8.9,
     "k198rScore": 31.1794,
     "k198rProb": 7.7,
     "probDifference": -1.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2485
    },
    {
     "sequence": "MYNKMAKTQSQPILN",
     "pos": 538,
     "wtScore": -16.8471,
     "wtProb": 6.5,
     "k198rScore": -28.8786,
     "k198rProb": 5.3,
     "probDifference": -1.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2486
    },
    {
     "sequence": "TLNSTSSTTTPTSRM",
     "pos": 652,
     "wtScore": -22.1778,
     "wtProb": 6,
     "k198rScore": -36.6769,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 2487
    },
    {
     "sequence": "AEKDEDDTKFKSIPA",
     "pos": 358,
     "wtScore": -8.71385,
     "wtProb": 7.9,
     "k198rScore": -19.0986,
     "k198rProb": 6.7,
     "probDifference": -1.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2488
    },
    {
     "sequence": "AQAVAAASAASRDFS",
     "pos": 465,
     "wtScore": 63.3177,
     "wtProb": 19,
     "k198rScore": 56.0683,
     "k198rProb": 20.2,
     "probDifference": 1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2489
    },
    {
     "sequence": "KYSPSHSTRSWATPP",
     "pos": 1977,
     "wtScore": -21.2363,
     "wtProb": 6.1,
     "k198rScore": -33.7938,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2490
    },
    {
     "sequence": "LTVFQCITMEGWTDV",
     "pos": 361,
     "wtScore": -16.1459,
     "wtProb": 6.5,
     "k198rScore": -28.2047,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2491
    },
    {
     "sequence": "LTVFQCITMEGWTDV",
     "pos": 360,
     "wtScore": -16.1459,
     "wtProb": 6.5,
     "k198rScore": -28.2047,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2492
    },
    {
     "sequence": "ISGLRFETQLKTLAQ",
     "pos": 45,
     "wtScore": -20.4952,
     "wtProb": 6.1,
     "k198rScore": -32.5025,
     "k198rProb": 5,
     "probDifference": -1.1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2493
    },
    {
     "sequence": "ISGLRFETQLKTLAQ",
     "pos": 49,
     "wtScore": -20.4952,
     "wtProb": 6.1,
     "k198rScore": -32.5025,
     "k198rProb": 5,
     "probDifference": -1.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2494
    },
    {
     "sequence": "EEGKRNTSMPTSETE",
     "pos": 464,
     "wtScore": 51.0714,
     "wtProb": 11.2,
     "k198rScore": 41.3135,
     "k198rProb": 10,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2495
    },
    {
     "sequence": "IGNIMIVTTLLQFMF",
     "pos": 1021,
     "wtScore": -22.2265,
     "wtProb": 6,
     "k198rScore": -36.3564,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2496
    },
    {
     "sequence": "YLLRFLSSPNKWKFF",
     "pos": 254,
     "wtScore": 43.6379,
     "wtProb": 9.1,
     "k198rScore": 33.4629,
     "k198rProb": 7.9,
     "probDifference": -1.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2497
    },
    {
     "sequence": "SENGHHSSHKHDREP",
     "pos": 1787,
     "wtScore": 50.4069,
     "wtProb": 10.9,
     "k198rScore": 40.762,
     "k198rProb": 9.8,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2498
    },
    {
     "sequence": "NPWFEHVSMLVIMLN",
     "pos": 105,
     "wtScore": 43.3332,
     "wtProb": 9,
     "k198rScore": 32.9878,
     "k198rProb": 7.9,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2499
    },
    {
     "sequence": "QPNSSKQTVLSWQAA",
     "pos": 48,
     "wtScore": -19.258,
     "wtProb": 6.2,
     "k198rScore": -31.385,
     "k198rProb": 5.1,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 2500
    },
    {
     "sequence": "YNGMASTSSWAALYF",
     "pos": 987,
     "wtScore": 39.1996,
     "wtProb": 8.6,
     "k198rScore": 23.9385,
     "k198rProb": 7.5,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2501
    },
    {
     "sequence": "PKSSLKGSNPLKSRS",
     "pos": 706,
     "wtScore": 55.494,
     "wtProb": 13.7,
     "k198rScore": 45.9606,
     "k198rProb": 12.6,
     "probDifference": -1.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2502
    },
    {
     "sequence": "PTVLPILTLLDSRVS",
     "pos": 751,
     "wtScore": -13.9444,
     "wtProb": 6.9,
     "k198rScore": -25.8272,
     "k198rProb": 5.8,
     "probDifference": -1.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2503
    },
    {
     "sequence": "HSIEKNITVNYNGTL",
     "pos": 296,
     "wtScore": -12.3322,
     "wtProb": 7.2,
     "k198rScore": -24.2691,
     "k198rProb": 6,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2504
    },
    {
     "sequence": "MRICNLISMMLLLCH",
     "pos": 371,
     "wtScore": 39.763,
     "wtProb": 8.7,
     "k198rScore": 26.6094,
     "k198rProb": 7.5,
     "probDifference": -1.1,
     "geneName": "HCN2",
     "known": "No",
     "id": 2505
    },
    {
     "sequence": "ETQTKRSTFDNFPQA",
     "pos": 686,
     "wtScore": -15.7065,
     "wtProb": 6.6,
     "k198rScore": -27.7504,
     "k198rProb": 5.5,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2506
    },
    {
     "sequence": "GFMQRQFTSMLQPGV",
     "pos": 98,
     "wtScore": -18.8648,
     "wtProb": 6.2,
     "k198rScore": -30.8462,
     "k198rProb": 5.1,
     "probDifference": -1.1,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 2507
    },
    {
     "sequence": "GAWSSRRSSWSSLGR",
     "pos": 1142,
     "wtScore": 43.0395,
     "wtProb": 9,
     "k198rScore": 32.7694,
     "k198rProb": 7.9,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2508
    },
    {
     "sequence": "YNHRVEISIFFIIYI",
     "pos": 1128,
     "wtScore": 46.2521,
     "wtProb": 9.6,
     "k198rScore": 36.5246,
     "k198rProb": 8.4,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2509
    },
    {
     "sequence": "KQGSTTATRPPRALL",
     "pos": 97,
     "wtScore": -17.485,
     "wtProb": 6.4,
     "k198rScore": -29.3041,
     "k198rProb": 5.3,
     "probDifference": -1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2510
    },
    {
     "sequence": "TSSSEGSTVDIGAPA",
     "pos": 1144,
     "wtScore": -14.4144,
     "wtProb": 6.8,
     "k198rScore": -26.3507,
     "k198rProb": 5.7,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2511
    },
    {
     "sequence": "DPADEEVSHITSSAC",
     "pos": 2101,
     "wtScore": 59.2591,
     "wtProb": 16.3,
     "k198rScore": 52.1541,
     "k198rProb": 17.5,
     "probDifference": 1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2512
    },
    {
     "sequence": "LSDVMILTVFCLSVF",
     "pos": 257,
     "wtScore": -16.6047,
     "wtProb": 6.5,
     "k198rScore": -28.4661,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2513
    },
    {
     "sequence": "LSDVMILTVFCLSVF",
     "pos": 253,
     "wtScore": -16.6047,
     "wtProb": 6.5,
     "k198rScore": -28.4661,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2514
    },
    {
     "sequence": "LSDVMILTVFCLSVF",
     "pos": 254,
     "wtScore": -16.6047,
     "wtProb": 6.5,
     "k198rScore": -28.4661,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2515
    },
    {
     "sequence": "LSDVMILTVFCLSVF",
     "pos": 253,
     "wtScore": -16.6047,
     "wtProb": 6.5,
     "k198rScore": -28.4661,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2516
    },
    {
     "sequence": "NCVFMTLSNPPDWTK",
     "pos": 147,
     "wtScore": 57.0043,
     "wtProb": 14.7,
     "k198rScore": 47.5801,
     "k198rProb": 13.6,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2517
    },
    {
     "sequence": "MIEKYFVSPTLFRVI",
     "pos": 1615,
     "wtScore": 46.5055,
     "wtProb": 9.6,
     "k198rScore": 36.8037,
     "k198rProb": 8.5,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2518
    },
    {
     "sequence": "LLRIFKITRYWNSLS",
     "pos": 630,
     "wtScore": -22.6911,
     "wtProb": 6,
     "k198rScore": -37.9529,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2519
    },
    {
     "sequence": "LSRRSSSTMSKSEYM",
     "pos": 447,
     "wtScore": -18.7057,
     "wtProb": 6.2,
     "k198rScore": -30.7092,
     "k198rProb": 5.1,
     "probDifference": -1.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2520
    },
    {
     "sequence": "MKDAFARSIEMMDIV",
     "pos": 456,
     "wtScore": 51.0572,
     "wtProb": 11.1,
     "k198rScore": 41.319,
     "k198rProb": 10,
     "probDifference": -1.1,
     "geneName": "KCNB1",
     "known": "Yes",
     "id": 2521
    },
    {
     "sequence": "FIQHGVVSVLTKGNK",
     "pos": 589,
     "wtScore": 56.6662,
     "wtProb": 14.5,
     "k198rScore": 47.3033,
     "k198rProb": 13.4,
     "probDifference": -1.1,
     "geneName": "HCN2",
     "known": "No",
     "id": 2522
    },
    {
     "sequence": "IRKRRSSSYHVSMDL",
     "pos": 686,
     "wtScore": 43.3466,
     "wtProb": 9,
     "k198rScore": 33.1963,
     "k198rProb": 7.9,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2523
    },
    {
     "sequence": "RVLRALKTISVIPGL",
     "pos": 225,
     "wtScore": -20.2415,
     "wtProb": 6.1,
     "k198rScore": -31.9092,
     "k198rProb": 5,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2524
    },
    {
     "sequence": "RVLRALKTISVIPGL",
     "pos": 229,
     "wtScore": -20.2415,
     "wtProb": 6.1,
     "k198rScore": -31.9092,
     "k198rProb": 5,
     "probDifference": -1.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2525
    },
    {
     "sequence": "RVLRALKTISVIPGL",
     "pos": 225,
     "wtScore": -20.2415,
     "wtProb": 6.1,
     "k198rScore": -31.9092,
     "k198rProb": 5,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2526
    },
    {
     "sequence": "RVLRALKTISVIPGL",
     "pos": 226,
     "wtScore": -20.2415,
     "wtProb": 6.1,
     "k198rScore": -31.9092,
     "k198rProb": 5,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2527
    },
    {
     "sequence": "ATWRFYESVVSFPFF",
     "pos": 403,
     "wtScore": 56.5868,
     "wtProb": 14.5,
     "k198rScore": 50.0997,
     "k198rProb": 15.6,
     "probDifference": 1.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2528
    },
    {
     "sequence": "SITADGESPPATKIN",
     "pos": 814,
     "wtScore": 53.3475,
     "wtProb": 12.4,
     "k198rScore": 47.4235,
     "k198rProb": 13.5,
     "probDifference": 1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2529
    },
    {
     "sequence": "LSLLQVATFKGWMDI",
     "pos": 1429,
     "wtScore": -15.143,
     "wtProb": 6.7,
     "k198rScore": -27.0029,
     "k198rProb": 5.6,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2530
    },
    {
     "sequence": "LSLLQVATFKGWMDI",
     "pos": 1419,
     "wtScore": -15.143,
     "wtProb": 6.7,
     "k198rScore": -27.0029,
     "k198rProb": 5.6,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2531
    },
    {
     "sequence": "GGLSVLRTFRLLRVL",
     "pos": 884,
     "wtScore": -22.6889,
     "wtProb": 6,
     "k198rScore": -36.8452,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2532
    },
    {
     "sequence": "RVFKLAKSWPTLNML",
     "pos": 871,
     "wtScore": 51.8843,
     "wtProb": 11.5,
     "k198rScore": 42.0985,
     "k198rProb": 10.4,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2533
    },
    {
     "sequence": "RVFKLAKSWPTLNML",
     "pos": 862,
     "wtScore": 51.8843,
     "wtProb": 11.5,
     "k198rScore": 42.0985,
     "k198rProb": 10.4,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2534
    },
    {
     "sequence": "RVFKLAKSWPTLNML",
     "pos": 863,
     "wtScore": 51.8843,
     "wtProb": 11.5,
     "k198rScore": 42.0985,
     "k198rProb": 10.4,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2535
    },
    {
     "sequence": "RVFKLAKSWPTLNML",
     "pos": 856,
     "wtScore": 51.8843,
     "wtProb": 11.5,
     "k198rScore": 42.0985,
     "k198rProb": 10.4,
     "probDifference": -1.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2536
    },
    {
     "sequence": "SWWILRKTCFLIVEH",
     "pos": 1189,
     "wtScore": -21.9456,
     "wtProb": 6,
     "k198rScore": -34.543,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2537
    },
    {
     "sequence": "TDDNGTTTETEMRKR",
     "pos": 684,
     "wtScore": 28.6479,
     "wtProb": 8.4,
     "k198rScore": -7.06441,
     "k198rProb": 7.3,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2538
    },
    {
     "sequence": "IGNIVIVTTLLQFMF",
     "pos": 1055,
     "wtScore": -22.3113,
     "wtProb": 6,
     "k198rScore": -35.148,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2539
    },
    {
     "sequence": "IYAIFGMSNFAYVKK",
     "pos": 1674,
     "wtScore": 53.1766,
     "wtProb": 12.3,
     "k198rScore": 43.5503,
     "k198rProb": 11.2,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2540
    },
    {
     "sequence": "VGIFFFVSYIIISFL",
     "pos": 1752,
     "wtScore": 41.892,
     "wtProb": 8.9,
     "k198rScore": 31.5112,
     "k198rProb": 7.7,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2541
    },
    {
     "sequence": "VGIFFFVSYIIISFL",
     "pos": 1767,
     "wtScore": 41.892,
     "wtProb": 8.9,
     "k198rScore": 31.5112,
     "k198rProb": 7.7,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2542
    },
    {
     "sequence": "VGIFFFVSYIIISFL",
     "pos": 1757,
     "wtScore": 41.892,
     "wtProb": 8.9,
     "k198rScore": 31.5112,
     "k198rProb": 7.7,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2543
    },
    {
     "sequence": "VGIFFFVSYIIISFL",
     "pos": 1747,
     "wtScore": 41.892,
     "wtProb": 8.9,
     "k198rScore": 31.5112,
     "k198rProb": 7.7,
     "probDifference": -1.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2544
    },
    {
     "sequence": "IVFTTIFTIEIALKI",
     "pos": 944,
     "wtScore": -19.9635,
     "wtProb": 6.2,
     "k198rScore": -31.41,
     "k198rProb": 5.1,
     "probDifference": -1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2545
    },
    {
     "sequence": "TFFKDETTAPWIVFN",
     "pos": 240,
     "wtScore": -14.7969,
     "wtProb": 6.8,
     "k198rScore": -26.5936,
     "k198rProb": 5.6,
     "probDifference": -1.1,
     "geneName": "HCN2",
     "known": "No",
     "id": 2546
    },
    {
     "sequence": "VTPESRASSSGAIVP",
     "pos": 2294,
     "wtScore": 44.6917,
     "wtProb": 9.2,
     "k198rScore": 34.9395,
     "k198rProb": 8.1,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2547
    },
    {
     "sequence": "LLRKANPSRCHSRES",
     "pos": 1896,
     "wtScore": 46.4872,
     "wtProb": 9.6,
     "k198rScore": 36.8546,
     "k198rProb": 8.5,
     "probDifference": -1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2548
    },
    {
     "sequence": "LWWNLRKTCYKIVEH",
     "pos": 1199,
     "wtScore": -22.3439,
     "wtProb": 6,
     "k198rScore": -34.8963,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2549
    },
    {
     "sequence": "FIFLNCVTIALERPD",
     "pos": 1309,
     "wtScore": -15.6515,
     "wtProb": 6.6,
     "k198rScore": -27.4765,
     "k198rProb": 5.5,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2550
    },
    {
     "sequence": "DMHGSGVTFHTYSNS",
     "pos": 200,
     "wtScore": -19.3175,
     "wtProb": 6.2,
     "k198rScore": -30.9093,
     "k198rProb": 5.1,
     "probDifference": -1.1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2551
    },
    {
     "sequence": "IFNVASDTVFLLDLI",
     "pos": 183,
     "wtScore": -16.8613,
     "wtProb": 6.5,
     "k198rScore": -28.5484,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 2552
    },
    {
     "sequence": "DSWGKQYSYALFKAM",
     "pos": 345,
     "wtScore": 45.3323,
     "wtProb": 9.4,
     "k198rScore": 35.7411,
     "k198rProb": 8.3,
     "probDifference": -1.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 2553
    },
    {
     "sequence": "GSAFTFPSQQSPRNE",
     "pos": 594,
     "wtScore": 48.1024,
     "wtProb": 10.1,
     "k198rScore": 38.5868,
     "k198rProb": 9,
     "probDifference": -1.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2554
    },
    {
     "sequence": "NLVARKVSVSRMLSL",
     "pos": 1919,
     "wtScore": 40.9719,
     "wtProb": 8.8,
     "k198rScore": 30.1788,
     "k198rProb": 7.7,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2555
    },
    {
     "sequence": "PPAEDEGSARPSAAE",
     "pos": 2202,
     "wtScore": 67.3125,
     "wtProb": 21.1,
     "k198rScore": 61.7279,
     "k198rProb": 22.2,
     "probDifference": 1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2556
    },
    {
     "sequence": "SVIPGLKTIVGALIQ",
     "pos": 234,
     "wtScore": -16.9932,
     "wtProb": 6.4,
     "k198rScore": -28.613,
     "k198rProb": 5.3,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2557
    },
    {
     "sequence": "SVIPGLKTIVGALIQ",
     "pos": 234,
     "wtScore": -16.9932,
     "wtProb": 6.4,
     "k198rScore": -28.613,
     "k198rProb": 5.3,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2558
    },
    {
     "sequence": "SVIPGLKTIVGALIQ",
     "pos": 238,
     "wtScore": -16.9932,
     "wtProb": 6.4,
     "k198rScore": -28.613,
     "k198rProb": 5.3,
     "probDifference": -1.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2559
    },
    {
     "sequence": "SVIPGLKTIVGALIQ",
     "pos": 235,
     "wtScore": -16.9932,
     "wtProb": 6.4,
     "k198rScore": -28.613,
     "k198rProb": 5.3,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2560
    },
    {
     "sequence": "PYYINKKTFIVLNKG",
     "pos": 89,
     "wtScore": -17.3849,
     "wtProb": 6.4,
     "k198rScore": -28.9481,
     "k198rProb": 5.3,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2561
    },
    {
     "sequence": "PYYINKKTFIVLNKG",
     "pos": 88,
     "wtScore": -17.3849,
     "wtProb": 6.4,
     "k198rScore": -28.9481,
     "k198rProb": 5.3,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2562
    },
    {
     "sequence": "AGHLDMLSRIKSLQS",
     "pos": 579,
     "wtScore": 44.3189,
     "wtProb": 9.2,
     "k198rScore": 34.5237,
     "k198rProb": 8.1,
     "probDifference": -1.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2563
    },
    {
     "sequence": "YHCINTTTGDRFDIE",
     "pos": 1380,
     "wtScore": -8.42667,
     "wtProb": 7.9,
     "k198rScore": -16.9914,
     "k198rProb": 6.8,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2564
    },
    {
     "sequence": "AFWWAVVSMTTVGYG",
     "pos": 370,
     "wtScore": 43.7114,
     "wtProb": 9.1,
     "k198rScore": 33.8623,
     "k198rProb": 8,
     "probDifference": -1.1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2565
    },
    {
     "sequence": "AFWWAVVSMTTVGYG",
     "pos": 368,
     "wtScore": 43.7114,
     "wtProb": 9.1,
     "k198rScore": 33.8623,
     "k198rProb": 8,
     "probDifference": -1.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2566
    },
    {
     "sequence": "TADFSLTTPQHISTI",
     "pos": 747,
     "wtScore": -23.8035,
     "wtProb": 5.9,
     "k198rScore": -37.9562,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2567
    },
    {
     "sequence": "AHSKELITAWYIGFL",
     "pos": 262,
     "wtScore": -17.8776,
     "wtProb": 6.3,
     "k198rScore": -29.3572,
     "k198rProb": 5.3,
     "probDifference": -1.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2568
    },
    {
     "sequence": "PDSFRFFTRESLAAI",
     "pos": 17,
     "wtScore": -19.3076,
     "wtProb": 6.2,
     "k198rScore": -30.6589,
     "k198rProb": 5.1,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2569
    },
    {
     "sequence": "PYPALAATVFFCLGQ",
     "pos": 77,
     "wtScore": -20.5875,
     "wtProb": 6.1,
     "k198rScore": -31.6913,
     "k198rProb": 5,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2570
    },
    {
     "sequence": "SLSNLVASLLNSVRS",
     "pos": 642,
     "wtScore": 39.1355,
     "wtProb": 8.6,
     "k198rScore": 40.6254,
     "k198rProb": 9.7,
     "probDifference": 1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2571
    },
    {
     "sequence": "LRVPKPNTIELIAMD",
     "pos": 1825,
     "wtScore": -23.5017,
     "wtProb": 6,
     "k198rScore": -36.1704,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2572
    },
    {
     "sequence": "VAAASAASRDFSGIG",
     "pos": 468,
     "wtScore": 55.8487,
     "wtProb": 14,
     "k198rScore": 46.3433,
     "k198rProb": 12.9,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2573
    },
    {
     "sequence": "SFWWATITMTTVGYG",
     "pos": 377,
     "wtScore": -18.9951,
     "wtProb": 6.2,
     "k198rScore": -30.3778,
     "k198rProb": 5.1,
     "probDifference": -1.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2574
    },
    {
     "sequence": "SFWWATITMTTVGYG",
     "pos": 373,
     "wtScore": -18.9951,
     "wtProb": 6.2,
     "k198rScore": -30.3778,
     "k198rProb": 5.1,
     "probDifference": -1.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2575
    },
    {
     "sequence": "PLSGEGPTSQPNSSK",
     "pos": 39,
     "wtScore": -20.0691,
     "wtProb": 6.2,
     "k198rScore": -31.111,
     "k198rProb": 5.1,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2576
    },
    {
     "sequence": "ADAESLTSAQKEEEE",
     "pos": 763,
     "wtScore": 60.7356,
     "wtProb": 17.3,
     "k198rScore": 53.403,
     "k198rProb": 18.4,
     "probDifference": 1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2577
    },
    {
     "sequence": "VPMYSSQTQTYGASR",
     "pos": 375,
     "wtScore": -13.4049,
     "wtProb": 7,
     "k198rScore": -24.8268,
     "k198rProb": 5.9,
     "probDifference": -1.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2578
    },
    {
     "sequence": "MYSSQTQTYGASRLI",
     "pos": 377,
     "wtScore": -10.5952,
     "wtProb": 7.5,
     "k198rScore": -21.6029,
     "k198rProb": 6.4,
     "probDifference": -1.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2579
    },
    {
     "sequence": "KPPASLRSSPCAPWG",
     "pos": 1125,
     "wtScore": 45.979,
     "wtProb": 9.5,
     "k198rScore": 36.57,
     "k198rProb": 8.4,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2580
    },
    {
     "sequence": "STPRLGPTPAARAAA",
     "pos": 850,
     "wtScore": -15.7849,
     "wtProb": 6.6,
     "k198rScore": -27.4134,
     "k198rProb": 5.5,
     "probDifference": -1.1,
     "geneName": "HCN2",
     "known": "Yes",
     "id": 2581
    },
    {
     "sequence": "KEAVSAASEDDIFRR",
     "pos": 1738,
     "wtScore": 124.813,
     "wtProb": 34.3,
     "k198rScore": 78.6123,
     "k198rProb": 35.4,
     "probDifference": 1.1,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 2582
    },
    {
     "sequence": "STRPASSSTPRLGPT",
     "pos": 843,
     "wtScore": 39.0355,
     "wtProb": 8.6,
     "k198rScore": 27.1791,
     "k198rProb": 7.5,
     "probDifference": -1.1,
     "geneName": "HCN2",
     "known": "No",
     "id": 2583
    },
    {
     "sequence": "LFPPAGNSVCHNHHN",
     "pos": 1734,
     "wtScore": 49.0881,
     "wtProb": 10.4,
     "k198rScore": 39.5347,
     "k198rProb": 9.3,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2584
    },
    {
     "sequence": "PPSYDRVTKPIVEKH",
     "pos": 1989,
     "wtScore": -15.5893,
     "wtProb": 6.6,
     "k198rScore": -27.1757,
     "k198rProb": 5.6,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2585
    },
    {
     "sequence": "PSPDRRDSASPGAAG",
     "pos": 865,
     "wtScore": 52.9325,
     "wtProb": 12.1,
     "k198rScore": 46.8167,
     "k198rProb": 13.2,
     "probDifference": 1.1,
     "geneName": "HCN2",
     "known": "No",
     "id": 2586
    },
    {
     "sequence": "IMYAAVDSRKPDEQP",
     "pos": 1424,
     "wtScore": 52.6824,
     "wtProb": 12,
     "k198rScore": 46.5935,
     "k198rProb": 13,
     "probDifference": 1.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2587
    },
    {
     "sequence": "WAVVSMTTVGYGDMY",
     "pos": 371,
     "wtScore": -22.7859,
     "wtProb": 6,
     "k198rScore": -34.3596,
     "k198rProb": 4.9,
     "probDifference": -1.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2588
    },
    {
     "sequence": "PWNWLDFSVIMMAYI",
     "pos": 199,
     "wtScore": 46.0757,
     "wtProb": 9.5,
     "k198rScore": 36.6992,
     "k198rProb": 8.5,
     "probDifference": -1.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2589
    },
    {
     "sequence": "AGNATISTVSSTQRK",
     "pos": 74,
     "wtScore": -19.8794,
     "wtProb": 6.2,
     "k198rScore": -30.6692,
     "k198rProb": 5.1,
     "probDifference": -1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2590
    },
    {
     "sequence": "WTKNVEYTFTGIYTF",
     "pos": 159,
     "wtScore": -12.6641,
     "wtProb": 7.1,
     "k198rScore": -24.1896,
     "k198rProb": 6.1,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2591
    },
    {
     "sequence": "WTKNVEYTFTGIYTF",
     "pos": 160,
     "wtScore": -12.6641,
     "wtProb": 7.1,
     "k198rScore": -24.1896,
     "k198rProb": 6.1,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2592
    },
    {
     "sequence": "WTKNVEYTFTGIYTF",
     "pos": 159,
     "wtScore": -12.6641,
     "wtProb": 7.1,
     "k198rScore": -24.1896,
     "k198rProb": 6.1,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2593
    },
    {
     "sequence": "QQSQPPQTQPQQPSP",
     "pos": 745,
     "wtScore": -12.1102,
     "wtProb": 7.2,
     "k198rScore": -23.5449,
     "k198rProb": 6.2,
     "probDifference": -1.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 2594
    },
    {
     "sequence": "QLVVLVKTMDNVATF",
     "pos": 910,
     "wtScore": -18.22,
     "wtProb": 6.3,
     "k198rScore": -29.4952,
     "k198rProb": 5.2,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2595
    },
    {
     "sequence": "NSNLSQTSRSSRMLA",
     "pos": 625,
     "wtScore": 38.3097,
     "wtProb": 8.6,
     "k198rScore": 25.3034,
     "k198rProb": 7.5,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2596
    },
    {
     "sequence": "VRKRRLSSYQISMEM",
     "pos": 687,
     "wtScore": 44.6105,
     "wtProb": 9.2,
     "k198rScore": 35.1689,
     "k198rProb": 8.2,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2597
    },
    {
     "sequence": "MALFTVSTFEGWPEL",
     "pos": 1132,
     "wtScore": -16.5151,
     "wtProb": 6.5,
     "k198rScore": -27.9167,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2598
    },
    {
     "sequence": "AGVITKSSKEMKLTD",
     "pos": 526,
     "wtScore": 39.9035,
     "wtProb": 8.7,
     "k198rScore": 29.0953,
     "k198rProb": 7.6,
     "probDifference": -1.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 2599
    },
    {
     "sequence": "PEKTDGSSSTTSPPS",
     "pos": 1961,
     "wtScore": 44.1181,
     "wtProb": 9.1,
     "k198rScore": 34.6561,
     "k198rProb": 8.1,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2600
    },
    {
     "sequence": "VCGGITETILVELEI",
     "pos": 601,
     "wtScore": -21.092,
     "wtProb": 6.1,
     "k198rScore": -31.917,
     "k198rProb": 5,
     "probDifference": -1.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2601
    },
    {
     "sequence": "WENLYQLTLRAAGKT",
     "pos": 390,
     "wtScore": -17.3126,
     "wtProb": 6.4,
     "k198rScore": -28.5918,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2602
    },
    {
     "sequence": "WENLYQLTLRAAGKT",
     "pos": 392,
     "wtScore": -17.3126,
     "wtProb": 6.4,
     "k198rScore": -28.5918,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2603
    },
    {
     "sequence": "WENLYQLTLRAAGKT",
     "pos": 391,
     "wtScore": -17.3126,
     "wtProb": 6.4,
     "k198rScore": -28.5918,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2604
    },
    {
     "sequence": "WENLYQLTLRAAGKT",
     "pos": 378,
     "wtScore": -17.3126,
     "wtProb": 6.4,
     "k198rScore": -28.5918,
     "k198rProb": 5.4,
     "probDifference": -1.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2605
    },
    {
     "sequence": "AAPGPRATPLRRAES",
     "pos": 1190,
     "wtScore": -21.8518,
     "wtProb": 6,
     "k198rScore": -32.7818,
     "k198rProb": 5,
     "probDifference": -1.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2606
    },
    {
     "sequence": "AEENSRISITFFRLF",
     "pos": 1371,
     "wtScore": 41.352,
     "wtProb": 8.8,
     "k198rScore": 31.471,
     "k198rProb": 7.7,
     "probDifference": -1.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2607
    },
    {
     "sequence": "LSPVYFVTFVLVAQF",
     "pos": 1841,
     "wtScore": -21.7179,
     "wtProb": 6,
     "k198rScore": -32.5446,
     "k198rProb": 5,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2608
    },
    {
     "sequence": "AASLRLATGLGTMNY",
     "pos": 605,
     "wtScore": -16.3174,
     "wtProb": 6.5,
     "k198rScore": -27.7143,
     "k198rProb": 5.5,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2609
    },
    {
     "sequence": "NNKERFRTAFRMKAY",
     "pos": 473,
     "wtScore": -15.3301,
     "wtProb": 6.7,
     "k198rScore": -26.8088,
     "k198rProb": 5.6,
     "probDifference": -1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2610
    },
    {
     "sequence": "EAAQQAATATASEHS",
     "pos": 462,
     "wtScore": -8.68208,
     "wtProb": 7.9,
     "k198rScore": -17.7368,
     "k198rProb": 6.8,
     "probDifference": -1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2611
    },
    {
     "sequence": "FVLILLNTICLAMQH",
     "pos": 1252,
     "wtScore": -24.8491,
     "wtProb": 5.9,
     "k198rScore": -39.1416,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2612
    },
    {
     "sequence": "PEDDSNSTNHNLEKV",
     "pos": 156,
     "wtScore": -9.62195,
     "wtProb": 7.7,
     "k198rScore": -19.9644,
     "k198rProb": 6.6,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2613
    },
    {
     "sequence": "ARRVRPVSLMVPSQA",
     "pos": 2097,
     "wtScore": 42.5735,
     "wtProb": 8.9,
     "k198rScore": 41.1621,
     "k198rProb": 10,
     "probDifference": 1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2614
    },
    {
     "sequence": "IICVNVITMSMEHYN",
     "pos": 1632,
     "wtScore": -18.8216,
     "wtProb": 6.2,
     "k198rScore": -29.8379,
     "k198rProb": 5.2,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2615
    },
    {
     "sequence": "VSVSRMLSLPNDSYM",
     "pos": 1925,
     "wtScore": 42.484,
     "wtProb": 8.9,
     "k198rScore": 41.1403,
     "k198rProb": 10,
     "probDifference": 1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2616
    },
    {
     "sequence": "PDSFNFFTRESLAAI",
     "pos": 17,
     "wtScore": -17.09,
     "wtProb": 6.4,
     "k198rScore": -28.2612,
     "k198rProb": 5.4,
     "probDifference": -1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2617
    },
    {
     "sequence": "FIGKMHKTCFFADSD",
     "pos": 299,
     "wtScore": -16.0772,
     "wtProb": 6.6,
     "k198rScore": -27.3932,
     "k198rProb": 5.5,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2618
    },
    {
     "sequence": "DDNHLSPSRWKWARK",
     "pos": 489,
     "wtScore": 47.3979,
     "wtProb": 9.9,
     "k198rScore": 38.154,
     "k198rProb": 8.9,
     "probDifference": -1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2619
    },
    {
     "sequence": "VEHNWFETFIIFMIL",
     "pos": 1201,
     "wtScore": -13.7137,
     "wtProb": 7,
     "k198rScore": -24.9321,
     "k198rProb": 5.9,
     "probDifference": -1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2620
    },
    {
     "sequence": "ILFIVLSTIALSLNT",
     "pos": 206,
     "wtScore": -25.2732,
     "wtProb": 5.9,
     "k198rScore": -42.3155,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2621
    },
    {
     "sequence": "MRKRRSSSFHVSMDF",
     "pos": 695,
     "wtScore": 44.2798,
     "wtProb": 9.2,
     "k198rScore": 34.9389,
     "k198rProb": 8.1,
     "probDifference": -1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2622
    },
    {
     "sequence": "TTPQHISTILLEETP",
     "pos": 753,
     "wtScore": -18.9958,
     "wtProb": 6.2,
     "k198rScore": -29.9027,
     "k198rProb": 5.2,
     "probDifference": -1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2623
    },
    {
     "sequence": "TLRAAGKTYMIFFVL",
     "pos": 399,
     "wtScore": -19.7695,
     "wtProb": 6.2,
     "k198rScore": -30.3776,
     "k198rProb": 5.1,
     "probDifference": -1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2624
    },
    {
     "sequence": "TLRAAGKTYMIFFVL",
     "pos": 398,
     "wtScore": -19.7695,
     "wtProb": 6.2,
     "k198rScore": -30.3776,
     "k198rProb": 5.1,
     "probDifference": -1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2625
    },
    {
     "sequence": "TLRAAGKTYMIFFVL",
     "pos": 397,
     "wtScore": -19.7695,
     "wtProb": 6.2,
     "k198rScore": -30.3776,
     "k198rProb": 5.1,
     "probDifference": -1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2626
    },
    {
     "sequence": "TLRAAGKTYMIFFVL",
     "pos": 385,
     "wtScore": -19.7695,
     "wtProb": 6.2,
     "k198rScore": -30.3776,
     "k198rProb": 5.1,
     "probDifference": -1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2627
    },
    {
     "sequence": "IVNDTIFTNLILFFI",
     "pos": 902,
     "wtScore": -20.6765,
     "wtProb": 6.1,
     "k198rScore": -31.0917,
     "k198rProb": 5.1,
     "probDifference": -1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2628
    },
    {
     "sequence": "AMEHHPMTPQFEHVL",
     "pos": 777,
     "wtScore": -11.0397,
     "wtProb": 7.4,
     "k198rScore": -21.8492,
     "k198rProb": 6.4,
     "probDifference": -1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2629
    },
    {
     "sequence": "NSNVSQASMSSRMVP",
     "pos": 628,
     "wtScore": 49.8026,
     "wtProb": 10.6,
     "k198rScore": 40.4409,
     "k198rProb": 9.6,
     "probDifference": -1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2630
    },
    {
     "sequence": "AVPMPEASAFFIFSS",
     "pos": 877,
     "wtScore": 56.3617,
     "wtProb": 14.3,
     "k198rScore": 47.0059,
     "k198rProb": 13.3,
     "probDifference": -1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2631
    },
    {
     "sequence": "YYADYSPTRRSIHSL",
     "pos": 1605,
     "wtScore": -17.8306,
     "wtProb": 6.3,
     "k198rScore": -28.8347,
     "k198rProb": 5.3,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2632
    },
    {
     "sequence": "SAFPQTFTTQRPLHI",
     "pos": 1770,
     "wtScore": -19.1908,
     "wtProb": 6.2,
     "k198rScore": -29.9421,
     "k198rProb": 5.2,
     "probDifference": -1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2633
    },
    {
     "sequence": "PGESPGATPAPGPPP",
     "pos": 16,
     "wtScore": -11.9384,
     "wtProb": 7.2,
     "k198rScore": -23.0232,
     "k198rProb": 6.2,
     "probDifference": -1,
     "geneName": "HCN2",
     "known": "No",
     "id": 2634
    },
    {
     "sequence": "GYFDYAFTAIFTVEI",
     "pos": 926,
     "wtScore": -13.2219,
     "wtProb": 7,
     "k198rScore": -24.3481,
     "k198rProb": 6,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2635
    },
    {
     "sequence": "TTYVERPTVLPILTL",
     "pos": 745,
     "wtScore": -23.2748,
     "wtProb": 6,
     "k198rScore": -33.5598,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2636
    },
    {
     "sequence": "RLYARWQSRWRKKVD",
     "pos": 483,
     "wtScore": 45.0403,
     "wtProb": 9.3,
     "k198rScore": 35.8803,
     "k198rProb": 8.3,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2637
    },
    {
     "sequence": "FPEATRFSHSPLTSL",
     "pos": 604,
     "wtScore": 41.4066,
     "wtProb": 8.8,
     "k198rScore": 32.0264,
     "k198rProb": 7.8,
     "probDifference": -1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2638
    },
    {
     "sequence": "IIRSKTCSRRVKINV",
     "pos": 33,
     "wtScore": 44.0672,
     "wtProb": 9.1,
     "k198rScore": 34.8732,
     "k198rProb": 8.1,
     "probDifference": -1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2639
    },
    {
     "sequence": "PLPPPSASHLQMKFP",
     "pos": 626,
     "wtScore": 45.1068,
     "wtProb": 9.3,
     "k198rScore": 36.0021,
     "k198rProb": 8.3,
     "probDifference": -1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2640
    },
    {
     "sequence": "SLLSIRGSLFSPRRN",
     "pos": 564,
     "wtScore": 36.1076,
     "wtProb": 8.4,
     "k198rScore": 39.9583,
     "k198rProb": 9.4,
     "probDifference": 1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2641
    },
    {
     "sequence": "SLLSIRGSLFSPRRN",
     "pos": 561,
     "wtScore": 36.1076,
     "wtProb": 8.4,
     "k198rScore": 39.9583,
     "k198rProb": 9.4,
     "probDifference": 1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2642
    },
    {
     "sequence": "SLLSIRGSLFSPRRN",
     "pos": 564,
     "wtScore": 36.1076,
     "wtProb": 8.4,
     "k198rScore": 39.9583,
     "k198rProb": 9.4,
     "probDifference": 1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2643
    },
    {
     "sequence": "VCYDSRRSPRRRLLP",
     "pos": 1904,
     "wtScore": 40.3418,
     "wtProb": 8.7,
     "k198rScore": 30.6852,
     "k198rProb": 7.7,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2644
    },
    {
     "sequence": "EAESHFSSIPDAFWW",
     "pos": 357,
     "wtScore": 54.5527,
     "wtProb": 13.1,
     "k198rScore": 45.2912,
     "k198rProb": 12.1,
     "probDifference": -1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2645
    },
    {
     "sequence": "ASLGRRASFHLECLK",
     "pos": 1980,
     "wtScore": 41.6591,
     "wtProb": 8.8,
     "k198rScore": 32.4134,
     "k198rProb": 7.8,
     "probDifference": -1,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 2646
    },
    {
     "sequence": "LVTLLLDTLPMLGNV",
     "pos": 225,
     "wtScore": -23.3866,
     "wtProb": 6,
     "k198rScore": -33.2572,
     "k198rProb": 5,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2647
    },
    {
     "sequence": "SGNGRQCTANGTECR",
     "pos": 326,
     "wtScore": -12.0969,
     "wtProb": 7.2,
     "k198rScore": -23.2371,
     "k198rProb": 6.2,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2648
    },
    {
     "sequence": "MLFIFIFSILGMHLF",
     "pos": 929,
     "wtScore": 39.1836,
     "wtProb": 8.6,
     "k198rScore": 29.1827,
     "k198rProb": 7.6,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2649
    },
    {
     "sequence": "IMFIVLSTIALSLNT",
     "pos": 202,
     "wtScore": -26.5159,
     "wtProb": 5.8,
     "k198rScore": -42.7951,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2650
    },
    {
     "sequence": "RLERRRRSTFPSPEA",
     "pos": 1586,
     "wtScore": 45.8053,
     "wtProb": 9.5,
     "k198rScore": 36.7584,
     "k198rProb": 8.5,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2651
    },
    {
     "sequence": "LFAPEIHSNPGDTGY",
     "pos": 895,
     "wtScore": 59.3244,
     "wtProb": 16.4,
     "k198rScore": 49.8543,
     "k198rProb": 15.4,
     "probDifference": -1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2652
    },
    {
     "sequence": "GGPSVPTSPVGQLLP",
     "pos": 661,
     "wtScore": 39.6394,
     "wtProb": 8.6,
     "k198rScore": 29.9649,
     "k198rProb": 7.7,
     "probDifference": -1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2653
    },
    {
     "sequence": "SIHSLCTSHYLDLFI",
     "pos": 1615,
     "wtScore": 37.6033,
     "wtProb": 8.5,
     "k198rScore": 26.1995,
     "k198rProb": 7.5,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2654
    },
    {
     "sequence": "FMGKMHKTCYNQEGI",
     "pos": 296,
     "wtScore": -21.306,
     "wtProb": 6.1,
     "k198rScore": -31.2562,
     "k198rProb": 5.1,
     "probDifference": -1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2655
    },
    {
     "sequence": "SSEKLNSSFPSIHCG",
     "pos": 2068,
     "wtScore": 42.2408,
     "wtProb": 8.9,
     "k198rScore": 33.055,
     "k198rProb": 7.9,
     "probDifference": -1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2656
    },
    {
     "sequence": "TPPYRDWTPCYTPLI",
     "pos": 1993,
     "wtScore": -18.4677,
     "wtProb": 6.3,
     "k198rScore": -29.1917,
     "k198rProb": 5.3,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2657
    },
    {
     "sequence": "ARRLSRASRPLSASQ",
     "pos": 816,
     "wtScore": 39.6895,
     "wtProb": 8.6,
     "k198rScore": 30.0616,
     "k198rProb": 7.7,
     "probDifference": -1,
     "geneName": "HCN2",
     "known": "No",
     "id": 2658
    },
    {
     "sequence": "SSFEINITSFFNNSL",
     "pos": 292,
     "wtScore": -11.4902,
     "wtProb": 7.3,
     "k198rScore": -22.3881,
     "k198rProb": 6.3,
     "probDifference": -1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2659
    },
    {
     "sequence": "FAIVYFISFYMLCAF",
     "pos": 1445,
     "wtScore": 44.5479,
     "wtProb": 9.2,
     "k198rScore": 35.4431,
     "k198rProb": 8.2,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2660
    },
    {
     "sequence": "IYSTEALTGKGPSGQ",
     "pos": 820,
     "wtScore": -8.9767,
     "wtProb": 7.8,
     "k198rScore": -17.0212,
     "k198rProb": 6.8,
     "probDifference": -1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2661
    },
    {
     "sequence": "SVGNLVFTGIFTAEM",
     "pos": 798,
     "wtScore": -9.59251,
     "wtProb": 7.7,
     "k198rScore": -19.2587,
     "k198rProb": 6.7,
     "probDifference": -1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2662
    },
    {
     "sequence": "WAVVSMTTVGYGDMV",
     "pos": 373,
     "wtScore": -20.7078,
     "wtProb": 6.1,
     "k198rScore": -30.5016,
     "k198rProb": 5.1,
     "probDifference": -1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2663
    },
    {
     "sequence": "IMYAAVDSRDVKLQP",
     "pos": 1428,
     "wtScore": 53.7605,
     "wtProb": 12.6,
     "k198rScore": 44.4709,
     "k198rProb": 11.7,
     "probDifference": -1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2664
    },
    {
     "sequence": "TVGKFYATFLIQDYF",
     "pos": 1608,
     "wtScore": -12.6837,
     "wtProb": 7.1,
     "k198rScore": -23.749,
     "k198rProb": 6.1,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2665
    },
    {
     "sequence": "RVLRLLRTLRPLRVI",
     "pos": 1402,
     "wtScore": -27.5581,
     "wtProb": 5.8,
     "k198rScore": -38.6269,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2666
    },
    {
     "sequence": "TICIVLNTLFMAMEH",
     "pos": 781,
     "wtScore": -30.3382,
     "wtProb": 5.8,
     "k198rScore": -38.4904,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2667
    },
    {
     "sequence": "TICIVLNTLFMAMEH",
     "pos": 773,
     "wtScore": -30.3382,
     "wtProb": 5.8,
     "k198rScore": -38.4904,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2668
    },
    {
     "sequence": "TICIVLNTLFMAMEH",
     "pos": 772,
     "wtScore": -30.3382,
     "wtProb": 5.8,
     "k198rScore": -38.4904,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2669
    },
    {
     "sequence": "TICIVLNTLFMAMEH",
     "pos": 766,
     "wtScore": -30.3382,
     "wtProb": 5.8,
     "k198rScore": -38.4904,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2670
    },
    {
     "sequence": "MEEMCALSFGQELDY",
     "pos": 117,
     "wtScore": 68.6026,
     "wtProb": 21.8,
     "k198rScore": 58.0484,
     "k198rProb": 20.8,
     "probDifference": -1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2671
    },
    {
     "sequence": "QWPPDNSSFEINITS",
     "pos": 286,
     "wtScore": 55.7107,
     "wtProb": 13.9,
     "k198rScore": 46.3667,
     "k198rProb": 12.9,
     "probDifference": -1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2672
    },
    {
     "sequence": "RSHSFRNTILGYFDY",
     "pos": 916,
     "wtScore": -23.9204,
     "wtProb": 5.9,
     "k198rScore": -33.1807,
     "k198rProb": 5,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2673
    },
    {
     "sequence": "QVTIDKVSPYGFFAH",
     "pos": 707,
     "wtScore": 51.6559,
     "wtProb": 11.4,
     "k198rScore": 42.1547,
     "k198rProb": 10.4,
     "probDifference": -1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2674
    },
    {
     "sequence": "FVLIMLNTLCLAMQH",
     "pos": 1218,
     "wtScore": -33.1915,
     "wtProb": 5.8,
     "k198rScore": -39.2829,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2675
    },
    {
     "sequence": "KFSIMNQSLLSIPGS",
     "pos": 545,
     "wtScore": 37.2614,
     "wtProb": 8.5,
     "k198rScore": 40.0306,
     "k198rProb": 9.5,
     "probDifference": 1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2676
    },
    {
     "sequence": "LFFILLSSISLAAED",
     "pos": 913,
     "wtScore": 36.461,
     "wtProb": 8.5,
     "k198rScore": 23.6763,
     "k198rProb": 7.5,
     "probDifference": -1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2677
    },
    {
     "sequence": "VFFCLGQTTRPRSWC",
     "pos": 85,
     "wtScore": -20.0052,
     "wtProb": 6.2,
     "k198rScore": -29.8778,
     "k198rProb": 5.2,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2678
    },
    {
     "sequence": "SFSGFSISQSKENLD",
     "pos": 807,
     "wtScore": 47.6478,
     "wtProb": 10,
     "k198rScore": 38.6009,
     "k198rProb": 9,
     "probDifference": -1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2679
    },
    {
     "sequence": "SPKSSMKTNNPLKLR",
     "pos": 662,
     "wtScore": -15.5668,
     "wtProb": 6.6,
     "k198rScore": -26.5762,
     "k198rProb": 5.7,
     "probDifference": -1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2680
    },
    {
     "sequence": "DNVATFCTLLMLFIF",
     "pos": 919,
     "wtScore": -33.8157,
     "wtProb": 5.8,
     "k198rScore": -38.0494,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2681
    },
    {
     "sequence": "LRPLRALSRFEGMRV",
     "pos": 1327,
     "wtScore": 100.562,
     "wtProb": 32.1,
     "k198rScore": 77.1539,
     "k198rProb": 33,
     "probDifference": 1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2682
    },
    {
     "sequence": "LRPLRALSRFEGMRV",
     "pos": 1307,
     "wtScore": 100.562,
     "wtProb": 32.1,
     "k198rScore": 77.1539,
     "k198rProb": 33,
     "probDifference": 1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2683
    },
    {
     "sequence": "LRPLRALSRFEGMRV",
     "pos": 1317,
     "wtScore": 100.562,
     "wtProb": 32.1,
     "k198rScore": 77.1539,
     "k198rProb": 33,
     "probDifference": 1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2684
    },
    {
     "sequence": "LRPLRALSRFEGMRV",
     "pos": 1315,
     "wtScore": 100.562,
     "wtProb": 32.1,
     "k198rScore": 77.1539,
     "k198rProb": 33,
     "probDifference": 1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2685
    },
    {
     "sequence": "TVGNLVFTGIFTAEM",
     "pos": 799,
     "wtScore": -10.3517,
     "wtProb": 7.6,
     "k198rScore": -20.5801,
     "k198rProb": 6.6,
     "probDifference": -1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2686
    },
    {
     "sequence": "TVGNLVFTGIFTAEM",
     "pos": 807,
     "wtScore": -10.3517,
     "wtProb": 7.6,
     "k198rScore": -20.5801,
     "k198rProb": 6.6,
     "probDifference": -1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2687
    },
    {
     "sequence": "LAPEHIPTPGAALSW",
     "pos": 44,
     "wtScore": -13.1186,
     "wtProb": 7.1,
     "k198rScore": -24.0327,
     "k198rProb": 6.1,
     "probDifference": -1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2688
    },
    {
     "sequence": "LIIFTVETFLKIIAY",
     "pos": 175,
     "wtScore": -25.3184,
     "wtProb": 5.9,
     "k198rScore": -34.628,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2689
    },
    {
     "sequence": "PSMRILVTLLLDTLP",
     "pos": 220,
     "wtScore": -31.6541,
     "wtProb": 5.8,
     "k198rScore": -36.15,
     "k198rProb": 4.9,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2690
    },
    {
     "sequence": "KMSPPCISVEPPAED",
     "pos": 2192,
     "wtScore": 67.0714,
     "wtProb": 21,
     "k198rScore": 55.8881,
     "k198rProb": 20.1,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2691
    },
    {
     "sequence": "SPSHSTRSWATPPAT",
     "pos": 1979,
     "wtScore": 49.3548,
     "wtProb": 10.5,
     "k198rScore": 40.0961,
     "k198rProb": 9.5,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2692
    },
    {
     "sequence": "HEVSTLISRPHPTVG",
     "pos": 801,
     "wtScore": 38.8127,
     "wtProb": 8.6,
     "k198rScore": 29.5397,
     "k198rProb": 7.6,
     "probDifference": -1,
     "geneName": "HCN1",
     "known": "No",
     "id": 2693
    },
    {
     "sequence": "EATRFSHSPLTSLPS",
     "pos": 606,
     "wtScore": 46.5539,
     "wtProb": 9.6,
     "k198rScore": 37.4598,
     "k198rProb": 8.7,
     "probDifference": -1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2694
    },
    {
     "sequence": "MALFTVSTFEGWPAL",
     "pos": 1098,
     "wtScore": -18.0349,
     "wtProb": 6.3,
     "k198rScore": -28.5565,
     "k198rProb": 5.4,
     "probDifference": -1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2695
    },
    {
     "sequence": "LERRRRSTFPSPEAQ",
     "pos": 1587,
     "wtScore": -22.8028,
     "wtProb": 6,
     "k198rScore": -31.972,
     "k198rProb": 5,
     "probDifference": -1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2696
    },
    {
     "sequence": "NRIGRKFSIMNQSLL",
     "pos": 540,
     "wtScore": 46.2983,
     "wtProb": 9.6,
     "k198rScore": 37.2156,
     "k198rProb": 8.6,
     "probDifference": -1,
     "geneName": "SCN8A",
     "known": "Yes",
     "id": 2697
    },
    {
     "sequence": "WPELLYRSIDSHTED",
     "pos": 1143,
     "wtScore": 56.8774,
     "wtProb": 14.7,
     "k198rScore": 47.7338,
     "k198rProb": 13.7,
     "probDifference": -1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2698
    },
    {
     "sequence": "RRKGFRFSIEGNRLT",
     "pos": 536,
     "wtScore": 52.3461,
     "wtProb": 11.8,
     "k198rScore": 42.9973,
     "k198rProb": 10.8,
     "probDifference": -0.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2699
    },
    {
     "sequence": "DLLEDPTSRQRAMSI",
     "pos": 699,
     "wtScore": 37.7567,
     "wtProb": 8.5,
     "k198rScore": 28.1503,
     "k198rProb": 7.6,
     "probDifference": -0.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2700
    },
    {
     "sequence": "MTIEEMESAADNILS",
     "pos": 2158,
     "wtScore": 71.6499,
     "wtProb": 22.9,
     "k198rScore": 60.8506,
     "k198rProb": 22,
     "probDifference": -0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2701
    },
    {
     "sequence": "AVGNLVFTGIFTAEM",
     "pos": 792,
     "wtScore": -9.98195,
     "wtProb": 7.6,
     "k198rScore": -19.5444,
     "k198rProb": 6.7,
     "probDifference": -0.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2702
    },
    {
     "sequence": "IRTANCTTANQNCVN",
     "pos": 479,
     "wtScore": -16.1512,
     "wtProb": 6.5,
     "k198rScore": -26.887,
     "k198rProb": 5.6,
     "probDifference": -0.9,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2703
    },
    {
     "sequence": "GETPCGSSFAVFYFI",
     "pos": 1497,
     "wtScore": 45.3822,
     "wtProb": 9.4,
     "k198rScore": 36.585,
     "k198rProb": 8.4,
     "probDifference": -0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2704
    },
    {
     "sequence": "HMNDFFHSFLIVFRV",
     "pos": 931,
     "wtScore": 51.0391,
     "wtProb": 11.1,
     "k198rScore": 41.7491,
     "k198rProb": 10.2,
     "probDifference": -0.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2705
    },
    {
     "sequence": "HMNDFFHSFLIVFRV",
     "pos": 939,
     "wtScore": 51.0391,
     "wtProb": 11.1,
     "k198rScore": 41.7491,
     "k198rProb": 10.2,
     "probDifference": -0.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2706
    },
    {
     "sequence": "LYNEITKTQPHSHPN",
     "pos": 549,
     "wtScore": -22.8155,
     "wtProb": 6,
     "k198rScore": -31.7112,
     "k198rProb": 5,
     "probDifference": -0.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2707
    },
    {
     "sequence": "IVLVFLNTLTISSEH",
     "pos": 536,
     "wtScore": -34.9982,
     "wtProb": 5.8,
     "k198rScore": -38.7998,
     "k198rProb": 4.9,
     "probDifference": -0.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2708
    },
    {
     "sequence": "SEVKKDSSQEGCKME",
     "pos": 878,
     "wtScore": 56.2304,
     "wtProb": 14.2,
     "k198rScore": 46.9767,
     "k198rProb": 13.3,
     "probDifference": -0.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2709
    },
    {
     "sequence": "LCLVVIATQFSETKQ",
     "pos": 419,
     "wtScore": -16.1505,
     "wtProb": 6.5,
     "k198rScore": -26.8288,
     "k198rProb": 5.6,
     "probDifference": -0.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2710
    },
    {
     "sequence": "TMMVETDTQSKQMEN",
     "pos": 1544,
     "wtScore": -17.2285,
     "wtProb": 6.4,
     "k198rScore": -27.6689,
     "k198rProb": 5.5,
     "probDifference": -0.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2711
    },
    {
     "sequence": "AWSSRRSSWSSLGRA",
     "pos": 1143,
     "wtScore": 39.9713,
     "wtProb": 8.7,
     "k198rScore": 31.3749,
     "k198rProb": 7.7,
     "probDifference": -0.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2712
    },
    {
     "sequence": "APGPAASTRPASSST",
     "pos": 837,
     "wtScore": -17.2167,
     "wtProb": 6.4,
     "k198rScore": -27.6226,
     "k198rProb": 5.5,
     "probDifference": -0.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 2713
    },
    {
     "sequence": "RAAVKSVTFYWLVIV",
     "pos": 523,
     "wtScore": -19.6684,
     "wtProb": 6.2,
     "k198rScore": -29.401,
     "k198rProb": 5.2,
     "probDifference": -0.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2714
    },
    {
     "sequence": "GQTTRPRSWCLRLVC",
     "pos": 90,
     "wtScore": 41.2838,
     "wtProb": 8.8,
     "k198rScore": 32.7197,
     "k198rProb": 7.9,
     "probDifference": -0.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2715
    },
    {
     "sequence": "SSTTTPTSRMRTQSP",
     "pos": 657,
     "wtScore": 34.6699,
     "wtProb": 8.4,
     "k198rScore": 21.5761,
     "k198rProb": 7.5,
     "probDifference": -0.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 2716
    },
    {
     "sequence": "GSGVTFHTYSNSTIG",
     "pos": 203,
     "wtScore": -21.6844,
     "wtProb": 6,
     "k198rScore": -30.7511,
     "k198rProb": 5.1,
     "probDifference": -0.9,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2717
    },
    {
     "sequence": "KPKKQGSTTATRPPR",
     "pos": 94,
     "wtScore": -11.4478,
     "wtProb": 7.3,
     "k198rScore": -21.918,
     "k198rProb": 6.4,
     "probDifference": -0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2718
    },
    {
     "sequence": "QKVKKVSSIYKKDKG",
     "pos": 1929,
     "wtScore": 51.0746,
     "wtProb": 11.2,
     "k198rScore": 41.8266,
     "k198rProb": 10.2,
     "probDifference": -0.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2719
    },
    {
     "sequence": "SPRAPRTSPYGGLPA",
     "pos": 792,
     "wtScore": 38.8543,
     "wtProb": 8.6,
     "k198rScore": 30.1147,
     "k198rProb": 7.7,
     "probDifference": -0.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 2720
    },
    {
     "sequence": "AILPYYVTIFLTESN",
     "pos": 275,
     "wtScore": -14.1695,
     "wtProb": 6.9,
     "k198rScore": -24.8043,
     "k198rProb": 5.9,
     "probDifference": -0.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2721
    },
    {
     "sequence": "AILPYYVTIFLTESN",
     "pos": 279,
     "wtScore": -14.1695,
     "wtProb": 6.9,
     "k198rScore": -24.8043,
     "k198rProb": 5.9,
     "probDifference": -0.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2722
    },
    {
     "sequence": "FVVDFVSSIPVDYIF",
     "pos": 297,
     "wtScore": 41.3949,
     "wtProb": 8.8,
     "k198rScore": 32.8926,
     "k198rProb": 7.9,
     "probDifference": -0.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 2723
    },
    {
     "sequence": "SILNFYRTGRLHMME",
     "pos": 100,
     "wtScore": -12.588,
     "wtProb": 7.1,
     "k198rScore": -23.344,
     "k198rProb": 6.2,
     "probDifference": -0.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2724
    },
    {
     "sequence": "PYYINKKTFIVMNKG",
     "pos": 88,
     "wtScore": -18.4028,
     "wtProb": 6.3,
     "k198rScore": -28.6019,
     "k198rProb": 5.3,
     "probDifference": -0.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2725
    },
    {
     "sequence": "GTPGAASTAKGSPNG",
     "pos": 92,
     "wtScore": -13.2188,
     "wtProb": 7,
     "k198rScore": -23.8167,
     "k198rProb": 6.1,
     "probDifference": -0.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 2726
    },
    {
     "sequence": "LSQTSRSSRMLAVFP",
     "pos": 628,
     "wtScore": 27.5284,
     "wtProb": 8.4,
     "k198rScore": 22.5317,
     "k198rProb": 7.5,
     "probDifference": -0.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2727
    },
    {
     "sequence": "TYEKRFSSPHQSLLS",
     "pos": 553,
     "wtScore": 42.4037,
     "wtProb": 8.9,
     "k198rScore": 33.961,
     "k198rProb": 8,
     "probDifference": -0.9,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 2728
    },
    {
     "sequence": "SSPDLKKSRSASTIS",
     "pos": 446,
     "wtScore": 40.8778,
     "wtProb": 8.8,
     "k198rScore": 32.4956,
     "k198rProb": 7.8,
     "probDifference": -0.9,
     "geneName": "KCNA2",
     "known": "Yes",
     "id": 2729
    },
    {
     "sequence": "SEHSREPSAAGRLSD",
     "pos": 473,
     "wtScore": 59.472,
     "wtProb": 16.5,
     "k198rScore": 50.0263,
     "k198rProb": 15.5,
     "probDifference": -0.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2730
    },
    {
     "sequence": "VSQASMSSRMVPGLP",
     "pos": 631,
     "wtScore": 34.3614,
     "wtProb": 8.4,
     "k198rScore": 22.6804,
     "k198rProb": 7.5,
     "probDifference": -0.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2731
    },
    {
     "sequence": "GVFSESSSVASKLSS",
     "pos": 487,
     "wtScore": 45.4405,
     "wtProb": 9.4,
     "k198rScore": 36.7729,
     "k198rProb": 8.5,
     "probDifference": -0.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2732
    },
    {
     "sequence": "GAIKSLRTLRALRPL",
     "pos": 1316,
     "wtScore": -28.918,
     "wtProb": 5.8,
     "k198rScore": -34.4451,
     "k198rProb": 4.9,
     "probDifference": -0.9,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2733
    },
    {
     "sequence": "GAIKSLRTLRALRPL",
     "pos": 1296,
     "wtScore": -28.918,
     "wtProb": 5.8,
     "k198rScore": -34.4451,
     "k198rProb": 4.9,
     "probDifference": -0.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2734
    },
    {
     "sequence": "GAIKSLRTLRALRPL",
     "pos": 1304,
     "wtScore": -28.918,
     "wtProb": 5.8,
     "k198rScore": -34.4451,
     "k198rProb": 4.9,
     "probDifference": -0.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2735
    },
    {
     "sequence": "GAIKSLRTLRALRPL",
     "pos": 1306,
     "wtScore": -28.918,
     "wtProb": 5.8,
     "k198rScore": -34.4451,
     "k198rProb": 4.9,
     "probDifference": -0.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2736
    },
    {
     "sequence": "FVVDFISSIPVDYIF",
     "pos": 228,
     "wtScore": 41.5418,
     "wtProb": 8.8,
     "k198rScore": 33.1744,
     "k198rProb": 7.9,
     "probDifference": -0.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 2737
    },
    {
     "sequence": "RFVASNPSKVSYEPI",
     "pos": 1878,
     "wtScore": 49.4567,
     "wtProb": 10.5,
     "k198rScore": 40.3673,
     "k198rProb": 9.6,
     "probDifference": -0.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2738
    },
    {
     "sequence": "LTLFRVSTGDNWNGI",
     "pos": 1805,
     "wtScore": -15.3235,
     "wtProb": 6.7,
     "k198rScore": -26.0204,
     "k198rProb": 5.8,
     "probDifference": -0.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2739
    },
    {
     "sequence": "LIMCTAATPMPTPKS",
     "pos": 1082,
     "wtScore": -18.2769,
     "wtProb": 6.3,
     "k198rScore": -28.3733,
     "k198rProb": 5.4,
     "probDifference": -0.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2740
    },
    {
     "sequence": "KYPAKNTTIALQAGL",
     "pos": 1636,
     "wtScore": -23.3273,
     "wtProb": 6,
     "k198rScore": -31.5024,
     "k198rProb": 5.1,
     "probDifference": -0.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2741
    },
    {
     "sequence": "ATDFPEATRFSHSPL",
     "pos": 601,
     "wtScore": -16.5904,
     "wtProb": 6.5,
     "k198rScore": -26.9436,
     "k198rProb": 5.6,
     "probDifference": -0.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2742
    },
    {
     "sequence": "RQAKAAQTMSTSAPP",
     "pos": 67,
     "wtScore": -13.3568,
     "wtProb": 7,
     "k198rScore": -23.803,
     "k198rProb": 6.1,
     "probDifference": -0.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2743
    },
    {
     "sequence": "SILNFYRTGKLHMME",
     "pos": 104,
     "wtScore": -11.9526,
     "wtProb": 7.2,
     "k198rScore": -22.3502,
     "k198rProb": 6.3,
     "probDifference": -0.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2744
    },
    {
     "sequence": "FNMLIMCTILTNCVF",
     "pos": 137,
     "wtScore": -23.6207,
     "wtProb": 6,
     "k198rScore": -31.5339,
     "k198rProb": 5.1,
     "probDifference": -0.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2745
    },
    {
     "sequence": "PGLNRKTSRSTLSLP",
     "pos": 13,
     "wtScore": 29.6609,
     "wtProb": 8.4,
     "k198rScore": 19.4508,
     "k198rProb": 7.5,
     "probDifference": -0.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2746
    },
    {
     "sequence": "SGDEQLPTICREDPE",
     "pos": 1821,
     "wtScore": -14.4517,
     "wtProb": 6.8,
     "k198rScore": -24.9451,
     "k198rProb": 5.9,
     "probDifference": -0.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2747
    },
    {
     "sequence": "SDLSRRSSSTMSKSE",
     "pos": 445,
     "wtScore": 33.3108,
     "wtProb": 8.4,
     "k198rScore": 22.5541,
     "k198rProb": 7.5,
     "probDifference": -0.9,
     "geneName": "KCNA1",
     "known": "Yes",
     "id": 2748
    },
    {
     "sequence": "FVNVTMSTFNWKDYI",
     "pos": 311,
     "wtScore": -25.7059,
     "wtProb": 5.9,
     "k198rScore": -32.9677,
     "k198rProb": 5,
     "probDifference": -0.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2749
    },
    {
     "sequence": "PEDDSNATNSNLERV",
     "pos": 154,
     "wtScore": -9.54457,
     "wtProb": 7.7,
     "k198rScore": -16.7353,
     "k198rProb": 6.8,
     "probDifference": -0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2750
    },
    {
     "sequence": "NGTFVNVTMSTFNWK",
     "pos": 308,
     "wtScore": -14.838,
     "wtProb": 6.7,
     "k198rScore": -25.3364,
     "k198rProb": 5.9,
     "probDifference": -0.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2751
    },
    {
     "sequence": "FLTLILSSFLVYLVE",
     "pos": 275,
     "wtScore": 40.6608,
     "wtProb": 8.7,
     "k198rScore": 32.543,
     "k198rProb": 7.8,
     "probDifference": -0.9,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2752
    },
    {
     "sequence": "CHRIVNDTIFTNLIL",
     "pos": 899,
     "wtScore": -13.3954,
     "wtProb": 7,
     "k198rScore": -23.8182,
     "k198rProb": 6.1,
     "probDifference": -0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2753
    },
    {
     "sequence": "CMEVAGQTMCLIVFM",
     "pos": 957,
     "wtScore": -20.9196,
     "wtProb": 6.1,
     "k198rScore": -29.7296,
     "k198rProb": 5.2,
     "probDifference": -0.9,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2754
    },
    {
     "sequence": "AASTAKGSPNGECGR",
     "pos": 96,
     "wtScore": 61.6018,
     "wtProb": 17.8,
     "k198rScore": 51.4122,
     "k198rProb": 16.9,
     "probDifference": -0.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 2755
    },
    {
     "sequence": "VSQASRASRVLPILP",
     "pos": 631,
     "wtScore": 35.0247,
     "wtProb": 8.4,
     "k198rScore": 26.6671,
     "k198rProb": 7.5,
     "probDifference": -0.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2756
    },
    {
     "sequence": "SSIYTTASAKTPPRS",
     "pos": 729,
     "wtScore": 56.0265,
     "wtProb": 14.1,
     "k198rScore": 46.798,
     "k198rProb": 13.2,
     "probDifference": -0.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2757
    },
    {
     "sequence": "DKDHPGSSVKGDCGN",
     "pos": 1740,
     "wtScore": 54.9163,
     "wtProb": 13.4,
     "k198rScore": 45.792,
     "k198rProb": 12.5,
     "probDifference": -0.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2758
    },
    {
     "sequence": "SNSTEGETPCGSSFA",
     "pos": 1492,
     "wtScore": -15.692,
     "wtProb": 6.6,
     "k198rScore": -26.2878,
     "k198rProb": 5.7,
     "probDifference": -0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2759
    },
    {
     "sequence": "GKTLFRFSATPALYI",
     "pos": 106,
     "wtScore": 40.5343,
     "wtProb": 8.7,
     "k198rScore": 40.4331,
     "k198rProb": 9.6,
     "probDifference": 0.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2760
    },
    {
     "sequence": "LIQSLDSSRRQYQEK",
     "pos": 402,
     "wtScore": 44.2828,
     "wtProb": 9.2,
     "k198rScore": 35.7716,
     "k198rProb": 8.3,
     "probDifference": -0.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 2761
    },
    {
     "sequence": "LIQSLDSSRRQYQEK",
     "pos": 471,
     "wtScore": 44.2828,
     "wtProb": 9.2,
     "k198rScore": 35.7716,
     "k198rProb": 8.3,
     "probDifference": -0.9,
     "geneName": "HCN2",
     "known": "No",
     "id": 2762
    },
    {
     "sequence": "IFLVFLNTLTIASEH",
     "pos": 537,
     "wtScore": -35.6921,
     "wtProb": 5.7,
     "k198rScore": -37.6972,
     "k198rProb": 4.9,
     "probDifference": -0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2763
    },
    {
     "sequence": "WSKNVEYTFTGIYTF",
     "pos": 163,
     "wtScore": -12.5285,
     "wtProb": 7.1,
     "k198rScore": -22.9189,
     "k198rProb": 6.3,
     "probDifference": -0.9,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2764
    },
    {
     "sequence": "FAVFYFISFYMLCAF",
     "pos": 1505,
     "wtScore": 42.3689,
     "wtProb": 8.9,
     "k198rScore": 34.1774,
     "k198rProb": 8,
     "probDifference": -0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2765
    },
    {
     "sequence": "LQEVEMETYGAGTPL",
     "pos": 1954,
     "wtScore": -11.1379,
     "wtProb": 7.4,
     "k198rScore": -21.0181,
     "k198rProb": 6.5,
     "probDifference": -0.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2766
    },
    {
     "sequence": "VDIIRSKTCSRRVKI",
     "pos": 31,
     "wtScore": -17.6492,
     "wtProb": 6.4,
     "k198rScore": -27.6675,
     "k198rProb": 5.5,
     "probDifference": -0.9,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2767
    },
    {
     "sequence": "GTVMFNATLFALVRT",
     "pos": 1608,
     "wtScore": -30.0521,
     "wtProb": 5.8,
     "k198rScore": -33.3701,
     "k198rProb": 5,
     "probDifference": -0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2768
    },
    {
     "sequence": "GTVMFNATLFALVRT",
     "pos": 1548,
     "wtScore": -30.0521,
     "wtProb": 5.8,
     "k198rScore": -33.3701,
     "k198rProb": 5,
     "probDifference": -0.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2769
    },
    {
     "sequence": "SEESNRISITFFRLF",
     "pos": 1313,
     "wtScore": 39.7933,
     "wtProb": 8.7,
     "k198rScore": 31.9976,
     "k198rProb": 7.8,
     "probDifference": -0.9,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2770
    },
    {
     "sequence": "PKRGFLRSASLGRRA",
     "pos": 1972,
     "wtScore": 54.1326,
     "wtProb": 12.8,
     "k198rScore": 47.7522,
     "k198rProb": 13.7,
     "probDifference": 0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2771
    },
    {
     "sequence": "QTQPQQPSPQPQTPG",
     "pos": 751,
     "wtScore": 51.8447,
     "wtProb": 11.5,
     "k198rScore": 45.6166,
     "k198rProb": 12.4,
     "probDifference": 0.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 2772
    },
    {
     "sequence": "EMQTRRSTFDNFPQS",
     "pos": 687,
     "wtScore": -19.1228,
     "wtProb": 6.2,
     "k198rScore": -28.5747,
     "k198rProb": 5.4,
     "probDifference": -0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2773
    },
    {
     "sequence": "KITRYWNSLSNLVAS",
     "pos": 635,
     "wtScore": 38.8744,
     "wtProb": 8.6,
     "k198rScore": 39.9657,
     "k198rProb": 9.4,
     "probDifference": 0.9,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2774
    },
    {
     "sequence": "FPAKASATGAGPAAA",
     "pos": 27,
     "wtScore": -9.99838,
     "wtProb": 7.6,
     "k198rScore": -16.3166,
     "k198rProb": 6.8,
     "probDifference": -0.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 2775
    },
    {
     "sequence": "TGEMFDVSVVNNYSE",
     "pos": 1377,
     "wtScore": 52.7058,
     "wtProb": 12,
     "k198rScore": 43.4788,
     "k198rProb": 11.1,
     "probDifference": -0.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2776
    },
    {
     "sequence": "LTSLPSKTGGSTAPE",
     "pos": 615,
     "wtScore": -10.0182,
     "wtProb": 7.6,
     "k198rScore": -18.2899,
     "k198rProb": 6.8,
     "probDifference": -0.9,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2777
    },
    {
     "sequence": "LFSPRRNSRASLFSF",
     "pos": 572,
     "wtScore": 38.3508,
     "wtProb": 8.6,
     "k198rScore": 30.8335,
     "k198rProb": 7.7,
     "probDifference": -0.9,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2778
    },
    {
     "sequence": "KMCSLAVTPNGHLEG",
     "pos": 1060,
     "wtScore": -15.1673,
     "wtProb": 6.7,
     "k198rScore": -25.441,
     "k198rProb": 5.8,
     "probDifference": -0.9,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2779
    },
    {
     "sequence": "SPSPSTQTPQPSAIL",
     "pos": 686,
     "wtScore": -17.4868,
     "wtProb": 6.4,
     "k198rScore": -27.3211,
     "k198rProb": 5.5,
     "probDifference": -0.9,
     "geneName": "HCN1",
     "known": "No",
     "id": 2780
    },
    {
     "sequence": "ASIPQPVTAVPGTGL",
     "pos": 819,
     "wtScore": -12.4377,
     "wtProb": 7.2,
     "k198rScore": -22.633,
     "k198rProb": 6.3,
     "probDifference": -0.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 2781
    },
    {
     "sequence": "IVAVSLVSLIANALG",
     "pos": 1277,
     "wtScore": 39.9555,
     "wtProb": 8.7,
     "k198rScore": 40.1182,
     "k198rProb": 9.5,
     "probDifference": 0.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2782
    },
    {
     "sequence": "FRPVVPASAPHPRPL",
     "pos": 1940,
     "wtScore": 50.4443,
     "wtProb": 10.9,
     "k198rScore": 44.6151,
     "k198rProb": 11.7,
     "probDifference": 0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2783
    },
    {
     "sequence": "LLFEYPESSGPARII",
     "pos": 157,
     "wtScore": 60.2755,
     "wtProb": 17.1,
     "k198rScore": 52.6236,
     "k198rProb": 17.9,
     "probDifference": 0.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2784
    },
    {
     "sequence": "PFLDGSHSVTPESRA",
     "pos": 2286,
     "wtScore": 57.8969,
     "wtProb": 15.3,
     "k198rScore": 50.4982,
     "k198rProb": 16.1,
     "probDifference": 0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2785
    },
    {
     "sequence": "PPVYTATSLSHSNLH",
     "pos": 671,
     "wtScore": 30.6665,
     "wtProb": 8.4,
     "k198rScore": 26.2924,
     "k198rProb": 7.5,
     "probDifference": -0.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 2786
    },
    {
     "sequence": "PGRQFHGSASSLVEA",
     "pos": 2114,
     "wtScore": 55.7237,
     "wtProb": 13.9,
     "k198rScore": 46.6365,
     "k198rProb": 13.1,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2787
    },
    {
     "sequence": "YFDIVFTTIFTIEIA",
     "pos": 941,
     "wtScore": -21.3293,
     "wtProb": 6.1,
     "k198rScore": -29.5602,
     "k198rProb": 5.2,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2788
    },
    {
     "sequence": "QGNVLATSLRSLRFL",
     "pos": 224,
     "wtScore": 37.6461,
     "wtProb": 8.5,
     "k198rScore": 30.3086,
     "k198rProb": 7.7,
     "probDifference": -0.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2789
    },
    {
     "sequence": "TFHYASPTASQLSLM",
     "pos": 722,
     "wtScore": -16.8859,
     "wtProb": 6.5,
     "k198rScore": -26.8063,
     "k198rProb": 5.6,
     "probDifference": -0.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 2790
    },
    {
     "sequence": "VARKVSVSRMLSLPN",
     "pos": 1921,
     "wtScore": 39.2112,
     "wtProb": 8.6,
     "k198rScore": 31.8409,
     "k198rProb": 7.8,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2791
    },
    {
     "sequence": "GIGLLAKTPLSRPVK",
     "pos": 90,
     "wtScore": -15.3866,
     "wtProb": 6.6,
     "k198rScore": -25.5958,
     "k198rProb": 5.8,
     "probDifference": -0.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2792
    },
    {
     "sequence": "PMPTPKSSPFLDAAP",
     "pos": 1090,
     "wtScore": 39.13,
     "wtProb": 8.6,
     "k198rScore": 31.8199,
     "k198rProb": 7.8,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2793
    },
    {
     "sequence": "LTNCVFMTMSNPPDW",
     "pos": 146,
     "wtScore": -17.927,
     "wtProb": 6.3,
     "k198rScore": -27.5862,
     "k198rProb": 5.5,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2794
    },
    {
     "sequence": "LTNCVFMTMSNPPDW",
     "pos": 145,
     "wtScore": -17.927,
     "wtProb": 6.3,
     "k198rScore": -27.5862,
     "k198rProb": 5.5,
     "probDifference": -0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2795
    },
    {
     "sequence": "ISPRQRRSITRDSDT",
     "pos": 780,
     "wtScore": 37.5131,
     "wtProb": 8.5,
     "k198rScore": 30.3174,
     "k198rProb": 7.7,
     "probDifference": -0.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2796
    },
    {
     "sequence": "PQNRFRVSCQKVITH",
     "pos": 1285,
     "wtScore": 50.1454,
     "wtProb": 10.8,
     "k198rScore": 41.0992,
     "k198rProb": 9.9,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2797
    },
    {
     "sequence": "TAATPMPTPKSSPFL",
     "pos": 1086,
     "wtScore": -19.1526,
     "wtProb": 6.2,
     "k198rScore": -28.3934,
     "k198rProb": 5.4,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2798
    },
    {
     "sequence": "PRALLCLTLKNPIRR",
     "pos": 107,
     "wtScore": -16.6018,
     "wtProb": 6.5,
     "k198rScore": -26.5689,
     "k198rProb": 5.7,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2799
    },
    {
     "sequence": "SFPRPFATPPATPGS",
     "pos": 2036,
     "wtScore": -15.0493,
     "wtProb": 6.7,
     "k198rScore": -25.2208,
     "k198rProb": 5.9,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2800
    },
    {
     "sequence": "PFVDLAITICIVLNT",
     "pos": 766,
     "wtScore": -24.0558,
     "wtProb": 5.9,
     "k198rScore": -30.8636,
     "k198rProb": 5.1,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2801
    },
    {
     "sequence": "PFVDLAITICIVLNT",
     "pos": 774,
     "wtScore": -24.0558,
     "wtProb": 5.9,
     "k198rScore": -30.8636,
     "k198rProb": 5.1,
     "probDifference": -0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2802
    },
    {
     "sequence": "PFVDLAITICIVLNT",
     "pos": 765,
     "wtScore": -24.0558,
     "wtProb": 5.9,
     "k198rScore": -30.8636,
     "k198rProb": 5.1,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2803
    },
    {
     "sequence": "PFVDLAITICIVLNT",
     "pos": 759,
     "wtScore": -24.0558,
     "wtProb": 5.9,
     "k198rScore": -30.8636,
     "k198rProb": 5.1,
     "probDifference": -0.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2804
    },
    {
     "sequence": "GPSGAWSSRRSSWSS",
     "pos": 1139,
     "wtScore": 33.6161,
     "wtProb": 8.4,
     "k198rScore": 27.3349,
     "k198rProb": 7.5,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2805
    },
    {
     "sequence": "IYEFIQQSFIRKQKI",
     "pos": 1036,
     "wtScore": 49.0753,
     "wtProb": 10.4,
     "k198rScore": 43.5976,
     "k198rProb": 11.2,
     "probDifference": 0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2806
    },
    {
     "sequence": "GTMDSNGTFVNVTMS",
     "pos": 303,
     "wtScore": -18.9041,
     "wtProb": 6.2,
     "k198rScore": -28.2024,
     "k198rProb": 5.4,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2807
    },
    {
     "sequence": "PASFWWATITMTTVG",
     "pos": 375,
     "wtScore": -21.1099,
     "wtProb": 6.1,
     "k198rScore": -29.297,
     "k198rProb": 5.3,
     "probDifference": -0.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2808
    },
    {
     "sequence": "PASFWWATITMTTVG",
     "pos": 371,
     "wtScore": -21.1099,
     "wtProb": 6.1,
     "k198rScore": -29.297,
     "k198rProb": 5.3,
     "probDifference": -0.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2809
    },
    {
     "sequence": "SPEKARSSSSPQHLN",
     "pos": 517,
     "wtScore": 42.5995,
     "wtProb": 8.9,
     "k198rScore": 34.7393,
     "k198rProb": 8.1,
     "probDifference": -0.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2810
    },
    {
     "sequence": "KLAKSWPTLNMLIKI",
     "pos": 865,
     "wtScore": -29.0397,
     "wtProb": 5.8,
     "k198rScore": -32.361,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2811
    },
    {
     "sequence": "KLAKSWPTLNMLIKI",
     "pos": 866,
     "wtScore": -29.0397,
     "wtProb": 5.8,
     "k198rScore": -32.361,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2812
    },
    {
     "sequence": "KLAKSWPTLNMLIKI",
     "pos": 859,
     "wtScore": -29.0397,
     "wtProb": 5.8,
     "k198rScore": -32.361,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2813
    },
    {
     "sequence": "KLAKSWPTLNMLIKI",
     "pos": 874,
     "wtScore": -29.0397,
     "wtProb": 5.8,
     "k198rScore": -32.361,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2814
    },
    {
     "sequence": "FSMLIMCTILTNCVF",
     "pos": 136,
     "wtScore": -24.9063,
     "wtProb": 5.9,
     "k198rScore": -31.2343,
     "k198rProb": 5.1,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2815
    },
    {
     "sequence": "FSMLIMCTILTNCVF",
     "pos": 136,
     "wtScore": -24.9063,
     "wtProb": 5.9,
     "k198rScore": -31.2343,
     "k198rProb": 5.1,
     "probDifference": -0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2816
    },
    {
     "sequence": "ATDDNGTTTETEMRK",
     "pos": 683,
     "wtScore": -14.5791,
     "wtProb": 6.8,
     "k198rScore": -24.6315,
     "k198rProb": 6,
     "probDifference": -0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2817
    },
    {
     "sequence": "LFSPRRNSKTSIFSF",
     "pos": 572,
     "wtScore": 39.3113,
     "wtProb": 8.6,
     "k198rScore": 32.1137,
     "k198rProb": 7.8,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2818
    },
    {
     "sequence": "WMGRLWVTFSGKLRR",
     "pos": 780,
     "wtScore": -13.297,
     "wtProb": 7,
     "k198rScore": -23.2145,
     "k198rProb": 6.2,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2819
    },
    {
     "sequence": "SSLGRAPSLKRRGQC",
     "pos": 1152,
     "wtScore": 38.8524,
     "wtProb": 8.6,
     "k198rScore": 39.8087,
     "k198rProb": 9.4,
     "probDifference": 0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2820
    },
    {
     "sequence": "SMSDLWITMLSMIVG",
     "pos": 374,
     "wtScore": -22.7749,
     "wtProb": 6,
     "k198rScore": -30.1529,
     "k198rProb": 5.2,
     "probDifference": -0.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 2821
    },
    {
     "sequence": "IIFGSFFTLNLFIGV",
     "pos": 1463,
     "wtScore": -29.1094,
     "wtProb": 5.8,
     "k198rScore": -32.1704,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2822
    },
    {
     "sequence": "IIFGSFFTLNLFIGV",
     "pos": 1454,
     "wtScore": -29.1094,
     "wtProb": 5.8,
     "k198rScore": -32.1704,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2823
    },
    {
     "sequence": "IIFGSFFTLNLFIGV",
     "pos": 1473,
     "wtScore": -29.1094,
     "wtProb": 5.8,
     "k198rScore": -32.1704,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2824
    },
    {
     "sequence": "IIFGSFFTLNLFIGV",
     "pos": 1458,
     "wtScore": -29.1094,
     "wtProb": 5.8,
     "k198rScore": -32.1704,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2825
    },
    {
     "sequence": "IYIEQRKTIRTILEY",
     "pos": 1226,
     "wtScore": -27.116,
     "wtProb": 5.8,
     "k198rScore": -31.8893,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2826
    },
    {
     "sequence": "NRKTSRSTLSLPPEP",
     "pos": 16,
     "wtScore": -30.0327,
     "wtProb": 5.8,
     "k198rScore": -32.1,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2827
    },
    {
     "sequence": "GKAIFRFSATSALYI",
     "pos": 102,
     "wtScore": 40.5729,
     "wtProb": 8.7,
     "k198rScore": 40.2417,
     "k198rProb": 9.5,
     "probDifference": 0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2828
    },
    {
     "sequence": "GKAIFRFSATSALYI",
     "pos": 102,
     "wtScore": 40.5729,
     "wtProb": 8.7,
     "k198rScore": 40.2417,
     "k198rProb": 9.5,
     "probDifference": 0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2829
    },
    {
     "sequence": "EGLSRHATFSNFGMA",
     "pos": 1789,
     "wtScore": -20.4099,
     "wtProb": 6.1,
     "k198rScore": -28.7831,
     "k198rProb": 5.3,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2830
    },
    {
     "sequence": "GNIMIVTTLLQFMFA",
     "pos": 1022,
     "wtScore": -28.9463,
     "wtProb": 5.8,
     "k198rScore": -32.1299,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2831
    },
    {
     "sequence": "FSHSPLTSLPSKTGG",
     "pos": 610,
     "wtScore": 27.9252,
     "wtProb": 8.4,
     "k198rScore": 28.4349,
     "k198rProb": 7.6,
     "probDifference": -0.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2832
    },
    {
     "sequence": "MMAILVNTLSMGVEY",
     "pos": 806,
     "wtScore": -27.1259,
     "wtProb": 5.8,
     "k198rScore": -31.7914,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2833
    },
    {
     "sequence": "WLDFTVITFAYVTEF",
     "pos": 198,
     "wtScore": -21.3067,
     "wtProb": 6.1,
     "k198rScore": -29.2636,
     "k198rProb": 5.3,
     "probDifference": -0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2834
    },
    {
     "sequence": "WLDFTVITFAYVTEF",
     "pos": 199,
     "wtScore": -21.3067,
     "wtProb": 6.1,
     "k198rScore": -29.2636,
     "k198rProb": 5.3,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2835
    },
    {
     "sequence": "ESTPSTASLPSYDSV",
     "pos": 1942,
     "wtScore": 41.448,
     "wtProb": 8.8,
     "k198rScore": 40.4568,
     "k198rProb": 9.6,
     "probDifference": 0.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2836
    },
    {
     "sequence": "NRNNNFQTFPQAVLL",
     "pos": 1390,
     "wtScore": -16.4975,
     "wtProb": 6.5,
     "k198rScore": -26.3477,
     "k198rProb": 5.7,
     "probDifference": -0.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2837
    },
    {
     "sequence": "NRNNNFQTFPQAVLL",
     "pos": 1448,
     "wtScore": -16.4975,
     "wtProb": 6.5,
     "k198rScore": -26.3477,
     "k198rProb": 5.7,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2838
    },
    {
     "sequence": "ARLAHRISKSKFSRY",
     "pos": 497,
     "wtScore": 43.5683,
     "wtProb": 9.1,
     "k198rScore": 35.5922,
     "k198rProb": 8.2,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2839
    },
    {
     "sequence": "WPWIYFVTLIIIGSF",
     "pos": 386,
     "wtScore": -23.2755,
     "wtProb": 6,
     "k198rScore": -30.2261,
     "k198rProb": 5.2,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2840
    },
    {
     "sequence": "KAMKEAVSAASEDDI",
     "pos": 1735,
     "wtScore": 71.3047,
     "wtProb": 22.9,
     "k198rScore": 62.5763,
     "k198rProb": 22.1,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2841
    },
    {
     "sequence": "AGMTKHGSRSTSSLP",
     "pos": 9,
     "wtScore": 41.3383,
     "wtProb": 8.8,
     "k198rScore": 33.9623,
     "k198rProb": 8,
     "probDifference": -0.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2842
    },
    {
     "sequence": "TVGKFYATFLIQEYF",
     "pos": 1668,
     "wtScore": -13.3519,
     "wtProb": 7,
     "k198rScore": -23.1059,
     "k198rProb": 6.2,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2843
    },
    {
     "sequence": "DYSPTRRSIHSLCTS",
     "pos": 1608,
     "wtScore": 34.4763,
     "wtProb": 8.4,
     "k198rScore": 28.7365,
     "k198rProb": 7.6,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2844
    },
    {
     "sequence": "GRSAFPQTFTTQRPL",
     "pos": 1768,
     "wtScore": -20.2348,
     "wtProb": 6.1,
     "k198rScore": -28.537,
     "k198rProb": 5.4,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2845
    },
    {
     "sequence": "KRKQEEVSAIIIQRA",
     "pos": 1907,
     "wtScore": 71.7534,
     "wtProb": 23,
     "k198rScore": 62.8492,
     "k198rProb": 22.2,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2846
    },
    {
     "sequence": "FHTYSNSTIGYQQST",
     "pos": 208,
     "wtScore": -16.5332,
     "wtProb": 6.5,
     "k198rScore": -26.318,
     "k198rProb": 5.7,
     "probDifference": -0.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2847
    },
    {
     "sequence": "DYLTRDWSILGPHHL",
     "pos": 1534,
     "wtScore": 46.0562,
     "wtProb": 9.5,
     "k198rScore": 37.752,
     "k198rProb": 8.7,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 2848
    },
    {
     "sequence": "DYLTRDWSILGPHHL",
     "pos": 1474,
     "wtScore": 46.0562,
     "wtProb": 9.5,
     "k198rScore": 37.752,
     "k198rProb": 8.7,
     "probDifference": -0.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2849
    },
    {
     "sequence": "KKSRSASTISKSDYM",
     "pos": 451,
     "wtScore": -16.0923,
     "wtProb": 6.5,
     "k198rScore": -25.9546,
     "k198rProb": 5.8,
     "probDifference": -0.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2850
    },
    {
     "sequence": "GGPSALTSPTGQLPP",
     "pos": 664,
     "wtScore": 36.6123,
     "wtProb": 8.5,
     "k198rScore": 30.2643,
     "k198rProb": 7.7,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2851
    },
    {
     "sequence": "IFPSLRRSVKRNSTV",
     "pos": 635,
     "wtScore": 44.8321,
     "wtProb": 9.3,
     "k198rScore": 36.6858,
     "k198rProb": 8.5,
     "probDifference": -0.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2852
    },
    {
     "sequence": "IVSFCLETLPIFRDE",
     "pos": 183,
     "wtScore": -28.2991,
     "wtProb": 5.8,
     "k198rScore": -31.8748,
     "k198rProb": 5,
     "probDifference": -0.8,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2853
    },
    {
     "sequence": "HSNVSQASRASRVLP",
     "pos": 628,
     "wtScore": 45.4812,
     "wtProb": 9.4,
     "k198rScore": 37.2962,
     "k198rProb": 8.6,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2854
    },
    {
     "sequence": "EDSVKRSSFLFSMDG",
     "pos": 535,
     "wtScore": 39.5122,
     "wtProb": 8.6,
     "k198rScore": 32.5115,
     "k198rProb": 7.8,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2855
    },
    {
     "sequence": "GSMPLSSTGDGISDS",
     "pos": 855,
     "wtScore": -11.2316,
     "wtProb": 7.4,
     "k198rScore": -20.3172,
     "k198rProb": 6.6,
     "probDifference": -0.8,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2856
    },
    {
     "sequence": "LSPLLQRSHSPASFP",
     "pos": 2024,
     "wtScore": 43.9031,
     "wtProb": 9.1,
     "k198rScore": 36.0886,
     "k198rProb": 8.3,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2857
    },
    {
     "sequence": "ANPSRCHSRESQAAM",
     "pos": 1900,
     "wtScore": 53.2249,
     "wtProb": 12.3,
     "k198rScore": 44.2956,
     "k198rProb": 11.5,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2858
    },
    {
     "sequence": "IATQFSETKQRESQL",
     "pos": 424,
     "wtScore": -16.3366,
     "wtProb": 6.5,
     "k198rScore": -26.1051,
     "k198rProb": 5.7,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2859
    },
    {
     "sequence": "WVAYGFQTYFTNAWC",
     "pos": 1265,
     "wtScore": -10.9052,
     "wtProb": 7.5,
     "k198rScore": -19.5014,
     "k198rProb": 6.7,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2860
    },
    {
     "sequence": "SAILSPCSYTTAVCS",
     "pos": 697,
     "wtScore": 40.2554,
     "wtProb": 8.7,
     "k198rScore": 33.2112,
     "k198rProb": 7.9,
     "probDifference": -0.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 2861
    },
    {
     "sequence": "AMQHYGQSCLFKIAM",
     "pos": 1263,
     "wtScore": 50.2765,
     "wtProb": 10.8,
     "k198rScore": 41.3602,
     "k198rProb": 10,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2862
    },
    {
     "sequence": "FVRNNNLTFLRPYYQ",
     "pos": 272,
     "wtScore": -12.8132,
     "wtProb": 7.1,
     "k198rScore": -22.4743,
     "k198rProb": 6.3,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2863
    },
    {
     "sequence": "FILLSSISLAAEDPV",
     "pos": 915,
     "wtScore": 40.0442,
     "wtProb": 8.7,
     "k198rScore": 39.9558,
     "k198rProb": 9.4,
     "probDifference": 0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2864
    },
    {
     "sequence": "CMEVAGQTMCLTVFM",
     "pos": 956,
     "wtScore": -20.6244,
     "wtProb": 6.1,
     "k198rScore": -28.6614,
     "k198rProb": 5.3,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2865
    },
    {
     "sequence": "TQGGSLQSPPRSPRP",
     "pos": 2056,
     "wtScore": 50.2753,
     "wtProb": 10.8,
     "k198rScore": 41.3954,
     "k198rProb": 10,
     "probDifference": -0.8,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2866
    },
    {
     "sequence": "LFSPRRNSRTSLFSF",
     "pos": 569,
     "wtScore": 33.9892,
     "wtProb": 8.4,
     "k198rScore": 28.8624,
     "k198rProb": 7.6,
     "probDifference": -0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2867
    },
    {
     "sequence": "EEYINNKTNFYTVPG",
     "pos": 309,
     "wtScore": -12.3513,
     "wtProb": 7.2,
     "k198rScore": -21.8339,
     "k198rProb": 6.4,
     "probDifference": -0.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2868
    },
    {
     "sequence": "CWYKFANTFLIWECH",
     "pos": 728,
     "wtScore": -17.3631,
     "wtProb": 6.4,
     "k198rScore": -26.725,
     "k198rProb": 5.6,
     "probDifference": -0.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2869
    },
    {
     "sequence": "SRHNSKSSIFSFRGP",
     "pos": 563,
     "wtScore": 37.9175,
     "wtProb": 8.5,
     "k198rScore": 31.6865,
     "k198rProb": 7.8,
     "probDifference": -0.8,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2870
    },
    {
     "sequence": "PMTEQFSSVLTVGNL",
     "pos": 789,
     "wtScore": 46.8865,
     "wtProb": 9.7,
     "k198rScore": 38.5458,
     "k198rProb": 9,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2871
    },
    {
     "sequence": "SLDGNGTTFNRTVSI",
     "pos": 305,
     "wtScore": -22.8572,
     "wtProb": 6,
     "k198rScore": -29.6813,
     "k198rProb": 5.2,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2872
    },
    {
     "sequence": "GNIVIVTTLLQFMFA",
     "pos": 1056,
     "wtScore": -31.3958,
     "wtProb": 5.8,
     "k198rScore": -31.1895,
     "k198rProb": 5.1,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2873
    },
    {
     "sequence": "IMYAAVDSRNVELQP",
     "pos": 1433,
     "wtScore": 47.614,
     "wtProb": 9.9,
     "k198rScore": 39.2892,
     "k198rProb": 9.2,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2874
    },
    {
     "sequence": "IMYAAVDSRNVELQP",
     "pos": 1443,
     "wtScore": 47.614,
     "wtProb": 9.9,
     "k198rScore": 39.2892,
     "k198rProb": 9.2,
     "probDifference": -0.8,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2875
    },
    {
     "sequence": "FIESPKSSMKTNNPL",
     "pos": 659,
     "wtScore": 40.9262,
     "wtProb": 8.8,
     "k198rScore": 33.9445,
     "k198rProb": 8,
     "probDifference": -0.8,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2876
    },
    {
     "sequence": "IYIEQRKTIKTMLEY",
     "pos": 1234,
     "wtScore": -28.3465,
     "wtProb": 5.8,
     "k198rScore": -31.4673,
     "k198rProb": 5.1,
     "probDifference": -0.8,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2877
    },
    {
     "sequence": "IYIEQRKTIKTMLEY",
     "pos": 1236,
     "wtScore": -28.3465,
     "wtProb": 5.8,
     "k198rScore": -31.4673,
     "k198rProb": 5.1,
     "probDifference": -0.8,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2878
    },
    {
     "sequence": "QSPLAARTFHYASPT",
     "pos": 715,
     "wtScore": -18.1798,
     "wtProb": 6.3,
     "k198rScore": -27.3096,
     "k198rProb": 5.5,
     "probDifference": -0.8,
     "geneName": "HCN1",
     "known": "No",
     "id": 2879
    },
    {
     "sequence": "TPSSYSSTGSNANIN",
     "pos": 1808,
     "wtScore": -14.8365,
     "wtProb": 6.7,
     "k198rScore": -24.5622,
     "k198rProb": 6,
     "probDifference": -0.8,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2880
    },
    {
     "sequence": "KNSDKQRSADSLVEA",
     "pos": 2056,
     "wtScore": 67.7187,
     "wtProb": 21.4,
     "k198rScore": 63.7546,
     "k198rProb": 22.1,
     "probDifference": 0.8,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2881
    },
    {
     "sequence": "FSMIIMCTILTNCVF",
     "pos": 140,
     "wtScore": -25.6337,
     "wtProb": 5.9,
     "k198rScore": -30.6978,
     "k198rProb": 5.1,
     "probDifference": -0.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2882
    },
    {
     "sequence": "IYIDQRKTIKTMLEY",
     "pos": 1246,
     "wtScore": -30.6149,
     "wtProb": 5.8,
     "k198rScore": -30.9607,
     "k198rProb": 5.1,
     "probDifference": -0.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2883
    },
    {
     "sequence": "AFFILSKTNPIRVGC",
     "pos": 871,
     "wtScore": -20.0312,
     "wtProb": 6.2,
     "k198rScore": -28.1426,
     "k198rProb": 5.4,
     "probDifference": -0.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2884
    },
    {
     "sequence": "EMKLTDGSYFGEICL",
     "pos": 535,
     "wtScore": 53.3536,
     "wtProb": 12.4,
     "k198rScore": 44.4238,
     "k198rProb": 11.6,
     "probDifference": -0.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 2885
    },
    {
     "sequence": "HYLDLFITFIICVNV",
     "pos": 1623,
     "wtScore": -20.3527,
     "wtProb": 6.1,
     "k198rScore": -28.2106,
     "k198rProb": 5.4,
     "probDifference": -0.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2886
    },
    {
     "sequence": "VIIPVGITFFTEQTT",
     "pos": 164,
     "wtScore": -18.8033,
     "wtProb": 6.2,
     "k198rScore": -27.5053,
     "k198rProb": 5.5,
     "probDifference": -0.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 2887
    },
    {
     "sequence": "LRPLRVISRAPGLKL",
     "pos": 1410,
     "wtScore": 45.5281,
     "wtProb": 9.4,
     "k198rScore": 37.5351,
     "k198rProb": 8.7,
     "probDifference": -0.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2888
    },
    {
     "sequence": "QRSHSPASFPRPFAT",
     "pos": 2029,
     "wtScore": 50.645,
     "wtProb": 11,
     "k198rScore": 44.5601,
     "k198rProb": 11.7,
     "probDifference": 0.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2889
    },
    {
     "sequence": "DGFIVSLSLMELSLA",
     "pos": 827,
     "wtScore": 85.9367,
     "wtProb": 26.1,
     "k198rScore": 70.6475,
     "k198rProb": 25.4,
     "probDifference": -0.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2890
    },
    {
     "sequence": "PASHRRSSFNFECLR",
     "pos": 1921,
     "wtScore": 46.8719,
     "wtProb": 9.7,
     "k198rScore": 42.2029,
     "k198rProb": 10.4,
     "probDifference": 0.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2891
    },
    {
     "sequence": "LQQAAAMSFCPQVAR",
     "pos": 736,
     "wtScore": 61.3373,
     "wtProb": 17.7,
     "k198rScore": 53.4564,
     "k198rProb": 18.4,
     "probDifference": 0.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 2892
    },
    {
     "sequence": "ELKREAETLREREGE",
     "pos": 153,
     "wtScore": -11.2288,
     "wtProb": 7.4,
     "k198rScore": -13.2759,
     "k198rProb": 6.7,
     "probDifference": -0.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2893
    },
    {
     "sequence": "PTSQPNSSKQTVLSW",
     "pos": 45,
     "wtScore": 37.8062,
     "wtProb": 8.5,
     "k198rScore": 32.2516,
     "k198rProb": 7.8,
     "probDifference": -0.7,
     "geneName": "CACNA1D",
     "known": "Yes",
     "id": 2894
    },
    {
     "sequence": "ECVLKLISLRHYYFT",
     "pos": 1593,
     "wtScore": 36.1449,
     "wtProb": 8.4,
     "k198rScore": 31.2203,
     "k198rProb": 7.7,
     "probDifference": -0.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2895
    },
    {
     "sequence": "IMSPLGISVFRCVRL",
     "pos": 615,
     "wtScore": 50.1173,
     "wtProb": 10.7,
     "k198rScore": 41.359,
     "k198rProb": 10,
     "probDifference": -0.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2896
    },
    {
     "sequence": "LSPCSYTTAVCSPPV",
     "pos": 700,
     "wtScore": -11.0788,
     "wtProb": 7.4,
     "k198rScore": -19.0314,
     "k198rProb": 6.7,
     "probDifference": -0.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 2897
    },
    {
     "sequence": "FLCLILASFLVYLAE",
     "pos": 246,
     "wtScore": 46.9781,
     "wtProb": 9.8,
     "k198rScore": 38.7905,
     "k198rProb": 9,
     "probDifference": -0.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2898
    },
    {
     "sequence": "FFTEQTTTPWIIFNV",
     "pos": 172,
     "wtScore": -18.5646,
     "wtProb": 6.3,
     "k198rScore": -27.2704,
     "k198rProb": 5.5,
     "probDifference": -0.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 2899
    },
    {
     "sequence": "DAIEESPSKEPKPVG",
     "pos": 457,
     "wtScore": 63.0721,
     "wtProb": 18.8,
     "k198rScore": 52.9072,
     "k198rProb": 18.1,
     "probDifference": -0.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2900
    },
    {
     "sequence": "VVLNRGKTLFRFSAT",
     "pos": 101,
     "wtScore": -34.651,
     "wtProb": 5.8,
     "k198rScore": -30.8502,
     "k198rProb": 5.1,
     "probDifference": -0.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2901
    },
    {
     "sequence": "SSSSEGSTVDIGAPV",
     "pos": 1155,
     "wtScore": -12.7427,
     "wtProb": 7.1,
     "k198rScore": -21.9115,
     "k198rProb": 6.4,
     "probDifference": -0.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2902
    },
    {
     "sequence": "ARRKKKMSPPCISVE",
     "pos": 2187,
     "wtScore": 54.9706,
     "wtProb": 13.4,
     "k198rScore": 46.1333,
     "k198rProb": 12.7,
     "probDifference": -0.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2903
    },
    {
     "sequence": "LKLARHSTGLQSLGF",
     "pos": 318,
     "wtScore": -20.5065,
     "wtProb": 6.1,
     "k198rScore": -28.0174,
     "k198rProb": 5.4,
     "probDifference": -0.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2904
    },
    {
     "sequence": "LKLARHSTGLQSLGF",
     "pos": 314,
     "wtScore": -20.5065,
     "wtProb": 6.1,
     "k198rScore": -28.0174,
     "k198rProb": 5.4,
     "probDifference": -0.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2905
    },
    {
     "sequence": "PRQRRSITRDSDTPL",
     "pos": 782,
     "wtScore": -14.2327,
     "wtProb": 6.9,
     "k198rScore": -23.6143,
     "k198rProb": 6.2,
     "probDifference": -0.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2906
    },
    {
     "sequence": "YRQVNIRTANCTTAN",
     "pos": 474,
     "wtScore": -16.1341,
     "wtProb": 6.5,
     "k198rScore": -25.3499,
     "k198rProb": 5.8,
     "probDifference": -0.7,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2907
    },
    {
     "sequence": "DGLLVLVSLVDIVVA",
     "pos": 1374,
     "wtScore": 50.5724,
     "wtProb": 11,
     "k198rScore": 44.4676,
     "k198rProb": 11.6,
     "probDifference": 0.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2908
    },
    {
     "sequence": "DAWNTFDSLIVIGSI",
     "pos": 1273,
     "wtScore": 40.9551,
     "wtProb": 8.8,
     "k198rScore": 39.9104,
     "k198rProb": 9.4,
     "probDifference": 0.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2909
    },
    {
     "sequence": "RSFTEIDTGDDEDFL",
     "pos": 808,
     "wtScore": 9.19657,
     "wtProb": 8.7,
     "k198rScore": -1.91888,
     "k198rProb": 8,
     "probDifference": -0.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2910
    },
    {
     "sequence": "LGTMNYPTILPSGVG",
     "pos": 614,
     "wtScore": -16.7731,
     "wtProb": 6.5,
     "k198rScore": -25.7591,
     "k198rProb": 5.8,
     "probDifference": -0.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2911
    },
    {
     "sequence": "GPPPRSATGEGPFGD",
     "pos": 856,
     "wtScore": -13.3869,
     "wtProb": 7,
     "k198rScore": -22.4095,
     "k198rProb": 6.3,
     "probDifference": -0.7,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2912
    },
    {
     "sequence": "SETSSNKSFENKYQE",
     "pos": 506,
     "wtScore": 62.7478,
     "wtProb": 18.6,
     "k198rScore": 52.6829,
     "k198rProb": 17.9,
     "probDifference": -0.7,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2913
    },
    {
     "sequence": "KGDCGNPSVGIFFFV",
     "pos": 1749,
     "wtScore": 64.5306,
     "wtProb": 19.8,
     "k198rScore": 57.5003,
     "k198rProb": 20.5,
     "probDifference": 0.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2914
    },
    {
     "sequence": "KGDCGNPSVGIFFFV",
     "pos": 1744,
     "wtScore": 64.5306,
     "wtProb": 19.8,
     "k198rScore": 57.5003,
     "k198rProb": 20.5,
     "probDifference": 0.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2915
    },
    {
     "sequence": "KGDCGNPSVGIFFFV",
     "pos": 1739,
     "wtScore": 64.5306,
     "wtProb": 19.8,
     "k198rScore": 57.5003,
     "k198rProb": 20.5,
     "probDifference": 0.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2916
    },
    {
     "sequence": "KGDCGNPSVGIFFFV",
     "pos": 1759,
     "wtScore": 64.5306,
     "wtProb": 19.8,
     "k198rScore": 57.5003,
     "k198rProb": 20.5,
     "probDifference": 0.7,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2917
    },
    {
     "sequence": "EFVLKLVSLRHYYFT",
     "pos": 1578,
     "wtScore": 36.2914,
     "wtProb": 8.4,
     "k198rScore": 31.7828,
     "k198rProb": 7.8,
     "probDifference": -0.7,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2918
    },
    {
     "sequence": "HGVAGVITKSSKEMK",
     "pos": 523,
     "wtScore": -11.5299,
     "wtProb": 7.3,
     "k198rScore": -19.9503,
     "k198rProb": 6.6,
     "probDifference": -0.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 2919
    },
    {
     "sequence": "IICNYSETGPPEPPY",
     "pos": 689,
     "wtScore": -10.8536,
     "wtProb": 7.5,
     "k198rScore": -16.605,
     "k198rProb": 6.8,
     "probDifference": -0.7,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2920
    },
    {
     "sequence": "FGQRCVSSRPAAPGG",
     "pos": 2080,
     "wtScore": 36.6205,
     "wtProb": 8.5,
     "k198rScore": 32.0316,
     "k198rProb": 7.8,
     "probDifference": -0.7,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2921
    },
    {
     "sequence": "PVSLMVPSQAGAPGR",
     "pos": 2102,
     "wtScore": 53.4014,
     "wtProb": 12.4,
     "k198rScore": 44.6264,
     "k198rProb": 11.8,
     "probDifference": -0.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2922
    },
    {
     "sequence": "IKKGVAWTKLKVHAF",
     "pos": 1010,
     "wtScore": -18.863,
     "wtProb": 6.2,
     "k198rScore": -27.0986,
     "k198rProb": 5.6,
     "probDifference": -0.7,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2923
    },
    {
     "sequence": "WPPQPVPTLRLEGVE",
     "pos": 2053,
     "wtScore": -24.4625,
     "wtProb": 5.9,
     "k198rScore": -29.3887,
     "k198rProb": 5.2,
     "probDifference": -0.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2924
    },
    {
     "sequence": "LLYSVDSSPPKSLPG",
     "pos": 772,
     "wtScore": 41.8361,
     "wtProb": 8.8,
     "k198rScore": 35.272,
     "k198rProb": 8.2,
     "probDifference": -0.7,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2925
    },
    {
     "sequence": "VRFTKILSLLRLLRL",
     "pos": 263,
     "wtScore": 42.3057,
     "wtProb": 8.9,
     "k198rScore": 40.2906,
     "k198rProb": 9.6,
     "probDifference": 0.7,
     "geneName": "HCN1",
     "known": "No",
     "id": 2926
    },
    {
     "sequence": "VRFTKILSLLRLLRL",
     "pos": 332,
     "wtScore": 42.3057,
     "wtProb": 8.9,
     "k198rScore": 40.2906,
     "k198rProb": 9.6,
     "probDifference": 0.7,
     "geneName": "HCN2",
     "known": "No",
     "id": 2927
    },
    {
     "sequence": "PGHPQDGSYPRQADH",
     "pos": 22,
     "wtScore": 50.8126,
     "wtProb": 11,
     "k198rScore": 44.5492,
     "k198rProb": 11.7,
     "probDifference": 0.7,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2928
    },
    {
     "sequence": "SCISNHTTIEIGKDL",
     "pos": 1057,
     "wtScore": -16.9142,
     "wtProb": 6.4,
     "k198rScore": -25.7479,
     "k198rProb": 5.8,
     "probDifference": -0.7,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2929
    },
    {
     "sequence": "PSPCALETGHGRQCQ",
     "pos": 319,
     "wtScore": -13.1882,
     "wtProb": 7,
     "k198rScore": -22.0293,
     "k198rProb": 6.4,
     "probDifference": -0.7,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2930
    },
    {
     "sequence": "PLHVQRPSIPPASDT",
     "pos": 1716,
     "wtScore": 37.9256,
     "wtProb": 8.5,
     "k198rScore": 32.8643,
     "k198rProb": 7.9,
     "probDifference": -0.7,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2931
    },
    {
     "sequence": "LLFEYPESSGPARVI",
     "pos": 161,
     "wtScore": 61.2016,
     "wtProb": 17.7,
     "k198rScore": 53.2184,
     "k198rProb": 18.3,
     "probDifference": 0.7,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2932
    },
    {
     "sequence": "PYSFHQVTIDKVSPY",
     "pos": 702,
     "wtScore": -19.7626,
     "wtProb": 6.2,
     "k198rScore": -27.4021,
     "k198rProb": 5.5,
     "probDifference": -0.6,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 2933
    },
    {
     "sequence": "YVFTSIFTLEIILKM",
     "pos": 964,
     "wtScore": -24.4861,
     "wtProb": 5.9,
     "k198rScore": -29.2553,
     "k198rProb": 5.3,
     "probDifference": -0.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2934
    },
    {
     "sequence": "ARTFHYASPTASQLS",
     "pos": 720,
     "wtScore": 53.7252,
     "wtProb": 12.6,
     "k198rScore": 44.9827,
     "k198rProb": 12,
     "probDifference": -0.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 2935
    },
    {
     "sequence": "YEDDSSPTWSRQNYG",
     "pos": 1858,
     "wtScore": -14.609,
     "wtProb": 6.8,
     "k198rScore": -23.7044,
     "k198rProb": 6.1,
     "probDifference": -0.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2936
    },
    {
     "sequence": "TPASHRRSSFNFECL",
     "pos": 1920,
     "wtScore": 42.7432,
     "wtProb": 9,
     "k198rScore": 35.969,
     "k198rProb": 8.3,
     "probDifference": -0.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2937
    },
    {
     "sequence": "TSPPSYDSVTKPEKE",
     "pos": 1976,
     "wtScore": 61.6757,
     "wtProb": 17.9,
     "k198rScore": 53.6219,
     "k198rProb": 18.6,
     "probDifference": 0.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2938
    },
    {
     "sequence": "YSGYSQGSRSSRIFP",
     "pos": 623,
     "wtScore": 41.7434,
     "wtProb": 8.8,
     "k198rScore": 35.3383,
     "k198rProb": 8.2,
     "probDifference": -0.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2939
    },
    {
     "sequence": "NSMKSIASLLLLLFL",
     "pos": 651,
     "wtScore": 34.1551,
     "wtProb": 8.4,
     "k198rScore": 31.4054,
     "k198rProb": 7.7,
     "probDifference": -0.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2940
    },
    {
     "sequence": "RHGERRNSNVSQASM",
     "pos": 622,
     "wtScore": 55.4381,
     "wtProb": 13.7,
     "k198rScore": 46.6294,
     "k198rProb": 13.1,
     "probDifference": -0.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2941
    },
    {
     "sequence": "RHGERRHSNVSQASR",
     "pos": 622,
     "wtScore": 61.4992,
     "wtProb": 17.8,
     "k198rScore": 51.7148,
     "k198rProb": 17.2,
     "probDifference": -0.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2942
    },
    {
     "sequence": "SMNAEENSRISITFF",
     "pos": 1368,
     "wtScore": 49.6065,
     "wtProb": 10.5,
     "k198rScore": 41.0579,
     "k198rProb": 9.9,
     "probDifference": -0.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2943
    },
    {
     "sequence": "ELRRDSGSAGTQAHC",
     "pos": 1880,
     "wtScore": 68.579,
     "wtProb": 21.8,
     "k198rScore": 66.0559,
     "k198rProb": 22.4,
     "probDifference": 0.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2944
    },
    {
     "sequence": "FKRIWAEYDPEAKGR",
     "pos": 1551,
     "wtScore": -12.8392,
     "wtProb": 7.1,
     "k198rScore": 11.1115,
     "k198rProb": 7.7,
     "probDifference": 0.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2945
    },
    {
     "sequence": "QAAAAAASAESRDFS",
     "pos": 467,
     "wtScore": 71.3657,
     "wtProb": 22.8,
     "k198rScore": 61.5038,
     "k198rProb": 22.2,
     "probDifference": -0.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2946
    },
    {
     "sequence": "QTQTYGASRLIPPLN",
     "pos": 381,
     "wtScore": 41.3612,
     "wtProb": 8.8,
     "k198rScore": 35.2306,
     "k198rProb": 8.2,
     "probDifference": -0.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2947
    },
    {
     "sequence": "VIMLNCVTLGMFRPC",
     "pos": 115,
     "wtScore": -22.3227,
     "wtProb": 6,
     "k198rScore": -28.3412,
     "k198rProb": 5.4,
     "probDifference": -0.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2948
    },
    {
     "sequence": "HQGSNYGSPRPAHAN",
     "pos": 21,
     "wtScore": 55.499,
     "wtProb": 13.7,
     "k198rScore": 48.549,
     "k198rProb": 14.3,
     "probDifference": 0.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2949
    },
    {
     "sequence": "RELRMPCTLGWEAYT",
     "pos": 315,
     "wtScore": -18.1855,
     "wtProb": 6.3,
     "k198rScore": -26.4507,
     "k198rProb": 5.7,
     "probDifference": -0.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2950
    },
    {
     "sequence": "GLKLVVETLISSLRP",
     "pos": 1421,
     "wtScore": -25.4961,
     "wtProb": 5.9,
     "k198rScore": -29.3023,
     "k198rProb": 5.3,
     "probDifference": -0.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2951
    },
    {
     "sequence": "YSQGSRSSRIFPSLR",
     "pos": 626,
     "wtScore": 35.4707,
     "wtProb": 8.4,
     "k198rScore": 32.2759,
     "k198rProb": 7.8,
     "probDifference": -0.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2952
    },
    {
     "sequence": "LRALKTISVIPGLKT",
     "pos": 227,
     "wtScore": 53.4177,
     "wtProb": 12.4,
     "k198rScore": 44.6817,
     "k198rProb": 11.8,
     "probDifference": -0.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2953
    },
    {
     "sequence": "LRALKTISVIPGLKT",
     "pos": 228,
     "wtScore": 53.4177,
     "wtProb": 12.4,
     "k198rScore": 44.6817,
     "k198rProb": 11.8,
     "probDifference": -0.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2954
    },
    {
     "sequence": "LRALKTISVIPGLKT",
     "pos": 231,
     "wtScore": 53.4177,
     "wtProb": 12.4,
     "k198rScore": 44.6817,
     "k198rProb": 11.8,
     "probDifference": -0.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2955
    },
    {
     "sequence": "LRALKTISVIPGLKT",
     "pos": 227,
     "wtScore": 53.4177,
     "wtProb": 12.4,
     "k198rScore": 44.6817,
     "k198rProb": 11.8,
     "probDifference": -0.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2956
    },
    {
     "sequence": "FKRIWSEYDPEAKGR",
     "pos": 1491,
     "wtScore": -12.4503,
     "wtProb": 7.2,
     "k198rScore": 10.6848,
     "k198rProb": 7.8,
     "probDifference": 0.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2957
    },
    {
     "sequence": "SLQSPPRSPRPASVR",
     "pos": 2060,
     "wtScore": 52.5986,
     "wtProb": 11.9,
     "k198rScore": 43.792,
     "k198rProb": 11.3,
     "probDifference": -0.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2958
    },
    {
     "sequence": "KRSSFLFSMDGNRLT",
     "pos": 539,
     "wtScore": 61.4809,
     "wtProb": 17.8,
     "k198rScore": 53.123,
     "k198rProb": 18.4,
     "probDifference": 0.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2959
    },
    {
     "sequence": "STQALHNTNLTREVR",
     "pos": 776,
     "wtScore": -14.3568,
     "wtProb": 6.8,
     "k198rScore": -23.0434,
     "k198rProb": 6.2,
     "probDifference": -0.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 2960
    },
    {
     "sequence": "HSTRSWATPPATPPY",
     "pos": 1982,
     "wtScore": -16.348,
     "wtProb": 6.5,
     "k198rScore": -24.9617,
     "k198rProb": 5.9,
     "probDifference": -0.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2961
    },
    {
     "sequence": "QSLEDSPSKVPKSWS",
     "pos": 477,
     "wtScore": 50.5193,
     "wtProb": 10.9,
     "k198rScore": 42.0109,
     "k198rProb": 10.3,
     "probDifference": -0.6,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 2962
    },
    {
     "sequence": "DFADDEHSTFEDNES",
     "pos": 595,
     "wtScore": 108.233,
     "wtProb": 34.9,
     "k198rScore": 77.7816,
     "k198rProb": 34.3,
     "probDifference": -0.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2963
    },
    {
     "sequence": "KTMDNVATFCTLLML",
     "pos": 916,
     "wtScore": -21.9924,
     "wtProb": 6,
     "k198rScore": -28.0772,
     "k198rProb": 5.4,
     "probDifference": -0.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2964
    },
    {
     "sequence": "QPSPQPQTPGSSTPK",
     "pos": 756,
     "wtScore": -14.1298,
     "wtProb": 6.9,
     "k198rScore": -22.8501,
     "k198rProb": 6.3,
     "probDifference": -0.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 2965
    },
    {
     "sequence": "TLGWEAYTQPQAEGV",
     "pos": 322,
     "wtScore": -13.9236,
     "wtProb": 6.9,
     "k198rScore": -22.5876,
     "k198rProb": 6.3,
     "probDifference": -0.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2966
    },
    {
     "sequence": "QRAVSIASILTNTME",
     "pos": 709,
     "wtScore": 47.3741,
     "wtProb": 9.9,
     "k198rScore": 39.5122,
     "k198rProb": 9.3,
     "probDifference": -0.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2967
    },
    {
     "sequence": "AEKDEDATKFTSIPA",
     "pos": 362,
     "wtScore": -13.5143,
     "wtProb": 7,
     "k198rScore": -22.0386,
     "k198rProb": 6.4,
     "probDifference": -0.6,
     "geneName": "KCNB2",
     "known": "No",
     "id": 2968
    },
    {
     "sequence": "IVIFCLETLPELKDD",
     "pos": 187,
     "wtScore": -18.962,
     "wtProb": 6.2,
     "k198rScore": -26.733,
     "k198rProb": 5.6,
     "probDifference": -0.6,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2969
    },
    {
     "sequence": "PPASLRSSPCAPWGP",
     "pos": 1126,
     "wtScore": 37.0486,
     "wtProb": 8.5,
     "k198rScore": 33.0117,
     "k198rProb": 7.9,
     "probDifference": -0.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2970
    },
    {
     "sequence": "AAEGGSTTLRRRTPS",
     "pos": 2214,
     "wtScore": -22.4161,
     "wtProb": 6,
     "k198rScore": -20.4516,
     "k198rProb": 6.6,
     "probDifference": 0.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2971
    },
    {
     "sequence": "IIIPVGITFFKDETT",
     "pos": 233,
     "wtScore": -20.1194,
     "wtProb": 6.2,
     "k198rScore": -27.0666,
     "k198rProb": 5.6,
     "probDifference": -0.6,
     "geneName": "HCN2",
     "known": "No",
     "id": 2972
    },
    {
     "sequence": "CAIAGVLTIALPVPV",
     "pos": 400,
     "wtScore": -11.6787,
     "wtProb": 7.3,
     "k198rScore": -19.0284,
     "k198rProb": 6.7,
     "probDifference": -0.6,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2973
    },
    {
     "sequence": "CAIAGVLTIALPVPV",
     "pos": 398,
     "wtScore": -11.6787,
     "wtProb": 7.3,
     "k198rScore": -19.0284,
     "k198rProb": 6.7,
     "probDifference": -0.6,
     "geneName": "KCNA1",
     "known": "No",
     "id": 2974
    },
    {
     "sequence": "AIIPYFITLGTELAE",
     "pos": 268,
     "wtScore": -23.7334,
     "wtProb": 5.9,
     "k198rScore": -28.4726,
     "k198rProb": 5.4,
     "probDifference": -0.6,
     "geneName": "KCNA2",
     "known": "No",
     "id": 2975
    },
    {
     "sequence": "LLWTFIKSFQALPYV",
     "pos": 1403,
     "wtScore": 49.3946,
     "wtProb": 10.5,
     "k198rScore": 41.0445,
     "k198rProb": 9.9,
     "probDifference": -0.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2976
    },
    {
     "sequence": "LLWTFIKSFQALPYV",
     "pos": 1345,
     "wtScore": 49.3946,
     "wtProb": 10.5,
     "k198rScore": 41.0445,
     "k198rProb": 9.9,
     "probDifference": -0.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2977
    },
    {
     "sequence": "NFETFGNSMICLFQI",
     "pos": 1702,
     "wtScore": 45.6354,
     "wtProb": 9.4,
     "k198rScore": 38.2011,
     "k198rProb": 8.9,
     "probDifference": -0.6,
     "geneName": "SCN2A",
     "known": "No",
     "id": 2978
    },
    {
     "sequence": "NFETFGNSMICLFQI",
     "pos": 1693,
     "wtScore": 45.6354,
     "wtProb": 9.4,
     "k198rScore": 38.2011,
     "k198rProb": 8.9,
     "probDifference": -0.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2979
    },
    {
     "sequence": "NFETFGNSMICLFQI",
     "pos": 1712,
     "wtScore": 45.6354,
     "wtProb": 9.4,
     "k198rScore": 38.2011,
     "k198rProb": 8.9,
     "probDifference": -0.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2980
    },
    {
     "sequence": "NFETFGNSMICLFQI",
     "pos": 1697,
     "wtScore": 45.6354,
     "wtProb": 9.4,
     "k198rScore": 38.2011,
     "k198rProb": 8.9,
     "probDifference": -0.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2981
    },
    {
     "sequence": "KFCSPHQSLLSIRGS",
     "pos": 557,
     "wtScore": 38.8654,
     "wtProb": 8.6,
     "k198rScore": 39.2409,
     "k198rProb": 9.2,
     "probDifference": 0.6,
     "geneName": "SCN3A",
     "known": "No",
     "id": 2982
    },
    {
     "sequence": "INYPQMTTLNSTSST",
     "pos": 645,
     "wtScore": -28.5689,
     "wtProb": 5.8,
     "k198rScore": -29.4492,
     "k198rProb": 5.2,
     "probDifference": -0.6,
     "geneName": "HCN1",
     "known": "Yes",
     "id": 2983
    },
    {
     "sequence": "IFDGFIVTLSLVELG",
     "pos": 840,
     "wtScore": -30.5205,
     "wtProb": 5.8,
     "k198rScore": -29.2135,
     "k198rProb": 5.3,
     "probDifference": -0.6,
     "geneName": "SCN1A",
     "known": "No",
     "id": 2984
    },
    {
     "sequence": "WPINFNESYLENGTK",
     "pos": 290,
     "wtScore": 92.3968,
     "wtProb": 29,
     "k198rScore": 74.6974,
     "k198rProb": 29.6,
     "probDifference": 0.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2985
    },
    {
     "sequence": "FNFDETQTKRSTFDN",
     "pos": 682,
     "wtScore": -15.0874,
     "wtProb": 6.7,
     "k198rScore": -23.7537,
     "k198rProb": 6.1,
     "probDifference": -0.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2986
    },
    {
     "sequence": "IERRIAESKLKKPPK",
     "pos": 31,
     "wtScore": 59.4697,
     "wtProb": 16.5,
     "k198rScore": 51.5475,
     "k198rProb": 17,
     "probDifference": 0.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2987
    },
    {
     "sequence": "MGSAGNATISTVSST",
     "pos": 71,
     "wtScore": -11.7153,
     "wtProb": 7.3,
     "k198rScore": -18.7934,
     "k198rProb": 6.7,
     "probDifference": -0.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2988
    },
    {
     "sequence": "SLQVVLNSIIKAMVP",
     "pos": 259,
     "wtScore": 41.4705,
     "wtProb": 8.8,
     "k198rScore": 35.5577,
     "k198rProb": 8.2,
     "probDifference": -0.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2989
    },
    {
     "sequence": "SLQVVLNSIIKAMVP",
     "pos": 262,
     "wtScore": 41.4705,
     "wtProb": 8.8,
     "k198rScore": 35.5577,
     "k198rProb": 8.2,
     "probDifference": -0.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2990
    },
    {
     "sequence": "NSVRSIASLLLLLFL",
     "pos": 652,
     "wtScore": 33.5445,
     "wtProb": 8.4,
     "k198rScore": 32.1226,
     "k198rProb": 7.8,
     "probDifference": -0.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2991
    },
    {
     "sequence": "EVRPLSASQPSLPHE",
     "pos": 788,
     "wtScore": 53.0317,
     "wtProb": 12.2,
     "k198rScore": 44.3872,
     "k198rProb": 11.6,
     "probDifference": -0.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 2992
    },
    {
     "sequence": "RAISGDLTAEEELDK",
     "pos": 1721,
     "wtScore": 18.7544,
     "wtProb": 8.6,
     "k198rScore": -1.61741,
     "k198rProb": 8,
     "probDifference": -0.6,
     "geneName": "CACNA1C",
     "known": "Yes",
     "id": 2993
    },
    {
     "sequence": "LGVSPSESPAAERGA",
     "pos": 52,
     "wtScore": 54.6982,
     "wtProb": 13.2,
     "k198rScore": 46.0475,
     "k198rProb": 12.7,
     "probDifference": -0.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 2994
    },
    {
     "sequence": "MVETDTQSKQMENIL",
     "pos": 1546,
     "wtScore": 49.1954,
     "wtProb": 10.4,
     "k198rScore": 40.9,
     "k198rProb": 9.8,
     "probDifference": -0.6,
     "geneName": "SCN8A",
     "known": "No",
     "id": 2995
    },
    {
     "sequence": "SLSNLVASLLNSMKS",
     "pos": 641,
     "wtScore": 37.1239,
     "wtProb": 8.5,
     "k198rScore": 38.8066,
     "k198rProb": 9,
     "probDifference": 0.6,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 2996
    },
    {
     "sequence": "VVSVSLISFGIQSSA",
     "pos": 1002,
     "wtScore": 59.3864,
     "wtProb": 16.4,
     "k198rScore": 51.4822,
     "k198rProb": 17,
     "probDifference": 0.6,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 2997
    },
    {
     "sequence": "PVTAVPGTGLQAGGR",
     "pos": 824,
     "wtScore": -12.963,
     "wtProb": 7.1,
     "k198rScore": -21.0128,
     "k198rProb": 6.5,
     "probDifference": -0.6,
     "geneName": "HCN1",
     "known": "No",
     "id": 2998
    },
    {
     "sequence": "SPLTSLPSKTGGSTA",
     "pos": 613,
     "wtScore": 42.778,
     "wtProb": 9,
     "k198rScore": 36.3608,
     "k198rProb": 8.4,
     "probDifference": -0.6,
     "geneName": "KCNB1",
     "known": "No",
     "id": 2999
    },
    {
     "sequence": "HYHFSHGSPRRPGPE",
     "pos": 534,
     "wtScore": 45.6435,
     "wtProb": 9.4,
     "k198rScore": 38.2823,
     "k198rProb": 8.9,
     "probDifference": -0.6,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3000
    },
    {
     "sequence": "NVDAIEESPSKEPKP",
     "pos": 455,
     "wtScore": 64.3128,
     "wtProb": 19.7,
     "k198rScore": 54.4043,
     "k198rProb": 19.1,
     "probDifference": -0.6,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3001
    },
    {
     "sequence": "TASVRADTYCRLYSL",
     "pos": 625,
     "wtScore": -13.5647,
     "wtProb": 7,
     "k198rScore": -21.6834,
     "k198rProb": 6.4,
     "probDifference": -0.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 3002
    },
    {
     "sequence": "TASVRADTYCRLYSL",
     "pos": 556,
     "wtScore": -13.5647,
     "wtProb": 7,
     "k198rScore": -21.6834,
     "k198rProb": 6.4,
     "probDifference": -0.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 3003
    },
    {
     "sequence": "KEELEMESIPSPVAP",
     "pos": 563,
     "wtScore": 54.5418,
     "wtProb": 13.1,
     "k198rScore": 45.9141,
     "k198rProb": 12.6,
     "probDifference": -0.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3004
    },
    {
     "sequence": "IDESDYMSFINNPSL",
     "pos": 1103,
     "wtScore": 57.9444,
     "wtProb": 15.3,
     "k198rScore": 50.2768,
     "k198rProb": 15.9,
     "probDifference": 0.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3005
    },
    {
     "sequence": "ALWWGLITLTTIGYG",
     "pos": 273,
     "wtScore": -26.4073,
     "wtProb": 5.9,
     "k198rScore": -28.8506,
     "k198rProb": 5.3,
     "probDifference": -0.5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3006
    },
    {
     "sequence": "CVFVAIRTIGNIVIV",
     "pos": 1047,
     "wtScore": -16.4925,
     "wtProb": 6.5,
     "k198rScore": -24.6809,
     "k198rProb": 6,
     "probDifference": -0.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3007
    },
    {
     "sequence": "DDMFNFETFGNSMIC",
     "pos": 1689,
     "wtScore": -14.1845,
     "wtProb": 6.9,
     "k198rScore": -22.4978,
     "k198rProb": 6.3,
     "probDifference": -0.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3008
    },
    {
     "sequence": "DDMFNFETFGNSMIC",
     "pos": 1693,
     "wtScore": -14.1845,
     "wtProb": 6.9,
     "k198rScore": -22.4978,
     "k198rProb": 6.3,
     "probDifference": -0.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3009
    },
    {
     "sequence": "DDMFNFETFGNSMIC",
     "pos": 1698,
     "wtScore": -14.1845,
     "wtProb": 6.9,
     "k198rScore": -22.4978,
     "k198rProb": 6.3,
     "probDifference": -0.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3010
    },
    {
     "sequence": "DDMFNFETFGNSMIC",
     "pos": 1708,
     "wtScore": -14.1845,
     "wtProb": 6.9,
     "k198rScore": -22.4978,
     "k198rProb": 6.3,
     "probDifference": -0.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3011
    },
    {
     "sequence": "DDSSPTWSRQNYGYY",
     "pos": 1860,
     "wtScore": 42.8945,
     "wtProb": 9,
     "k198rScore": 36.5536,
     "k198rProb": 8.4,
     "probDifference": -0.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3012
    },
    {
     "sequence": "REQERVKSAGAWIIH",
     "pos": 197,
     "wtScore": 71.0941,
     "wtProb": 22.8,
     "k198rScore": 67.1291,
     "k198rProb": 23.3,
     "probDifference": 0.5,
     "geneName": "HCN2",
     "known": "No",
     "id": 3013
    },
    {
     "sequence": "KALSETSSNKSFENK",
     "pos": 503,
     "wtScore": 60.107,
     "wtProb": 17,
     "k198rScore": 52.2002,
     "k198rProb": 17.5,
     "probDifference": 0.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3014
    },
    {
     "sequence": "AAHGKRPSIGNLEHV",
     "pos": 1772,
     "wtScore": 46.1187,
     "wtProb": 9.5,
     "k198rScore": 41.4735,
     "k198rProb": 10.1,
     "probDifference": 0.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3015
    },
    {
     "sequence": "SEASKLSSKSAKERR",
     "pos": 490,
     "wtScore": 52.6604,
     "wtProb": 11.9,
     "k198rScore": 45.7239,
     "k198rProb": 12.5,
     "probDifference": 0.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3016
    },
    {
     "sequence": "RLLAATFTLIGVSFF",
     "pos": 297,
     "wtScore": -26.3791,
     "wtProb": 5.9,
     "k198rScore": -28.6782,
     "k198rProb": 5.3,
     "probDifference": -0.5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3017
    },
    {
     "sequence": "AMQHYEQSKMFNDAM",
     "pos": 1229,
     "wtScore": 51.3759,
     "wtProb": 11.3,
     "k198rScore": 42.7604,
     "k198rProb": 10.8,
     "probDifference": -0.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3018
    },
    {
     "sequence": "NLKTANCTLANTNYV",
     "pos": 482,
     "wtScore": -21.2151,
     "wtProb": 6.1,
     "k198rScore": -20.5118,
     "k198rProb": 6.6,
     "probDifference": 0.5,
     "geneName": "KCNA2",
     "known": "No",
     "id": 3019
    },
    {
     "sequence": "AMFVGHATALIQSLD",
     "pos": 393,
     "wtScore": -12.7353,
     "wtProb": 7.1,
     "k198rScore": -20.2756,
     "k198rProb": 6.6,
     "probDifference": -0.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 3020
    },
    {
     "sequence": "TLAQFPNTLLGNPKK",
     "pos": 60,
     "wtScore": -27.2539,
     "wtProb": 5.8,
     "k198rScore": -28.6875,
     "k198rProb": 5.3,
     "probDifference": -0.5,
     "geneName": "KCNA1",
     "known": "No",
     "id": 3021
    },
    {
     "sequence": "IFDGFIVSLSLMELG",
     "pos": 831,
     "wtScore": 32.2335,
     "wtProb": 8.4,
     "k198rScore": 32.6289,
     "k198rProb": 7.9,
     "probDifference": -0.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3022
    },
    {
     "sequence": "SHSVTPESRASSSGA",
     "pos": 2291,
     "wtScore": 43.9548,
     "wtProb": 9.1,
     "k198rScore": 40.4676,
     "k198rProb": 9.6,
     "probDifference": 0.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3023
    },
    {
     "sequence": "IFDGIIVSLSLMELG",
     "pos": 832,
     "wtScore": 34.9228,
     "wtProb": 8.4,
     "k198rScore": 33.2389,
     "k198rProb": 7.9,
     "probDifference": -0.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3024
    },
    {
     "sequence": "SDTDEDKTSVHFEED",
     "pos": 1033,
     "wtScore": -13.2422,
     "wtProb": 7.1,
     "k198rScore": -20.7564,
     "k198rProb": 6.6,
     "probDifference": -0.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3025
    },
    {
     "sequence": "GLQSLGFTLRRSYNE",
     "pos": 322,
     "wtScore": -27.9973,
     "wtProb": 5.8,
     "k198rScore": -28.6317,
     "k198rProb": 5.3,
     "probDifference": -0.5,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3026
    },
    {
     "sequence": "GLQSLGFTLRRSYNE",
     "pos": 326,
     "wtScore": -27.9973,
     "wtProb": 5.8,
     "k198rScore": -28.6317,
     "k198rProb": 5.3,
     "probDifference": -0.5,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3027
    },
    {
     "sequence": "NPNYGYTSFDTFSWA",
     "pos": 364,
     "wtScore": 60.9036,
     "wtProb": 17.5,
     "k198rScore": 52.6073,
     "k198rProb": 17.9,
     "probDifference": 0.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3028
    },
    {
     "sequence": "NPNYGYTSFDTFSWA",
     "pos": 351,
     "wtScore": 60.9036,
     "wtProb": 17.5,
     "k198rScore": 52.6073,
     "k198rProb": 17.9,
     "probDifference": 0.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3029
    },
    {
     "sequence": "NPNYGYTSFDTFSWA",
     "pos": 363,
     "wtScore": 60.9036,
     "wtProb": 17.5,
     "k198rScore": 52.6073,
     "k198rProb": 17.9,
     "probDifference": 0.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3030
    },
    {
     "sequence": "NPNYGYTSFDTFSWA",
     "pos": 365,
     "wtScore": 60.9036,
     "wtProb": 17.5,
     "k198rScore": 52.6073,
     "k198rProb": 17.9,
     "probDifference": 0.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3031
    },
    {
     "sequence": "RPYYADYSPTRRSIH",
     "pos": 1603,
     "wtScore": 52.2197,
     "wtProb": 11.7,
     "k198rScore": 45.3835,
     "k198rProb": 12.2,
     "probDifference": 0.5,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3032
    },
    {
     "sequence": "QRAMSIASILTNTVE",
     "pos": 717,
     "wtScore": 48.1087,
     "wtProb": 10.1,
     "k198rScore": 42.3848,
     "k198rProb": 10.6,
     "probDifference": 0.5,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3033
    },
    {
     "sequence": "IVRSSSSTGQKNFSA",
     "pos": 695,
     "wtScore": -15.0546,
     "wtProb": 6.7,
     "k198rScore": -23.0066,
     "k198rProb": 6.2,
     "probDifference": -0.5,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3034
    },
    {
     "sequence": "IDENDYMSFINNPSL",
     "pos": 1091,
     "wtScore": 58.8866,
     "wtProb": 16.1,
     "k198rScore": 50.9537,
     "k198rProb": 16.5,
     "probDifference": 0.5,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3035
    },
    {
     "sequence": "SLPHEVSTLISRPHP",
     "pos": 798,
     "wtScore": -24.7109,
     "wtProb": 5.9,
     "k198rScore": -28.0255,
     "k198rProb": 5.4,
     "probDifference": -0.5,
     "geneName": "HCN1",
     "known": "No",
     "id": 3036
    },
    {
     "sequence": "AIIPYFITLGTEIAE",
     "pos": 267,
     "wtScore": -22.6202,
     "wtProb": 6,
     "k198rScore": -27.428,
     "k198rProb": 5.5,
     "probDifference": -0.5,
     "geneName": "KCNA1",
     "known": "No",
     "id": 3037
    },
    {
     "sequence": "FVNLGNVSALRTFRV",
     "pos": 216,
     "wtScore": 60.1557,
     "wtProb": 17,
     "k198rScore": 52.1923,
     "k198rProb": 17.5,
     "probDifference": 0.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3038
    },
    {
     "sequence": "SRGEGIRTLLWTFIK",
     "pos": 1337,
     "wtScore": -18.4607,
     "wtProb": 6.3,
     "k198rScore": -18.6767,
     "k198rProb": 6.7,
     "probDifference": 0.5,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3039
    },
    {
     "sequence": "SRGEGIRTLLWTFIK",
     "pos": 1395,
     "wtScore": -18.4607,
     "wtProb": 6.3,
     "k198rScore": -18.6767,
     "k198rProb": 6.7,
     "probDifference": 0.5,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3040
    },
    {
     "sequence": "ECVLKLISLRYYYFT",
     "pos": 1583,
     "wtScore": 39.6548,
     "wtProb": 8.6,
     "k198rScore": 35.2492,
     "k198rProb": 8.2,
     "probDifference": -0.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3041
    },
    {
     "sequence": "SQKGSAFTFPSQQSP",
     "pos": 591,
     "wtScore": -15.2563,
     "wtProb": 6.7,
     "k198rScore": -23.3068,
     "k198rProb": 6.2,
     "probDifference": -0.5,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3042
    },
    {
     "sequence": "GGPSTLTSAGQLLPE",
     "pos": 664,
     "wtScore": 46.2531,
     "wtProb": 9.6,
     "k198rScore": 41.3272,
     "k198rProb": 10,
     "probDifference": 0.5,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3043
    },
    {
     "sequence": "IIDEDHMSFINNPNL",
     "pos": 1084,
     "wtScore": 56.6508,
     "wtProb": 14.5,
     "k198rScore": 49.4023,
     "k198rProb": 15,
     "probDifference": 0.5,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3044
    },
    {
     "sequence": "FFCLGQTTRPRSWCL",
     "pos": 86,
     "wtScore": -16.149,
     "wtProb": 6.5,
     "k198rScore": -23.9685,
     "k198rProb": 6.1,
     "probDifference": -0.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3045
    },
    {
     "sequence": "CVFVAIRTIGNIMIV",
     "pos": 1013,
     "wtScore": -16.4848,
     "wtProb": 6.5,
     "k198rScore": -24.149,
     "k198rProb": 6.1,
     "probDifference": -0.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3046
    },
    {
     "sequence": "GCCPGRSSQKVSLKD",
     "pos": 433,
     "wtScore": 43.0644,
     "wtProb": 9,
     "k198rScore": 37.045,
     "k198rProb": 8.6,
     "probDifference": -0.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3047
    },
    {
     "sequence": "DPFFIVETLCIIWFS",
     "pos": 226,
     "wtScore": -24.5319,
     "wtProb": 5.9,
     "k198rScore": -27.7289,
     "k198rProb": 5.5,
     "probDifference": -0.4,
     "geneName": "KCNA2",
     "known": "No",
     "id": 3048
    },
    {
     "sequence": "DPFFIVETLCIIWFS",
     "pos": 225,
     "wtScore": -24.5319,
     "wtProb": 5.9,
     "k198rScore": -27.7289,
     "k198rProb": 5.5,
     "probDifference": -0.4,
     "geneName": "KCNA1",
     "known": "No",
     "id": 3049
    },
    {
     "sequence": "KLNSSFPSIHCGSWA",
     "pos": 2071,
     "wtScore": 46.9248,
     "wtProb": 9.7,
     "k198rScore": 39.5728,
     "k198rProb": 9.3,
     "probDifference": -0.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3050
    },
    {
     "sequence": "ISFGIQSSAINVVKI",
     "pos": 1008,
     "wtScore": 49.6815,
     "wtProb": 10.6,
     "k198rScore": 43.2902,
     "k198rProb": 11,
     "probDifference": 0.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3051
    },
    {
     "sequence": "STAACPPSYDRVTKP",
     "pos": 1984,
     "wtScore": 52.6546,
     "wtProb": 11.9,
     "k198rScore": 44.2757,
     "k198rProb": 11.5,
     "probDifference": -0.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3052
    },
    {
     "sequence": "PAAGLIQSAWRFYAT",
     "pos": 341,
     "wtScore": 54.1431,
     "wtProb": 12.9,
     "k198rScore": 45.6887,
     "k198rProb": 12.4,
     "probDifference": -0.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3053
    },
    {
     "sequence": "QTVRRSPSADQSLED",
     "pos": 467,
     "wtScore": 63.6743,
     "wtProb": 19.2,
     "k198rScore": 55.3447,
     "k198rProb": 19.6,
     "probDifference": 0.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3054
    },
    {
     "sequence": "KGSPQAQTVRRSPSA",
     "pos": 461,
     "wtScore": -12.0197,
     "wtProb": 7.2,
     "k198rScore": -17.1674,
     "k198rProb": 6.8,
     "probDifference": -0.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3055
    },
    {
     "sequence": "LTNCVFMTFSNPPDW",
     "pos": 149,
     "wtScore": -19.7316,
     "wtProb": 6.2,
     "k198rScore": -26.0285,
     "k198rProb": 5.7,
     "probDifference": -0.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3056
    },
    {
     "sequence": "RGTARSPSLSRLLCR",
     "pos": 2007,
     "wtScore": 31.511,
     "wtProb": 8.4,
     "k198rScore": 33.4099,
     "k198rProb": 7.9,
     "probDifference": -0.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3057
    },
    {
     "sequence": "LLFALMMSLPALFNI",
     "pos": 1655,
     "wtScore": 34.2915,
     "wtProb": 8.4,
     "k198rScore": 33.6855,
     "k198rProb": 8,
     "probDifference": -0.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3058
    },
    {
     "sequence": "LLFALMMSLPALFNI",
     "pos": 1665,
     "wtScore": 34.2915,
     "wtProb": 8.4,
     "k198rScore": 33.6855,
     "k198rProb": 8,
     "probDifference": -0.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3059
    },
    {
     "sequence": "LLFALMMSLPALFNI",
     "pos": 1650,
     "wtScore": 34.2915,
     "wtProb": 8.4,
     "k198rScore": 33.6855,
     "k198rProb": 8,
     "probDifference": -0.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3060
    },
    {
     "sequence": "LLFALMMSLPALFNI",
     "pos": 1646,
     "wtScore": 34.2915,
     "wtProb": 8.4,
     "k198rScore": 33.6855,
     "k198rProb": 8,
     "probDifference": -0.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3061
    },
    {
     "sequence": "YRTFTPASLTVPSSF",
     "pos": 2039,
     "wtScore": 32.113,
     "wtProb": 8.4,
     "k198rScore": 33.506,
     "k198rProb": 7.9,
     "probDifference": -0.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3062
    },
    {
     "sequence": "EPSPSKGSPCRGPLC",
     "pos": 418,
     "wtScore": 51.6157,
     "wtProb": 11.4,
     "k198rScore": 43.2198,
     "k198rProb": 11,
     "probDifference": -0.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3063
    },
    {
     "sequence": "GPRGRLRSRDSSCGR",
     "pos": 76,
     "wtScore": 51.9742,
     "wtProb": 11.6,
     "k198rScore": 44.9985,
     "k198rProb": 12,
     "probDifference": 0.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 3064
    },
    {
     "sequence": "SEASKLSSKSAKEWR",
     "pos": 492,
     "wtScore": 49.2378,
     "wtProb": 10.4,
     "k198rScore": 42.9643,
     "k198rProb": 10.8,
     "probDifference": 0.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3065
    },
    {
     "sequence": "VVETLISSLRPIGNI",
     "pos": 1425,
     "wtScore": 34.9066,
     "wtProb": 8.4,
     "k198rScore": 34.0571,
     "k198rProb": 8,
     "probDifference": -0.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3066
    },
    {
     "sequence": "STSPKFSTGTRSEKN",
     "pos": 787,
     "wtScore": -15.5466,
     "wtProb": 6.6,
     "k198rScore": -23.0909,
     "k198rProb": 6.2,
     "probDifference": -0.4,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3067
    },
    {
     "sequence": "TTELKMCSLAVTPNG",
     "pos": 1056,
     "wtScore": 39.6818,
     "wtProb": 8.6,
     "k198rScore": 38.8185,
     "k198rProb": 9.1,
     "probDifference": 0.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3068
    },
    {
     "sequence": "PAGEPRGSQASFMQR",
     "pos": 157,
     "wtScore": 55.0189,
     "wtProb": 13.5,
     "k198rScore": 46.6801,
     "k198rProb": 13.1,
     "probDifference": -0.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 3069
    },
    {
     "sequence": "SLSLMELSLADVEGL",
     "pos": 832,
     "wtScore": 67.8983,
     "wtProb": 21.4,
     "k198rScore": 60.7157,
     "k198rProb": 21.8,
     "probDifference": 0.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3070
    },
    {
     "sequence": "FNFDEMQTRRSTFDN",
     "pos": 683,
     "wtScore": -18.9274,
     "wtProb": 6.2,
     "k198rScore": -25.4709,
     "k198rProb": 5.8,
     "probDifference": -0.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3071
    },
    {
     "sequence": "GLQAYFVSLFNRFDC",
     "pos": 585,
     "wtScore": 35.0658,
     "wtProb": 8.4,
     "k198rScore": 37.9749,
     "k198rProb": 8.8,
     "probDifference": 0.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3072
    },
    {
     "sequence": "GLQAYFVSLFNRFDC",
     "pos": 584,
     "wtScore": 35.0658,
     "wtProb": 8.4,
     "k198rScore": 37.9749,
     "k198rProb": 8.8,
     "probDifference": 0.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3073
    },
    {
     "sequence": "PAEHTQCSPSMNAEE",
     "pos": 1359,
     "wtScore": 49.6338,
     "wtProb": 10.6,
     "k198rScore": 41.6375,
     "k198rProb": 10.2,
     "probDifference": -0.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3074
    },
    {
     "sequence": "LLLFRCATGEAWQDI",
     "pos": 1461,
     "wtScore": -14.4356,
     "wtProb": 6.8,
     "k198rScore": -21.7465,
     "k198rProb": 6.4,
     "probDifference": -0.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3075
    },
    {
     "sequence": "LAHRISKSKFSRYWR",
     "pos": 499,
     "wtScore": 54.2116,
     "wtProb": 12.9,
     "k198rScore": 47.0847,
     "k198rProb": 13.3,
     "probDifference": 0.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3076
    },
    {
     "sequence": "MSFINNPSLTVTVPI",
     "pos": 1097,
     "wtScore": 32.1872,
     "wtProb": 8.4,
     "k198rScore": 33.6883,
     "k198rProb": 8,
     "probDifference": -0.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3077
    },
    {
     "sequence": "MSFINNPSLTVTVPI",
     "pos": 1109,
     "wtScore": 32.1872,
     "wtProb": 8.4,
     "k198rScore": 33.6883,
     "k198rProb": 8,
     "probDifference": -0.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3078
    },
    {
     "sequence": "MSFINNPSLTVTVPI",
     "pos": 1099,
     "wtScore": 32.1872,
     "wtProb": 8.4,
     "k198rScore": 33.6883,
     "k198rProb": 8,
     "probDifference": -0.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3079
    },
    {
     "sequence": "FRFSLEGSRLTYEKR",
     "pos": 543,
     "wtScore": 55.3634,
     "wtProb": 13.6,
     "k198rScore": 46.9736,
     "k198rProb": 13.3,
     "probDifference": -0.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3080
    },
    {
     "sequence": "IFDGFIVSLSLMELS",
     "pos": 825,
     "wtScore": 32.5506,
     "wtProb": 8.4,
     "k198rScore": 33.7591,
     "k198rProb": 8,
     "probDifference": -0.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3081
    },
    {
     "sequence": "IVDVSLVSLVANALG",
     "pos": 1285,
     "wtScore": 41.113,
     "wtProb": 8.8,
     "k198rScore": 39.1887,
     "k198rProb": 9.2,
     "probDifference": 0.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3082
    },
    {
     "sequence": "GAFLHKGSFCRNYFN",
     "pos": 982,
     "wtScore": 45.8335,
     "wtProb": 9.5,
     "k198rScore": 40.9799,
     "k198rProb": 9.9,
     "probDifference": 0.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3083
    },
    {
     "sequence": "EMESAASTLLNGNVR",
     "pos": 2104,
     "wtScore": -18.9607,
     "wtProb": 6.2,
     "k198rScore": -20.3081,
     "k198rProb": 6.6,
     "probDifference": 0.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3084
    },
    {
     "sequence": "KIDSPRDTLDPAEPG",
     "pos": 2033,
     "wtScore": -19.611,
     "wtProb": 6.2,
     "k198rScore": -20.7654,
     "k198rProb": 6.6,
     "probDifference": 0.4,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3085
    },
    {
     "sequence": "LLYRSIDSHTEDKGP",
     "pos": 1146,
     "wtScore": 99.3659,
     "wtProb": 31.6,
     "k198rScore": 76.4212,
     "k198rProb": 32,
     "probDifference": 0.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3086
    },
    {
     "sequence": "IAVLAAGSQGNVFAT",
     "pos": 186,
     "wtScore": 66.058,
     "wtProb": 20.7,
     "k198rScore": 58.6097,
     "k198rProb": 21,
     "probDifference": 0.4,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3087
    },
    {
     "sequence": "PPCWYKFSNIFLIWD",
     "pos": 741,
     "wtScore": 44.6438,
     "wtProb": 9.2,
     "k198rScore": 38.166,
     "k198rProb": 8.9,
     "probDifference": -0.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3088
    },
    {
     "sequence": "KQGNSSNSRPARALF",
     "pos": 99,
     "wtScore": 49.0753,
     "wtProb": 10.4,
     "k198rScore": 42.7181,
     "k198rProb": 10.7,
     "probDifference": 0.4,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3089
    },
    {
     "sequence": "PSDSAFETNTTSYFN",
     "pos": 288,
     "wtScore": -15.7392,
     "wtProb": 6.6,
     "k198rScore": -23.0819,
     "k198rProb": 6.2,
     "probDifference": -0.4,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3090
    },
    {
     "sequence": "DFLIVDVSLVSLTAN",
     "pos": 1294,
     "wtScore": 37.1851,
     "wtProb": 8.5,
     "k198rScore": 38.1431,
     "k198rProb": 8.9,
     "probDifference": 0.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3091
    },
    {
     "sequence": "DFLIVDVSLVSLTAN",
     "pos": 1284,
     "wtScore": 37.1851,
     "wtProb": 8.5,
     "k198rScore": 38.1431,
     "k198rProb": 8.9,
     "probDifference": 0.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3092
    },
    {
     "sequence": "NKVNPGSSVKGDCGN",
     "pos": 1750,
     "wtScore": 52.9117,
     "wtProb": 12.1,
     "k198rScore": 44.5836,
     "k198rProb": 11.7,
     "probDifference": -0.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3093
    },
    {
     "sequence": "SLVGGPSTLTSAGQL",
     "pos": 661,
     "wtScore": -23.9715,
     "wtProb": 5.9,
     "k198rScore": -27.006,
     "k198rProb": 5.6,
     "probDifference": -0.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3094
    },
    {
     "sequence": "SLQAGLRTLHDIGPE",
     "pos": 1704,
     "wtScore": -14.763,
     "wtProb": 6.8,
     "k198rScore": -21.9615,
     "k198rProb": 6.4,
     "probDifference": -0.4,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3095
    },
    {
     "sequence": "SLIGGPGSHIGGRLL",
     "pos": 656,
     "wtScore": 55.2168,
     "wtProb": 13.6,
     "k198rScore": 48.0232,
     "k198rProb": 13.9,
     "probDifference": 0.4,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3096
    },
    {
     "sequence": "IVDVSLVSLTANALG",
     "pos": 1287,
     "wtScore": 37.7851,
     "wtProb": 8.5,
     "k198rScore": 38.2191,
     "k198rProb": 8.9,
     "probDifference": 0.4,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3097
    },
    {
     "sequence": "IVDVSLVSLTANALG",
     "pos": 1297,
     "wtScore": 37.7851,
     "wtProb": 8.5,
     "k198rScore": 38.2191,
     "k198rProb": 8.9,
     "probDifference": 0.4,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3098
    },
    {
     "sequence": "EMKLSDGSYFGEICL",
     "pos": 604,
     "wtScore": 55.1023,
     "wtProb": 13.5,
     "k198rScore": 46.7607,
     "k198rProb": 13.1,
     "probDifference": -0.4,
     "geneName": "HCN2",
     "known": "No",
     "id": 3099
    },
    {
     "sequence": "ALWWGLITLATIGYG",
     "pos": 312,
     "wtScore": -24.988,
     "wtProb": 5.9,
     "k198rScore": -27.2462,
     "k198rProb": 5.5,
     "probDifference": -0.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3100
    },
    {
     "sequence": "LEVCDDYSLDDNEYF",
     "pos": 76,
     "wtScore": 68.449,
     "wtProb": 21.8,
     "k198rScore": 63.2887,
     "k198rProb": 22.1,
     "probDifference": 0.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3101
    },
    {
     "sequence": "KWKWTKRTLSETSSS",
     "pos": 493,
     "wtScore": -15.2615,
     "wtProb": 6.7,
     "k198rScore": -22.5126,
     "k198rProb": 6.3,
     "probDifference": -0.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3102
    },
    {
     "sequence": "LLSLMGITLEEIEMS",
     "pos": 1696,
     "wtScore": -18.4964,
     "wtProb": 6.3,
     "k198rScore": -24.9568,
     "k198rProb": 5.9,
     "probDifference": -0.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3103
    },
    {
     "sequence": "DFLIVAVSLVSLIAN",
     "pos": 1274,
     "wtScore": 36.6324,
     "wtProb": 8.5,
     "k198rScore": 37.9434,
     "k198rProb": 8.8,
     "probDifference": 0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3104
    },
    {
     "sequence": "LLAKTPLSRPVKRNN",
     "pos": 93,
     "wtScore": 50.9253,
     "wtProb": 11.1,
     "k198rScore": 42.749,
     "k198rProb": 10.8,
     "probDifference": -0.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3105
    },
    {
     "sequence": "AIKILVHSLFSMLIM",
     "pos": 127,
     "wtScore": 32.872,
     "wtProb": 8.4,
     "k198rScore": 34.1879,
     "k198rProb": 8,
     "probDifference": -0.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3106
    },
    {
     "sequence": "AIKILVHSLFSMLIM",
     "pos": 127,
     "wtScore": 32.872,
     "wtProb": 8.4,
     "k198rScore": 34.1879,
     "k198rProb": 8,
     "probDifference": -0.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3107
    },
    {
     "sequence": "PDASQHSSFFIESPK",
     "pos": 650,
     "wtScore": 47.1783,
     "wtProb": 9.8,
     "k198rScore": 41.6189,
     "k198rProb": 10.2,
     "probDifference": 0.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3108
    },
    {
     "sequence": "RFETQLKTLAQFPNT",
     "pos": 53,
     "wtScore": -24.4132,
     "wtProb": 5.9,
     "k198rScore": -26.9468,
     "k198rProb": 5.6,
     "probDifference": -0.3,
     "geneName": "KCNA1",
     "known": "No",
     "id": 3109
    },
    {
     "sequence": "RLSNPRGSNTKGKLF",
     "pos": 437,
     "wtScore": 50.7898,
     "wtProb": 11,
     "k198rScore": 42.6465,
     "k198rProb": 10.7,
     "probDifference": -0.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3110
    },
    {
     "sequence": "ALQAGLRTLHDIGPE",
     "pos": 1645,
     "wtScore": -15.1524,
     "wtProb": 6.7,
     "k198rScore": -22.2472,
     "k198rProb": 6.4,
     "probDifference": -0.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3111
    },
    {
     "sequence": "NCVFMTFSNPPDWSK",
     "pos": 151,
     "wtScore": 49.3003,
     "wtProb": 10.4,
     "k198rScore": 42.7694,
     "k198rProb": 10.8,
     "probDifference": 0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3112
    },
    {
     "sequence": "QKGEQATSLAILRVI",
     "pos": 286,
     "wtScore": 46.7352,
     "wtProb": 9.7,
     "k198rScore": 41.2341,
     "k198rProb": 10,
     "probDifference": 0.3,
     "geneName": "KCNA1",
     "known": "No",
     "id": 3113
    },
    {
     "sequence": "DDQGKYMTLVLSRIN",
     "pos": 1554,
     "wtScore": -21.0342,
     "wtProb": 6.1,
     "k198rScore": -25.8729,
     "k198rProb": 5.8,
     "probDifference": -0.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3114
    },
    {
     "sequence": "YFSDAWNTFDSLIVI",
     "pos": 1270,
     "wtScore": -16.0795,
     "wtProb": 6.6,
     "k198rScore": -23.0145,
     "k198rProb": 6.2,
     "probDifference": -0.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3115
    },
    {
     "sequence": "RHLSNDSTLASFSEP",
     "pos": 445,
     "wtScore": -24.451,
     "wtProb": 5.9,
     "k198rScore": -26.8723,
     "k198rProb": 5.6,
     "probDifference": -0.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3116
    },
    {
     "sequence": "NFGMAFLTLFRVSTG",
     "pos": 1799,
     "wtScore": -21.3601,
     "wtProb": 6.1,
     "k198rScore": -25.9744,
     "k198rProb": 5.8,
     "probDifference": -0.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3117
    },
    {
     "sequence": "TFDNFPQSLLTVFQI",
     "pos": 694,
     "wtScore": 35.6277,
     "wtProb": 8.4,
     "k198rScore": 37.6768,
     "k198rProb": 8.7,
     "probDifference": 0.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3118
    },
    {
     "sequence": "HMHDFFHSFLIVFRV",
     "pos": 924,
     "wtScore": 47.7366,
     "wtProb": 10,
     "k198rScore": 40.591,
     "k198rProb": 9.7,
     "probDifference": -0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3119
    },
    {
     "sequence": "HMHDFFHSFLIVFRV",
     "pos": 930,
     "wtScore": 47.7366,
     "wtProb": 10,
     "k198rScore": 40.591,
     "k198rProb": 9.7,
     "probDifference": -0.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3120
    },
    {
     "sequence": "YFCDAWNTFDALIVV",
     "pos": 1332,
     "wtScore": -12.6258,
     "wtProb": 7.1,
     "k198rScore": -17.689,
     "k198rProb": 6.8,
     "probDifference": -0.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3121
    },
    {
     "sequence": "LLDRVRLSNPRGSNT",
     "pos": 432,
     "wtScore": 56.7904,
     "wtProb": 14.6,
     "k198rScore": 48.4635,
     "k198rProb": 14.3,
     "probDifference": -0.3,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3122
    },
    {
     "sequence": "ENSRISITFFRLFRV",
     "pos": 1373,
     "wtScore": -18.0758,
     "wtProb": 6.3,
     "k198rScore": -24.5002,
     "k198rProb": 6,
     "probDifference": -0.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3123
    },
    {
     "sequence": "MGIMIFSSLVFFAEK",
     "pos": 346,
     "wtScore": 32.4852,
     "wtProb": 8.4,
     "k198rScore": 37.4246,
     "k198rProb": 8.7,
     "probDifference": 0.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3124
    },
    {
     "sequence": "MGIMIFSSLVFFAEK",
     "pos": 350,
     "wtScore": 32.4852,
     "wtProb": 8.4,
     "k198rScore": 37.4246,
     "k198rProb": 8.7,
     "probDifference": 0.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3125
    },
    {
     "sequence": "LLLFRCATGEAWQEI",
     "pos": 1403,
     "wtScore": -16.0834,
     "wtProb": 6.6,
     "k198rScore": -22.9356,
     "k198rProb": 6.3,
     "probDifference": -0.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3126
    },
    {
     "sequence": "GPAARDGTLEYAPVD",
     "pos": 665,
     "wtScore": -23.4769,
     "wtProb": 6,
     "k198rScore": -22.885,
     "k198rProb": 6.3,
     "probDifference": 0.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3127
    },
    {
     "sequence": "GFCLEDFTFLRDPWN",
     "pos": 183,
     "wtScore": -15.431,
     "wtProb": 6.6,
     "k198rScore": -22.3661,
     "k198rProb": 6.3,
     "probDifference": -0.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3128
    },
    {
     "sequence": "GFCLEDFTFLRDPWN",
     "pos": 184,
     "wtScore": -15.431,
     "wtProb": 6.6,
     "k198rScore": -22.3661,
     "k198rProb": 6.3,
     "probDifference": -0.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3129
    },
    {
     "sequence": "GFCLEDFTFLRDPWN",
     "pos": 183,
     "wtScore": -15.431,
     "wtProb": 6.6,
     "k198rScore": -22.3661,
     "k198rProb": 6.3,
     "probDifference": -0.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3130
    },
    {
     "sequence": "LVFIMLSSAALAAED",
     "pos": 899,
     "wtScore": 46.3289,
     "wtProb": 9.6,
     "k198rScore": 39.4881,
     "k198rProb": 9.3,
     "probDifference": -0.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3131
    },
    {
     "sequence": "VSMDQLASYGRKDRI",
     "pos": 689,
     "wtScore": 56.7144,
     "wtProb": 14.5,
     "k198rScore": 49.1986,
     "k198rProb": 14.8,
     "probDifference": 0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3132
    },
    {
     "sequence": "VGALGNLTLVLAIIV",
     "pos": 893,
     "wtScore": -12.7342,
     "wtProb": 7.1,
     "k198rScore": -16.8379,
     "k198rProb": 6.8,
     "probDifference": -0.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3133
    },
    {
     "sequence": "VGALGNLTLVLAIIV",
     "pos": 885,
     "wtScore": -12.7342,
     "wtProb": 7.1,
     "k198rScore": -16.8379,
     "k198rProb": 6.8,
     "probDifference": -0.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3134
    },
    {
     "sequence": "VGALGNLTLVLAIIV",
     "pos": 884,
     "wtScore": -12.7342,
     "wtProb": 7.1,
     "k198rScore": -16.8379,
     "k198rProb": 6.8,
     "probDifference": -0.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3135
    },
    {
     "sequence": "VGALGNLTLVLAIIV",
     "pos": 878,
     "wtScore": -12.7342,
     "wtProb": 7.1,
     "k198rScore": -16.8379,
     "k198rProb": 6.8,
     "probDifference": -0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3136
    },
    {
     "sequence": "NTTVIYNSNIFTDPF",
     "pos": 213,
     "wtScore": 55.9871,
     "wtProb": 14.1,
     "k198rScore": 48.5566,
     "k198rProb": 14.3,
     "probDifference": 0.3,
     "geneName": "KCNA1",
     "known": "No",
     "id": 3137
    },
    {
     "sequence": "FLVMFIFSIFGMSNF",
     "pos": 1665,
     "wtScore": 41.4033,
     "wtProb": 8.8,
     "k198rScore": 36.8517,
     "k198rProb": 8.5,
     "probDifference": -0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3138
    },
    {
     "sequence": "PLSASQPSLPHGAPG",
     "pos": 825,
     "wtScore": 30.4225,
     "wtProb": 8.4,
     "k198rScore": 34.5682,
     "k198rProb": 8.1,
     "probDifference": -0.3,
     "geneName": "HCN2",
     "known": "No",
     "id": 3139
    },
    {
     "sequence": "SPSKVPKSWSFGDRS",
     "pos": 482,
     "wtScore": 52.6533,
     "wtProb": 11.9,
     "k198rScore": 44.4693,
     "k198rProb": 11.7,
     "probDifference": -0.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3140
    },
    {
     "sequence": "YSGYSGYSQGSRSSR",
     "pos": 620,
     "wtScore": 62.0748,
     "wtProb": 18.2,
     "k198rScore": 52.6833,
     "k198rProb": 17.9,
     "probDifference": -0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3141
    },
    {
     "sequence": "KGAKGIRTLLFALMM",
     "pos": 1657,
     "wtScore": -24.2837,
     "wtProb": 5.9,
     "k198rScore": -23.284,
     "k198rProb": 6.2,
     "probDifference": 0.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3142
    },
    {
     "sequence": "KGAKGIRTLLFALMM",
     "pos": 1638,
     "wtScore": -24.2837,
     "wtProb": 5.9,
     "k198rScore": -23.284,
     "k198rProb": 6.2,
     "probDifference": 0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3143
    },
    {
     "sequence": "KGAKGIRTLLFALMM",
     "pos": 1642,
     "wtScore": -24.2837,
     "wtProb": 5.9,
     "k198rScore": -23.284,
     "k198rProb": 6.2,
     "probDifference": 0.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3144
    },
    {
     "sequence": "KGAKGIRTLLFALMM",
     "pos": 1647,
     "wtScore": -24.2837,
     "wtProb": 5.9,
     "k198rScore": -23.284,
     "k198rProb": 6.2,
     "probDifference": 0.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3145
    },
    {
     "sequence": "PRRNSRTSLFSFRGR",
     "pos": 572,
     "wtScore": 36.4146,
     "wtProb": 8.5,
     "k198rScore": 37.6995,
     "k198rProb": 8.7,
     "probDifference": 0.3,
     "geneName": "SCN1A",
     "known": "Yes",
     "id": 3146
    },
    {
     "sequence": "VTVPMYSSQTQTYGA",
     "pos": 373,
     "wtScore": 43.416,
     "wtProb": 9,
     "k198rScore": 39.614,
     "k198rProb": 9.3,
     "probDifference": 0.3,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3147
    },
    {
     "sequence": "KFTSIPASFWWATIT",
     "pos": 370,
     "wtScore": 51.5913,
     "wtProb": 11.4,
     "k198rScore": 43.4146,
     "k198rProb": 11.1,
     "probDifference": -0.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3148
    },
    {
     "sequence": "IGVILFSSAVYFAEA",
     "pos": 343,
     "wtScore": 49.3381,
     "wtProb": 10.5,
     "k198rScore": 41.6572,
     "k198rProb": 10.2,
     "probDifference": -0.3,
     "geneName": "KCNA2",
     "known": "No",
     "id": 3149
    },
    {
     "sequence": "IGVILFSSAVYFAEA",
     "pos": 341,
     "wtScore": 49.3381,
     "wtProb": 10.5,
     "k198rScore": 41.6572,
     "k198rProb": 10.2,
     "probDifference": -0.3,
     "geneName": "KCNA1",
     "known": "No",
     "id": 3150
    },
    {
     "sequence": "KFKCCQISIEEGKGK",
     "pos": 1184,
     "wtScore": 103.117,
     "wtProb": 33.4,
     "k198rScore": 77.4605,
     "k198rProb": 33.7,
     "probDifference": 0.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3151
    },
    {
     "sequence": "ESNRISITFFRLFRV",
     "pos": 1315,
     "wtScore": -19.2905,
     "wtProb": 6.2,
     "k198rScore": -24.9356,
     "k198rProb": 5.9,
     "probDifference": -0.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3152
    },
    {
     "sequence": "RARERRSSYSGYSGY",
     "pos": 612,
     "wtScore": 45.2409,
     "wtProb": 9.4,
     "k198rScore": 38.8767,
     "k198rProb": 9.1,
     "probDifference": -0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3153
    },
    {
     "sequence": "LYTCSDSSKQTEAEC",
     "pos": 1081,
     "wtScore": 45.5512,
     "wtProb": 9.4,
     "k198rScore": 40.6856,
     "k198rProb": 9.7,
     "probDifference": 0.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3154
    },
    {
     "sequence": "AAQTMSTSAPPPVGS",
     "pos": 71,
     "wtScore": 48.6456,
     "wtProb": 10.3,
     "k198rScore": 41.1891,
     "k198rProb": 10,
     "probDifference": -0.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3155
    },
    {
     "sequence": "NCVFMTMSNPPDWTK",
     "pos": 148,
     "wtScore": 49.5862,
     "wtProb": 10.5,
     "k198rScore": 42.8873,
     "k198rProb": 10.8,
     "probDifference": 0.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3156
    },
    {
     "sequence": "NCVFMTMSNPPDWTK",
     "pos": 147,
     "wtScore": 49.5862,
     "wtProb": 10.5,
     "k198rScore": 42.8873,
     "k198rProb": 10.8,
     "probDifference": 0.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3157
    },
    {
     "sequence": "IKHLDVVTLLRRIQP",
     "pos": 1506,
     "wtScore": -19.1112,
     "wtProb": 6.2,
     "k198rScore": -24.782,
     "k198rProb": 5.9,
     "probDifference": -0.3,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3158
    },
    {
     "sequence": "IKHLDVVTLLRRIQP",
     "pos": 1566,
     "wtScore": -19.1112,
     "wtProb": 6.2,
     "k198rScore": -24.782,
     "k198rProb": 5.9,
     "probDifference": -0.3,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3159
    },
    {
     "sequence": "STVPQRVTLFRQMSS",
     "pos": 839,
     "wtScore": -30.3287,
     "wtProb": 5.8,
     "k198rScore": -27.0643,
     "k198rProb": 5.6,
     "probDifference": -0.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 3160
    },
    {
     "sequence": "KFKSIPASFWWATIT",
     "pos": 366,
     "wtScore": 54.0748,
     "wtProb": 12.8,
     "k198rScore": 45.8844,
     "k198rProb": 12.5,
     "probDifference": -0.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3161
    },
    {
     "sequence": "LNEMVNDSWGKQYSY",
     "pos": 339,
     "wtScore": 61.9116,
     "wtProb": 18.1,
     "k198rScore": 53.0619,
     "k198rProb": 18.3,
     "probDifference": 0.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 3162
    },
    {
     "sequence": "AIKILVHSLFNMLIM",
     "pos": 128,
     "wtScore": 33.0486,
     "wtProb": 8.4,
     "k198rScore": 34.6636,
     "k198rProb": 8.1,
     "probDifference": -0.3,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3163
    },
    {
     "sequence": "DTIHPGSSVKGDCGN",
     "pos": 1735,
     "wtScore": 48.6663,
     "wtProb": 10.3,
     "k198rScore": 41.2542,
     "k198rProb": 10,
     "probDifference": -0.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3164
    },
    {
     "sequence": "TSPPSYDSVTKPDKE",
     "pos": 1971,
     "wtScore": 60.9774,
     "wtProb": 17.5,
     "k198rScore": 52.4923,
     "k198rProb": 17.8,
     "probDifference": 0.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3165
    },
    {
     "sequence": "NAMKKLGSKKPQKPI",
     "pos": 1500,
     "wtScore": 56.7266,
     "wtProb": 14.5,
     "k198rScore": 48.445,
     "k198rProb": 14.3,
     "probDifference": -0.3,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3166
    },
    {
     "sequence": "NAMKKLGSKKPQKPI",
     "pos": 1505,
     "wtScore": 56.7266,
     "wtProb": 14.5,
     "k198rScore": 48.445,
     "k198rProb": 14.3,
     "probDifference": -0.3,
     "geneName": "SCN2A",
     "known": "Yes",
     "id": 3167
    },
    {
     "sequence": "NAMKKLGSKKPQKPI",
     "pos": 1515,
     "wtScore": 56.7266,
     "wtProb": 14.5,
     "k198rScore": 48.445,
     "k198rProb": 14.3,
     "probDifference": -0.3,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3168
    },
    {
     "sequence": "NAMKKLGSKKPQKPI",
     "pos": 1496,
     "wtScore": 56.7266,
     "wtProb": 14.5,
     "k198rScore": 48.445,
     "k198rProb": 14.3,
     "probDifference": -0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3169
    },
    {
     "sequence": "QPGVNKFSLRMFGSQ",
     "pos": 109,
     "wtScore": 34.8166,
     "wtProb": 8.4,
     "k198rScore": 35.0182,
     "k198rProb": 8.2,
     "probDifference": -0.3,
     "geneName": "HCN1",
     "known": "No",
     "id": 3170
    },
    {
     "sequence": "QPGVNKFSLRMFGSQ",
     "pos": 178,
     "wtScore": 34.8166,
     "wtProb": 8.4,
     "k198rScore": 35.0182,
     "k198rProb": 8.2,
     "probDifference": -0.3,
     "geneName": "HCN2",
     "known": "No",
     "id": 3171
    },
    {
     "sequence": "IAIFQVITLEGWVDI",
     "pos": 375,
     "wtScore": -25.9999,
     "wtProb": 5.9,
     "k198rScore": -26.8455,
     "k198rProb": 5.6,
     "probDifference": -0.3,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3172
    },
    {
     "sequence": "KGSNPLKSRSLKVNF",
     "pos": 711,
     "wtScore": 43.8034,
     "wtProb": 9.1,
     "k198rScore": 38.1001,
     "k198rProb": 8.8,
     "probDifference": -0.3,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3173
    },
    {
     "sequence": "PILNTKESAAQSKPK",
     "pos": 549,
     "wtScore": 52.2593,
     "wtProb": 11.7,
     "k198rScore": 45.008,
     "k198rProb": 12,
     "probDifference": 0.3,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3174
    },
    {
     "sequence": "RFETQLKTLAQFPET",
     "pos": 49,
     "wtScore": -24.2294,
     "wtProb": 5.9,
     "k198rScore": -26.4798,
     "k198rProb": 5.7,
     "probDifference": -0.3,
     "geneName": "KCNA2",
     "known": "No",
     "id": 3175
    },
    {
     "sequence": "GFCIDGFTFLRDPWN",
     "pos": 187,
     "wtScore": -17.1233,
     "wtProb": 6.4,
     "k198rScore": -23.4675,
     "k198rProb": 6.2,
     "probDifference": -0.3,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3176
    },
    {
     "sequence": "ARGPPFLTLSREKGP",
     "pos": 652,
     "wtScore": -18.2239,
     "wtProb": 6.3,
     "k198rScore": -24.2789,
     "k198rProb": 6,
     "probDifference": -0.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3177
    },
    {
     "sequence": "QRAMSIASILTNTME",
     "pos": 708,
     "wtScore": 44.8944,
     "wtProb": 9.3,
     "k198rScore": 38.7641,
     "k198rProb": 9,
     "probDifference": -0.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3178
    },
    {
     "sequence": "PGDVEQVTLALGAGA",
     "pos": 53,
     "wtScore": -28.1084,
     "wtProb": 5.8,
     "k198rScore": -26.9838,
     "k198rProb": 5.6,
     "probDifference": -0.2,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3179
    },
    {
     "sequence": "YGDIYPKTLLGKIVG",
     "pos": 390,
     "wtScore": -23.4669,
     "wtProb": 6,
     "k198rScore": -26.2787,
     "k198rProb": 5.7,
     "probDifference": -0.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3180
    },
    {
     "sequence": "YGDIYPKTLLGKIVG",
     "pos": 386,
     "wtScore": -23.4669,
     "wtProb": 6,
     "k198rScore": -26.2787,
     "k198rProb": 5.7,
     "probDifference": -0.2,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3181
    },
    {
     "sequence": "YKPQWCRSREAWALY",
     "pos": 1267,
     "wtScore": 49.2239,
     "wtProb": 10.4,
     "k198rScore": 41.6385,
     "k198rProb": 10.2,
     "probDifference": -0.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3182
    },
    {
     "sequence": "TYEKRYSSPHQSLLS",
     "pos": 550,
     "wtScore": 45.9442,
     "wtProb": 9.5,
     "k198rScore": 39.5009,
     "k198rProb": 9.3,
     "probDifference": -0.2,
     "geneName": "SCN1A",
     "known": "Yes",
     "id": 3183
    },
    {
     "sequence": "FRKVKRRSLRLYARW",
     "pos": 474,
     "wtScore": 31.9491,
     "wtProb": 8.4,
     "k198rScore": 37.1434,
     "k198rProb": 8.6,
     "probDifference": 0.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3184
    },
    {
     "sequence": "VAAKGKGSPQAQTVR",
     "pos": 456,
     "wtScore": 67.7846,
     "wtProb": 21.4,
     "k198rScore": 58.8672,
     "k198rProb": 21.1,
     "probDifference": -0.2,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3185
    },
    {
     "sequence": "SAPCQGPSKGLSPRF",
     "pos": 780,
     "wtScore": 54.0237,
     "wtProb": 12.8,
     "k198rScore": 46.532,
     "k198rProb": 13,
     "probDifference": 0.2,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3186
    },
    {
     "sequence": "VRFFACPSKAGFFTN",
     "pos": 245,
     "wtScore": 47.7645,
     "wtProb": 10,
     "k198rScore": 40.7853,
     "k198rProb": 9.8,
     "probDifference": -0.2,
     "geneName": "KCNA2",
     "known": "No",
     "id": 3187
    },
    {
     "sequence": "RYSSPHQSLLSIRGS",
     "pos": 554,
     "wtScore": 35.4409,
     "wtProb": 8.4,
     "k198rScore": 37.3385,
     "k198rProb": 8.6,
     "probDifference": 0.2,
     "geneName": "SCN1A",
     "known": "Yes",
     "id": 3188
    },
    {
     "sequence": "RGPPDAESVHSIYHA",
     "pos": 570,
     "wtScore": 57.0821,
     "wtProb": 14.7,
     "k198rScore": 49.3453,
     "k198rProb": 14.9,
     "probDifference": 0.2,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3189
    },
    {
     "sequence": "KVTRHWTSLSNLVAS",
     "pos": 634,
     "wtScore": 37.4818,
     "wtProb": 8.5,
     "k198rScore": 35.8936,
     "k198rProb": 8.3,
     "probDifference": -0.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3190
    },
    {
     "sequence": "ASLPSYDSVTKPEKE",
     "pos": 1948,
     "wtScore": 61.2069,
     "wtProb": 17.7,
     "k198rScore": 52.1766,
     "k198rProb": 17.5,
     "probDifference": -0.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3191
    },
    {
     "sequence": "MPTSETESVNTENVA",
     "pos": 472,
     "wtScore": 56.671,
     "wtProb": 14.5,
     "k198rScore": 49.0882,
     "k198rProb": 14.7,
     "probDifference": 0.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3192
    },
    {
     "sequence": "RHGERRNSNLSQTSR",
     "pos": 619,
     "wtScore": 59.1735,
     "wtProb": 16.3,
     "k198rScore": 50.4406,
     "k198rProb": 16.1,
     "probDifference": -0.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3193
    },
    {
     "sequence": "CKINDDCTLPRWHMN",
     "pos": 919,
     "wtScore": -24.9986,
     "wtProb": 5.9,
     "k198rScore": -26.3571,
     "k198rProb": 5.7,
     "probDifference": -0.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3194
    },
    {
     "sequence": "LNTEEFSSESELEES",
     "pos": 1121,
     "wtScore": 131.318,
     "wtProb": 34.6,
     "k198rScore": 77.7395,
     "k198rProb": 34.4,
     "probDifference": -0.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3195
    },
    {
     "sequence": "TFSWAFLSLFRLMTQ",
     "pos": 373,
     "wtScore": 40.0813,
     "wtProb": 8.7,
     "k198rScore": 38.2144,
     "k198rProb": 8.9,
     "probDifference": 0.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3196
    },
    {
     "sequence": "TFSWAFLSLFRLMTQ",
     "pos": 375,
     "wtScore": 40.0813,
     "wtProb": 8.7,
     "k198rScore": 38.2144,
     "k198rProb": 8.9,
     "probDifference": 0.2,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3197
    },
    {
     "sequence": "TFSWAFLSLFRLMTQ",
     "pos": 374,
     "wtScore": 40.0813,
     "wtProb": 8.7,
     "k198rScore": 38.2144,
     "k198rProb": 8.9,
     "probDifference": 0.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3198
    },
    {
     "sequence": "IMSVVTNTLVEELEE",
     "pos": 706,
     "wtScore": -18.5456,
     "wtProb": 6.3,
     "k198rScore": -21.5936,
     "k198rProb": 6.4,
     "probDifference": 0.2,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3199
    },
    {
     "sequence": "YFNGTMDSNGTFVNV",
     "pos": 300,
     "wtScore": 60.3544,
     "wtProb": 17.2,
     "k198rScore": 51.9027,
     "k198rProb": 17.3,
     "probDifference": 0.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3200
    },
    {
     "sequence": "WKSYIQDSRYHYFLE",
     "pos": 320,
     "wtScore": 44.7824,
     "wtProb": 9.3,
     "k198rScore": 39.9829,
     "k198rProb": 9.5,
     "probDifference": 0.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3201
    },
    {
     "sequence": "YHCVNMTTGNMFDIS",
     "pos": 1368,
     "wtScore": -18.7203,
     "wtProb": 6.2,
     "k198rScore": -24.2378,
     "k198rProb": 6.1,
     "probDifference": -0.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3202
    },
    {
     "sequence": "MPTSETESVNTENVS",
     "pos": 472,
     "wtScore": 56.2478,
     "wtProb": 14.2,
     "k198rScore": 48.1541,
     "k198rProb": 14,
     "probDifference": -0.2,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3203
    },
    {
     "sequence": "VFEFDWKSYIQDSRY",
     "pos": 315,
     "wtScore": 53.9421,
     "wtProb": 12.7,
     "k198rScore": 45.8764,
     "k198rProb": 12.5,
     "probDifference": -0.2,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3204
    },
    {
     "sequence": "IPFPEDDSNATNSNL",
     "pos": 151,
     "wtScore": 65.8183,
     "wtProb": 20.5,
     "k198rScore": 57.2905,
     "k198rProb": 20.4,
     "probDifference": -0.2,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3205
    },
    {
     "sequence": "GRGEPQCSPAGPEGP",
     "pos": 109,
     "wtScore": 53.4718,
     "wtProb": 12.5,
     "k198rScore": 45.5416,
     "k198rProb": 12.3,
     "probDifference": -0.2,
     "geneName": "HCN2",
     "known": "No",
     "id": 3206
    },
    {
     "sequence": "PSALTSPTGQLPPEG",
     "pos": 666,
     "wtScore": -18.8627,
     "wtProb": 6.2,
     "k198rScore": -22.0766,
     "k198rProb": 6.4,
     "probDifference": 0.2,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3207
    },
    {
     "sequence": "IFKLSRHSKGLQILG",
     "pos": 310,
     "wtScore": 49.4631,
     "wtProb": 10.5,
     "k198rScore": 42.0591,
     "k198rProb": 10.4,
     "probDifference": -0.1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 3208
    },
    {
     "sequence": "IFKLSRHSKGLQILG",
     "pos": 308,
     "wtScore": 49.4631,
     "wtProb": 10.5,
     "k198rScore": 42.0591,
     "k198rProb": 10.4,
     "probDifference": -0.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 3209
    },
    {
     "sequence": "KRQQYAKSKKQGNSS",
     "pos": 90,
     "wtScore": 60.0161,
     "wtProb": 16.9,
     "k198rScore": 51.2496,
     "k198rProb": 16.8,
     "probDifference": -0.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3210
    },
    {
     "sequence": "SMEMLEDSSGRQRAV",
     "pos": 698,
     "wtScore": 63.5965,
     "wtProb": 19.2,
     "k198rScore": 54.3209,
     "k198rProb": 19,
     "probDifference": -0.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3211
    },
    {
     "sequence": "YGGGNRASMEFLRQE",
     "pos": 761,
     "wtScore": 49.034,
     "wtProb": 10.3,
     "k198rScore": 42.2592,
     "k198rProb": 10.5,
     "probDifference": 0.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3212
    },
    {
     "sequence": "VFNVVSDTFFLMDLV",
     "pos": 252,
     "wtScore": -19.9548,
     "wtProb": 6.2,
     "k198rScore": -24.3511,
     "k198rProb": 6,
     "probDifference": -0.1,
     "geneName": "HCN2",
     "known": "No",
     "id": 3213
    },
    {
     "sequence": "LTNCVFMTLSNPPDW",
     "pos": 145,
     "wtScore": -28.8288,
     "wtProb": 5.8,
     "k198rScore": -26.457,
     "k198rProb": 5.7,
     "probDifference": -0.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3214
    },
    {
     "sequence": "TLAQFPETLLGDPKK",
     "pos": 56,
     "wtScore": -25.323,
     "wtProb": 5.9,
     "k198rScore": -26.098,
     "k198rProb": 5.7,
     "probDifference": -0.1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 3215
    },
    {
     "sequence": "VLATSLRSLRFLQIL",
     "pos": 227,
     "wtScore": 36.1228,
     "wtProb": 8.4,
     "k198rScore": 35.9551,
     "k198rProb": 8.3,
     "probDifference": -0.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3216
    },
    {
     "sequence": "AKYRRIQTLIYDALE",
     "pos": 108,
     "wtScore": -22.0825,
     "wtProb": 6,
     "k198rScore": -23.6083,
     "k198rProb": 6.2,
     "probDifference": 0.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3217
    },
    {
     "sequence": "AAAASAESRDFSGAG",
     "pos": 470,
     "wtScore": 57.9989,
     "wtProb": 15.4,
     "k198rScore": 49.722,
     "k198rProb": 15.3,
     "probDifference": -0.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3218
    },
    {
     "sequence": "AWYIGFLTLILSSFL",
     "pos": 270,
     "wtScore": -16.8113,
     "wtProb": 6.5,
     "k198rScore": -20.3315,
     "k198rProb": 6.6,
     "probDifference": 0.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3219
    },
    {
     "sequence": "PVVEPEETLEPEACF",
     "pos": 1173,
     "wtScore": -19.3873,
     "wtProb": 6.2,
     "k198rScore": -24.1502,
     "k198rProb": 6.1,
     "probDifference": -0.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3220
    },
    {
     "sequence": "ITVNYNGTLINETVF",
     "pos": 302,
     "wtScore": -20.1166,
     "wtProb": 6.2,
     "k198rScore": -24.3729,
     "k198rProb": 6,
     "probDifference": -0.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3221
    },
    {
     "sequence": "RGSELGVSPSESPAA",
     "pos": 48,
     "wtScore": 100.044,
     "wtProb": 31.9,
     "k198rScore": 76.3252,
     "k198rProb": 31.8,
     "probDifference": -0.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3222
    },
    {
     "sequence": "STQTPQPSAILSPCS",
     "pos": 690,
     "wtScore": 43.7746,
     "wtProb": 9.1,
     "k198rScore": 38.5443,
     "k198rProb": 9,
     "probDifference": -0.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 3223
    },
    {
     "sequence": "EDIVDDKSCPCEFVT",
     "pos": 521,
     "wtScore": 64.3886,
     "wtProb": 19.7,
     "k198rScore": 55.2919,
     "k198rProb": 19.6,
     "probDifference": -0.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3224
    },
    {
     "sequence": "PAHERSLSAYGGGNR",
     "pos": 752,
     "wtScore": 69.4468,
     "wtProb": 22.1,
     "k198rScore": 63.3929,
     "k198rProb": 22.2,
     "probDifference": 0.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3225
    },
    {
     "sequence": "RISKSKFSRYWRRWN",
     "pos": 502,
     "wtScore": 42.8619,
     "wtProb": 9,
     "k198rScore": 39.0371,
     "k198rProb": 9.1,
     "probDifference": 0.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3226
    },
    {
     "sequence": "IPFPEDDSNSTNHNL",
     "pos": 153,
     "wtScore": 63.838,
     "wtProb": 19.4,
     "k198rScore": 54.8991,
     "k198rProb": 19.5,
     "probDifference": 0.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3227
    },
    {
     "sequence": "SYEPITTTLKRKQEE",
     "pos": 1893,
     "wtScore": -23.9196,
     "wtProb": 5.9,
     "k198rScore": -24.1385,
     "k198rProb": 6.1,
     "probDifference": 0.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3228
    },
    {
     "sequence": "SYEPITTTLKRKQEE",
     "pos": 1898,
     "wtScore": -23.9196,
     "wtProb": 5.9,
     "k198rScore": -24.1385,
     "k198rProb": 6.1,
     "probDifference": 0.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3229
    },
    {
     "sequence": "VVGVSLVSFGIQSSA",
     "pos": 968,
     "wtScore": 59.4176,
     "wtProb": 16.4,
     "k198rScore": 51.0474,
     "k198rProb": 16.6,
     "probDifference": 0.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3230
    },
    {
     "sequence": "ATDSPKSSLKGSNPL",
     "pos": 702,
     "wtScore": 31.5182,
     "wtProb": 8.4,
     "k198rScore": 35.507,
     "k198rProb": 8.2,
     "probDifference": -0.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3231
    },
    {
     "sequence": "EDDTKFKSIPASFWW",
     "pos": 362,
     "wtScore": 47.1097,
     "wtProb": 9.8,
     "k198rScore": 40.6282,
     "k198rProb": 9.7,
     "probDifference": -0.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3232
    },
    {
     "sequence": "NHEELERSPSGFSIS",
     "pos": 802,
     "wtScore": 57.8604,
     "wtProb": 15.3,
     "k198rScore": 49.6058,
     "k198rProb": 15.2,
     "probDifference": -0.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3233
    },
    {
     "sequence": "GGKPNSSSNSRDDGN",
     "pos": 10,
     "wtScore": 45.5465,
     "wtProb": 9.4,
     "k198rScore": 40.1959,
     "k198rProb": 9.5,
     "probDifference": 0.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 3234
    },
    {
     "sequence": "TSSSEGSTIDIKPEV",
     "pos": 1135,
     "wtScore": -15.4988,
     "wtProb": 6.6,
     "k198rScore": -18.4484,
     "k198rProb": 6.7,
     "probDifference": 0.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3235
    },
    {
     "sequence": "AHEVLWRTLDRLPRT",
     "pos": 47,
     "wtScore": -25.5693,
     "wtProb": 5.9,
     "k198rScore": -24.6167,
     "k198rProb": 6,
     "probDifference": 0.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3236
    },
    {
     "sequence": "GLQILGQTLKASMRE",
     "pos": 317,
     "wtScore": -25.9708,
     "wtProb": 5.9,
     "k198rScore": -25.9945,
     "k198rProb": 5.8,
     "probDifference": -0.1,
     "geneName": "KCNA1",
     "known": "No",
     "id": 3237
    },
    {
     "sequence": "GLQILGQTLKASMRE",
     "pos": 319,
     "wtScore": -25.9708,
     "wtProb": 5.9,
     "k198rScore": -25.9945,
     "k198rProb": 5.8,
     "probDifference": -0.1,
     "geneName": "KCNA2",
     "known": "No",
     "id": 3238
    },
    {
     "sequence": "LLAPILNSKPPDCDP",
     "pos": 1735,
     "wtScore": 49.5109,
     "wtProb": 10.5,
     "k198rScore": 42.1518,
     "k198rProb": 10.4,
     "probDifference": -0.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3239
    },
    {
     "sequence": "YTTASAKTPPRSPEK",
     "pos": 732,
     "wtScore": -23.2297,
     "wtProb": 6,
     "k198rScore": -25.2817,
     "k198rProb": 5.9,
     "probDifference": -0.1,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3240
    },
    {
     "sequence": "AGADKDGTLLLEGGG",
     "pos": 65,
     "wtScore": -22.9767,
     "wtProb": 6,
     "k198rScore": -25.2142,
     "k198rProb": 5.9,
     "probDifference": -0.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3241
    },
    {
     "sequence": "RSPSADQSLEDSPSK",
     "pos": 471,
     "wtScore": 67.338,
     "wtProb": 21.1,
     "k198rScore": 58.6128,
     "k198rProb": 21,
     "probDifference": -0.1,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3242
    },
    {
     "sequence": "DFLIVDVSLVSLVAN",
     "pos": 1282,
     "wtScore": 36.7986,
     "wtProb": 8.5,
     "k198rScore": 37.0289,
     "k198rProb": 8.6,
     "probDifference": 0.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3243
    },
    {
     "sequence": "PLSASQPSLPHEVST",
     "pos": 791,
     "wtScore": 34.3351,
     "wtProb": 8.4,
     "k198rScore": 35.9542,
     "k198rProb": 8.3,
     "probDifference": -0.1,
     "geneName": "HCN1",
     "known": "No",
     "id": 3244
    },
    {
     "sequence": "NHEVLWRTLDRLPRT",
     "pos": 51,
     "wtScore": -25.3671,
     "wtProb": 5.9,
     "k198rScore": -25.7249,
     "k198rProb": 5.8,
     "probDifference": -0.1,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3245
    },
    {
     "sequence": "AALPINPTIIRIMRV",
     "pos": 1711,
     "wtScore": -20.9694,
     "wtProb": 6.1,
     "k198rScore": -24.4469,
     "k198rProb": 6,
     "probDifference": -0.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3246
    },
    {
     "sequence": "LPSPPAGTLTCELKS",
     "pos": 691,
     "wtScore": -22.5769,
     "wtProb": 6,
     "k198rScore": -24.0966,
     "k198rProb": 6.1,
     "probDifference": 0.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3247
    },
    {
     "sequence": "EEKPRNMSMPTSETE",
     "pos": 464,
     "wtScore": 56.99,
     "wtProb": 14.7,
     "k198rScore": 48.9613,
     "k198rProb": 14.6,
     "probDifference": -0.1,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3248
    },
    {
     "sequence": "GDQKPPASLRSSPCA",
     "pos": 1122,
     "wtScore": 35.1374,
     "wtProb": 8.4,
     "k198rScore": 36.7662,
     "k198rProb": 8.5,
     "probDifference": 0.1,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3249
    },
    {
     "sequence": "YTDEPDISYRTFTPA",
     "pos": 2031,
     "wtScore": 47.1019,
     "wtProb": 9.8,
     "k198rScore": 40.987,
     "k198rProb": 9.9,
     "probDifference": 0.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3250
    },
    {
     "sequence": "SYQPITTTLKRKQEE",
     "pos": 1908,
     "wtScore": -27.3124,
     "wtProb": 5.8,
     "k198rScore": -25.8399,
     "k198rProb": 5.8,
     "probDifference": -0.1,
     "geneName": "SCN1A",
     "known": "Yes",
     "id": 3251
    },
    {
     "sequence": "RFSSPHQSLLSIRGS",
     "pos": 557,
     "wtScore": 36.9123,
     "wtProb": 8.5,
     "k198rScore": 36.96,
     "k198rProb": 8.5,
     "probDifference": 0.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3252
    },
    {
     "sequence": "LPSLHRSSWYTDEPD",
     "pos": 2022,
     "wtScore": 41.0421,
     "wtProb": 8.8,
     "k198rScore": 38.0358,
     "k198rProb": 8.8,
     "probDifference": 0.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3253
    },
    {
     "sequence": "SELGAIKSLRTLRAL",
     "pos": 1313,
     "wtScore": 37.9926,
     "wtProb": 8.5,
     "k198rScore": 37.1761,
     "k198rProb": 8.6,
     "probDifference": 0.1,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3254
    },
    {
     "sequence": "SELGAIKSLRTLRAL",
     "pos": 1301,
     "wtScore": 37.9926,
     "wtProb": 8.5,
     "k198rScore": 37.1761,
     "k198rProb": 8.6,
     "probDifference": 0.1,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3255
    },
    {
     "sequence": "SELGAIKSLRTLRAL",
     "pos": 1293,
     "wtScore": 37.9926,
     "wtProb": 8.5,
     "k198rScore": 37.1761,
     "k198rProb": 8.6,
     "probDifference": 0.1,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3256
    },
    {
     "sequence": "SELGAIKSLRTLRAL",
     "pos": 1303,
     "wtScore": 37.9926,
     "wtProb": 8.5,
     "k198rScore": 37.1761,
     "k198rProb": 8.6,
     "probDifference": 0.1,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3257
    },
    {
     "sequence": "LPWVYFVSLVIFGSF",
     "pos": 387,
     "wtScore": 36.4942,
     "wtProb": 8.5,
     "k198rScore": 36.8678,
     "k198rProb": 8.5,
     "probDifference": 0.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3258
    },
    {
     "sequence": "QVNGSLPSLHRSSWY",
     "pos": 2017,
     "wtScore": 34.3222,
     "wtProb": 8.4,
     "k198rScore": 36.1331,
     "k198rProb": 8.3,
     "probDifference": -0.1,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3259
    },
    {
     "sequence": "QVTSAIATLQQAAAM",
     "pos": 728,
     "wtScore": -29.7914,
     "wtProb": 5.8,
     "k198rScore": -25.7595,
     "k198rProb": 5.8,
     "probDifference": -0.1,
     "geneName": "HCN2",
     "known": "No",
     "id": 3260
    },
    {
     "sequence": "HKKSQKGSAFTFPSQ",
     "pos": 588,
     "wtScore": 59.9139,
     "wtProb": 16.8,
     "k198rScore": 51.2619,
     "k198rProb": 16.8,
     "probDifference": -0.1,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3261
    },
    {
     "sequence": "STPKHKKSQKGSAFT",
     "pos": 584,
     "wtScore": 51.742,
     "wtProb": 11.4,
     "k198rScore": 43.9185,
     "k198rProb": 11.4,
     "probDifference": 0,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3262
    },
    {
     "sequence": "SQEEVPSSPIFPHRT",
     "pos": 1939,
     "wtScore": 51.8144,
     "wtProb": 11.5,
     "k198rScore": 44.043,
     "k198rProb": 11.4,
     "probDifference": 0,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3263
    },
    {
     "sequence": "SYTTAVCSPPVQSPL",
     "pos": 704,
     "wtScore": 50.2536,
     "wtProb": 10.8,
     "k198rScore": 42.7825,
     "k198rProb": 10.8,
     "probDifference": 0,
     "geneName": "HCN1",
     "known": "No",
     "id": 3264
    },
    {
     "sequence": "GLFGNHVSYYQSDGR",
     "pos": 1755,
     "wtScore": 50.0996,
     "wtProb": 10.7,
     "k198rScore": 42.6497,
     "k198rProb": 10.7,
     "probDifference": 0,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3265
    },
    {
     "sequence": "VDESDYMSFINNPSL",
     "pos": 1093,
     "wtScore": 57.5123,
     "wtProb": 15,
     "k198rScore": 49.5049,
     "k198rProb": 15.1,
     "probDifference": 0,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3266
    },
    {
     "sequence": "FLFIIIFSLLGMQLF",
     "pos": 665,
     "wtScore": 36.0859,
     "wtProb": 8.4,
     "k198rScore": 36.3692,
     "k198rProb": 8.4,
     "probDifference": 0,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3267
    },
    {
     "sequence": "FLFIIIFSLLGMQLF",
     "pos": 664,
     "wtScore": 36.0859,
     "wtProb": 8.4,
     "k198rScore": 36.3692,
     "k198rProb": 8.4,
     "probDifference": 0,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3268
    },
    {
     "sequence": "STSWQPQSHPRQGHG",
     "pos": 721,
     "wtScore": 44.1126,
     "wtProb": 9.1,
     "k198rScore": 38.9634,
     "k198rProb": 9.1,
     "probDifference": 0,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3269
    },
    {
     "sequence": "PPPEPSPSKGSPCRG",
     "pos": 415,
     "wtScore": 55.0848,
     "wtProb": 13.5,
     "k198rScore": 47.3851,
     "k198rProb": 13.5,
     "probDifference": 0,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3270
    },
    {
     "sequence": "PGPPPPASPPGAPAS",
     "pos": 778,
     "wtScore": 47.1429,
     "wtProb": 9.8,
     "k198rScore": 40.8088,
     "k198rProb": 9.8,
     "probDifference": 0,
     "geneName": "HCN2",
     "known": "No",
     "id": 3271
    },
    {
     "sequence": "SETKQRESQLMREQR",
     "pos": 429,
     "wtScore": 53.3659,
     "wtProb": 12.4,
     "k198rScore": 45.6241,
     "k198rProb": 12.4,
     "probDifference": 0,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3272
    },
    {
     "sequence": "TLSETSSSKSFETKE",
     "pos": 500,
     "wtScore": 47.7642,
     "wtProb": 10,
     "k198rScore": 41.3125,
     "k198rProb": 10,
     "probDifference": 0,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3273
    },
    {
     "sequence": "GPPEPPYSFHQVTID",
     "pos": 697,
     "wtScore": 48.3917,
     "wtProb": 10.2,
     "k198rScore": 41.729,
     "k198rProb": 10.2,
     "probDifference": 0,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3274
    },
    {
     "sequence": "SPSGFSISQDRDDYV",
     "pos": 809,
     "wtScore": 54.416,
     "wtProb": 13.1,
     "k198rScore": 46.5959,
     "k198rProb": 13,
     "probDifference": 0,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3275
    },
    {
     "sequence": "EPVDIIRSKTCSRRV",
     "pos": 29,
     "wtScore": 50.2333,
     "wtProb": 10.8,
     "k198rScore": 42.984,
     "k198rProb": 10.8,
     "probDifference": 0,
     "geneName": "KCNB2",
     "known": "No",
     "id": 3276
    },
    {
     "sequence": "FATSALRSLRFLQIL",
     "pos": 198,
     "wtScore": 35.3031,
     "wtProb": 8.4,
     "k198rScore": 36.5823,
     "k198rProb": 8.4,
     "probDifference": 0,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3277
    },
    {
     "sequence": "SYEPITTTLRRKQEE",
     "pos": 1888,
     "wtScore": -24.555,
     "wtProb": 5.9,
     "k198rScore": -25.1323,
     "k198rProb": 5.9,
     "probDifference": 0,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3278
    },
    {
     "sequence": "VSFGIQSSAISVVKI",
     "pos": 974,
     "wtScore": 49.0729,
     "wtProb": 10.4,
     "k198rScore": 42.0426,
     "k198rProb": 10.3,
     "probDifference": 0,
     "geneName": "CACNA1D",
     "known": "No",
     "id": 3279
    },
    {
     "sequence": "SEAPKRGSILSKPRA",
     "pos": 51,
     "wtScore": 48.6108,
     "wtProb": 10.2,
     "k198rScore": 41.8194,
     "k198rProb": 10.2,
     "probDifference": 0,
     "geneName": "KCNQ2",
     "known": "Yes",
     "id": 3280
    },
    {
     "sequence": "YKKKFKETLRPYDVK",
     "pos": 536,
     "wtScore": -23.4395,
     "wtProb": 6,
     "k198rScore": -24.8182,
     "k198rProb": 5.9,
     "probDifference": 0,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3281
    },
    {
     "sequence": "TPPATPGSRGWPPQP",
     "pos": 2043,
     "wtScore": 50.7017,
     "wtProb": 11,
     "k198rScore": 43.2692,
     "k198rProb": 11,
     "probDifference": 0,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3282
    },
    {
     "sequence": "EKYFVSPTLFRVIRL",
     "pos": 1632,
     "wtScore": -24.5901,
     "wtProb": 5.9,
     "k198rScore": -24.9157,
     "k198rProb": 5.9,
     "probDifference": 0,
     "geneName": "SCN1A",
     "known": "No",
     "id": 3283
    },
    {
     "sequence": "EKYFVSPTLFRVIRL",
     "pos": 1622,
     "wtScore": -24.5901,
     "wtProb": 5.9,
     "k198rScore": -24.9157,
     "k198rProb": 5.9,
     "probDifference": 0,
     "geneName": "SCN2A",
     "known": "No",
     "id": 3284
    },
    {
     "sequence": "EKYFVSPTLFRVIRL",
     "pos": 1613,
     "wtScore": -24.5901,
     "wtProb": 5.9,
     "k198rScore": -24.9157,
     "k198rProb": 5.9,
     "probDifference": 0,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3285
    },
    {
     "sequence": "EKYFVSPTLFRVIRL",
     "pos": 1617,
     "wtScore": -24.5901,
     "wtProb": 5.9,
     "k198rScore": -24.9157,
     "k198rProb": 5.9,
     "probDifference": 0,
     "geneName": "SCN3A",
     "known": "No",
     "id": 3286
    },
    {
     "sequence": "PIEDMIPTLKAAIRA",
     "pos": 513,
     "wtScore": -24.9814,
     "wtProb": 5.9,
     "k198rScore": -25.0252,
     "k198rProb": 5.9,
     "probDifference": 0,
     "geneName": "KCNQ3",
     "known": "No",
     "id": 3287
    },
    {
     "sequence": "RDSDTSISIPSVDHE",
     "pos": 788,
     "wtScore": 50.0917,
     "wtProb": 10.7,
     "k198rScore": 42.6965,
     "k198rProb": 10.7,
     "probDifference": 0,
     "geneName": "KCNQ2",
     "known": "No",
     "id": 3288
    },
    {
     "sequence": "SEISKLSSKSAKERR",
     "pos": 484,
     "wtScore": 52.2122,
     "wtProb": 11.7,
     "k198rScore": 44.5581,
     "k198rProb": 11.7,
     "probDifference": 0,
     "geneName": "SCN8A",
     "known": "No",
     "id": 3289
    },
    {
     "sequence": "NLGQALMSLFVLSSK",
     "pos": 1495,
     "wtScore": 38.5462,
     "wtProb": 8.6,
     "k198rScore": 37.038,
     "k198rProb": 8.6,
     "probDifference": 0,
     "geneName": "CACNA1H",
     "known": "No",
     "id": 3290
    },
    {
     "sequence": "VAGLECATLLDKAVL",
     "pos": 711,
     "wtScore": -15.386,
     "wtProb": 6.6,
     "k198rScore": -19.9753,
     "k198rProb": 6.6,
     "probDifference": 0,
     "geneName": "KCNB1",
     "known": "No",
     "id": 3291
    },
    {
     "sequence": "SRCHSRESQAAMAGQ",
     "pos": 1903,
     "wtScore": 47.1988,
     "wtProb": 9.8,
     "k198rScore": 40.9171,
     "k198rProb": 9.8,
     "probDifference": 0,
     "geneName": "CACNA1C",
     "known": "No",
     "id": 3292
    },
    {
     "sequence": "DTYCRLYSLSVDNFN",
     "pos": 562,
     "wtScore": 35.565,
     "wtProb": 8.4,
     "k198rScore": 36.4893,
     "k198rProb": 8.4,
     "probDifference": 0,
     "geneName": "HCN1",
     "known": "No",
     "id": 3293
    },
    {
     "sequence": "DTYCRLYSLSVDNFN",
     "pos": 631,
     "wtScore": 35.565,
     "wtProb": 8.4,
     "k198rScore": 36.4893,
     "k198rProb": 8.4,
     "probDifference": 0,
     "geneName": "HCN2",
     "known": "No",
     "id": 3294
    }
   ]