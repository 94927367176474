import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components'
import { FlexCol, FlexRow } from '../styles/layout';
import { NormalText } from '../styles/text';
import { Slider } from './form/Slider';

const SliderLabel = styled(NormalText)`
  width: 230px;
  margin: 0px;
  margin-bottom: 18px;
`
const SliderValue = styled(NormalText)`
  margin: 0;
  margin-left: 16px;
`

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px;
`

const ProbabilityFilter = ({
  probabilityDifference,
  setProbabilityDifference,
  wildTypeProb,
  setWildTypeProb,
  mutantProb,
  setMutantProb
}) => {
  let sliders =[
    {label: "Probability Difference", value: probabilityDifference, setValue: setProbabilityDifference},
    {label: "Wild Type Probability", value: wildTypeProb, setValue: setWildTypeProb},
    {label: "K198R Probability", value: mutantProb, setValue: setMutantProb},
  ]
  return (
    <FlexRow justifyContent="space-between" fullWidth>
      { sliders.map(s => 
        <SliderContainer>
          <SliderLabel>{s.label}</SliderLabel>
          <FlexRow alignItems="center">
            <Slider
              value={s.value}
              min={0}
              step={1}
              max={100}
              onChange={(e, value) => {
                s.setValue(value)
              }}
              ariaLabel="non-linear-slider"
            />
            <SliderValue>{s.value}</SliderValue>
          </FlexRow>    
                  
        </SliderContainer>
      )}   
    </FlexRow>
  )
}

export default ProbabilityFilter